import { IconBug, IconKey } from '@tabler/icons';
import CompaniesIcon from 'assets/SideBarIcons/buildings.svg';
import DashboardIcon from 'assets/SideBarIcons/dashboard.svg';
import SettingIcon from 'assets/SideBarIcons/setting.svg';
import { HiMiniTableCells } from 'react-icons/hi2';
import { TbBuilding } from 'react-icons/tb';
import { FormattedMessage } from 'react-intl';
import { NavItemType } from 'types';
const icons = {
    IconKey,
    IconBug
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages: NavItemType = {
    // TODO : Need to change this title and caption
    id: 'pages',
    title: '',
    caption: '',
    // title: <FormattedMessage id="pages" />,
    // caption: <FormattedMessage id="pages-caption" />,
    // icon: icons.IconKey,
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: <FormattedMessage id="dashboard" />,
            type: 'item',
            url: '/',
            icon: DashboardIcon
        },
        {
            id: 'data',
            title: <FormattedMessage id="data" />,
            type: 'collapse',
            icon: CompaniesIcon,
            children: [
                {
                    id: 'exim',
                    title: <FormattedMessage id="exim" />,
                    type: 'item',
                    url: '/data/exim',
                    target: false
                },
                {
                    id: 'accounting',
                    title: <FormattedMessage id="accounting" />,
                    type: 'item',
                    url: '/data/accounting',
                    target: false
                },
                {
                    id: 'warehouse',
                    title: <FormattedMessage id="warehouse" />,
                    type: 'item',
                    url: '/data/warehouse',
                    target: false
                },
                {
                    id: 'production',
                    title: <FormattedMessage id="production" />,
                    type: 'item',
                    url: '/data/production',
                    target: false
                },
                {
                    id: 'bom',
                    title: <FormattedMessage id="bom" />,
                    type: 'item',
                    url: '/data/bom',
                    target: false
                }
            ]
        },
        // {
        //     id: 'new-balance',
        //     title: <FormattedMessage id="BALANCE" />,
        //     type: 'collapse',
        //     icon: CompaniesIcon,
        //     children: [
        //         {
        //             id: 'tab-management-code',
        //             title: <FormattedMessage id="MANAGEMENT CODE" />,
        //             type: 'item',
        //             url: '/new-balance/management-code',
        //             target: false
        //         },
        //         {
        //             id: 'tab-tax',
        //             title: <FormattedMessage id="TAX RISK" />,
        //             type: 'item',
        //             url: '/new-balance/tax-risk',
        //             target: false
        //         },
        //         {
        //             id: 'tab-report-statement',
        //             title: <FormattedMessage id="REPORT STATMENT" />,
        //             type: 'item',
        //             url: '/new-balance/report-statement',
        //             target: false
        //         },
        //         {
        //             id: 'tab-balance-statement',
        //             title: <FormattedMessage id="BALANCE STATEMENT" />,
        //             type: 'item',
        //             url: '/new-balance/balance-statement',
        //             target: false
        //         },
        //         {
        //             id: 'tab-making-statement',
        //             title: <FormattedMessage id="MAKING STATEMENT" />,
        //             type: 'item',
        //             url: '/new-balance/making-statement',
        //             target: false
        //         },
        //         {
        //             id: 'tab-report-in-out',
        //             title: <FormattedMessage id="REPORT IN/OUT" />,
        //             type: 'item',
        //             url: '/new-balance/report-in-out',
        //             target: false
        //         },
        //         {
        //             id: 'tab-balance',
        //             title: <FormattedMessage id="BALANCE" />,
        //             type: 'item',
        //             url: '/new-balance/balance',
        //             target: false
        //         }
        //     ]
        // },
        {
            id: 'table-collapse',
            title: <FormattedMessage id="FTA" />,
            type: 'collapse',
            icon: () => (
                <>
                    <HiMiniTableCells style={{ color: 'gray' }} />
                </>
            ),
            children: [
                { id: 'invoice-table', title: <FormattedMessage id="FTA Invoice" />, type: 'item', target: false, url: '/fta/bangke' },
                {
                    id: 'co-list',
                    title: <FormattedMessage id="FTA CO-List" />,
                    type: 'item',
                    target: false,
                    url: '/fta/co-list'
                },
                {
                    id: 'annex-10',
                    title: <FormattedMessage id="FTA ANNEX-10" />,
                    type: 'item',
                    target: false,
                    url: '/fta/annex-10'
                },
                {
                    id: 'ecus-stock',
                    title: <FormattedMessage id="FTA Ecus Stock" />,
                    type: 'item',
                    target: false,
                    url: '/fta/ecus-stock'
                },
                {
                    id: 'co-form',
                    title: <FormattedMessage id="FTA Ecus CO Form" />,
                    type: 'item',
                    target: false,
                    url: '/fta/co-form'
                }
            ]
        },
        // { id: 'user', title: <FormattedMessage id="user" />, type: 'item', icon: UserIcon },
        // { id: 'tool', title: <FormattedMessage id="tool" />, type: 'item', icon: ToolIcon },
        // { id: 'harmonized', title: <FormattedMessage id="harmonized-system" />, type: 'item', icon: HarmonizedIcon },
        {
            id: 'company-setting',
            title: <FormattedMessage id="Company Setting" />,
            type: 'item',
            icon: () => (
                <>
                    <TbBuilding style={{ color: 'gray' }} />
                </>
            ),
            url: '/company'
        },
        {
            id: 'setting',
            title: <FormattedMessage id="setting" />,
            type: 'collapse',
            icon: SettingIcon,
            children: [
                {
                    id: 'account-profile',
                    title: <FormattedMessage id="account-profile" />,
                    type: 'item',
                    url: '/user',
                    target: false
                }
            ]
        }
    ]
};

export default pages;
