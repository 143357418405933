import { useCallback, useEffect, useRef } from 'react'

export const useSkipper = () => {
  const shouldSkipRef = useRef(true)
  const shouldSkip = shouldSkipRef.current
  // Wrap a function with this to skip a pagination reset temporarily
  const skip = useCallback(() => {
    shouldSkipRef.current = false
  }, [])

  useEffect(() => {
    shouldSkipRef.current = true
  })

  return [shouldSkip, skip] as const
}
