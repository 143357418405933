import React, { createContext, useEffect, useReducer } from 'react';

// third-party
import { Chance } from 'chance';
import jwtDecode from 'jwt-decode';

// reducer - state management
import accountReducer from 'store/accountReducer';
import { LOGIN, LOGOUT, UPDATE } from 'store/actions';

// project imports
import Loader from 'ui-component/Loader';
import axios from 'utils/axios';

// types
import useSnackBar, { SnackBarAlertEnum } from 'components/SnackBar/UISnackbar';
import { useAuthRepo } from 'di';
import { ResultState } from 'models';
import { ChangePasswordProps, UpdateUserProfileProps } from 'repository/auth/type';
import { match } from 'ts-pattern';
import { KeyedObject } from 'types';
import { InitialLoginContextProps, JWTContextType } from 'types/auth';
import { UserProfile } from 'types/user-profile';

const chance = new Chance();

// constant
const initialState: InitialLoginContextProps = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

const verifyToken: (st: string) => boolean = (serviceToken) => {
    if (!serviceToken) {
        return false;
    }
    const decoded: KeyedObject = jwtDecode(serviceToken);
    /**
     * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
     */
    return decoded.exp > Date.now() / 1000;
};

const setSession = (serviceToken?: string | null) => {
    if (serviceToken) {
        localStorage.setItem('serviceToken', serviceToken);
        axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    } else {
        localStorage.removeItem('serviceToken');
        delete axios.defaults.headers.common.Authorization;
    }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext<JWTContextType | null>(null);

export const JWTProvider = ({ children }: { children: React.ReactElement }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);

    const authRepo = useAuthRepo();
    const { showSnackBar } = useSnackBar();

    const changePassword = async (data: ChangePasswordProps) => {
        const response = await authRepo.changePassword(data);
        match(response)
            .with({ state: ResultState.success }, ({ data }) => {
                showSnackBar('Update', SnackBarAlertEnum.success);
            })
            .with({ state: ResultState.failed }, ({ exception }) => {
                showSnackBar(exception.meaning, SnackBarAlertEnum.error);
            })
            .exhaustive();
    };

    const init = async () => {
        const serviceToken = window.localStorage.getItem('token');

        if (serviceToken) {
            const respone = await authRepo.getMe(serviceToken as unknown as string);
            match(respone)
                .with({ state: ResultState.success }, ({ data }) => {
                    dispatch({
                        type: UPDATE,
                        payload: {
                            isLoggedIn: true,
                            isInitialized: true,
                            user: data
                        }
                    });
                })
                .otherwise(() => {
                    window.localStorage.removeItem('token');
                    dispatch({ type: LOGOUT });
                });
        } else {
            window.localStorage.removeItem('token');
            dispatch({
                type: LOGOUT
            });
        }
    };

    useEffect(() => {
        init();
    }, []);

    const login = async (email: string, password: string) => {
        const response = await authRepo.login({ email, password });
        return new Promise((resolve, reject) => {
            match(response)
                .with({ state: ResultState.success }, (_) => {
                    dispatch({
                        type: LOGIN,
                        payload: {
                            isLoggedIn: true
                        }
                    });
                    init();
                    resolve(_);
                })
                .otherwise((_) => {
                    reject(_);
                });
        });
    };

    const register = async (email: string, password: string, name: string) => {
        const response = await authRepo.register({ email, password, name });
        return new Promise((resolve, reject) => {
            match(response)
                .with({ state: ResultState.success }, (_) => {
                    resolve(_);
                })
                .otherwise((_) => {
                    reject(_);
                });
        });
    };
    const logout = () => {
        // setSession(null);
        localStorage.removeItem('token');
        dispatch({ type: LOGOUT });
    };

    const resetPassword = async (email: string) => {
        return await authRepo.resetPassword(email);
    };

    const resendOTP = async (email: string) => {
        return await authRepo.resendOTP(email);
    };

    const resetPasswordVerify = async (token: string) => {
        return await authRepo.resetPasswordVerify(token);
    };

    const resetPasswordConfirm = async (token: string, password: string) => {
        return await authRepo.resetPasswordConfirm(token, password);
    };

    const updateProfile = async (userInfo: UpdateUserProfileProps) => {
        const respone = await authRepo.UpdateUserProfile(userInfo);
        match(respone).with({ state: ResultState.success }, ({ data }) => {
            dispatch({
                type: UPDATE,
                payload: {
                    ...initialState,
                    user: { ...initialState.user, ...data } as UserProfile
                }
            });
        });
        return respone;
    };

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    const verify = async (token: string) => {
        const response = await authRepo.verify(token);
        return new Promise((resolve, reject) => {
            match(response)
                .with({ state: ResultState.success }, (_) => {
                    dispatch({
                        type: LOGIN,
                        payload: {
                            isLoggedIn: true
                        }
                    });
                    init();
                    resolve(_);
                })
                .otherwise((_) => {
                    reject(_);
                });
        });
    };

    const loginWithGoogle = async (token: string) => {
        const response = await authRepo.loginWithGoogle(token);
        match(response)
            .with({ state: ResultState.success }, () => {
                dispatch({
                    type: LOGIN,
                    payload: {
                        isLoggedIn: true
                    }
                });
                init();
            })
            .otherwise(() => {});
    };

    return (
        <JWTContext.Provider
            value={{
                ...state,
                login,
                logout,
                register,
                resetPassword,
                updateProfile,
                changePassword,
                verify,
                resendOTP,
                resetPasswordVerify,
                resetPasswordConfirm,
                loginWithGoogle
            }}
        >
            {children}
        </JWTContext.Provider>
    );
};

export default JWTContext;
