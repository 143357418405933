import { JsonObject, JsonProperty } from 'json2typescript';
import { JsonSerializable } from 'models/serialization';

export interface ISellImport {
    company_slug: string;
    code: string;
    quantity: string;
}

@JsonObject('SellImportSetup')
export class SellImportSetup extends JsonSerializable<SellImportSetup> implements ISellImport {
    protected get classRef(): new () => SellImportSetup {
        return SellImportSetup;
    }

    @JsonProperty('company_slug', String, false)
    company_slug!: string;

    @JsonProperty('code', String, false)
    code!: string;

    @JsonProperty('quantity', String, false)
    quantity!: string;
}
