import { AutoFillParams } from 'components';
import _ from 'lodash';
import { JSONType, TakeoutAccountingData } from 'models';
import { AutoFillData } from 'models/AutoFillData/AutoFillData';
import { BalanceNewImportDataSource } from 'repository/BalanceImportDataSource';
import { OutputQueryParams } from '../type';
import { OutputImportParams } from './type';

export class TakeoutAccountingDataSource extends BalanceNewImportDataSource<OutputImportParams> {
    path: string = 'acc-take-out';

    public async getAll(params: OutputQueryParams) {
        const response = await this.http.instance.get('/api/new-balance/acc-take-out/', {
            params
        });
        return new TakeoutAccountingData().fromPaging<TakeoutAccountingData>(response.data);
    }

    public async update(data: Partial<TakeoutAccountingData>, params: OutputQueryParams) {
        return this.http.instance.put(
            `/api/new-balance/acc-take-out/${data.id}/`,
            _.omit(
                {
                    ...data,
                    ...params
                },
                'id'
            )
        );
    }

    public async delete(ids: string[]) {
        return this.http.instance.delete(`/api/new-balance/acc-take-out/bulk-delete/`, {
            data: { id_list: ids }
        });
    }

    public async insert(data: JSONType) {
        return this.http.instance.post(`/api/new-balance/acc-take-out/`, data);
    }

    public async getAutoFill(data: AutoFillParams) {
        const respone = await this.http.instance.post('/api/new-balance/acc-take-out/autofill/', data);
        return new AutoFillData().fromPaging<AutoFillData>(respone.data);
    }
}
