import MainLayout from 'layout/MainLayout';
import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';
import { PrivateRoute, RouteEnum } from './PrivateRoute';
const HomePage = Loadable(lazy(() => import('views/HomePage/HomePage')));
const HsPage = Loadable(lazy(() => import('views/HsPage/HsPage')));
const Classification = Loadable(lazy(() => import('views/Classsification/NavClassification')));
const SearchResultPage = Loadable(lazy(() => import('views/SearchResultPage/SearchResultPage')));
const SearchingCO = Loadable(lazy(()=> import('views/SearchingCO/index')));
const RegulationPage = Loadable(lazy(() => import('views/RegulationDetail/NavRegulationsDetail')));

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <HomePage />
        },
        {
            path: '/:id',
            element: <HsPage />
        },
        {
            path: '/classification/:id',
            element: <Classification />
        },
        {
            path: '/regulation/:id',
            element: <RegulationPage />
        },
        {
            path: '/search',
            element: <SearchResultPage />
        },
        {
            path: '/searching-co',
            element: <SearchingCO />
        },
        {
            path: '/data',
            element: <PrivateRoute routeType={RouteEnum.balanceSystem} />
        },
        {
            path: '/data/:tableId',
            element: <PrivateRoute routeType={RouteEnum.balanceSystem} />
        },
        {
            path: '/fta/bangke',
            element: <PrivateRoute routeType={RouteEnum.ftaInvoice} />
        },
        {
            path: '/fta/co-list',
            element: <PrivateRoute routeType={RouteEnum.ftaCOList} />
        },
        {
            path: '/fta/annex-10',
            element: <PrivateRoute routeType={RouteEnum.ftaANNEX} />
        },
        {
            path: '/fta/ecus-stock',
            element: <PrivateRoute routeType={RouteEnum.ftaEcusStock} />
        },
        {
            path: '/fta/co-form',
            element: <PrivateRoute routeType={RouteEnum.ftaEcusWordInvoice} />
        },
        {
            path: '/detail-page',
            element: <PrivateRoute routeType={RouteEnum.tableDetail} />
        },
        {
            path: '/detail-page/:tableType/:tableDetailId',
            element: <PrivateRoute routeType={RouteEnum.tableDetail} />
        },
        {
            path: '/user',
            element: <PrivateRoute routeType={RouteEnum.userPage} />
        },
        {
            path: '/company',
            element: <PrivateRoute routeType={RouteEnum.companyPage} />
        },
        {
            path: '/new-balance/management-code',
            element: <PrivateRoute routeType={RouteEnum.mappingCode} />
        },
        {
            path: '/new-balance/report-statement',
            element: <PrivateRoute routeType={RouteEnum.reportStatement} />
        }
    ]
};

export default MainRoutes;
