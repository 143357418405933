import { JsonObject, JsonProperty } from 'json2typescript';
import { JsonSerializable } from 'models/serialization';

export interface IAmaSetupDTO {
    company_slug: string;
    account_number: string;
    row_number: string;
    code: string;
    unit: string;
    total: string;
}

@JsonObject('AmaSetupDTO')
export class AmaSetupDTO extends JsonSerializable<AmaSetupDTO> implements IAmaSetupDTO {
    protected get classRef(): new () => AmaSetupDTO {
        return AmaSetupDTO;
    }

    @JsonProperty('company_slug', String, false)
    company_slug!: string;

    @JsonProperty('account_number', String, false)
    account_number!: string;

    @JsonProperty('code', String, false)
    code!: string;

    @JsonProperty('unit', String, false)
    unit!: string;

    @JsonProperty('total', String, false)
    total!: string;

    @JsonProperty('row_number', String, false)
    row_number!: string;
}
