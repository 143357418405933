import { SetupParams, SheetMeta, useCompanyFolderSlug } from 'components/BalanceImport';
import { voidCallback } from 'components/BalanceTable/TableInvoice';
import { useReportStatement15ARepo, useReportStatement15Repo } from 'di';
import _, { isNil } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import * as XLSX from 'xlsx';
import { StatementTypeEnum } from '../context';

export interface useReportStatement15ImportProps {
    statementType: string;
    statement15TypeCode: string;
    statement15ATypeCode: string;
    physicalCounting: string;
    setupSuccessful: boolean;
    setSetupSuccessful: voidCallback;
    year: string;
    setOpenModal: voidCallback;
    setRefresh: voidCallback;
}

function useReportStatement15Import({
    statementType,
    statement15TypeCode,
    statement15ATypeCode,
    physicalCounting,
    setupSuccessful,
    setSetupSuccessful,
    year,
    setOpenModal,
    setRefresh
}: useReportStatement15ImportProps) {
    const { company_slug, folder_slug } = useCompanyFolderSlug();
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [workBook, setWorkBook] = useState<XLSX.WorkBook | null>(null);
    const [selectedSheet, setSelectedSheet] = useState('');
    const [header, setHeader] = useState<string[] | undefined>(undefined);
    const [rowIndex, _setRowIndex] = useState<[number, number | undefined] | undefined>(undefined);
    const [endRow, setEndRow] = useState<number | undefined>(undefined);

    const [rawData, setRawData] = useState<null | any[][]>(null);

    const reportStatement15ARepo = useReportStatement15ARepo();
    const reportStatement15Repo = useReportStatement15Repo();

    const getFixedHeader = useCallback(async () => {
        if (statementType === StatementTypeEnum.statement15) return reportStatement15Repo.getFixedHeader();
        else return reportStatement15ARepo.getFixedHeader();
    }, [statementType]);

    const setupImport = useCallback(
        async (params: SetupParams) => {
            if (statementType === StatementTypeEnum.statement15) return reportStatement15Repo.setupImport(params);
            else return reportStatement15ARepo.setupImport(params);
        },
        [statementType]
    );

    const importForm = useCallback(
        async (data: any) => {
            console.log(data, statementType);
            if (statementType === StatementTypeEnum.statement15) return reportStatement15Repo.importData(data);
            else return reportStatement15ARepo.importData(data);
        },
        [statementType]
    );

    const setRowIndex = (from: number, to: number) => {
        _setRowIndex([from, to]);
    };

    const sheetMetaImportParams = useMemo<SheetMeta | undefined>(() => {
        if (_.some([selectedFile, selectedSheet, endRow, rowIndex], isNil)) return undefined;
        const [from, to] = rowIndex!;
        const draft: SheetMeta = {
            excel_file: selectedFile!,
            sheet_name: selectedSheet!,
            header_from: from,
            end_row: endRow!
        };
        if (from !== to && !!to) draft.header_to = to;
        return draft;
    }, [selectedFile, selectedSheet, rowIndex, endRow]);

    useEffect(() => {
        if (setupSuccessful && sheetMetaImportParams) {
            importForm({ ...sheetMetaImportParams, company_slug, folder_slug, year });
            setSetupSuccessful(false);
            // setRefresh(true);
            // setOpenModal(false);
        }
    }, [setupSuccessful, , company_slug, folder_slug, year, sheetMetaImportParams]);

    useEffect(() => {
        if (workBook) {
            const fiftyA = workBook.Sheets['15A'];
            const raw_data = XLSX.utils.sheet_to_json(fiftyA, { header: 1 });
            setRawData(raw_data as [][]);
        }
    }, [workBook]);

    return {
        workBook,
        setWorkBook,
        selectedFile,
        setSelectedFile,
        selectedSheet,
        setSelectedSheet,
        header,
        setHeader,
        setEndRow,
        setRowIndex,
        getFixedHeader,
        setupImport,
        rawData,
        setRawData
    };
}

export default useReportStatement15Import;
