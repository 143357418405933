// material-ui
import { Avatar, Box, Button, Menu, MenuItem, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// project imports
import LAYOUT_CONST from 'constant';
import useConfig from 'hooks/useConfig';
import LogoSection from '../LogoSection';
import LocalizationSection from './LocalizationSection';
import MobileSection from './MobileSection';
import ProfileSection from './ProfileSection';
import Search from './SearchSection/Search';

import { useState } from 'react';
import { useDispatch, useSelector } from 'store';
import { setYear } from 'store/slices/HsCode';
import { openDrawer } from 'store/slices/menu';

// assets
import { IconMenu2 } from '@tabler/icons';
import useAuth from 'hooks/useAuth';
import searchCover from 'assets/Search-Cover.png'; 

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const YearSelector = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const yearOpen = Boolean(open);
    const yearId = yearOpen ? 'year' : undefined;
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const dispatch = useDispatch();
    const { Year } = useSelector((state) => state.HsCode);
    const handleChangeYear = (year: string) => {
        localStorage.setItem('hsyear', year);
        dispatch(setYear(year));
        handleClose();
    };

    const years = ['2017', '2018', '2019', '2020', '2021', '2022', '2023'];

    return (
        <>
            <Button onClick={handleClick} size="small" sx={{ margin: '0 -4px 0 8px !important' }} disableRipple variant="outlined">
                {Year}
            </Button>
            <Menu
                id={yearId}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                slotProps={{
                    paper: {
                        sx: {
                            overflow: 'scroll',
                            mt: 0.5,
                            maxHeight: 124,
                            '&::-webkit-scrollbar': {
                                width: '16px'
                            }
                        }
                    }
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {years.map((year, index) => (
                    <MenuItem key={index} onClick={() => handleChangeYear(year)}>
                        {year}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};

const Header = () => {
    const theme = useTheme();
    const { isLoggedIn } = useAuth();
    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);

    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const { layout } = useConfig();

    return (
        <>
            <Box sx={{ display: { xs: 'block', sm: 'block', md: 'none' }, width: '100vw', margin: 0, padding: 0 }}>
                <MobileSection />
            </Box>
            {/* logo & toggler button */}
            <Box
                sx={{
                    display: { xs: 'none', sm: 'none', md: 'flex' },
                    width: '100vw',
                    height: 93,
                    paddingY: 0,
                    alignItems: 'center',
                    gap: 6,
                    justifyContent: 'space-evenly',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box component="span" sx={{ flexGrow: 1 }}>
                    <LogoSection />
                </Box>
                {(layout === LAYOUT_CONST.VERTICAL_LAYOUT || (layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && matchDownMd)) && isLoggedIn && (
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            overflow: 'hidden',
                            transition: 'all .2s ease-in-out',
                            background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
                            color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
                            '&:hover': {
                                background: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
                                color: theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.light
                            }
                        }}
                        onClick={() => dispatch(openDrawer(!drawerOpen))}
                        color="inherit"
                    >
                        <IconMenu2 stroke={1.5} size="20px" />
                    </Avatar>
                )}
                {/* <Box sx={{ flexGrow: 1 }} /> */}

                {!isLoggedIn ? (
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            height: '100%',
                            paddingY: 1,
                        }}
                    >
                        <Box component="span" sx={{  flexGrow: 1 }}>
                            <Search />
                        </Box>
                    </Box>
                ) : null}

                {/* <Box sx={{ flexGrow: 1 }} /> */}

                {/* live customization & localization */}
                <Box sx={{ display: 'flex' , alignItems: 'center', paddingRight: 2,}}>
                    <LocalizationSection />
                    <ProfileSection />
                </Box>

                {/* {!isLoggedIn ? <YearSelector /> : null} */}

                {/* notification & profile */}

                {/* mobile header */}

            </Box>



        </>
    );
};

export default Header;
