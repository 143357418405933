import { JsonObject, JsonProperty } from 'json2typescript';
import { JsonSerializable } from '../serialization';
export interface IBeginSetupDTO {
    company_slug: string;
    code: string;
    name: string;
    unit: string;
    begin_value: string;
}

@JsonObject('BeginSetupDTO')
export class BeginSetupDTO extends JsonSerializable<BeginSetupDTO> implements IBeginSetupDTO {
    protected get classRef(): new () => BeginSetupDTO {
        return BeginSetupDTO;
    }

    @JsonProperty('company_slug', String, false)
    company_slug!: string;

    @JsonProperty('item_type', String, false)
    item_type!: string;

    @JsonProperty('code', String, false)
    code!: string;

    @JsonProperty('begin_value', String, false)
    begin_value!: string;

    @JsonProperty('name', String, false)
    name!: string;

    @JsonProperty('unit', String, false)
    unit!: string;
}
