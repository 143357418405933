import { Http } from 'api';
import { AutoFillParams, SheetMeta } from 'components';
import _ from 'lodash';
import { JSONType } from 'models';
import { AutoFillData } from 'models/AutoFillData/AutoFillData';
import { ProductionData, ProductionSetupDTO } from 'models/ProductionTable';
import { BalanceImportDataSource } from 'repository/BalanceImportDataSource';
import { BalanceParams, ProductionQueryParams } from 'repository/type';

export class ProductionDataSource extends BalanceImportDataSource<SheetMeta & BalanceParams> {
    path: string = 'production';

    public async getAutoFill(data: AutoFillParams) {
        const respone = await this.http.instance.post('/api/balance/production/autofill/', data);
        return new AutoFillData().fromPaging<AutoFillData>(respone.data);
    }

    public async getAll(params: ProductionQueryParams) {
        const response = await this.http.instance.get('/api/balance/production/', {
            params
        });
        return new ProductionData().fromPaging<ProductionData>(response.data);
    }

    public async update(data: Partial<ProductionData>, params: ProductionQueryParams) {
        return this.http.instance.put(
            `/api/balance/production/${data.id}/`,
            _.omit(
                {
                    ...data,
                    ...params
                },
                'id'
            )
        );
    }

    public async delete(ids: string[]) {
        return this.http.instance.delete(`/api/balance/production/bulk-delete/`, {
            data: { id_list: ids }
        });
    }

    public async insert(data: JSONType) {
        return this.http.instance.post(`/api/balance/production/`, data);
    }
}
