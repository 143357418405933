import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
    Autocomplete,
    Box,
    Chip,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    MenuItem,
    Radio,
    RadioGroup,
    Stack,
    styled,
    Switch,
    TextField,
    Tooltip,
    Typography,
    Checkbox
} from '@mui/material';
import { setSelectedHs } from 'store/slices/HsCode';

import { AdvancedSearchHs, getProdClass, getSelectionByHs, SearchHs, SearchDescriptionNote } from 'api/HS/HsApi';
import FilterIcon from 'assets/filter.svg';
import Button from 'components/Button';
import Input from 'components/Input';
import Select from 'components/Select';
import { fieldLists } from 'constant/Homepage';
import { useHsContext } from 'contexts';
import useConfig from 'hooks/useConfig';
import useSnackBar, { SnackBarAlertEnum } from 'components/SnackBar/UISnackbar';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'store';
import { setSearchResult, setIsProductSearch } from 'store/slices/HsCode';
import SearchIcon from '@mui/icons-material/Search';
import { setisSearch } from 'store/slices/HsCode';
import { border, borderRadius, display, maxHeight } from '@mui/system';
import { setTitilePage } from 'store/slices/HsCode';
import { CircularUnderLoad } from './loading';
import { openDrawer } from 'store/slices/menu';
import Sidebar from 'layout/MainLayout/Sidebar';

function cleanString(input) {
    return input.replace(/^-+|-+$/g, '')
        .replace(/[-\s]+/g, ' ')
        .trim();
}

function countKeywordOccurrences(keyword, text) {
    if (!text) return 0;

    keyword = decodeURIComponent(keyword);

    const regex = new RegExp(`\\b${keyword}\\b`, 'gi');
    const matches = String(text).match(regex);

    return matches ? matches.length : 0;
}

// Hàm để đếm tổng số lần xuất hiện của các từ đơn trong keyword
function countSingleWordOccurrences(keyword, text) {
    if (!text) return 0;

    keyword = decodeURIComponent(keyword);

    const words = keyword.split(' '); // Tách keyword thành các từ đơn
    let totalOccurrences = 0;

    words.forEach((word) => {
        const regex = new RegExp(`\\b${word}\\b`, 'gi'); // Tạo regex cho từng từ đơn
        const matches = String(text).match(regex);
        totalOccurrences += matches ? matches.length : 0;
    });

    return totalOccurrences; // Trả về tổng số lần xuất hiện của các từ đơn
}
type SearchProps = {
    sideBar: boolean; // Declaring sideBar as a boolean
};

const Search: React.FC<SearchProps> = ({ sideBar }) => {
    const [advancedSearch, setAdvancedSearch] = useState(false);
    const { locale: lang } = useConfig();
    const { Year } = useHsContext();
    const { showSnackBar } = useSnackBar();
    const [selectedValue, setSelectedValue] = useState('option1'); // Khởi tạo selectedValue với giá trị 'option1'
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);

    const { handleSubmit, control, setValue, watch, getValues } = useForm({
        defaultValues: {
            search: '',
            types: 'hscode',
            dsf: 'description'
        }
    });
    const types = watch('types');
    const fieldState = getValues();
    const defaultProdClass = useMemo(() => ['Doc no', 'Doc date', 'Product name', 'Hs'], []);

    const handleUserKeyPress = useCallback((event) => {
        if (event.keyCode === 27) {
            setAdvancedSearch(false);
        }
    }, []);

    useEffect(() => {
        window.addEventListener('keydown', handleUserKeyPress);
        return () => window.removeEventListener('keydown', handleUserKeyPress);
    }, [handleUserKeyPress]);

    useEffect(() => {
        if (selectedValue) {
            const langDescription = `${lang}_description`;
            const langNotes = `${lang}_notes`;

            if (selectedValue === 'option1' && lang !== 'vi') {
                setValue('dsf', `${langDescription},${langNotes}`);
                setValue('fieldlist', '*');
            }

            if (selectedValue === 'option1' && lang === 'vi') {
                setValue('dsf', 'description,notes');
                setValue('fieldlist', '*');
            }

            // if (selectedValue === 'option2') {
            //     setValue('dsf', 'Name');
            //     setValue('fieldlist', ['Name', 'Regulation']);
            // }
            if (selectedValue === 'option2') {
                setValue('dsf', 'Product name');
                setValue('fieldlist', '*');
            }
        }
    }, [types, setValue, fieldState, defaultProdClass]);
    const onSubmit = async (data) => {
        dispatch(setSelectedHs(null));
        // setValue('keyword', data.search)
        data.keyword = data.search;
        // let search = encodeURIComponent(data?.keyword ? data.keyword.trim().replace(/\s+/g, ' ') || data.search.trim().replace(/\s+/g, ' '));
        let search = (
            (data?.keyword?.trim().replace(/\s+/g, ' ') || data?.search?.trim().replace(/\s+/g, ' ')) ?? ''
        );

        console.log(search)
        // let search = encodeURIComponent(
        //     data.search
        //   );
        let keyWord = data.search

        // const keyDecode = decodeURIComponent(decodeURIComponent(String(keyWord)))

        dispatch(setisSearch(search));

        const isRomanNumber = /^M{0,4}(CM|CD|D?C{0,3})(XC|XL|L?X{0,3})(IX|IV|V?I{0,3})$/.test(search);
        const isNumber = /^\d{1,8}$/.test(search.replaceAll('.', ''));

        let query = data?.keyword ? data.keyword.trim().replace(/\s+/g, ' ') : '';

        query = query === '' ? '*:*' : data.exact ? ['"', '"'].join(query) : query;

        let q = '';
        if (selectedValue === 'option1' && lang === 'vi') {
            q = `(description:${query} OR notes:${query})`;
        } else if (selectedValue === 'option1' && lang !== 'vi') {
            const langDescription = `${lang}_description`;
            const langNotes = `${lang}_notes`;
            q = `(${langDescription}:${query} OR ${langNotes}:${query})`;
        } else if (selectedValue === 'option2' && lang === 'vi') {
            q = `(product_name:${query} OR product_properties:${query} OR product_description:${query})`;
        } else if (selectedValue === 'option2' && (lang === 'ko' || lang === 'en')) {
            q = `(product_name:${query} OR product_properties:${query})`;
        }

        let type;

        if (selectedValue === 'option2') {
            type = `${lang}_prod_class`
        } else if (selectedValue === 'option1') {
            type = 'hscode' + Year
        }
        // const type = data.types === 'hscode' ? data.types + Year : data.types === 'prodclass' ? 'vi_prod_class' : data.types;


        const reprocessing = (data) => {
            let df = data.dsf.replaceAll(' ', '_').toLowerCase();
            let fl = data.fieldlist === '*'
                ? '*'
                : data.fieldlist.join(',').replaceAll(' ', '_').toLowerCase();
            if (lang !== 'vi' && data.types === 'hscode') {
                df = df.replace('description', `${lang}_description`);
                df = df.replace('notes', `${lang}_notes`);
                fl = fl.replace('description', `${lang}_description`);
                fl = fl.replace('notes', `${lang}_notes`);
            } else if (lang !== 'vi' && data.types === 'regulation') {
                const newLang = lang === 'ko' ? 'kr' : lang;
                df = df.replace('regulation', `${newLang}_regulation`);
                df = df.replace('description', `${newLang}_description`);
                fl = fl.replace('regulation', `${newLang}_regulation`);
                fl = fl.replace('description', `${newLang}_description`);
            }
            return { params: { df, fl, q }, type };
        };
        // try {
        if (!advancedSearch && search) {
            if (isRomanNumber || isNumber) {

                if (!search.includes('.') && search.length >= 4) {
                    search =
                        search.length === 4 && selectedValue === 'option1'
                            ? search.slice(0, 2) + '.' + search.slice(2, 4)
                            : search.length === 4 && selectedValue === 'option2'
                                ? search
                                : search.length > 4 && search.length < 7
                                    ? search.slice(0, 4) + '.' + search.slice(4, 6)
                                    : search.slice(0, 4) + '.' + search.slice(4, 6) + '.' + search.slice(6);
                }

                const searchResult = selectedValue === 'option1' ? await getSelectionByHs(search + ' hs:' + '*' + search + '*', Year) : await getProdClass('*' + search + '*', lang);

                console.log("Type is: " + type);
                if (selectedValue === 'option2') {
                    const type = reprocessing(data).type;
                    if (type.includes('prod_class')) dispatch(setIsProductSearch(true));
                    else dispatch(setIsProductSearch(false));
                    let prefixURL = '';
                    if (type.includes('prod_class')) prefixURL = '/classification/';
                    else prefixURL = '/';
                    // const searchResult = await AdvancedSearchHs(reprocessing(data));
                    dispatch(setSearchResult({ ...searchResult, type }));
                    // navigate('/search');
                    const productData = searchResult.response.docs[0];
                    if (String(productData.hs).length > 5) {
                        navigate(prefixURL + productData.hs.split('.')[0]);
                    } else {
                        navigate(prefixURL + productData.hs);
                    }
                    // const productData = searchResult.response.docs[0]

                    //     console.log(searchResult)
                    //     navigate('/'+productData.hs );
                    //     if (String(productData.hs).length > 2){
                    //         dispatch(setTitilePage(`Heading ${productData.hs}: ${cleanString((productData[`${lang}_description`] || productData.description).toLowerCase())}`));
                    //     }
                    //     else{
                    //         dispatch(setTitilePage(`HS code ${productData.hs}: ${cleanString((productData[`${lang}_description`] || productData.description).toLowerCase())}`))
                    //     }

                } else {
                    dispatch(setSearchResult(searchResult));
                    const productData = searchResult.response.docs[0]
                    if (String(productData.hs).length > 5) {
                        navigate('/' + productData.hs.split('.')[0]);
                    }
                    else {
                        navigate('/' + productData.hs);
                    }


                    if (String(productData.hs).length > 2) {

                        dispatch(setTitilePage(`${lang === 'vi' ? 'Nhóm ' : 'Heading '} ${productData.hs}: ${cleanString((productData[`${lang}_description`] || productData.description).toLowerCase())}`));
                    }
                    else {
                        dispatch(setTitilePage(`${lang === 'vi' ? 'HS code ' : 'HS code '} ${productData.hs}: ${cleanString((productData[`${lang}_description`] || productData.description).toLowerCase())}`))
                    }
                    dispatch(setIsProductSearch(false));

                }

            } else {

                if (selectedValue === 'option2') {

                    let searchResult;
                    const type = reprocessing(data).type;

                    try {
                        setLoading(true);
                        searchResult = await AdvancedSearchHs(reprocessing(data));
                    } catch (error) {
                        console.error("Error fetching search result:", error);
                    }
                    finally {
                        setLoading(false);
                    }
                    if (type.includes('prod_class')) dispatch(setIsProductSearch(true));
                    else dispatch(setIsProductSearch(false));
                    let prefixURL = '';
                    if (type.includes('prod_class')) prefixURL = '/classification/';
                    else prefixURL = '/';
                    // const searchResult = await AdvancedSearchHs(reprocessing(data));
                    dispatch(setSearchResult({ ...searchResult, type }));
                    // navigate('/search');
                    const productData = searchResult.response.docs[0];
                    if (String(productData.hs).length > 5) {
                        navigate(prefixURL + productData.hs.split('.')[0]);
                    } else {
                        navigate(prefixURL + productData.hs);
                    }
                    // const productData = searchResult.response.docs[0]

                    //     console.log(searchResult)
                    //     navigate('/'+productData.hs );
                    //     if (String(productData.hs).length > 2){
                    //         dispatch(setTitilePage(`Heading ${productData.hs}: ${cleanString((productData[`${lang}_description`] || productData.description).toLowerCase())}`));
                    //     }
                    //     else{
                    //         dispatch(setTitilePage(`HS code ${productData.hs}: ${cleanString((productData[`${lang}_description`] || productData.description).toLowerCase())}`))
                    //     }

                } else {
                    let searchResult;
                    try {
                        setLoading(true);
                        searchResult = await SearchDescriptionNote(Year, keyWord, lang);
                    } catch (error) {
                        console.error("Error fetching search result:", error);
                    }
                    finally {
                        setLoading(false);
                    }

                    dispatch(setIsProductSearch(false));

                    const docs = searchResult.response.docs;
                    const highlighting = searchResult.highlighting;
                    const keyword = keyWord;

                    const docsWithHighlighting = docs.map(doc => {
                        const id = doc.id;
                        const highlightedDescription = highlighting[id]?.description?.[0] || doc.description;

                        return {
                            ...doc,
                            highlightedDescription
                        };
                    });

                    docsWithHighlighting.sort((a, b) => {
                        // Đếm số lần xuất hiện của cả cụm từ keyword
                        const aKeywordCount = countKeywordOccurrences(keyword, a.notes || '') + countKeywordOccurrences(keyword, a.highlightedDescription);
                        const bKeywordCount = countKeywordOccurrences(keyword, b.notes || '') + countKeywordOccurrences(keyword, b.highlightedDescription);

                        // Nếu số lần xuất hiện của cả cụm từ keyword bằng nhau, đếm số lần xuất hiện của các từ đơn
                        if (aKeywordCount === bKeywordCount) {
                            const aSingleWordCount = countSingleWordOccurrences(keyword, a.notes || '') + countSingleWordOccurrences(keyword, a.highlightedDescription);
                            const bSingleWordCount = countSingleWordOccurrences(keyword, b.notes || '') + countSingleWordOccurrences(keyword, b.highlightedDescription);

                            return bSingleWordCount - aSingleWordCount; // Sắp xếp theo số lần xuất hiện của các từ đơn
                        }

                        return bKeywordCount - aKeywordCount; // Sắp xếp theo số lần xuất hiện của cả cụm từ keyword
                    });

                    const sortedDocs = docsWithHighlighting.map(({ highlightedDescription, ...doc }) => doc);

                    const sortedSearchResult = {
                        ...searchResult,
                        response: {
                            ...searchResult.response,
                            docs: sortedDocs
                        }
                    };

                    dispatch(setSearchResult(sortedSearchResult));
                    // console.log("kienle tim kiem" + sortedSearchResult.response.docs[0].hs)
                    const productData = sortedSearchResult.response.docs[0]
                    if (String(productData.hs).length > 5) {
                        navigate('/' + productData.hs.split('.')[0]);
                    }
                    else {
                        navigate('/' + productData.hs);
                    }
                    if (String(productData.hs).length > 2) {
                        dispatch(setTitilePage(`${lang === 'vi' ? 'Nhóm ' : 'Heading '}  ${productData.hs}: ${cleanString((productData[`${lang}_description`] || productData.description).toLowerCase())}`));
                    }
                    else {
                        dispatch(setTitilePage(`${lang === 'vi' ? 'HS code ' : 'HS code '} ${productData.hs}: ${cleanString((productData[`${lang}_description`] || productData.description).toLowerCase())}`))
                    }
                }
            }
        } else if (advancedSearch) {
            const searchResult = await AdvancedSearchHs(reprocessing(data));
            dispatch(setIsProductSearch(false));

            dispatch(setSearchResult({ ...searchResult, type }));
            const productData = searchResult.response.docs[0]

            if (String(productData.hs).length > 5) {
                navigate('/' + productData.hs.split('.')[0]);
            }
            else {
                navigate('/' + productData.hs);
            }
            if (String(productData.hs).length > 2) {
                dispatch(setTitilePage(`${lang === 'vi' ? 'Nhóm ' : 'Heading '}  ${productData.hs}: ${cleanString((productData[`${lang}_description`] || productData.description).toLowerCase())}`));
            }
            else {
                dispatch(setTitilePage(`${lang === 'vi' ? 'HS code ' : 'HS code'} ${productData.hs}: ${cleanString((productData[`${lang}_description`] || productData.description).toLowerCase())}`))
            }
            // navigate('/search');
        }
        dispatch(openDrawer(false));
        // } catch (error) {
        //     // toast.error(
        //     //   <ToastNotification
        //     //     message={error.response.data.error.msg.split(':')[1]}
        //     //   />
        //     // )
        //     showSnackBar(error.response.data.error.msg.split(':')[1], SnackBarAlertEnum.error);
        // }
        return;
    };


    const handleCoboboxSearch = (value) => {
        setSelectedValue(value); // Cập nhật giá trị được chọn
    };

    const labels = {
        en: {
            label1: 'Find HS Code by',
            label2: 'Tariff Schedule',
            label3: 'Classification',
            label4: 'Exact keywords',
            label5: 'Enter product name or HS code',
            label6: 'Search',
        },
        vi: {
            label1: 'Tra cứu mã HS theo',
            label2: 'Biểu thuế',
            label3: 'Quyết định phân loại',
            label4: 'Chính xác từ khóa',
            label5: 'Nhập tên hàng hoặc mã HS',
            label6: 'Tìm kiếm',
        },
        ko: {
            label1: 'HS 검색',
            label2: '관세율표',
            label3: '분류 결정',
            label4: '검색어 일치',
            label5: 'HS 코드 또는 제품명 입력',
            label6: '검색',
        }
    };

    return (

        <Box
            sx={{
                height: '100%',
                maxHeight: '100%', // Constrain maximum height
                width: '100%',
                maxWidth: '100%',
                form: {
                    padding: { xs: 1, md: 0 },
                    borderRadius: '4px',
                    background: '#fffff',
                    width: '100%',
                    maxWidth: '100%',
                    maxHeight: '100%',
                    height: '100%',
                    zIndex: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignContent: 'center',
                    justifyContent: 'center',
                    alignItems: 'stretch',
                },
            }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box sx={{
                    height: '100%', width: '100%', display: "flex",
                    alignItems: "center",
                    justifyContent: 'center',
                    flexDirection: 'column',
                }}>
                    {sideBar ?
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '100%',
                                gap: { xs: 0, md: 2 },
                                paddingY: { xs: 2, md: 1 },
                            }}
                        >
                            <Typography
                                variant="body1"
                                sx={{
                                    color: { xs: 'white', sm: 'white', md: '#2196f3' },
                                    minWidth: '100px',
                                    fontWeight: 'bold',
                                    whiteSpace: 'nowrap', // Prevent wrapping
                                    marginRight: { xs: '5px', sm: 2 },
                                    marginTop: {xs:'1px', sm: 0},
                                    fontSize: { xs: '12px', sm: '1.19em' },
                                }}
                            >
                                {labels[lang].label1}{" "}
                            </Typography>

                            <CustomRadioButton
                                sideBar={true}
                                checked={selectedValue === 'option1'}
                                onChange={() => handleCoboboxSearch('option1')}
                                label={labels[lang].label2}
                            />
                            <CustomRadioButton
                                sideBar={true}
                                checked={selectedValue === 'option2'}
                                onChange={() => handleCoboboxSearch('option2')}
                                label={labels[lang].label3}
                            />
                        </Box>

                        :
                        <>
                            <Box
                                sx={{
                                    display: { xs: 'flex', sm: 'flex', md: 'none' },
                                    flexDirection: { xs: 'column', sm: 'column', md: 'row' },
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: '100%',
                                    gap: { xs: 0, sm: 0, md: 0 },
                                }}
                            >
                                <Typography
                                    variant="body1"
                                    sx={{
                                        color: { xs: 'white', sm: 'white', md: '#2196f3' },
                                        minWidth: '100px',
                                        fontWeight: 'bold',
                                        minWidth: { xs: 'auot', md: '120px' },
                                        fontSize: { xs: '1.3em', sm: '1.1em' }, // Responsive font size
                                    }}
                                >
                                    {labels[lang].label1}{" "}
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignContent: 'center',
                                        justifyContent: { xs: 'space-around', sm: 'space-around', md: 'center' },
                                        width: '100%',
                                        paddingY: { xs: 2, md: 1 },
                                    }}
                                >
                                    <CustomRadioButton
                                        checked={selectedValue === 'option1'}
                                        onChange={() => handleCoboboxSearch('option1')}
                                        label={labels[lang].label2}
                                    />
                                    <CustomRadioButton
                                        checked={selectedValue === 'option2'}
                                        onChange={() => handleCoboboxSearch('option2')}
                                        label={labels[lang].label3}
                                    />
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    display: { xs: 'none', sm: 'none', md: 'flex' },
                                    flexDirection: { xs: 'column', sm: 'column', md: 'row' },
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: '100%',
                                    gap: { md: 4 },
                                }}
                            >
                                <Typography
                                    variant="body1"
                                    sx={{
                                        color: { xs: 'white', sm: 'white', md: '#2196f3' },
                                        minWidth: '100px',
                                        fontWeight: 'bold',
                                        whiteSpace: 'nowrap',
                                        fontSize: { md: '1.3em' }, // Responsive font size
                                    }}
                                >
                                    {labels[lang].label1}{" "}
                                </Typography>

                                <CustomRadioButton
                                    checked={selectedValue === 'option1'}
                                    onChange={() => handleCoboboxSearch('option1')}
                                    label={labels[lang].label2}
                                />
                                <CustomRadioButton
                                    checked={selectedValue === 'option2'}
                                    onChange={() => handleCoboboxSearch('option2')}
                                    label={labels[lang].label3}
                                />

                            </Box>

                        </>

                    }


                    {/* Input Field */}
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2, // Adds spacing between input and button
                            width: '100%',
                            paddingX: 1,
                        }}>

                        <Controller
                            name="search"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <StyleInput
                                    placeholder={labels[lang].label5}
                                    fullWidth
                                    value={value}
                                    onChange={onChange}
                                    autoComplete="off"
                                    disabled={!!advancedSearch}
                                    InputProps={{
                                        startAdornment: (
                                            <IconButton type="submit" size="small" sx={{ ml: -1.5 }}>
                                                <SearchIcon sx={{ color: '#2196f3', fontSize: 35 }} />
                                            </IconButton>
                                        ),
                                        endAdornment: (
                                            <>
                                                {loading && <CircularUnderLoad />}
                                                <Grid
                                                    item
                                                    xs={2}
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        mx: 1,
                                                    }}
                                                >
                                                    <Stack direction="row" spacing={1} alignItems="center">
                                                        <Controller
                                                            control={control}
                                                            name="exact"
                                                            defaultValue={false}
                                                            render={({ field: { onChange } }) => (
                                                                <div>
                                                                    <StyleSwitch onChange={onChange} />
                                                                </div>
                                                            )}
                                                        />
                                                        <StyleTypography
                                                            sx={{
                                                                color: '#2196f3',
                                                                width: 50,
                                                                fontSize: '10px',
                                                                fontWeight: 'normal',
                                                                lineHeight: '1.2',
                                                            }}
                                                        >
                                                            {labels[lang].label4}
                                                        </StyleTypography>
                                                    </Stack>
                                                </Grid>
                                            </>
                                        ),
                                    }}
                                />
                            )}
                        />

                        {/* Submit Button */}
                        <StyleButton
                            type="submit"
                            sx={{
                                display: { xs: 'none', md: 'block' },
                                backgroundColor: '#2196f3',
                                borderRadius: '4px',
                                minWidth: 80,
                                minHeight: 50,
                                width: { sx: '5%', sm: '20%' },
                                whiteSpace: 'nowrap',
                            }}
                        >
                            {labels[lang].label6}
                        </StyleButton>
                    </Box>
                </Box>
            </form>
        </Box>


    );
};
export default Search;
const StyleInput = styled(Input)`
    background: #ffffff;
    border-radius: 4px;
    & .MuiInputBase-root {
        border-radius: 4px; 
        background: #f2f7ff; 
    }

    & .MuiInputAdornment-root {
        border-radius: 4px; 
    }
`;
const StyleButton = styled(Button)`
    border-radius: 10px;
    height: 45px;
    width: 100%;
    max-width: 225px;
    min-width: 100px;
    background-color: #006bd6;
`;
const StyleTypography = styled(Typography)(({ theme }) => ({
    fontSize: '14px',
    fontWeight: 700,
    color: theme.palette.primary.main,
    lineHeight: '22px'
}));
const StyleRadioGroup = styled(RadioGroup)`
    label {
        margin-left: unset;
    }

    span.MuiTypography-root {
        padding: 4px 16px;
        font-weight: 700;
        font-size: 18px;
        line-height: 20px;
        text-align: center;
        color: rgb(189, 189, 189);
        border-bottom: 1px solid #d9d9d9; /* Đường gạch dưới bạc */
        border-radius: 0;
        border-top: none;
        border-left: none;
        border-right: none;
    }

    span.MuiRadio-root {
        display: none;
    }

    span.Mui-checked + span {
        color: ${(props) => props.theme.palette.primary.main}; /* Đổi màu chữ */
        border-bottom: 2px solid ${(props) => props.theme.palette.primary.main}; /* Đổi màu đường gạch dưới */
        background: none; /* Xóa background */
    }
`;


const StyleSwitch = styled(Switch)(({ theme }) => ({
    width: 44,
    height: 24,
    padding: 0,
    display: 'flex',
    borderRadius: '10px',

    '&.Mui-checked .MuiSwitch-track': {
        backgroundColor: 'red', // Khi bật
    },
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)'
        }
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(20px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: '#a3ccec'
            }
        }
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        backgroundColor: '#2196f3',
        width: 20,
        height: 20,
        borderRadius: 10,
        transition: theme.transitions.create(['width'], {
            duration: 200
        })
    },
    '& .MuiSwitch-track': {

        borderRadius: '4px',
        backgroundColor: '#d2d7db',
        opacity: 1,

        boxSizing: 'border-box'
    }
}));
const StyleAutocomplete = styled(Autocomplete)`
    fieldset {
        border-color: #bdbdbd !important;
        border-width: 1px !important;
    }
    div.MuiOutlinedInput-root {
        padding-top: 8.5px;
        padding-bottom: 8.5px;
    }
`;
const StyleChip = styled(Chip)`
    border-radius: 4px;
`;



const CustomRadioButton = ({ checked, onChange, label,sideBar }) => (
    <Box
        onClick={onChange} // Khi nhấp vào box, sẽ gọi hàm onChange
        sx={{
            mr: sideBar? 1:0,
            ml: 0,
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            borderRadius: '10px',
            padding: { xs: 0, sm: '3px' },
            transition: 'background-color 0.3s',
        }}
    >
        <Box
            sx={{
                width: sideBar ? {xs: '15px'} : '20px',
                height: sideBar ? {xs:'15px'} : '20px',
                borderRadius: '50%',
                backgroundColor: checked ? '#2196f3' : '#d9d9d9',
                border: '2px solid #d9d9d9',
                marginRight: sideBar ? { xs: '2px', sm: '8px' } : { xs: '6px', sm: '8px' },
            }}
        />
        <Typography sx={{ fontSize: sideBar ? { xs: '11px', sm: '16px' } : { xs: '17px', sm: '1.21em' }, whiteSpace: 'nowrap', fontWeight: 'bold', color: { xs: 'white', sm: 'white', md: '#2196f3' }}}>{label}</Typography>
    </Box>
);

