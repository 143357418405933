import { Button, Switch, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { OutlinedButton } from 'components/Button';
import { IEcusData } from 'models';
import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setLoading } from 'store/Global';
import { CheckPropertyInterface } from '../TableInvoiceWord';
import { InvoiceModalContext } from './InvoiceModalProvider';

function InvoiceWordStep3() {
    const { uniqHSMap, SubmitExtraStepHandler } = useContext(InvoiceModalContext);

    const [step3HsMap, setStep3HsMap] = useState<null | CheckPropertyInterface[]>(null);

    useEffect(() => {
        if (uniqHSMap) {
            uniqHSMap.forEach((value: CheckPropertyInterface[], key: string) => {
                setStep3HsMap((prev: null | CheckPropertyInterface[]) => {
                    if (prev === null) return [...value];
                    else return [...prev, ...value];
                });
            });
        }
    }, [uniqHSMap]);

    return (
        <>
            {step3HsMap && (
                <Box sx={{ display: 'flex', flexDirection: 'column', height: '600px', overflowY: 'scroll' }}>
                    {step3HsMap.map((item: CheckPropertyInterface, index: number) => (
                        <Row key={index} item={item.item} combine={item.combine} />
                    ))}
                    <OutlinedButton
                        onClick={() => {
                            SubmitExtraStepHandler();
                        }}
                    >
                        Submit
                    </OutlinedButton>
                </Box>
            )}
        </>
    );
}

function Row({ item, combine }: { item: IEcusData; combine: boolean }) {
    const [originate, setOriginate] = useState(item.origin_criteria ?? '');
    const [isCombine, setIsCombine] = useState(combine);
    const [submit, setSubmit] = useState(false);
    const dispatch = useDispatch();
    const { OriginHandler } = useContext(InvoiceModalContext);

    useEffect(() => {
        if (submit) {
            dispatch(setLoading(true));
            OriginHandler(item.id, item.hs, originate, isCombine);

            setTimeout(() => {
                setSubmit(false);
                dispatch(setLoading(false));
            }, 1000);
        }
    }, [originate, isCombine, submit]);

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
            <Box sx={{ width: '100px', height: '40px', textOverflow: 'ellipsis' }}>{item.name}</Box>
            <Box sx={{ width: '100px', height: '40px', textOverflow: 'ellipsis' }}>{item.hs}</Box>
            <TextField
                value={originate}
                onChange={(event: any) => {
                    setOriginate(event.target.value);
                }}
            />
            <Box sx={{}}>
                On
                <Switch
                    checked={isCombine}
                    onChange={() => {
                        setIsCombine((prev) => !prev);
                    }}
                />
                Off
            </Box>
            <Box>
                <Button
                    onClick={() => {
                        setSubmit(true);
                    }}
                >
                    Apply
                </Button>
            </Box>
        </Box>
    );
}

export default InvoiceWordStep3;
