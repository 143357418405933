import { initializeApp } from 'firebase/app';

const firebaseConfig = {
    apiKey: 'AIzaSyBqIovN7hcc03ZuRvTo-RMmShPsUWML3Jw',
    authDomain: 'eximhow-beta.firebaseapp.com',
    projectId: 'eximhow-beta',
    storageBucket: 'eximhow-beta.appspot.com',
    messagingSenderId: '521122507963',
    appId: '1:521122507963:web:985734f98815a7cf76cd9e'
};

export const firApp = initializeApp(firebaseConfig);
