import { Box } from '@mui/material';
import { FormTypeEnum } from 'components/BalanceTable/TableInvoice/InvoiceProvider';
import { useContext } from 'react';
import { InvoiceWordContext } from '..';
import InvoiceWordStep1 from '../InvoiceWordModal/InvoiceWordStep1';
import InvoiceSheetImport from './InvoiceSheetImport';
import InvoiceSheetStep from './InvoiceSheetStep/InvoiceSheetStep';
import InvoiceSheetStepButton from './InvoiceSheetStep/InvoiceSheetStepButton';
import InvoiceSheetStep2 from './InvoiceSheetStep2';
import InvoiceSheetStep3 from './InvoiceSheetStep3';
import InvoiceSheetStep4 from './InvoiceSheetStep4';
import InvoiceSheetStep6 from './InvoiceSheetStep6';

function InvoiceSheetStepFormVJ({ step }: { step: number }) {
    switch (step) {
        case 0:
            return <InvoiceWordStep1 />;
        case 1:
            return <InvoiceSheetImport />;
        case 2:
            return <InvoiceSheetStep3 />;
        case 3:
            return <InvoiceSheetStep4 />;
        case 4:
            return <InvoiceSheetStep6 />;
        case 5:
            return <InvoiceSheetStep2 />;
        default:
            return <></>;
    }
}

function InvoiceSheetStepFormAC({ step }: { step: number }) {
    console.log(step);
    switch (step) {
        case 0:
            return <InvoiceWordStep1 />;
        case 1:
            return <InvoiceSheetImport />;
        case 2:
            return <InvoiceSheetStep3 />;
        case 3:
            return <InvoiceSheetStep4 />;
        case 4:
            return <InvoiceSheetStep2 />;

        default:
            return <></>;
    }
}

function InvoiceSheetPreview({ step, wordFormType }: { step: number; wordFormType: FormTypeEnum }) {
    switch (wordFormType) {
        // case FormTypeEnum.VJ || FormTypeEnum.AJ:
        //     return InvoiceSheetStepFormVJ({ step });
        default:
            return InvoiceSheetStepFormAC({ step });
    }
}

function InvoiceSheetModal() {
    const { sheetActiveStep, wordFormType } = useContext(InvoiceWordContext);

    return (
        <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', padding: '6px 14px', gap: '12px' }}>
            <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <InvoiceSheetStep />
            </Box>
            <Box sx={{ flex: 16, height: '80%' }}>
                <InvoiceSheetPreview step={sheetActiveStep} wordFormType={wordFormType} />
            </Box>
            <Box sx={{ flex: 1 }}>
                <InvoiceSheetStepButton />
            </Box>
        </Box>
    );
}

export default InvoiceSheetModal;
