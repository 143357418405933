import { JsonProperty, JsonObject } from 'json2typescript';

import { JsonSerializable } from 'models/serialization';
import { BangKeExcelRowData } from './BangKeExcelRowData';
import { string } from 'yup';

export interface BangKeExcelDataPriceInterface {
    type: string;
    value: number;
    percent: number;
}

export interface BangKeExcelDataInterface {
    fulfill_criteria?: string;
    price: BangKeExcelDataPriceInterface;
    sum_of_no_fta_origin_cost: number;
    sum_of_has_fta_origin_cost: number;
    tax_code: string;
    account_number: string;
    total: number;
    id: string;
    results: BangKeExcelRowData[];
}

@JsonObject('BangKeExcelData')
export class BangKeExcelData extends JsonSerializable<BangKeExcelData> implements BangKeExcelDataInterface {
    protected get classRef(): new () => BangKeExcelData {
        return BangKeExcelData;
    }

    @JsonProperty('id', String, true)
    id: string = '';

    @JsonProperty('fulfill_criteria', String, true)
    fulfill_criteria: string = '';

    @JsonProperty('tax_code', String, true)
    tax_code: string = '';

    @JsonProperty('account_number', String, true)
    account_number: string = '';

    @JsonProperty('price', Object, true)
    price: BangKeExcelDataPriceInterface = {
        type: '',
        value: 0,
        percent: 0
    };

    @JsonProperty('sum_of_no_fta_origin_cost', Number, true)
    sum_of_no_fta_origin_cost: number = 0;

    @JsonProperty('sum_of_has_fta_origin_cost', Number, true)
    sum_of_has_fta_origin_cost: number = 0;

    @JsonProperty('total', Number, true)
    total: number = 0;

    @JsonProperty('results', [BangKeExcelRowData], true)
    results: [] = [];
}
