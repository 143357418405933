import { PSRDataInterface } from 'models/PSR';
import { useContext, useEffect, useMemo, useState } from 'react';
import { ConvertFormType, FormTypeEnum, InvoiceContext } from '../InvoiceProvider';
import { FormularFormProps } from './FormularFormEXW';
import { FormCFR, FormCIF, FormCIP, FormCPT, FormDAP, FormDDP, FormDPU, FormEXW, FormFAS, FormFCA, FormFOB } from './OtherFormLists';
import { DropDownFOBInsertTypeEnum } from './DropDownFormulars';
import { BangKeDataInterface } from 'models/BangKe';
import _ from 'lodash';

export function ConvertFOBOption(insertType: DropDownFOBInsertTypeEnum, formType: FormTypeEnum) {
    switch (insertType) {
        case DropDownFOBInsertTypeEnum.formular:
            return ConvertFormType(formType);
        default:
            return DropDownFOBInsertTypeEnum.user_input;
    }
}

export function RevertBangKeDataFOBOption(opt: string) {
    switch (opt) {
        case 'userInput':
            return DropDownFOBInsertTypeEnum.user_input;
        default:
            return DropDownFOBInsertTypeEnum.formular;
    }
}

const FormularFormOther = ({ item, index, bangKeData }: FormularFormProps) => {
    const { psrData, formType } = useContext(InvoiceContext);
    const [checkBangKeData, setCheckBangKeData] = useState<BangKeDataInterface[] | null>(null);

    const psrItem = useMemo(() => {
        const itemHsCode = item.hs.slice(0, 6);
        if (psrData && formType) {
            return psrData.filter((psrItem: PSRDataInterface) => {
                if (psrItem.hs === itemHsCode) {
                    //@ts-ignore
                    return psrItem;
                }
            })[0];
        } else return null;
    }, [item, psrData, formType]);

    const bangKeItem = useMemo(() => {
        return checkBangKeData ? checkBangKeData[index] : null;
    }, [checkBangKeData, index]);

    useEffect(() => {
        if (bangKeData) {
            setCheckBangKeData((prev: BangKeDataInterface[] | null) => {
                if (prev === null) return bangKeData;
                else {
                    if (_.isEqual(prev, bangKeData)) return prev;
                    else return bangKeData;
                }
            });
        }
    }, [bangKeData, checkBangKeData]);

    switch (item.invoice_price_condition) {
        case 'EXW':
            return <FormEXW key={item.id} item={item} psrItem={psrItem} index={index + 1} bangKeItem={bangKeItem} />;
        case 'FCA':
            return <FormFCA key={item.id} item={item} psrItem={psrItem} index={index + 1} bangKeItem={bangKeItem} />;
        case 'FAS':
            return <FormFAS key={item.id} item={item} psrItem={psrItem} index={index + 1} bangKeItem={bangKeItem} />;
        case 'CFR':
            return <FormCFR key={item.id} item={item} psrItem={psrItem} index={index + 1} bangKeItem={bangKeItem} />;
        case 'CIF':
            return <FormCIF key={item.id} item={item} psrItem={psrItem} index={index + 1} bangKeItem={bangKeItem} />;
        case 'CPT':
            return <FormCPT key={item.id} item={item} psrItem={psrItem} index={index + 1} bangKeItem={bangKeItem} />;
        case 'CIP':
            return <FormCIP key={item.id} item={item} psrItem={psrItem} index={index + 1} bangKeItem={bangKeItem} />;
        case 'DAP':
            return <FormDAP key={item.id} item={item} psrItem={psrItem} index={index + 1} bangKeItem={bangKeItem} />;
        case 'DPU':
            return <FormDPU key={item.id} item={item} psrItem={psrItem} index={index + 1} bangKeItem={bangKeItem} />;
        case 'DDP':
            return <FormDDP key={item.id} item={item} psrItem={psrItem} index={index + 1} bangKeItem={bangKeItem} />;

        default:
            return <FormFOB item={item} psrItem={psrItem} index={index + 1} bangKeItem={bangKeItem} />;
    }
};

export default FormularFormOther;
