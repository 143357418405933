import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

// material-ui
import {
    Box,
    Chip,
    ClickAwayListener,
    FormControl,
    FormControlLabel,
    FormLabel,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    PaletteMode,
    Paper,
    Popper,
    Radio,
    RadioGroup,
    Avatar,
    Typography
} from '@mui/material';

import { useTheme } from '@mui/material/styles';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
// third-party
import { FormattedMessage } from 'react-intl';

// project imports
import useAuth from 'hooks/useAuth';
import { useDispatch } from 'store';
import { openDrawer } from 'store/slices/menu';
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';

// assets
import { IconLogout, IconSettings } from '@tabler/icons';
import useSnackBar, { SnackBarAlertEnum } from 'components/SnackBar/UISnackbar';
import useConfig from 'hooks/useConfig';

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
    const theme = useTheme();
    const { borderRadius } = useConfig();
    const navigate = useNavigate();

    const [sdm, setSdm] = useState(true);
    const [value, setValue] = useState('');
    const [notification, setNotification] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const { logout, isLoggedIn } = useAuth();
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const { onChangeMenuType, navType } = useConfig();
    const anchorRef = useRef<any>(null);
    const { showSnackBar } = useSnackBar();
    const handleLogout = async () => {
        try {
            logout();
            dispatch(openDrawer(!false));
        } catch (err) {
            if (typeof err === 'string') {
                showSnackBar(err, SnackBarAlertEnum.error);
            }
        }
    };

    const handleListItemClick = (event: React.MouseEvent<HTMLDivElement>, index: number, route: string = '') => {
        setSelectedIndex(index);
        handleClose(event);

        if (route && route !== '') {
            navigate(route);
        }
    };
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    const handleClose = (event: React.MouseEvent<HTMLDivElement> | MouseEvent | TouchEvent) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };
    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    const handleClick = () => {
        window.location.href = 'https://services.eximhow.com/';
    };
    return (
        <>
            {isLoggedIn ? (
                <>
                    
                    <Chip
                        sx={{
                            height: '48px',
                            alignItems: 'center',
                            borderRadius: '27px',
                            transition: 'all .2s ease-in-out',
                            borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                            backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                            '&[aria-controls="menu-list-grow"], &:hover': {
                                borderColor: theme.palette.primary.main,
                                background: `${theme.palette.primary.main}!important`,
                                color: theme.palette.primary.light,
                                '& svg': {
                                    stroke: theme.palette.primary.light
                                }
                            },
                            '& .MuiChip-label': {
                                lineHeight: 0
                            }
                        }}
                        label={<IconSettings stroke={1.5} size="1.5rem" color={theme.palette.primary.main} />}
                        variant="outlined"
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}
                        color="primary"
                        aria-label="user-account"
                    />
                    <Popper
                        placement="bottom"
                        open={open}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition
                        disablePortal
                        modifiers={[
                            {
                                name: 'offset',
                                options: {
                                    offset: [0, 4]
                                }
                            }
                        ]}
                    >
                        {({ TransitionProps }) => (
                            <ClickAwayListener onClickAway={handleClose}>
                                <Transitions in={open} {...TransitionProps}>
                                    <Paper>
                                        {open && (
                                            <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                                <Box sx={{ padding: 1 }}>
                                                    <List
                                                        component="nav"
                                                        sx={{
                                                            width: '100%',
                                                            maxWidth: 220,
                                                            minWidth: 200,
                                                            backgroundColor: theme.palette.background.paper,
                                                            borderRadius: '10px',
                                                            [theme.breakpoints.down('md')]: {
                                                                minWidth: '100%'
                                                            },
                                                            '& .MuiListItemButton-root': {
                                                                mt: 0.5
                                                            }
                                                        }}
                                                    >
                                                        <ListItemButton
                                                            sx={{ borderRadius: `${borderRadius}px` }}
                                                            selected={selectedIndex === 4}
                                                        >
                                                            <div>
                                                                <FormControl component="fieldset">
                                                                    <FormLabel component="legend">Screen mode</FormLabel>
                                                                    <RadioGroup
                                                                        row
                                                                        aria-label="layout"
                                                                        value={navType}
                                                                        onChange={(e) => onChangeMenuType(e.target.value as PaletteMode)}
                                                                        name="row-radio-buttons-group"
                                                                    >
                                                                        <FormControlLabel
                                                                            value="light"
                                                                            control={<Radio />}
                                                                            label="Light"
                                                                            sx={{
                                                                                '& .MuiSvgIcon-root': { fontSize: 22 },
                                                                                '& .MuiFormControlLabel-label': {
                                                                                    color: theme.palette.grey[900]
                                                                                }
                                                                            }}
                                                                        />
                                                                        <FormControlLabel
                                                                            value="dark"
                                                                            control={<Radio />}
                                                                            label="Dark"
                                                                            sx={{
                                                                                '& .MuiSvgIcon-root': { fontSize: 22 },
                                                                                '& .MuiFormControlLabel-label': {
                                                                                    color: theme.palette.grey[900]
                                                                                }
                                                                            }}
                                                                        />
                                                                    </RadioGroup>
                                                                </FormControl>
                                                            </div>
                                                        </ListItemButton>
                                                        <ListItemButton
                                                            sx={{ borderRadius: `${borderRadius}px` }}
                                                            selected={selectedIndex === 4}
                                                            onClick={handleLogout}
                                                        >
                                                            <ListItemIcon>
                                                                <IconLogout stroke={1.5} size="1.3rem" />
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                primary={
                                                                    <Typography variant="body2">
                                                                        <FormattedMessage id="logout" />
                                                                    </Typography>
                                                                }
                                                            />
                                                        </ListItemButton>
                                                    </List>
                                                </Box>
                                            </MainCard>
                                        )}
                                    </Paper>
                                </Transitions>
                            </ClickAwayListener>
                        )}
                    </Popper>
                </>
            ) : (
                <div>
                    
                        <ListItemButton
                            sx={{
                                borderRadius: `${borderRadius}px`, // Bo góc cho nút
                                width: 'auto',                    
                                padding: '6px 12px', 
                                ml: -1,
                                backgroundColor: '#2196f3',
                                '&:hover': {
                                    backgroundColor: '#aed9fb', // Màu nền khi hover
                                },
                            }}
                            selected={selectedIndex === 4}
                            component={Link}
                            onClick={handleClick}
                            // to="/login"
                        >
                            <ListItemText
                                primary={
                                    <Typography variant="body2" sx={{ fontSize: '1rem', color: 'white', textAlign: 'center', whiteSpace: 'nowrap' }}>
                                        <FormattedMessage id="login" />
                                    </Typography>
                                }
                            />
                        </ListItemButton>
                    
                </div>
            )}
        </>
    );
};

export default ProfileSection;
