import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { Allotment } from 'allotment';
import 'allotment/dist/style.css';
import { $Table, TableContext, useCompanyFolderSlug, useManipulateTable, useQueryTable } from 'components';
import { $TableForm } from 'components/Table/TableForm/$TableForm';
import { TableType } from 'components/Table/TableForm/Type';
import { AutoFillParams, ColumnMeta, ColumnWidth } from 'components/Table/type';
import { useTakeinAccountingRepo } from 'di';
import { ConnectionState, useAsync } from 'hooks/Async';
import _ from 'lodash';
import { OutputData, PagingData, TakeinAccountingData } from 'models';
import { AutoFillData } from 'models/AutoFillData/AutoFillData';
import { useContext, useEffect, useMemo } from 'react';
import { BaseQueryParams, IobQueryParams } from 'repository/type';
import { ItemType } from '..';
import styles from '../TableEcusNext/styles.module.css';

const TakeinAccountingColumnMetaMap: { [key: string]: ColumnMeta } = {
    code: {
        inputType: 'text',
        enableSorting: true
    },
    name: {
        inputType: 'text',
        enableSorting: true
    },
    unit: {
        inputType: 'text',
        enableSorting: true
    },
    warehouse_name: {
        inputType: 'text'
    },
    output_date: {
        inputType: 'date',
        enableSorting: true
    },
    doc_number: { inputType: 'text' },
    bill_date: { inputType: 'date' },
    bill_no: { inputType: 'text' },
    explain: { inputType: 'text' },
    unit_price: { inputType: 'number' },
    import_quantity: { inputType: 'number' },
    import_value: { inputType: 'number' }
};
const TableFormType: TableType = TableType.takeinAccounting;

export const TableTakeinAccountingNext = ({ dropDownValue }: { dropDownValue: ItemType }) => {
    const { folder_slug, company_slug } = useCompanyFolderSlug();
    const params: IobQueryParams = useMemo(
        () => ({
            item_type: dropDownValue,
            folder_slug,
            company_slug
        }),
        [folder_slug, company_slug, dropDownValue]
    );
    const takeinAccountingRepository = useTakeinAccountingRepo();
    const { panelVisibility, codeLabel, nameInput, unitInput, setlistAutoFillName, setlistAutoFillUnit } = useContext(TableContext);
    const { execute, tableData } = useAsync<PagingData<AutoFillData>, any>(() =>
        takeinAccountingRepository.getAutoFillData(autoFillParams)
    );

    const autoFillParams: AutoFillParams = useMemo(() => {
        const result = {
            code: codeLabel ?? '',
            select: ['name', 'unit'],
            filter: {},
            company_slug: params.company_slug,
            folder_slug: params.folder_slug
        };
        if (nameInput) {
            return {
                ...result,
                filter: { name: nameInput }
            };
        } else if (unitInput) {
            return {
                ...result,
                filter: { unit: unitInput }
            };
        } else {
            return result;
        }
    }, [codeLabel, nameInput, unitInput]);

    useEffect(() => {
        if (codeLabel) {
            execute('');
        }
    }, [codeLabel, autoFillParams]);

    useEffect(() => {
        if (tableData && tableData.state === ConnectionState.hasData) {
            tableData.data.results.map((item) => {
                setlistAutoFillName(item.name);
                setlistAutoFillUnit(item.unit);
            });
        }
    }, [tableData]);

    const defaultColumns = useMemo<ColumnDef<OutputData, any>[]>(() => {
        const columnHelper = createColumnHelper<OutputData>();
        return _.toPairs(TakeinAccountingColumnMetaMap).map(([key, value]) =>
            // @ts-ignore
            columnHelper.accessor(key, {
                id: key,
                minSize: ColumnWidth.ssm,
                size: key === 'name' || key === 'item_name' ? ColumnWidth.xxl : ColumnWidth.sm,
                enableSorting: value.enableSorting ?? false,
                cell: (info) => <div className={`tw-pr-[8px]`}>{info.getValue() as React.ReactNode}</div>,
                header: () => <div className={styles.header}>{key}</div>
            })
        );
    }, []);

    useQueryTable({
        params,
        fetchFn: (params) => takeinAccountingRepository.getAll(params)
    });
    useManipulateTable<TakeinAccountingData, BaseQueryParams>({
        args: params,
        updateFn: (row, params) => takeinAccountingRepository.updateRow(row, params),
        deleteFn: (ids) => takeinAccountingRepository.deleteRows(ids),
        insertFn: (json) => takeinAccountingRepository.insertRow(json),
        extractDataId: (data) => data.id
    });

    return (
        <>
            <div className="tw-relative tw-h-[886px]">
                <Allotment snap>
                    <Allotment.Pane className={styles.leftPane}>
                        <$Table<OutputData>
                            enableRowSelection
                            columnsDef={defaultColumns}
                            columnsMeta={TakeinAccountingColumnMetaMap}
                            tableType={TableFormType}
                        />
                    </Allotment.Pane>
                    <Allotment.Pane visible={panelVisibility} className={styles.rightPane}>
                        <$TableForm columnsMeta={TakeinAccountingColumnMetaMap} TableFormType={TableFormType} />
                    </Allotment.Pane>
                </Allotment>
            </div>
        </>
    );
};
