import { Http } from 'api';
import { CancleData } from 'models';
import { IAmamImportDTO } from 'repository/ama';
import { CancleQueryParams } from 'repository/type';

export class CancleDataSource {
    constructor(private http: Http) {}

    async getAll(params: CancleQueryParams) {
        const respone = await this.http.instance.get('/api/balance/ecus-cancel/', { params });
        return new CancleData().fromPaging<CancleData>(respone.data);
    }

    async importData(data: any) {
        try {
            var bodyFormData = new FormData();

            Object.keys(data).map((e) => bodyFormData.append(e, data[e]));

            await this.http.instance.post(`/api/balance/ecus/cancel_import_data/`, bodyFormData, {
                headers: { 'content-type': 'multipart/form-data' }
            });
            return { response: 'SUCCESS' };
        } catch (e) {
            return { error: e };
        }
    }
}
