import {
    AmaCancleDataSource,
    AmaCancleRepository,
    AnnexDataSource,
    AnnexRepository,
    AuthRepository,
    BalanceDataSource,
    BalanceNewDataSource,
    BalanceNewRepository,
    BalanceRepository,
    BeginDataSource,
    BeginRepository,
    BomDataSource,
    BomExwDataSource,
    BomExwRepository,
    BomRepository,
    CODataSource,
    CORepository,
    EcusDataSource,
    EcusRepository,
    EcusStockRepository,
    EndingInventoryDataSourceACC,
    EndingInventoryRepositoryACC,
    IobDataSource,
    IobRepository,
    LocalAuthDataSource,
    MapCodeDataSource,
    MapCodeRepository,
    NormalBomDataSource,
    NormalBomExwDataSource,
    PhysicalCountingWHDataSource,
    PhysicalCountingWHRepository,
    RemoteAuthDataSource,
    ReportStatement15ADS,
    ReportStatement15ARepo,
    SideBarComapnyDS,
    SideBarComapnyRepo,
    Statement15ADataSource,
    Statement15ARepository,
    Statement15DataSource,
    Statement15Repository,
    TakeinAccountingDataSource,
    TakeinAccountingRepository,
    TakeoutAccountingRepository
} from 'repository';
import { EximhowHttp } from '../api';
import { TakeoutAccountingDataSource } from '../repository/takeoutAccounting/TakeoutAccountingDataSource';

import { createContainer, injected, token } from 'brandi';
import { createInjectionHooks } from 'brandi-react';
import { EcusStockDataSource } from 'repository/FTA/ecus_stock/EcusStockDataSource';
import { AmaDataSource } from 'repository/ama/AmaDataSource';
import { AmaRepository } from 'repository/ama/AmaRepository';
import { CancleDataSource, CancleRepository } from 'repository/cancle';
import { CompanyDataSource, CompanyRepository } from 'repository/company';
import { EndingInventoryDataSourceProduction, EndingInventoryRepositoryProduction } from 'repository/endingInventoryProduction';
import { EndingInventoryDataSourceWH, EndingInventoryRepositoryWH } from 'repository/endingInventoryWH';
import { InvoiceDataSource, InvoiceRepository } from 'repository/invoice';
import { IobAccountingDataSource, IobAccountingRepository } from 'repository/iobAccounting';
import { ProductionDataSource, ProductionRepository } from 'repository/production';
import { PurchaseDataSource, PurchaseStockDataSource } from 'repository/purchase/PurchaseDataSource';
import { PurchaseRepository, PurchaseStockRepository } from 'repository/purchase/PurchaseRepository';
import { ScrapDataSource, ScrapRepository } from 'repository/scrap';
import { SellDataSource, SellRepository } from 'repository/sell';
import { TakeInDataSource, TakeInRepository } from 'repository/take_in';
import { TakeOutDataSource, TakeOutRepository } from 'repository/take_out';
import { PhysicalCountingAccDataSource, PhysicalCountingAccRepository } from 'repository/physicalCountingAcc';
import { ReportStatement15DS, ReportStatement15Repo } from 'repository/reportStatement15';
import { OtherOutputAccDS, OtherOutputAccRepository } from 'repository/otherOutputAcc';

const TOKENS = {
    eximhowHttp: token<EximhowHttp>('EximhowHttp'),

    authRepository: token<AuthRepository>('AuthRepository'),
    localAuthDataSource: token<LocalAuthDataSource>('LocalAuthDataSource'),
    remoteAuthDataSource: token<RemoteAuthDataSource>('RemoteAuthDataSource'),

    ecusDataSource: token<EcusDataSource>('EcusDataSource'),
    ecusRepository: token<EcusRepository>('EcusRepository'),

    Statement15Repository: token<Statement15Repository>('Statement15Repository'),
    Statement15DataSource: token<Statement15DataSource>('Statement15DataSource'),

    Statement15ARepository: token<Statement15ARepository>('Statement15ARepository'),
    Statement15ADataSource: token<Statement15ADataSource>('Statement15ADataSource'),

    sidebarcompanyDataSource: token<SideBarComapnyDS>('SidebarcompanyDataSource'),
    sidebarcompanyRepository: token<SideBarComapnyRepo>('sidebarcompanyRepository'),

    beginRepository: token<BeginRepository>('BeginRepository'),
    beginDataSource: token<BeginDataSource>('BeginDataSource'),

    iobRepository: token<IobRepository>('IobRepository'),
    iobDataSource: token<IobDataSource>('IobDataSource'),

    iobAccountingRepository: token<IobAccountingRepository>('IobAccountingRepository'),
    iobAccountingDataSource: token<IobAccountingDataSource>('IobAccountingDataSource'),

    takeoutAccountingRepository: token<TakeoutAccountingRepository>('TakeoutAccountingRepository'),
    takoutAccountingDataSource: token<TakeoutAccountingDataSource>('TakeoutAccountingDataSource'),

    takeinAccountingRepository: token<TakeinAccountingRepository>('TakeinAccountingRepository'),
    takeinAccountingDataSource: token<TakeinAccountingDataSource>('TakeinAccountingDataSource'),

    purchaseRepository: token<PurchaseRepository>('PurchaseRepository'),
    purchaseDataSource: token<PurchaseDataSource>('PurchaseDataSource'),

    takeInRepository: token<TakeInRepository>('TakeInRepository'),
    takeInDataSource: token<TakeInDataSource>('TakeInDataSource'),

    takeOutRepository: token<TakeOutRepository>('TakeOutRepository'),
    takeOutDataSource: token<TakeOutDataSource>('TakeOutDataSource'),

    scrapRepository: token<ScrapRepository>('ScrapRepository'),
    scrapDataSource: token<ScrapDataSource>('ScrapDataSource'),

    productionRepository: token<ProductionRepository>('ProductionRepository'),
    productionDataSource: token<ProductionDataSource>('ProductionDataSource'),

    endingInventoryRepositoryProduction: token<EndingInventoryRepositoryProduction>('EndingInventoryRepositoryProduction'),
    endingInventoryDataSourceProduction: token<EndingInventoryDataSourceProduction>('EndingInventoryDataSourceProduction'),

    endingInventoryRepositoryWH: token<EndingInventoryRepositoryWH>('EndingInventoryRepositoryWH'),
    endingInventoryDataSourceWH: token<EndingInventoryDataSourceWH>('EndingInventoryDataSourceWH'),

    balanceRepository: token<BalanceRepository>('BalanceRepository'),
    balanceDataSource: token<BalanceDataSource>('BalanceDataSource'),

    balanceNewRepository: token<BalanceNewRepository>('BalanceNewRepository'),
    balanceNewDataSource: token<BalanceNewDataSource>('BalanceNewDataSource'),

    bomRepository: token<BomRepository>('BomRepository'),
    bomDataSource: token<BomDataSource>('BomDataSource'),
    normalBomDataSource: token<NormalBomDataSource>('NormalBomDataSource'),

    amaDataSource: token<AmaDataSource>('AmaDataSource'),
    amaRepository: token<AmaRepository>('AmaRepository'),

    cancleDataSource: token<CancleDataSource>('CancleDataSource'),
    cancleRepository: token<CancleRepository>('CancleRepository'),

    endingInventoryRepositoryACC: token<EndingInventoryRepositoryACC>('endingInventoryRepositoryACC'),
    endingInventoryDataSourceACC: token<EndingInventoryDataSourceACC>('endingInventoryDataSourceACC'),

    sellDataSource: token<SellDataSource>('sellDataSource'),
    sellRepository: token<SellRepository>('sellRepository'),

    companyRepository: token<CompanyRepository>('CompanyRepository'),
    companyDataSource: token<CompanyDataSource>('CompanyDataSource'),

    amaCancleDataSoure: token<AmaCancleDataSource>('amaCancleDataSoure'),
    amaCancleRepository: token<AmaCancleRepository>('amaCancleRepository'),

    invoiceDataSource: token<InvoiceDataSource>('invoiceDataSource'),
    invoiceRepository: token<InvoiceRepository>('invoiceRepository'),

    annexDataSource: token<AnnexDataSource>('annexDataSource'),
    annexRepository: token<AnnexRepository>('annexRepository'),

    coDataSource: token<CODataSource>('coDataSource'),
    coRepository: token<CORepository>('coRepository'),

    purchaseStockDataSource: token<PurchaseStockDataSource>('purchaseStockDataSource'),
    purchaseStockRepository: token<PurchaseStockRepository>('purchaseStockRepository'),

    ecusStockDataSource: token<EcusStockDataSource>('ecusStockDataSource'),
    ecusStockRepository: token<EcusStockRepository>('ecusStockRepository'),

    bomExwRepository: token<BomExwRepository>('bomExwRepository'),
    bomExwDataSource: token<BomExwDataSource>('bomExwDataSource'),
    normalBomExwDataSource: token<NormalBomExwDataSource>('normalBomExwDataSource'),

    physicalWhDataSource: token<PhysicalCountingWHDataSource>('PhysicalCountingWHDataSource'),
    physicalWhRepository: token<PhysicalCountingWHRepository>('PhysicalCountingWHRepository'),

    physicalAccDataSource: token<PhysicalCountingAccDataSource>('PhysicalCountingAccDataSource'),
    physicalAccRepository: token<PhysicalCountingAccRepository>('PhysicalCountingAccRepository'),

    mapCodeDataSource: token<MapCodeDataSource>('MapCodeDataSource'),
    mapCodeRepository: token<MapCodeRepository>('MapCodeRepository'),

    reportStatement15ADS: token<ReportStatement15ADS>('ReportStatement15ADS'),
    reportStatement15ARepo: token<ReportStatement15ARepo>('ReportStatement15ARepo'),

    reportStatement15DS: token<ReportStatement15DS>('ReportStatement15DS'),
    reportStatement15Repo: token<ReportStatement15Repo>('ReportStatement15Repo'),

    otherOutputAccDS: token<OtherOutputAccDS>('otherOutputAccDS'),
    otherOutputAccRepo: token<OtherOutputAccRepository>('OtherOutputAccRepository')
};

injected(EximhowHttp, TOKENS.localAuthDataSource);
injected(RemoteAuthDataSource, TOKENS.eximhowHttp);
injected(AuthRepository, TOKENS.localAuthDataSource, TOKENS.remoteAuthDataSource);

injected(EcusDataSource, TOKENS.eximhowHttp);
injected(EcusRepository, TOKENS.ecusDataSource);

injected(Statement15DataSource, TOKENS.eximhowHttp);
injected(Statement15Repository, TOKENS.Statement15DataSource);

injected(Statement15ADataSource, TOKENS.eximhowHttp);
injected(Statement15ARepository, TOKENS.Statement15ADataSource);

injected(BeginDataSource, TOKENS.eximhowHttp);
injected(BeginRepository, TOKENS.beginDataSource);

injected(IobDataSource, TOKENS.eximhowHttp);
injected(IobRepository, TOKENS.iobDataSource);

injected(IobAccountingDataSource, TOKENS.eximhowHttp);
injected(IobAccountingRepository, TOKENS.iobAccountingDataSource);

injected(TakeoutAccountingDataSource, TOKENS.eximhowHttp);
injected(TakeoutAccountingRepository, TOKENS.takoutAccountingDataSource);

injected(TakeinAccountingDataSource, TOKENS.eximhowHttp);
injected(TakeinAccountingRepository, TOKENS.takeinAccountingDataSource);

injected(SideBarComapnyDS, TOKENS.eximhowHttp);
injected(SideBarComapnyRepo, TOKENS.sidebarcompanyDataSource);

injected(PurchaseDataSource, TOKENS.eximhowHttp);
injected(PurchaseRepository, TOKENS.purchaseDataSource);

injected(TakeInDataSource, TOKENS.eximhowHttp);
injected(TakeInRepository, TOKENS.takeInDataSource);

injected(TakeOutDataSource, TOKENS.eximhowHttp);
injected(TakeOutRepository, TOKENS.takeOutDataSource);

injected(ScrapDataSource, TOKENS.eximhowHttp);
injected(ScrapRepository, TOKENS.scrapDataSource);

injected(ProductionDataSource, TOKENS.eximhowHttp);
injected(ProductionRepository, TOKENS.productionDataSource);

injected(EndingInventoryDataSourceProduction, TOKENS.eximhowHttp);
injected(EndingInventoryRepositoryProduction, TOKENS.endingInventoryDataSourceProduction);

injected(EndingInventoryDataSourceWH, TOKENS.eximhowHttp);
injected(EndingInventoryRepositoryWH, TOKENS.endingInventoryDataSourceWH);

injected(BalanceDataSource, TOKENS.eximhowHttp);
injected(BalanceRepository, TOKENS.balanceDataSource);

injected(BalanceNewDataSource, TOKENS.eximhowHttp);
injected(BalanceNewRepository, TOKENS.balanceNewDataSource);

injected(BomDataSource, TOKENS.eximhowHttp);
injected(NormalBomDataSource, TOKENS.eximhowHttp);
injected(BomRepository, TOKENS.bomDataSource, TOKENS.normalBomDataSource);

injected(BomExwDataSource, TOKENS.eximhowHttp);
injected(NormalBomExwDataSource, TOKENS.eximhowHttp);
injected(BomExwRepository, TOKENS.bomExwDataSource, TOKENS.normalBomExwDataSource);

injected(CompanyDataSource, TOKENS.eximhowHttp);
injected(CompanyRepository, TOKENS.companyDataSource);

injected(AmaRepository, TOKENS.amaDataSource);
injected(AmaDataSource, TOKENS.eximhowHttp);

injected(CancleRepository, TOKENS.cancleDataSource);
injected(CancleDataSource, TOKENS.eximhowHttp);

injected(EndingInventoryRepositoryACC, TOKENS.endingInventoryDataSourceACC);
injected(EndingInventoryDataSourceACC, TOKENS.eximhowHttp);

injected(SellRepository, TOKENS.sellDataSource);
injected(SellDataSource, TOKENS.eximhowHttp);

injected(AmaCancleRepository, TOKENS.amaCancleDataSoure);
injected(AmaCancleDataSource, TOKENS.eximhowHttp);

injected(InvoiceDataSource, TOKENS.eximhowHttp);
injected(InvoiceRepository, TOKENS.invoiceDataSource);

injected(AnnexDataSource, TOKENS.eximhowHttp);
injected(AnnexRepository, TOKENS.annexDataSource);

injected(CODataSource, TOKENS.eximhowHttp);
injected(CORepository, TOKENS.coDataSource);

injected(EcusStockDataSource, TOKENS.eximhowHttp);
injected(EcusStockRepository, TOKENS.ecusStockDataSource);

injected(PurchaseStockDataSource, TOKENS.eximhowHttp);
injected(PurchaseStockRepository, TOKENS.purchaseStockDataSource);

injected(PhysicalCountingWHDataSource, TOKENS.eximhowHttp);
injected(PhysicalCountingWHRepository, TOKENS.physicalWhDataSource);

injected(PhysicalCountingAccDataSource, TOKENS.eximhowHttp);
injected(PhysicalCountingAccRepository, TOKENS.physicalAccDataSource);

injected(MapCodeDataSource, TOKENS.eximhowHttp);
injected(MapCodeRepository, TOKENS.mapCodeDataSource);

injected(ReportStatement15ADS, TOKENS.eximhowHttp);
injected(ReportStatement15ARepo, TOKENS.reportStatement15ADS);

injected(ReportStatement15DS, TOKENS.eximhowHttp);
injected(ReportStatement15Repo, TOKENS.reportStatement15DS);

injected(OtherOutputAccDS, TOKENS.eximhowHttp);
injected(OtherOutputAccRepository, TOKENS.otherOutputAccDS);

const container = createContainer();

container.bind(TOKENS.eximhowHttp).toInstance(EximhowHttp).inSingletonScope();

container.bind(TOKENS.ecusDataSource).toInstance(EcusDataSource).inSingletonScope();
container.bind(TOKENS.ecusRepository).toInstance(EcusRepository).inSingletonScope();

container.bind(TOKENS.localAuthDataSource).toInstance(LocalAuthDataSource).inSingletonScope();

container.bind(TOKENS.remoteAuthDataSource).toInstance(RemoteAuthDataSource).inSingletonScope();

container.bind(TOKENS.authRepository).toInstance(AuthRepository).inSingletonScope();

container.bind(TOKENS.Statement15Repository).toInstance(Statement15Repository).inSingletonScope();
container.bind(TOKENS.Statement15DataSource).toInstance(Statement15DataSource).inSingletonScope();

container.bind(TOKENS.Statement15ARepository).toInstance(Statement15ARepository).inSingletonScope();
container.bind(TOKENS.Statement15ADataSource).toInstance(Statement15ADataSource).inSingletonScope();

container.bind(TOKENS.beginDataSource).toInstance(BeginDataSource).inSingletonScope();
container.bind(TOKENS.beginRepository).toInstance(BeginRepository).inSingletonScope();

container.bind(TOKENS.iobDataSource).toInstance(IobDataSource).inSingletonScope();
container.bind(TOKENS.iobRepository).toInstance(IobRepository).inSingletonScope();

container.bind(TOKENS.iobAccountingDataSource).toInstance(IobAccountingDataSource).inSingletonScope();
container.bind(TOKENS.iobAccountingRepository).toInstance(IobAccountingRepository).inSingletonScope();

container.bind(TOKENS.sidebarcompanyDataSource).toInstance(SideBarComapnyDS).inSingletonScope();
container.bind(TOKENS.sidebarcompanyRepository).toInstance(SideBarComapnyRepo).inSingletonScope();

container.bind(TOKENS.takeoutAccountingRepository).toInstance(TakeoutAccountingRepository).inSingletonScope();
container.bind(TOKENS.takoutAccountingDataSource).toInstance(TakeoutAccountingDataSource).inSingletonScope();

container.bind(TOKENS.takeinAccountingRepository).toInstance(TakeinAccountingRepository).inSingletonScope();
container.bind(TOKENS.takeinAccountingDataSource).toInstance(TakeinAccountingDataSource).inSingletonScope();

container.bind(TOKENS.purchaseDataSource).toInstance(PurchaseDataSource).inSingletonScope();
container.bind(TOKENS.purchaseRepository).toInstance(PurchaseRepository).inSingletonScope();

container.bind(TOKENS.takeInDataSource).toInstance(TakeInDataSource).inSingletonScope();
container.bind(TOKENS.takeInRepository).toInstance(TakeInRepository).inSingletonScope();

container.bind(TOKENS.takeOutDataSource).toInstance(TakeOutDataSource).inSingletonScope();
container.bind(TOKENS.takeOutRepository).toInstance(TakeOutRepository).inSingletonScope();

container.bind(TOKENS.scrapDataSource).toInstance(ScrapDataSource).inSingletonScope();
container.bind(TOKENS.scrapRepository).toInstance(ScrapRepository).inSingletonScope();

container.bind(TOKENS.productionDataSource).toInstance(ProductionDataSource).inSingletonScope();
container.bind(TOKENS.productionRepository).toInstance(ProductionRepository).inSingletonScope();

container.bind(TOKENS.endingInventoryDataSourceProduction).toInstance(EndingInventoryDataSourceProduction).inSingletonScope();
container.bind(TOKENS.endingInventoryRepositoryProduction).toInstance(EndingInventoryRepositoryProduction).inSingletonScope();

container.bind(TOKENS.endingInventoryDataSourceWH).toInstance(EndingInventoryDataSourceWH).inSingletonScope();
container.bind(TOKENS.endingInventoryRepositoryWH).toInstance(EndingInventoryRepositoryWH).inSingletonScope();

container.bind(TOKENS.balanceRepository).toInstance(BalanceRepository).inSingletonScope();
container.bind(TOKENS.balanceDataSource).toInstance(BalanceDataSource).inSingletonScope();

container.bind(TOKENS.balanceNewRepository).toInstance(BalanceNewRepository).inSingletonScope();
container.bind(TOKENS.balanceNewDataSource).toInstance(BalanceNewDataSource).inSingletonScope();

container.bind(TOKENS.bomRepository).toInstance(BomRepository).inSingletonScope();
container.bind(TOKENS.bomDataSource).toInstance(BomDataSource).inSingletonScope();
container.bind(TOKENS.normalBomDataSource).toInstance(NormalBomDataSource).inSingletonScope();

container.bind(TOKENS.bomExwRepository).toInstance(BomExwRepository).inSingletonScope();
container.bind(TOKENS.bomExwDataSource).toInstance(BomExwDataSource).inSingletonScope();
container.bind(TOKENS.normalBomExwDataSource).toInstance(NormalBomExwDataSource).inSingletonScope();

container.bind(TOKENS.amaRepository).toInstance(AmaRepository).inSingletonScope();
container.bind(TOKENS.amaDataSource).toInstance(AmaDataSource).inSingletonScope();

container.bind(TOKENS.cancleRepository).toInstance(CancleRepository).inSingletonScope();
container.bind(TOKENS.cancleDataSource).toInstance(CancleDataSource).inSingletonScope();

container.bind(TOKENS.endingInventoryDataSourceACC).toInstance(EndingInventoryDataSourceACC).inSingletonScope();
container.bind(TOKENS.endingInventoryRepositoryACC).toInstance(EndingInventoryRepositoryACC).inSingletonScope();

container.bind(TOKENS.sellDataSource).toInstance(SellDataSource).inSingletonScope();
container.bind(TOKENS.sellRepository).toInstance(SellRepository).inSingletonScope();

container.bind(TOKENS.companyDataSource).toInstance(CompanyDataSource).inSingletonScope();
container.bind(TOKENS.companyRepository).toInstance(CompanyRepository).inSingletonScope();

container.bind(TOKENS.amaCancleDataSoure).toInstance(AmaCancleDataSource).inSingletonScope();
container.bind(TOKENS.amaCancleRepository).toInstance(AmaCancleRepository).inSingletonScope();

container.bind(TOKENS.invoiceDataSource).toInstance(InvoiceDataSource).inSingletonScope();
container.bind(TOKENS.invoiceRepository).toInstance(InvoiceRepository).inSingletonScope();

container.bind(TOKENS.annexDataSource).toInstance(AnnexDataSource).inSingletonScope();
container.bind(TOKENS.annexRepository).toInstance(AnnexRepository).inSingletonScope();

container.bind(TOKENS.coDataSource).toInstance(CODataSource).inSingletonScope();
container.bind(TOKENS.coRepository).toInstance(CORepository).inSingletonScope();

container.bind(TOKENS.ecusStockDataSource).toInstance(EcusStockDataSource).inSingletonScope();
container.bind(TOKENS.ecusStockRepository).toInstance(EcusStockRepository).inSingletonScope();

container.bind(TOKENS.purchaseStockDataSource).toInstance(PurchaseStockDataSource).inSingletonScope();
container.bind(TOKENS.purchaseStockRepository).toInstance(PurchaseStockRepository).inSingletonScope();

container.bind(TOKENS.physicalWhDataSource).toInstance(PhysicalCountingWHDataSource).inSingletonScope();
container.bind(TOKENS.physicalWhRepository).toInstance(PhysicalCountingWHRepository).inSingletonScope();

container.bind(TOKENS.physicalAccDataSource).toInstance(PhysicalCountingAccDataSource).inSingletonScope();
container.bind(TOKENS.physicalAccRepository).toInstance(PhysicalCountingAccRepository).inSingletonScope();

container.bind(TOKENS.mapCodeDataSource).toInstance(MapCodeDataSource).inSingletonScope();
container.bind(TOKENS.mapCodeRepository).toInstance(MapCodeRepository).inSingletonScope();

container.bind(TOKENS.reportStatement15ADS).toInstance(ReportStatement15ADS).inSingletonScope();
container.bind(TOKENS.reportStatement15ARepo).toInstance(ReportStatement15ARepo).inSingletonScope();

container.bind(TOKENS.reportStatement15DS).toInstance(ReportStatement15DS).inSingletonScope();
container.bind(TOKENS.reportStatement15Repo).toInstance(ReportStatement15Repo).inSingletonScope();

container.bind(TOKENS.otherOutputAccDS).toInstance(OtherOutputAccDS).inSingletonScope();
container.bind(TOKENS.otherOutputAccRepo).toInstance(OtherOutputAccRepository).inSingletonScope();

const [
    useEcusRepo,
    useAuthRepo,
    useStatement15Repo,
    useStatement15ARepo,
    useBeginRepo,
    useIobRepo,
    useIobAccountingRepo,
    useTakeoutAccountingRepo,
    useTakeinAccountingRepo,
    usePurchaseRepo,
    useSidebarcompanyRepo,
    useScrapRepo,
    useProductionRepo,
    useEndingInvenRepoProduction,
    useTakeInRepo,
    useTakeOutRepo,
    useEndingInvenRepoWH,
    useBomRepo,
    useAmaRepo,
    useCancleRepo,
    useBalanceRepo,
    useBalanceNewRepo,
    useCompanyRepo,
    useEndingInvenRepoACC,
    useSellRepo,
    useAmaCancleRepo,
    useInvoiceRepo,
    useAnnexRepository,
    useCORepository,
    useEcusStockRepo,
    useBomExwRepo,
    usePurchaseStockRepo,
    usePhysicalCountWHRepo,
    usePhysicalCountAccRepo,
    useMapCodeRepo,
    useReportStatement15ARepo,
    useReportStatement15Repo,
    useOtherOutputAccRepo
] = createInjectionHooks(
    TOKENS.ecusRepository,
    TOKENS.authRepository,
    TOKENS.Statement15Repository,
    TOKENS.Statement15ARepository,
    TOKENS.beginRepository,
    TOKENS.iobRepository,
    TOKENS.iobAccountingRepository,
    TOKENS.takeoutAccountingRepository,
    TOKENS.takeinAccountingRepository,
    TOKENS.purchaseRepository,
    TOKENS.sidebarcompanyRepository,
    TOKENS.scrapRepository,
    TOKENS.productionRepository,
    TOKENS.endingInventoryRepositoryProduction,
    TOKENS.takeInRepository,
    TOKENS.takeOutRepository,
    TOKENS.endingInventoryRepositoryWH,
    TOKENS.bomRepository,
    TOKENS.amaRepository,
    TOKENS.cancleRepository,
    TOKENS.balanceRepository,
    TOKENS.balanceNewRepository,
    TOKENS.companyRepository,
    TOKENS.endingInventoryRepositoryACC,
    TOKENS.sellRepository,
    TOKENS.amaCancleRepository,
    TOKENS.invoiceRepository,
    TOKENS.annexRepository,
    TOKENS.coRepository,
    TOKENS.ecusStockRepository,
    TOKENS.bomExwRepository,
    TOKENS.purchaseStockRepository,
    TOKENS.physicalWhRepository,
    TOKENS.physicalAccRepository,
    TOKENS.mapCodeRepository,
    TOKENS.reportStatement15ARepo,
    TOKENS.reportStatement15Repo,
    TOKENS.otherOutputAccRepo
);

export {
    TOKENS,
    container,
    useAmaCancleRepo,
    useAmaRepo,
    useAnnexRepository,
    useAuthRepo,
    useBalanceNewRepo,
    useBalanceRepo,
    useBeginRepo,
    useBomExwRepo,
    useBomRepo,
    useCORepository,
    useCancleRepo,
    useCompanyRepo,
    useEcusRepo,
    useEcusStockRepo,
    useEndingInvenRepoACC,
    useEndingInvenRepoProduction,
    useEndingInvenRepoWH,
    useInvoiceRepo,
    useIobAccountingRepo,
    useIobRepo,
    usePhysicalCountWHRepo,
    useProductionRepo,
    usePurchaseRepo,
    usePurchaseStockRepo,
    useScrapRepo,
    useSellRepo,
    useSidebarcompanyRepo,
    useStatement15ARepo,
    useStatement15Repo,
    useTakeInRepo,
    useTakeOutRepo,
    useTakeinAccountingRepo,
    useTakeoutAccountingRepo,
    usePhysicalCountAccRepo,
    useMapCodeRepo,
    useReportStatement15ARepo,
    useReportStatement15Repo,
    useOtherOutputAccRepo
};
