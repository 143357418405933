import { JsonObject, JsonProperty } from 'json2typescript';
import { JsonSerializable } from 'models/serialization';
import { BangKeExcelRowData } from './BangKeExcelRowData';

export interface BangKePerformManualInterface {
    ecus_stock_id: string;
    old_fta_origin_cost: number;
    count: number;
    results: BangKeExcelRowData[];
}

@JsonObject('BangKePerformManual')
export class BangKePerformManual extends JsonSerializable<BangKePerformManual> implements BangKePerformManualInterface {
    protected get classRef(): new () => BangKePerformManual {
        return BangKePerformManual;
    }

    @JsonProperty('ecus_stock_id', String, false)
    ecus_stock_id!: string;

    @JsonProperty('old_fta_origin_cost', Number, true)
    old_fta_origin_cost: number = 0;

    @JsonProperty('count', Number, true)
    count: number = 0;

    @JsonProperty('results', [BangKeExcelRowData], true)
    results: BangKeExcelRowData[] = [];
}
