import { AsyncValue } from 'hooks/Async';
import { BalanceParams } from 'repository';

export type TableState<T> =
    | {
          state: 'read';
      }
    | { state: 'edit'; draft: { [key: number]: T } }
    | { state: 'update'; row: { index: number; data: Partial<T> } }
    | { state: 'insert' };

export type ColumnMeta = {
    inputType: 'number' | 'text' | 'date';
    enableSorting?: boolean;
    isRequire?: boolean;
};

export type ManipulationAsyncValue = {
    insert: AsyncValue<any>;
    delete: AsyncValue<any>;
    update: AsyncValue<any>;
};
export const checkbox_column_id = 'select_id';

export type AutoFillFilter = { name?: string } | { unit?: string };
export type AutoFillParams = BalanceParams & { code: string; select: string[]; filter: AutoFillFilter };

export enum ColumnWidth {
    ssm = 100,
    sm = 220,
    md = 250,
    lg = 350,
    xl = 450,
    xxl = 650
}

export enum DeleteAllTableEnum {
    ecus = 'ecus',
    bom = 'bom',
    begin = 'begin',
    purchase = 'purchase',
    bomexw = 'bom_exw',
    co = 'co',
    annex = 'annex',
    statement15 = 'statement-15',
    statement15A = 'statement-15A',
    takeinAcc = 'acc-take-in',
    takeoutAcc = 'acc-take-out',
    takeinWH = 'wh-take-in',
    takeoutWH = 'wh-take-out',
    iobAcc = 'acc-iob',
    iobWh = 'wh-iob',
    physicalAcc = 'acc-counting',
    physicalWH = 'wh-counting',
    mapCode = 'map-code',
    otherOutputAcc = 'acc-other-output'
}
