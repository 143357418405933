import { Http } from 'api';
import { AmaCancleData } from 'models/AmaCancleTable/AmaCancleData';
import { AmaCancleQueryParams } from 'repository/type';

export class AmaCancleDataSource {
    constructor(public http: Http) {}
    async getAll(params: AmaCancleQueryParams) {
        const response = await this.http.instance.get('/api/balance/import-record/', { params });
        return new AmaCancleData().fromPaging<AmaCancleData>(response.data);
    }
}
