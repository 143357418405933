import { Http } from '../api';
import _ from 'lodash';
import { SheetMeta } from '../components';
import { BalanceParams } from './type';

export abstract class BalanceImportDataSource<P extends SheetMeta & BalanceParams> {
    abstract path: string;
    importUrl?: string;
    setupUrl?: string;
    fixedHeaderUrl?: string;

    constructor(public http: Http) {}

    public async getFixedHeader() {
        const response = await this.http.instance.get(this.fixedHeaderUrl ?? `/api/balance/${this.path}/get_fixed_columns/`);
        if (!_.isObject(response.data)) throw Error('Response should be an object');
        return _.keys(response.data);
    }

    public async setupImport(data: { [key: string]: any }) {
        const url = this.setupUrl ?? `/api/balance/${this.path}/setup/`;
        return this.http.instance.post(url, data);
    }

    public async importData(data: P) {
        let bodyFormData = new FormData();
        Object.entries(data).map(([key, value]) => {
            if (_.isNumber(value)) {
                bodyFormData.append(key, `${value}`);
            } else bodyFormData.append(key, value);
        });
        const url = this.importUrl ?? `/api/balance/${this.path}/import_data/`;
        await this.http.instance.post(url, bodyFormData, {
            headers: { 'content-type': 'multipart/form-data' }
        });
    }
}

export abstract class BalanceNewImportDataSource<P extends SheetMeta & BalanceParams> {
    abstract path: string;
    importUrl?: string;
    setupUrl?: string;
    fixedHeaderUrl?: string;

    constructor(public http: Http) {}

    public async getFixedHeader() {
        const response = await this.http.instance.get(this.fixedHeaderUrl ?? `/api/new-balance/${this.path}/get_fixed_columns/`);
        if (!_.isObject(response.data)) throw Error('Response should be an object');
        return _.keys(response.data);
    }

    public async setupImport(data: { [key: string]: any }) {
        const url = this.setupUrl ?? `/api/new-balance/${this.path}/setup/`;
        return this.http.instance.post(url, data);
    }

    public async importData(data: P) {
        let bodyFormData = new FormData();
        Object.entries(data).map(([key, value]) => {
            if (_.isNumber(value)) {
                bodyFormData.append(key, `${value}`);
            } else bodyFormData.append(key, value);
        });
        const url = this.importUrl ?? `/api/new-balance/${this.path}/import_data/`;
        await this.http.instance.post(url, bodyFormData, {
            headers: { 'content-type': 'multipart/form-data' }
        });
    }
}
