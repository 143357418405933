import { ProductionData, ProductionSetupDTO, JSONType, Result, resultGuard } from 'models';
import { ProductionQueryParams } from '../type';
import { ProductionDataSource } from './ProductionDataSource';
import { IProductionImportDTO } from './type';
import { AutoFillParams } from 'components';

export class ProductionRepository {
    constructor(private datasource: ProductionDataSource) {}

    public async getAutoFillData(data: AutoFillParams) {
        return this.datasource.getAutoFill(data);
    }

    public async getAll(params: ProductionQueryParams) {
        return this.datasource.getAll(params);
    }

    public async deleteRows(ids: string[]) {
        return this.datasource.delete(ids);
    }

    public async updateRow(row: Partial<ProductionData>, params: ProductionQueryParams) {
        return this.datasource.update(row, params);
    }

    public async insertRow(row: JSONType) {
        return this.datasource.insert(row);
    }

    public async importData(value: IProductionImportDTO): Promise<Result<void>> {
        return resultGuard(async () => {
            await this.datasource.importData(value);
        });
    }

    public async setupImport(data: { [key: string]: any }) {
        return resultGuard(async () => {
            await this.datasource.setupImport(data);
        });
    }

    public async getFixedHeader(): Promise<Result<string[]>> {
        return resultGuard(async () => {
            return this.datasource.getFixedHeader();
        });
    }
}
