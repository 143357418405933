import { MenuItem, Select, SelectChangeEvent, Switch, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import useSnackBar, { SnackBarAlertEnum } from 'components/SnackBar/UISnackbar';
import { useContext, useEffect, useState } from 'react';
import { FormTypeEnum, GetFirstDay, InvoiceContext } from '..';
import background01 from '../../../../assets/images/pages/bangke_bg_01.avif';
import './datePicker.css';

export enum OptionFormEnum {
    ecus = 'ecus',
    purchase = 'purchase',
    default = 'default'
}

export enum OptionOriginEnum {
    o = 'originated',
    no = 'non_originated',
    empty = ''
}

export enum OptionPriceEnum {
    low = 'low_price',
    high = 'high_price',
    empty = ''
}

// ==============================|| DATETIME ||============================== //

function BangKeStepOne() {
    const { showSnackBar } = useSnackBar();
    const [defaultDisplay, setDefaultDisplay] = useState(true);
    const {
        setStartDate,
        setFormType,
        setDefaultDisplay: setDefaultDisplayProvider,
        setOptionForm: setOptionFormProvider,
        setOptionPrice: setOptionPriceProvider,
        setOptionOrigin: setOptionOriginProvider,
        setUseBegin: setUseBeginProvider,
        setMatchContract: setMatchContractProvider
    } = useContext(InvoiceContext);
    const [useBegin, setUseBegin] = useState(true);
    const optionPrices = ['low_price', 'high_price'];
    const optionForms = ['ecus', 'purchase', 'default'];
    const optionOrigins = ['originated', 'non_originated'];
    const [bangKeStartDate, setBangKeStartDate] = useState<Date | null>(GetFirstDay(new Date()));
    const [optionForm, setOptionForm] = useState<OptionFormEnum>(OptionFormEnum.default);
    const [optionPrice, setOptionPrice] = useState<OptionPriceEnum>(OptionPriceEnum.low);
    const [bangKeFormType, setBangKeFormType] = useState<FormTypeEnum>(FormTypeEnum.ATIGA);
    const [optionOrigin, setOptionOrigin] = useState<OptionOriginEnum>(OptionOriginEnum.o);
    const dropListItems = ['ATIGA', 'UKV', 'EV', 'EUEA', 'AC', 'AANZ', 'AHK', 'CPTPP', 'VC', 'VK', 'RCEP', 'VJ', 'AJ', 'AK'];

    const [matchContract, setMatchContract] = useState(true);

    function DisplayHandler() {
        setDefaultDisplay((prev) => !prev);
    }

    function MatchContractHandler() {
        setMatchContract((prev) => !prev);
    }

    function UseBeginTableHandler() {
        setUseBegin((prev) => !prev);
    }

    function OptionPriceHandler(event: SelectChangeEvent) {
        setOptionPrice(event.target.value as OptionPriceEnum);
    }

    function OptionOrginHandler(event: SelectChangeEvent) {
        setOptionOrigin(event.target.value as OptionOriginEnum);
    }

    function OptionFormHandler(event: SelectChangeEvent) {
        setOptionForm(event.target.value as OptionFormEnum);
    }

    function ChangeFormHandler(event: SelectChangeEvent) {
        setBangKeFormType(event.target.value as FormTypeEnum);
    }

    function ChangeDateHandler(newDate: Date | null) {
        setBangKeStartDate(newDate);
    }

    useEffect(() => {
        setFormType(bangKeFormType);
        setUseBeginProvider(useBegin);
        setStartDate(bangKeStartDate);
        setMatchContractProvider(matchContract);
        setDefaultDisplayProvider(defaultDisplay);
        if (defaultDisplay) {
            setOptionFormProvider(optionForm);
            setOptionPriceProvider(optionPrice);
            setOptionOriginProvider(optionOrigin);
        }
        showSnackBar('Applied Change Success', SnackBarAlertEnum.success);
    }, [bangKeFormType, useBegin, bangKeStartDate, matchContract, defaultDisplay, optionForm, optionOrigin, optionPrice]);

    useEffect(() => {
        showSnackBar(null, null, false);
    }, []);

    return (
        <Box sx={{ display: 'flex', padding: '22px 22px' }}>
            <Box sx={{ flex: 2 }}>
                <img style={{ width: '90%', borderRadius: '4px' }} src={background01} alt="bangke_bg_01" />
            </Box>
            <Box sx={{ flex: 1 }}>
                <Box sx={{ display: 'flex', marginBottom: '12px', gap: 3 }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                            <label>Please select your form: </label>
                            <Select
                                labelId="drop-down-psr-select"
                                id="drop-down-psr-select"
                                value={bangKeFormType}
                                onChange={ChangeFormHandler}
                                sx={{ width: '200px', height: '48px', borderRadius: '5px' }}
                            >
                                {dropListItems.map((item) => (
                                    <MenuItem key={item} value={item}>
                                        {item}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                        {defaultDisplay && (
                            <Box sx={{ display: 'flex', gap: '12px', flexDirection: 'column', marginBottom: '12px' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                    <label>Option Form: </label>
                                    <Select
                                        labelId="drop-down-option-form-select"
                                        id="drop-down-option-form-select"
                                        value={optionForm}
                                        onChange={OptionFormHandler}
                                        sx={{ width: '200px', height: '48px', borderRadius: '5px' }}
                                    >
                                        {optionForms.map((item) => (
                                            <MenuItem key={item} value={item}>
                                                {item}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                    <label>Option Origin: </label>
                                    <Select
                                        labelId="drop-down-option-origin-select"
                                        id="drop-down-option-origin-select"
                                        value={optionOrigin}
                                        onChange={OptionOrginHandler}
                                        sx={{ width: '200px', height: '48px', borderRadius: '5px' }}
                                    >
                                        {optionOrigins.map((item) => (
                                            <MenuItem key={item} value={item}>
                                                {item}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                    <label>Option Price: </label>
                                    <Select
                                        labelId="drop-down-option-price-select"
                                        id="drop-down-option-price-select"
                                        value={optionPrice}
                                        onChange={OptionPriceHandler}
                                        sx={{ width: '200px', height: '48px', borderRadius: '5px' }}
                                    >
                                        {optionPrices.map((item) => (
                                            <MenuItem key={item} value={item}>
                                                {item}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Box>
                            </Box>
                        )}

                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                gap: '10px',
                                marginBottom: '12px'
                            }}
                        >
                            <label>Date</label>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    renderInput={(props) => <TextField sx={{ width: 200 }} {...props} />}
                                    value={bangKeStartDate}
                                    onChange={ChangeDateHandler}
                                />
                            </LocalizationProvider>
                        </Box>
                    </Box>

                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                            <label>Display {defaultDisplay ? 'Default' : 'Custom'}: </label>
                            <Switch defaultChecked onChange={DisplayHandler} />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                            <label>Use begin table: </label>
                            <Switch defaultChecked onChange={UseBeginTableHandler} />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                            <label>Match Contract</label>
                            <Switch defaultChecked onChange={MatchContractHandler} />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default BangKeStepOne;

const ProgressiveImg = ({ placeholderSrc, src, ...props }: any) => {
    return <img {...{ src: placeholderSrc, ...props }} alt={props.alt || ''} className="image" />;
};
