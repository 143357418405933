import { Button, ButtonProps, styled } from '@mui/material';
import { ProjectTheme } from 'theme';
import { BalanceButtonProps } from './type';

const ButtonS = styled(Button)(({ theme }) => ({
    textTransform: 'capitalize',
    fontSize: '16px',
    fontWeight: 700
}));
const StyleButton = (props: any) => {
    return <ButtonS {...props} variant="contained" />;
};

const BaseButton = styled(Button)<ButtonProps>(() => ({
    boxShadow: 'none',
    padding: '12px 0px',
    textTransform: 'capitalize',
    fontSize: '14px',
    borderRadius: '8px',
    maxHeight: '40px',
    maxWidth: '30px'
}));

export const ContainedButton = styled(BaseButton)<ButtonProps>(({}) => ({
    color: '#FFF',
    backgroundColor: ProjectTheme.colors.primary,
    '&:hover': {
        backgroundColor: '#061F35'
    }
}));

export const OutlinedButton = styled(BaseButton)<ButtonProps>(({}) => ({
    color: ProjectTheme.colors.primary,
    backgroundColor: '#FFF'
}));

export const BalanceButton = ({ children, onClick, ...passProps }: BalanceButtonProps) => {
    const ButtonS = styled(Button)`
        font-size: 14px;
        font-weight: 500;
        text-transform: capitalize;
    `;

    return (
        <ButtonS variant="contained" onClick={onClick} {...passProps}>
            {children}
        </ButtonS>
    );
};

export default StyleButton;
