import { hsHttpInstance } from 'api/AxiosClient';

export type dataType = { params: { df: string; fl: string; q: string }; type: string };

const titleSupHeader = '&fl=id+hs+_nest_parent_+description+en_description+ko_description+unit_of_quantity+en_unit_of_quantity+_version_'

export const getDetailHS = async (year: string | null, id: string) => {
    const url = `/hscode${year}/select/?indent=true&q.op=OR&q=hs:${id}`
    return await hsHttpInstance.get(url);
}
export const getSection = async (year: string | null) => {
    const url = `/hscode${year}/select/?indent=true&q.op=OR&q=-_nest_parent_:["" TO *]&rows=21`;
    return await hsHttpInstance.get(url);
};
export const getChapter = async (year: string | null) => {
    const url = `/hscode${year}/select/?indent=true&q.op=OR&q=_nest_parent_:[I TO XXI]&rows=600&start=0`;
    return await hsHttpInstance.get(url);
};

export const getHeader = async (year: string | null) => {
    const url = `/hscode${year}/select/?indent=true&q.op=OR&q=_nest_parent_:[1 TO 98]&rows=20000&start=0${titleSupHeader}`;
    return await hsHttpInstance.get(url);
};

// export const getHeader = async (year: string | null) => {
//     const url = `/hscode${year}/select/?indent=true&q=_nest_parent_:[1 TO 98] AND -_nest_parent_:/.*\\..*/&rows=20000&start=0${titleSupHeader}`;
//     return await hsHttpInstance.get(url);
// };

export const getSupHeader = async (year: string | null) => {
    const url = `/hscode${year}/select/?indent=true&q.op=OR&q=_nest_parent_:[01.01 TO 98.52]&rows=20000&start=0`;
    return await hsHttpInstance.get(url);
};


export const getNestedHs = async (year: string | null, id: string) => {
    const url = `/hscode${year}/select/?indent=true&q.op=OR&q=_nest_parent_:${id}&rows=600&start=0`;
    return await hsHttpInstance.get(url);
};

export const getProduct = async (year: string | null, id: string) => {
    const url = `/hscode${year}/select/?indent=true&q.op=OR&q=hs:${id}`;
    return await hsHttpInstance.get(url);
};

export const getAllRegulationByIdList = async (regulationIdList: []) => {
    const url = `/regulation/select/?indent=true&q.op=OR&q=${regulationIdList.reduce((prev, curr) => {
        return prev + ' id:' + curr;
    }, '')}`;
    return await hsHttpInstance.get(url);
};
export const SearchHs = (year: string | null, search: string, lang: string) => {
    const descripton = lang === 'vi' ? 'description' : `${lang}_description`;
    const url = `/hscode${year}/select/?hl.fl=${descripton}&df=${descripton}&hl.simple.pre=<b>&hl.simple.post=</b>&hl=true&indent=true&q.op=OR&q=${search}&rows=600&start=0`;
    return hsHttpInstance.get(url);
};

export const SearchDescriptionNote = (year: string | null, search: string, lang: string) => {
    const descripton = lang === 'vi' ? 'description' : `${lang}_description`;
    const notes = lang === 'vi' ? 'notes' : `${lang}_notes`;
    const url = `/hscode${year}/select/?hl.fl=${descripton}&df=${descripton}&hl.simple.pre=<b>&hl.simple.post=</b>&hl=true&indent=true&rows=600&start=0&q=(${descripton}:${search} OR ${notes}:${search})`;
    return hsHttpInstance.get(url);
};

export const AdvancedSearchHs = (data: dataType) => {
    const { type, params } = data;
    const url = `/${type}/select`;
    const highlighting =
        params.q !== '*:*'
            ? {
                  'hl.fl': params.df,
                  'hl.simple.pre': '<b>',
                  'hl.simple.post': '</b>',
                  hl: true
              }
            : {};
    return hsHttpInstance.get(url, {
        params: {
            ...params,
            fl: `id,${params.fl}`,
            ...highlighting,
            indent: true,
            'q.op': 'OR',
            rows: 600,
            start: 0
        }
    });
};

// not clean
export const getSelectionByHs = (hs: string, year: string | null) => {
    const url = `/hscode${year}/select/?indent=true&q.op=OR&q=hs:${hs}&rows=600&start=0&fl=id,*`;
    return hsHttpInstance.get(url);
};
export const getSelectionById = async (id: string, year: string | null) => {
    const url = `/hscode${year}/select/?indent=true&q.op=OR&q=id:${id}&rows=600&start=0&fl=id,*`;
    return await hsHttpInstance.get(url);
};

export const getProdClass = (hs: string, lang: string) => {
    const url = `/${lang}_prod_class/select/?indent=true&q.op=OR&q=hs:${hs}&rows=600&start=0&fl=id,*`;
    return hsHttpInstance.get(url);
};

export const getProdClassById = async (id: string, lang: string) => {
    const url = `/${lang}_prod_class/select/?indent=true&q.op=OR&q=id:${id}&rows=600&start=0&fl=id,*`;
    return hsHttpInstance.get(url);
};
// fta origin
export const getFtaOriginByHs = async (hs: string, lang: string) => {
    let new_lang = lang === 'vi' ? lang : 'en';
    const url = `/${new_lang}_fta_origin/select/?indent=true&q.op=OR&q=hs:${hs}&rows=600&start=0&fl=id,*`;
    return hsHttpInstance.get(url);
};

//CO checking
export const getCOInfo = (type: string, kw: string) => {
    const url = `/co_sedo/select/?indent=true&q.op=OR&q=${type}:*${kw}*&rows=600&start=0&fl=id,*`;
    return hsHttpInstance.get(url);
};