import { Http } from 'api';
import _ from 'lodash';
import { IobData, IobSetupDTO, JSONType } from 'models';
import { BalanceParams, IobQueryParams } from '../type';
import { AutoFillParams, SheetMeta } from 'components';
import { AutoFillData } from 'models/AutoFillData/AutoFillData';
import { BalanceNewImportDataSource } from '../BalanceImportDataSource';
import { IobImportParams } from './type';

export class IobDataSource extends BalanceNewImportDataSource<IobImportParams> {
    path: string = 'wh-iob';

    public async getAutoFill(data: AutoFillParams) {
        const respone = await this.http.instance.post('/api/new-balance/wh-iob/autofill/', data);
        return new AutoFillData().fromPaging<AutoFillData>(respone.data);
    }

    public async getAll(params: IobQueryParams) {
        const response = await this.http.instance.get('/api/new-balance/wh-iob/', {
            params
        });
        return new IobData().fromPaging<IobData>(response.data);
    }

    public async update(data: Partial<IobData>, params: IobQueryParams) {
        return this.http.instance.put(
            `/api/new-balance/wh-iob/${data.id}/`,
            _.omit(
                {
                    ...data,
                    ...params
                },
                'id'
            )
        );
    }

    public async delete(ids: string[]) {
        return this.http.instance.delete(`/api/new-balance/wh-iob/bulk-delete/`, {
            data: { id_list: ids }
        });
    }

    public async insert(data: JSONType) {
        return this.http.instance.post(`/api/new-balance/wh-iob/`, data);
    }
}
