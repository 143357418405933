import { JsonObject, JsonProperty } from 'json2typescript';
import { JsonSerializable } from './serialization';

@JsonObject('AuthResponse')
export class AuthResponse extends JsonSerializable<AuthResponse> {
    protected get classRef(): new () => AuthResponse {
        return AuthResponse;
    }

    @JsonProperty('access_token', String, false)
    access_token!: string;
}

@JsonObject('RegisterResponse')
export class RegisterResponse extends JsonSerializable<RegisterResponse> {
    protected get classRef(): new () => RegisterResponse {
        return RegisterResponse;
    }

    @JsonProperty('token', AuthResponse, false)
    token!: AuthResponse;
}

@JsonObject('ChangePasswordRespone')
export class ChangePasswordRespone extends JsonSerializable<ChangePasswordRespone> {
    protected get classRef(): new () => ChangePasswordRespone {
        return ChangePasswordRespone;
    }

    @JsonProperty('token', AuthResponse, false)
    token!: AuthResponse;
}

export interface IUpdateUserProifleRespone {
    name?: string;
    address?: string;
    company_slug?: string;
    phone?: null | string;
    birthday?: string;
}
@JsonObject('UpdateUserProfileResponse')
export class UpdateUserProfileResponse extends JsonSerializable<UpdateUserProfileResponse> implements IUpdateUserProifleRespone {
    protected get classRef(): new () => UpdateUserProfileResponse {
        return UpdateUserProfileResponse;
    }

    @JsonProperty('name', String, true)
    name: string = '';

    @JsonProperty('address', String, true)
    address: string = '';

    @JsonProperty('company_slug', String, true)
    company_slug: string = '';

    @JsonProperty('phone', String, true)
    phone: string = '';

    @JsonProperty('birthday', String, true)
    birthday: string = '';
}

export interface IUserCompany {
    company_slug: string;
    company_name: string;
    profile_picture: string;
    role: string;
    since: string;
}

export interface IGetUserRespone {
    updated_on: string;
    date_joined: string;
    bio?: string;
    sex?: string;
    position: string;
    address?: string;
    profile_picture?: string;
    email: string;
    name: string;
    company_slug: string;
    phone?: string;
    birthday?: string;
    company: IUserCompany[];
}

@JsonObject('UserCompany')
export class UserCompany extends JsonSerializable<UserCompany> implements IUserCompany {
    protected get classRef(): new () => UserCompany {
        return UserCompany;
    }

    @JsonProperty('company_slug', String, true)
    company_slug: string = '';

    @JsonProperty('company_name', String, true)
    company_name: string = '';

    @JsonProperty('profile_picture', String, true)
    profile_picture: string = '';

    @JsonProperty('role', String, true)
    role: string = '';

    @JsonProperty('since', String, true)
    since: string = '';
}

@JsonObject('GetUserRespone')
export class GetUserRespone extends JsonSerializable<GetUserRespone> implements IGetUserRespone {
    protected get classRef(): new () => GetUserRespone {
        return GetUserRespone;
    }

    @JsonProperty('name', String, true)
    name: string = '';

    @JsonProperty('company_slug', String, true)
    company_slug: string = '';

    @JsonProperty('phone', String, true)
    phone: string = '';

    @JsonProperty('birthday', String, true)
    birthday: string = '';

    @JsonProperty('email', String, false)
    email!: string;

    @JsonProperty('profile_picture', String, true)
    profile_picture: string = '';

    @JsonProperty('updated_on', String, false)
    updated_on!: string;

    @JsonProperty('date_joined', String, false)
    date_joined!: string;

    @JsonProperty('bio', String, true)
    bio: string = '';

    @JsonProperty('sex', String, true)
    sex: string = '';

    @JsonProperty('position', String, true)
    position: string = '';

    @JsonProperty('address', String, true)
    address: string = '';

    @JsonProperty('company', [UserCompany], true)
    company: UserCompany[] = [];
}
