import { yupResolver } from '@hookform/resolvers/yup';
import { Autocomplete, AutocompleteRenderInputParams, Button, TextField, Typography } from '@mui/material';
import { CircularProgressBackdrop } from 'components/Table/CircularProgressBackdrop';
import { ColumnMeta } from 'components/Table/type';
import { ConnectionState } from 'hooks/Async';
import _ from 'lodash';
import { JSONType } from 'models';
import moment from 'moment';
import { JSXElementConstructor, ReactElement, ReactNode, useCallback, useContext, useEffect, useMemo } from 'react';
import { Controller, ControllerFieldState, ControllerRenderProps, FieldValues, UseFormStateReturn, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { P, match } from 'ts-pattern';
import * as yup from 'yup';
import { TableContext } from '../context';
import { TableType } from './Type';
import useSnackBar, { SnackBarAlertEnum } from 'components/SnackBar/UISnackbar';

export const $TableForm = ({ columnsMeta, TableFormType }: { columnsMeta: { [key: string]: ColumnMeta }; TableFormType: TableType }) => {
    const {
        setInsertData,
        manipulateConnectionState,
        tableState,
        updateRows,
        setTableState,
        togglePanel,
        setCodeLabel,
        setNameInput,
        setUnitInput,
        nameInput,
        unitInput,
        listAutoFillName,
        listAutoFillUnit
    } = useContext(TableContext);
    // const intl = useIntl();
    const { showSnackBar } = useSnackBar();
    const defaultValues = useMemo(() => {
        return _.mapValues(columnsMeta, (value) => {
            if (value.inputType === 'number') return 0;
            else return '';
        });
    }, [columnsMeta]);

    const resetDefault = useCallback(() => {
        setCodeLabel('');
        setNameInput('');
        setUnitInput('');
        reset(defaultValues);
    }, []);

    const formSchema = yup
        .object(
            _.mapValues(columnsMeta, (meta, _key) => {
                let schema: yup.BaseSchema;
                switch (meta.inputType) {
                    case 'date':
                        schema = yup.date();
                        break;
                    case 'number':
                        schema = yup.number();
                        break;
                    default:
                        schema = yup.string();
                        break;
                }

                if (meta.isRequire) return schema.required();
                return schema.optional();
            })
        )
        .required();

    const { handleSubmit, control, reset, formState } = useForm({
        resolver: yupResolver(formSchema),
        mode: 'onSubmit',
        defaultValues,
        criteriaMode: 'firstError'
    });

    useEffect(() => {
        match(tableState)
            .with({ state: 'update', row: P.select() }, (row) => {
                reset({ ...row.data });
            })
            .with({ state: 'insert' }, () => {
                resetDefault();
            })
            .otherwise(() => {});
    }, [tableState]);

    const onSubmit = (data: JSONType) => {
        match(tableState)
            .with({ state: 'insert' }, () => {
                const json = _.mapValues(data, (value, _key) => {
                    if (value instanceof Date) return value;
                    if (_key === 'name' || _key === 'item_name') return nameInput ?? '';
                    if (_key === 'unit') return unitInput ?? '';
                    return `${value}`;
                });
                setInsertData(json);
                togglePanel();
            })
            .with({ state: 'update', row: P.select() }, ({ data: initialData, index }) => {
                const editedField = _.mapValues(formState.dirtyFields, (_, key) => {
                    return data[key];
                });
                setTableState({
                    state: 'update',
                    row: {
                        index,
                        data: {
                            id: initialData.id,
                            ...editedField
                        }
                    }
                });
                updateRows();
                togglePanel();
            })
            .otherwise(() => {});
        resetDefault();
    };

    const onSubmitError = (errors: any, e?: any) => {
        showSnackBar(errors, SnackBarAlertEnum.error);
    };

    const onCancel = () => {
        match(tableState)
            .with({ state: 'update' }, (_) => {
                setTableState({ state: 'read' });
            })
            .with({ state: 'insert' }, (_) => {
                setTableState({ state: 'read' });
            })
            .otherwise((_) => reset());
        resetDefault();
    };

    return (
        <div className=" tw-h-full tw-mx-[16px] tw-relative">
            {manipulateConnectionState === ConnectionState.waiting ? <CircularProgressBackdrop /> : <></>}
            <div className="tw-h-full tw-overflow-y-scroll">
                <div className={'tw-mt-[12px] tw-pb-[56px] '}>
                    <form>
                        {Object.entries(columnsMeta).map(([key, value]) => {
                            if (key === 'name' || key === 'item_name') {
                                return (
                                    <div className="tw-mb-[8px]" key={key}>
                                        <Typography>
                                            {/* {intl.formatMessage({
                                                id: `${TableFormType}.${key}`
                                            })} */}
                                            {key}
                                        </Typography>
                                        <Controller
                                            control={control}
                                            name={key}
                                            render={function ({
                                                field
                                            }: {
                                                field: ControllerRenderProps<FieldValues, string>;
                                            }): ReactElement<any, string | JSXElementConstructor<any>> {
                                                return (
                                                    <Autocomplete
                                                        freeSolo={true}
                                                        disablePortal={true}
                                                        options={listAutoFillName ?? []}
                                                        value={nameInput}
                                                        onChange={(event, value) => {
                                                            field.onChange(event, value);
                                                        }}
                                                        renderInput={function (params: AutocompleteRenderInputParams): ReactNode {
                                                            return (
                                                                <TextField
                                                                    {...params}
                                                                    {...field}
                                                                    name={key}
                                                                    onBlur={(event) => {
                                                                        setNameInput(event.target.value);
                                                                    }}
                                                                />
                                                            );
                                                        }}
                                                    />
                                                );
                                            }}
                                        />
                                    </div>
                                );
                            } else if (key === 'unit') {
                                return (
                                    <div className="tw-mb-[8px]" key={key}>
                                        <Typography>
                                            {/* {intl.formatMessage({
                                                id: `${TableFormType}.${key}`
                                            })} */}
                                            {key}
                                        </Typography>
                                        <Controller
                                            control={control}
                                            name={key}
                                            render={function ({
                                                field
                                            }: {
                                                field: ControllerRenderProps<FieldValues, string>;
                                            }): ReactElement<any, string | JSXElementConstructor<any>> {
                                                return (
                                                    <Autocomplete
                                                        freeSolo={true}
                                                        options={listAutoFillUnit ?? []}
                                                        value={unitInput}
                                                        onChange={(event, value) => {
                                                            field.onChange(event, value);
                                                        }}
                                                        renderInput={function (params: AutocompleteRenderInputParams): ReactNode {
                                                            return (
                                                                <TextField
                                                                    {...params}
                                                                    {...field}
                                                                    name={key}
                                                                    onBlur={(event) => {
                                                                        setUnitInput(event.target.value);
                                                                    }}
                                                                />
                                                            );
                                                        }}
                                                    />
                                                );
                                            }}
                                        />
                                    </div>
                                );
                            } else {
                                return (
                                    <div className="tw-mb-[8px]" key={key}>
                                        <Typography>
                                            {/* {intl.formatMessage({
                                                id: `${TableFormType}.${key}`
                                            })} */}
                                            {key}
                                        </Typography>
                                        <Controller
                                            name={key}
                                            control={control}
                                            render={({ field: { ref, onChange, value: textVal }, fieldState: { error } }) => {
                                                return (
                                                    <TextField
                                                        name={key}
                                                        value={textVal}
                                                        inputRef={ref}
                                                        onChange={onChange}
                                                        error={!_.isNil(error)}
                                                        helperText={error?.message}
                                                        placeholder={key}
                                                        type={value.inputType}
                                                        fullWidth
                                                        size="small"
                                                        onBlur={() => {
                                                            if (textVal && key === 'code') {
                                                                setCodeLabel(textVal);
                                                            }
                                                        }}
                                                    />
                                                );
                                            }}
                                        />
                                    </div>
                                );
                            }
                        })}
                    </form>
                </div>
            </div>
            <div className="tw-flex tw-bg-white tw-flex-row tw-justify-end tw-absolute tw-bottom-0 tw-right-[18px] tw-left-0">
                <Button variant="text" onClick={onCancel}>
                    Cancel
                </Button>
                <div className="tw-w-[12px]" />
                <Button
                    variant="contained"
                    disabled={!formState.isDirty || !formState.isValid}
                    onClick={handleSubmit(onSubmit, onSubmitError)}
                >
                    Submit
                </Button>
            </div>
        </div>
    );
};
