import { Result, resultGuard } from 'models';
import { StatementQueryParams } from '../type';
import { AutoFillParams } from 'components';
import { JSONType } from 'models';
import { PhysicalCountingData } from 'models/PhysicalCountingTable';
import { PhysicalCountingWHDataSource } from 'repository';
import { StatementImportParams } from './type';

export class PhysicalCountingWHRepository {
    constructor(private datasource: PhysicalCountingWHDataSource) {}
    public async getAutoFillData(data: AutoFillParams) {
        return this.datasource.getAutoFill(data);
    }

    public async getAll(params: StatementQueryParams) {
        return this.datasource.getAll(params);
    }

    public async importData(value: StatementImportParams): Promise<Result<void>> {
        return resultGuard(async () => {
            await this.datasource.importData(value);
        });
    }

    public async delete(ids: string[], params?: StatementQueryParams) {
        return this.datasource.delete(ids);
    }

    public async update(data: Partial<PhysicalCountingData>, params: StatementQueryParams) {
        return this.datasource.update(data, params);
    }

    public async insert(data: JSONType) {
        return this.datasource.insert(data);
    }

    public async importFile(data?: JSONType) {
        throw new Error('Method not implemented.');
    }

    public async getFixedHeader(): Promise<Result<string[]>> {
        return resultGuard(async () => {
            return this.datasource.getFixedHeader();
        });
    }

    public async setupImport(data: { [key: string]: any }) {
        return resultGuard(async () => {
            await this.datasource.setupImport(data);
        });
    }
}
