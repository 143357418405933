import { JsonObject, JsonProperty } from 'json2typescript';
import { JsonSerializable } from 'models/serialization';

export interface ICancleData {
    id: string;
    hs: string;
    account_number: string;
    code: string;
    type_code: string;
    unit: string;
    unit_2: string;
    item_name: string;
    from_country: string;
    partner: string;
    invoice_no: string;
    total: number;
    total_2: number;
    unit_price: number;
    unit_price_taxed: number;
    nt_value: number;
    total_value: number;
    tax_rate: number;
    tax_cost: number;
    vat_cost: number;
    bill_date: string;
    registered_date: string;
    contract: string;
    contract_date: string;
    row_number: string;
}

@JsonObject('CancleData')
export class CancleData extends JsonSerializable<CancleData> implements ICancleData {
    protected get classRef(): new () => CancleData {
        return CancleData;
    }

    @JsonProperty('id', String, false)
    id!: string;

    @JsonProperty('code', String, false)
    code!: string;

    @JsonProperty('account_number', String, false)
    account_number!: string;

    @JsonProperty('registered_date', String, true)
    registered_date: string = '';

    @JsonProperty('type_code', String, true)
    type_code: string = '';

    @JsonProperty('hs', String, true)
    hs: string = '';

    @JsonProperty('item_name', String, true)
    item_name: string = '';

    @JsonProperty('from_country', String, true)
    from_country: string = '';

    @JsonProperty('unit_price', Number, false)
    unit_price!: number;

    @JsonProperty('unit_price_taxed', Number, false)
    unit_price_taxed!: number;

    @JsonProperty('total', Number, false)
    total!: number;

    @JsonProperty('unit', String, true)
    unit: string = '';

    @JsonProperty('unit', String, true)
    unit_2: string = '';

    @JsonProperty('nt_value', Number, false)
    nt_value!: number;

    @JsonProperty('total_value', Number, false)
    total_value!: number;

    @JsonProperty('tax_rate', Number, false)
    tax_rate!: number;

    @JsonProperty('tax_cost', Number, false)
    tax_cost!: number;

    @JsonProperty('partner', String, false)
    partner!: string;

    @JsonProperty('invoice_no', String, false)
    invoice_no!: string;

    @JsonProperty('bill_date', String, true)
    bill_date: string = '';

    @JsonProperty('contract', String, true)
    contract: string = '';

    @JsonProperty('contract_date', String, true)
    contract_date: string = '';

    @JsonProperty('total_2', Number, false)
    total_2!: number;

    @JsonProperty('vat_cost', Number, false)
    vat_cost!: number;

    @JsonProperty('row_number', String, false)
    row_number!: string;
}
