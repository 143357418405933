import { JsonObject, JsonProperty } from 'json2typescript';
import { JsonSerializable } from '../serialization';
export interface IIobAccountingData {
    id: string;
    code: string;
    name: string;
    unit: string;
    warehouse_name: string;
    take_in_quantity: number;
    take_in_value: number;
    take_out_quantity: number;
    take_out_value: number;
    begin_quantity: number;
    begin_value: number;
    end_quantity: number;
    end_value: number;
}

@JsonObject('IobData')
export class IobData extends JsonSerializable<IobData> implements IIobAccountingData {
    protected get classRef(): new () => IobData {
        return IobData;
    }

    @JsonProperty('id', String, false)
    id!: string;

    @JsonProperty('code', String, false)
    code!: string;

    @JsonProperty('name', String, true)
    name: string = '';

    @JsonProperty('unit', String, true)
    unit: string = '';

    @JsonProperty('warehouse_name', String, true)
    warehouse_name: string = '';

    @JsonProperty('take_in_quantity', Number, true)
    take_in_quantity: number = 0;

    @JsonProperty('take_in_value', Number, true)
    take_in_value: number = 0;

    @JsonProperty('take_out_quantity', Number, true)
    take_out_quantity: number = 0;

    @JsonProperty('take_out_value', Number, true)
    take_out_value: number = 0;

    @JsonProperty('begin_quantity', Number, true)
    begin_quantity: number = 0;

    @JsonProperty('begin_value', Number, true)
    begin_value: number = 0;

    @JsonProperty('end_quantity', Number, true)
    end_quantity: number = 0;

    @JsonProperty('end_value', Number, true)
    end_value: number = 0;
}
