import { JsonObject, JsonProperty } from 'json2typescript';
import { SubFolder } from './subfolderData';

export interface IFolderSet {
    slug: string;
    name: string;
    subfolders: SubFolder[];
    company_name: string;
    type_code: string;
    folder_type: string;
    company_id: number;
    last_updated: string;
    date_start?: string;
    date_end?: string;
    year: number;
}

@JsonObject('FolderSet')
export class FolderSet implements IFolderSet {
    @JsonProperty('slug', String, true)
    slug: string = '';

    @JsonProperty('name', String, true)
    name: string = '';

    @JsonProperty('subfolders', [SubFolder], true)
    subfolders: SubFolder[] = [];

    @JsonProperty('company_name', String, true)
    company_name: string = '';

    @JsonProperty('type_code', String, true)
    type_code: string = '';

    @JsonProperty('folder_type', String, true)
    folder_type: string = '';

    @JsonProperty('company_id', Number, true)
    company_id: number = 0;

    @JsonProperty('last_updated', String, true)
    last_updated: string = '';

    @JsonProperty('date_start', String, true)
    date_start?: string;

    @JsonProperty('date_end', String, true)
    date_end?: string;

    @JsonProperty('year', Number, true)
    year: number = 0;
}
