import { createContext, useContext } from 'react';

export const HsContext = createContext<{
    Section: any;
    Chapter: any;
    Header: any;
    SupHeader: any;
    TitilePage: string | any;
    isSearch: boolean | any;
    Year: string | null;
    SelectedHs: any;
    SearchResult: any;
    isProductSearch: boolean | any;
}>({
    Section: null,
    Chapter: null,
    Header: null,
    SupHeader: null,
    TitilePage: null,
    isSearch: null,
    Year: null,
    SelectedHs: null,
    SearchResult: null,
    isProductSearch: null
});
export const useHsContext = () => useContext(HsContext);
