import { fetchAndActivate, getRemoteConfig, getValue } from 'firebase/remote-config';
import { firApp } from 'utils/fireBase/firebase';
const _remoteConfig = getRemoteConfig(firApp);

const CONSTANST = {
    dev_endpoint: 'dev_endpoint'
};

_remoteConfig.settings.minimumFetchIntervalMillis = 0;
_remoteConfig.defaultConfig = {
    [CONSTANST.dev_endpoint]: 'https://dev-be.eximhow.com'
};

const initialize = async () => {
    await fetchAndActivate(_remoteConfig);
};

const getDevEndpoint = () => {
    return getValue(_remoteConfig, CONSTANST.dev_endpoint).asString();
};

export const remoteConfig = {
    initialize,
    getDevEndpoint
};
