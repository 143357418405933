import { createSlice } from '@reduxjs/toolkit';
const detectLang = () => {
    if (localStorage.getItem('language') === 'en' || localStorage.getItem('language') === 'vi' || localStorage.getItem('language') === 'ko')
        return localStorage.getItem('language');
    if (window.navigator.language === 'en-US') return 'en';
    if (window.navigator.language === 'vi') return 'vi';
    if (window.navigator.language === 'ko') return 'ko';
    return 'vi';
};
const initialState = {
    loading: false,
    lang: detectLang()
};

export const globalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        setLoading: (state, { payload }) => {
            state.loading = payload;
        },
        setLanguage: (state, { payload }) => {
            state.lang = payload;
        }
    }
});

export const { setLoading, setLanguage } = globalSlice.actions;

export default globalSlice.reducer;
