import { resultGuard } from 'models';
import { ReportStatement15DS } from '.';
import { ReportStatementQueryParams } from 'repository/type';

export class ReportStatement15Repo {
    constructor(private datasource: ReportStatement15DS) {}

    async getAll(params: ReportStatementQueryParams) {
        return resultGuard(async () => this.datasource.getAll(params));
    }
    async getFixedHeader() {
        return resultGuard(async () => this.datasource.getFixedHeader());
    }

    async setupImport(data: { [key: string]: any }) {
        return resultGuard(async () => this.datasource.setupImport(data));
    }

    async importData(data: any) {
        return resultGuard(async () => this.datasource.importData(data));
    }
}
