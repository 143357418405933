import { JsonObject, JsonProperty } from 'json2typescript';
import { JsonSerializable } from '../serialization';

export interface IStatement15Data {
    id: string;
    import_record_id: number;
    created_at: string;
    company_slug_id: number;
    folder_slug_id: number;
    code: string;
    name: string;
    unit: string;
    begin_value: number;
    take_in: number;
    re_export: number;
    re_purpose: number;
    export_to_produce: number;
    other_export: number;
    end_value: number;
    note: string;
}

@JsonObject('Statement15Data')
export class Statement15Data extends JsonSerializable<Statement15Data> implements IStatement15Data {
    protected get classRef(): new () => Statement15Data {
        return Statement15Data;
    }

    @JsonProperty('id', String, false)
    id!: string;

    @JsonProperty('import_record_id', Number, true)
    import_record_id: number = 0;

    @JsonProperty('created_at', String, true)
    created_at: string = '';

    @JsonProperty('company_slug_id', Number, true)
    company_slug_id: number = 0;

    @JsonProperty('folder_slug_id', Number, true)
    folder_slug_id: number = 0;

    @JsonProperty('code', String, true)
    code: string = '';

    @JsonProperty('name', String, true)
    name: string = '';

    @JsonProperty('unit', String, true)
    unit: string = '';

    @JsonProperty('note', String, true)
    note: string = '';

    @JsonProperty('begin_value', Number, true)
    begin_value: number = 0;

    @JsonProperty('take_in', Number, true)
    take_in: number = 0;

    @JsonProperty('re_export', Number, true)
    re_export: number = 0;

    @JsonProperty('re_purpose', Number, true)
    re_purpose: number = 0;

    @JsonProperty('export_to_produce', Number, true)
    export_to_produce: number = 0;

    @JsonProperty('other_export', Number, true)
    other_export: number = 0;

    @JsonProperty('end_value', Number, true)
    end_value: number = 0;
}
