import {BeginData, BeginSetupDTO, JSONType, Result, resultGuard} from 'models';
import {BeginQueryParams} from '../type';
import {BeginDataSource} from './BeginDataSource';
import {BeginImportParams, IBeginImportDTO} from './type';
import {AutoFillParams} from 'components';

export class BeginRepository {
    constructor(private datasource: BeginDataSource) {
    }

    public async getAll(params: BeginQueryParams) {
        return this.datasource.getAll(params);
    }

    public async deleteRows(ids: string[]) {
        return this.datasource.delete(ids);
    }

    public async updateRow(row: Partial<BeginData>, params: BeginQueryParams) {
        return this.datasource.update(row, params);
    }

    public async insertRow(row: JSONType) {
        return this.datasource.insert(row);
    }

    public async importData(value: BeginImportParams): Promise<Result<void>> {
        return resultGuard(async () => {
            await this.datasource.importData(value);
        });
    }

    public async getAutoFillData(data: AutoFillParams) {
        return this.datasource.getAutoFill(data);
    }

    public async setupImport(data: { [key: string]: any }) {
        return resultGuard<void>(async () => {
                await this.datasource.setupImport(data)
            }
        )
    }

    public async getFixedHeader(): Promise<Result<string[]>> {
        return resultGuard(async () => {
            return this.datasource.getFixedHeader();
        });
    }
}
