import {ConnectionState, useAsync} from 'hooks/Async';
import {PagingData} from 'models';
import {BalanceParams, BaseQueryParams, FieldsQueryParams, PagingQueryParams} from 'repository/type';
import produce from 'immer';
import _, {get} from 'lodash';
import {useContext, useEffect, useMemo, useRef} from 'react';
import {match} from 'ts-pattern';
import {TableContext} from '../context';
import {CompanyContext} from "../../../contexts";

export const useQueryTable = <T,  P extends  PagingQueryParams & FieldsQueryParams>({
                                                                                      params,
                                                                                      fetchFn
                                                                                  }: {
    params:P,
    fetchFn: (args: P) => Promise<PagingData<T>>;

}) => {
    const {
        pagingState,
        sorting,
        setDataCount,
        pagingController,
        setAsyncData,
        refreshId,
        setManipuateConnectionState,
        q,
        extQ
    } =
        useContext(TableContext);
    const offsetResetFlag = useRef(false);
    const capturedFieldsQuery = useRef<FieldsQueryParams>({
        ordering: undefined
    });

    const {execute, tableData} = useAsync<PagingData<T>, P>((params) => fetchFn(params));

    useEffect(() => {
        match(tableData)
            .with({state: ConnectionState.hasData}, ({data}) => {
                setDataCount(data.count);
            })
            .otherwise((_) => {
            });
    }, [tableData, pagingState]);

    useEffect(() => {
        setAsyncData(tableData);
        match(tableData)
            .with({state: ConnectionState.hasData}, () => {
                setManipuateConnectionState(ConnectionState.none);
            })
            .with({state: ConnectionState.hasError}, () => {
                setManipuateConnectionState(ConnectionState.none);
            })
            .otherwise(() => {
            });
    }, [tableData]);

    const memoizedParam = useMemo(() => {
        return produce<any, P>(params, (draft) => {
            draft.offset = pagingState.pageIndex * pagingState.pageSize;
            draft.limit = pagingState.pageSize;

            const ordering = sorting
                .reduce<string[]>((acc, curr) => {
                    const sign = curr.desc ? '-' : '';
                    acc.push(`${sign}${curr.id}`);
                    return acc;
                }, [])
                .join(',');
            if (ordering.length !== 0) draft.ordering = ordering;
            draft.q = q;
            draft = _.merge(draft, extQ);
        });
    }, [params, pagingState, sorting, q, extQ]);

    useEffect(() => {
        // ignore duplicate fetch cause by reset paging
        // if (offsetResetFlag.current) {
        //   offsetResetFlag.current = false
        //   return
        // }

        // const queryParam = produce(memoizedParam, (draft) => {
        // draft.offset = 0
        // const currentFieldQuery: FieldsQueryParams = {
        //   ordering: draft.ordering,
        // }
        // // if currentFieldQuery !== prevFieldQUery -> force reset paging
        // if (
        //   !_.isEqual(currentFieldQuery, capturedFieldsQuery.current) &&
        //   draft.offset !== 0
        // ) {
        //   draft.offset = 0
        //   offsetResetFlag.current = true
        // }
        // });

        execute(memoizedParam);
        // .then((value) => {
        //   return value
        // })
        // .finally(() => {
        //   if (offsetResetFlag.current) {
        //     pagingController?.setPageIndex && pagingController.setPageIndex(0)
        //   }
        //   // capture new fieldQuery
        //   capturedFieldsQuery.current = {
        //     ordering: queryParam.ordering,
        //   }
        // })
    }, [memoizedParam, refreshId]);
};
