import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// material-ui
import { useTheme } from '@mui/material/styles';
import { AppBar, Box, IconButton, ClickAwayListener, Grid, Paper, Popper, Toolbar, useMediaQuery } from '@mui/material';
import { Button } from '@mui/material';
import { Menu, Flag } from '@mui/icons-material';
// project imports
import LocalizationSection from '../LocalizationSection';
import Transitions from 'ui-component/extended/Transitions';
import LogoSection from 'layout/MainLayout/LogoSection';
import ProfileSection from '../ProfileSection';
import { openDrawer } from 'store/slices/menu';

// assets
import { IconDotsVertical } from '@tabler/icons';

// ==============================|| MOBILE HEADER ||============================== //

const MobileSection = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const matchMobile = useMediaQuery(theme.breakpoints.down('md'));
    const { drawerOpen } = useSelector((state) => state.menu);

    const [open, setOpen] = useState(false);
    /**
     * anchorRef is used on different componets and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef<any>(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: MouseEvent | TouchEvent) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <Toolbar
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                backgroundColor: 'white',
                padding: '0 1rem',
            }}
        >
            {/* Left Section: Menu Icon */}
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <IconButton sx={{
                    display: { xs: 'flex', md: 'none' },
                    width: '30px',
                    height: '30px',
                    alignItems: 'center',
                    color: theme.palette.primary.main,
                    border: '0.2px solid',
                    borderColor: '#aed9fb',
                    borderRadius: "5px",
                }}>
                    <Menu onClick={() => dispatch(openDrawer(!drawerOpen))}
                    />
                </IconButton>
                <IconButton>
                    <LocalizationSection />
                </IconButton>
            </Box>

            {/* Center Section: Logo */}
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexGrow: 1 }}>
                <LogoSection />
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', }}>
                <ProfileSection />
            </Box>
          
        </Toolbar>
    );
};

export default MobileSection;
