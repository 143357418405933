import { SetupParams } from 'components';
import {
    PhysicalCountingEnum,
    Statement15ATypeCodeEnum,
    Statement15TypeCodeEnum,
    StatementTypeEnum,
    TableStatementSubTab
} from 'components/BalanceTable';
import { VoidCallBack, voidCallback } from 'components/BalanceTable/TableInvoice';
import { Result } from 'models/Result';
import { createContext, useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import useGenerateExcel, { xlxsItem } from '../hooks/useGenerateExcel';
import useReportStatement15Import from '../hooks/useReportStatement15Import';

export interface Report15ContextInterface {
    isOpenModal: boolean;
    setOpenModal: voidCallback;
    reportList: null | Object[];
    step: number;
    setStep: voidCallback;

    statementType: string;
    setStatementType: voidCallback;
    statement15TypeCode: string;
    setStatement15TypeCode: voidCallback;
    statement15ATypeCode: string;
    setStatement15ATypeCode: voidCallback;
    physicalCounting: string;
    setPhysicalCounting: voidCallback;

    selectedFile: File | null;
    setSelectedFile: voidCallback;
    workBook: XLSX.WorkBook | null;
    setWorkBook: voidCallback;
    selectedSheet: string;
    setSelectedSheet: voidCallback;
    header: string[] | undefined;
    setHeader: voidCallback;
    setRowIndex: (from: number, to: number) => void;
    setEndRow: voidCallback;
    isSelectHeader: boolean;
    setIsSelectHeader: voidCallback;
    isDisableSelectHeader: boolean;
    setDisableSelectHeader: voidCallback;
    isSubmitStep4: boolean;
    setSubmitStep4: voidCallback;
    isDisableSubmitBtn: boolean;
    setDisableSubmitBtn: voidCallback;

    getFixedHeader: () => Promise<Result<string[]>>;
    setupImport: (props: SetupParams) => Promise<Result<any>>;
    setSetupSuccessful: voidCallback;
    subTabDropdown: string;
    setSubTabDropdown: voidCallback;

    count: number;
    setCount: voidCallback;
    limit: number;
    offset: number;
    setOffset: voidCallback;
    year: string;
    setYear: voidCallback;
    isRefresh: boolean;
    setRefresh: voidCallback;
    rawData: any[][] | null;
    setRawData: voidCallback;

    BCQT: null | xlxsItem;
}

export const Report15Context = createContext<Report15ContextInterface>({
    isOpenModal: false,
    setOpenModal: VoidCallBack,
    reportList: null,
    step: 0,
    setStep: VoidCallBack,

    statementType: '',
    setStatementType: VoidCallBack,
    statement15TypeCode: '',
    setStatement15TypeCode: VoidCallBack,
    statement15ATypeCode: '',
    setStatement15ATypeCode: VoidCallBack,
    physicalCounting: '',
    setPhysicalCounting: VoidCallBack,

    selectedFile: null,
    setSelectedFile: VoidCallBack,
    workBook: null,
    setWorkBook: VoidCallBack,
    selectedSheet: '',
    setSelectedSheet: VoidCallBack,
    header: undefined,
    setHeader: VoidCallBack,
    setRowIndex: (from: number, to: number) => {},
    setEndRow: VoidCallBack,
    isSelectHeader: false,
    setIsSelectHeader: VoidCallBack,
    isDisableSelectHeader: false,
    setDisableSelectHeader: VoidCallBack,
    isSubmitStep4: false,
    setSubmitStep4: VoidCallBack,
    isDisableSubmitBtn: true,
    setDisableSubmitBtn: VoidCallBack,

    getFixedHeader: () => Promise.reject('Unimplemented'),
    setupImport: (_) => Promise.reject('Unimplemented'),
    setSetupSuccessful: VoidCallBack,
    subTabDropdown: '',
    setSubTabDropdown: VoidCallBack,

    count: 0,
    setCount: VoidCallBack,
    limit: 0,
    offset: 0,
    setOffset: VoidCallBack,
    year: '',
    setYear: VoidCallBack,
    isRefresh: false,
    setRefresh: VoidCallBack,
    rawData: null,
    setRawData: VoidCallBack,

    BCQT: null
});

function Report15Provider({ children }: { children: React.ReactNode }) {
    const reportList = [{}];

    //* MODAL
    const [step, setStep] = useState<number>(0);
    const [isOpenModal, setOpenModal] = useState(false);
    const [statementType, setStatementType] = useState<string>(StatementTypeEnum.statement15);
    const [statement15TypeCode, setStatement15TypeCode] = useState<string>(Statement15TypeCodeEnum.e11);
    const [statement15ATypeCode, setStatement15ATypeCode] = useState<string>(Statement15ATypeCodeEnum.e42);
    const [physicalCounting, setPhysicalCounting] = useState<string>(PhysicalCountingEnum.acc);
    const [isSelectHeader, setIsSelectHeader] = useState(false);
    const [isDisableSelectHeader, setDisableSelectHeader] = useState(true);
    const [setupSuccessful, setSetupSuccessful] = useState(false);
    const [isSubmitStep4, setSubmitStep4] = useState(false);
    const [isDisableSubmitBtn, setDisableSubmitBtn] = useState(true);
    const [year, setYear] = useState('');

    //* TABLE
    const [subTabDropdown, setSubTabDropdown] = useState<string>(TableStatementSubTab.bcgq);
    const [count, setCount] = useState(0);
    const [limit] = useState(50);
    const [offset, setOffset] = useState(0);
    const [isRefresh, setRefresh] = useState(false);

    const {
        selectedFile,
        setSelectedFile,
        selectedSheet,
        setSelectedSheet,
        workBook,
        setWorkBook,
        header,
        setHeader,
        setRowIndex,
        setEndRow,
        getFixedHeader,
        setupImport,
        rawData,
        setRawData
    } = useReportStatement15Import({
        statementType,
        statement15TypeCode,
        statement15ATypeCode,
        physicalCounting,
        setupSuccessful,
        setSetupSuccessful,
        year,
        setOpenModal,
        setRefresh
    });

    const { BCQT } = useGenerateExcel({ statementType, statement15TypeCode, statement15ATypeCode, physicalCounting });

    function RESET_DEFAULT() {
        setStep(0);
        setStatementType(StatementTypeEnum.statement15);
        setStatement15TypeCode(Statement15TypeCodeEnum.e11);
        setStatement15ATypeCode(Statement15ATypeCodeEnum.e42);
        setPhysicalCounting(PhysicalCountingEnum.acc);
        setIsSelectHeader(false);
        setDisableSelectHeader(true);
        setSetupSuccessful(false);
        setSubmitStep4(false);
        setDisableSubmitBtn(true);
        setYear('');
        setOffset(0);
        setWorkBook(null);
        setSelectedFile(null);
    }

    useEffect(() => {
        if (!isOpenModal) {
            // RESET_DEFAULT();
        }
    }, [isOpenModal]);

    return (
        <Report15Context.Provider
            value={{
                isOpenModal,
                setOpenModal,
                reportList,
                step,
                setStep,
                statementType,
                setStatementType,
                statement15TypeCode,
                setStatement15TypeCode,
                statement15ATypeCode,
                setStatement15ATypeCode,
                physicalCounting,
                setPhysicalCounting,
                selectedFile,
                setSelectedFile,
                workBook,
                setWorkBook,
                selectedSheet,
                setSelectedSheet,
                header,
                setHeader,
                setRowIndex,
                setEndRow,
                isSelectHeader,
                setIsSelectHeader,
                isDisableSelectHeader,
                setDisableSelectHeader,
                getFixedHeader,
                setupImport,
                setSetupSuccessful,
                subTabDropdown,
                setSubTabDropdown,
                count,
                setCount,
                limit,
                offset,
                setOffset,
                isSubmitStep4,
                setSubmitStep4,
                isDisableSubmitBtn,
                setDisableSubmitBtn,
                year,
                setYear,
                isRefresh,
                setRefresh,
                rawData,
                setRawData,
                BCQT
            }}
        >
            {children}
        </Report15Context.Provider>
    );
}

export default Report15Provider;
