import {BalanceImportDataSource} from "repository/BalanceImportDataSource";
import {Http} from "../../../api";
import {BalanceParams, BaseQueryParams, BomQueryParams} from "../../type";
import {BomData} from "../../../models";
import {AnnexData} from "../../../models/AnnexTable";
import {SheetMeta} from "../../../components";

export class AnnexDataSource extends BalanceImportDataSource<SheetMeta & BalanceParams> {
    path: string = "";
    importUrl = "api/fta/annex/import_data/";
    setupUrl = "api/fta/annex/setup/"
    fixedHeaderUrl = "api/fta/annex/get_fixed_columns/"

    constructor(public http: Http) {
        super(http);
    }

    public async getAll(params: BaseQueryParams) {
        const response = await this.http.instance.get('/api/fta/annex/', {
            params
        });
        return new AnnexData().fromPaging<BomData>(response.data);
    }

    public async delete(ids: string[]) {
        return this.http.instance.delete(`/api/fta/annex/bulk-delete/`, {
            data: {id_list: ids}
        });
    }
}