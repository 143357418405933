import { AutoFillParams } from 'components';
import { JSONType, TakeoutAccountingData, Result, resultGuard } from 'models';
import { OutputQueryParams } from '../type';
import { TakeoutAccountingDataSource } from './TakeoutAccountingDataSource';
import { OutputImportParams } from './type';

export class TakeoutAccountingRepository {
    constructor(private datasource: TakeoutAccountingDataSource) {}

    public async getAll(params: OutputQueryParams) {
        return this.datasource.getAll(params);
    }

    public async deleteRows(ids: string[]) {
        return this.datasource.delete(ids);
    }

    public async updateRow(row: Partial<TakeoutAccountingData>, params: OutputQueryParams) {
        return this.datasource.update(row, params);
    }

    public async insertRow(row: JSONType) {
        return this.datasource.insert(row);
    }

    public async importData(value: OutputImportParams): Promise<Result<void>> {
        return resultGuard(async () => {
            await this.datasource.importData(value);
        });
    }

    public async setupImport(data: { [key: string]: any }) {
        return resultGuard(async () => {
            await this.datasource.setupImport(data);
        });
    }

    public async getFixedHeader(): Promise<Result<string[]>> {
        return resultGuard(async () => {
            return this.datasource.getFixedHeader();
        });
    }

    public async getAutoFillData(data: AutoFillParams) {
        return this.datasource.getAutoFill(data);
    }
}
