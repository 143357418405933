import { JsonObject, JsonProperty } from 'json2typescript';

export interface ISubFolder {
    slug: string;
    name: string;
}

@JsonObject('SubFolder')
export class SubFolder implements ISubFolder {
    @JsonProperty('slug', String, true)
    slug: string = '';

    @JsonProperty('name', String, true)
    name: string = '';
}
