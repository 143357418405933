import { JsonProperty, JsonObject } from 'json2typescript';
import { JsonSerializable } from 'models/serialization';

export interface PSRDataInterface {
    id: string;
    hs: string;
    AJ: string;
    AK: string;
    VJ: string;
    AANZ: string;
    AHK: string;
    CPTPP: string;
    RCEP: string;
    VC: string;
    VK: string;
    AC: string;
    EUEA: string;
    EV: string;
    UKV: string;
    ATIGA: string;
}

@JsonObject('PSRData')
export class PSRData extends JsonSerializable<PSRData> implements PSRDataInterface {
    protected get classRef(): new () => PSRData {
        return PSRData;
    }

    @JsonProperty('id', String, true)
    id: string = '';

    @JsonProperty('hs', String, true)
    hs: string = '';

    @JsonProperty('AJ', String, true)
    AJ: string = '';

    @JsonProperty('AK', String, true)
    AK: string = '';

    @JsonProperty('VJ', String, true)
    VJ: string = '';

    @JsonProperty('AANZ', String, true)
    AANZ: string = '';

    @JsonProperty('AHK', String, true)
    AHK: string = '';

    @JsonProperty('CPTPP', String, true)
    CPTPP: string = '';

    @JsonProperty('RCEP', String, true)
    RCEP: string = '';

    @JsonProperty('VC', String, true)
    VC: string = '';

    @JsonProperty('VK', String, true)
    VK: string = '';

    @JsonProperty('AC', String, true)
    AC: string = '';

    @JsonProperty('EUEA', String, true)
    EUEA: string = '';

    @JsonProperty('EV', String, true)
    EV: string = '';

    @JsonProperty('UKV', String, true)
    UKV: string = '';

    @JsonProperty('ATIGA', String, true)
    ATIGA: string = '';
}
