import { Http } from 'api';
import { AmaData } from 'models';
import { AmaSetupDTO } from 'models/AmaTable/AmaSetupDTO';
import { AmaCancleQueryParams } from 'repository/type';

export class AmaDataSource {
    constructor(public http: Http) {}

    async getAll(params: AmaCancleQueryParams) {
        const response = await this.http.instance.get('/api/balance/ecus-ama/', { params });
        return new AmaData().fromPaging<AmaData>(response.data);
    }

    async setUp(data: AmaSetupDTO) {
        return await this.http.instance.post('/api/balance/ecus/ama_setup/', data.toJSON());
    }

    public async importData(data: any) {
        try {
            var bodyFormData = new FormData();

            Object.keys(data).map((e) => bodyFormData.append(e, data[e]));

            await this.http.instance.post(`/api/balance/ecus/ama_import_data/`, bodyFormData, {
                headers: { 'content-type': 'multipart/form-data' }
            });
            return { response: 'SUCCESS' };
        } catch (e) {
            return { error: e };
        }
    }
}
