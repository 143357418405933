import { useForm } from 'react-hook-form'
import ImportExcel from './importExcel'
import { useContext, useEffect, useState } from 'react'
import { read } from 'xlsx'
import { SpreadSheetContext } from './context'

export const ImportSheet = () => {
  const { setValue } = useForm({})

  const { setWorkBook, setSelectedFile, selectedFile } =
    useContext(SpreadSheetContext)

  useEffect(() => {
    if (selectedFile) {
      var reader = new FileReader()
      reader.onload = function (ev) {
        var content = ev?.target?.result // content is a string
        let sheet = read(content, { type: 'buffer' })
        setWorkBook(sheet)
        // utils.sheet_to_json(wb.Sheets[wb.SheetNames[1]], { header: 1 })
      }
      reader.onerror = function (err) {
        console.error('Failed to read file', err)
      }
      reader.readAsArrayBuffer(selectedFile)
    }
  }, [selectedFile])

  return (
    <ImportExcel
      setValue={setValue}
      selectedFile={selectedFile}
      setSelectedFile={setSelectedFile}
      error={null}
    />
  )
}
