import { BangKeDataInterface } from 'models/BangKe';
import { InvoiceProductDataInterface } from 'models/InvoiceTable/InvoiceProductData';
import { PSRDataInterface } from 'models/PSR';
import { useContext, useEffect, useMemo, useState } from 'react';
import { DropDownApplicableEnum, DropDownEXWInsertTypeEnum } from '.';
import { FormTypeEnum, InvoiceContext } from '../InvoiceProvider';
import {
    ConvertFormType,
    FormCFR,
    FormCIF,
    FormCIP,
    FormCPT,
    FormDAP,
    FormDDP,
    FormDDU,
    FormEXW,
    FormFAS,
    FormFCA,
    FormFOB
} from './EXWFormLists';
import _ from 'lodash';

export interface FormularFormProps {
    item: InvoiceProductDataInterface;
    index: number;
    bangKeData: BangKeDataInterface[] | null;
}

export interface FormularFormRef {
    submit: (_?: any) => void;
}

export function ConvertOption(insertType: DropDownEXWInsertTypeEnum, formType: FormTypeEnum) {
    switch (insertType) {
        case DropDownEXWInsertTypeEnum.formular:
            return ConvertFormType(formType);
        case DropDownEXWInsertTypeEnum.progess_price:
            return DropDownEXWInsertTypeEnum.progess_price;
        default:
            return DropDownEXWInsertTypeEnum.user_input;
    }
}

export function RevertBangKeDataEXWOption(opt: string) {
    switch (opt) {
        case 'progressPriceInput':
            return DropDownEXWInsertTypeEnum.progess_price;
        case 'userInput':
            return DropDownEXWInsertTypeEnum.user_input;
        default:
            return DropDownEXWInsertTypeEnum.formular;
    }
}

export function RevertBangKeDataFormType(form_type: string): DropDownApplicableEnum {
    switch (form_type) {
        case 'CTC':
            return DropDownApplicableEnum.CTC;
        default:
            return DropDownApplicableEnum.RVC;
    }
}

const FormularFormEXW = ({ item, index, bangKeData }: FormularFormProps) => {
    const { psrData } = useContext(InvoiceContext);
    const [checkBangKeData, setCheckBangKeData] = useState<BangKeDataInterface[] | null>(null);

    const psrItem = useMemo(() => {
        const itemHsCode = item.hs.slice(0, 6);
        if (psrData) {
            return psrData.filter((psrItem: PSRDataInterface) => {
                if (psrItem.hs === itemHsCode) {
                    return psrItem;
                }
            })[0];
        } else return null;
    }, [item, psrData]);

    const bangKeItem = useMemo(() => {
        return checkBangKeData ? checkBangKeData[index] : null;
    }, [checkBangKeData, index]);

    useEffect(() => {
        if (bangKeData) {
            setCheckBangKeData((prev: BangKeDataInterface[] | null) => {
                if (prev === null) return bangKeData;
                else {
                    if (_.isEqual(prev, bangKeData)) return prev;
                    else return bangKeData;
                }
            });
        }
    }, [bangKeData]);

    switch (item.invoice_price_condition) {
        case 'FOB':
            return <FormFOB key={item.id} item={item} psrItem={psrItem} index={index + 1} bangKeItem={bangKeItem} />;
        case 'FCA':
            return <FormFCA key={item.id} item={item} psrItem={psrItem} index={index + 1} bangKeItem={bangKeItem} />;
        case 'FAS':
            return <FormFAS key={item.id} item={item} psrItem={psrItem} index={index + 1} bangKeItem={bangKeItem} />;
        case 'CFR':
            return <FormCFR key={item.id} item={item} psrItem={psrItem} index={index + 1} bangKeItem={bangKeItem} />;
        case 'CIF':
            return <FormCIF key={item.id} item={item} psrItem={psrItem} index={index + 1} bangKeItem={bangKeItem} />;
        case 'CPT':
            return <FormCPT key={item.id} item={item} psrItem={psrItem} index={index + 1} bangKeItem={bangKeItem} />;
        case 'CIP':
            return <FormCIP key={item.id} item={item} psrItem={psrItem} index={index + 1} bangKeItem={bangKeItem} />;
        case 'DAP':
            return <FormDAP key={item.id} item={item} psrItem={psrItem} index={index + 1} bangKeItem={bangKeItem} />;
        case 'DDU':
            return <FormDDU key={item.id} item={item} psrItem={psrItem} index={index + 1} bangKeItem={bangKeItem} />;
        case 'DDP':
            return <FormDDP key={item.id} item={item} psrItem={psrItem} index={index + 1} bangKeItem={bangKeItem} />;

        default:
            return <FormEXW item={item} psrItem={psrItem} index={index + 1} bangKeItem={bangKeItem} />;
    }
};

export default FormularFormEXW;
