import { InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { Box } from '@mui/system';
import styled from './style.module.css';

export enum DropDownApplicableEnum {
    CTC = 'CTC',
    RVC = 'RVC'
}

export enum DropDownEXWInsertTypeEnum {
    user_input = 'userInput',
    formular = 'formular',
    progess_price = 'progressPriceInput'
}

export enum DropDownFOBInsertTypeEnum {
    user_input = 'userInput',
    formular = 'formular'
}

export enum DropDownType {
    fob = 'fob',
    exw = 'exw'
}

export interface DropDownApplicableProps {
    dropDownApplicable: DropDownApplicableEnum;
    setDropDownApplicable: (_: DropDownApplicableEnum) => void;
    index?: number;
    sedo?: boolean;
}

export function DropDownFormularApplicableCriteria({ dropDownApplicable, setDropDownApplicable, index, sedo }: DropDownApplicableProps) {
    const _index = index ?? 0;
    const useSedo = sedo ?? false;

    function ChangeHandler(event: SelectChangeEvent) {
        setDropDownApplicable(event.target.value as DropDownApplicableEnum);
    }

    return (
        <div className={styled['step2-dropdown']}>
            <h3 className={styled['step2-label']}>{_index === 1 ? 'Form type' : ' '}</h3>
            <Select id="dropdown-applicalble" value={dropDownApplicable} onChange={ChangeHandler}>
                <MenuItem disabled={useSedo} value={DropDownApplicableEnum.CTC}>
                    CTC
                </MenuItem>
                <MenuItem value={DropDownApplicableEnum.RVC}>RVC</MenuItem>
            </Select>
        </div>
    );
}

export interface DropDownInsertTypeProps {
    insertType: DropDownEXWInsertTypeEnum | DropDownFOBInsertTypeEnum;
    setInsertType: any;
    type?: DropDownType;
    sedo?: boolean;
    index?: number;
}

export function DropDownInsertType({ insertType, setInsertType, type, sedo, index }: DropDownInsertTypeProps) {
    const dropDownType = type ?? DropDownType.fob;
    const useSedo = sedo ?? false;
    const _index = index ?? 0;

    function ChangeHandler(event: SelectChangeEvent) {
        setInsertType(event.target.value as DropDownEXWInsertTypeEnum);
    }

    return (
        <>
            <Box className={styled['step2-dropdown']}>
                <h3 className={styled['step2-label']}>{_index === 1 ? 'Insert type' : ' '}</h3>
                <Select
                    id="dropdown-insert-type"
                    labelId="dropdown-insert-type"
                    value={insertType}
                    onChange={ChangeHandler}
                    sx={{ width: '100px' }}
                >
                    <MenuItem value={DropDownEXWInsertTypeEnum.user_input}>User Input</MenuItem>
                    <MenuItem value={DropDownEXWInsertTypeEnum.formular}>Formular</MenuItem>
                    <MenuItem
                        disabled={dropDownType === DropDownType.exw && useSedo ? false : true}
                        value={DropDownEXWInsertTypeEnum.progess_price}
                    >
                        Progess Price
                    </MenuItem>
                </Select>
            </Box>
        </>
    );
}
