import { createContext } from 'react';
import { TableType } from 'components';

export const HeaderContext = createContext<{
    tableState: TableType;
    setTableState: (item: TableType) => void;
}>({
    tableState: 'ecus' as TableType,
    setTableState: () => {}
});
