import { Http } from 'api';
import { GetSheetHeaderDTO } from './type';
import produce from 'immer';
import { SheetMeta } from 'components';
import _ from 'lodash';

export class BalanceDataSource {
    constructor(public http: Http) {}

    async getSheetHeader(params: GetSheetHeaderDTO): Promise<string[][]> {
        const body = produce<GetSheetHeaderDTO, SheetMeta & { use_cols: string }>(params, (draft) => {
            draft.use_cols = `${params.colStart}:${params.colEnd}`;
            draft = _.omit(draft, 'colStart', 'colEnd');
        });
        const bodyFormData = new FormData();

        Object.entries(body).map(([key, value]) => {
            if (_.isNumber(value)) {
                bodyFormData.append(key, `${value}`);
            } else bodyFormData.append(key, value as string);
        });

        const response = await this.http.instance.post('/api/balance/get-excel-headers/', bodyFormData, {
            headers: { 'content-type': 'multipart/form-data' }
        });
        if (!_.isObject(response.data)) throw Error('Invalid list header');
        const result: string[][] = [];
        for (const item of _.values(response.data)) {
            if (_.isString(item)) {
                result.push([item]);
            } else {
                if (!_.isArray(item) || !_.every(item, String)) continue;
                result.push(item as string[]);
            }
        }
        return result;
    }
}

export class BalanceNewDataSource {
    constructor(public http: Http) {}

    async getSheetHeader(params: GetSheetHeaderDTO): Promise<string[][]> {
        const body = produce<GetSheetHeaderDTO, SheetMeta & { use_cols: string }>(params, (draft) => {
            draft.use_cols = `${params.colStart}:${params.colEnd}`;
            draft = _.omit(draft, 'colStart', 'colEnd');
        });
        const bodyFormData = new FormData();

        Object.entries(body).map(([key, value]) => {
            if (_.isNumber(value)) {
                bodyFormData.append(key, `${value}`);
            } else bodyFormData.append(key, value as string);
        });

        const response = await this.http.instance.post('/api/new-balance/get-excel-headers/', bodyFormData, {
            headers: { 'content-type': 'multipart/form-data' }
        });
        if (!_.isObject(response.data)) throw Error('Invalid list header');
        const result: string[][] = [];
        for (const item of _.values(response.data)) {
            if (_.isString(item)) {
                result.push([item]);
            } else {
                if (!_.isArray(item) || !_.every(item, String)) continue;
                result.push(item as string[]);
            }
        }
        return result;
    }
}
