// routing
import Routes from 'routes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import Locales from 'ui-component/Locales';

// import RTLLayout from 'ui-component/RTLLayout';
import Snackbar from 'ui-component/extended/Snackbar';
import ThemeCustomization from './themes/index';

// auth provider
import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
import 'handsontable/dist/handsontable.full.min.css';
import { registerAllModules } from 'handsontable/registry';
import { CompanyProvider, HsProvider } from './contexts/';
import { AppProvider } from './contexts/AppContext/AppProvider';

registerAllModules();
// import { FirebaseProvider as AuthProvider } from 'contexts/FirebaseContext';
// import { AWSCognitoProvider as AuthProvider } from 'contexts/AWSCognitoContext';
// import { Auth0Provider as AuthProvider } from 'contexts/Auth0Context';

// ==============================|| APP ||============================== //

const App = () => (
    <ThemeCustomization>
        {/* RTL layout */}
        {/* <RTLLayout> */}
        <Locales>
            <NavigationScroll>
                <AuthProvider>
                    <AppProvider>
                        <CompanyProvider>
                            <HsProvider>
                                <Routes />
                                <Snackbar />
                            </HsProvider>
                        </CompanyProvider>
                    </AppProvider>
                </AuthProvider>
            </NavigationScroll>
        </Locales>
        {/* </RTLLayout> */}
    </ThemeCustomization>
);

export default App;
