import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    MenuItem,
    Select,
    Typography,
    IconButton,
    Menu,
} from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { setLanguage as setGlobalLanguage } from 'store/Global';
import useConfig from 'hooks/useConfig';
import VN from 'assets/images/localization/VN.png';
import KOR from 'assets/images/localization/KOR.png';
import ENG from 'assets/images/localization/ENG.png';

const LocalizationSelect = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const isMobile = useMediaQuery(theme.breakpoints.down('md')); // Check if screen size is small

    const { locale, onChangeLocale } = useConfig();
    const { lang } = useSelector((state: any) => state.global);

    const [language, setLanguage] = useState<string>(locale);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    useEffect(() => {
        setLanguage(locale);
    }, [locale]);

    const handleLanguageChange = (selectedLanguage) => {
        setLanguage(selectedLanguage);
        localStorage.setItem('language', selectedLanguage);
        dispatch(setGlobalLanguage(selectedLanguage)); // Update language in Redux
        onChangeLocale(selectedLanguage); // Update locale in your system
        setAnchorEl(null); // Close the menu
    };

    const getFlagSrc = (lang) => {
        switch (lang) {
            case 'en': return ENG;
            case 'vi': return VN;
            case 'ko': return KOR;
            default: return ENG;
        }
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            {isMobile ? (
                // Mobile version with a clickable flag that opens a menu
                <Box sx={{
                    border: '0.2px solid',
                    borderColor: '#aed9fb',
                    borderRadius: "5px",
                    width: '30px',
                    height: '30px',
                }}>
                    <IconButton
                        onClick={handleClick}
                        sx={{
                            padding: '5px',
                            borderRadius: '50%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <img
                            src={getFlagSrc(language)}
                            alt={language}
                            style={{ width: '100%', height: '100%', borderRadius: '50%' }}
                        />
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        sx={{mt: 1}}
                    >
                        <MenuItem onClick={() => handleLanguageChange('en')}>
                            <Box display="flex" alignItems="center">
                                <img
                                    src={ENG}
                                    alt="English"
                                    style={{ width: 20, height: 20, marginRight: 8 }}
                                />
                                <Typography>EN</Typography>
                            </Box>
                        </MenuItem>
                        <MenuItem onClick={() => handleLanguageChange('vi')}>
                            <Box display="flex" alignItems="center">
                                <img
                                    src={VN}
                                    alt="Vietnamese"
                                    style={{ width: 20, height: 20, marginRight: 8 }}
                                />
                                <Typography>VI</Typography>
                            </Box>
                        </MenuItem>
                        <MenuItem onClick={() => handleLanguageChange('ko')}>
                            <Box display="flex" alignItems="center">
                                <img
                                    src={KOR}
                                    alt="Korean"
                                    style={{ width: 20, height: 20, marginRight: 8 }}
                                />
                                <Typography>KO</Typography>
                            </Box>
                        </MenuItem>
                    </Menu>
                </Box>
            ) : (
                // Desktop version with Select
                <Select
                    value={language}
                    onChange={(event) => handleLanguageChange(event.target.value)}
                    sx={{
                        mr: 4,
                        width: 100,
                        background: '#ffffff',
                        border: 'none',
                        borderRadius: '0px',
                        padding: '0 2px',
                        fontSize: '1rem',
                        '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
                        '& .MuiSelect-icon': { display: 'none' },
                        '&:active': { background: '#ffffff' },
                    }}
                    renderValue={(selected) => (
                        <Box display="flex" alignItems="center">
                            <img
                                src={getFlagSrc(selected)}
                                alt={selected}
                                style={{ width: 40, height: 40, marginRight: 8 }}
                            />
                            <Typography
                                sx={{
                                    fontSize: '1.17em',
                                    color: '#2196f3',
                                    fontWeight: 'bold',
                                }}
                            >
                                {selected.toUpperCase()}
                            </Typography>
                        </Box>
                    )}
                    MenuProps={{
                        PaperProps: { sx: { backgroundColor: '#ffffff' } },
                    }}
                >
                    <MenuItem value="en">
                        <Box display="flex" alignItems="center">
                            <img
                                src={ENG}
                                alt="English"
                                style={{ width: 20, height: 20, marginRight: 8 }}
                            />
                            <Typography>EN</Typography>
                        </Box>
                    </MenuItem>
                    <MenuItem value="vi">
                        <Box display="flex" alignItems="center">
                            <img
                                src={VN}
                                alt="Vietnamese"
                                style={{ width: 20, height: 20, marginRight: 8 }}
                            />
                            <Typography>VI</Typography>
                        </Box>
                    </MenuItem>
                    <MenuItem value="ko">
                        <Box display="flex" alignItems="center">
                            <img
                                src={KOR}
                                alt="Korean"
                                style={{ width: 20, height: 20, marginRight: 8 }}
                            />
                            <Typography>KO</Typography>
                        </Box>
                    </MenuItem>
                </Select>
            )}
        </>
    );
};

export default LocalizationSelect;
