import { SheetMeta } from 'components';
import { GetSheetHeaderDTO } from './type';
import { BalanceDataSource } from './BalanceDataSource';
import { Result, resultGuard } from 'models';

export class BalanceRepository {
    constructor(private balanceDS: BalanceDataSource) {}

    async getSheetHeader(params: GetSheetHeaderDTO): Promise<Result<string[]>> {
        return resultGuard(async () => {
            const response = await this.balanceDS.getSheetHeader(params);
            return response.map((value) => value.join('|'));
        });
    }
}

export class BalanceNewRepository {
    constructor(private balanceDS: BalanceDataSource) {}

    async getSheetHeader(params: GetSheetHeaderDTO): Promise<Result<string[]>> {
        return resultGuard(async () => {
            const response = await this.balanceDS.getSheetHeader(params);
            return response.map((value) => value.join('|'));
        });
    }
}
