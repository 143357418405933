import {BalanceParams} from "../../repository";

export type SheetRow = (string | number | null)[]
export const space_key = '#space#'

export type SheetMeta = {
    header_from: number
    header_to?: number
    sheet_name: string
    excel_file: File,
    end_row: number,
}

export type ImportExcelProps = {
    setValue: any;
    error: any;
    selectedFile: any;
    setSelectedFile: any;
};

export type UploadFileProps = {
    isdragaccept: any;
    isdragreject: any;
    isfocused: any;
};

export enum Primitive {
    date = "date",
}

export type ExtraInputField = string[] | Primitive
export type ExtraInputType = { [key: string]: ExtraInputField };

export type SheetPreviewRef = {
    getHeader: () => string[] | undefined
}

export type SetupParams = { [key: string]: any } & BalanceParams;
