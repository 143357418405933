import { JsonObject, JsonProperty } from 'json2typescript';
import { JsonSerializable } from '../serialization';
export interface ITakeinAccountingData {
    id: string;
    code: string;
    name: string;
    unit: string;
    warehouse_name: string;
    output_date: string;
    bill_date: string;
    doc_number: string;
    bill_no: string;
    explain: string;
    created_at: string;
    unit_price: number;
    import_quantity: number;
    import_value: number;
    balance_id: number;
    company_slug_id: number;
}

@JsonObject('TakeinAccountingData')
export class TakeinAccountingData extends JsonSerializable<TakeinAccountingData> implements ITakeinAccountingData {
    protected get classRef(): new () => TakeinAccountingData {
        return TakeinAccountingData;
    }

    @JsonProperty('id', String, false)
    id!: string;

    @JsonProperty('code', String, false)
    code!: string;

    @JsonProperty('name', String, true)
    name: string = '';

    @JsonProperty('unit', String, true)
    unit: string = '';

    @JsonProperty('warehouse_name', String, true)
    warehouse_name: string = '';

    @JsonProperty('output_date', String, true)
    output_date: string = '';

    @JsonProperty('bill_date', String, true)
    bill_date: string = '';

    @JsonProperty('doc_number', String, true)
    doc_number: string = '';

    @JsonProperty('bill_no', String, true)
    bill_no: string = '';

    @JsonProperty('explain', String, true)
    explain: string = '';

    @JsonProperty('created_at', String, true)
    created_at: string = '';

    @JsonProperty('unit_price', Number, true)
    unit_price: number = 0;

    @JsonProperty('import_quantity', Number, true)
    import_quantity: number = 0;

    @JsonProperty('import_value', Number, true)
    import_value: number = 0;

    @JsonProperty('balance_id', Number, true)
    balance_id: number = 0;

    @JsonProperty('company_slug_id', Number, true)
    company_slug_id: number = 0;
}
