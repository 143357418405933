import { JsonSerializable } from '../serialization';
import { JsonObject, JsonProperty } from 'json2typescript';
export interface IBeginData {
    id: string;
    item_type: string;
    code: string;
    begin_value: number;
    name?: string;
    from_date?: string;
    unit?: string;
    contract?: string;
}

@JsonObject('BeginData')
export class BeginData extends JsonSerializable<BeginData> implements IBeginData {
    protected get classRef(): new () => BeginData {
        return BeginData;
    }

    static empty(): BeginData {
        return new BeginData().fromJSON({
            id: new Date().getMilliseconds(),
            item_type: '',
            code: '',
            begin_value: 0,
            name: '',
            from_date: '',
            unit: '',
            contract: ''
        });
    }

    @JsonProperty('id', String, false)
    id!: string;

    @JsonProperty('item_type', String, true)
    item_type!: string;

    @JsonProperty('code', String, false)
    code!: string;

    @JsonProperty('begin_value', Number, false)
    begin_value!: number;

    @JsonProperty('name', String, true)
    name?: string;

    @JsonProperty('from_date', String, true)
    from_date?: string;

    @JsonProperty('unit', String, true)
    unit?: string;

    @JsonProperty('contract', String, true)
    contract?: string;
}
