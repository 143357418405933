import {AnnexDataSource} from "./AnnexDataSource";
import {BalanceParams, BaseQueryParams} from "../../type";
import {SheetMeta} from "../../../components";
import {Result, resultGuard} from "../../../models";

export class AnnexRepository {
    constructor(private datasource: AnnexDataSource) {
    }

    getAll(params: BaseQueryParams) {
        return this.datasource.getAll(params)
    }

    importData(data: any) {
        return resultGuard(() => this.datasource.importData(data))
    }

    getFixedHeader(): Promise<Result<string[]>> {
        return resultGuard(async () => {
          //  return ["exporter", "import_declaration_number", "part_number"]
            return this.datasource.getFixedHeader();
        });
    }

    public async setupImport(data: { [key: string]: any }) {
        return resultGuard(async () => {
            await this.datasource.setupImport(data);
        });
    }

    public async deleteRows(ids: string[]) {
        return this.datasource.delete(ids);
    }
}