import { SheetMeta } from './type';
import ToastNotification from 'components/ToastNotification/ToastNotification';
import { Exception, FolderSet, Result, ResultState } from 'models';
import { setLoading } from 'store/Global';
import { nanoid } from '@reduxjs/toolkit';
import {useContext, useEffect, useMemo, useRef, useState} from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { match, P } from 'ts-pattern';
import { TableContext } from '../Table';
import { CompanyContext } from '../../contexts';
import {useSelector} from "../../store/reducer";
import produce from "immer";
import {BalanceParams} from "../../repository";
import {get} from "lodash";

export const useBalanceImport = <P extends SheetMeta>({
    shouldRefreshAfterFinish = true,
    importFn
}: {
    importFn: (params: P & SheetMeta) => Promise<Result<any>>;
    shouldRefreshAfterFinish?: boolean;
}) => {
    const dispatch = useDispatch();

    const restorationId = useRef('');
    const [open, setOpen] = useState(false);
    const { refresh } = useContext(TableContext);
    useEffect(() => {
        if (!open) restorationId.current = nanoid();
    }, [open]);
    const { companyDetail } = useContext(CompanyContext);

    const onFinish = async (params: P) => {
        dispatch(setLoading(true));
        if (!companyDetail) throw new Error('company is required');

        const result = await importFn({
            ...params,
            company_slug: companyDetail!.slug,
            folder_slug: companyDetail.folder_set ? companyDetail.folder_set[0].slug : ''
        });
        match(result)
            .with({ state: ResultState.success }, (_) => {
                setOpen(false);
            })
            .with({ state: ResultState.failed, exception: P.select() }, (e) => {
                const exception = Exception.parse(e);
                toast.error(<ToastNotification message={exception.meaning} />);
            })
            .exhaustive();
        dispatch(setLoading(false));
        if (shouldRefreshAfterFinish) refresh();
    };

    return { onFinish, open, restorationId: restorationId.current, setOpen };
};

export const useCompanyFolderSlug = () : BalanceParams => {
    const companyDetail = useSelector(state => state.company.detail)
    return useMemo(() => {
        return produce<{}, BalanceParams>({}, draft => {
            if (!companyDetail?.slug) throw Error("company_slug should not be null")
            draft.company_slug = companyDetail!.slug
            const folder_slug = get(companyDetail, "folder_set.0.slug")
            if (!folder_slug) throw Error("company_slug should not be null")
            draft.folder_slug = folder_slug
        }) as BalanceParams
    },[companyDetail])
}

export function useDebounce(value: any, delay: number) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    return debouncedValue;
}