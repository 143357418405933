import { animated, useSpring } from '@react-spring/web';
import { TableType } from 'components/Table';
import { SimpleTabs, TabProps } from 'components/Tabs';
import { useEffect, useMemo, useRef } from 'react';
import { BehaviorSubject, Subject, debounceTime } from 'rxjs';
import TableReportStatement15 from './Table15/TableReportStatement15';
import TableReportStatement15A from './Table15A/TableReportStatement15A';
import styled from './style.module.css';

function TabelReportStatement() {
    const TabPropsItem = useMemo((): TabProps[] => {
        return [
            { name: TableType.statement15, item: <TableReportStatement15 /> },
            { name: TableType.statement15a, item: <TableReportStatement15A /> }
        ];
    }, []);
    var subject = useMemo<Subject<number>>(() => {
        return new BehaviorSubject<number>(800);
    }, []);
    const ref = useRef(null);

    useEffect(() => {
        const subscription = subject.pipe(debounceTime(250)).subscribe((value) => {
            handleResize(value);
        });
        return () => subscription.unsubscribe();
    }, []);

    useEffect(() => {
        if (!ref.current) return;
        const resizeObserver = new ResizeObserver(() => {
            // @ts-ignore
            const width = ref?.current?.getBoundingClientRect()?.width;
            subject.next(width);
        });
        resizeObserver.observe(ref.current);
        return () => resizeObserver.disconnect(); // clean up
    }, []);

    const [springs, api] = useSpring(() => ({
        from: { width: 800 },
        config: {
            duration: 80
        }
    }));

    const handleResize = (to: number) => {
        api.start({
            to: {
                width: to
            }
        });
    };

    return (
        <div className={styled['tabs_div']} ref={ref}>
            <animated.div style={{ ...springs }}>
                <SimpleTabs data={TabPropsItem} />
            </animated.div>
        </div>
    );
}

export default TabelReportStatement;
