import { Box } from '@mui/material';
import ImportExcel from 'components/BalanceImport/importExcel';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as XLSX from 'xlsx';
import { InvoiceSheetModalProviderContext } from './InvoiceSheetModalProvider';

function InvoiceSheetImport() {
    const { setValue } = useForm({});
    const { setSelectedFile, setWorkBook } = useContext(InvoiceSheetModalProviderContext);
    const [workbook, setWorkbook] = useState<any>();

    useEffect(() => {
        if (workbook) {
            setSelectedFile(workbook);
            var reader = new FileReader();
            reader.onload = function (ev) {
                var content = ev?.target?.result;
                let workBook = XLSX.read(content, { type: 'buffer' });
                setWorkBook(workBook);
            };
            reader.onerror = function (err) {
                console.error('Failed to read file', err);
            };
            reader.readAsArrayBuffer(workbook);
        }
    }, [workbook]);

    return (
        <Box sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <ImportExcel setValue={setValue} selectedFile={workbook} setSelectedFile={setWorkbook} error={null} />
        </Box>
    );
}

export default InvoiceSheetImport;
