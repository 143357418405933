import { JsonObject, JsonProperty } from 'json2typescript';
import { JsonSerializable } from 'models/serialization';

export interface EcusStockDataInterface {
    id: string;
    code: string;
    hs: string;
    item_name: string;
    unit_price: number;
    unit: string;
}

@JsonObject('EcusStockData')
export class EcusStockData extends JsonSerializable<EcusStockData> implements EcusStockDataInterface {
    protected get classRef(): new () => EcusStockData {
        return EcusStockData;
    }

    @JsonProperty('id', String, false)
    id!: string;

    @JsonProperty('code', String, false)
    code!: string;

    @JsonProperty('hs', String, false)
    hs!: string;

    @JsonProperty('item_name', String, false)
    item_name: string = '';

    @JsonProperty('unit_price', Number, false)
    unit_price: number = 0;

    @JsonProperty('unit', String, false)
    unit: string = '';
}
