import { Box, Button } from '@mui/material';
import { FormTypeEnum } from 'components/BalanceTable/TableInvoice/InvoiceProvider';
import { SnackBarAlertEnum } from 'components/SnackBar';
import useSnackBar from 'components/SnackBar/UISnackbar';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { InvoiceWordContext } from '../../InvoiceWordProvider';
import { InvoiceSheetModalProviderContext } from '../InvoiceSheetModalProvider';

function InvoiceSheetStepButton() {
    const { wordFormType, sheetActiveStep, setSheetActiveStep, setWordFormType, isMissing, setMarkPackage, setRowPerPage } =
        useContext(InvoiceWordContext);
    const { workBook, setSelectHeader, submitStep2, setSubmit, setSubmitStep4, disableSubmitStep4 } =
        useContext(InvoiceSheetModalProviderContext);

    const {
        setWorkBook,
        selectedSheet,
        endRow,
        setEndRow,
        coList,
        setCOList,
        setSelectedSheet,
        setMergeFile1,
        setMergeFile2,
        setMergeFile3,
        revertData,
        setRevertData
    } = useContext(InvoiceSheetModalProviderContext);
    const { showSnackBar } = useSnackBar();

    const steps = useMemo(() => {
        switch (wordFormType) {
            case FormTypeEnum.VJ || FormTypeEnum.AJ:
                return ['Step 1', 'Step 2', 'Step 3', 'Step 4', 'Step 5', 'Step 6'];
            case FormTypeEnum.AC:
                return ['Step 1', 'Step 2', 'Step 3', 'Step 4', 'Step 5'];
            case FormTypeEnum.AI:
                return ['Step 1', 'Step 2', 'Step 3', 'Step 4', 'Step 5'];
            default:
                return ['Step 1', 'Step 2', 'Step 3', 'Step 4', 'Step 5'];
        }
    }, [wordFormType]);

    const handleNext = useCallback(() => {
        setSheetActiveStep((prevActiveStep: any) => prevActiveStep + 1);
    }, [sheetActiveStep]);

    function handleBackAC(sheetActiveStep: number) {
        if (sheetActiveStep === 0) {
        } else if (sheetActiveStep === 1) {
            setMarkPackage('');
            setRowPerPage('');
        } else if (sheetActiveStep === 2) {
            setWorkBook(null);
            setSelectedSheet(null);
        } else if (sheetActiveStep === 3) {
            setSelectedSheet(null);
            setEndRow(null);
        } else if (sheetActiveStep === 4) {
            setCOList(null);
            setMergeFile1(null);
            setMergeFile2(null);
            setMergeFile3(null);
        }
    }

    function handleBackAI(sheetActiveStep: number) {
        if (sheetActiveStep === 0) {
        } else if (sheetActiveStep === 1) {
            setMarkPackage('');
            setRowPerPage('');
        } else if (sheetActiveStep === 2) {
            setWorkBook(null);
            setSelectedSheet(null);
        } else if (sheetActiveStep === 3) {
            setSelectedSheet(null);
            setEndRow(null);
        } else if (sheetActiveStep === 4) {
            setCOList(null);
            setMergeFile1(null);
            setMergeFile2(null);
            setMergeFile3(null);
        }
    }

    // function handleBackVJ(sheetActiveStep: number) {
    //     if (sheetActiveStep === 0) {
    //     } else if (sheetActiveStep === 1) {
    //         setFieldState(null);
    //         setWordFormType(null);
    //     } else if (sheetActiveStep === 2) {
    //         setWorkBook(null);
    //         setSelectedSheet(null);
    //     } else if (sheetActiveStep === 3) {
    //         setSelectedSheet(null);
    //         setEndRow(null);
    //     } else if (sheetActiveStep === 4) {
    //         setCOList(null);
    //         setMergeFile1(null);
    //         setMergeFile2(null);
    //         setMergeFile3(null);
    //         setSubmitStep2(false);
    //     } else if (sheetActiveStep === 5) {
    //         setRevertData(null);
    //     }
    // }

    const handleBack = useCallback(() => {
        if (sheetActiveStep === 0) return;
        switch (wordFormType) {
            // case FormTypeEnum.VJ || FormTypeEnum.AJ:
            //     handleBackVJ(sheetActiveStep);
            //     break;

            case FormTypeEnum.AC:
                handleBackAC(sheetActiveStep);
                break;
            case FormTypeEnum.AI:
                handleBackAI(sheetActiveStep);
                break;
        }
        setSheetActiveStep((prevActiveStep: any) => prevActiveStep - 1);
    }, [sheetActiveStep, wordFormType]);

    const handleReset = () => {
        setSheetActiveStep(0);
    };

    const stepDisable = useMemo(() => {
        if (sheetActiveStep === 0 && !isMissing) return false;
        if (sheetActiveStep === 1 && workBook) return false;
        if (sheetActiveStep === 2 && selectedSheet && endRow) return false;
        if (sheetActiveStep === 3 && coList) return false;
        if (sheetActiveStep === 4 && revertData) return false;
        return true;
    }, [sheetActiveStep, workBook, selectedSheet, endRow, coList, revertData, isMissing]);

    function stepBackDisableFormVJ(sheetActiveStep: number, submitStep2: boolean) {
        if (sheetActiveStep === 0) {
            return true;
        } else if (sheetActiveStep === 5 && !submitStep2) {
            return true;
        }
        return false;
    }

    function stepBackDisableFormAC(sheetActiveStep: number) {
        if (sheetActiveStep === 0) {
            return true;
        } else if (sheetActiveStep === 4) {
            return true;
        }
        return false;
    }

    function stepBackDisableFormAI(sheetActiveStep: number) {
        if (sheetActiveStep === 0) {
            return true;
        } else if (sheetActiveStep === 4) {
            return true;
        }
        return false;
    }

    const stepBackDisable = useMemo(() => {
        switch (wordFormType) {
            // case FormTypeEnum.VJ || FormTypeEnum.AJ:
            //     return stepBackDisableFormVJ(sheetActiveStep, submitStep2);
            case FormTypeEnum.AC:
                return stepBackDisableFormAC(sheetActiveStep);

            case FormTypeEnum.AI:
                return stepBackDisableFormAI(sheetActiveStep);
            default:
                return false;
        }
    }, [sheetActiveStep, wordFormType]);

    const MiddleBtnDisable = useMemo(() => {
        let result = false;
        if ((sheetActiveStep === 2 && !selectedSheet) || !endRow) result = true;
        else if (sheetActiveStep === 3 && disableSubmitStep4) result = true;
        else if (sheetActiveStep === 4 && !submitStep2) result = true;
        return result;
    }, [sheetActiveStep, submitStep2, selectedSheet, endRow, disableSubmitStep4]);

    const MiddleBtnHandler = useCallback(() => {
        if (sheetActiveStep === 2) {
            setSelectHeader(true);
        } else if (sheetActiveStep === 3) {
            setSubmitStep4(true);
        } else if (sheetActiveStep === 4) {
            setSubmit(true);
        }
    }, [sheetActiveStep]);

    useEffect(() => {
        if (workBook) {
            showSnackBar('Import Success!', SnackBarAlertEnum.success);
        }
    }, [workBook]);

    return (
        <>
            {sheetActiveStep === steps.length ? (
                <>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: '6px' }}>
                        <Button onClick={handleReset}>Reset</Button>
                    </Box>
                </>
            ) : (
                <>
                    <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                        <Button color="inherit" disabled={stepBackDisable} onClick={handleBack}>
                            Back
                        </Button>
                        <Button disabled={MiddleBtnDisable} onClick={MiddleBtnHandler}>
                            {sheetActiveStep === 2
                                ? 'Select header'
                                : sheetActiveStep === 3
                                ? 'Select'
                                : sheetActiveStep === 4
                                ? 'Download'
                                : ''}
                        </Button>
                        <Button onClick={handleNext} disabled={stepDisable}>
                            {sheetActiveStep === steps.length - 1 ? 'Finish' : 'Next'}
                        </Button>
                    </Box>
                </>
            )}
        </>
    );
}

export default InvoiceSheetStepButton;
