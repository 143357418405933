export enum TableType {
    endingInventoryWH = 'EI-WareHouse',
    endingInventoryPR = 'EI-Production',
    endingInventoryACC = 'EI-Accounting',
    bom = 'BOM',
    takein = 'TAKE-IN',
    takeout = 'TAKE-OUT',
    purchase = 'PURCHASE',
    purchaseStock = 'PURCHASE-STOCK',
    ama = 'AMA',
    cancle = 'CANCLE',
    scrapNg = 'SCRAP/NG',
    iob = 'IOB',
    ecus = 'ECUS',
    begin = 'BEGIN',
    statement15 = 'STATEMENT 15',
    statement15a = 'STATEMENT 15A',
    cancel = 'CANCLE',
    output = 'OUTPUT',
    input = 'INPUT',
    production = 'PRODUCTION',
    sell = 'SELL',
    detail = 'DETAIL',
    annex = 'ANNEX',
    co = 'CO',
    ecus_stock = 'ECUS_STOCK',
    bom_exw = 'BOM-EXW',
    physicalAcc = 'PHYSICAL COUNTING',
    takeoutAccounting = 'TAKE-OUT',
    takeinAccounting = 'TAKE-IN',
    iobAccounting = 'IOB',
    physicalWH = 'PHYSICAL COUNTING',
    mapCode = 'map-code',
    reportStatement = 'CHECK STATEMENT',
    otherExport = 'OTHER-OUTPUT'
}
