import { yupResolver } from '@hookform/resolvers/yup';
import { Box, TextField } from '@mui/material';
import { BangKeDataInterface } from 'models/BangKe';
import { InvoiceProductDataInterface } from 'models/InvoiceTable/InvoiceProductData';
import { forwardRef, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { ConvertBangKeName, InvoiceContext } from '../InvoiceProvider';
import {
    DropDownApplicableEnum,
    DropDownFOBInsertTypeEnum,
    DropDownFormularApplicableCriteria,
    DropDownInsertType
} from './DropDownFormulars';
import { FormInputProps, FormProps, UserInput, UserInputInterface } from './EXWFormLists';
import { FormularFormRef, RevertBangKeDataFormType } from './FormularFormEXW';
import { ConvertFOBOption, RevertBangKeDataFOBOption } from './FormularFormOthers';
import styled from './style.module.css';
import _ from 'lodash';

interface FormFobInputInterface {
    nt_value: number;
}

interface FormFobInputProps {
    item: InvoiceProductDataInterface;
    fn: (item: FormFobInputInterface) => void;
}

const FormFobInput = forwardRef<FormularFormRef, any>(({ item, fn }: FormFobInputProps, ref) => {
    const { handleSubmit, control } = useForm({ defaultValues: { nt_value: item.nt_value } });

    const SubmitHandler: SubmitHandler<FormFobInputInterface> = (data: FormFobInputInterface) => {
        fn(data);
    };

    return (
        <form onChange={handleSubmit(SubmitHandler)} style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
            <Controller
                control={control}
                name="nt_value"
                render={({ field }) => <TextField variant="standard" type="number" {...field} disabled />}
            />
        </form>
    );
});

export interface FormFOBInterface {
    psr_input: string;
}

export const FormFOB = ({ item, psrItem, index, bangKeItem }: FormProps) => {
    const { formType, MapBangKeData } = useContext(InvoiceContext);
    const psrLabel = useMemo(() => {
        if (bangKeItem) return bangKeItem.criteria;
        else if (psrItem) return psrItem[formType] ?? '';
        else return '';
    }, [psrItem, bangKeItem]);
    const [insertType, setInsertType] = useState(DropDownFOBInsertTypeEnum.formular);
    const [dropDownApplicable, setDropDownApplicable] = useState<DropDownApplicableEnum>(DropDownApplicableEnum.CTC);
    const fobInputRef = useRef(null);
    const [fobInput, setFobInput] = useState<null | FormFobInputInterface>(null);
    function CollectFobInput(input: FormFobInputInterface) {
        setFobInput({ ...input });
    }
    const inputRef = useRef(null);
    const [userInput, setUserInput] = useState<null | UserInputInterface>(null);
    function CollectInput(input: UserInputInterface) {
        setUserInput({ ...input });
    }
    const [bangKeData, setBangKeData] = useState<null | BangKeDataInterface>(null);
    const [formData, setFormData] = useState<FormFOBInterface>({ psr_input: psrLabel ?? '' });

    useEffect(() => {
        const bangKeItem: BangKeDataInterface = {
            id: item.id,
            exchange_rate: item.exchange_rate,
            account_number: item.account_number,
            registered_date: item.registered_date,
            option: ConvertFOBOption(insertType, formType),
            form_type: dropDownApplicable,
            criteria: formData.psr_input,
            name: item.item_name,
            bill_date: item.bill_date,
            hs: item.hs,
            total: item.total,
            nt_value: item.nt_value,
            code: item.code,
            input: {}
        };

        if (insertType === DropDownFOBInsertTypeEnum.user_input) {
            bangKeItem.input = { result: userInput?.user_input ?? 0 };
        }
        const timeout = setTimeout(() => {
            setBangKeData((prev: BangKeDataInterface | null) => {
                if (prev === null) return bangKeItem;
                else {
                    if (_.isEqual(prev, bangKeItem)) return prev;
                    else {
                        return bangKeItem;
                    }
                }
            });
        }, 500);

        return () => clearTimeout(timeout);
    }, [insertType, dropDownApplicable, userInput, fobInput, formData]);

    useEffect(() => {
        if (bangKeData) {
            MapBangKeData(bangKeData);
        }
    }, [bangKeData]);

    useEffect(() => {
        if (bangKeItem) {
            if (RevertBangKeDataFOBOption(bangKeItem.option) === DropDownFOBInsertTypeEnum.user_input) {
                setInsertType(DropDownFOBInsertTypeEnum.user_input);
                setUserInput({ user_input: bangKeItem.input.result ?? 0 });
            } else {
                setInsertType(DropDownFOBInsertTypeEnum.formular);
            }
            setFormData({ psr_input: bangKeItem.criteria });
            setDropDownApplicable(RevertBangKeDataFormType(bangKeItem.form_type));
        }
    }, [bangKeItem]);

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '10px' }}>
            <div className={styled['no-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'No' : ' '}</h3>
                <h3>{index}</h3>
            </div>
            <div className={styled['code-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Code' : ' '}</h3>
                <h3>{item.code}</h3>
            </div>
            <div className={styled['name-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Name' : ' '}</h3>
                <h3>{ConvertBangKeName(item.item_name)}</h3>
            </div>
            <div className={styled['hs-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Hs' : ' '}</h3>
                <h3>{item.hs}</h3>
            </div>

            <div className={styled['criteria-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Criteria' : ' '}</h3>
                <TextField
                    value={formData.psr_input}
                    variant="standard"
                    sx={{ width: 200 }}
                    onChange={(e) => {
                        setFormData({ psr_input: e.target.value });
                    }}
                />
            </div>

            <DropDownInsertType insertType={insertType} setInsertType={setInsertType} index={index} />

            <div className={styled['hs-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Value' : ' '}</h3>
                {insertType === DropDownFOBInsertTypeEnum.user_input ? (
                    <Box>
                        <UserInput ref={inputRef} insertType={insertType} fn={CollectInput} prevUserInput={userInput} />
                    </Box>
                ) : (
                    <Box>
                        <FormFobInput ref={fobInputRef} item={item} fn={CollectFobInput} />
                    </Box>
                )}
            </div>

            <DropDownFormularApplicableCriteria
                dropDownApplicable={dropDownApplicable}
                setDropDownApplicable={setDropDownApplicable}
                index={index}
            />
        </Box>
    );
};

interface FormExwInputInterface {
    nt_value: number;
    domestic_expenses: number;
}

const FormExwInput = forwardRef<FormularFormRef, any>(
    ({ item, insertType, fn, prevInputItem }: FormInputProps<FormExwInputInterface>, ref) => {
        const schema = yup.object({
            nt_value: yup.number(),
            domestic_expenses: yup.number().required().min(0)
        });

        const { handleSubmit, control, reset } = useForm({
            defaultValues: { nt_value: item.nt_value, domestic_expenses: prevInputItem ? prevInputItem.domestic_expenses : 0 },
            resolver: yupResolver(schema)
        });

        const SubmitHandler: SubmitHandler<FormExwInputInterface> = (data: FormExwInputInterface) => {
            fn(data);
        };

        useEffect(() => {
            ////////// reset();
        }, [insertType]);

        return (
            <form onChange={handleSubmit(SubmitHandler)} style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                <Controller
                    control={control}
                    name="nt_value"
                    render={({ field }) => <TextField variant="standard" type="number" {...field} disabled />}
                />
                <div style={{ fontWeight: 'bold' }}> + </div>
                <Controller
                    control={control}
                    name="domestic_expenses"
                    render={({ field, formState: { errors } }) => (
                        <>
                            <TextField variant="standard" type="number" {...field} />
                            {errors.domestic_expenses && <span>This input is required</span>}
                        </>
                    )}
                />
            </form>
        );
    }
);

export interface FormEXWInterface {
    psr_input: string;
}

export const FormEXW = ({ item, psrItem, index, bangKeItem }: FormProps) => {
    const { formType, MapBangKeData } = useContext(InvoiceContext);
    const psrLabel = useMemo(() => {
        if (bangKeItem) return bangKeItem.criteria;
        else if (psrItem) return psrItem[formType] ?? '';
        else return '';
    }, [psrItem, bangKeItem]);
    const [insertType, setInsertType] = useState(DropDownFOBInsertTypeEnum.formular);
    const [dropDownApplicable, setDropDownApplicable] = useState<DropDownApplicableEnum>(DropDownApplicableEnum.CTC);
    const exwInputRef = useRef(null);
    const [exwInput, setExwInput] = useState<null | FormExwInputInterface>(null);
    function CollectExtInput(input: FormExwInputInterface) {
        setExwInput({ ...input });
    }

    const [userInput, setUserInput] = useState<null | UserInputInterface>(null);
    const inputRef = useRef(null);
    function CollectInput(input: UserInputInterface) {
        setUserInput({ ...input });
    }
    const [bangKeData, setBangKeData] = useState<null | BangKeDataInterface>(null);
    const [formData, setFormData] = useState<FormEXWInterface>({
        psr_input: psrLabel ?? ''
    });

    useEffect(() => {
        const bangKeItem: BangKeDataInterface = {
            id: item.id,
            exchange_rate: item.exchange_rate,
            account_number: item.account_number,
            registered_date: item.registered_date,
            option: ConvertFOBOption(insertType, formType),
            form_type: dropDownApplicable,
            criteria: formData.psr_input,
            name: item.item_name,
            bill_date: item.bill_date,
            hs: item.hs,
            total: item.total,
            nt_value: item.nt_value,
            code: item.code,
            input: { result: 0 }
        };
        if (insertType === DropDownFOBInsertTypeEnum.formular) {
            bangKeItem.input = { domestic_expenses: exwInput?.domestic_expenses ?? 0 };
        }
        if (insertType === DropDownFOBInsertTypeEnum.user_input) {
            bangKeItem.input = { result: userInput?.user_input ?? 0 };
        }
        const timeout = setTimeout(() => {
            setBangKeData((prev: BangKeDataInterface | null) => {
                if (prev === null) return bangKeItem;
                else {
                    if (_.isEqual(prev, bangKeItem)) return prev;
                    else {
                        return bangKeItem;
                    }
                }
            });
        }, 500);

        return () => clearTimeout(timeout);
    }, [insertType, dropDownApplicable, exwInput, userInput, formData]);

    useEffect(() => {
        if (bangKeData) {
            MapBangKeData(bangKeData);
        }
    }, [bangKeData]);

    useEffect(() => {
        if (bangKeItem) {
            if (RevertBangKeDataFOBOption(bangKeItem.option) === DropDownFOBInsertTypeEnum.user_input) {
                setInsertType(DropDownFOBInsertTypeEnum.user_input);
                setUserInput({ user_input: bangKeItem.input.result ?? 0 });
            } else {
                setInsertType(DropDownFOBInsertTypeEnum.formular);
                setExwInput({ nt_value: item.nt_value, domestic_expenses: bangKeItem.input.domestic_expenses ?? 0 });
            }
            setFormData({ psr_input: bangKeItem.criteria });
            setDropDownApplicable(RevertBangKeDataFormType(bangKeItem.form_type));
        }
    }, [bangKeItem]);

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '10px' }}>
            <div className={styled['no-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'No' : ' '}</h3>
                <h3>{index}</h3>
            </div>
            <div className={styled['code-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Code' : ' '}</h3>
                <h3>{item.code}</h3>
            </div>
            <div className={styled['name-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Name' : ' '}</h3>
                <h3>{ConvertBangKeName(item.item_name)}</h3>
            </div>
            <div className={styled['hs-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Hs' : ' '}</h3>
                <h3>{item.hs}</h3>
            </div>

            <div className={styled['criteria-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Criteria' : ' '}</h3>
                <TextField
                    value={formData.psr_input}
                    variant="standard"
                    sx={{ width: 200 }}
                    onChange={(e) => {
                        setFormData({ psr_input: e.target.value });
                    }}
                />
            </div>

            <DropDownInsertType insertType={insertType} setInsertType={setInsertType} index={index} />

            <div className={styled['hs-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Value' : ' '}</h3>
                {insertType === DropDownFOBInsertTypeEnum.user_input ? (
                    <Box>
                        <UserInput ref={inputRef} insertType={insertType} fn={CollectInput} prevUserInput={userInput} />
                    </Box>
                ) : (
                    <Box>
                        <FormExwInput item={item} insertType={insertType} ref={exwInputRef} fn={CollectExtInput} prevInputItem={exwInput} />
                    </Box>
                )}
            </div>

            <DropDownFormularApplicableCriteria
                dropDownApplicable={dropDownApplicable}
                setDropDownApplicable={setDropDownApplicable}
                index={index}
            />
        </Box>
    );
};

export const FormFCA = ({ item, psrItem, index, bangKeItem }: FormProps) => {
    const { formType, MapBangKeData } = useContext(InvoiceContext);
    const psrLabel = useMemo(() => {
        if (bangKeItem) return bangKeItem.criteria;
        else if (psrItem) return psrItem[formType] ?? '';
        else return '';
    }, [psrItem, bangKeItem]);
    const [insertType, setInsertType] = useState(DropDownFOBInsertTypeEnum.formular);
    const [dropDownApplicable, setDropDownApplicable] = useState<DropDownApplicableEnum>(DropDownApplicableEnum.CTC);
    const exwInputRef = useRef(null);
    const [exwInput, setExwInput] = useState<null | FormExwInputInterface>(null);
    function CollectExtInput(input: FormExwInputInterface) {
        setExwInput({ ...input });
    }

    const [userInput, setUserInput] = useState<null | UserInputInterface>(null);
    const inputRef = useRef(null);
    function CollectInput(input: UserInputInterface) {
        setUserInput({ ...input });
    }
    const [bangKeData, setBangKeData] = useState<null | BangKeDataInterface>(null);
    const [formData, setFormData] = useState<FormEXWInterface>({
        psr_input: psrLabel ?? ''
    });

    useEffect(() => {
        const bangKeItem: BangKeDataInterface = {
            id: item.id,
            exchange_rate: item.exchange_rate,
            account_number: item.account_number,
            registered_date: item.registered_date,
            option: ConvertFOBOption(insertType, formType),
            form_type: dropDownApplicable,
            criteria: formData.psr_input,
            name: item.item_name,
            bill_date: item.bill_date,
            hs: item.hs,
            total: item.total,
            nt_value: item.nt_value,
            code: item.code,
            input: { result: 0 }
        };
        if (insertType === DropDownFOBInsertTypeEnum.formular) {
            bangKeItem.input = { domestic_expenses: exwInput?.domestic_expenses ?? 0 };
        }
        if (insertType === DropDownFOBInsertTypeEnum.user_input) {
            bangKeItem.input = { result: userInput?.user_input ?? 0 };
        }
        const timeout = setTimeout(() => {
            setBangKeData((prev: BangKeDataInterface | null) => {
                if (prev === null) return bangKeItem;
                else {
                    if (_.isEqual(prev, bangKeItem)) return prev;
                    else {
                        return bangKeItem;
                    }
                }
            });
        }, 500);

        return () => clearTimeout(timeout);
    }, [insertType, dropDownApplicable, exwInput, userInput, formData]);

    useEffect(() => {
        if (bangKeData) {
            MapBangKeData(bangKeData);
        }
    }, [bangKeData]);

    useEffect(() => {
        if (bangKeItem) {
            if (RevertBangKeDataFOBOption(bangKeItem.option) === DropDownFOBInsertTypeEnum.user_input) {
                setInsertType(DropDownFOBInsertTypeEnum.user_input);
                setUserInput({ user_input: bangKeItem.input.result ?? 0 });
            } else {
                setInsertType(DropDownFOBInsertTypeEnum.formular);
                setExwInput({ nt_value: item.nt_value, domestic_expenses: bangKeItem.input.domestic_expenses ?? 0 });
            }
            setFormData({ psr_input: bangKeItem.criteria });
            setDropDownApplicable(RevertBangKeDataFormType(bangKeItem.form_type));
        }
    }, [bangKeItem]);

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '10px' }}>
            <div className={styled['no-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'No' : ' '}</h3>
                <h3>{index}</h3>
            </div>
            <div className={styled['code-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Code' : ' '}</h3>
                <h3>{item.code}</h3>
            </div>
            <div className={styled['name-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Name' : ' '}</h3>
                <h3>{ConvertBangKeName(item.item_name)}</h3>
            </div>
            <div className={styled['hs-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Hs' : ' '}</h3>
                <h3>{item.hs}</h3>
            </div>

            <div className={styled['criteria-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Criteria' : ' '}</h3>
                <TextField
                    value={formData.psr_input}
                    variant="standard"
                    sx={{ width: 200 }}
                    onChange={(e) => {
                        setFormData({ psr_input: e.target.value });
                    }}
                />
            </div>

            <DropDownInsertType insertType={insertType} setInsertType={setInsertType} index={index} />

            <div className={styled['hs-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Value' : ' '}</h3>
                {insertType === DropDownFOBInsertTypeEnum.user_input ? (
                    <Box>
                        <UserInput ref={inputRef} insertType={insertType} fn={CollectInput} prevUserInput={userInput} />
                    </Box>
                ) : (
                    <Box>
                        <FormExwInput item={item} insertType={insertType} ref={exwInputRef} fn={CollectExtInput} prevInputItem={exwInput} />
                    </Box>
                )}
            </div>

            <DropDownFormularApplicableCriteria
                dropDownApplicable={dropDownApplicable}
                setDropDownApplicable={setDropDownApplicable}
                index={index}
            />
        </Box>
    );
};

export const FormFAS = ({ item, psrItem, index, bangKeItem }: FormProps) => {
    const { formType, MapBangKeData } = useContext(InvoiceContext);
    const psrLabel = useMemo(() => {
        if (bangKeItem) return bangKeItem.criteria;
        else if (psrItem) return psrItem[formType] ?? '';
        else return '';
    }, [psrItem, bangKeItem]);
    const [insertType, setInsertType] = useState(DropDownFOBInsertTypeEnum.formular);
    const [dropDownApplicable, setDropDownApplicable] = useState<DropDownApplicableEnum>(DropDownApplicableEnum.CTC);
    const exwInputRef = useRef(null);
    const [exwInput, setExwInput] = useState<null | FormExwInputInterface>(null);
    function CollectExtInput(input: FormExwInputInterface) {
        setExwInput({ ...input });
    }

    const [userInput, setUserInput] = useState<null | UserInputInterface>(null);
    const inputRef = useRef(null);
    function CollectInput(input: UserInputInterface) {
        setUserInput({ ...input });
    }
    const [bangKeData, setBangKeData] = useState<null | BangKeDataInterface>(null);
    const [formData, setFormData] = useState<FormEXWInterface>({
        psr_input: psrLabel ?? ''
    });

    useEffect(() => {
        const bangKeItem: BangKeDataInterface = {
            id: item.id,
            exchange_rate: item.exchange_rate,
            account_number: item.account_number,
            registered_date: item.registered_date,
            option: ConvertFOBOption(insertType, formType),
            form_type: dropDownApplicable,
            criteria: formData.psr_input,
            name: item.item_name,
            bill_date: item.bill_date,
            hs: item.hs,
            total: item.total,
            nt_value: item.nt_value,
            code: item.code,
            input: { result: 0 }
        };
        if (insertType === DropDownFOBInsertTypeEnum.formular) {
            bangKeItem.input = { domestic_expenses: exwInput?.domestic_expenses ?? 0 };
        }
        if (insertType === DropDownFOBInsertTypeEnum.user_input) {
            bangKeItem.input = { result: userInput?.user_input ?? 0 };
        }
        const timeout = setTimeout(() => {
            setBangKeData((prev: BangKeDataInterface | null) => {
                if (prev === null) return bangKeItem;
                else {
                    if (_.isEqual(prev, bangKeItem)) return prev;
                    else {
                        return bangKeItem;
                    }
                }
            });
        }, 500);

        return () => clearTimeout(timeout);
    }, [insertType, dropDownApplicable, exwInput, userInput, formData]);

    useEffect(() => {
        if (bangKeData) {
            MapBangKeData(bangKeData);
        }
    }, [bangKeData]);

    useEffect(() => {
        if (bangKeItem) {
            if (RevertBangKeDataFOBOption(bangKeItem.option) === DropDownFOBInsertTypeEnum.user_input) {
                setInsertType(DropDownFOBInsertTypeEnum.user_input);
                setUserInput({ user_input: bangKeItem.input.result ?? 0 });
            } else {
                setInsertType(DropDownFOBInsertTypeEnum.formular);
                setExwInput({ nt_value: item.nt_value, domestic_expenses: bangKeItem.input.domestic_expenses ?? 0 });
            }
            setFormData({ psr_input: bangKeItem.criteria });
            setDropDownApplicable(RevertBangKeDataFormType(bangKeItem.form_type));
        }
    }, [bangKeItem]);

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '10px' }}>
            <div className={styled['no-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'No' : ' '}</h3>
                <h3>{index}</h3>
            </div>
            <div className={styled['code-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Code' : ' '}</h3>
                <h3>{item.code}</h3>
            </div>
            <div className={styled['name-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Name' : ' '}</h3>
                <h3>{ConvertBangKeName(item.item_name)}</h3>
            </div>
            <div className={styled['hs-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Hs' : ' '}</h3>
                <h3>{item.hs}</h3>
            </div>

            <div className={styled['criteria-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Criteria' : ' '}</h3>
                <TextField
                    value={formData.psr_input}
                    variant="standard"
                    sx={{ width: 200 }}
                    onChange={(e) => {
                        setFormData({ psr_input: e.target.value });
                    }}
                />
            </div>

            <DropDownInsertType insertType={insertType} setInsertType={setInsertType} index={index} />

            <div className={styled['hs-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Value' : ' '}</h3>
                {insertType === DropDownFOBInsertTypeEnum.user_input ? (
                    <Box>
                        <UserInput ref={inputRef} insertType={insertType} fn={CollectInput} prevUserInput={userInput} />
                    </Box>
                ) : (
                    <Box>
                        <FormExwInput item={item} insertType={insertType} ref={exwInputRef} fn={CollectExtInput} prevInputItem={exwInput} />
                    </Box>
                )}
            </div>

            <DropDownFormularApplicableCriteria
                dropDownApplicable={dropDownApplicable}
                setDropDownApplicable={setDropDownApplicable}
                index={index}
            />
        </Box>
    );
};

interface FormCfrInputInterface {
    nt_value: number;
    freight: number;
}

const FormCfrInput = forwardRef<FormularFormRef, any>(
    ({ item, insertType, fn, prevInputItem }: FormInputProps<FormCfrInputInterface>, ref) => {
        const schema = yup.object({ nt_value: yup.number(), freight: yup.number().required().min(0) });

        const SubmitHandler: SubmitHandler<FormCfrInputInterface> = (data: FormCfrInputInterface) => {
            if (data) {
                fn(data);
            }
        };

        const { handleSubmit, control, reset } = useForm({
            defaultValues: { nt_value: item.nt_value, freight: prevInputItem ? prevInputItem.freight : 0 },
            resolver: yupResolver(schema)
        });

        useEffect(() => {
            //////////// reset();
        }, [insertType]);

        return (
            <form onChange={handleSubmit(SubmitHandler)} style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                <Controller
                    control={control}
                    name="nt_value"
                    render={({ field }) => <TextField variant="standard" type="number" {...field} disabled />}
                />
                <div style={{ fontWeight: 'bold' }}> - </div>
                <Controller
                    control={control}
                    name="freight"
                    render={({ field, formState: { errors } }) => (
                        <>
                            <TextField variant="standard" type="number" {...field} />
                            {errors.freight && <span>This input is required</span>}
                        </>
                    )}
                />
            </form>
        );
    }
);

export interface FormCFRInterface {
    psr_input: string;
}

export const FormCFR = ({ item, psrItem, index, bangKeItem }: FormProps) => {
    const { formType, MapBangKeData } = useContext(InvoiceContext);
    const psrLabel = useMemo(() => {
        if (bangKeItem) return bangKeItem.criteria;
        else if (psrItem) return psrItem[formType] ?? '';
        else return '';
    }, [psrItem, bangKeItem]);
    const [cfrInput, setCfrInput] = useState<null | FormCfrInputInterface>(null);
    const cfrInputRef = useRef(null);
    function CollectExwInput(input: FormCfrInputInterface) {
        setCfrInput({ ...input });
    }
    const [userInput, setUserInput] = useState<null | UserInputInterface>(null);
    const inputRef = useRef(null);
    function CollectInput(input: UserInputInterface) {
        setUserInput({ ...input });
    }
    const [bangKeData, setBangKeData] = useState<null | BangKeDataInterface>(null);
    const [insertType, setInsertType] = useState(DropDownFOBInsertTypeEnum.formular);
    const [dropDownApplicable, setDropDownApplicable] = useState<DropDownApplicableEnum>(DropDownApplicableEnum.CTC);
    const [formData, setFormData] = useState<FormCFRInterface>({
        psr_input: psrLabel ?? ''
    });

    useEffect(() => {
        const bangKeItem: BangKeDataInterface = {
            id: item.id,
            exchange_rate: item.exchange_rate,
            account_number: item.account_number,
            registered_date: item.registered_date,
            option: ConvertFOBOption(insertType, formType),
            form_type: dropDownApplicable,
            criteria: formData.psr_input,
            name: item.item_name,
            bill_date: item.bill_date,
            hs: item.hs,
            total: item.total,
            nt_value: item.nt_value,
            code: item.code,

            input: { result: 0 }
        };
        if (insertType === DropDownFOBInsertTypeEnum.formular) {
            bangKeItem.input = { freight: cfrInput?.freight ?? 0 };
        }
        if (insertType === DropDownFOBInsertTypeEnum.user_input) {
            bangKeItem.input = { result: userInput?.user_input ?? 0 };
        }
        const timeout = setTimeout(() => {
            setBangKeData((prev: BangKeDataInterface | null) => {
                if (prev === null) return bangKeItem;
                else {
                    if (_.isEqual(prev, bangKeItem)) return prev;
                    else {
                        return bangKeItem;
                    }
                }
            });
        }, 500);

        return () => clearTimeout(timeout);
    }, [insertType, dropDownApplicable, cfrInput, userInput, formData]);

    useEffect(() => {
        if (bangKeData) {
            MapBangKeData(bangKeData);
        }
    }, [bangKeData]);

    useEffect(() => {
        if (bangKeItem) {
            if (RevertBangKeDataFOBOption(bangKeItem.option) === DropDownFOBInsertTypeEnum.user_input) {
                setInsertType(DropDownFOBInsertTypeEnum.user_input);
                setUserInput({ user_input: bangKeItem.input.result ?? 0 });
            } else {
                setInsertType(DropDownFOBInsertTypeEnum.formular);
                setCfrInput({ nt_value: item.nt_value, freight: bangKeItem.input.freight ?? 0 });
            }
            setFormData({ psr_input: bangKeItem.criteria });
            setDropDownApplicable(RevertBangKeDataFormType(bangKeItem.form_type));
        }
    }, [bangKeItem]);

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '10px' }}>
            <div className={styled['no-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'No' : ' '}</h3>
                <h3>{index}</h3>
            </div>
            <div className={styled['code-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Code' : ' '}</h3>
                <h3>{item.code}</h3>
            </div>
            <div className={styled['name-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Name' : ' '}</h3>
                <h3>{ConvertBangKeName(item.item_name)}</h3>
            </div>
            <div className={styled['hs-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Hs' : ' '}</h3>
                <h3>{item.hs}</h3>
            </div>

            <div className={styled['criteria-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Criteria' : ' '}</h3>
                <TextField
                    value={formData.psr_input}
                    variant="standard"
                    sx={{ width: 200 }}
                    onChange={(e) => {
                        setFormData({ psr_input: e.target.value });
                    }}
                />
            </div>

            <DropDownInsertType insertType={insertType} setInsertType={setInsertType} index={index} />

            <div className={styled['hs-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Value' : ' '}</h3>
                {insertType === DropDownFOBInsertTypeEnum.user_input ? (
                    <Box>
                        <UserInput ref={inputRef} insertType={insertType} fn={CollectInput} prevUserInput={userInput} />
                    </Box>
                ) : (
                    <Box>
                        <FormCfrInput item={item} insertType={insertType} ref={cfrInputRef} fn={CollectExwInput} prevInputItem={cfrInput} />
                    </Box>
                )}
            </div>

            <DropDownFormularApplicableCriteria
                dropDownApplicable={dropDownApplicable}
                setDropDownApplicable={setDropDownApplicable}
                index={index}
            />
        </Box>
    );
};

interface FormCifInputInterface {
    nt_value: number;
    freight: number;
    insurance: number;
}

const FormCifInput = forwardRef<FormularFormRef, any>(
    ({ item, insertType, fn, prevInputItem }: FormInputProps<FormCifInputInterface>, ref) => {
        const schema = yup.object({
            nt_value: yup.number(),
            freight: yup.number().required().min(0),
            insurance: yup.number().required().min(0)
        });

        const { handleSubmit, control, reset } = useForm({
            defaultValues: {
                nt_value: item.nt_value,
                freight: prevInputItem ? prevInputItem.freight : 0,
                insurance: prevInputItem ? prevInputItem.insurance : 0
            },
            resolver: yupResolver(schema)
        });

        const SubmitHandler: SubmitHandler<FormCifInputInterface> = (data: FormCifInputInterface) => {
            if (data) {
                fn(data);
            }
        };

        useEffect(() => {
            //////////// reset();
        }, [insertType]);

        return (
            <form onChange={handleSubmit(SubmitHandler)} style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                <Controller
                    control={control}
                    name="nt_value"
                    render={({ field }) => <TextField variant="standard" type="number" {...field} disabled />}
                />
                <div style={{ fontWeight: 'bold' }}> - </div>
                <Controller
                    control={control}
                    name="freight"
                    render={({ field, formState: { errors } }) => (
                        <>
                            <TextField variant="standard" type="number" {...field} />
                            {errors.freight && <span>This input is required</span>}
                        </>
                    )}
                />
                <div style={{ fontWeight: 'bold' }}> - </div>
                <Controller
                    control={control}
                    name="insurance"
                    render={({ field, formState: { errors } }) => (
                        <>
                            <TextField variant="standard" type="number" {...field} />
                            {errors.insurance && <span>This input is required</span>}
                        </>
                    )}
                />
            </form>
        );
    }
);

export interface FormCIFInterface {
    psr_input: string;
}

export const FormCIF = ({ item, psrItem, index, bangKeItem }: FormProps) => {
    const { formType, MapBangKeData } = useContext(InvoiceContext);
    const psrLabel = useMemo(() => {
        if (bangKeItem) return bangKeItem.criteria;
        else if (psrItem) return psrItem[formType] ?? '';
        else return '';
    }, [psrItem, bangKeItem]);
    const [insertType, setInsertType] = useState(DropDownFOBInsertTypeEnum.formular);
    const [dropDownApplicable, setDropDownApplicable] = useState<DropDownApplicableEnum>(DropDownApplicableEnum.CTC);
    const [cifInput, setCifInput] = useState<null | FormCifInputInterface>(null);
    const cifInputRef = useRef(null);
    function CollectCifInput(input: FormCifInputInterface) {
        setCifInput({ ...input });
    }

    const [userInput, setUserInput] = useState<null | UserInputInterface>(null);
    const inputRef = useRef(null);
    function CollectInput(input: UserInputInterface) {
        setUserInput({ ...input });
    }
    const [bangKeData, setBangKeData] = useState<null | BangKeDataInterface>(null);
    const [formData, setFormData] = useState<FormCIFInterface>({
        psr_input: psrLabel ?? ''
    });

    useEffect(() => {
        const bangKeItem: BangKeDataInterface = {
            id: item.id,
            exchange_rate: item.exchange_rate,
            account_number: item.account_number,
            registered_date: item.registered_date,
            option: ConvertFOBOption(insertType, formType),
            form_type: dropDownApplicable,
            criteria: formData.psr_input,
            name: item.item_name,
            bill_date: item.bill_date,
            hs: item.hs,
            total: item.total,
            nt_value: item.nt_value,
            code: item.code,
            input: { result: 0 }
        };
        if (insertType === DropDownFOBInsertTypeEnum.formular) {
            bangKeItem.input = { freight: cifInput?.freight ?? 0, insurance: cifInput?.insurance ?? 0 };
        }
        if (insertType === DropDownFOBInsertTypeEnum.user_input) {
            bangKeItem.input = { result: userInput?.user_input ?? 0 };
        }
        const timeout = setTimeout(() => {
            setBangKeData((prev: BangKeDataInterface | null) => {
                if (prev === null) return bangKeItem;
                else {
                    if (_.isEqual(prev, bangKeItem)) return prev;
                    else {
                        return bangKeItem;
                    }
                }
            });
        }, 500);

        return () => clearTimeout(timeout);
    }, [insertType, dropDownApplicable, cifInput, userInput, formData]);

    useEffect(() => {
        if (bangKeData) {
            MapBangKeData(bangKeData);
        }
    }, [bangKeData]);

    useEffect(() => {
        if (bangKeItem) {
            if (RevertBangKeDataFOBOption(bangKeItem.option) === DropDownFOBInsertTypeEnum.user_input) {
                setInsertType(DropDownFOBInsertTypeEnum.user_input);
                setUserInput({ user_input: bangKeItem.input.result ?? 0 });
            } else {
                setInsertType(DropDownFOBInsertTypeEnum.formular);
                setCifInput({
                    nt_value: item.nt_value,
                    freight: bangKeItem.input.freight ?? 0,
                    insurance: bangKeItem.input.insurance ?? 0
                });
            }
            setFormData({ psr_input: bangKeItem.criteria });
            setDropDownApplicable(RevertBangKeDataFormType(bangKeItem.form_type));
        }
    }, [bangKeItem]);

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '10px' }}>
            <div className={styled['no-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'No' : ' '}</h3>
                <h3>{index}</h3>
            </div>
            <div className={styled['code-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Code' : ' '}</h3>
                <h3>{item.code}</h3>
            </div>
            <div className={styled['name-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Name' : ' '}</h3>
                <h3>{ConvertBangKeName(item.item_name)}</h3>
            </div>
            <div className={styled['hs-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Hs' : ' '}</h3>
                <h3>{item.hs}</h3>
            </div>

            <div className={styled['criteria-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Criteria' : ' '}</h3>
                <TextField
                    value={formData.psr_input}
                    variant="standard"
                    sx={{ width: 200 }}
                    onChange={(e) => {
                        setFormData({ psr_input: e.target.value });
                    }}
                />
            </div>

            <DropDownInsertType insertType={insertType} setInsertType={setInsertType} index={index} />

            <div className={styled['hs-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Value' : ' '}</h3>
                {insertType === DropDownFOBInsertTypeEnum.user_input ? (
                    <Box>
                        <UserInput ref={inputRef} insertType={insertType} fn={CollectInput} prevUserInput={userInput} />
                    </Box>
                ) : (
                    <Box>
                        <FormCifInput item={item} insertType={insertType} ref={cifInputRef} fn={CollectCifInput} prevInputItem={cifInput} />
                    </Box>
                )}
            </div>

            <DropDownFormularApplicableCriteria
                dropDownApplicable={dropDownApplicable}
                setDropDownApplicable={setDropDownApplicable}
                index={index}
            />
        </Box>
    );
};

interface FormCptInputInterface {
    nt_value: number;
    oversea_expenses: number;
}

const FormCptInput = forwardRef<FormularFormRef, any>(
    ({ item, insertType, fn, prevInputItem }: FormInputProps<FormCptInputInterface>, ref) => {
        const schema = yup.object({ nt_value: yup.number(), oversea_expenses: yup.number().required().min(0) });

        const { handleSubmit, control, reset } = useForm({
            defaultValues: { nt_value: item.nt_value, oversea_expenses: prevInputItem ? prevInputItem.oversea_expenses : 0 },
            resolver: yupResolver(schema)
        });

        const SubmitHandler: SubmitHandler<FormCptInputInterface> = (data: FormCptInputInterface) => {
            if (data) {
                fn(data);
            }
        };

        useEffect(() => {
            ////////// reset();
        }, [insertType]);

        return (
            <form onChange={handleSubmit(SubmitHandler)} style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                <Controller
                    control={control}
                    name="nt_value"
                    render={({ field }) => <TextField variant="standard" type="number" {...field} disabled />}
                />
                <div style={{ fontWeight: 'bold' }}> - </div>
                <Controller
                    control={control}
                    name="oversea_expenses"
                    render={({ field, formState: { errors } }) => (
                        <>
                            <TextField variant="standard" type="number" {...field} disabled />
                            {errors.oversea_expenses && <span>This input is required</span>}
                        </>
                    )}
                />
            </form>
        );
    }
);

export interface FormCPTInterface {
    psr_input: string;
}

export const FormCPT = ({ item, psrItem, index, bangKeItem }: FormProps) => {
    const { formType, MapBangKeData } = useContext(InvoiceContext);
    const psrLabel = useMemo(() => {
        if (bangKeItem) return bangKeItem.criteria;
        else if (psrItem) return psrItem[formType] ?? '';
        else return '';
    }, [psrItem, bangKeItem]);
    const [insertType, setInsertType] = useState(DropDownFOBInsertTypeEnum.formular);
    const [dropDownApplicable, setDropDownApplicable] = useState<DropDownApplicableEnum>(DropDownApplicableEnum.CTC);
    const [cptInput, setCptInput] = useState<null | FormCptInputInterface>(null);
    const cptInputRef = useRef(null);

    function CollectCptInput(input: FormCptInputInterface) {
        setCptInput({ ...input });
    }

    const [userInput, setUserInput] = useState<null | UserInputInterface>(null);
    const inputRef = useRef(null);
    function CollectInput(input: UserInputInterface) {
        setUserInput({ ...input });
    }

    const [bangKeData, setBangKeData] = useState<null | BangKeDataInterface>(null);
    const [formData, setFormData] = useState<FormCPTInterface>({
        psr_input: psrLabel ?? ''
    });

    useEffect(() => {
        const bangKeItem: BangKeDataInterface = {
            id: item.id,
            exchange_rate: item.exchange_rate,
            account_number: item.account_number,
            registered_date: item.registered_date,
            option: ConvertFOBOption(insertType, formType),
            form_type: dropDownApplicable,
            criteria: formData.psr_input,
            name: item.item_name,
            bill_date: item.bill_date,
            hs: item.hs,
            total: item.total,
            nt_value: item.nt_value,
            code: item.code,
            input: { result: 0 }
        };
        if (insertType === DropDownFOBInsertTypeEnum.formular) {
            bangKeItem.input = { oversea_expenses: cptInput?.oversea_expenses ?? 0 };
        }
        if (insertType === DropDownFOBInsertTypeEnum.user_input) {
            bangKeItem.input = { result: userInput?.user_input ?? 0 };
        }
        const timeout = setTimeout(() => {
            setBangKeData((prev: BangKeDataInterface | null) => {
                if (prev === null) return bangKeItem;
                else {
                    if (_.isEqual(prev, bangKeItem)) return prev;
                    else {
                        return bangKeItem;
                    }
                }
            });
        }, 500);

        return () => clearTimeout(timeout);
    }, [insertType, dropDownApplicable, cptInput, userInput, formData]);

    useEffect(() => {
        if (bangKeData) {
            MapBangKeData(bangKeData);
        }
    }, [bangKeData]);

    useEffect(() => {
        if (bangKeItem) {
            if (RevertBangKeDataFOBOption(bangKeItem.option) === DropDownFOBInsertTypeEnum.user_input) {
                setInsertType(DropDownFOBInsertTypeEnum.user_input);
                setUserInput({ user_input: bangKeItem.input.result ?? 0 });
            } else {
                setInsertType(DropDownFOBInsertTypeEnum.formular);
                setCptInput({ nt_value: item.nt_value, oversea_expenses: bangKeItem.input.oversea_expenses ?? 0 });
            }
            setFormData({ psr_input: bangKeItem.criteria });
            setDropDownApplicable(RevertBangKeDataFormType(bangKeItem.form_type));
        }
    }, [bangKeItem]);

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '10px' }}>
            <div className={styled['no-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'No' : ' '}</h3>
                <h3>{index}</h3>
            </div>
            <div className={styled['code-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Code' : ' '}</h3>
                <h3>{item.code}</h3>
            </div>
            <div className={styled['name-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Name' : ' '}</h3>
                <h3>{ConvertBangKeName(item.item_name)}</h3>
            </div>
            <div className={styled['hs-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Hs' : ' '}</h3>
                <h3>{item.hs}</h3>
            </div>

            <div className={styled['criteria-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Criteria' : ' '}</h3>
                <TextField
                    value={formData.psr_input}
                    variant="standard"
                    sx={{ width: 200 }}
                    onChange={(e) => {
                        setFormData({ psr_input: e.target.value });
                    }}
                />
            </div>

            <DropDownInsertType insertType={insertType} setInsertType={setInsertType} index={index} />

            <div className={styled['hs-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Value' : ' '}</h3>
                {insertType === DropDownFOBInsertTypeEnum.user_input ? (
                    <Box>
                        <UserInput ref={inputRef} insertType={insertType} fn={CollectInput} prevUserInput={userInput} />
                    </Box>
                ) : (
                    <Box>
                        <FormCptInput item={item} insertType={insertType} ref={cptInputRef} fn={CollectCptInput} prevInputItem={cptInput} />
                    </Box>
                )}
            </div>

            <DropDownFormularApplicableCriteria
                dropDownApplicable={dropDownApplicable}
                setDropDownApplicable={setDropDownApplicable}
                index={index}
            />
        </Box>
    );
};

export const FormDPU = ({ item, psrItem, index, bangKeItem }: FormProps) => {
    const { formType, MapBangKeData } = useContext(InvoiceContext);
    const psrLabel = useMemo(() => {
        if (bangKeItem) return bangKeItem.criteria;
        else if (psrItem) return psrItem[formType] ?? '';
        else return '';
    }, [psrItem, bangKeItem]);
    const [insertType, setInsertType] = useState(DropDownFOBInsertTypeEnum.formular);
    const [dropDownApplicable, setDropDownApplicable] = useState<DropDownApplicableEnum>(DropDownApplicableEnum.CTC);
    const [cptInput, setCptInput] = useState<null | FormCptInputInterface>(null);
    const cptInputRef = useRef(null);

    function CollectCptInput(input: FormCptInputInterface) {
        setCptInput({ ...input });
    }

    const [userInput, setUserInput] = useState<null | UserInputInterface>(null);
    const inputRef = useRef(null);
    function CollectInput(input: UserInputInterface) {
        setUserInput({ ...input });
    }

    const [bangKeData, setBangKeData] = useState<null | BangKeDataInterface>(null);
    const [formData, setFormData] = useState<FormCPTInterface>({
        psr_input: psrLabel ?? ''
    });

    useEffect(() => {
        const bangKeItem: BangKeDataInterface = {
            id: item.id,
            exchange_rate: item.exchange_rate,
            account_number: item.account_number,
            registered_date: item.registered_date,
            option: ConvertFOBOption(insertType, formType),
            form_type: dropDownApplicable,
            criteria: formData.psr_input,
            name: item.item_name,
            bill_date: item.bill_date,
            hs: item.hs,
            total: item.total,
            nt_value: item.nt_value,
            code: item.code,
            input: { result: 0 }
        };
        if (insertType === DropDownFOBInsertTypeEnum.formular) {
            bangKeItem.input = { oversea_expenses: cptInput?.oversea_expenses ?? 0 };
        }
        if (insertType === DropDownFOBInsertTypeEnum.user_input) {
            bangKeItem.input = { result: userInput?.user_input ?? 0 };
        }
        const timeout = setTimeout(() => {
            setBangKeData((prev: BangKeDataInterface | null) => {
                if (prev === null) return bangKeItem;
                else {
                    if (_.isEqual(prev, bangKeItem)) return prev;
                    else {
                        return bangKeItem;
                    }
                }
            });
        }, 500);

        return () => clearTimeout(timeout);
    }, [insertType, dropDownApplicable, cptInput, userInput, formData]);

    useEffect(() => {
        if (bangKeData) {
            MapBangKeData(bangKeData);
        }
    }, [bangKeData]);

    useEffect(() => {
        if (bangKeItem) {
            if (RevertBangKeDataFOBOption(bangKeItem.option) === DropDownFOBInsertTypeEnum.user_input) {
                setInsertType(DropDownFOBInsertTypeEnum.user_input);
                setUserInput({ user_input: bangKeItem.input.result ?? 0 });
            } else {
                setInsertType(DropDownFOBInsertTypeEnum.formular);
                setCptInput({ nt_value: item.nt_value, oversea_expenses: bangKeItem.input.oversea_expenses ?? 0 });
            }
            setFormData({ psr_input: bangKeItem.criteria });
            setDropDownApplicable(RevertBangKeDataFormType(bangKeItem.form_type));
        }
    }, [bangKeItem]);

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '10px' }}>
            <div className={styled['no-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'No' : ' '}</h3>
                <h3>{index}</h3>
            </div>
            <div className={styled['code-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Code' : ' '}</h3>
                <h3>{item.code}</h3>
            </div>
            <div className={styled['name-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Name' : ' '}</h3>
                <h3>{ConvertBangKeName(item.item_name)}</h3>
            </div>
            <div className={styled['hs-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Hs' : ' '}</h3>
                <h3>{item.hs}</h3>
            </div>

            <div className={styled['criteria-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Criteria' : ' '}</h3>
                <TextField
                    value={formData.psr_input}
                    variant="standard"
                    sx={{ width: 200 }}
                    onChange={(e) => {
                        setFormData({ psr_input: e.target.value });
                    }}
                />
            </div>

            <DropDownInsertType insertType={insertType} setInsertType={setInsertType} index={index} />

            <div className={styled['hs-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Value' : ' '}</h3>
                {insertType === DropDownFOBInsertTypeEnum.user_input ? (
                    <Box>
                        <UserInput ref={inputRef} insertType={insertType} fn={CollectInput} prevUserInput={userInput} />
                    </Box>
                ) : (
                    <Box>
                        <FormCptInput item={item} insertType={insertType} ref={cptInputRef} fn={CollectCptInput} prevInputItem={cptInput} />
                    </Box>
                )}
            </div>

            <DropDownFormularApplicableCriteria
                dropDownApplicable={dropDownApplicable}
                setDropDownApplicable={setDropDownApplicable}
                index={index}
            />
        </Box>
    );
};

export const FormDDP = ({ item, psrItem, index, bangKeItem }: FormProps) => {
    const { formType, MapBangKeData } = useContext(InvoiceContext);
    const psrLabel = useMemo(() => {
        if (bangKeItem) return bangKeItem.criteria;
        else if (psrItem) return psrItem[formType] ?? '';
        else return '';
    }, [psrItem, bangKeItem]);
    const [insertType, setInsertType] = useState(DropDownFOBInsertTypeEnum.formular);
    const [dropDownApplicable, setDropDownApplicable] = useState<DropDownApplicableEnum>(DropDownApplicableEnum.CTC);
    const [cptInput, setCptInput] = useState<null | FormCptInputInterface>(null);
    const cptInputRef = useRef(null);

    function CollectCptInput(input: FormCptInputInterface) {
        setCptInput({ ...input });
    }

    const [userInput, setUserInput] = useState<null | UserInputInterface>(null);
    const inputRef = useRef(null);
    function CollectInput(input: UserInputInterface) {
        setUserInput({ ...input });
    }

    const [bangKeData, setBangKeData] = useState<null | BangKeDataInterface>(null);
    const [formData, setFormData] = useState<FormCPTInterface>({
        psr_input: psrLabel ?? ''
    });

    useEffect(() => {
        const bangKeItem: BangKeDataInterface = {
            id: item.id,
            exchange_rate: item.exchange_rate,
            account_number: item.account_number,
            registered_date: item.registered_date,
            option: ConvertFOBOption(insertType, formType),
            form_type: dropDownApplicable,
            criteria: formData.psr_input,
            name: item.item_name,
            bill_date: item.bill_date,
            hs: item.hs,
            total: item.total,
            nt_value: item.nt_value,
            code: item.code,
            input: { result: 0 }
        };
        if (insertType === DropDownFOBInsertTypeEnum.formular) {
            bangKeItem.input = { oversea_expenses: cptInput?.oversea_expenses ?? 0 };
        }
        if (insertType === DropDownFOBInsertTypeEnum.user_input) {
            bangKeItem.input = { result: userInput?.user_input ?? 0 };
        }
        const timeout = setTimeout(() => {
            setBangKeData((prev: BangKeDataInterface | null) => {
                if (prev === null) return bangKeItem;
                else {
                    if (_.isEqual(prev, bangKeItem)) return prev;
                    else {
                        return bangKeItem;
                    }
                }
            });
        }, 500);

        return () => clearTimeout(timeout);
    }, [insertType, dropDownApplicable, cptInput, userInput, formData]);

    useEffect(() => {
        if (bangKeData) {
            MapBangKeData(bangKeData);
        }
    }, [bangKeData]);

    useEffect(() => {
        if (bangKeItem) {
            if (RevertBangKeDataFOBOption(bangKeItem.option) === DropDownFOBInsertTypeEnum.user_input) {
                setInsertType(DropDownFOBInsertTypeEnum.user_input);
                setUserInput({ user_input: bangKeItem.input.result ?? 0 });
            } else {
                setInsertType(DropDownFOBInsertTypeEnum.formular);
                setCptInput({ nt_value: item.nt_value, oversea_expenses: bangKeItem.input.oversea_expenses ?? 0 });
            }
            setFormData({ psr_input: bangKeItem.criteria });
            setDropDownApplicable(RevertBangKeDataFormType(bangKeItem.form_type));
        }
    }, [bangKeItem]);

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '10px' }}>
            <div className={styled['no-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'No' : ' '}</h3>
                <h3>{index}</h3>
            </div>
            <div className={styled['code-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Code' : ' '}</h3>
                <h3>{item.code}</h3>
            </div>
            <div className={styled['name-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Name' : ' '}</h3>
                <h3>{ConvertBangKeName(item.item_name)}</h3>
            </div>
            <div className={styled['hs-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Hs' : ' '}</h3>
                <h3>{item.hs}</h3>
            </div>

            <div className={styled['criteria-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Criteria' : ' '}</h3>
                <TextField
                    value={formData.psr_input}
                    variant="standard"
                    sx={{ width: 200 }}
                    onChange={(e) => {
                        setFormData({ psr_input: e.target.value });
                    }}
                />
            </div>

            <DropDownInsertType insertType={insertType} setInsertType={setInsertType} index={index} />

            <div className={styled['hs-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Value' : ' '}</h3>
                {insertType === DropDownFOBInsertTypeEnum.user_input ? (
                    <Box>
                        <UserInput ref={inputRef} insertType={insertType} fn={CollectInput} prevUserInput={userInput} />
                    </Box>
                ) : (
                    <Box>
                        <FormCptInput item={item} insertType={insertType} ref={cptInputRef} fn={CollectCptInput} prevInputItem={cptInput} />
                    </Box>
                )}
            </div>

            <DropDownFormularApplicableCriteria
                dropDownApplicable={dropDownApplicable}
                setDropDownApplicable={setDropDownApplicable}
                index={index}
            />
        </Box>
    );
};

interface FormCipInputInterface {
    nt_value: number;
    freight: number;
    insurance: number;
    oversea_expenses: number;
}

const FormCipInput = forwardRef<FormularFormRef, any>(
    ({ item, insertType, fn, prevInputItem }: FormInputProps<FormCipInputInterface>, ref) => {
        const schema = yup.object({
            nt_value: yup.number(),
            freight: yup.number().required().min(0),
            insurance: yup.number().required().min(0),
            oversea_expenses: yup.number().required().min(0)
        });

        const { handleSubmit, control, reset } = useForm({
            defaultValues: {
                nt_value: item.nt_value,
                freight: prevInputItem ? prevInputItem.freight : 0,
                insurance: prevInputItem ? prevInputItem.insurance : 0,
                oversea_expenses: prevInputItem ? prevInputItem.oversea_expenses : 0
            },
            resolver: yupResolver(schema)
        });
        const SubmitHandler: SubmitHandler<FormCipInputInterface> = (data: FormCipInputInterface) => {
            if (data) {
                fn(data);
            }
        };

        useEffect(() => {
            ////////// reset();
        }, [insertType]);

        return (
            <form onChange={handleSubmit(SubmitHandler)} style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                <Controller
                    control={control}
                    name="nt_value"
                    render={({ field }) => <TextField variant="standard" type="number" {...field} disabled />}
                />
                <div style={{ fontWeight: 'bold' }}> - </div>
                <Controller
                    control={control}
                    name="freight"
                    render={({ field, formState: { errors } }) => (
                        <>
                            <TextField variant="standard" type="number" {...field} />
                            {errors.freight && <span>This input is required</span>}
                        </>
                    )}
                />
                <div style={{ fontWeight: 'bold' }}> - </div>
                <Controller
                    control={control}
                    name="oversea_expenses"
                    render={({ field, formState: { errors } }) => (
                        <>
                            <TextField variant="standard" type="number" {...field} />
                            {errors.oversea_expenses && <span>This input is required</span>}
                        </>
                    )}
                />
                <div style={{ fontWeight: 'bold' }}> - </div>
                <Controller
                    control={control}
                    name="insurance"
                    render={({ field, formState: { errors } }) => (
                        <>
                            <TextField variant="standard" type="number" {...field} />
                            {errors.insurance && <span>This input is required</span>}
                        </>
                    )}
                />
            </form>
        );
    }
);

export interface FormCIPInterface {
    psr_input: string;
}

export const FormCIP = ({ item, psrItem, index, bangKeItem }: FormProps) => {
    const { formType, MapBangKeData } = useContext(InvoiceContext);
    const psrLabel = useMemo(() => {
        if (bangKeItem) return bangKeItem.criteria;
        else if (psrItem) return psrItem[formType] ?? '';
        else return '';
    }, [psrItem, bangKeItem]);
    const [insertType, setInsertType] = useState(DropDownFOBInsertTypeEnum.formular);
    const [dropDownApplicable, setDropDownApplicable] = useState<DropDownApplicableEnum>(DropDownApplicableEnum.CTC);

    const [cipInput, setCipInput] = useState<null | FormCipInputInterface>(null);
    const cipInputRef = useRef(null);
    function CollectCipInput(input: FormCipInputInterface) {
        setCipInput({ ...input });
    }

    const [userInput, setUserInput] = useState<null | UserInputInterface>(null);
    const inputRef = useRef(null);
    function CollectInput(input: UserInputInterface) {
        setUserInput({ ...input });
    }

    const [bangKeData, setBangKeData] = useState<null | BangKeDataInterface>(null);
    const [formData, setFormData] = useState<FormCIPInterface>({
        psr_input: psrLabel ?? ''
    });

    useEffect(() => {
        const bangKeItem: BangKeDataInterface = {
            id: item.id,
            exchange_rate: item.exchange_rate,
            account_number: item.account_number,
            registered_date: item.registered_date,
            option: ConvertFOBOption(insertType, formType),
            form_type: dropDownApplicable,
            criteria: formData.psr_input,
            name: item.item_name,
            bill_date: item.bill_date,
            hs: item.hs,
            total: item.total,
            nt_value: item.nt_value,
            code: item.code,
            input: {
                result: 0
            }
        };
        if (insertType === DropDownFOBInsertTypeEnum.formular) {
            bangKeItem.input = {
                freight: cipInput?.freight ?? 0,
                oversea_expenses: cipInput?.oversea_expenses ?? 0,
                insurance: cipInput?.insurance ?? 0
            };
        }
        if (insertType === DropDownFOBInsertTypeEnum.user_input) {
            bangKeItem.input = { result: userInput?.user_input ?? 0 };
        }
        const timeout = setTimeout(() => {
            setBangKeData((prev: BangKeDataInterface | null) => {
                if (prev === null) return bangKeItem;
                else {
                    if (_.isEqual(prev, bangKeItem)) return prev;
                    else {
                        return bangKeItem;
                    }
                }
            });
        }, 500);

        return () => clearTimeout(timeout);
    }, [insertType, dropDownApplicable, cipInput, userInput, formData]);

    useEffect(() => {
        if (bangKeData) {
            MapBangKeData(bangKeData);
        }
    }, [bangKeData]);

    useEffect(() => {
        if (bangKeItem) {
            if (RevertBangKeDataFOBOption(bangKeItem.option) === DropDownFOBInsertTypeEnum.user_input) {
                setInsertType(DropDownFOBInsertTypeEnum.user_input);
                setUserInput({ user_input: bangKeItem.input.result ?? 0 });
            } else {
                setInsertType(DropDownFOBInsertTypeEnum.formular);
                setCipInput({
                    nt_value: item.nt_value,
                    freight: bangKeItem.input.freight ?? 0,
                    insurance: bangKeItem.input.insurance ?? 0,
                    oversea_expenses: bangKeItem.input.oversea_expenses ?? 0
                });
            }
            setFormData({ psr_input: bangKeItem.criteria });
            setDropDownApplicable(RevertBangKeDataFormType(bangKeItem.form_type));
        }
    }, [bangKeItem]);

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '10px' }}>
            <div className={styled['no-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'No' : ' '}</h3>
                <h3>{index}</h3>
            </div>
            <div className={styled['code-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Code' : ' '}</h3>
                <h3>{item.code}</h3>
            </div>
            <div className={styled['name-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Name' : ' '}</h3>
                <h3>{ConvertBangKeName(item.item_name)}</h3>
            </div>
            <div className={styled['hs-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Hs' : ' '}</h3>
                <h3>{item.hs}</h3>
            </div>

            <div className={styled['criteria-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Criteria' : ' '}</h3>
                <TextField
                    value={formData.psr_input}
                    variant="standard"
                    sx={{ width: 200 }}
                    onChange={(e) => {
                        setFormData({ psr_input: e.target.value });
                    }}
                />
            </div>

            <DropDownInsertType insertType={insertType} setInsertType={setInsertType} index={index} />

            <div className={styled['hs-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Value' : ' '}</h3>
                {insertType === DropDownFOBInsertTypeEnum.user_input ? (
                    <Box>
                        <UserInput ref={inputRef} insertType={insertType} fn={CollectInput} prevUserInput={userInput} />
                    </Box>
                ) : (
                    <Box>
                        <FormCipInput item={item} insertType={insertType} ref={cipInputRef} fn={CollectCipInput} prevInputItem={cipInput} />
                    </Box>
                )}
            </div>

            <DropDownFormularApplicableCriteria
                dropDownApplicable={dropDownApplicable}
                setDropDownApplicable={setDropDownApplicable}
                index={index}
            />
        </Box>
    );
};

interface FormDapInputInterface {
    nt_value: number;
    import_expenses: number;
}

const FormDapInput = forwardRef<FormularFormRef, any>(
    ({ item, insertType, fn, prevInputItem }: FormInputProps<FormDapInputInterface>, ref) => {
        const schema = yup.object({ nt_value: yup.number(), import_expenses: yup.number().required().min(0) });

        const { handleSubmit, control, reset } = useForm({
            defaultValues: { nt_value: item.nt_value, import_expenses: prevInputItem ? prevInputItem.import_expenses : 0 },
            resolver: yupResolver(schema)
        });

        const SubmitHandler: SubmitHandler<FormDapInputInterface> = (data: FormDapInputInterface) => {
            if (data) {
                fn(data);
            }
        };

        useEffect(() => {
            ////////// reset();
        }, [insertType]);

        return (
            <form onChange={handleSubmit(SubmitHandler)} style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                <Controller
                    control={control}
                    name="nt_value"
                    render={({ field }) => <TextField variant="standard" type="number" {...field} disabled />}
                />
                <div style={{ fontWeight: 'bold' }}> - </div>
                <Controller
                    control={control}
                    name="import_expenses"
                    render={({ field, formState: { errors } }) => (
                        <>
                            <TextField variant="standard" type="number" {...field} />
                            {errors.import_expenses && <span>This input is required or can't be negative</span>}
                        </>
                    )}
                />
            </form>
        );
    }
);

export interface FormDAPInterface {
    psr_input: string;
}

export const FormDAP = ({ item, psrItem, index, bangKeItem }: FormProps) => {
    const { formType, MapBangKeData } = useContext(InvoiceContext);
    const psrLabel = useMemo(() => {
        if (bangKeItem) return bangKeItem.criteria;
        else if (psrItem) return psrItem[formType] ?? '';
        else return '';
    }, [psrItem, bangKeItem]);
    const [insertType, setInsertType] = useState(DropDownFOBInsertTypeEnum.formular);
    const [dropDownApplicable, setDropDownApplicable] = useState<DropDownApplicableEnum>(DropDownApplicableEnum.CTC);

    const [dapInput, setDapInput] = useState<null | FormDapInputInterface>(null);
    const dapInputRef = useRef(null);
    function CollectDapInput(input: FormDapInputInterface) {
        setDapInput({ ...input });
    }

    const [userInput, setUserInput] = useState<null | UserInputInterface>(null);
    const inputRef = useRef(null);
    function CollectInput(input: UserInputInterface) {
        setUserInput({ ...input });
    }
    const [bangKeData, setBangKeData] = useState<null | BangKeDataInterface>(null);
    const [formData, setFormData] = useState<FormDAPInterface>({
        psr_input: psrLabel ?? ''
    });

    useEffect(() => {
        const bangKeItem: BangKeDataInterface = {
            id: item.id,
            exchange_rate: item.exchange_rate,
            account_number: item.account_number,
            registered_date: item.registered_date,
            option: ConvertFOBOption(insertType, formType),
            form_type: dropDownApplicable,
            criteria: formData.psr_input,
            name: item.item_name,
            bill_date: item.bill_date,
            hs: item.hs,
            total: item.total,
            nt_value: item.nt_value,
            code: item.code,
            input: { result: 0 }
        };
        if (insertType === DropDownFOBInsertTypeEnum.formular) {
            bangKeItem.input = { imported_expenses: dapInput?.import_expenses ?? 0 };
        }
        if (insertType === DropDownFOBInsertTypeEnum.user_input) {
            bangKeItem.input = { result: userInput?.user_input ?? 0 };
        }
        const timeout = setTimeout(() => {
            setBangKeData((prev: BangKeDataInterface | null) => {
                if (prev === null) return bangKeItem;
                else {
                    if (_.isEqual(prev, bangKeItem)) return prev;
                    else {
                        return bangKeItem;
                    }
                }
            });
        }, 500);

        return () => clearTimeout(timeout);
    }, [insertType, dropDownApplicable, dapInput, userInput, formData]);

    useEffect(() => {
        if (bangKeData) {
            MapBangKeData(bangKeData);
        }
    }, [bangKeData]);

    useEffect(() => {
        if (bangKeItem) {
            if (RevertBangKeDataFOBOption(bangKeItem.option) === DropDownFOBInsertTypeEnum.user_input) {
                setInsertType(DropDownFOBInsertTypeEnum.user_input);
                setUserInput({ user_input: bangKeItem.input.result ?? 0 });
            } else {
                setInsertType(DropDownFOBInsertTypeEnum.formular);
                setDapInput({ nt_value: item.nt_value, import_expenses: bangKeItem.input.imported_expenses ?? 0 });
            }
            setFormData({ psr_input: bangKeItem.criteria });
            setDropDownApplicable(RevertBangKeDataFormType(bangKeItem.form_type));
        }
    }, [bangKeItem]);

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '10px' }}>
            <div className={styled['no-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'No' : ' '}</h3>
                <h3>{index}</h3>
            </div>
            <div className={styled['code-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Code' : ' '}</h3>
                <h3>{item.code}</h3>
            </div>
            <div className={styled['name-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Name' : ' '}</h3>
                <h3>{ConvertBangKeName(item.item_name)}</h3>
            </div>
            <div className={styled['hs-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Hs' : ' '}</h3>
                <h3>{item.hs}</h3>
            </div>

            <div className={styled['criteria-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Criteria' : ' '}</h3>
                <TextField
                    value={formData.psr_input}
                    variant="standard"
                    sx={{ width: 200 }}
                    onChange={(e) => {
                        setFormData({ psr_input: e.target.value });
                    }}
                />
            </div>

            <DropDownInsertType insertType={insertType} setInsertType={setInsertType} index={index} />

            <div className={styled['hs-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Value' : ' '}</h3>
                {insertType === DropDownFOBInsertTypeEnum.user_input ? (
                    <Box>
                        <UserInput ref={inputRef} insertType={insertType} fn={CollectInput} prevUserInput={userInput} />
                    </Box>
                ) : (
                    <Box>
                        <FormDapInput item={item} insertType={insertType} ref={dapInputRef} fn={CollectDapInput} prevInputItem={dapInput} />
                    </Box>
                )}
            </div>

            <DropDownFormularApplicableCriteria
                dropDownApplicable={dropDownApplicable}
                setDropDownApplicable={setDropDownApplicable}
                index={index}
            />

            <div className={styled['hs-field']}>
                <h3 className={styled['step2-label']}>SEDO</h3>
            </div>
        </Box>
    );
};
