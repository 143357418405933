import CloseIcon from '@mui/icons-material/Close';
import { Box, Modal } from '@mui/material';
import { ImportStep } from 'components';
import { SheetPreview, useBalanceImport } from 'components/BalanceImport';
import { OutlinedButton } from 'components/Button';
import { TableType } from 'components/Table';
import { useMapCodeRepo } from 'di';
import { ReactNode } from 'react';
import { StatementImportParams } from '../../../repository/statement15/type';

export const TableMapCodeNextImport = ({ render }: { render: (onClick: () => void) => ReactNode }) => {
    const mapCodeRepo = useMapCodeRepo();

    const { restorationId, onFinish, open, setOpen } = useBalanceImport<StatementImportParams>({
        importFn: (params) => mapCodeRepo.importData(params)
    });

    return (
        <>
            {render(() => setOpen(true))}
            <Modal open={open}>
                <Box
                    sx={{
                        position: 'absolute' as 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '98%',
                        height: '98%',
                        bgcolor: 'whitesmoke',
                        borderRadius: 2,
                        p: 4,
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <OutlinedButton
                        sx={{ alignSelf: 'flex-end' }}
                        onClick={() => {
                            setOpen(false);
                        }}
                    >
                        <CloseIcon />
                    </OutlinedButton>
                    <SheetPreview<StatementImportParams>
                        key={restorationId}
                        setupImport={(data) => mapCodeRepo.setupImport(data)}
                        getFixedHeader={() => mapCodeRepo.getFixedHeader()}
                        onFinish={onFinish}
                        table={TableType.mapCode}
                        steps={[ImportStep.upload, ImportStep.pickHeader, ImportStep.mapping]}
                    />
                </Box>
            </Modal>
        </>
    );
};
