import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';

export function CircularUnderLoad() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '1vh', 
        mr: 4,
  
      }}
    >
      <CircularProgress sx={{ color: '#2196f3' }} disableShrink />
    </Box>
  );
}

export function CircularUnderLoadCenter() {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '10vh',  
           mb: 100
        }}
      >
        <CircularProgress disableShrink />
      </Box>
    );
  }
