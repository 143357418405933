import { JsonSerializable, PagingData } from '../serialization';
import { JsonObject, JsonProperty } from 'json2typescript';
export interface IIobAccountingData {
    id: string;
    code: string;
    name: string;
    unit: string;
    warehouse_name: string;
    import_quantity: number;
    import_value: number;
    export_quantity: number;
    export_value: number;
    begin_quantity: number;
    begin_value: number;
    end_quantity: number;
    end_value: number;
}

@JsonObject('IobAccountingData')
export class IobAccountingData extends JsonSerializable<IobAccountingData> implements IIobAccountingData {
    protected get classRef(): new () => IobAccountingData {
        return IobAccountingData;
    }

    @JsonProperty('id', String, false)
    id!: string;

    @JsonProperty('code', String, false)
    code!: string;

    @JsonProperty('name', String, true)
    name: string = '';

    @JsonProperty('unit', String, true)
    unit: string = '';

    @JsonProperty('warehouse_name', String, true)
    warehouse_name: string = '';

    @JsonProperty('import_quantity', Number, true)
    import_quantity: number = 0;

    @JsonProperty('import_value', Number, true)
    import_value: number = 0;

    @JsonProperty('export_quantity', Number, true)
    export_quantity: number = 0;

    @JsonProperty('export_value', Number, true)
    export_value: number = 0;

    @JsonProperty('begin_quantity', Number, true)
    begin_quantity: number = 0;

    @JsonProperty('begin_value', Number, true)
    begin_value: number = 0;

    @JsonProperty('end_quantity', Number, true)
    end_quantity: number = 0;

    @JsonProperty('end_value', Number, true)
    end_value: number = 0;
}
