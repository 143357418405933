import React, { useMemo, useState } from 'react';
import TreeView from '@mui/lab/TreeView';
import { memo } from 'react';
import { Box } from '@mui/material';
import { StyledTreeItem, StyledTreeItem_noline, StyledLabel, MinusSquare, PlusSquare, CloseSquare } from './LeftSectionStyled';
import { CircularUnderLoad } from './loading';
import { useNavigate, useParams } from 'react-router-dom';
import { useHsContext } from 'contexts';
import useConfig from 'hooks/useConfig';

function cleanString(input) {
	return input.replace(/^-+|-+$/g, '') // Xóa dấu '-' ở đầu và cuối chuỗi
				.replace(/[-\s]+/g, ' ') // Thay thế các dấu '-' và khoảng trắng liên tiếp bằng một khoảng trắng
				.trim(); // Xóa khoảng trắng ở đầu và cuối chuỗi
}

const LeftSections = () => {
	const { id: urlId } = useParams<{ id: string }>();
	const { locale: lang } = useConfig();
	const { Section, Chapter, Header, SupHeader } = useHsContext();
	const [expanded, setExpanded] = useState<string[]>([]); // Track expanded nodes in TreeView
	const [loadingNodes, setLoadingNodes] = useState<{ [key: string]: boolean }>({}); // Track loading state for lazy loading
	const [loadedNodes, setLoadedNodes] = useState<{ [key: string]: boolean }>({}); // Track loaded state for lazy loading
	const navigate = useNavigate();

	let chapterExpendID = '';
	// Function to handle node expansion and lazy load child nodes
	const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
		if (nodeIds.length === 0) {
			return setExpanded([]);
		}
		setExpanded((prevExpanded) => {
			const newExpanded = [...nodeIds];
	
			// Check if the node has not been loaded yet and needs lazy loading
			nodeIds.forEach((nodeId) => {
				if (!loadedNodes[nodeId] && nodeId.startsWith('Chapter')) {
					// Mark node as loading
					setLoadingNodes((prev) => ({ ...prev, [nodeId]: true }));
	
					// Simulate async loading process (you can replace this with actual API/data fetching logic)
					setTimeout(() => {
						// Mark node as loaded and stop showing the loading indicator
						setLoadedNodes((prev) => ({ ...prev, [nodeId]: true }));
						setLoadingNodes((prev) => ({ ...prev, [nodeId]: false }));
					}, 1000); // Simulated delay of 1 second
				}
			});
	
			// Tạo một Set để quản lý trạng thái mở/đóng
			const expandedSet = new Set(newExpanded);
	
			// Lấy node đầu tiên được nhấn
			const currentNodeId = nodeIds[0];
			if (!currentNodeId) return prevExpanded;
	
			const [type] = currentNodeId.split('-');
	
			if (type === "Chapter") {
				// Collapse tất cả các Chapter khác nhưng giữ lại Section
				const allChapters = Array.from(prevExpanded).filter((nodeId) => nodeId.startsWith('Chapter-'));
				allChapters.forEach((chapterId) => {
					if (chapterId !== currentNodeId) {
						expandedSet.delete(chapterId);
					}
				});
			}
	
			if (type === "Header") {
				// Collapse tất cả các Heading khác nhưng giữ lại Chapter và Section
				const allHeaders = Array.from(prevExpanded).filter((nodeId) => nodeId.startsWith('Header-'));
				allHeaders.forEach((headerId) => {
					if (headerId !== currentNodeId) {
						expandedSet.delete(headerId);
					}
				});
			}
	
			if (type === "Section") {
				// Collapse tất cả các Section khác
				const allSections = Array.from(prevExpanded).filter((nodeId) => nodeId.startsWith('Section-'));
				allSections.forEach((sectionId) => {
					if (sectionId !== currentNodeId) {
						expandedSet.delete(sectionId);
					}
				});
			}
	
			// Thêm node hiện tại vào expanded nếu nó chưa có
			expandedSet.add(currentNodeId);
	
			return Array.from(expandedSet);
		});
	};
	
	
	

	// Prepare list of sections with titles
	const listSection = useMemo(() => {
		if (Section) {
			return Section.map((e: any) => ({
				...e,
				title: e[`${lang}_description`] || e.description,
				hs: e.hs,
			}));
		}
		return []
	}, [Section, lang]);

	const getChildChapters = (hs: string): Chapter[] => {
		return Chapter ? Chapter.filter((ch: { _nest_parent_: string }) => ch._nest_parent_ === hs) : [];
	};

	const getChildHeader = (hs: string): Header[] => {
		return Header ? Header.filter((ch: { _nest_parent_: string }) => ch._nest_parent_ === hs) : [];
	};

	const getChildSupHeader = (hs: string): SupHeader[] => {
		return SupHeader ? SupHeader.filter((ch: { _nest_parent_: string }) => ch._nest_parent_ === hs) : [];
	};

	const getNodeId = (prefix, index) => `${prefix}-${index}`;

	const renderSections = (sections: Section[]) => {
		return (
			<Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
			<TreeView
				expanded={expanded}
				onNodeToggle={handleToggle}
				defaultCollapseIcon={<MinusSquare />}
				defaultExpandIcon={<PlusSquare />}
				defaultEndIcon={<CloseSquare />}
				sx={{display: 'flex', flexDirection: 'column', mb: 'auto', position: 'relative', top: '0' }}
			>
				{sections.map((section: Section) => {
					const childChapters = getChildChapters(section.hs);
					const firstChildHs = childChapters.length > 0 ? childChapters[0].hs : '';
					const lastChildHs = childChapters.length > 0 ? childChapters[childChapters.length - 1].hs : '';
					let NodeID = 0;
					const sectionNodeID = getNodeId('Section', section.hs + "_" + NodeID);

					return (
						<>
						<StyledTreeItem_noline
							iconText={section.hs}
							nodeId={String(sectionNodeID)}
							id={String(sectionNodeID)}
							label={<strong>{"SECTION " + String(section.hs) + " (" + String(firstChildHs) + "-" + String(lastChildHs) + ")"}</strong>}
							section={<StyledLabel>{String(section.title.toLowerCase().charAt(0).toUpperCase() + section.title.toLowerCase().slice(1))}</StyledLabel>}
							hs={section.hs}
							expanded={expanded}
							node = {section}
						>
							{childChapters.map((child: Chapter) => {
								NodeID = NodeID + 1;
								const chapterNodeID = getNodeId('Chapter', child.hs + "_" + NodeID);
								const description = cleanString(child[`${lang}_description`] || child.description);
								const childHS = child.hs;
								const childHeader = getChildHeader(childHS);

								// Check if this chapter is being lazy loaded
								const isLoading = loadingNodes[chapterNodeID];
								const isLoaded = loadedNodes[chapterNodeID];

								return (
									<StyledTreeItem
										iconText={childHS}
										nodeId={String(chapterNodeID)}
										id={String(chapterNodeID)}
										label={<StyledLabel>{String(description)}</StyledLabel>}
										hs={childHS}
										expanded={expanded}
										node = {child}
									>
										<div className="verticalLine" />
										{isLoading ? (
											<CircularUnderLoad /> // Show loading indicator while loading
										) : isLoaded ? (
											childHeader.length === 0 ? (
												<CircularUnderLoad />
											) : (
												childHeader.map((childHeader: Header) => {
													NodeID = NodeID + 1;
													const headerNodeID = getNodeId('Header', childHeader.hs + "_" + NodeID);
													const description_heading = cleanString(childHeader[`${lang}_description`] || childHeader.description);
													const childSupHeader = getChildSupHeader(childHeader.hs);

													return (
														<StyledTreeItem
															iconText={childHeader.hs}
															nodeId={String(headerNodeID)}
															id={String(headerNodeID)}
															label={<StyledLabel>{String(description_heading)}</StyledLabel>}
															hs={childHeader.hs}
															node={childHeader}
															expanded={expanded}
														>
															<div className="verticalLine" />
															{childSupHeader.length === 0 ? (
																<CircularUnderLoad />
															) : (
																childSupHeader.map((childSupHeader: SupHeader) => {
																	NodeID = NodeID + 1;
																	const nodeID = getNodeId('SupHeader', childSupHeader.hs + "_" + NodeID);
																	const description_supheader = cleanString(
																		childSupHeader[`${lang}_description`] || childSupHeader.description
																	);
																	const uqParam =
																		childSupHeader[`${lang}_unit_of_quantity`] || childSupHeader.unit_of_quantity;
																	return (
																		<StyledTreeItem
																			iconText={childSupHeader.hs}
																			uqParam={String(uqParam)}
																			nodeId={String(nodeID)}
																			id={String(nodeID)}
																			label={<StyledLabel>{String(description_supheader)}</StyledLabel>}
																			end={childSupHeader}
																			hs={childHeader.hs}
																			expanded={expanded}
																		/>
																	);
																})
															)}
														</StyledTreeItem>
													);
												})
											)
										) : null}
									</StyledTreeItem>
								);
							})}
						</StyledTreeItem_noline>
						<Box sx={{ borderBottom: '1px dashed #ccc', mt: 1, mb: 1 }} />
						</>
					);
				})}
			</TreeView>
			</Box>
		);
	};

	return <Box>{renderSections(listSection)}</Box>;
};

export default memo(LeftSections);
