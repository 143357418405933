import React, {ReactNode, useState} from "react";
import {Alert, Backdrop, CircularProgress, Snackbar, SnackbarProps} from "@mui/material";
import {AlertColor} from "@mui/material/Alert/Alert";
import {useSelector} from "react-redux";
import {State} from "store/reducer";

export const AppProvider = ({children}: { children: ReactNode }) => {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('')
    const [action, setAction] = useState<ReactNode | undefined>()
    const [severity, setSeverity] = useState<AlertColor>('info')

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        setOpen(false);
    };

    const {loading} = useSelector((state: State) => state.app)

    return (
        <>
            {children}
            <Snackbar
                open={open}
                onClose={handleClose}
                message={message}
                action={action}
                anchorOrigin={{vertical: "top", horizontal: "right"}}
            >
                <Alert sx={{minWidth: 280}} severity={severity}>{message}</Alert>
            </Snackbar>
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.snackbar + 1}}
                open={loading}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
        </>
    );
}