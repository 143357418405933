import { HotTable } from '@handsontable/react';
import { HotTableProps } from '@handsontable/react/types';
import equal from 'deep-equal';
import React, { forwardRef } from 'react';

const _Sheet = forwardRef(
    (
        props: HotTableProps & {
            startCell?: string;
            endCell?: string;
        },
        ref: undefined | React.LegacyRef<HotTable>
    ) => {
        return (
            <HotTable
                ref={ref}
                {...props}
                rowHeaders={props.rowHeaders ?? true}
                colHeaders={props.colHeaders ?? true}
                licenseKey="non-commercial-and-evaluation"
            ></HotTable>
        );
    }
);

export const Sheet = React.memo(
    _Sheet,
    (prevProps, nextProps) =>
        equal(prevProps.data, nextProps.data) && prevProps.startCell === nextProps.startCell && prevProps.endCell === nextProps.endCell
);
