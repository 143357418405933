import { EcusBalanceType, EcusData, JSONType, Result, resultGuard, ResultState } from 'models';
import { BalanceParams, EcusQueryParams } from '../type';
import { EcusDataSource } from './EcusDataSource';
import { EcusImportParams, IEcusImportDTO } from './type';
import { AutoFillParams, DeleteAllTableEnum } from 'components';

export class EcusRepository {
    constructor(private datasource: EcusDataSource) {}

    public async getAll(params: EcusQueryParams) {
        return this.datasource.getAll(params);
    }

    public async deleteRows(ids: string[], params: EcusQueryParams) {
        return this.datasource.delete(ids, params);
    }

    public async updateRow(row: Partial<EcusData>, params: EcusQueryParams) {
        return this.datasource.update(row, params);
    }

    public async insertRow(row: JSONType) {
        return this.datasource.insert(row);
    }

    public async importData(data: EcusImportParams) {
        const params: Partial<IEcusImportDTO> = {
            company_slug: data.company_slug,
            end_row: data.end_row,
            excel_file: data.excel_file,
            folder_slug: data.folder_slug,
            sheet_name: data.sheet_name,
            type: data.type,
            ...(data.type === EcusBalanceType.IMPORT ? { header: data.header_from } : { header_from: data.header_from })
        };
        return resultGuard(() => this.datasource.importData({ ...params, header: data.header_from } as IEcusImportDTO));
    }

    public async getFixedHeader(): Promise<Result<string[]>> {
        return {
            state: ResultState.success,
            data: [
                'code',
                'account_number',
                'registered_date',
                'type_code',
                'hs',
                'item_name',
                'from_country',
                'unit_price',
                'unit_price_taxed',
                'total',
                'unit',
                'unit_2',
                'nt_value',
                'total_value',
                'tax_rate',
                'tax_cost',
                'partner',
                'invoice_no',
                'bill_date',
                'contract',
                'contract_date'
            ]
        };
    }
    public async getAutoFillData(data: AutoFillParams) {
        return this.datasource.getAutoFill(data);
    }

    public async getSelectableValues(params: EcusQueryParams) {
        return this.datasource.getSelectableValues(params);
    }

    async deleteAll(params: BalanceParams, type: DeleteAllTableEnum) {
        return resultGuard(async () => await this.datasource.deleteAll(params, type));
    }
}
