import { JsonObject, JsonProperty } from 'json2typescript';
import { JsonSerializable } from 'models/serialization';

export interface IProductionSetupDTO {
    company_slug: string;
    code: string;
    manufacturing_order_no: string;
    product_quantity: string;
    child_item: string;
    use_quantity: string;
}

@JsonObject('ProductionSetupDTO')
export class ProductionSetupDTO extends JsonSerializable<ProductionSetupDTO> implements IProductionSetupDTO {
    protected get classRef(): new () => ProductionSetupDTO {
        return ProductionSetupDTO;
    }

    @JsonProperty('company_slug', String, false)
    company_slug!: string;

    @JsonProperty('code', String, false)
    code!: string;

    @JsonProperty('manufacturing_order_no', String, false)
    manufacturing_order_no!: string;

    @JsonProperty('product_quantity', String, false)
    product_quantity!: string;

    @JsonProperty('child_item', String, false)
    child_item!: string;

    @JsonProperty('use_quantity', String, false)
    use_quantity!: string;
}
