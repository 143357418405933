import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Switch, TextField } from '@mui/material';
import _ from 'lodash';
import { BangKeDataInterface } from 'models/BangKe';
import { InvoiceProductDataInterface } from 'models/InvoiceTable/InvoiceProductData';
import { PSRDataInterface } from 'models/PSR';
import { forwardRef, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { ConvertBangKeName, FormTypeEnum, InvoiceContext } from '../InvoiceProvider';
import {
    DropDownApplicableEnum,
    DropDownEXWInsertTypeEnum,
    DropDownFormularApplicableCriteria,
    DropDownInsertType,
    DropDownType
} from './DropDownFormulars';
import { ConvertOption, FormularFormRef, RevertBangKeDataEXWOption, RevertBangKeDataFormType } from './FormularFormEXW';
import styled from './style.module.css';

export function ConvertFormType(formType: FormTypeEnum) {
    if (formType === FormTypeEnum.UKV || formType === FormTypeEnum.EV) {
        return 'EXW';
    } else return 'FOB';
}

export function BiggerThanZero(str: string) {
    const num = parseInt(str);
    if (num < 0) {
        return false;
    } else {
        return true;
    }
}

export interface FormInputProps<T> {
    insertType: string;
    fn: (item: T) => void;
    item: InvoiceProductDataInterface;
    prevInputItem?: T | null;
}

export interface FormProps {
    item: InvoiceProductDataInterface;
    psrItem: PSRDataInterface | null;
    index: number;
    bangKeItem: BangKeDataInterface | null;
}

export interface UserInputProps {
    fn: (_: UserInputInterface) => void;
    insertType: string;
    prevUserInput?: UserInputInterface | null;
}

export interface UserInputInterface {
    user_input: number;
}

export const UserInput = forwardRef<FormularFormRef, any>(({ fn, insertType, prevUserInput }: UserInputProps, ref: any) => {
    const schema = yup.object({ user_input: yup.number().required().min(0) });
    const { control, handleSubmit, reset } = useForm({
        defaultValues: { user_input: prevUserInput ? prevUserInput.user_input : 0 },
        resolver: yupResolver(schema)
    });

    const SubmitHandler: SubmitHandler<UserInputInterface> = (data: UserInputInterface) => {
        fn(data);
    };

    useEffect(() => {
        // reset();;
    }, [insertType]);

    useImperativeHandle(ref, () => {
        return { submit: handleSubmit(SubmitHandler) };
    });

    return (
        <form onChange={handleSubmit(SubmitHandler)}>
            <Controller
                control={control}
                name="user_input"
                render={({ field, formState: { errors } }) => {
                    return (
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <TextField variant="standard" type="number" {...field} sx={{ width: '100px' }} />
                            {errors.user_input && <span>This input is required</span>}
                        </Box>
                    );
                }}
            />
        </form>
    );
});

export interface ProcessPriceProps {
    fn: (_: ProcessingPriceInterface) => void;
    insertType: string;
    prevProgessPrice?: ProcessingPriceInterface | null;
}

export interface ProcessingPriceInterface {
    price_input: number;
}

export const ProcessPrice = forwardRef<FormularFormRef, any>(({ fn, insertType, prevProgessPrice }: ProcessPriceProps, ref: any) => {
    const schema = yup.object({ price_input: yup.number().required().min(0) });
    const { control, handleSubmit, reset } = useForm({
        defaultValues: { price_input: prevProgessPrice ? prevProgessPrice.price_input : 0 },
        resolver: yupResolver(schema)
    });

    const SubmitHandler: SubmitHandler<ProcessingPriceInterface> = (data: ProcessingPriceInterface) => {
        fn(data);
    };

    useEffect(() => {
        // reset();;
    }, [insertType]);

    useImperativeHandle(ref, () => {
        return { submit: handleSubmit(SubmitHandler) };
    });

    return (
        <form onChange={handleSubmit(SubmitHandler)}>
            <Controller
                control={control}
                name="price_input"
                render={({ field, formState: { errors } }) => {
                    return (
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <TextField variant="standard" type="number" {...field} sx={{ width: '100px' }} />
                            {errors.price_input && <span>This input is required</span>}
                        </Box>
                    );
                }}
            />
        </form>
    );
});

interface FormExwInputInterface {
    nt_value: number;
}

interface FormExwInputProps {
    item: InvoiceProductDataInterface;
    fn: (item: FormExwInputInterface) => void;
}

const FormExwInput = forwardRef(({ item, fn }: FormExwInputProps, ref) => {
    const { handleSubmit, control } = useForm({ defaultValues: { nt_value: item.nt_value } });

    const SubmitHandler: SubmitHandler<FormExwInputInterface> = (data: FormExwInputInterface) => {
        fn(data);
    };

    useImperativeHandle(ref, () => ({ submit: handleSubmit(SubmitHandler) }));

    return (
        <form style={{ display: 'flex', alignItems: 'center' }}>
            <Controller
                control={control}
                name="nt_value"
                render={({ field, formState: { errors } }) => <TextField variant="standard" type="number" {...field} disabled />}
            />
        </form>
    );
});

export interface FormEXWInterface {
    psr_input: string;
}

export const FormEXW = ({ item, psrItem, index, bangKeItem }: FormProps) => {
    const [bangKeData, setBangKeData] = useState<null | BangKeDataInterface>(null);
    const [insertType, setInsertType] = useState(DropDownEXWInsertTypeEnum.formular);
    const { formType, MapBangKeData } = useContext(InvoiceContext);
    const psrLabel = useMemo(() => {
        if (bangKeItem) return bangKeItem.criteria;
        else if (psrItem) return psrItem[formType] ?? '';
        else return '';
    }, [psrItem, bangKeItem]);
    const [sedo, setSedo] = useState(false);

    const [dropDownApplicable, setDropDownApplicable] = useState<DropDownApplicableEnum>(DropDownApplicableEnum.CTC);
    const [exwInput, setExwInput] = useState<null | FormExwInputInterface>(null);
    const exwInputRef = useRef(null);
    function CollectExwInput(input: FormExwInputInterface) {
        setExwInput(input);
    }
    const processPriceRef = useRef(null);
    const [processPrice, setProcessPrice] = useState<null | ProcessingPriceInterface>(null);
    function CollectProcessPrice(input: ProcessingPriceInterface) {
        setProcessPrice({ ...input });
    }
    const [userInput, setUserInput] = useState<null | UserInputInterface>(null);
    const inputRef = useRef(null);
    function CollectInput(input: UserInputInterface) {
        setUserInput(input);
    }
    const [formData, setFormData] = useState<FormEXWInterface>({ psr_input: psrLabel ?? '' });

    useEffect(() => {
        const bangKeItem: BangKeDataInterface = {
            id: item.id,
            exchange_rate: item.exchange_rate,
            use_sedo: sedo,
            account_number: item.account_number,
            registered_date: item.registered_date,
            option: ConvertOption(insertType, formType),
            form_type: dropDownApplicable,
            criteria: formData.psr_input,
            code: item.code,
            name: item.item_name,
            bill_date: item.bill_date,
            hs: item.hs,
            total: item.total,
            nt_value: item.nt_value,

            input: {}
        };

        if (insertType === DropDownEXWInsertTypeEnum.user_input) {
            bangKeItem.input = { result: userInput?.user_input ?? 0 };
        }
        if (insertType === DropDownEXWInsertTypeEnum.progess_price) {
            bangKeItem.input = { result: processPrice?.price_input ?? 0 };
        }
        const timeout = setTimeout(() => {
            setBangKeData((prev: BangKeDataInterface | null) => {
                if (prev === null) return bangKeItem;
                else {
                    if (_.isEqual(prev, bangKeItem)) return prev;
                    else {
                        return bangKeItem;
                    }
                }
            });
        }, 500);

        return () => clearTimeout(timeout);
    }, [item, insertType, dropDownApplicable, sedo, exwInput, processPrice, userInput, formData]);

    useEffect(() => {
        if (bangKeData) {
            MapBangKeData(bangKeData);
        }
    }, [bangKeData]);

    function SedoHandler() {
        setSedo((prev) => !prev);
    }

    useEffect(() => {
        if (sedo) {
            setDropDownApplicable(DropDownApplicableEnum.RVC);
            setInsertType(DropDownEXWInsertTypeEnum.progess_price);
        } else {
            setInsertType(DropDownEXWInsertTypeEnum.formular);
        }
    }, [sedo]);

    useEffect(() => {
        if (bangKeItem) {
            if (bangKeItem.use_sedo) {
                setDropDownApplicable(DropDownApplicableEnum.RVC);
                setSedo(true);
            } else {
                setDropDownApplicable(RevertBangKeDataFormType(bangKeItem.form_type));
            }
            if (RevertBangKeDataEXWOption(bangKeItem.option) === DropDownEXWInsertTypeEnum.progess_price) {
                setInsertType(DropDownEXWInsertTypeEnum.progess_price);
                setProcessPrice({ price_input: bangKeItem.input.result ?? 0 });
            } else if (RevertBangKeDataEXWOption(bangKeItem.option) === DropDownEXWInsertTypeEnum.user_input) {
                setInsertType(DropDownEXWInsertTypeEnum.user_input);
                setUserInput({ user_input: bangKeItem.input.result ?? 0 });
            } else {
                setInsertType(DropDownEXWInsertTypeEnum.formular);
            }
            setFormData({ psr_input: bangKeItem.criteria });
        }
    }, [bangKeItem]);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <h3>{item.item_name}</h3>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                <label>Criteria to apply: </label>
                <TextField
                    value={formData.psr_input}
                    variant="standard"
                    sx={{ width: 200 }}
                    onChange={(e) => {
                        setFormData({ psr_input: e.target.value });
                    }}
                />
            </Box>
            <DropDownInsertType sedo={sedo} insertType={insertType} setInsertType={setInsertType} type={DropDownType.exw} index={index} />
            {insertType === DropDownEXWInsertTypeEnum.user_input ? (
                <Box>
                    <UserInput ref={inputRef} insertType={insertType} fn={CollectInput} prevUserInput={userInput} />
                </Box>
            ) : (
                <>
                    {insertType === DropDownEXWInsertTypeEnum.formular ? (
                        <Box>
                            <FormExwInput ref={exwInputRef} item={item} fn={CollectExwInput} />
                        </Box>
                    ) : (
                        <Box>
                            <ProcessPrice
                                ref={processPriceRef}
                                insertType={insertType}
                                fn={CollectProcessPrice}
                                prevProgessPrice={processPrice}
                            />
                        </Box>
                    )}
                </>
            )}
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                <DropDownFormularApplicableCriteria
                    sedo={sedo}
                    dropDownApplicable={dropDownApplicable}
                    setDropDownApplicable={setDropDownApplicable}
                    index={index}
                />
                <Box sx={{ display: 'flex', gap: ' 6px', alignItems: 'center' }}>
                    <h3 className={styled['step2-label']}>{index === 1 ? 'Sedo' : ' '}</h3>
                    <Switch checked={sedo} onChange={SedoHandler} />
                </Box>
            </Box>
        </Box>
    );
};

interface FormFobInputInterface {
    nt_value: number;
    domestic_expenses: number;
}

const FormFobInput = forwardRef<FormularFormRef, any>(
    ({ item, insertType, fn, prevInputItem }: FormInputProps<FormFobInputInterface>, ref) => {
        const schema = yup.object({
            nt_value: yup.number(),
            domestic_expenses: yup.number().required().min(0)
        });

        const { handleSubmit, control, reset } = useForm({
            defaultValues: { nt_value: item.nt_value, domestic_expenses: prevInputItem ? prevInputItem.domestic_expenses : 0 },
            resolver: yupResolver(schema)
        });

        const SubmitHandler: SubmitHandler<FormFobInputInterface> = (data: FormFobInputInterface) => {
            fn(data);
        };

        useEffect(() => {
            // reset();;
        }, [insertType]);

        useImperativeHandle(ref, () => ({ submit: handleSubmit(SubmitHandler) }));

        return (
            <form onChange={handleSubmit(SubmitHandler)} style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                <Controller
                    control={control}
                    name="nt_value"
                    render={({ field }) => (
                        <TextField
                            variant="standard"
                            type="number"
                            {...field}
                            disabled
                            sx={{
                                width: '80px'
                            }}
                        />
                    )}
                />
                <div style={{ fontWeight: 'bold' }}> - </div>
                <Controller
                    control={control}
                    name="domestic_expenses"
                    render={({ field, formState: { errors } }) => (
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <TextField
                                variant="standard"
                                type="number"
                                {...field}
                                sx={{
                                    width: '80px'
                                }}
                            />
                            {errors.domestic_expenses && <span>This input is required</span>}
                        </Box>
                    )}
                />
            </form>
        );
    }
);

export interface FormFobInterface {
    psr_input: string;
}

export const FormFOB = ({ item, psrItem, index, bangKeItem }: FormProps) => {
    const [bangKeData, setBangKeData] = useState<null | BangKeDataInterface>(null);
    const { formType, MapBangKeData } = useContext(InvoiceContext);
    const [formData, setFormData] = useState<FormFobInterface>({ psr_input: psrItem ? psrItem[formType] : '' });
    const [sedo, setSedo] = useState(false);
    const [insertType, setInsertType] = useState(DropDownEXWInsertTypeEnum.formular);
    const [dropDownApplicable, setDropDownApplicable] = useState<DropDownApplicableEnum>(DropDownApplicableEnum.RVC);
    const fobInputRef = useRef(null);
    const [fobInput, setFobInput] = useState<null | FormFobInputInterface>(null);
    function CollectionFobInput(input: FormFobInputInterface) {
        setFobInput(input);
    }
    const processPriceRef = useRef(null);
    const [processPrice, setProcessPrice] = useState<null | ProcessingPriceInterface>(null);
    function CollectProcessPrice(input: ProcessingPriceInterface) {
        setProcessPrice({ ...input });
    }
    const inputRef = useRef(null);
    const [userInput, setUserInput] = useState<null | UserInputInterface>(null);
    function CollectInput(input: UserInputInterface) {
        setUserInput(input);
    }

    useEffect(() => {
        const bangKeItem: BangKeDataInterface = {
            id: item.id,
            exchange_rate: item.exchange_rate,
            use_sedo: sedo,
            account_number: item.account_number,
            registered_date: item.registered_date,
            hs: item.hs,
            total: item.total,
            nt_value: item.nt_value,
            name: item.item_name,
            bill_date: item.bill_date,
            form_type: dropDownApplicable,
            option: ConvertOption(insertType, formType),
            criteria: formData.psr_input,
            code: item.code,
            input: { result: 0 }
        };
        if (insertType === DropDownEXWInsertTypeEnum.formular) {
            bangKeItem.input = { domestic_expenses: fobInput?.domestic_expenses ?? 0 };
        }
        if (insertType === DropDownEXWInsertTypeEnum.user_input) {
            bangKeItem.input = { result: userInput?.user_input ?? 0 };
        }
        if (insertType === DropDownEXWInsertTypeEnum.progess_price) {
            bangKeItem.input = { result: processPrice?.price_input ?? 0 };
        }

        const timeout = setTimeout(() => {
            setBangKeData((prev: BangKeDataInterface | null) => {
                if (prev === null) return bangKeItem;
                else {
                    if (_.isEqual(prev, bangKeItem)) return prev;
                    else {
                        return bangKeItem;
                    }
                }
            });
        }, 500);

        return () => clearTimeout(timeout);
    }, [sedo, insertType, dropDownApplicable, fobInput, processPrice, userInput, formData]);

    useEffect(() => {
        if (bangKeData) {
            MapBangKeData(bangKeData);
        }
    }, [bangKeData]);

    useEffect(() => {
        if (sedo) {
            setDropDownApplicable(DropDownApplicableEnum.RVC);
            setInsertType(DropDownEXWInsertTypeEnum.progess_price);
        } else {
            setInsertType(DropDownEXWInsertTypeEnum.formular);
        }
    }, [sedo]);

    useEffect(() => {
        if (bangKeItem) {
            if (bangKeItem.use_sedo) {
                setDropDownApplicable(DropDownApplicableEnum.RVC);
                setSedo(true);
            } else {
                setDropDownApplicable(RevertBangKeDataFormType(bangKeItem.form_type));
                setSedo(false);
            }
            if (RevertBangKeDataEXWOption(bangKeItem.option) === DropDownEXWInsertTypeEnum.progess_price) {
                setInsertType(DropDownEXWInsertTypeEnum.progess_price);
                setProcessPrice({ price_input: bangKeItem.input.result ?? 0 });
            } else if (RevertBangKeDataEXWOption(bangKeItem.option) === DropDownEXWInsertTypeEnum.user_input) {
                setInsertType(DropDownEXWInsertTypeEnum.user_input);
                setUserInput({ user_input: bangKeItem.input.result ?? 0 });
            } else {
                setInsertType(DropDownEXWInsertTypeEnum.formular);
                setFobInput({ nt_value: item.nt_value ?? 0, domestic_expenses: bangKeItem.input.domestic_expenses ?? 0 });
            }
            setFormData({ psr_input: bangKeItem.criteria });
        }
    }, [bangKeItem]);

    function SedoHandler() {
        setSedo((prev) => !prev);
    }

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '10px' }}>
            <div className={styled['no-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'No' : ' '}</h3>
                <h3>{index}</h3>
            </div>
            <div className={styled['code-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Code' : ' '}</h3>
                <h3>{item.code}</h3>
            </div>
            <div className={styled['name-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Name' : ' '}</h3>
                <h3>{ConvertBangKeName(item.item_name)}</h3>
            </div>
            <div className={styled['hs-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Hs' : ' '}</h3>
                <h3>{item.hs}</h3>
            </div>

            <div className={styled['criteria-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Criteria' : ' '}</h3>
                <TextField
                    value={formData.psr_input}
                    variant="standard"
                    sx={{ width: 200 }}
                    onChange={(e) => {
                        setFormData({ psr_input: e.target.value });
                    }}
                />
            </div>

            <DropDownInsertType sedo={sedo} insertType={insertType} setInsertType={setInsertType} type={DropDownType.exw} index={index} />

            <div className={styled['hs-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Value' : ' '}</h3>
                {insertType === DropDownEXWInsertTypeEnum.user_input ? (
                    <UserInput ref={inputRef} insertType={insertType} fn={CollectInput} prevUserInput={userInput} />
                ) : (
                    <>
                        {insertType === DropDownEXWInsertTypeEnum.formular ? (
                            <FormFobInput
                                item={item}
                                ref={fobInputRef}
                                insertType={insertType}
                                fn={CollectionFobInput}
                                prevInputItem={fobInput}
                            />
                        ) : (
                            <ProcessPrice
                                ref={processPriceRef}
                                insertType={insertType}
                                fn={CollectProcessPrice}
                                prevProgessPrice={processPrice}
                            />
                        )}
                    </>
                )}
            </div>

            <DropDownFormularApplicableCriteria
                sedo={sedo}
                dropDownApplicable={dropDownApplicable}
                setDropDownApplicable={setDropDownApplicable}
                index={index}
            />

            <div className={styled['hs-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Sedo' : ' '}</h3>
                <Switch checked={sedo} onChange={SedoHandler} />
            </div>
        </Box>
    );
};

export const FormFCA = ({ item, psrItem, index, bangKeItem }: FormProps) => {
    const [bangKeData, setBangKeData] = useState<null | BangKeDataInterface>(null);
    const { formType, MapBangKeData } = useContext(InvoiceContext);
    const [formData, setFormData] = useState<FormFobInterface>({ psr_input: psrItem ? psrItem[formType] : '' });
    const [sedo, setSedo] = useState(false);

    const [insertType, setInsertType] = useState(DropDownEXWInsertTypeEnum.formular);
    const [dropDownApplicable, setDropDownApplicable] = useState<DropDownApplicableEnum>(DropDownApplicableEnum.CTC);
    const fobInputRef = useRef(null);
    const [fobInput, setFobInput] = useState<null | FormFobInputInterface>(null);
    function CollectionFobInput(input: FormFobInputInterface) {
        setFobInput(input);
    }
    const processPriceRef = useRef(null);
    const [processPrice, setProcessPrice] = useState<null | ProcessingPriceInterface>(null);
    function CollectProcessPrice(input: ProcessingPriceInterface) {
        setProcessPrice({ ...input });
    }
    const inputRef = useRef(null);
    const [userInput, setUserInput] = useState<null | UserInputInterface>(null);
    function CollectInput(input: UserInputInterface) {
        setUserInput(input);
    }

    useEffect(() => {
        const bangKeItem: BangKeDataInterface = {
            id: item.id,
            exchange_rate: item.exchange_rate,
            use_sedo: sedo,
            account_number: item.account_number,
            registered_date: item.registered_date,
            hs: item.hs,
            total: item.total,
            nt_value: item.nt_value,
            name: item.item_name,
            bill_date: item.bill_date,
            form_type: dropDownApplicable,
            option: ConvertOption(insertType, formType),
            criteria: formData.psr_input,
            code: item.code,
            input: { result: 0 }
        };
        if (insertType === DropDownEXWInsertTypeEnum.formular) {
            bangKeItem.input = { domestic_expenses: fobInput?.domestic_expenses ?? 0 };
        }
        if (insertType === DropDownEXWInsertTypeEnum.user_input) {
            bangKeItem.input = { result: userInput?.user_input ?? 0 };
        }
        if (insertType === DropDownEXWInsertTypeEnum.progess_price) {
            bangKeItem.input = { result: processPrice?.price_input ?? 0 };
        }
        const timeout = setTimeout(() => {
            setBangKeData((prev: BangKeDataInterface | null) => {
                if (prev === null) return bangKeItem;
                else {
                    if (_.isEqual(prev, bangKeItem)) return prev;
                    else {
                        return bangKeItem;
                    }
                }
            });
        }, 500);

        return () => clearTimeout(timeout);
    }, [sedo, insertType, dropDownApplicable, fobInput, processPrice, userInput, formData]);

    useEffect(() => {
        if (bangKeData) {
            MapBangKeData(bangKeData);
        }
    }, [bangKeData]);

    useEffect(() => {
        if (sedo) {
            setDropDownApplicable(DropDownApplicableEnum.RVC);
            setInsertType(DropDownEXWInsertTypeEnum.progess_price);
        } else {
            setInsertType(DropDownEXWInsertTypeEnum.formular);
        }
    }, [sedo]);

    useEffect(() => {
        if (bangKeItem) {
            if (bangKeItem.use_sedo) {
                setDropDownApplicable(DropDownApplicableEnum.RVC);
                setSedo(true);
            } else {
                setDropDownApplicable(RevertBangKeDataFormType(bangKeItem.form_type));
                setSedo(false);
            }
            if (RevertBangKeDataEXWOption(bangKeItem.option) === DropDownEXWInsertTypeEnum.progess_price) {
                setInsertType(DropDownEXWInsertTypeEnum.progess_price);
                setProcessPrice({ price_input: bangKeItem.input.result ?? 0 });
            } else if (RevertBangKeDataEXWOption(bangKeItem.option) === DropDownEXWInsertTypeEnum.user_input) {
                setInsertType(DropDownEXWInsertTypeEnum.user_input);
                setUserInput({ user_input: bangKeItem.input.result ?? 0 });
            } else {
                setInsertType(DropDownEXWInsertTypeEnum.formular);
                setFobInput({ nt_value: item.nt_value ?? 0, domestic_expenses: bangKeItem.input.domestic_expenses ?? 0 });
            }
            setFormData({ psr_input: bangKeItem.criteria });
        }
    }, [bangKeItem]);

    function SedoHandler() {
        setSedo((prev) => !prev);
    }

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '10px' }}>
            <div className={styled['no-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'No' : ' '}</h3>
                <h3>{index}</h3>
            </div>
            <div className={styled['code-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Code' : ' '}</h3>
                <h3>{item.code}</h3>
            </div>
            <div className={styled['name-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Name' : ' '}</h3>
                <h3>{ConvertBangKeName(item.item_name)}</h3>
            </div>
            <div className={styled['hs-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Hs' : ' '}</h3>
                <h3>{item.hs}</h3>
            </div>

            <div className={styled['criteria-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Criteria' : ' '}</h3>
                <TextField
                    value={formData.psr_input}
                    variant="standard"
                    sx={{ width: 200 }}
                    onChange={(e) => {
                        setFormData({ psr_input: e.target.value });
                    }}
                />
            </div>

            <DropDownInsertType sedo={sedo} insertType={insertType} setInsertType={setInsertType} type={DropDownType.exw} index={index} />

            <div className={styled['hs-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Value' : ' '}</h3>
                {insertType === DropDownEXWInsertTypeEnum.user_input ? (
                    <UserInput ref={inputRef} insertType={insertType} fn={CollectInput} prevUserInput={userInput} />
                ) : (
                    <>
                        {insertType === DropDownEXWInsertTypeEnum.formular ? (
                            <FormFobInput
                                item={item}
                                ref={fobInputRef}
                                insertType={insertType}
                                fn={CollectionFobInput}
                                prevInputItem={fobInput}
                            />
                        ) : (
                            <ProcessPrice
                                ref={processPriceRef}
                                insertType={insertType}
                                fn={CollectProcessPrice}
                                prevProgessPrice={processPrice}
                            />
                        )}
                    </>
                )}
            </div>

            <DropDownFormularApplicableCriteria
                sedo={sedo}
                dropDownApplicable={dropDownApplicable}
                setDropDownApplicable={setDropDownApplicable}
                index={index}
            />

            <div className={styled['hs-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Sedo' : ' '}</h3>
                <Switch checked={sedo} onChange={SedoHandler} />
            </div>
        </Box>
    );
};

export const FormFAS = ({ item, psrItem, index, bangKeItem }: FormProps) => {
    const [bangKeData, setBangKeData] = useState<null | BangKeDataInterface>(null);
    const { formType, MapBangKeData } = useContext(InvoiceContext);
    const [formData, setFormData] = useState<FormFobInterface>({ psr_input: psrItem ? psrItem[formType] : '' });
    const [sedo, setSedo] = useState(false);

    const [insertType, setInsertType] = useState(DropDownEXWInsertTypeEnum.formular);
    const [dropDownApplicable, setDropDownApplicable] = useState<DropDownApplicableEnum>(DropDownApplicableEnum.CTC);
    const fobInputRef = useRef(null);
    const [fobInput, setFobInput] = useState<null | FormFobInputInterface>(null);
    function CollectionFobInput(input: FormFobInputInterface) {
        setFobInput(input);
    }
    const processPriceRef = useRef(null);
    const [processPrice, setProcessPrice] = useState<null | ProcessingPriceInterface>(null);
    function CollectProcessPrice(input: ProcessingPriceInterface) {
        setProcessPrice({ ...input });
    }
    const inputRef = useRef(null);
    const [userInput, setUserInput] = useState<null | UserInputInterface>(null);
    function CollectInput(input: UserInputInterface) {
        setUserInput(input);
    }

    useEffect(() => {
        const bangKeItem: BangKeDataInterface = {
            id: item.id,
            exchange_rate: item.exchange_rate,
            use_sedo: sedo,
            account_number: item.account_number,
            registered_date: item.registered_date,
            hs: item.hs,
            total: item.total,
            nt_value: item.nt_value,
            name: item.item_name,
            bill_date: item.bill_date,
            form_type: dropDownApplicable,
            option: ConvertOption(insertType, formType),
            criteria: formData.psr_input,
            code: item.code,
            input: { result: 0 }
        };
        if (insertType === DropDownEXWInsertTypeEnum.formular) {
            bangKeItem.input = { domestic_expenses: fobInput?.domestic_expenses ?? 0 };
        }
        if (insertType === DropDownEXWInsertTypeEnum.user_input) {
            bangKeItem.input = { result: userInput?.user_input ?? 0 };
        }
        if (insertType === DropDownEXWInsertTypeEnum.progess_price) {
            bangKeItem.input = { result: processPrice?.price_input ?? 0 };
        }

        const timeout = setTimeout(() => {
            setBangKeData((prev: BangKeDataInterface | null) => {
                if (prev === null) return bangKeItem;
                else {
                    if (_.isEqual(prev, bangKeItem)) return prev;
                    else {
                        return bangKeItem;
                    }
                }
            });
        }, 500);

        return () => clearTimeout(timeout);
    }, [sedo, insertType, dropDownApplicable, fobInput, processPrice, userInput, formData]);

    useEffect(() => {
        if (bangKeData) {
            MapBangKeData(bangKeData);
        }
    }, [bangKeData]);

    useEffect(() => {
        if (sedo) {
            setDropDownApplicable(DropDownApplicableEnum.RVC);
            setInsertType(DropDownEXWInsertTypeEnum.progess_price);
        } else {
            setInsertType(DropDownEXWInsertTypeEnum.formular);
        }
    }, [sedo]);

    useEffect(() => {
        if (bangKeItem) {
            if (bangKeItem.use_sedo) {
                setDropDownApplicable(DropDownApplicableEnum.RVC);
                setSedo(true);
            } else {
                setDropDownApplicable(RevertBangKeDataFormType(bangKeItem.form_type));
                setSedo(false);
            }
            if (RevertBangKeDataEXWOption(bangKeItem.option) === DropDownEXWInsertTypeEnum.progess_price) {
                setInsertType(DropDownEXWInsertTypeEnum.progess_price);
                setProcessPrice({ price_input: bangKeItem.input.result ?? 0 });
            } else if (RevertBangKeDataEXWOption(bangKeItem.option) === DropDownEXWInsertTypeEnum.user_input) {
                setInsertType(DropDownEXWInsertTypeEnum.user_input);
                setUserInput({ user_input: bangKeItem.input.result ?? 0 });
            } else {
                setInsertType(DropDownEXWInsertTypeEnum.formular);
                setFobInput({ nt_value: item.nt_value ?? 0, domestic_expenses: bangKeItem.input.domestic_expenses ?? 0 });
            }
            setFormData({ psr_input: bangKeItem.criteria });
        }
    }, [bangKeItem]);

    function SedoHandler() {
        setSedo((prev) => !prev);
    }

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '10px' }}>
            <div className={styled['no-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'No' : ' '}</h3>
                <h3>{index}</h3>
            </div>
            <div className={styled['code-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Code' : ' '}</h3>
                <h3>{item.code}</h3>
            </div>
            <div className={styled['name-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Name' : ' '}</h3>
                <h3>{ConvertBangKeName(item.item_name)}</h3>
            </div>
            <div className={styled['hs-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Hs' : ' '}</h3>
                <h3>{item.hs}</h3>
            </div>

            <div className={styled['criteria-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Criteria' : ' '}</h3>
                <TextField
                    value={formData.psr_input}
                    variant="standard"
                    sx={{ width: 200 }}
                    onChange={(e) => {
                        setFormData({ psr_input: e.target.value });
                    }}
                />
            </div>

            <DropDownInsertType sedo={sedo} insertType={insertType} setInsertType={setInsertType} type={DropDownType.exw} index={index} />

            <div className={styled['hs-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Value' : ' '}</h3>
                {insertType === DropDownEXWInsertTypeEnum.user_input ? (
                    <UserInput ref={inputRef} insertType={insertType} fn={CollectInput} prevUserInput={userInput} />
                ) : (
                    <>
                        {insertType === DropDownEXWInsertTypeEnum.formular ? (
                            <FormFobInput
                                item={item}
                                ref={fobInputRef}
                                insertType={insertType}
                                fn={CollectionFobInput}
                                prevInputItem={fobInput}
                            />
                        ) : (
                            <ProcessPrice
                                ref={processPriceRef}
                                insertType={insertType}
                                fn={CollectProcessPrice}
                                prevProgessPrice={processPrice}
                            />
                        )}
                    </>
                )}
            </div>

            <DropDownFormularApplicableCriteria
                sedo={sedo}
                dropDownApplicable={dropDownApplicable}
                setDropDownApplicable={setDropDownApplicable}
                index={index}
            />

            <div className={styled['hs-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Sedo' : ' '}</h3>
                <Switch checked={sedo} onChange={SedoHandler} />
            </div>
        </Box>
    );
};

interface FormCfrInputInterface {
    nt_value: number;
    freight: number;
    domestic_expenses: number;
}

const FormCfrInput = forwardRef<FormularFormRef, any>(
    ({ item, insertType, fn, prevInputItem }: FormInputProps<FormCfrInputInterface>, ref) => {
        const schema = yup.object({
            nt_value: yup.number(),
            freight: yup.number().required().min(0),
            domestic_expenses: yup.number().required().min(0)
        });

        const { handleSubmit, control, reset } = useForm({
            defaultValues: {
                nt_value: item.nt_value,
                freight: prevInputItem ? prevInputItem.freight : 0,
                domestic_expenses: prevInputItem ? prevInputItem.domestic_expenses : 0
            },
            resolver: yupResolver(schema)
        });

        const SubmitHandler: SubmitHandler<FormCfrInputInterface> = (data: FormCfrInputInterface) => {
            fn(data);
        };

        useEffect(() => {
            // reset();;
        }, [insertType]);

        useImperativeHandle(ref, () => ({ submit: handleSubmit(SubmitHandler) }));

        return (
            <form onChange={handleSubmit(SubmitHandler)} style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                <Controller
                    control={control}
                    name="nt_value"
                    render={({ field, formState: { errors } }) => (
                        <TextField
                            variant="standard"
                            type="number"
                            {...field}
                            disabled
                            sx={{
                                width: '80px'
                            }}
                        />
                    )}
                />
                <div style={{ fontWeight: 'bold' }}> - </div>
                <Controller
                    control={control}
                    name="freight"
                    render={({ field, formState: { errors } }) => (
                        <>
                            <TextField
                                variant="standard"
                                type="number"
                                {...field}
                                disabled
                                sx={{
                                    width: '80px'
                                }}
                            />
                            {errors.freight && <span>This input is required</span>}
                        </>
                    )}
                />
                <div style={{ fontWeight: 'bold' }}> - </div>
                <Controller
                    control={control}
                    name="domestic_expenses"
                    render={({ field, formState: { errors } }) => (
                        <>
                            <TextField
                                variant="standard"
                                type="number"
                                {...field}
                                disabled
                                sx={{
                                    width: '80px'
                                }}
                            />
                            {errors.domestic_expenses && <span>This input is required</span>}
                        </>
                    )}
                />
            </form>
        );
    }
);

export interface FormCFRInterface {
    psr_input: string;
}

export const FormCFR = ({ item, psrItem, index, bangKeItem }: FormProps) => {
    const [bangKeData, setBangKeData] = useState<null | BangKeDataInterface>(null);
    const { formType, MapBangKeData } = useContext(InvoiceContext);
    const psrLabel = useMemo(() => {
        if (bangKeItem) return bangKeItem.criteria;
        else if (psrItem) return psrItem[formType] ?? '';
        else return '';
    }, [psrItem, bangKeItem]);
    const [formData, setFormData] = useState<FormCFRInterface>({ psr_input: psrLabel ?? '' });
    const [sedo, setSedo] = useState(false);

    const [insertType, setInsertType] = useState(DropDownEXWInsertTypeEnum.formular);
    const [dropDownApplicable, setDropDownApplicable] = useState<DropDownApplicableEnum>(DropDownApplicableEnum.CTC);
    const processPriceRef = useRef(null);
    const [processPrice, setProcessPrice] = useState<null | ProcessingPriceInterface>(null);
    function CollectProcessPrice(input: ProcessingPriceInterface) {
        setProcessPrice({ ...input });
    }
    const [userInput, setUserInput] = useState<null | UserInputInterface>(null);
    const inputRef = useRef(null);
    function CollectInput(input: UserInputInterface) {
        setUserInput(input);
    }
    const [cfrInput, setCfrInput] = useState<null | FormCfrInputInterface>(null);
    const cfrInputRef = useRef(null);
    function CollectCfrInput(input: FormCfrInputInterface) {
        setCfrInput(input);
    }

    useEffect(() => {
        const bangKeItem: BangKeDataInterface = {
            id: item.id,
            exchange_rate: item.exchange_rate,
            use_sedo: sedo,
            account_number: item.account_number,
            registered_date: item.registered_date,
            hs: item.hs,
            total: item.total,
            nt_value: item.nt_value,
            name: item.item_name,
            bill_date: item.bill_date,
            form_type: dropDownApplicable,
            option: ConvertOption(insertType, formType),
            criteria: formData.psr_input,
            code: item.code,

            input: {
                result: 0
            }
        };
        if (insertType === DropDownEXWInsertTypeEnum.formular) {
            bangKeItem.input = {
                domestic_expenses: cfrInput?.domestic_expenses ?? 0,
                freight: cfrInput?.freight ?? 0
            };
        }
        if (insertType === DropDownEXWInsertTypeEnum.user_input) {
            bangKeItem.input = { result: userInput?.user_input ?? 0 };
        }
        if (insertType === DropDownEXWInsertTypeEnum.progess_price) {
            bangKeItem.input = { result: processPrice?.price_input ?? 0 };
        }
        const timeout = setTimeout(() => {
            setBangKeData((prev: BangKeDataInterface | null) => {
                if (prev === null) return bangKeItem;
                else {
                    if (_.isEqual(prev, bangKeItem)) return prev;
                    else {
                        return bangKeItem;
                    }
                }
            });
        }, 500);

        return () => clearTimeout(timeout);
    }, [sedo, insertType, dropDownApplicable, processPrice, userInput, cfrInput, formData]);

    useEffect(() => {
        if (bangKeData) {
            MapBangKeData(bangKeData);
        }
    }, [bangKeData]);

    function SedoHandler() {
        setSedo((prev) => !prev);
    }

    useEffect(() => {
        if (sedo) {
            setDropDownApplicable(DropDownApplicableEnum.RVC);
            setInsertType(DropDownEXWInsertTypeEnum.progess_price);
        } else {
            setInsertType(DropDownEXWInsertTypeEnum.formular);
        }
    }, [sedo]);

    useEffect(() => {
        if (bangKeItem) {
            if (bangKeItem.use_sedo) {
                setDropDownApplicable(DropDownApplicableEnum.RVC);
                setSedo(true);
            } else {
                setDropDownApplicable(RevertBangKeDataFormType(bangKeItem.form_type));
                setSedo(false);
            }
            if (RevertBangKeDataEXWOption(bangKeItem.option) === DropDownEXWInsertTypeEnum.progess_price) {
                setInsertType(DropDownEXWInsertTypeEnum.progess_price);
                setProcessPrice({ price_input: bangKeItem.input.result ?? 0 });
            } else if (RevertBangKeDataEXWOption(bangKeItem.option) === DropDownEXWInsertTypeEnum.user_input) {
                setInsertType(DropDownEXWInsertTypeEnum.user_input);
                setUserInput({ user_input: bangKeItem.input.result ?? 0 });
            } else {
                setInsertType(DropDownEXWInsertTypeEnum.formular);
                setCfrInput({
                    nt_value: item.nt_value ?? 0,
                    domestic_expenses: bangKeItem.input.domestic_expenses ?? 0,
                    freight: bangKeItem.input.freight ?? 0
                });
            }
            setFormData({ psr_input: bangKeItem.criteria });
        }
    }, [bangKeItem]);

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '10px' }}>
            <div className={styled['no-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'No' : ' '}</h3>
                <h3>{index}</h3>
            </div>
            <div className={styled['code-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Code' : ' '}</h3>
                <h3>{item.code}</h3>
            </div>
            <div className={styled['name-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Name' : ' '}</h3>
                <h3>{ConvertBangKeName(item.item_name)}</h3>
            </div>
            <div className={styled['hs-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Hs' : ' '}</h3>
                <h3>{item.hs}</h3>
            </div>

            <div className={styled['criteria-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Criteria' : ' '}</h3>
                <TextField
                    value={formData.psr_input}
                    variant="standard"
                    sx={{ width: 200 }}
                    onChange={(e) => {
                        setFormData({ psr_input: e.target.value });
                    }}
                />
            </div>

            <DropDownInsertType sedo={sedo} insertType={insertType} setInsertType={setInsertType} type={DropDownType.exw} index={index} />

            <div className={styled['hs-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Value' : ' '}</h3>
                {insertType === DropDownEXWInsertTypeEnum.user_input ? (
                    <Box>
                        <UserInput ref={inputRef} insertType={insertType} fn={CollectInput} prevUserInput={userInput} />
                    </Box>
                ) : (
                    <>
                        {insertType === DropDownEXWInsertTypeEnum.formular ? (
                            <Box>
                                <FormCfrInput
                                    ref={cfrInputRef}
                                    item={item}
                                    insertType={insertType}
                                    fn={CollectCfrInput}
                                    prevInputItem={FormFobInput}
                                />
                            </Box>
                        ) : (
                            <Box>
                                <ProcessPrice
                                    ref={processPriceRef}
                                    insertType={insertType}
                                    fn={CollectProcessPrice}
                                    prevProgessPrice={processPrice}
                                />
                            </Box>
                        )}
                    </>
                )}
            </div>
            <DropDownFormularApplicableCriteria
                sedo={sedo}
                dropDownApplicable={dropDownApplicable}
                setDropDownApplicable={setDropDownApplicable}
                index={index}
            />

            <div className={styled['hs-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Sedo' : ' '}</h3>
                <Switch checked={sedo} onChange={SedoHandler} />
            </div>
        </Box>
    );
};

interface FormCifInputInterface {
    nt_value: number;
    domestic_expenses: number;
    insurance: number;
    freight: number;
}

const FormCifInput = forwardRef<FormularFormRef, any>(
    ({ item, insertType, fn, prevInputItem }: FormInputProps<FormCifInputInterface>, ref) => {
        const schema = yup.object({
            nt_value: yup.number(),
            domestic_expenses: yup.number().required().min(0),
            insurance: yup.number().required().min(0),
            freight: yup.number().required().min(0)
        });
        const { handleSubmit, control, reset } = useForm({
            defaultValues: {
                nt_value: item.nt_value,
                domestic_expenses: prevInputItem ? prevInputItem.domestic_expenses : 0,
                insurance: prevInputItem ? prevInputItem.insurance : 0,
                freight: prevInputItem ? prevInputItem.freight : 0
            },
            resolver: yupResolver(schema)
        });

        const SubmitHandler: SubmitHandler<FormCifInputInterface> = (data: FormCifInputInterface) => {
            fn(data);
        };

        useEffect(() => {
            // reset();;
        }, [insertType]);

        useImperativeHandle(ref, () => ({ submit: handleSubmit(SubmitHandler) }));

        return (
            <form onChange={handleSubmit(SubmitHandler)} style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                <Controller
                    control={control}
                    name="nt_value"
                    render={({ field, formState: { errors } }) => (
                        <TextField
                            variant="standard"
                            type="number"
                            {...field}
                            disabled
                            sx={{
                                width: '80px'
                            }}
                        />
                    )}
                />
                <div style={{ fontWeight: 'bold' }}> - </div>
                <Controller
                    control={control}
                    name="freight"
                    render={({ field, formState: { errors } }) => (
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <>
                                <TextField
                                    variant="standard"
                                    type="number"
                                    {...field}
                                    disabled
                                    sx={{
                                        width: '80px'
                                    }}
                                />
                                {errors.freight && <span>This input is required</span>}
                            </>
                        </Box>
                    )}
                />
                <div style={{ fontWeight: 'bold' }}> - </div>
                <Controller
                    control={control}
                    name="insurance"
                    render={({ field, formState: { errors } }) => (
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <>
                                <TextField
                                    variant="standard"
                                    type="number"
                                    {...field}
                                    disabled
                                    sx={{
                                        width: '80px'
                                    }}
                                />
                                {errors.insurance && <span>This input is required</span>}
                            </>
                        </Box>
                    )}
                />
                <div style={{ fontWeight: 'bold' }}> - </div>
                <Controller
                    control={control}
                    name="domestic_expenses"
                    render={({ field, formState: { errors } }) => (
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <>
                                <TextField
                                    variant="standard"
                                    type="number"
                                    {...field}
                                    disabled
                                    sx={{
                                        width: '80px'
                                    }}
                                />
                                {errors.domestic_expenses && <span>This input is required</span>}
                            </>
                        </Box>
                    )}
                />
            </form>
        );
    }
);

export interface FormCIFInterface {
    psr_input: string;
}

export const FormCIF = ({ item, psrItem, index, bangKeItem }: FormProps) => {
    const [bangKeData, setBangKeData] = useState<null | BangKeDataInterface>(null);
    const { formType, MapBangKeData } = useContext(InvoiceContext);
    const psrLabel = useMemo(() => {
        if (bangKeItem) return bangKeItem.criteria;
        else if (psrItem) return psrItem[formType] ?? '';
        else return '';
    }, [psrItem, bangKeItem]);
    const [formData, setFormData] = useState<FormCIFInterface>({
        psr_input: psrLabel ?? ''
    });

    const [sedo, setSedo] = useState(false);

    const [insertType, setInsertType] = useState(DropDownEXWInsertTypeEnum.formular);
    const [dropDownApplicable, setDropDownApplicable] = useState<DropDownApplicableEnum>(DropDownApplicableEnum.CTC);
    const [cifInput, setCifInput] = useState<null | FormCifInputInterface>(null);
    const cifInputRef = useRef(null);
    function CollectCifInput(input: FormCifInputInterface) {
        setCifInput(input);
    }

    const processPriceRef = useRef(null);
    const [processPrice, setProcessPrice] = useState<null | ProcessingPriceInterface>(null);
    function CollectProcessPrice(input: ProcessingPriceInterface) {
        setProcessPrice({ ...input });
    }

    const inputRef = useRef(null);
    const [userInput, setUserInput] = useState<null | UserInputInterface>(null);
    function CollectInput(input: UserInputInterface) {
        setUserInput(input);
    }
    useEffect(() => {
        const bangKeItem: BangKeDataInterface = {
            id: item.id,
            exchange_rate: item.exchange_rate,
            use_sedo: sedo,
            account_number: item.account_number,
            registered_date: item.registered_date,
            hs: item.hs,
            total: item.total,
            nt_value: item.nt_value,
            name: item.item_name,
            bill_date: item.bill_date,
            form_type: dropDownApplicable,
            option: ConvertOption(insertType, formType),
            criteria: formData.psr_input,
            code: item.code,

            input: {
                result: 0
            }
        };
        if (insertType === DropDownEXWInsertTypeEnum.formular) {
            bangKeItem.input = {
                domestic_expenses: cifInput?.domestic_expenses ?? 0,
                freight: cifInput?.freight ?? 0,
                insurance: cifInput?.insurance ?? 0
            };
        }

        if (insertType === DropDownEXWInsertTypeEnum.user_input) {
            bangKeItem.input = { result: userInput?.user_input ?? 0 };
        }
        if (insertType === DropDownEXWInsertTypeEnum.progess_price) {
            bangKeItem.input = { result: processPrice?.price_input ?? 0 };
        }
        const timeout = setTimeout(() => {
            setBangKeData((prev: BangKeDataInterface | null) => {
                if (prev === null) return bangKeItem;
                else {
                    if (_.isEqual(prev, bangKeItem)) return prev;
                    else {
                        return bangKeItem;
                    }
                }
            });
        }, 500);

        return () => clearTimeout(timeout);
    }, [sedo, insertType, dropDownApplicable, cifInput, processPrice, userInput, formData]);

    useEffect(() => {
        if (bangKeData) {
            MapBangKeData(bangKeData);
        }
    }, [bangKeData]);

    function SedoHandler() {
        setSedo((prev) => !prev);
    }

    useEffect(() => {
        if (sedo) {
            setDropDownApplicable(DropDownApplicableEnum.RVC);
            setInsertType(DropDownEXWInsertTypeEnum.progess_price);
        } else {
            setInsertType(DropDownEXWInsertTypeEnum.formular);
        }
    }, [sedo]);

    useEffect(() => {
        if (bangKeItem) {
            if (bangKeItem.use_sedo) {
                setDropDownApplicable(DropDownApplicableEnum.RVC);
                setSedo(true);
            } else {
                setDropDownApplicable(RevertBangKeDataFormType(bangKeItem.form_type));
                setSedo(false);
            }
            if (RevertBangKeDataEXWOption(bangKeItem.option) === DropDownEXWInsertTypeEnum.progess_price) {
                setInsertType(DropDownEXWInsertTypeEnum.progess_price);
                setProcessPrice({ price_input: bangKeItem.input.result ?? 0 });
            } else if (RevertBangKeDataEXWOption(bangKeItem.option) === DropDownEXWInsertTypeEnum.user_input) {
                setInsertType(DropDownEXWInsertTypeEnum.user_input);
                setUserInput({ user_input: bangKeItem.input.result ?? 0 });
            } else {
                setInsertType(DropDownEXWInsertTypeEnum.formular);
                setCifInput({
                    nt_value: item.nt_value ?? 0,
                    domestic_expenses: bangKeItem.input.domestic_expenses ?? 0,
                    freight: bangKeItem.input.freight ?? 0,
                    insurance: bangKeItem.input.insurance ?? 0
                });
            }
            setFormData({ psr_input: bangKeItem.criteria });
        }
    }, [bangKeItem]);

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '10px' }}>
            <div className={styled['no-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'No' : ' '}</h3>
                <h3>{index}</h3>
            </div>
            <div className={styled['code-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Code' : ' '}</h3>
                <h3>{item.code}</h3>
            </div>
            <div className={styled['name-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Name' : ' '}</h3>
                <h3>{ConvertBangKeName(item.item_name)}</h3>
            </div>
            <div className={styled['hs-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Hs' : ' '}</h3>
                <h3>{item.hs}</h3>
            </div>

            <div className={styled['criteria-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Criteria' : ' '}</h3>
                <TextField
                    value={formData.psr_input}
                    variant="standard"
                    sx={{ width: 200 }}
                    onChange={(e) => {
                        setFormData({ psr_input: e.target.value });
                    }}
                />
            </div>

            <DropDownInsertType sedo={sedo} insertType={insertType} setInsertType={setInsertType} type={DropDownType.exw} index={index} />

            <div className={styled['hs-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Value' : ' '}</h3>
                {insertType === DropDownEXWInsertTypeEnum.user_input ? (
                    <Box>
                        <UserInput ref={inputRef} insertType={insertType} fn={CollectInput} prevUserInput={userInput} />
                    </Box>
                ) : (
                    <>
                        {insertType === DropDownEXWInsertTypeEnum.formular ? (
                            <Box>
                                <FormCifInput
                                    ref={cifInputRef}
                                    insertType={insertType}
                                    item={item}
                                    fn={CollectCifInput}
                                    prevInputItem={FormCifInput}
                                />
                            </Box>
                        ) : (
                            <Box>
                                <ProcessPrice
                                    ref={processPriceRef}
                                    insertType={insertType}
                                    fn={CollectProcessPrice}
                                    prevProgessPrice={processPrice}
                                />
                            </Box>
                        )}
                    </>
                )}
            </div>

            <DropDownFormularApplicableCriteria
                sedo={sedo}
                dropDownApplicable={dropDownApplicable}
                setDropDownApplicable={setDropDownApplicable}
                index={index}
            />

            <div className={styled['hs-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Sedo' : ' '}</h3>
                <Switch checked={sedo} onChange={SedoHandler} />
            </div>
        </Box>
    );
};

interface FormCptInputInterface {
    nt_value: number;
    domestic_expenses: number;
    oversea_expenses: number;
    freight: number;
}

const FormCptInput = forwardRef<FormularFormRef, any>(
    ({ item, insertType, fn, prevInputItem }: FormInputProps<FormCptInputInterface>, ref) => {
        const schema = yup.object({
            nt_value: yup.number(),
            freight: yup.number().required().min(0),
            domestic_expenses: yup.number().required().min(0),
            oversea_expenses: yup.number().required().min(0)
        });

        const { handleSubmit, control, reset } = useForm({
            defaultValues: {
                nt_value: item.nt_value,
                domestic_expenses: prevInputItem ? prevInputItem.domestic_expenses : 0,
                oversea_expenses: prevInputItem ? prevInputItem.oversea_expenses : 0,
                freight: prevInputItem ? prevInputItem.freight : 0
            },
            resolver: yupResolver(schema)
        });

        const SubmitHandler: SubmitHandler<FormCptInputInterface> = (data: FormCptInputInterface) => {
            if (data) {
                fn(data);
            }
        };

        useEffect(() => {
            // reset();;
        }, [insertType]);

        useImperativeHandle(ref, () => ({ submit: handleSubmit(SubmitHandler) }));

        return (
            <form onChange={handleSubmit(SubmitHandler)} style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                <Controller
                    control={control}
                    name="nt_value"
                    render={({ field, formState: { errors } }) => <TextField variant="standard" type="number" {...field} disabled />}
                />
                <div style={{ fontWeight: 'bold' }}> - </div>
                <Controller
                    control={control}
                    name="freight"
                    render={({ field, formState: { errors } }) => (
                        <>
                            <TextField
                                variant="standard"
                                type="number"
                                {...field}
                                disabled
                                sx={{
                                    width: '80px'
                                }}
                            />
                            {errors.freight && <span>This input is required</span>}
                        </>
                    )}
                />
                <div style={{ fontWeight: 'bold' }}> - </div>
                <Controller
                    control={control}
                    name="oversea_expenses"
                    render={({ field, formState: { errors } }) => (
                        <>
                            <TextField
                                variant="standard"
                                type="number"
                                {...field}
                                disabled
                                sx={{
                                    width: '80px'
                                }}
                            />
                            {errors.oversea_expenses && <span>This input is required</span>}
                        </>
                    )}
                />
                <div style={{ fontWeight: 'bold' }}> - </div>
                <Controller
                    control={control}
                    name="domestic_expenses"
                    render={({ field, formState: { errors } }) => (
                        <>
                            <TextField
                                variant="standard"
                                type="number"
                                {...field}
                                disabled
                                sx={{
                                    width: '80px'
                                }}
                            />
                            {errors.domestic_expenses && <span>This input is required</span>}
                        </>
                    )}
                />
            </form>
        );
    }
);

export interface FormCPTInterface {
    psr_input: string;
}

export const FormCPT = ({ item, psrItem, index, bangKeItem }: FormProps) => {
    const { formType, MapBangKeData } = useContext(InvoiceContext);
    const psrLabel = useMemo(() => {
        if (bangKeItem) return bangKeItem.criteria;
        else if (psrItem) return psrItem[formType] ?? '';
        else return '';
    }, [psrItem, bangKeItem]);
    const [insertType, setInsertType] = useState(DropDownEXWInsertTypeEnum.formular);
    const [dropDownApplicable, setDropDownApplicable] = useState<DropDownApplicableEnum>(DropDownApplicableEnum.CTC);
    const [sedo, setSedo] = useState(false);

    const [cptInput, setCptInput] = useState<null | FormCptInputInterface>(null);
    const cptInputRef = useRef(null);
    function CollectCptInput(input: FormCptInputInterface) {
        setCptInput(input);
    }

    const processPriceRef = useRef(null);
    const [processPrice, setProcessPrice] = useState<null | ProcessingPriceInterface>(null);
    function CollectProcessPrice(input: ProcessingPriceInterface) {
        setProcessPrice({ ...input });
    }

    const inputRef = useRef(null);
    const [userInput, setUserInput] = useState<null | UserInputInterface>(null);
    function CollectInput(input: UserInputInterface) {
        setUserInput(input);
    }
    const [bangKeData, setBangKeData] = useState<null | BangKeDataInterface>(null);
    const [formData, setFormData] = useState<FormCPTInterface>({
        psr_input: psrLabel ?? ''
    });

    useEffect(() => {
        const bangKeItem: BangKeDataInterface = {
            id: item.id,
            exchange_rate: item.exchange_rate,
            use_sedo: sedo,
            account_number: item.account_number,
            registered_date: item.registered_date,
            hs: item.hs,
            total: item.total,
            nt_value: item.nt_value,
            name: item.item_name,
            bill_date: item.bill_date,
            form_type: dropDownApplicable,
            option: ConvertOption(insertType, formType),
            criteria: formData.psr_input,
            code: item.code,

            input: {
                result: 0
            }
        };
        if (insertType === DropDownEXWInsertTypeEnum.formular) {
            bangKeItem.input = {
                domestic_expenses: cptInput?.domestic_expenses ?? 0,
                freight: cptInput?.freight ?? 0,
                oversea_expenses: cptInput?.oversea_expenses ?? 0
            };
        }

        if (insertType === DropDownEXWInsertTypeEnum.user_input) {
            bangKeItem.input = { result: userInput?.user_input ?? 0 };
        }
        if (insertType === DropDownEXWInsertTypeEnum.progess_price) {
            bangKeItem.input = { result: processPrice?.price_input ?? 0 };
        }
        const timeout = setTimeout(() => {
            setBangKeData((prev: BangKeDataInterface | null) => {
                if (prev === null) return bangKeItem;
                else {
                    if (_.isEqual(prev, bangKeItem)) return prev;
                    else {
                        return bangKeItem;
                    }
                }
            });
        }, 500);

        return () => clearTimeout(timeout);
    }, [sedo, insertType, dropDownApplicable, cptInput, processPrice, userInput, formData]);

    useEffect(() => {
        if (bangKeData) {
            MapBangKeData(bangKeData);
        }
    }, [bangKeData]);

    function SedoHandler() {
        setSedo((prev) => !prev);
    }

    useEffect(() => {
        if (sedo) {
            setDropDownApplicable(DropDownApplicableEnum.RVC);
            setInsertType(DropDownEXWInsertTypeEnum.progess_price);
        } else {
            setInsertType(DropDownEXWInsertTypeEnum.formular);
        }
    }, [sedo]);

    useEffect(() => {
        if (bangKeItem) {
            if (bangKeItem.use_sedo) {
                setDropDownApplicable(DropDownApplicableEnum.RVC);
                setSedo(true);
            } else {
                setDropDownApplicable(RevertBangKeDataFormType(bangKeItem.form_type));
                setSedo(false);
            }
            if (RevertBangKeDataEXWOption(bangKeItem.option) === DropDownEXWInsertTypeEnum.progess_price) {
                setInsertType(DropDownEXWInsertTypeEnum.progess_price);
                setProcessPrice({ price_input: bangKeItem.input.result ?? 0 });
            } else if (RevertBangKeDataEXWOption(bangKeItem.option) === DropDownEXWInsertTypeEnum.user_input) {
                setInsertType(DropDownEXWInsertTypeEnum.user_input);
                setUserInput({ user_input: bangKeItem.input.result ?? 0 });
            } else {
                setInsertType(DropDownEXWInsertTypeEnum.formular);
                setCptInput({
                    nt_value: item.nt_value ?? 0,
                    domestic_expenses: bangKeItem.input.domestic_expenses ?? 0,
                    freight: bangKeItem.input.freight ?? 0,
                    oversea_expenses: bangKeItem.input.oversea_expenses ?? 0
                });
            }
            setFormData({ psr_input: bangKeItem.criteria });
        }
    }, [bangKeItem]);

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '10px' }}>
            <div className={styled['no-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'No' : ' '}</h3>
                <h3>{index}</h3>
            </div>
            <div className={styled['code-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Code' : ' '}</h3>
                <h3>{item.code}</h3>
            </div>
            <div className={styled['name-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Name' : ' '}</h3>
                <h3>{ConvertBangKeName(item.item_name)}</h3>
            </div>
            <div className={styled['hs-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Hs' : ' '}</h3>
                <h3>{item.hs}</h3>
            </div>

            <div className={styled['criteria-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Criteria' : ' '}</h3>
                <TextField
                    value={formData.psr_input}
                    variant="standard"
                    sx={{ width: 200 }}
                    onChange={(e) => {
                        setFormData({ psr_input: e.target.value });
                    }}
                />
            </div>

            <DropDownInsertType sedo={sedo} insertType={insertType} setInsertType={setInsertType} type={DropDownType.exw} index={index} />

            <div className={styled['hs-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Value' : ' '}</h3>
                {insertType === DropDownEXWInsertTypeEnum.user_input ? (
                    <Box>
                        <UserInput ref={inputRef} insertType={insertType} fn={CollectInput} prevUserInput={userInput} />
                    </Box>
                ) : (
                    <>
                        {insertType === DropDownEXWInsertTypeEnum.formular ? (
                            <Box>
                                <FormCptInput
                                    ref={cptInputRef}
                                    insertType={insertType}
                                    item={item}
                                    fn={CollectCptInput}
                                    prevInputItem={cptInput}
                                />
                            </Box>
                        ) : (
                            <Box>
                                <ProcessPrice
                                    ref={processPriceRef}
                                    insertType={insertType}
                                    fn={CollectProcessPrice}
                                    prevProgessPrice={processPrice}
                                />
                            </Box>
                        )}
                    </>
                )}
            </div>

            <DropDownFormularApplicableCriteria
                sedo={sedo}
                dropDownApplicable={dropDownApplicable}
                setDropDownApplicable={setDropDownApplicable}
                index={index}
            />

            <div className={styled['hs-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Sedo' : ' '}</h3>
                <Switch checked={sedo} onChange={SedoHandler} />
            </div>
        </Box>
    );
};

export const FormDDU = ({ item, psrItem, index, bangKeItem }: FormProps) => {
    const { formType, MapBangKeData } = useContext(InvoiceContext);
    const psrLabel = useMemo(() => {
        if (bangKeItem) return bangKeItem.criteria;
        else if (psrItem) return psrItem[formType] ?? '';
        else return '';
    }, [psrItem, bangKeItem]);
    const [insertType, setInsertType] = useState(DropDownEXWInsertTypeEnum.formular);
    const [dropDownApplicable, setDropDownApplicable] = useState<DropDownApplicableEnum>(DropDownApplicableEnum.CTC);
    const [sedo, setSedo] = useState(false);

    const [cptInput, setCptInput] = useState<null | FormCptInputInterface>(null);
    const cptInputRef = useRef(null);
    function CollectCptInput(input: FormCptInputInterface) {
        setCptInput(input);
    }

    const processPriceRef = useRef(null);
    const [processPrice, setProcessPrice] = useState<null | ProcessingPriceInterface>(null);
    function CollectProcessPrice(input: ProcessingPriceInterface) {
        setProcessPrice({ ...input });
    }

    const inputRef = useRef(null);
    const [userInput, setUserInput] = useState<null | UserInputInterface>(null);
    function CollectInput(input: UserInputInterface) {
        setUserInput(input);
    }
    const [bangKeData, setBangKeData] = useState<null | BangKeDataInterface>(null);
    const [formData, setFormData] = useState<FormCPTInterface>({
        psr_input: psrLabel ?? ''
    });

    useEffect(() => {
        const bangKeItem: BangKeDataInterface = {
            id: item.id,
            exchange_rate: item.exchange_rate,
            use_sedo: sedo,
            account_number: item.account_number,
            registered_date: item.registered_date,
            hs: item.hs,
            total: item.total,
            nt_value: item.nt_value,
            name: item.item_name,
            bill_date: item.bill_date,
            form_type: dropDownApplicable,
            option: ConvertOption(insertType, formType),
            criteria: formData.psr_input,
            code: item.code,

            input: {
                result: 0
            }
        };
        if (insertType === DropDownEXWInsertTypeEnum.formular) {
            bangKeItem.input = {
                domestic_expenses: cptInput?.domestic_expenses ?? 0,
                freight: cptInput?.freight ?? 0,
                oversea_expenses: cptInput?.oversea_expenses ?? 0
            };
        }

        if (insertType === DropDownEXWInsertTypeEnum.user_input) {
            bangKeItem.input = { result: userInput?.user_input ?? 0 };
        }
        if (insertType === DropDownEXWInsertTypeEnum.progess_price) {
            bangKeItem.input = { result: processPrice?.price_input ?? 0 };
        }
        const timeout = setTimeout(() => {
            setBangKeData((prev: BangKeDataInterface | null) => {
                if (prev === null) return bangKeItem;
                else {
                    if (_.isEqual(prev, bangKeItem)) return prev;
                    else {
                        return bangKeItem;
                    }
                }
            });
        }, 500);

        return () => clearTimeout(timeout);
    }, [sedo, insertType, dropDownApplicable, cptInput, processPrice, userInput, formData]);

    useEffect(() => {
        if (bangKeData) {
            MapBangKeData(bangKeData);
        }
    }, [bangKeData]);

    function SedoHandler() {
        setSedo((prev) => !prev);
    }

    useEffect(() => {
        if (sedo) {
            setDropDownApplicable(DropDownApplicableEnum.RVC);
            setInsertType(DropDownEXWInsertTypeEnum.progess_price);
        } else {
            setInsertType(DropDownEXWInsertTypeEnum.formular);
        }
    }, [sedo]);

    useEffect(() => {
        if (bangKeItem) {
            if (bangKeItem.use_sedo) {
                setDropDownApplicable(DropDownApplicableEnum.RVC);
                setSedo(true);
            } else {
                setDropDownApplicable(RevertBangKeDataFormType(bangKeItem.form_type));
                setSedo(false);
            }
            if (RevertBangKeDataEXWOption(bangKeItem.option) === DropDownEXWInsertTypeEnum.progess_price) {
                setInsertType(DropDownEXWInsertTypeEnum.progess_price);
                setProcessPrice({ price_input: bangKeItem.input.result ?? 0 });
            } else if (RevertBangKeDataEXWOption(bangKeItem.option) === DropDownEXWInsertTypeEnum.user_input) {
                setInsertType(DropDownEXWInsertTypeEnum.user_input);
                setUserInput({ user_input: bangKeItem.input.result ?? 0 });
            } else {
                setInsertType(DropDownEXWInsertTypeEnum.formular);
                setCptInput({
                    nt_value: item.nt_value ?? 0,
                    domestic_expenses: bangKeItem.input.domestic_expenses ?? 0,
                    freight: bangKeItem.input.freight ?? 0,
                    oversea_expenses: bangKeItem.input.oversea_expenses ?? 0
                });
            }
            setFormData({ psr_input: bangKeItem.criteria });
        }
    }, [bangKeItem]);

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '10px' }}>
            <div className={styled['no-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'No' : ' '}</h3>
                <h3>{index}</h3>
            </div>
            <div className={styled['code-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Code' : ' '}</h3>
                <h3>{item.code}</h3>
            </div>
            <div className={styled['name-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Name' : ' '}</h3>
                <h3>{ConvertBangKeName(item.item_name)}</h3>
            </div>
            <div className={styled['hs-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Hs' : ' '}</h3>
                <h3>{item.hs}</h3>
            </div>

            <div className={styled['criteria-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Criteria' : ' '}</h3>
                <TextField
                    value={formData.psr_input}
                    variant="standard"
                    sx={{ width: 200 }}
                    onChange={(e) => {
                        setFormData({ psr_input: e.target.value });
                    }}
                />
            </div>

            <DropDownInsertType sedo={sedo} insertType={insertType} setInsertType={setInsertType} type={DropDownType.exw} index={index} />

            <div className={styled['hs-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Value' : ' '}</h3>
                {insertType === DropDownEXWInsertTypeEnum.user_input ? (
                    <Box>
                        <UserInput ref={inputRef} insertType={insertType} fn={CollectInput} prevUserInput={userInput} />
                    </Box>
                ) : (
                    <>
                        {insertType === DropDownEXWInsertTypeEnum.formular ? (
                            <Box>
                                <FormCptInput
                                    ref={cptInputRef}
                                    insertType={insertType}
                                    item={item}
                                    fn={CollectCptInput}
                                    prevInputItem={cptInput}
                                />
                            </Box>
                        ) : (
                            <Box>
                                <ProcessPrice
                                    ref={processPriceRef}
                                    insertType={insertType}
                                    fn={CollectProcessPrice}
                                    prevProgessPrice={processPrice}
                                />
                            </Box>
                        )}
                    </>
                )}
            </div>

            <DropDownFormularApplicableCriteria
                sedo={sedo}
                dropDownApplicable={dropDownApplicable}
                setDropDownApplicable={setDropDownApplicable}
                index={index}
            />

            <div className={styled['hs-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Sedo' : ' '}</h3>
                <Switch checked={sedo} onChange={SedoHandler} />
            </div>
        </Box>
    );
};

export const FormDDP = ({ item, psrItem, index, bangKeItem }: FormProps) => {
    const { formType, MapBangKeData } = useContext(InvoiceContext);
    const psrLabel = useMemo(() => {
        if (bangKeItem) return bangKeItem.criteria;
        else if (psrItem) return psrItem[formType] ?? '';
        else return '';
    }, [psrItem, bangKeItem]);
    const [insertType, setInsertType] = useState(DropDownEXWInsertTypeEnum.formular);
    const [dropDownApplicable, setDropDownApplicable] = useState<DropDownApplicableEnum>(DropDownApplicableEnum.CTC);
    const [sedo, setSedo] = useState(false);

    const [cptInput, setCptInput] = useState<null | FormCptInputInterface>(null);
    const cptInputRef = useRef(null);
    function CollectCptInput(input: FormCptInputInterface) {
        setCptInput(input);
    }

    const processPriceRef = useRef(null);
    const [processPrice, setProcessPrice] = useState<null | ProcessingPriceInterface>(null);
    function CollectProcessPrice(input: ProcessingPriceInterface) {
        setProcessPrice({ ...input });
    }

    const inputRef = useRef(null);
    const [userInput, setUserInput] = useState<null | UserInputInterface>(null);
    function CollectInput(input: UserInputInterface) {
        setUserInput(input);
    }
    const [bangKeData, setBangKeData] = useState<null | BangKeDataInterface>(null);
    const [formData, setFormData] = useState<FormCPTInterface>({
        psr_input: psrLabel ?? ''
    });

    useEffect(() => {
        const bangKeItem: BangKeDataInterface = {
            id: item.id,
            exchange_rate: item.exchange_rate,
            use_sedo: sedo,
            account_number: item.account_number,
            registered_date: item.registered_date,
            hs: item.hs,
            total: item.total,
            nt_value: item.nt_value,
            name: item.item_name,
            bill_date: item.bill_date,
            form_type: dropDownApplicable,
            option: ConvertOption(insertType, formType),
            criteria: formData.psr_input,
            code: item.code,

            input: {
                result: 0
            }
        };
        if (insertType === DropDownEXWInsertTypeEnum.formular) {
            bangKeItem.input = {
                domestic_expenses: cptInput?.domestic_expenses ?? 0,
                freight: cptInput?.freight ?? 0,
                oversea_expenses: cptInput?.oversea_expenses ?? 0
            };
        }

        if (insertType === DropDownEXWInsertTypeEnum.user_input) {
            bangKeItem.input = { result: userInput?.user_input ?? 0 };
        }
        if (insertType === DropDownEXWInsertTypeEnum.progess_price) {
            bangKeItem.input = { result: processPrice?.price_input ?? 0 };
        }
        const timeout = setTimeout(() => {
            setBangKeData((prev: BangKeDataInterface | null) => {
                if (prev === null) return bangKeItem;
                else {
                    if (_.isEqual(prev, bangKeItem)) return prev;
                    else {
                        return bangKeItem;
                    }
                }
            });
        }, 500);

        return () => clearTimeout(timeout);
    }, [sedo, insertType, dropDownApplicable, cptInput, processPrice, userInput, formData]);

    useEffect(() => {
        if (bangKeData) {
            MapBangKeData(bangKeData);
        }
    }, [bangKeData]);

    function SedoHandler() {
        setSedo((prev) => !prev);
    }

    useEffect(() => {
        if (sedo) {
            setDropDownApplicable(DropDownApplicableEnum.RVC);
            setInsertType(DropDownEXWInsertTypeEnum.progess_price);
        } else {
            setInsertType(DropDownEXWInsertTypeEnum.formular);
        }
    }, [sedo]);

    useEffect(() => {
        if (bangKeItem) {
            if (bangKeItem.use_sedo) {
                setDropDownApplicable(DropDownApplicableEnum.RVC);
                setSedo(true);
            } else {
                setDropDownApplicable(RevertBangKeDataFormType(bangKeItem.form_type));
                setSedo(false);
            }
            if (RevertBangKeDataEXWOption(bangKeItem.option) === DropDownEXWInsertTypeEnum.progess_price) {
                setInsertType(DropDownEXWInsertTypeEnum.progess_price);
                setProcessPrice({ price_input: bangKeItem.input.result ?? 0 });
            } else if (RevertBangKeDataEXWOption(bangKeItem.option) === DropDownEXWInsertTypeEnum.user_input) {
                setInsertType(DropDownEXWInsertTypeEnum.user_input);
                setUserInput({ user_input: bangKeItem.input.result ?? 0 });
            } else {
                setInsertType(DropDownEXWInsertTypeEnum.formular);
                setCptInput({
                    nt_value: item.nt_value ?? 0,
                    domestic_expenses: bangKeItem.input.domestic_expenses ?? 0,
                    freight: bangKeItem.input.freight ?? 0,
                    oversea_expenses: bangKeItem.input.oversea_expenses ?? 0
                });
            }
            setFormData({ psr_input: bangKeItem.criteria });
        }
    }, [bangKeItem]);

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '10px' }}>
            <div className={styled['no-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'No' : ' '}</h3>
                <h3>{index}</h3>
            </div>
            <div className={styled['code-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Code' : ' '}</h3>
                <h3>{item.code}</h3>
            </div>
            <div className={styled['name-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Name' : ' '}</h3>
                <h3>{ConvertBangKeName(item.item_name)}</h3>
            </div>
            <div className={styled['hs-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Hs' : ' '}</h3>
                <h3>{item.hs}</h3>
            </div>

            <div className={styled['criteria-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Criteria' : ' '}</h3>
                <TextField
                    value={formData.psr_input}
                    variant="standard"
                    sx={{ width: 200 }}
                    onChange={(e) => {
                        setFormData({ psr_input: e.target.value });
                    }}
                />
            </div>

            <DropDownInsertType sedo={sedo} insertType={insertType} setInsertType={setInsertType} type={DropDownType.exw} index={index} />

            <div className={styled['hs-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Value' : ' '}</h3>
                {insertType === DropDownEXWInsertTypeEnum.user_input ? (
                    <Box>
                        <UserInput ref={inputRef} insertType={insertType} fn={CollectInput} prevUserInput={userInput} />
                    </Box>
                ) : (
                    <>
                        {insertType === DropDownEXWInsertTypeEnum.formular ? (
                            <Box>
                                <FormCptInput
                                    ref={cptInputRef}
                                    insertType={insertType}
                                    item={item}
                                    fn={CollectCptInput}
                                    prevInputItem={cptInput}
                                />
                            </Box>
                        ) : (
                            <Box>
                                <ProcessPrice
                                    ref={processPriceRef}
                                    insertType={insertType}
                                    fn={CollectProcessPrice}
                                    prevProgessPrice={processPrice}
                                />
                            </Box>
                        )}
                    </>
                )}
            </div>

            <DropDownFormularApplicableCriteria
                sedo={sedo}
                dropDownApplicable={dropDownApplicable}
                setDropDownApplicable={setDropDownApplicable}
                index={index}
            />

            <div className={styled['hs-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Sedo' : ' '}</h3>
                <Switch checked={sedo} onChange={SedoHandler} />
            </div>
        </Box>
    );
};

interface FormCipInputInterface {
    nt_value: number;
    domestic_expenses: number;
    insurance: number;
    oversea_expenses: number;
    freight: number;
}

const FormCipInput = forwardRef<FormularFormRef, any>(
    ({ item, insertType, fn, prevInputItem }: FormInputProps<FormCipInputInterface>, ref) => {
        const schema = yup.object({
            nt_value: yup.number(),
            domestic_expenses: yup.number().required().min(0),
            insurance: yup.number().required().min(0),
            oversea_expenses: yup.number().required().min(0),
            freight: yup.number().required().min(0)
        });

        const { handleSubmit, control, reset } = useForm({
            defaultValues: {
                nt_value: item.nt_value,
                domestic_expenses: prevInputItem ? prevInputItem.domestic_expenses : 0,
                insurance: prevInputItem ? prevInputItem.insurance : 0,
                oversea_expenses: prevInputItem ? prevInputItem.oversea_expenses : 0,
                freight: prevInputItem ? prevInputItem.freight : 0
            },
            resolver: yupResolver(schema)
        });

        const SubmitHandler: SubmitHandler<FormCipInputInterface> = (data: FormCipInputInterface) => {
            fn(data);
        };

        useEffect(() => {
            // reset();;
        }, [insertType]);

        useImperativeHandle(ref, () => ({ submit: handleSubmit(SubmitHandler) }));

        return (
            <form onChange={handleSubmit(SubmitHandler)} style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                <Controller
                    control={control}
                    name="nt_value"
                    render={({ field, formState: { errors } }) => <TextField variant="standard" type="number" {...field} disabled />}
                />
                <div style={{ fontWeight: 'bold' }}> - </div>
                <Controller
                    control={control}
                    name="freight"
                    render={({ field, formState: { errors } }) => (
                        <>
                            <TextField
                                variant="standard"
                                type="number"
                                {...field}
                                disabled
                                sx={{
                                    width: '80px'
                                }}
                            />
                            {errors.freight && <span>This input is required</span>}
                        </>
                    )}
                />
                <div style={{ fontWeight: 'bold' }}> - </div>
                <Controller
                    control={control}
                    name="oversea_expenses"
                    render={({ field, formState: { errors } }) => (
                        <>
                            <TextField
                                variant="standard"
                                type="number"
                                {...field}
                                disabled
                                sx={{
                                    width: '80px'
                                }}
                            />
                            {errors.oversea_expenses && <span>This input is required</span>}
                        </>
                    )}
                />
                <div style={{ fontWeight: 'bold' }}> - </div>
                <Controller
                    control={control}
                    name="insurance"
                    render={({ field, formState: { errors } }) => (
                        <>
                            <TextField
                                variant="standard"
                                type="number"
                                {...field}
                                disabled
                                sx={{
                                    width: '80px'
                                }}
                            />
                            {errors.insurance && <span>This input is required</span>}
                        </>
                    )}
                />
                <div style={{ fontWeight: 'bold' }}> - </div>
                <Controller
                    control={control}
                    name="domestic_expenses"
                    render={({ field, formState: { errors } }) => (
                        <>
                            <TextField
                                variant="standard"
                                type="number"
                                {...field}
                                disabled
                                sx={{
                                    width: '80px'
                                }}
                            />
                            {errors.domestic_expenses && <span>This input is required</span>}
                        </>
                    )}
                />
            </form>
        );
    }
);

export interface FormCIPInterface {
    psr_input: string;
}

export const FormCIP = ({ item, psrItem, index, bangKeItem }: FormProps) => {
    const { formType, MapBangKeData } = useContext(InvoiceContext);
    const psrLabel = useMemo(() => {
        if (bangKeItem) return bangKeItem.criteria;
        else if (psrItem) return psrItem[formType] ?? '';
        else return '';
    }, [psrItem, bangKeItem]);
    const [insertType, setInsertType] = useState(DropDownEXWInsertTypeEnum.formular);
    const [sedo, setSedo] = useState(false);

    const [dropDownApplicable, setDropDownApplicable] = useState<DropDownApplicableEnum>(DropDownApplicableEnum.CTC);
    const cipInputRef = useRef(null);
    const [cipInput, setCipInput] = useState<null | FormCipInputInterface>(null);
    function CollectCipInput(input: FormCipInputInterface) {
        setCipInput(input);
    }

    const processPriceRef = useRef(null);
    const [processPrice, setProcessPrice] = useState<null | ProcessingPriceInterface>(null);
    function CollectProcessPrice(input: ProcessingPriceInterface) {
        setProcessPrice({ ...input });
    }

    const inputRef = useRef(null);
    const [userInput, setUserInput] = useState<null | UserInputInterface>(null);
    function CollectInput(input: UserInputInterface) {
        setUserInput(input);
    }
    const [bangKeData, setBangKeData] = useState<null | BangKeDataInterface>(null);
    const schema = yup.object({ psr_input: yup.string().required() });
    const [formData, setFormData] = useState<FormCIPInterface>({
        psr_input: psrLabel ?? ''
    });

    useEffect(() => {
        const bangKeItem: BangKeDataInterface = {
            id: item.id,
            exchange_rate: item.exchange_rate,
            use_sedo: sedo,
            account_number: item.account_number,
            registered_date: item.registered_date,
            hs: item.hs,
            total: item.total,
            nt_value: item.nt_value,
            name: item.item_name,
            bill_date: item.bill_date,
            form_type: dropDownApplicable,
            option: ConvertOption(insertType, formType),
            criteria: formData.psr_input,
            code: item.code,

            input: {
                result: 0
            }
        };
        if (insertType === DropDownEXWInsertTypeEnum.formular) {
            bangKeItem.input = {
                domestic_expenses: cipInput?.domestic_expenses ?? 0,
                freight: cipInput?.freight ?? 0,
                insurance: cipInput?.insurance ?? 0,
                oversea_expenses: cipInput?.oversea_expenses ?? 0
            };
        }

        if (insertType === DropDownEXWInsertTypeEnum.user_input) {
            bangKeItem.input = { result: userInput?.user_input ?? 0 };
        }
        if (insertType === DropDownEXWInsertTypeEnum.progess_price) {
            bangKeItem.input = { result: processPrice?.price_input ?? 0 };
        }
        const timeout = setTimeout(() => {
            setBangKeData((prev: BangKeDataInterface | null) => {
                if (prev === null) return bangKeItem;
                else {
                    if (_.isEqual(prev, bangKeItem)) return prev;
                    else {
                        return bangKeItem;
                    }
                }
            });
        }, 500);

        return () => clearTimeout(timeout);
    }, [sedo, insertType, dropDownApplicable, cipInput, processPrice, userInput, formData]);

    useEffect(() => {
        if (bangKeData) {
            MapBangKeData(bangKeData);
        }
    }, [bangKeData]);

    function SedoHandler() {
        setSedo((prev) => !prev);
    }

    useEffect(() => {
        if (sedo) {
            setDropDownApplicable(DropDownApplicableEnum.RVC);
            setInsertType(DropDownEXWInsertTypeEnum.progess_price);
        } else {
            setInsertType(DropDownEXWInsertTypeEnum.formular);
        }
    }, [sedo]);

    useEffect(() => {
        if (bangKeItem) {
            if (bangKeItem.use_sedo) {
                setDropDownApplicable(DropDownApplicableEnum.RVC);
                setSedo(true);
            } else {
                setDropDownApplicable(RevertBangKeDataFormType(bangKeItem.form_type));
                setSedo(false);
            }
            if (RevertBangKeDataEXWOption(bangKeItem.option) === DropDownEXWInsertTypeEnum.progess_price) {
                setInsertType(DropDownEXWInsertTypeEnum.progess_price);
                setProcessPrice({ price_input: bangKeItem.input.result ?? 0 });
            } else if (RevertBangKeDataEXWOption(bangKeItem.option) === DropDownEXWInsertTypeEnum.user_input) {
                setInsertType(DropDownEXWInsertTypeEnum.user_input);
                setUserInput({ user_input: bangKeItem.input.result ?? 0 });
            } else {
                setInsertType(DropDownEXWInsertTypeEnum.formular);
                setCipInput({
                    nt_value: item.nt_value ?? 0,
                    domestic_expenses: bangKeItem.input.domestic_expenses ?? 0,
                    freight: bangKeItem.input.freight ?? 0,
                    insurance: bangKeItem.input.insurance ?? 0,
                    oversea_expenses: bangKeItem.input.oversea_expenses ?? 0
                });
            }
            setFormData({ psr_input: bangKeItem.criteria });
        }
    }, [bangKeItem]);

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '10px' }}>
            <div className={styled['no-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'No' : ' '}</h3>
                <h3>{index}</h3>
            </div>
            <div className={styled['code-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Code' : ' '}</h3>
                <h3>{item.code}</h3>
            </div>
            <div className={styled['name-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Name' : ' '}</h3>
                <h3>{ConvertBangKeName(item.item_name)}</h3>
            </div>
            <div className={styled['hs-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Hs' : ' '}</h3>
                <h3>{item.hs}</h3>
            </div>

            <div className={styled['criteria-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Criteria' : ' '}</h3>
                <TextField
                    value={formData.psr_input}
                    variant="standard"
                    sx={{ width: 200 }}
                    onChange={(e) => {
                        setFormData({ psr_input: e.target.value });
                    }}
                />
            </div>

            <DropDownInsertType sedo={sedo} insertType={insertType} setInsertType={setInsertType} type={DropDownType.exw} index={index} />

            <div className={styled['hs-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Value' : ' '}</h3>
                {insertType === DropDownEXWInsertTypeEnum.formular ? (
                    <Box>
                        <FormCipInput item={item} ref={cipInputRef} insertType={insertType} fn={CollectCipInput} />
                    </Box>
                ) : (
                    <>
                        {insertType === DropDownEXWInsertTypeEnum.user_input ? (
                            <Box>
                                <UserInput ref={inputRef} insertType={insertType} fn={CollectInput} prevUserInput={userInput} />
                            </Box>
                        ) : (
                            <Box>
                                <ProcessPrice
                                    ref={processPriceRef}
                                    insertType={insertType}
                                    fn={CollectProcessPrice}
                                    prevProgessPrice={processPrice}
                                />
                            </Box>
                        )}
                    </>
                )}
            </div>

            <DropDownFormularApplicableCriteria
                sedo={sedo}
                dropDownApplicable={dropDownApplicable}
                setDropDownApplicable={setDropDownApplicable}
                index={index}
            />

            <div className={styled['hs-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Sedo' : ' '}</h3>
                <Switch checked={sedo} onChange={SedoHandler} />
            </div>
        </Box>
    );
};

interface FormDapInputInterface {
    nt_value: number;
    domestic_expenses: number;
    imported_expenses: number;
}

const FormDapInput = forwardRef<FormularFormRef, any>(
    ({ item, insertType, fn, prevInputItem }: FormInputProps<FormDapInputInterface>, ref) => {
        const schema = yup.object({
            nt_value: yup.number(),
            domestic_expenses: yup.number().required().min(0),
            imported_expenses: yup.number().required().min(0)
        });

        const { handleSubmit, control, reset } = useForm({
            defaultValues: {
                nt_value: item.nt_value,
                domestic_expenses: prevInputItem ? prevInputItem.domestic_expenses : 0,
                imported_expenses: prevInputItem ? prevInputItem.imported_expenses : 0
            },
            resolver: yupResolver(schema)
        });

        const SubmitHandler: SubmitHandler<FormDapInputInterface> = (data: FormDapInputInterface) => {
            if (data) {
                fn(data);
            }
        };

        useEffect(() => {
            // reset();;
        }, [insertType]);

        useImperativeHandle(ref, () => ({ submit: handleSubmit(SubmitHandler) }));

        return (
            <form onChange={handleSubmit(SubmitHandler)} style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                <Controller
                    control={control}
                    name="nt_value"
                    render={({ field }) => <TextField variant="standard" type="number" {...field} disabled />}
                />
                <div style={{ fontWeight: 'bold' }}> - </div>
                <Controller
                    control={control}
                    name="imported_expenses"
                    render={({ field, formState: { errors } }) => (
                        <>
                            <TextField
                                variant="standard"
                                type="number"
                                {...field}
                                disabled
                                sx={{
                                    width: '80px'
                                }}
                            />
                            {errors.imported_expenses && <span>This input is required</span>}
                        </>
                    )}
                />
                <div style={{ fontWeight: 'bold' }}> - </div>
                <Controller
                    control={control}
                    name="domestic_expenses"
                    render={({ field, formState: { errors } }) => (
                        <>
                            <TextField
                                variant="standard"
                                type="number"
                                {...field}
                                disabled
                                sx={{
                                    width: '80px'
                                }}
                            />
                            {errors.domestic_expenses && <span>This input is required</span>}
                        </>
                    )}
                />
            </form>
        );
    }
);
export interface FormDAPInterface {
    psr_input: string;
}

export const FormDAP = ({ item, psrItem, index, bangKeItem }: FormProps) => {
    const { formType, MapBangKeData } = useContext(InvoiceContext);
    const [sedo, setSedo] = useState(false);
    const psrLabel = useMemo(() => {
        if (bangKeItem) return bangKeItem.criteria;
        else if (psrItem) return psrItem[formType] ?? '';
        else return '';
    }, [psrItem, bangKeItem]);
    const [insertType, setInsertType] = useState(DropDownEXWInsertTypeEnum.formular);
    const [dropDownApplicable, setDropDownApplicable] = useState<DropDownApplicableEnum>(DropDownApplicableEnum.CTC);
    const dapInputRef = useRef(null);
    const [dapInput, setDapInput] = useState<null | FormDapInputInterface>(null);
    function CollectDapInput(input: FormDapInputInterface) {
        setDapInput(input);
    }

    const processPriceRef = useRef(null);
    const [processPrice, setProcessPrice] = useState<null | ProcessingPriceInterface>(null);
    function CollectProcessPrice(input: ProcessingPriceInterface) {
        setProcessPrice({ ...input });
    }

    const inputRef = useRef(null);
    const [userInput, setUserInput] = useState<null | UserInputInterface>(null);
    function CollectInput(input: UserInputInterface) {
        setUserInput(input);
    }

    const [bangKeData, setBangKeData] = useState<null | BangKeDataInterface>(null);
    const [formData, setFormData] = useState<FormDAPInterface>({
        psr_input: psrLabel ?? ''
    });

    useEffect(() => {
        const bangKeItem: BangKeDataInterface = {
            id: item.id,
            exchange_rate: item.exchange_rate,
            use_sedo: sedo,
            account_number: item.account_number,
            registered_date: item.registered_date,
            hs: item.hs,
            total: item.total,
            nt_value: item.nt_value,
            name: item.item_name,
            bill_date: item.bill_date,
            form_type: dropDownApplicable,
            option: ConvertOption(insertType, formType),
            criteria: formData.psr_input,
            code: item.code,
            input: {
                result: 0
            }
        };
        if (insertType === DropDownEXWInsertTypeEnum.formular) {
            bangKeItem.input = {
                domestic_expenses: dapInput?.domestic_expenses ?? 0,
                imported_expenses: dapInput?.imported_expenses ?? 0
            };
        }

        if (insertType === DropDownEXWInsertTypeEnum.user_input) {
            bangKeItem.input = { result: userInput?.user_input ?? 0 };
        }
        if (insertType === DropDownEXWInsertTypeEnum.progess_price) {
            bangKeItem.input = { result: processPrice?.price_input ?? 0 };
        }
        const timeout = setTimeout(() => {
            setBangKeData((prev: BangKeDataInterface | null) => {
                if (prev === null) return bangKeItem;
                else {
                    if (_.isEqual(prev, bangKeItem)) return prev;
                    else {
                        return bangKeItem;
                    }
                }
            });
        }, 500);

        return () => clearTimeout(timeout);
    }, [sedo, insertType, dropDownApplicable, dapInput, processPrice, userInput, formData]);

    useEffect(() => {
        if (bangKeData) {
            MapBangKeData(bangKeData);
        }
    }, [bangKeData]);

    function SedoHandler() {
        setSedo((prev) => !prev);
    }

    useEffect(() => {
        if (sedo) {
            setDropDownApplicable(DropDownApplicableEnum.RVC);
            setInsertType(DropDownEXWInsertTypeEnum.progess_price);
        } else {
            setInsertType(DropDownEXWInsertTypeEnum.formular);
        }
    }, [sedo]);

    useEffect(() => {
        if (bangKeItem) {
            if (bangKeItem.use_sedo) {
                setDropDownApplicable(DropDownApplicableEnum.RVC);
                setSedo(true);
            } else {
                setDropDownApplicable(RevertBangKeDataFormType(bangKeItem.form_type));
                setSedo(false);
            }
            if (RevertBangKeDataEXWOption(bangKeItem.option) === DropDownEXWInsertTypeEnum.progess_price) {
                setInsertType(DropDownEXWInsertTypeEnum.progess_price);
                setProcessPrice({ price_input: bangKeItem.input.result ?? 0 });
            } else if (RevertBangKeDataEXWOption(bangKeItem.option) === DropDownEXWInsertTypeEnum.user_input) {
                setInsertType(DropDownEXWInsertTypeEnum.user_input);
                setUserInput({ user_input: bangKeItem.input.result ?? 0 });
            } else {
                setInsertType(DropDownEXWInsertTypeEnum.formular);
                setDapInput({
                    nt_value: item.nt_value ?? 0,
                    domestic_expenses: bangKeItem.input.domestic_expenses ?? 0,
                    imported_expenses: bangKeItem.input.imported_expenses ?? 0
                });
            }
            setFormData({ psr_input: bangKeItem.criteria });
        }
    }, [bangKeItem]);

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '10px' }}>
            <div className={styled['no-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'No' : ' '}</h3>
                <h3>{index}</h3>
            </div>
            <div className={styled['code-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Code' : ' '}</h3>
                <h3>{item.code}</h3>
            </div>
            <div className={styled['name-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Name' : ' '}</h3>
                <h3>{ConvertBangKeName(item.item_name)}</h3>
            </div>
            <div className={styled['hs-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Hs' : ' '}</h3>
                <h3>{item.hs}</h3>
            </div>

            <div className={styled['criteria-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Criteria' : ' '}</h3>
                <TextField
                    value={formData.psr_input}
                    variant="standard"
                    sx={{ width: 200 }}
                    onChange={(e) => {
                        setFormData({ psr_input: e.target.value });
                    }}
                />
            </div>

            <DropDownInsertType sedo={sedo} insertType={insertType} setInsertType={setInsertType} type={DropDownType.exw} index={index} />

            <div className={styled['hs-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Value' : ' '}</h3>
                {insertType === DropDownEXWInsertTypeEnum.user_input ? (
                    <Box>
                        <UserInput ref={inputRef} insertType={insertType} fn={CollectInput} prevUserInput={userInput} />
                    </Box>
                ) : (
                    <>
                        {insertType === DropDownEXWInsertTypeEnum.formular ? (
                            <Box>
                                <FormDapInput
                                    item={item}
                                    ref={dapInputRef}
                                    insertType={insertType}
                                    fn={CollectDapInput}
                                    prevInputItem={dapInput}
                                />
                            </Box>
                        ) : (
                            <Box>
                                <ProcessPrice
                                    ref={processPriceRef}
                                    insertType={insertType}
                                    fn={CollectProcessPrice}
                                    prevProgessPrice={processPrice}
                                />
                            </Box>
                        )}
                    </>
                )}
            </div>

            <DropDownFormularApplicableCriteria
                sedo={sedo}
                dropDownApplicable={dropDownApplicable}
                setDropDownApplicable={setDropDownApplicable}
                index={index}
            />

            <div className={styled['hs-field']}>
                <h3 className={styled['step2-label']}>{index === 1 ? 'Sedo' : ' '}</h3>
                <Switch checked={sedo} onChange={SedoHandler} />
            </div>
        </Box>
    );
};
