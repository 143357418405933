import { ConnectionState, useAsync } from 'hooks/Async';
import { JSONType } from 'models';
import produce, { castDraft } from 'immer';
import _ from 'lodash';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { P, match } from 'ts-pattern';
import { TableContext } from '../context';
import { ManipulationAsyncValue } from '../type';
import useSnackBar, { SnackBarAlertEnum } from 'components/SnackBar/UISnackbar';

export const useManipulateTable = <T, BalanceDataParam>({
    args,
    updateFn,
    deleteFn,
    insertFn,
    extractDataId
}: {
    args: BalanceDataParam;
    updateFn: (rows: Partial<T>, args: any) => Promise<any>;
    deleteFn: (ids: string[], args: any) => Promise<any>;
    insertFn: (json: JSONType) => Promise<any>;
    extractDataId: (value: T) => string;
}) => {
    const { setManipuateConnectionState } = useContext(TableContext);
    const { showSnackBar } = useSnackBar();

    const { deleteData } = useDelete<T, BalanceDataParam>({
        args,
        deleteFn,
        extractDataId
    });

    const { updateData } = useUpdate<T, BalanceDataParam>({
        args,
        updateFn
    });

    const { insertData } = useInsert({
        args,
        insertFn
    });

    const manipulationState = useMemo<ManipulationAsyncValue>(() => {
        return {
            insert: insertData,
            delete: deleteData,
            update: updateData
        };
    }, [updateData, deleteData, insertData]);

    useEffect(() => {
        if (
            _.mapKeys(manipulationState, (value, _) => {
                return value.state;
            })[ConnectionState.waiting]
        ) {
            setManipuateConnectionState(ConnectionState.waiting);
        }
        const message = match(manipulationState)
            .with({ update: { state: ConnectionState.hasError, error: P.select() } }, (error) => {
                return 'Failed to update: ' + error.meaning;
            })
            .with({ insert: { state: ConnectionState.hasError, error: P.select() } }, (error) => {
                return 'Failed to insert: ' + error.meaning;
            })
            .with({ delete: { state: ConnectionState.hasError, error: P.select() } }, (error) => {
                return 'Failed to delete: ' + error.meaning;
            })
            .otherwise(() => {});
        if (message) {
            setManipuateConnectionState(ConnectionState.hasError);
            showSnackBar(message, SnackBarAlertEnum.error);
        }
    }, [manipulationState]);
};

const useDelete = <T, BalanceDataParam>({
    args,
    deleteFn,
    extractDataId
}: {
    args: BalanceDataParam;
    deleteFn: (ids: string[], args: any) => Promise<any>;
    extractDataId: (value: T) => string;
}) => {
    const {
        tableState,
        selectedRowData,
        setTableState,
        refresh,
        setRowSelection,
        rowSelection,
        rowDeleteId,
        dataCount,
        pagingState,
        pagingController
    } = useContext(TableContext);

    const { execute, tableData } = useAsync<number[], BalanceDataParam>(() =>
        deleteFn(
            selectedRowData.map((value) => extractDataId(value)),
            args
        )
    );
    const updateDataAfterDelete = useCallback(() => {
        match(tableState)
            .with({ state: 'edit' }, (state) => {
                const nextTableState = produce(state, (stateDraft) => {
                    stateDraft.draft = castDraft(
                        _.omit(state.draft, _.keys(rowSelection)) as {
                            [key: number]: T;
                        }
                    );
                });
                setTableState(nextTableState);
            })
            .otherwise((_) => {});

        if (dataCount % pagingState.pageSize <= selectedRowData.length && pagingState.pageIndex > 0) {
            pagingController.previousPage();
        }

        refresh({ setTableStateAsRead: false });
        setRowSelection({});
    }, [tableState, rowSelection, pagingState, dataCount, selectedRowData]);

    useEffect(() => {
        match(tableData)
            .with({ state: ConnectionState.hasData }, (_) => {
                updateDataAfterDelete();
            })
            .otherwise(() => {});
    }, [tableData]);

    useEffect(() => {
        if (rowDeleteId && Object.keys(rowSelection).length !== 0) {
            execute(args);
        }
    }, [rowDeleteId]);

    return {
        deleteData: tableData
    };
};

const useUpdate = <T, BalanceDataParam>({
    args,
    updateFn
}: {
    args: BalanceDataParam;
    updateFn: (rows: Partial<T>, args: any) => Promise<any>;
}) => {
    const { tableState, rowUpdateId, refresh, setTableState } = useContext(TableContext);

    const { execute, tableData } = useAsync<number[], BalanceDataParam>((args) =>
        updateFn(
            match(tableState)
                .with({ state: 'update', row: P.select() }, ({ data }) => data)
                .otherwise(() => []),
            args
        )
    );

    useEffect(() => {
        match(tableData)
            .with({ state: ConnectionState.hasData }, (_) => {
                refresh({ setTableStateAsRead: false });
            })
            .otherwise(() => {});
    }, [tableData]);

    useEffect(() => {
        if (rowUpdateId) {
            execute(args);
            setTableState({ state: 'read' });
        }
    }, [rowUpdateId]);

    return {
        updateData: tableData
    };
};

const useInsert = <T, BalanceDataParam>({ args, insertFn }: { args: BalanceDataParam; insertFn: (json: JSONType) => Promise<void> }) => {
    const { insertData, refresh, setTableState } = useContext(TableContext);

    const { execute, tableData } = useAsync<void, JSONType>((params) => {
        return insertFn(_.merge(params, args));
    });

    useEffect(() => {
        match(tableData)
            .with({ state: ConnectionState.hasData }, (_) => {
                refresh({ setTableStateAsRead: false });
            })
            .otherwise(() => {});
    }, [tableData]);

    useEffect(() => {
        if (insertData) {
            execute(insertData);
            setTableState({ state: 'read' });
        }
    }, [insertData]);

    return { insertData: tableData };
};

/**
 *   const updateTableState = useCallback(
    (ids: number[]) => {
      const idsMap = ids.reduce<{ [key: number]: boolean }>((prev, curr) => {
        prev[curr] = true
        return prev
      }, {})
      const nextTableState = match<TableState<any>>(tableState)
        .with({ state: 'edit' }, ({ draft }) => {
          const next = _.omitBy(draft, (record) => idsMap[record.id])
          if (_.isEmpty(next))
            return {
              state: 'read',
            }
          return { state: 'edit', draft: next }
        })
        .otherwise(() => ({
          state: 'read',
        }))
      if (tableState !== nextTableState)
        setTableState(nextTableState as TableState<any>)
    },
    [tableState]
  )

 */
