import { Step, StepLabel, Stepper } from '@mui/material';
import { useContext } from 'react';
import { Report15Context } from '../context';
import styled from '../style.module.css';

function ReportStatementStep() {
    const { step } = useContext(Report15Context);

    const STEPS = ['Insert value', 'Import file', 'Pick header', 'Map header'];

    return (
        <div className={styled['modal_step']}>
            <Stepper activeStep={step} sx={{ width: '80%' }}>
                {STEPS.map((label, index) => {
                    return (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
        </div>
    );
}

export default ReportStatementStep;
