import {AutoFillParams} from 'components/Table';
import _ from 'lodash';
import {BomData, JSONType} from 'models';
import {AutoFillData} from 'models/AutoFillData/AutoFillData';
import {BomImportParams, IBomExportExcel, IBomFix} from './type';
import {saveAs} from 'file-saver';
import {BomQueryParams} from 'repository/type';
import {Http} from 'api';
import {BalanceImportDataSource} from "../BalanceImportDataSource";

export class BomDataSource extends BalanceImportDataSource<BomImportParams> {
    path: string = "bom";
    importUrl = "/api/balance/bom/calculator_import/"
    setupUrl = "/api/balance/bom/calculator_import_setup/"

    public async getAll(params: BomQueryParams) {
        const response = await this.http.instance.get('/api/balance/bom/', {
            params
        });
        return new BomData().fromPaging<BomData>(response.data);
    }

    public async update(data: Partial<BomData>, params: BomQueryParams) {
        return this.http.instance.put(
            `/api/balance/bom/${data.id}/`,
            _.omit(
                {
                    ...data,
                    ...params
                },
                'id'
            )
        );
    }

    public async delete(ids: string[]) {
        return this.http.instance.delete(`/api/balance/bom/bulk-delete/`, {
            data: {id_list: ids}
        });
    }

    public async insert(data: JSONType) {
        return this.http.instance.post(`/api/balance/bom/`, data);
    }

    public async getAutoFill(data: AutoFillParams) {
        const respone = await this.http.instance.post('/api/balance/bom/autofill/', data);
        return new AutoFillData().fromPaging<AutoFillData>(respone.data);
    }

    public async exportExcel(params: IBomExportExcel) {
        const response = await this.http.instance.get(`/api/balance/bom/`, {params, responseType: 'blob'});
        const blob = new Blob([response.data], {type: response.data.type});
        saveAs(blob, 'bom.xlsx');
    }

    public async fixBom(data: IBomFix) {
        return await this.http.instance.post(`/api/balance/bom/fix/`, data);
    }
}

export class NormalBomDataSource extends BalanceImportDataSource<BomImportParams> {
    path: string = "bom";
}