import React, { useState } from 'react';
import axios from 'axios';
import { Box, Grid, TextField, Typography, Fab, IconButton } from '@mui/material';
import { Message, Close, AttachFile, EmojiEmotions, Send } from '@mui/icons-material';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';

const FloatingContactWidget = () => {
  const [showForm, setShowForm] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    question: ''
  });
  const [selectedEmoji, setSelectedEmoji] = useState('');
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [showSuccess, setShowSuccess] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const getBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = error => reject(error);
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  
    const formDataToSend: any = {
      name: formData.name,
      email: formData.email,
      question: formData.question + selectedEmoji
    };

    if (selectedFile) {
      const base64File = await getBase64(selectedFile);
      formDataToSend.fileName = selectedFile.name;
      formDataToSend.fileType = selectedFile.type;
      formDataToSend.file = base64File.split(',')[1]; // Extracting the base64 part
    }
  
    try {
      await axios.post('https://func-cw.azurewebsites.net/api/httptriggerfunc-cw?code=6guyAkYxh5nUv5EjGGjMmHI7ecuLpRusqIkQBuxBRzqaAzFuCU5K0g%3D%3D', formDataToSend, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      setShowSuccess(true);
      setTimeout(() => setShowSuccess(false), 3000);
      setFormData({ name: '', email: '', question: '' });
      setSelectedFile(null);
    } catch (error) {
      console.error('Error sending form data:', error);
    } 
  };

  const handleToggleForm = () => {
    setShowForm(!showForm);
  };

  const handleEmojiSelect = (emoji: any) => {
    setSelectedEmoji(emoji.native);
    setFormData({
      ...formData,
      question: formData.question + emoji.native
    });
    setShowEmojiPicker(false);
  };

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    setSelectedFile(file);
  };

  return (
    <Box sx={{ position: 'fixed', bottom: '30%', right: 16, display: 'flex', flexDirection: 'column', alignItems: 'flex-end', zIndex: 999 }}>
      {showForm && (
        <Box sx={{ mb: 2, p: 2, borderRadius: 2, boxShadow: 3, backgroundColor: 'white', maxWidth: 300, position: 'relative' }}>
          <Typography variant="h6" mb={2} sx={{ color: '#5c95ec' }}>
            Leave your question and we will answer you soon.
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField fullWidth label="Your Name" name="name" value={formData.name} onChange={handleChange} variant="outlined" required />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth label="Your Email" name="email" value={formData.email} onChange={handleChange} variant="outlined" type="email" required />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth label="Your Question" name="question" value={formData.question} onChange={handleChange} variant="outlined" multiline rows={4} required />
              </Grid>
              <Grid item xs={4} textAlign="center" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <IconButton onClick={() => setShowEmojiPicker(!showEmojiPicker)} sx={{ height: '10px', width: '10px' }}>
                  <EmojiEmotions />
                </IconButton>
                {showEmojiPicker && (
                  <Box sx={{ position: 'absolute', bottom: 60, right: 16, zIndex: 1000 }}>
                    <Picker data={data} onEmojiSelect={handleEmojiSelect} />
                  </Box>
                )}
                <IconButton component="label" sx={{ height: '40px', width: '40px' }}>
                  <AttachFile />
                  <input type="file" onChange={handleFileSelect} accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt" hidden />
                </IconButton>
              </Grid>
              <Grid item xs={3} textAlign="right">
                {selectedFile && <Typography variant="body2" sx={{ ml: 1 }}>{selectedFile.name}</Typography>}
              </Grid>
              <Grid item xs={5} textAlign="right">
                <IconButton type="submit" >
                  <Send sx={{ color: '#5c95ec' }}/>
                </IconButton>
              </Grid>
              <Grid item xs={12} textAlign="center">
                {showSuccess && <Typography variant="body2" sx={{ color: 'green', position: 'static', top: -20, right: 0 }}>Successfully sent!</Typography>}
              </Grid>
            </Grid>
          </form>
        </Box>
      )}
      <Fab sx={{ backgroundColor: '#5c95ec', color: 'white', '&:hover': {backgroundColor: '#192c49'} }} onClick={handleToggleForm}>
        {showForm ? <Close /> : <Message />}
      </Fab>
    </Box>
  );
};

export default FloatingContactWidget;
