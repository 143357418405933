
function IconIconHome(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="#2196F3"
      height="1em"
      width="1em"
      {...props}
    >
      <path d="M13 20v-5h-2v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-7.59l-.3.3a1 1 0 11-1.4-1.42l9-9a1 1 0 011.4 0l9 9a1 1 0 01-1.4 1.42l-.3-.3V20a2 2 0 01-2 2h-3a2 2 0 01-2-2zm5 0v-9.59l-6-6-6 6V20h3v-5c0-1.1.9-2 2-2h2a2 2 0 012 2v5h3z" />
    </svg>
  );
}

export default IconIconHome;
