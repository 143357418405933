import { InputBase, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { borderColor, borderRadius } from '@mui/system';
import { ProjectThemeProps } from 'theme';
export * from './DateRangePicker';

const Input = styled(TextField)(({ theme }: { theme: ProjectThemeProps }) => ({
    '& .MuiOutlinedInput-root': {
        outline: 'none',
        '& fieldset': {
            borderRadius: `4px`,
            // background: `#F2F7FF`,
            border: `1px solid rgba(33, 150, 243, 0.5)`,
            borderColor: '#2196F3',
        },
        '& input': {
            paddingTop: '16px',
            paddingBottom: '16px',
            background: `#F2F7FF`,
            
        },
        '&:hover fieldset': {
            border: `1px solid rgba(33, 150, 243, 0.5)`,
            borderColor: '#2196F3',
            
            
        },
        '&.Mui-focused fieldset': {
            border: `1px solid rgba(33, 150, 243, 0.5)`,
            borderColor: '#2196F3'
        }
    }
}));
const StyleInput = (props: any) => {
    return <Input {...props} />;
};

export default StyleInput;
