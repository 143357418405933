import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { SpreadSheetContext } from '../context';
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { Range, utils, WorkSheet } from 'xlsx';
import { SheetRow } from '../type';
import _ from 'lodash';
import { HotTable } from '@handsontable/react';
import './SheetTitlePicker.scss';
import { DetailedSettings as MergeCellsSettings } from 'handsontable/plugins/mergeCells';
import { Sheet, TableType } from 'components';
import { useBalanceNewRepo, useBalanceRepo } from 'di';
import { useDispatch } from 'react-redux';
import { match, P } from 'ts-pattern';
import { Exception, resultGuard, ResultState } from 'models';
import { Dictionary } from '@reduxjs/toolkit';
import { setLoading } from 'store/Global';
import { useDebounce } from 'use-debounce';
import useSnackBar, { SnackBarAlertEnum } from '../../SnackBar/UISnackbar';

export const SheetTitlePicker = () => {
    const { workBook, selectedSheet, setSelectedSheet, selectedFile, endRow, rowIndex, table } = useContext(SpreadSheetContext);
    const { onSelectHeader, availableCells, hotRef, sheetJson, mergeCells, setStartCell, setEndCell, startCell, endCell } = useViewModel({
        table
    });

    const [startCellText, setStartCellText] = useState('');
    const [endCellText, setEndCellText] = useState('');
    const [startCellTextDebounced] = useDebounce(startCellText, 500);
    const [endCellTextDebounced] = useDebounce(endCellText, 500);

    useEffect(() => {
        const range = availableCells[startCellTextDebounced];
        if (range) setStartCell([startCellTextDebounced, range]);
        else setStartCell(undefined);
    }, [startCellTextDebounced, availableCells]);

    useEffect(() => {
        const range = availableCells[endCellTextDebounced];
        if (range) setEndCell([endCellTextDebounced, range]);
        else setEndCell(undefined);
    }, [endCellTextDebounced, availableCells]);

    return (
        <>
            {workBook ? (
                <>
                    <div className="tw-flex tw-flex-col">
                        <FormControl sx={{ maxWidth: 320 }}>
                            <InputLabel>Sheet</InputLabel>
                            <Select<string>
                                value={selectedSheet ?? ''}
                                label="Sheet"
                                onChange={(value) => {
                                    setSelectedSheet(value.target.value);
                                }}
                            >
                                {workBook.SheetNames.map((sheetName) => (
                                    <MenuItem key={sheetName} value={sheetName}>
                                        {sheetName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <div className="tw-flex tw-flex-row tw-mt-4">
                            <TextField
                                label={'Start'}
                                value={startCellText}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setStartCellText(event.target.value.toUpperCase());
                                }}
                            ></TextField>
                            <div className="tw-w-2" />
                            <TextField
                                label={'End'}
                                value={endCellText}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setEndCellText(event.target.value.toUpperCase());
                                }}
                            ></TextField>
                        </div>
                    </div>
                    <div className="tw-h-[12px]" />
                    {sheetJson ? (
                        <div className="tw-relative tw-w-full">
                            <Sheet
                                key={_.keys(sheetJson).join('#')}
                                height={320}
                                hiddenRows
                                hiddenColumns
                                ref={hotRef}
                                outsideClickDeselects={false}
                                data={sheetJson}
                                contextMenu
                                selectionMode={'range'}
                                mergeCells={mergeCells}
                                rowHeaders={true}
                                colHeaders={true}
                                endCell={_.get(endCell, '0')}
                                startCell={_.get(startCell, '0')}
                                licenseKey="non-commercial-and-evaluation" // for non-commercial use only
                            />
                            <div className="tw-flex tw-flex-row tw-justify-end tw-mt-4">
                                <Button variant={'outlined'} onClick={onSelectHeader}>
                                    Select header
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                </>
            ) : (
                <></>
            )}
        </>
    );
};

const useViewModel = ({ table = TableType.ecus }: { table?: TableType }) => {
    const { workBook, selectedSheet, selectedFile, setHeader, setRowIndex, setEndRow } = useContext(SpreadSheetContext);
    const [sheetJson, setSheetJson] = useState<SheetRow[] | undefined>();
    const [mergeCells, setMergeCells] = useState<MergeCellsSettings[]>([]);
    const hotRef = useRef<HotTable | null>(null);
    const [workSheet, setWorkSheet] = useState<WorkSheet | undefined>();
    const [startCell, setStartCell] = useState<[string, Range] | undefined>();
    const [endCell, setEndCell] = useState<[string, Range] | undefined>();
    const dispatch = useDispatch();
    const { showSnackBar } = useSnackBar();
    const getHotTableInst = () => {
        return hotRef.current?.hotInstance;
    };

    const convertSheetToJson = useCallback(() => {
        if (!selectedSheet || !workBook) return;
        const workSheet = workBook.Sheets[selectedSheet];
        const sheetJson = utils.sheet_to_json<(string | number | null)[]>(workSheet, {
            header: 1,
            skipHidden: false,
            blankrows: true,
            defval: null,
            range: `A1:${endCell![0]}`
        });
        const merged = workSheet['!merges']?.map(({ s: { r: startRow, c: startCol }, e: { r: endRow, c: endCol } }) => ({
            row: startRow,
            col: startCol,
            rowspan: endRow - startRow + 1,
            colspan: endCol - startCol + 1
        }));
        if (merged) setMergeCells(merged);
        console.log(sheetJson);
        setSheetJson(sheetJson);
    }, [selectedSheet, workBook, endCell]);

    useEffect(() => {
        if (workBook && selectedSheet) setWorkSheet(workBook.Sheets[selectedSheet]);
        // convertSheetToJson()
    }, [selectedSheet, workBook]);

    const getSelectionRange = () => {
        const hot = getHotTableInst()!;
        const selected = hot.getSelectedLast();
        const [row1, column1, row2, column2] = selected ?? [];
        if ([row1, column1, row2, column2].some((val) => _.isNil(val))) {
            throw new Exception("You haven't select the header yet");
        }
        const startRow = Math.max(Math.min(row1, row2), 0);
        const endRow = Math.max(row1, row2);
        const startCol = Math.max(Math.min(column1, column2), 0);
        const endCol = Math.max(column1, column2);
        return {
            startRow,
            endRow,
            startCol,
            endCol
        };
    };

    const availableCells = useMemo<Dictionary<Range>>(() => {
        return _.chain(workSheet)
            .omitBy((_, key) => key.includes('!'))
            .mapValues((value, key) => {
                return utils.decode_range(key);
            })
            .value();
    }, [workSheet]);

    let _onSelectHeader = useCallback(() => {
        const hot = getHotTableInst();
        if (!hot) return;
        hot.suspendRender();

        const { startRow, startCol, endRow, endCol } = getSelectionRange();
        for (let rowIndex = 0; rowIndex < hot.countRows(); rowIndex++) {
            for (let colIndex = 0; colIndex < hot.countCols(); colIndex++) {
                let inSelectedRange = _.inRange(rowIndex, startRow, endRow + 1) && _.inRange(colIndex, startCol, endCol + 1);

                hot.setCellMeta(rowIndex, colIndex, 'className', inSelectedRange ? 'tw-bg-blue-100' : 'tw-bg-white');
            }
        }

        hot.render();
        hot.resumeRender();

        setRowIndex(startRow + 1, endRow + 1);
    }, []);

    const onSelectHeader = async () => {
        _onSelectHeader();
        return resultGuard(upload);
    };

    const hide = useCallback(
        (params?: { shouldSuspendRender: boolean }) => {
            const hot = getHotTableInst();
            if (!hot || !startCell || !endCell) return;
            const { shouldSuspendRender = true } = params ?? {};
            if (shouldSuspendRender) hot.suspendRender();
            const hideColumnPlugin = hot.getPlugin('hiddenColumns');
            const columns = [..._.range(0, startCell[1].s.c), ..._.range(endCell[1].s.c + 1, hot.countCols())];
            hideColumnPlugin.hideColumns(columns);

            const hideRowPlugin = hot.getPlugin('hiddenRows');
            const startCellRow = startCell[1].s.r;
            const MAX_VISIBLE_ROW = 20;
            const visibleRowIndex = Math.min(startCellRow + MAX_VISIBLE_ROW, endCell[1].e.r);
            hideRowPlugin.hideRows([..._.range(0, startCellRow), ..._.range(visibleRowIndex + 1, hot.countRows())]);
            if (shouldSuspendRender) {
                hot.render();
                hot.resumeRender();
            }
        },
        [startCell, endCell]
    );

    useEffect(() => {
        if (!sheetJson) return;
        hide();
    }, [sheetJson]);

    useEffect(() => {
        setEndCell(undefined);
        setStartCell(undefined);
        setSheetJson(undefined);
    }, [selectedSheet]);

    useEffect(() => {
        if (!endCell || !startCell) setSheetJson(undefined);
        if (selectedSheet && endCell && startCell) {
            convertSheetToJson();
            setEndRow(endCell[1].s.r + 1);
        }
    }, [startCell, endCell, selectedSheet]);

    useEffect(() => {
        hide();
    }, []);

    const balanceRepo = useBalanceRepo();
    const balanceNewRepo = useBalanceNewRepo();

    const upload = useCallback(async () => {
        const { startRow, endRow } = getSelectionRange();
        dispatch(setLoading(true));
        let result;
        switch (table) {
            case TableType.statement15:
                result = await balanceNewRepo.getSheetHeader({
                    header_from: startRow + 1,
                    header_to: endRow + 1,
                    sheet_name: selectedSheet!,
                    excel_file: selectedFile!,
                    colStart: startCell![0].replaceAll(/\d/g, ''),
                    colEnd: endCell![0].replaceAll(/\d/g, '')
                });
                break;
            case TableType.statement15a:
                result = await balanceNewRepo.getSheetHeader({
                    header_from: startRow + 1,
                    header_to: endRow + 1,
                    sheet_name: selectedSheet!,
                    excel_file: selectedFile!,
                    colStart: startCell![0].replaceAll(/\d/g, ''),
                    colEnd: endCell![0].replaceAll(/\d/g, '')
                });
                break;
            case TableType.takeinAccounting:
                result = await balanceNewRepo.getSheetHeader({
                    header_from: startRow + 1,
                    header_to: endRow + 1,
                    sheet_name: selectedSheet!,
                    excel_file: selectedFile!,
                    colStart: startCell![0].replaceAll(/\d/g, ''),
                    colEnd: endCell![0].replaceAll(/\d/g, '')
                });
                break;
            case TableType.takeoutAccounting:
                result = await balanceNewRepo.getSheetHeader({
                    header_from: startRow + 1,
                    header_to: endRow + 1,
                    sheet_name: selectedSheet!,
                    excel_file: selectedFile!,
                    colStart: startCell![0].replaceAll(/\d/g, ''),
                    colEnd: endCell![0].replaceAll(/\d/g, '')
                });
                break;
            case TableType.physicalAcc:
                result = await balanceNewRepo.getSheetHeader({
                    header_from: startRow + 1,
                    header_to: endRow + 1,
                    sheet_name: selectedSheet!,
                    excel_file: selectedFile!,
                    colStart: startCell![0].replaceAll(/\d/g, ''),
                    colEnd: endCell![0].replaceAll(/\d/g, '')
                });
                break;
            case TableType.iobAccounting:
                result = await balanceNewRepo.getSheetHeader({
                    header_from: startRow + 1,
                    header_to: endRow + 1,
                    sheet_name: selectedSheet!,
                    excel_file: selectedFile!,
                    colStart: startCell![0].replaceAll(/\d/g, ''),
                    colEnd: endCell![0].replaceAll(/\d/g, '')
                });
                break;
            case TableType.iob:
                result = await balanceNewRepo.getSheetHeader({
                    header_from: startRow + 1,
                    header_to: endRow + 1,
                    sheet_name: selectedSheet!,
                    excel_file: selectedFile!,
                    colStart: startCell![0].replaceAll(/\d/g, ''),
                    colEnd: endCell![0].replaceAll(/\d/g, '')
                });
                break;
            case TableType.physicalWH:
                result = await balanceNewRepo.getSheetHeader({
                    header_from: startRow + 1,
                    header_to: endRow + 1,
                    sheet_name: selectedSheet!,
                    excel_file: selectedFile!,
                    colStart: startCell![0].replaceAll(/\d/g, ''),
                    colEnd: endCell![0].replaceAll(/\d/g, '')
                });
                break;
            case TableType.takein:
                result = await balanceNewRepo.getSheetHeader({
                    header_from: startRow + 1,
                    header_to: endRow + 1,
                    sheet_name: selectedSheet!,
                    excel_file: selectedFile!,
                    colStart: startCell![0].replaceAll(/\d/g, ''),
                    colEnd: endCell![0].replaceAll(/\d/g, '')
                });
                break;
            case TableType.takeout:
                result = await balanceNewRepo.getSheetHeader({
                    header_from: startRow + 1,
                    header_to: endRow + 1,
                    sheet_name: selectedSheet!,
                    excel_file: selectedFile!,
                    colStart: startCell![0].replaceAll(/\d/g, ''),
                    colEnd: endCell![0].replaceAll(/\d/g, '')
                });
                break;
            default:
                result = await balanceRepo.getSheetHeader({
                    header_from: startRow + 1,
                    header_to: endRow + 1,
                    sheet_name: selectedSheet!,
                    excel_file: selectedFile!,
                    colStart: startCell![0].replaceAll(/\d/g, ''),
                    colEnd: endCell![0].replaceAll(/\d/g, '')
                });
                break;
        }

        match(result)
            .with({ state: ResultState.success, data: P.select() }, (data) => {
                setHeader(data);
                showSnackBar('FETCHED', SnackBarAlertEnum.success);
            })
            .with({ state: ResultState.failed, exception: P.select() }, (error) => {
                showSnackBar(error.meaning, SnackBarAlertEnum.error);
            })
            .exhaustive();
        dispatch(setLoading(false));
    }, [startCell, endCell, selectedSheet, selectedFile, table]);

    return {
        availableCells,
        hotRef,
        sheetJson,
        mergeCells,
        onSelectHeader,
        setStartCell,
        setEndCell,
        endCell,
        startCell
    };
};
