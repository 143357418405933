import { JsonObject, JsonProperty } from 'json2typescript';
import { JsonSerializable } from 'models/serialization';

export interface BangKeInputInterface {
    result?: number;
    freight?: number;
    domestic_expenses?: number;
    insurance?: number;
    imported_expenses?: number;
    oversea_expenses?: number;
}

export interface BangKeDataInterface {
    id: string;
    option: string;
    criteria: string;
    form_type: string;
    input: BangKeInputInterface;
    name: string;
    hs: string;
    account_number: string;
    registered_date: string;
    exchange_rate: number;
    total: number;
    code: string;
    lookup?: string;
    origin_option?: string;
    price_option?: string;
    use_sedo?: boolean;
    bill_date?: string;
    nt_value: number;
}

@JsonObject('BangKeData')
export class BangKeData extends JsonSerializable<BangKeData> implements BangKeDataInterface {
    protected get classRef(): new () => BangKeData {
        return BangKeData;
    }

    @JsonProperty('id', String, false)
    id!: string;

    @JsonProperty('option', String, false)
    option!: string;

    @JsonProperty('criteria', String, false)
    criteria!: string;

    @JsonProperty('name', String, true)
    name: string = '';

    @JsonProperty('hs', String, true)
    hs: string = '';

    @JsonProperty('form_type', String, false)
    form_type!: string;

    @JsonProperty('registered_date', String, false)
    registered_date!: string;

    @JsonProperty('account_number', String, false)
    account_number!: string;

    @JsonProperty('code', String, false)
    code!: string;

    @JsonProperty('lookup', String, true)
    lookup?: string;

    @JsonProperty('origin_option', String, true)
    origin_option?: string;

    @JsonProperty('price_option', String, true)
    price_option?: string;

    @JsonProperty('bill_date', String, true)
    bill_date: string = '';

    @JsonProperty('exchange_rate', Number, false)
    exchange_rate!: number;

    @JsonProperty('total', Number, false)
    total!: number;

    @JsonProperty('input', Object, true)
    input: BangKeInputInterface = {};

    @JsonProperty('use_sedo', Boolean, true)
    use_sedo: boolean = false;

    @JsonProperty('nt_value', Number, true)
    nt_value: number = 0;
}
