import { EximhowHttp } from 'api';
import { TableType } from 'components';
import { AuthResponse, ChangePasswordRespone, Exception, GetUserRespone, Result, ResultState, UpdateUserProfileResponse } from 'models';
import { DetailRespone } from 'models/DetailRespone';
import { DetailQueryParams } from 'repository/type';
import { ChangePasswordProps, UpdateUserProfileProps } from './type';

export class RemoteAuthDataSource {
    constructor(private http: EximhowHttp) {}

    async login(data: { email: string; password: string }): Promise<Result<string>> {
        try {
            const CLIENT_SECRET =
                'U3NOc004aW1FN0RIQzdhZEFzd0RBbnIxcTYzRUVYZnRjYlBFbXBacTpDUnllaWM3QzZXUnh0MGtEVlp5Q0oxRUVuYmdpSGlIQ2QyRUlKb0R3QzBNaWl6RW1BWVJ0UHliQmQzQ3lIZVgzTFZBVnB6cHYyVHlndmtRN0I3VE1BRnpGT2ZHUmt0VHAzY1NJdDZMYWhOMmljcGh4UU9IQlFnbnRWN2xQbGxreQ==';
            const params = new URLSearchParams();
            params.append('username', data.email);
            params.append('password', data.password);
            params.append('grant_type', 'password');
            const response = await this.http.instance.post('/api/oauth/token/', params, {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded',
                    Authorization: `Basic ${CLIENT_SECRET}`
                }
            });
            const token = new AuthResponse().fromJSON(response.data).access_token;
            return {
                state: ResultState.success,
                data: token
            };
        } catch (err) {
            return {
                state: ResultState.failed,
                exception: Exception.parse(err)
            };
        }
    }

    async register(data: { email: string; password: string; name: string }): Promise<Result<undefined>> {
        try {
            await this.http.instance.post('/api/registration/', data, {});
            return { state: ResultState.success, data: undefined };
        } catch (err) {
            return { state: ResultState.failed, exception: Exception.parse(err) };
        }
    }
    async verify(otp: string): Promise<Result<string>> {
        try {
            const response = await this.http.instance.post('/api/registration-confirm/', { token: otp });
            const { token } = response.data;
            return {
                state: ResultState.success,
                data: token.access_token
            };
        } catch (err) {
            return { state: ResultState.failed, exception: Exception.parse(err) };
        }
    }

    async resendOTP(email: string): Promise<Result<undefined>> {
        return await this.http.instance.post('/api/registration/resend/', { email });
    }

    async getMe(token: string) {
        const respone = await this.http.instance.get('/api/clients-api/info/', { headers: { Authorization: `Bearer ${token}` } });
        return new GetUserRespone().fromJSON(respone.data);
    }

    async resetPassword(email: string): Promise<Result<undefined>> {
        try {
            await this.http.instance.post('/api/password-reset/', { email });
            return {
                state: ResultState.success,
                data: undefined
            };
        } catch (err) {
            return {
                state: ResultState.failed,
                exception: Exception.parse(err)
            };
        }
    }

    async resetPasswordVerify(otp: string): Promise<Result<undefined>> {
        try {
            await this.http.instance.post('/api/password-reset/validate_token/', { token: otp });
            return {
                state: ResultState.success,
                data: undefined
            };
        } catch (err) {
            return {
                state: ResultState.failed,
                exception: Exception.parse(err)
            };
        }
    }

    async resetPasswordConfirm(otp: string, password: string): Promise<Result<undefined>> {
        try {
            await this.http.instance.post('/api/password-reset/confirm/', { token: otp, password });
            return {
                state: ResultState.success,
                data: undefined
            };
        } catch (err) {
            return {
                state: ResultState.failed,
                exception: Exception.parse(err)
            };
        }
    }

    async changePassword(data: ChangePasswordProps) {
        const respone = await this.http.instance.put('/api/change-password/', data);
        return new ChangePasswordRespone().fromJSON(respone.data).token.access_token;
    }

    async changeUserProfile(userInfo: UpdateUserProfileProps) {
        const respone = await this.http.instance.patch('/api/update-profile/', userInfo);
        return new UpdateUserProfileResponse().fromJSON(respone.data);
    }

    async loginWithGoogle(token: string) {
        const params = new URLSearchParams();
        params.append('grant_type', 'convert_token');
        params.append('backend', 'google-oauth2');
        params.append('token', token);
        params.append('client_id', 'SsNsM8imE7DHC7adAswDAnr1q63EEXftcbPEmpZq');
        params.append(
            'client_secret',
            'CRyeic7C6WRxt0kDVZyCJ1EEnbgiHiHCd2EIJoDwC0MiizEmAYRtPybBd3CyHeX3LVAVpzpv2TygvkQ7B7TMAFzFOfGRktTp3cSIt6LahN2icphxQOHBQgntV7lPllky'
        );

        const response = await this.http.instance.post('/api/social-auth/convert-token/', params, {
            headers: {
                'content-type': 'application/x-www-form-urlencoded'
            }
        });
        const { access_token } = response.data;
        return access_token;
    }

    async getDetailPage(params: DetailQueryParams) {
        switch (params.tableType) {
            case TableType.ama:
                const responeAma = await this.http.instance.get(`/api/balance/ecus-ama/`, {
                    params: {
                        company_slug: params.company_slug,
                        folder_slug: params.folder_slug,
                        import_record_id: params.import_record_id
                    }
                });
                return new DetailRespone().fromPaging<DetailRespone>(responeAma.data);

            default:
                const responeCancle = await this.http.instance.get(`/api/balance/ecus-cancel/`, {
                    params: {
                        company_slug: params.company_slug,
                        folder_slug: params.folder_slug,
                        import_record_id: params.import_record_id
                    }
                });
                return new DetailRespone().fromPaging<DetailRespone>(responeCancle.data);
        }
    }
}
