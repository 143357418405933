import { isNil } from 'lodash';

export class LocalAuthDataSource {
    protected TOKEN_KEY = 'token';

    constructor(private storageInstance = localStorage) {}

    get token(): string | null {
        return this.storageInstance.getItem(this.TOKEN_KEY);
    }

    get isAuthenticated(): boolean {
        return !isNil(this.storageInstance.getItem(this.TOKEN_KEY));
    }

    public setToken(token: string) {
        this.storageInstance.setItem(this.TOKEN_KEY, token);
    }
}



/**
 *   async login(data: {
    email: string
    password: string
  }): Promise<Result<string>> {
    try {
      const { email: username, password } = data
      const response = await this.http.instance.post(
        '/api/oauth/token/',
        qs.stringify({
          username,
          password,
          grant_type: 'password',
        }),
        {
          headers: {
            'content-type': 'application/x-www-form-urlencoded',
            Authorization:
              'Basic U0dQTXNqMlp2Sk9idFVERVE1am9IeTh6RVBxTFloZzV1ZjR3T1U0NTpOTm1UakcxeTVRUGNSZ3h4YkllM0d5ZDF5cjg5MFhZOTRwQXV6ck03SHRlY0FyQ1NPUnJXYWgwQnl4WXZWUm9SWkQyMXJjVHFKeU1malo3QW03QlFkZ2pRcHhkeGttczdzVm5Zc1I2MDJ1dTJMSUJQSm9rTEVTR0VGM3ptOU1Gdg==',
          },
        }
      )

      const token = new AuthResponse().fromJSON(response.data).access_token
      return {
        state: ResultState.success,
        data: token,
      }
    } catch (err) {
      return {
        state: ResultState.failed,
        exception: Exception.parse(err),
      }
    }
  }

  async getUserInfo(): Promise<AxiosResponse<any, any>> {
    return await this.http.instance.get(`/api/clients-api/info/`)
  }
 */
