import { memo, useMemo } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Drawer, IconButton, Stack, useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'; // Import Close icon
import { Menu } from '@mui/icons-material';
// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MenuCard from './MenuCard';
import LogoSection from '../LogoSection';
import MiniDrawerStyled from './MiniDrawerStyled';
import Chip from 'ui-component/extended/Chip';

import LAYOUT_CONST from 'constant';
import useConfig from 'hooks/useConfig';
import { drawerWidth } from 'store/constant';

import { useDispatch, useSelector } from 'store';
import { openDrawer } from 'store/slices/menu';
import HomeMenuList from '../MenuList/homeMenuList';
import LeftSections from './LeftSections';
import ItemList from './SearchHSWidget';
import { useHsContext } from 'contexts';
import { maxWidth } from '@mui/system';
import Search from '../Header/SearchSection/Search';
import searchCover from 'assets/Search-Cover.png';

// ==============================|| SIDEBAR DRAWER ||============================== //

const HomeSidebar = () => {
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const { isSearch } = useHsContext();
    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);

    const { layout, drawerType } = useConfig();

    const logo = useMemo(
        () => (
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    paddingX: { xs: 2.8, md: 0 },
                }}
            >
                <LogoSection />

                <IconButton
                    size="large"
                    onClick={() => dispatch(openDrawer(!drawerOpen))}
                    sx={{
                        color: theme.palette.primary.main,
                        background: { xs: theme.palette.grey[50], sm: 'transparent' },
                        borderRadius: "10px",
                        marginRight: 0,
                        marginTop: { xs: 0, md: '109px' }
                    }}
                >
                    <CloseIcon />
                </IconButton>

            </Box>
        ),
        [dispatch, drawerOpen, matchDownMd, theme.palette.text.primary]
    );

    const drawerContent = (
        <>
            <HomeMenuList />
        </>
    );

    const drawerSectionsContent = <LeftSections />;
    const drawerSearchContent = <ItemList />;

    const drawerSectionsSX = {
        marginTop: drawerOpen ? 0 : '20px',
    };

    const drawerSX = {
        // paddingLeft: drawerOpen ? '1px' : 0,
        paddingX: drawerOpen ? '16px' : 0,
        marginTop: drawerOpen ? 0 : '-40px',
        maxWidth: '100vw',
    };

    const drawer = useMemo(
        () => (
            <>
                {matchDownMd ? (
                    <Box sx={drawerSX}>{drawerContent}</Box>
                ) : (
                    <PerfectScrollbar
                        component="div"
                        style={{
                            height: !matchUpMd ? 'calc(100vh - 56px)' : 'calc(100vh - 88px)',
                            ...drawerSX,
                        }}
                    >
                        {!drawerOpen && <IconButton
                            size="large"
                            onClick={() => dispatch(openDrawer(!drawerOpen))}
                            sx={{
                                color: theme.palette.primary.main,
                                background: { xs: theme.palette.grey[50], sm: 'transparent' },
                                borderRadius: "10px",
                                mb: 1,
                                ml: -1,
                            }}
                        >
                            <Menu />
                        </IconButton>}

                        {drawerContent}
                    </PerfectScrollbar>
                )}
            </>
        ),
        [matchUpMd, drawerOpen, drawerType, matchDownMd, drawerSX]
    );

    const drawerSearch = useMemo(
        () => (
            <>
                {matchDownMd ? (
                    <Box sx={drawerSectionsSX}>{drawerSectionsContent}</Box>
                ) : (
                    <PerfectScrollbar> 
                        {!drawerOpen && <IconButton
                        size="large"
                        onClick={() => dispatch(openDrawer(!drawerOpen))}
                        sx={{
                            color: theme.palette.primary.main,
                            background: { xs: theme.palette.grey[50], sm: 'transparent' },
                            borderRadius: "10px",
                            mb: 1,
                            ml: 1,
                            mt: '-40px'
                        }}
                    >
                        <Menu />
                    </IconButton>}
                        {drawerSearchContent}</PerfectScrollbar>
                )}
            </>
        ),
        [matchUpMd, drawerOpen, matchDownMd, drawerSectionsContent, drawerSectionsSX]
    );

    const drawerSections = useMemo(
        () => (
            <>
                {matchDownMd ? (
                    <Box sx={drawerSectionsSX}>{drawerSectionsContent}</Box>
                ) : (
                    <PerfectScrollbar>{drawerSectionsContent}</PerfectScrollbar>
                )}
            </>
        ),
        [matchUpMd, drawerOpen, matchDownMd, drawerSectionsContent, drawerSectionsSX]
    );

    return (
        <Box
            component="nav"
            sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : 'auto' }}
            aria-label="mailbox folders"
        >
            {matchDownMd || (drawerType === LAYOUT_CONST.MINI_DRAWER && drawerOpen) ? (
                <Drawer
                    variant={matchUpMd ? 'persistent' : 'temporary'}
                    anchor="left"
                    open={drawerOpen}
                    onClose={() => dispatch(openDrawer(!drawerOpen))}
                    sx={{
                        '& .MuiDrawer-paper': {
                            mt: matchDownMd ? 0 : 11,
                            zIndex: 1099,
                            width: { xs: '100vw', sm: '500px', md: drawerWidth },
                            background: theme.palette.background.default,
                            color: theme.palette.text.primary,
                            borderRight: 'none',
                        },
                    }}
                    ModalProps={{ keepMounted: true }}
                    color="inherit"
                >
                    {matchDownMd && logo}
                    {drawer}
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            height: '150px',
                            alignItems: 'center',
                            background: `url(${searchCover}) no-repeat center center`,
                            backgroundSize: 'cover',
                        }}
                    >
                        <Search  sideBar={true} />
                    </Box>
                </Drawer>
            ) : (
                <MiniDrawerStyled variant="permanent" open={drawerOpen}>
                    {logo}
                    {isSearch ? <>{drawerSearch}</> : <>{drawer}{drawerSections}</>}
                </MiniDrawerStyled>
            )}
        </Box>
    );
};

export default memo(HomeSidebar);
