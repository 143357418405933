import { Box, Checkbox, FormControlLabel, FormGroup, MenuItem, Select, Switch, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ConvertDate, FormTypeEnum, GetFirstDay } from 'components/BalanceTable/TableInvoice/InvoiceProvider';
import useSnackBar, { SnackBarAlertEnum } from 'components/SnackBar/UISnackbar';
import { CompanyContext } from 'contexts';
import { ICompanySetting } from 'models';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import img from '../../../../assets/images/pages/co_form_bg_02.avif';
import {
    ConsigneeCompanyInterface,
    ConvertFormName,
    ExporterCompanyInterface,
    FieldStateInterface,
    InvoiceWordContext
} from '../InvoiceWordProvider';
import styled from './InvoiceWordForms/style.module.css';

const formLabel01 = `1. Products consigned from (Exporter's business name, address, country)`;
const formLabel02 = `2. Products consigned to (Consignee's name, address, country)`;

interface wordFormListInterface {
    title: FormTypeEnum;
}

function InvoiceWordStep1() {
    const { showSnackBar } = useSnackBar();
    const { company } = useContext(CompanyContext);
    const {
        label: labelProvider,
        exhibition: exhibitionProvider,
        movement: movementProvider,
        place: placeProvider,
        portDischarge: portDischargeProvider,
        referenceNo: referenceNoProvider,
        thirdParty: thirdPartyProvider,
        userInput: userInputProvider,
        userInput2: userInput2Provider,
        vesselName: vesselNameProvider,
        rowPerPage: rowPerPageProvider,
        markPackage: markPackageProvider,
        fieldState: fieldStateProvider,
        wordFormType: wordFormTypeProvider,
        retroactively: retroactivelyProvider,
        back2back: back2backProvider,
        cumulation: cumulationProvider,
        thirdCountry: thirdCountryProvider,
        gw: gwProvider,
        setGW: setGWProvider,
        setFieldState,
        setWordFormType,
        setRowPerPage: setRowPerPageProvider,
        setUserInput: setUserInputProvider,
        setLabel: setLabelProvider,
        setPlace: setPlaceProvider,
        setVesselName: setVesselNameProvider,
        setMarkPackage: setMarkPackageProvider,
        setPortDischarge: setPortDischargesetProvider,
        setDepartureDate: setDepartureDateProvider,
        setCompanyExporter: setCompanyExporterProvider,
        setCompanyConsignee: setCompanyConsigneeProvider,
        setUserInput2: setUserInput2Provider,
        setThirdParty: setThirdPartyProvider,
        setExhibition: setExhibitionProvider,
        setMovement: setMovementProvider,
        setRetroactively: setRetroactivelyProvider,
        setReferenceNo: setReferenceNoProvider,
        setCumulation: setCumulationProvider,
        setBack2Back: setBack2BackProvider,
        setThirdCountry: setThirdCountryProvider
    } = useContext(InvoiceWordContext);
    const [label, setLabel] = useState(labelProvider);
    const [place, setPlace] = useState(placeProvider);
    const [userInput, setUserInput] = useState(userInputProvider);
    const [vesselName, setVesselName] = useState(vesselNameProvider);
    const [rowPerPage, setRowPerPage] = useState(rowPerPageProvider);
    const [markPackage, setMarkPackage] = useState(markPackageProvider);
    const [portDischarge, setPortDischarge] = useState(portDischargeProvider);
    const [companyExporter, setCompanyExporter] = useState('');
    const [companyConsignee, setCompanyConsignee] = useState('');
    const [stepFieldState, setStepFieldState] = useState<FieldStateInterface>(fieldStateProvider);
    const [stepWordFormType, setStepWordFormType] = useState<FormTypeEnum>(wordFormTypeProvider);
    const [departureDate, setDepartureDate] = useState<Date | null>(GetFirstDay(new Date()));
    const [retroactively, setRetroactively] = useState(retroactivelyProvider);
    const [movement, setMovement] = useState(movementProvider);
    const [exhibition, setExhibition] = useState(exhibitionProvider);
    const [thirdParty, setThirdParty] = useState(thirdPartyProvider);
    const [userInput2, setUserInput2] = useState<string | null>(userInput2Provider);
    const [referenceNo, setReferenceNo] = useState<string | null>(referenceNoProvider);
    const [back2back, setBack2Back] = useState(back2backProvider);
    const [cumulation, setCumulation] = useState(cumulationProvider);
    const [thirdCountry, setThirdCountry] = useState(thirdCountryProvider);
    const [gw, setGW] = useState(gwProvider);

    const wordFormList: wordFormListInterface[] = useMemo(() => {
        return [{ title: FormTypeEnum.AC }, { title: FormTypeEnum.AI }];
    }, []);

    const listRowPerPage = useMemo(() => {
        switch (stepWordFormType) {
            case FormTypeEnum.AC:
                return [1, 2, 3, 4, 5];
            case FormTypeEnum.VJ:
                return [1, 2, 3, 4, 5, 6, 7];
            case FormTypeEnum.AI:
                return [1, 2, 3, 4, 5, 6, 7];
            default:
                return [1, 2, 3, 4, 5];
        }
    }, [stepWordFormType]);

    function SwitchHandler(index: string) {
        setStepFieldState((prev: FieldStateInterface) => {
            const newValue = { ...prev };
            //@ts-ignore
            newValue[index].isHidden = !newValue[index].isHidden;
            return newValue;
        });
    }

    function PlaceHandler(event: any) {
        const placeInput: string = event.target.value;
        // if (placeInput.toUpperCase().trim() === 'HO CHI MINH CITY' || placeInput.toUpperCase().trim() === 'HO CHI MINH') {
        //     const currentTime = new Date();
        //     setPlace(event.target.value + ', VIET NAM ' + ConvertDate(currentTime));
        // } else setPlace(event.target.value);
        const currentTime = new Date();
        setPlace(event.target.value.trim() + ' ' + ConvertDate(currentTime, 'top'));
    }

    useEffect(() => {
        const timeout = setTimeout(() => {
            setPlaceProvider(place.toUpperCase());
            setLabelProvider(label.toUpperCase());
            setFieldState(stepFieldState);
            setUserInputProvider(userInput.toUpperCase());
            setVesselNameProvider(vesselName.toUpperCase());
            setWordFormType(stepWordFormType);
            setRowPerPageProvider(rowPerPage);
            setMarkPackageProvider(markPackage);
            setPortDischargesetProvider(portDischarge.toUpperCase());
            setDepartureDateProvider(ConvertDate(departureDate ?? new Date(), 'top').toUpperCase());
            setRetroactivelyProvider(retroactively);
            setExhibitionProvider(exhibition);
            setMovementProvider(movement);
            setThirdPartyProvider(thirdParty);
            setUserInput2Provider(userInput2);
            setReferenceNoProvider(referenceNo);
            setGWProvider(gw);
            setBack2BackProvider(back2back);
            setCumulationProvider(cumulation);
            setThirdCountryProvider(thirdCountry);
            if (companyExporter.length !== 0) {
                const splitCompany = companyExporter.split('/');
                const newValue: ExporterCompanyInterface = {
                    company_exporter_name: splitCompany[0].toUpperCase(),
                    company_exporter_address: splitCompany[1].toUpperCase()
                };
                setCompanyExporterProvider(newValue);
            }
            if (companyConsignee.length !== 0) {
                const splitCompany = companyConsignee.split('/');
                const newValue: ConsigneeCompanyInterface = {
                    company_consignee_name: splitCompany[0].toUpperCase(),
                    company_consignee_address: splitCompany[1].toUpperCase()
                };
                setCompanyConsigneeProvider(newValue);
            }
            showSnackBar('Submit success', SnackBarAlertEnum.success);
        }, 300);

        return () => clearTimeout(timeout);
    }, [
        label,
        place,
        userInput,
        userInput2,
        vesselName,
        rowPerPage,
        markPackage,
        portDischarge,
        fieldStateProvider,
        wordFormTypeProvider,
        departureDate,
        retroactively,
        movement,
        exhibition,
        thirdParty,
        referenceNo,
        stepFieldState,
        stepWordFormType,
        companyExporter,
        companyConsignee,
        gw,
        thirdCountry,
        cumulation,
        back2back
    ]);

    const listCompanies = useMemo(() => {
        if (company) {
            return company.map((data) => data);
        } else return [];
    }, [company]);

    const Section13 = useCallback(() => {
        switch (stepWordFormType) {
            case FormTypeEnum.AC:
                return (
                    <Box sx={{ display: 'flex', flexDirection: 'column', border: '1px solid black', borderRadius: '8px', p: 2 }}>
                        <h2>13. </h2>
                        <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                                <Checkbox
                                    checked={retroactively}
                                    onClick={() => {
                                        setRetroactively((prev) => !prev);
                                    }}
                                />
                                <p>Issued Retroactively</p>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                                <Checkbox
                                    checked={exhibition}
                                    onChange={() => {
                                        setExhibition((prev) => !prev);
                                    }}
                                />
                                <p>Exhibition</p>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                                <Checkbox
                                    checked={movement}
                                    onChange={() => {
                                        setMovement((prev) => !prev);
                                    }}
                                />
                                <p>Movement Certificate</p>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                                <Checkbox
                                    checked={thirdParty}
                                    onChange={() => {
                                        setThirdParty((prev) => !prev);
                                    }}
                                />
                                <p>Third Party Invoicing</p>
                            </Box>
                        </Box>
                    </Box>
                );

            // case FormTypeEnum.AJ:
            //     return (
            //         <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)' }}>
            //             <Box sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
            //                 <Checkbox
            //                     checked={retroactively}
            //                     onChange={() => {
            //                         setRetroactively((prev) => !prev);
            //                     }}
            //                 />
            //                 <p>Issued Retroactively</p>
            //             </Box>
            //             <Box sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
            //                 <Checkbox
            //                     checked={exhibition}
            //                     onChange={() => {
            //                         setBack2Back((prev) => !prev);
            //                     }}
            //                 />
            //                 <p>Back to Back CO</p>
            //             </Box>
            //             <Box sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
            //                 <Checkbox
            //                     checked={thirdCountry}
            //                     onChange={() => {
            //                         setThirdCountry((prev) => !prev);
            //                     }}
            //                 />
            //                 <p>Third Country Invoicing</p>
            //             </Box>
            //         </Box>
            //     );

            case FormTypeEnum.VJ:
                return <></>;
            case FormTypeEnum.AI:
                return (
                    <Box sx={{ display: 'flex', flexDirection: 'column', border: '1px solid black', borderRadius: '8px', p: 2 }}>
                        <h3>13. </h3>
                        <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                                <Checkbox
                                    checked={back2back}
                                    onClick={() => {
                                        setBack2Back((prev) => !prev);
                                    }}
                                />
                                <p>Back to back</p>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                                <Checkbox
                                    checked={exhibition}
                                    onChange={() => {
                                        setExhibition((prev) => !prev);
                                    }}
                                />
                                <p>Exhibition</p>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                                <Checkbox
                                    checked={cumulation}
                                    onChange={() => {
                                        setCumulation((prev) => !prev);
                                    }}
                                />
                                <p>Cumulation</p>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                                <Checkbox
                                    checked={thirdCountry}
                                    onChange={() => {
                                        setThirdCountry((prev) => !prev);
                                    }}
                                />
                                <p>Third Country Invoicing</p>
                            </Box>
                        </Box>
                    </Box>
                );
            default:
                return <></>;
        }
    }, [stepWordFormType, retroactively, exhibition, thirdParty, movement, cumulation, back2back, thirdCountry]);

    const RowPerPage = useCallback(() => {
        return (
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <h2>Please confirm number per/page from 0-10 (defalut: 5)</h2>
                <Select
                    sx={{ width: '200px' }}
                    id="select_row_per_page"
                    value={rowPerPage}
                    onChange={(e) => {
                        setRowPerPage(e.target.value);
                    }}
                >
                    {listRowPerPage.map((item, index) => (
                        <MenuItem key={index} value={item}>
                            {item}
                        </MenuItem>
                    ))}
                </Select>
                {rowPerPage == '' && <p style={{ color: 'red' }}>This field is required</p>}
            </Box>
        );
    }, [stepWordFormType, rowPerPage]);

    return (
        <Box sx={{ width: '100%', height: '100%', display: 'flex' }}>
            <Box
                sx={{
                    flex: '1',
                    overflowY: 'scroll',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px',
                    paddingBottom: '18px'
                }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', border: '1px solid black', borderRadius: '8px', p: 2 }}>
                    <h2>External Input</h2>

                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <h3>Reference No: </h3>
                        <TextField
                            onChange={(e) => {
                                setReferenceNo(e.target.value);
                            }}
                        />
                    </Box>

                    {stepWordFormType !== FormTypeEnum.AC && (
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <h3>GW: </h3>
                            <TextField
                                type="number"
                                onChange={(e) => {
                                    setGW(e.target.value);
                                }}
                            />
                        </Box>
                    )}

                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <h2>Please select your form: </h2>
                        <Select
                            id="select_word_form_type"
                            value={stepWordFormType}
                            onChange={(e) => {
                                setRowPerPage('');
                                setStepWordFormType(e.target.value as FormTypeEnum);
                            }}
                        >
                            {wordFormList.map((form: wordFormListInterface, index) => (
                                <MenuItem key={index + form.title} value={form.title}>
                                    {ConvertFormName(form.title)}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>

                    <RowPerPage />

                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <h2>Please choose to display fields</h2>
                        <FormGroup sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)' }}>
                            {stepFieldState &&
                                Object.entries(stepFieldState).map(([key, value], index) => {
                                    return (
                                        <FormControlLabel
                                            sx={{ fontSize: '12px' }}
                                            key={key + index}
                                            control={
                                                <Switch
                                                    defaultChecked
                                                    onChange={() => {
                                                        SwitchHandler(key);
                                                    }}
                                                />
                                            }
                                            label={key}
                                        />
                                    );
                                })}
                        </FormGroup>
                    </Box>
                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'column', border: '1px solid black', borderRadius: '8px', p: 2 }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <h2>{formLabel01}</h2>
                        <Select
                            value={companyExporter}
                            onChange={(e) => {
                                setCompanyExporter(e.target.value);
                            }}
                        >
                            {listCompanies.map((company: ICompanySetting, index) => {
                                return (
                                    <MenuItem
                                        key={company.tax_code + index}
                                        sx={{ display: 'flex', alignItems: 'center' }}
                                        value={company.name + '/' + company.address}
                                    >
                                        <div style={{ fontWeight: 'bold' }}>{company.name}</div>
                                        <div>{company.address}</div>
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', border: '1px solid black', borderRadius: '8px', p: 2 }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <h2>{formLabel02}</h2>
                        <Select
                            value={companyConsignee}
                            onChange={(e) => {
                                setCompanyConsignee(e.target.value);
                            }}
                        >
                            {listCompanies.map((company: ICompanySetting, index) => {
                                return (
                                    <MenuItem
                                        key={company.tax_code + index}
                                        sx={{ display: 'flex', alignItems: 'center' }}
                                        value={company.name + '/' + company.address}
                                    >
                                        <div style={{ fontWeight: 'bold' }}>{company.name}</div>
                                        <div>{company.address}</div>
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', border: '1px solid black', borderRadius: '8px', p: 2 }}>
                    <h2>3. Means of transport and route( as far as know)</h2>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <h3>Label</h3>
                        <TextField
                            multiline
                            value={label}
                            onChange={(e) => {
                                setLabel(e.target.value);
                            }}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <h3>Depature date</h3>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                renderInput={(props) => <TextField sx={{ width: 200 }} {...props} />}
                                value={departureDate}
                                onChange={(e) => {
                                    setDepartureDate(e);
                                }}
                            />
                        </LocalizationProvider>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <h3>Vessel's name/Aircraft etc. </h3>
                        <TextField
                            value={vesselName}
                            onChange={(e) => {
                                setVesselName(e.target.value);
                            }}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <h3>Port of Dischange</h3>
                        <TextField
                            value={portDischarge}
                            onChange={(e) => {
                                setPortDischarge(e.target.value);
                            }}
                        />
                    </Box>
                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'column', border: '1px solid black', borderRadius: '8px', p: 2 }}>
                    <h2>6. Marks and numbers on packages</h2>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <TextField
                            value={markPackage}
                            placeholder={markPackage == '' ? 'This field is required' : ''}
                            onChange={(e) => {
                                setMarkPackage(e.target.value);
                            }}
                        />
                    </Box>
                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'column', border: '1px solid black', borderRadius: '8px', p: 2 }}>
                    <h2>7. Number & type of packages,...</h2>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <p>
                            Please insert user input <strong>TOP</strong> at Section 7 (optional)
                        </p>
                        <TextField
                            multiline
                            onChange={(e) => {
                                setUserInput(e.target.value);
                            }}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <p>
                            Please insert user input <strong>BOTTOM</strong> at Section 7 (optional)
                        </p>
                        <TextField
                            multiline
                            onChange={(e) => {
                                setUserInput2(e.target.value);
                            }}
                        />
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', border: '1px solid black', borderRadius: '8px', p: 2 }}>
                    <h2>11. Declaration by the exporter</h2>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <p>Place</p>
                        <TextField onChange={PlaceHandler} />
                    </Box>
                </Box>

                <Section13 />
            </Box>

            <Box sx={{ flex: '2', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '90%' }}>
                <img style={{ width: '90%', height: '70%', borderRadius: '4px' }} src={img} alt="co_form_bg_01" />
            </Box>
        </Box>
    );
}

export default InvoiceWordStep1;
