import { HotTable } from '@handsontable/react';
import { Button, Chip } from '@mui/material';
import { Sheet } from 'components';
import { RowObject } from 'handsontable/common';
import produce from 'immer';
import _ from 'lodash';
import { ResultState, resultGuard } from 'models';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { match } from 'ts-pattern';
import { CompanyContext } from '../../contexts';
import { AsyncValue, ConnectionState } from '../../hooks/Async';
import { setLoading } from '../../store/Global';
import { removeDiacritics } from '../../utils';
import { SpreadSheetContext } from './context';

export const SheetHeaderMapping = () => {
    const [tableData, setTableData] = useState<RowObject[]>([[], []]);
    const { submit, submitDisabled, selectedHeaderMap, colsAsyncValue, header, headerTitle, fixedHeader } = useViewModel({
        tableData
    });
    const ref = useRef<HotTable | undefined>();

    return (
        <div className={'tw-relative tw-w-full tw-h-full tw-overflow-visible'}>
            {!_.isEmpty(fixedHeader) && (
                <div className="tw-flex-wrap">
                    {fixedHeader!.map((chip) => {
                        return (
                            <Chip
                                color={selectedHeaderMap[chip] ? 'primary' : undefined}
                                className={'tw-mr-4 tw-mb-2'}
                                key={chip}
                                label={chip}
                            />
                        );
                    })}
                </div>
            )}
            {match(colsAsyncValue)
                .with({ state: ConnectionState.hasData }, ({ data: columns }) => {
                    return (
                        <Sheet
                            height={320}
                            width={'auto'}
                            ref={ref}
                            afterChange={(data) => {
                                const tableValues = ref.current?.hotInstance?.getData();
                                if (!tableValues) return;
                                setTableData(tableValues);
                            }}
                            rowHeaders={['Required header', 'Header title']}
                            rowHeaderWidth={200}
                            manualColumnResize
                            columns={columns}
                            data={tableData}
                            className={'tw-left-0 tw-right-0 tw-top-0 tw-bottom-0'}
                            colHeaders={header!.map((title) => {
                                return title
                                    .split('|')
                                    .filter((word) => !word.includes('Unnamed'))
                                    .join("<hr class='tw-border-1 tw-my-2 tw-border-gray-400'>");
                            })}
                        ></Sheet>
                    );
                })
                .otherwise(() => (
                    <></>
                ))}
            <div className={'tw-flex-row tw-flex tw-justify-end'}>
                <Button disabled={submitDisabled} variant={'contained'} onClick={submit}>
                    SUBMIT
                </Button>
            </div>
        </div>
    );
};

const useViewModel = ({ tableData }: { tableData: RowObject[] }) => {
    const { getFixedHeader, header, setupImport, setSetupSuccessful } = useContext(SpreadSheetContext);
    const [colsAsyncValue, setColsAsyncValue] = useState<
        AsyncValue<
            {
                type: string;
                source: string[];
            }[]
        >
    >({ state: ConnectionState.waiting });
    const [fixedHeader, setFixedHeader] = useState<string[]>([]);
    const dispatch = useDispatch();
    const headerTitle = useMemo(() => {
        if (!header) return [];
        return header!.map((title, index) => {
            if (index >= header.length) return '';
            return title;
        });
    }, [header]);

    const selectedHeaderMap = useMemo(() => {
        return produce<{ [key: string]: boolean }>({}, (draft) => {
            for (const value of _.flatten(tableData)) {
                if (!_.isEmpty(value)) draft[value] = true;
            }
        });
    }, [tableData]);

    const fetch = async () => {
        setColsAsyncValue({ state: ConnectionState.waiting });
        const result = await getFixedHeader();
        match(result)
            .with({ state: ResultState.success }, ({ data }) => {
                const columns = header!.map((_) => {
                    return {
                        type: 'dropdown',
                        source: data,
                        strict: false,
                        validator: false
                    };
                });
                setColsAsyncValue({ state: ConnectionState.hasData, data: columns });
                setFixedHeader(data);
            })
            .with({ state: ResultState.failed }, ({ exception }) => {
                setColsAsyncValue({ state: ConnectionState.hasError, error: exception });
            })
            .exhaustive();
    };

    useEffect(() => {
        fetch();
    }, []);

    const getPayload = useCallback(() => {
        if (!header) return {};
        const tuples = _.zip<string | undefined, string | undefined, string>(_.get(tableData, '0'), _.get(tableData, '1'), header);

        const result = produce<{ [key: string]: any }>({ extra_fields: {} }, (draft) => {
            for (const tuple of tuples) {
                const [key, title, mapping_col_name] = tuple;

                const entry = {
                    mapping_col_name,
                    title: title ?? mapping_col_name
                };
                if (_.isNil(key)) {
                    const key = mapping_col_name!
                        .split('|')
                        .filter((word) => !word.includes('Unnamed'))
                        .map((word: string) => removeDiacritics(word.replaceAll(' ', '_')))
                        .join('_');
                    draft.extra_fields[key] = entry;
                } else {
                    draft[key] = entry;
                }
            }
            if (_.isEmpty(draft.extra_fields)) delete draft.extra_fields;
        });
        return result;
    }, [header, tableData]);

    const submitDisabled = useMemo(() => {
        return _.keys(selectedHeaderMap).length < fixedHeader?.length;
    }, [selectedHeaderMap, fixedHeader]);

    const { companyDetail } = useContext(CompanyContext);

    const submit = async () => {
        const payload = getPayload();
        dispatch(setLoading(true));
        if (!companyDetail) throw Error('Company is required');
        const result = await resultGuard(() => setupImport({ ...payload, company_slug: companyDetail.slug }));
        match(result)
            .with(
                {
                    state: ResultState.success
                },
                () => {
                    setSetupSuccessful(true);
                }
            )
            .with(
                {
                    state: ResultState.failed
                },
                () => {
                    setSetupSuccessful(false);
                }
            )
            .exhaustive();
        dispatch(setLoading(false));
    };

    return {
        header,
        selectedHeaderMap,
        colsAsyncValue,
        headerTitle,
        fixedHeader,
        submitDisabled,
        submit
    };
};
