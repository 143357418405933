import { useCompanyFolderSlug } from 'components/BalanceImport';
import { PhysicalCountingEnum, Statement15ATypeCodeEnum, Statement15TypeCodeEnum, StatementTypeEnum } from 'components/BalanceTable';
import { SnackBarAlertEnum } from 'components/SnackBar';
import useSnackBar from 'components/SnackBar/UISnackbar';
import { useStatement15ARepo, useStatement15Repo } from 'di';
import { Statement15AData } from 'models';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { beginLogicParams } from 'repository/reportStatement15A/type';
import { setLoading } from 'store/Global';

export enum BeginParamEnum {
    e11 = 'E11',
    e21 = 'E21',
    e31 = 'E31',
    e42 = 'E42',
    e52 = 'E52',
    e62 = 'E62',
    acc = 'accounting',
    wh = 'warehouse',
    begin = 'begin',
    takein = 'take_in',
    takeout = 'take_out',
    missing = 'missing_code',
    inventory = 'inventory'
}

export interface MergeCells {
    row: number;
    col: number;
    rowspan: number;
    colspan: number;
}

export interface xlxsItem {
    data: any[];
    mergeCells: MergeCells[];
    colWidths?: number[];
}

function ConvertBeginParam({
    statement15TypeCode,
    statement15ATypeCode,
    physicalCounting
}: {
    statement15TypeCode: string;
    statement15ATypeCode: string;
    physicalCounting: string;
}) {
    let rev15TypeCode = statement15TypeCode;
    let rev15ATypeCode = statement15ATypeCode;
    let revPhysical = physicalCounting;

    if (statement15TypeCode === Statement15TypeCodeEnum.e11) rev15TypeCode = BeginParamEnum.e11;
    else if (statement15TypeCode === Statement15TypeCodeEnum.e21) rev15TypeCode = BeginParamEnum.e21;
    else rev15TypeCode = BeginParamEnum.e31;

    if (statement15ATypeCode === Statement15ATypeCodeEnum.e42) rev15ATypeCode = BeginParamEnum.e42;
    else if (statement15TypeCode === Statement15ATypeCodeEnum.e52) rev15ATypeCode = BeginParamEnum.e52;
    else rev15ATypeCode = BeginParamEnum.e62;

    if (physicalCounting === PhysicalCountingEnum.acc) revPhysical = BeginParamEnum.acc;
    else revPhysical = BeginParamEnum.wh;

    return { rev15TypeCode, rev15ATypeCode, revPhysical };
}

function MapBCQT15A(results: Statement15AData[]) {
    return results.map((item: Statement15AData, index: number) => {
        const { code, name, unit, begin_quantity, import_value, re_purpose, exportt, other_export, end_quantity } = item;
        return [index, code, name, unit, begin_quantity, import_value, re_purpose, exportt, other_export, end_quantity];
    });
}

// function MapBCQT15(results: Statement15Data[]) {
//     return results.map((item: Statement15Data, index: number) => {
//         const { code, name, unit, begin_quantity, import_value, re_purpose, exportt, other_export, end_quantity } = item;
//         return [index, code, name, unit, begin_quantity, import_value, re_purpose, exportt, other_export, end_quantity];
//     });
// }

function useGenerateExcel({
    statementType,
    statement15TypeCode,
    statement15ATypeCode,
    physicalCounting
}: {
    statementType: string;
    statement15TypeCode: string;
    statement15ATypeCode: string;
    physicalCounting: string;
}) {
    const { company_slug, folder_slug } = useCompanyFolderSlug();
    const dispatch = useDispatch();
    const { showSnackBar } = useSnackBar();
    const statement15Repo = useStatement15Repo();
    const statement15ARepo = useStatement15ARepo();

    const [rawBCQT15, setRawBCQT15] = useState<null | any[][]>(null);
    const [rawBCQT15A, setRawBCQT15A] = useState<null | any[][]>(null);
    const [BCQT, setBcqt] = useState<null | xlxsItem>(null);

    async function FetchData() {
        dispatch(setLoading(true));
        const response = await getAll();
        const { results } = response;
        if (!results) {
            showSnackBar('Empty Data', SnackBarAlertEnum.warning);
            return;
        } else {
            if (statementType === StatementTypeEnum.statement15) {
                // setRawBCQT15(results as Statement15Data[]);
            } else {
                setRawBCQT15A(MapBCQT15A(results as Statement15AData[]));
            }
        }

        dispatch(setLoading(false));
    }

    const getAll = useCallback(async () => {
        if (statementType === StatementTypeEnum.statement15) return statement15Repo.getAll({ company_slug, folder_slug });
        else return statement15ARepo.getAll({ company_slug, folder_slug });
    }, [statementType]);

    useEffect(() => {
        FetchData();
        setRawBCQT15(null);
        setRawBCQT15A(null);
    }, [statementType]);

    const bcqtFixedHeader = useMemo(() => {
        return [
            [
                'STT',
                'Mã sản phẩm xuất khẩu',
                'Tên sản phẩm xuất khẩu',
                'Đơn vị tính',
                'Lượng sản phẩm tồn kho đầu kỳ',
                'Lượng sản phẩm nhập kho trong kỳ',
                'Lượng sản phẩm xuất kho trong kỳ',
                null,
                null,
                'Lượng sản phẩm tồn kho cuối kỳ theo sổ sách theo dõi',
                'Ghi chú'
            ],
            [
                null,
                null,
                null,
                null,
                null,
                null,
                'Lượng sản phẩm thay đổi mục đích sử dụng, chuyển tiêu thụ nội địa',
                'Lương sản phẩm xuất khẩu',
                'Xuất kho khác',
                null,
                null
            ],
            ['1', '2', '3', '4', '5', '6', '7', '8', '9', '(10) =(5)+(6)-(7)-(8)- (9)', '11']
        ];
    }, [statementType]);
    const bcqtMergeCell = useMemo(() => {
        return [
            { row: 0, col: 0, rowspan: 2, colspan: 1 },
            { row: 0, col: 1, rowspan: 2, colspan: 1 },
            { row: 0, col: 2, rowspan: 2, colspan: 1 },
            { row: 0, col: 3, rowspan: 2, colspan: 1 },
            { row: 0, col: 4, rowspan: 2, colspan: 1 },
            { row: 0, col: 5, rowspan: 2, colspan: 1 },
            { row: 0, col: 6, rowspan: 1, colspan: 3 },
            { row: 0, col: 9, rowspan: 2, colspan: 1 },
            { row: 0, col: 10, rowspan: 2, colspan: 1 }
        ];
    }, [statementType]);

    const beginQueryParams = useMemo(() => {
        const { rev15TypeCode, rev15ATypeCode, revPhysical } = ConvertBeginParam({
            statement15ATypeCode,
            physicalCounting,
            statement15TypeCode
        });
        const item: beginLogicParams = {
            company_slug,
            folder_slug,
            data_type: revPhysical,
            option_type: 'begin',
            type_code: statementType === StatementTypeEnum.statement15 ? [rev15TypeCode] : [rev15ATypeCode]
        };
        return item;
    }, [statement15TypeCode, statement15ATypeCode, physicalCounting, company_slug, folder_slug]);

    const TakeinQueryParams = useMemo(() => {
        const { rev15TypeCode, rev15ATypeCode, revPhysical } = ConvertBeginParam({
            statement15ATypeCode,
            physicalCounting,
            statement15TypeCode
        });
        const item: beginLogicParams = {
            company_slug,
            folder_slug,
            data_type: revPhysical,
            option_type: 'take_in',
            type_code: statementType === StatementTypeEnum.statement15 ? [rev15TypeCode] : [rev15ATypeCode]
        };
        return item;
    }, [statement15TypeCode, statement15ATypeCode, physicalCounting, company_slug, folder_slug]);

    const TakeoutQueryParams = useMemo(() => {
        const { rev15TypeCode, rev15ATypeCode, revPhysical } = ConvertBeginParam({
            statement15ATypeCode,
            physicalCounting,
            statement15TypeCode
        });
        const item: beginLogicParams = {
            company_slug,
            folder_slug,
            data_type: revPhysical,
            option_type: 'take_out',
            type_code: statementType === StatementTypeEnum.statement15 ? [rev15TypeCode] : [rev15ATypeCode]
        };
        return item;
    }, [statement15TypeCode, statement15ATypeCode, physicalCounting, company_slug, folder_slug]);

    const inventoryQueryParams = useMemo(() => {
        const { rev15TypeCode, rev15ATypeCode, revPhysical } = ConvertBeginParam({
            statement15ATypeCode,
            physicalCounting,
            statement15TypeCode
        });
        const item: beginLogicParams = {
            company_slug,
            folder_slug,
            data_type: revPhysical,
            option_type: 'inventory',
            type_code: statementType === StatementTypeEnum.statement15 ? [rev15TypeCode] : [rev15ATypeCode]
        };
        return item;
    }, [statement15TypeCode, statement15ATypeCode, physicalCounting, company_slug, folder_slug]);

    const missingCodeQueryParams = useMemo(() => {
        const { rev15TypeCode, rev15ATypeCode, revPhysical } = ConvertBeginParam({
            statement15ATypeCode,
            physicalCounting,
            statement15TypeCode
        });
        const item: beginLogicParams = {
            company_slug,
            folder_slug,
            data_type: revPhysical,
            option_type: 'missing_code',
            type_code: statementType === StatementTypeEnum.statement15 ? [rev15TypeCode] : [rev15ATypeCode]
        };
        return item;
    }, [statement15TypeCode, statement15ATypeCode, physicalCounting, company_slug, folder_slug]);

    const bcqtItem = useMemo(() => {
        if (statementType === StatementTypeEnum.statement15) {
            return null;
        }
        if (statementType === StatementTypeEnum.statement15A && rawBCQT15A) {
            const item: xlxsItem = {
                data: [...bcqtFixedHeader, ...rawBCQT15A],
                mergeCells: bcqtMergeCell
            };
            return item;
        }
    }, [statementType, rawBCQT15A, rawBCQT15]);

    useEffect(() => {
        if (bcqtItem) setBcqt(bcqtItem);
    }, [bcqtItem]);

    // const FetchBCQT = useCallback(async () => {
    //     const response = await getBcqt();
    //     match(response)
    //         .with({ state: ResultState.success }, () => {})
    //         .with({ state: ResultState.failed }, () => {}).exhaustive;
    // }, [getBcqt]);

    // const FetchBegin = useCallback(async () => {
    //     const response = await getBegin();
    //     match(response)
    //         .with({ state: ResultState.success }, () => {})
    //         .with({ state: ResultState.failed }, () => {}).exhaustive;
    // }, [getBegin]);

    // const FetchTakein = useCallback(async () => {
    //     const response = await getTakein();
    //     match(response)
    //         .with({ state: ResultState.success }, () => {})
    //         .with({ state: ResultState.failed }, () => {}).exhaustive;
    // }, [getTakein]);

    // const FetchTakeout = useCallback(async () => {
    //     const response = await getTakeout();
    //     match(response)
    //         .with({ state: ResultState.success }, () => {})
    //         .with({ state: ResultState.failed }, () => {}).exhaustive;
    // }, [getTakeout]);

    // const FetchInventory = useCallback(async () => {
    //     const response = await getInventory();
    //     match(response)
    //         .with({ state: ResultState.success }, () => {})
    //         .with({ state: ResultState.failed }, () => {}).exhaustive;
    // }, [getInventory]);

    // const FetchMissingCode = useCallback(async () => {
    //     const response = await getMissingCode();
    //     match(response)
    //         .with({ state: ResultState.success }, () => {})
    //         .with({ state: ResultState.failed }, () => {}).exhaustive;
    // }, [getMissingCode]);

    // useEffect(() => {
    //     FetchBCQT();
    //     FetchBegin();
    //     FetchTakein();
    //     FetchTakeout();
    //     FetchInventory();
    //     FetchMissingCode();
    // }, [getBcqt, getBegin, getTakein, getTakeout, getInventory, getMissingCode]);
    return { BCQT };
}

export default useGenerateExcel;
