import { Typography } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from 'store/Global';
const ToastNotification = (props: any) => {
    const { message } = props;
    const { loading } = useSelector((state: any) => state.Global);
    const dispatch = useDispatch();
    useEffect(() => {
        loading && dispatch(setLoading(false));
    }, [dispatch, loading]);
    if (message && Array.isArray(message))
        return (
            <div>
                {message.map((e, i) => (
                    <Typography paragraph key={i}>
                        {e}
                    </Typography>
                ))}
            </div>
        );
    if (message && !Array.isArray(message) && typeof message === 'string') return <div>{message}</div>;
    return <div>Server Error</div>;
};
export default ToastNotification;
