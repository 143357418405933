import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { FieldStateInterface, FormTypeEnum, InvoiceWordContext } from 'components/BalanceTable';
import { ReactElement, useContext, useMemo } from 'react';
import { CheckPropertyInterface } from '../../TableInvoiceWord';
import { InvoiceModalContext } from '../InvoiceModalProvider';

export default function InvoiceWordStep() {
    const { activeStep, fieldState, wordFormType, setActiveStep, setFieldState } = useContext(InvoiceWordContext);
    const { revertData } = useContext(InvoiceModalContext);

    const steps = useMemo(() => {
        if (wordFormType === FormTypeEnum.VJ || wordFormType === FormTypeEnum.AJ) return ['Step 1', 'Step 2', 'Step 3'];
        else return ['Step 1', 'Step 2'];
    }, [wordFormType]);

    // function getFormVJStep(activeStep: number, fieldState: FieldStateInterface | null, revertData: CheckPropertyInterface[] | null) {
    //     let result = true;

    //     if (activeStep === 0) {
    //         if (fieldState) result = false;
    //     } else if (activeStep === 1) {
    //         if (revertData) result = false;
    //     }
    //     console.log(result, revertData, fieldState, activeStep);
    //     return result;
    // }

    // function getFormACStep(activeStep: number, fieldState: FieldStateInterface | null) {
    //     let result = true;
    //     if (activeStep === 0) {
    //         if (fieldState) result = false;
    //     }
    //     console.log(result, fieldState, activeStep);
    //     return result;
    // }

    // const stepDisable = useMemo(() => {
    //     // switch (wordFormType) {
    //     //     case FormTypeEnum.AJ || FormTypeEnum.VJ:
    //     //         return getFormVJStep(activeStep, fieldState, revertData);
    //     //     default:
    //     //         return getFormACStep(activeStep, fieldState);
    //     // }
    //     let result = true;
    //     if (activeStep === 0) {
    //         if (fieldState) result = false;
    //     }

    //     if (activeStep === 1 && revertData) result = false;

    //     return result;
    // }, [activeStep, fieldState, wordFormType, revertData]);

    return (
        <Box sx={{ width: '80%' }}>
            <Stepper activeStep={activeStep} sx={{ my: '12px' }}>
                {steps.map((label, index) => {
                    const stepProps: { completed?: boolean } = {};
                    const labelProps: {
                        optional?: ReactElement;
                    } = {};

                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
        </Box>
    );
}
