import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import Zoom from '@mui/material/Zoom';

export interface IToolTipProps {
    title: string;
    children: React.ReactElement;
    placement?: ToolTipPlacementEnum;
}

export enum ToolTipPlacementEnum {
    top = 'top',
    bottom = 'bottom',
    left = 'left',
    right = 'right'
}

export function ToolTip(props: IToolTipProps) {
    return (
        <Tooltip
            title={props.title}
            TransitionComponent={Zoom}
            placement={props.placement ?? ToolTipPlacementEnum.top}
            PopperProps={{
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, -12]
                        }
                    }
                ]
            }}
        >
            {props.children}
        </Tooltip>
    );
}
