export const ProjectTheme = {
    colors: {
        primary: '#092C4C',
        secondary: '#F2994A',
        warning: '#E2B93B',
        error: '#EB5757',
        purple: '#3E66FB',

        gray1: '#3E3E3E',
        gray2: '#4F4F4F',
        gray3: '#979BA0',
        gray4: '#E0E1E4',
        gray5: '#F2F4F8',

        black1: '#000000',
        black2: '#1D1D1D',
        black3: '#282828',

        sideBarItemHover: '#ffffff26',
        sideBarTextHover: '#ffffff'
    }
};

export type ProjectThemeProps = {
    [key: string]: any;
};
