import { JsonObject, JsonProperty } from 'json2typescript';
import { JsonSerializable } from '../serialization';
export interface ITakeoutAccountingData {
    id: string;
    code: string;
    name: string;
    unit: string;
    warehouse_name: string;
    output_date: string;
    bill_date: string;
    doc_number: string;
    bill_no: string;
    explain: string;
    created_at: string;
    unit_price: number;
    export_quantity: number;
    export_value: number;
    balance_id: number;
    company_slug_id: number;
}

@JsonObject('TakeoutAccountingData')
export class TakeoutAccountingData extends JsonSerializable<TakeoutAccountingData> implements ITakeoutAccountingData {
    protected get classRef(): new () => TakeoutAccountingData {
        return TakeoutAccountingData;
    }

    @JsonProperty('id', String, false)
    id!: string;

    @JsonProperty('code', String, false)
    code!: string;

    @JsonProperty('name', String, true)
    name: string = '';

    @JsonProperty('unit', String, true)
    unit: string = '';

    @JsonProperty('warehouse_name', String, true)
    warehouse_name: string = '';

    @JsonProperty('output_date', String, true)
    output_date: string = '';

    @JsonProperty('bill_date', String, true)
    bill_date: string = '';

    @JsonProperty('doc_number', String, true)
    doc_number: string = '';

    @JsonProperty('bill_no', String, true)
    bill_no: string = '';

    @JsonProperty('explain', String, true)
    explain: string = '';

    @JsonProperty('created_at', String, true)
    created_at: string = '';

    @JsonProperty('unit_price', Number, true)
    unit_price: number = 0;

    @JsonProperty('export_quantity', Number, true)
    export_quantity: number = 0;

    @JsonProperty('export_value', Number, true)
    export_value: number = 0;

    @JsonProperty('balance_id', Number, true)
    balance_id: number = 0;

    @JsonProperty('company_slug_id', Number, true)
    company_slug_id: number = 0;
}
