import { IobData, IobSetupDTO, JSONType, Result, resultGuard } from 'models';
import { IobQueryParams } from '../type';
import { IobDataSource } from './IobDataSource';
import {IIobImportDTO, IobImportParams} from './type';
import { AutoFillParams } from 'components';

export class IobRepository {
    constructor(private datasource: IobDataSource) {}
    public async getAutoFillData(data: AutoFillParams) {
        return this.datasource.getAutoFill(data);
    }

    public async getAll(params: IobQueryParams) {
        return this.datasource.getAll(params);
    }

    public async deleteRows(ids: string[]) {
        return this.datasource.delete(ids);
    }

    public async updateRow(row: Partial<IobData>, params: IobQueryParams) {
        return this.datasource.update(row, params);
    }

    public async insertRow(row: JSONType) {
        return this.datasource.insert(row);
    }

    public async importData(value: IobImportParams): Promise<Result<void>> {
        return resultGuard(async () => this.datasource.importData(value));
    }

    public async setupImport(data: { [key: string]: any }) {
        return resultGuard(async () => {
            await this.datasource.setupImport(data);
        });
    }

    public async getFixedHeader(): Promise<Result<string[]>> {
        return resultGuard(async () => {
            return this.datasource.getFixedHeader()
        })
    }
}
