import { AutoFillParams } from 'components/Table';
import { saveAs } from 'file-saver';
import _ from 'lodash';
import { BomData, JSONType } from 'models';
import { AutoFillData } from 'models/AutoFillData/AutoFillData';
import { BomQueryParams } from 'repository/type';
import { BalanceImportDataSource } from '../BalanceImportDataSource';
import { BomImportParams, IBomExportExcel, IBomFix } from './type';

export class BomExwDataSource extends BalanceImportDataSource<BomImportParams> {
    path: string = 'bom_exw';
    importUrl = '/api/balance/bom_exw/calculator_import/';
    setupUrl = '/api/balance/bom_exw/calculator_import_setup/';

    public async getAll(params: BomQueryParams) {
        const response = await this.http.instance.get('/api/balance/bom_exw/', {
            params
        });
        return new BomData().fromPaging<BomData>(response.data);
    }

    public async update(data: Partial<BomData>, params: BomQueryParams) {
        return this.http.instance.put(
            `/api/balance/bom_exw/${data.id}/`,
            _.omit(
                {
                    ...data,
                    ...params
                },
                'id'
            )
        );
    }

    public async delete(ids: string[]) {
        return this.http.instance.delete(`/api/balance/bom_exw/bulk-delete/`, {
            data: { id_list: ids }
        });
    }

    public async insert(data: JSONType) {
        return this.http.instance.post(`/api/balance/bom_exw/`, data);
    }

    public async getAutoFill(data: AutoFillParams) {
        const respone = await this.http.instance.post('/api/balance/bom_exw/autofill/', data);
        return new AutoFillData().fromPaging<AutoFillData>(respone.data);
    }

    public async exportExcel(params: IBomExportExcel) {
        const response = await this.http.instance.get(`/api/balance/bom_exw/`, { params, responseType: 'blob' });
        const blob = new Blob([response.data], { type: response.data.type });
        saveAs(blob, 'bom.xlsx');
    }

    public async fixBom(data: IBomFix) {
        return await this.http.instance.post(`/api/balance/bom_exw/fix/`, data);
    }
}

export class NormalBomExwDataSource extends BalanceImportDataSource<BomImportParams> {
    path: string = 'bom_exw';
}
