import { resultGuard } from 'models';
import { BaseQueryParams } from 'repository/type';
import { EcusStockDataSource } from './EcusStockDataSource';

export class EcusStockRepository {
    constructor(public datasource: EcusStockDataSource) {}

    async getAll(params: BaseQueryParams) {
        return this.datasource.getAll(params);
    }
}
