import { JsonObject, JsonProperty } from 'json2typescript';
import { JsonSerializable } from 'models/serialization';

export interface InvoiceExcelDataInterface {
    id: string;
    material_name: string;
    material_unit: string;
    bom_value: number;
    hs: string;
    from_country: string;
    reference_no: string;
    issued_date: string;
    unit_price: number;
    registered_date: string;
}

@JsonObject('InvoiceExcelData')
export class InvoiceExcelData extends JsonSerializable<InvoiceExcelData> implements InvoiceExcelDataInterface {
    protected get classRef(): new () => InvoiceExcelData {
        return InvoiceExcelData;
    }

    @JsonProperty('id', String, false)
    id!: string;

    @JsonProperty('material_name', String, false)
    material_name!: string;

    @JsonProperty('material_unit', String, false)
    material_unit!: string;

    @JsonProperty('bom_value', Number, false)
    bom_value!: number;

    @JsonProperty('hs', String, false)
    hs!: string;

    @JsonProperty('from_country', String, true)
    from_country: string = '';

    @JsonProperty('reference_no', String, true)
    reference_no: string = '';

    @JsonProperty('issued_date', String, true)
    issued_date: string = '';

    @JsonProperty('unit_price', Number, false)
    unit_price!: number;

    @JsonProperty('registered_date', String, false)
    registered_date!: string;
}
