import { EndingInventoryDataACC, EndingInventorySetupACC, JSONType, Result, resultGuard } from 'models';
import { EndingInventoryQueryParamsACC } from '../type';
import { EndingInventoryDataSourceACC } from './EndingInventoryDataSourceACC';
import { IEndingInventoryImportACC } from './type';
import { AutoFillParams } from 'components';

export class EndingInventoryRepositoryACC {
    constructor(private datasource: EndingInventoryDataSourceACC) {}

    public async getAll(params: EndingInventoryQueryParamsACC) {
        return this.datasource.getAll(params);
    }

    public async deleteRows(ids: string[]) {
        return this.datasource.delete(ids);
    }

    public async updateRow(row: Partial<EndingInventoryDataACC>, params: EndingInventoryQueryParamsACC) {
        return this.datasource.update(row, params);
    }

    public async insertRow(row: JSONType) {
        return this.datasource.insert(row);
    }

    public async importData(value: IEndingInventoryImportACC): Promise<Result<void>> {
        return resultGuard(async () => {
            await this.datasource.importData(value);
        });
    }

    public async setupImport(data: { [key: string]: any }) {
        return resultGuard(async () => {
            await this.datasource.setupImport(data);
        });
    }

    public async getAutoFillData(data: AutoFillParams) {
        return this.datasource.getAutoFill(data);
    }

    public async getFixedHeader(): Promise<Result<string[]>> {
        return resultGuard(async () => {
            return this.datasource.getFixedHeader();
        });
    }
}
