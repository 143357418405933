import { getChapter, getSection, getHeader, getSupHeader } from 'api/HS/HsApi';
import { ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'store';
import { setChapter, setSection, setHeader, setSupHeader } from 'store/slices/HsCode';
import { HsContext } from './HsContext';

export const HsProvider = ({ children }: { children: ReactNode }) => {
    const { Section, Chapter, Header, SupHeader, TitilePage, isSearch, Year, SelectedHs, SearchResult, isProductSearch } = useSelector(
        (state) => state.HsCode
    );
    const dispatch = useDispatch();
    useEffect(() => {
        getSection(Year).then((res: { docs: any }) => dispatch(setSection(res.response.docs)));
        getChapter(Year).then((res: { docs: any }) => dispatch(setChapter(res.response.docs)));
        getHeader(Year).then((res: { docs: any }) => dispatch(setHeader(res.response.docs)));
        getSupHeader(Year).then((res: { docs: any }) => dispatch(setSupHeader(res.response.docs)));
    }, [Year, dispatch]);
    return (
        <HsContext.Provider
            value={{
                Section,
                Chapter,
                Header,
                SupHeader,
                TitilePage,
                isSearch,
                Year,
                SelectedHs,
                SearchResult,
                isProductSearch
            }}
        >
            {children}
        </HsContext.Provider>
    );
};
