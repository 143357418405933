import { OptionOriginEnum, OptionPriceEnum } from 'components/BalanceTable/TableInvoice/BangKeModal';
import { JsonObject, JsonProperty } from 'json2typescript';

export interface BangKeExcelRowDataInterface {
    id?: string;
    bom_id: string;
    material_code: string;
    material_name: string;
    material_unit: string;
    bom_value: number;
    hs?: string;
    from_country?: string;
    reference_no?: string;
    issued_date?: string;
    unit_price?: number;
    account_number?: string;
    registered_date?: string;
    has_fta_origin_cost?: number;
    no_fta_origin_cost?: number;
    ecus_stock_id: string;
    purchase_stock_id: string;
    begin_id: string;
    note_price: string;
    old_fta_origin_cost: number;
    origin_option?: string;
    price_option?: string;
}
@JsonObject('BangKeExcelRowData')
export class BangKeExcelRowData implements BangKeExcelRowDataInterface {
    @JsonProperty('id', String, true)
    id: string = '';

    @JsonProperty('material_name', String, false)
    material_name!: string;

    @JsonProperty('material_unit', String, false)
    material_unit!: string;

    @JsonProperty('material_code', String, false)
    material_code!: string;

    @JsonProperty('bom_value', Number, false)
    bom_value!: number;

    @JsonProperty('bom_id', String, true)
    bom_id: string = '';

    @JsonProperty('hs', String, true)
    hs: string = '';

    @JsonProperty('from_country', String, true)
    from_country: string = '';

    @JsonProperty('reference_no', String, true)
    reference_no: string = '';

    @JsonProperty('issued_date', String, true)
    issued_date: string = '';

    @JsonProperty('unit_price', Number, true)
    unit_price: number = 0;

    @JsonProperty('account_number', String, true)
    account_number: string = '';

    @JsonProperty('registered_date', String, true)
    registered_date: string = '';

    @JsonProperty('ecus_stock_id', String, true)
    ecus_stock_id: string = '';

    @JsonProperty('purchase_stock_id', String, true)
    purchase_stock_id: string = '';

    @JsonProperty('begin_id', String, true)
    begin_id: string = '';

    @JsonProperty('has_fta_origin_cost', Number, true)
    has_fta_origin_cost: number = 0;

    @JsonProperty('no_fta_origin_cost', Number, true)
    no_fta_origin_cost: number = 0;

    @JsonProperty('note_price', String, true)
    note_price: string = '';

    @JsonProperty('old_fta_origin_cost', Number, true)
    old_fta_origin_cost: number = 0;

    @JsonProperty('origin_option', String, true)
    origin_option: string = OptionOriginEnum.empty;

    @JsonProperty('price_option', String, true)
    price_option: string = OptionPriceEnum.empty;
}
