import BuildIcon from '@mui/icons-material/Build';
import { Box, Button, FormControl, InputLabel, MenuItem, Modal, Select } from '@mui/material';
import { OutlinedButton } from 'components/Button/Button';
import { useContext, useEffect } from 'react';
import { ToolTip } from 'ui-component/tooltip/tooltip';
import ReportStatementModal from '../Modal/ReportStatementModal';
import StatementBCQT from '../SubTabs/BCQT';
import { StatementTypeEnum, TableStatementSubTab } from '../context';
import Report15Provider, { Report15Context } from '../context/ReportStatementProvider';

function ReportStatement15SubTab({ subTabDropdown }: { subTabDropdown: string }) {
    const { statementType, setStatementType } = useContext(Report15Context);

    useEffect(() => {
        setStatementType(StatementTypeEnum.statement15);
    }, []);

    switch (subTabDropdown) {
        case TableStatementSubTab.bcgq:
            return <StatementBCQT />;

        case TableStatementSubTab.begin:
            return <></>;

        case TableStatementSubTab.takein:
            return <></>;

        case TableStatementSubTab.takeout:
            return <></>;

        case TableStatementSubTab.inventory:
            return <></>;

        case TableStatementSubTab.missingcode:
            return <></>;
        default:
            return <></>;
    }
}

function TableReportStatement15() {
    return (
        <Report15Provider>
            <Report15Context.Consumer>
                {({ isOpenModal, setOpenModal, subTabDropdown, setSubTabDropdown }) => {
                    return (
                        <div className="tw-flex tw-flex-col tw-w-full">
                            <div className="tw-mb-[12px] tw-flex tw-flex-col">
                                <Modal open={isOpenModal}>
                                    <Box
                                        sx={{
                                            position: 'relative',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            width: '98%',
                                            height: '98%',
                                            bgcolor: 'whitesmoke',
                                            borderRadius: 2,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                width: '100%',
                                                height: '100%',
                                                display: 'flex',
                                                flexDirection: 'column'
                                            }}
                                        >
                                            <Button
                                                variant="text"
                                                sx={{
                                                    position: 'absolute',
                                                    top: '0px',
                                                    right: '0px',
                                                    width: '40px',
                                                    height: '40px',
                                                    padding: '12px'
                                                }}
                                                onClick={() => {
                                                    setOpenModal(false);
                                                }}
                                            >
                                                Close
                                            </Button>
                                            <ReportStatementModal />
                                        </Box>
                                    </Box>
                                </Modal>
                                <div className="tw-flex tw-justify-center tw-items-center tw-px-2 tw-gap-1">
                                    <div className="tw-flex-1 tw-flex tw-items-center tw-justify-end">
                                        <FormControl sx={{ width: '120px', position: 'absolute', right: '70px', top: '-10px' }}>
                                            <InputLabel id="item-types-select-label" sx={{ fontSize: '14px' }}>
                                                Statement
                                            </InputLabel>
                                            <Select
                                                className="tw-h-[40px]"
                                                labelId="item-types-select-label"
                                                id="item-types-select"
                                                label="item_types"
                                                value={subTabDropdown}
                                                onChange={(e) => {
                                                    setSubTabDropdown(e.target.value as TableStatementSubTab);
                                                }}
                                            >
                                                <MenuItem value={TableStatementSubTab.bcgq}>{TableStatementSubTab.bcgq}</MenuItem>
                                                <MenuItem value={TableStatementSubTab.begin}>{TableStatementSubTab.begin}</MenuItem>
                                                <MenuItem value={TableStatementSubTab.takein}>{TableStatementSubTab.takein}</MenuItem>
                                                <MenuItem value={TableStatementSubTab.takeout}>{TableStatementSubTab.takeout}</MenuItem>
                                                <MenuItem value={TableStatementSubTab.inventory}>{TableStatementSubTab.inventory}</MenuItem>
                                                <MenuItem value={TableStatementSubTab.missingcode}>
                                                    {TableStatementSubTab.missingcode}
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                        <ToolTip title="Open modal">
                                            <OutlinedButton
                                                sx={{ position: 'absolute', right: '0px', top: '-10px' }}
                                                onClick={() => {
                                                    setOpenModal(true);
                                                }}
                                            >
                                                <BuildIcon />
                                            </OutlinedButton>
                                        </ToolTip>
                                    </div>
                                </div>
                            </div>
                            <ReportStatement15SubTab subTabDropdown={subTabDropdown} />
                        </div>
                    );
                }}
            </Report15Context.Consumer>
        </Report15Provider>
    );
}

export default TableReportStatement15;
