import { WorkBook } from 'xlsx';
import { ExtraInputType, SheetMeta } from './type';
import { Result, ResultState } from 'models';
import { TableType } from 'components/Table';
import { createContext } from 'react';

export const SpreadSheetContext = createContext<{
    workBook?: WorkBook;
    setWorkBook: (value: WorkBook) => void;
    selectedSheet?: string;
    setSelectedSheet: (value: string) => void;
    onFinish: (params: any) => void;
    formLayout: string[][];
    activeStep: number;
    submitId: string;
    setNextButtonDisabled: (value: boolean) => void;
    extraInput: ExtraInputType;
    selectedFile?: File;
    setSelectedFile: (file: File) => void;
    getFixedHeader: () => Promise<Result<string[]>>;
    header?: string[];
    setHeader: (value: string[]) => void;
    setupImport: (data: { [key: string]: any } & { company_slug: string }) => Promise<Result<void>>;
    rowIndex?: [number, number | undefined];
    setRowIndex: (from: number, to: number) => void;

    endRow?: number;
    setEndRow: (index?: number) => void;
    setupSuccessful: boolean;
    setSetupSuccessful: (val: boolean) => void;
    sheetMetaImportParams?: SheetMeta;
    table: TableType;
}>({
    setWorkBook: (_) => {},
    setSelectedSheet: (_) => {},
    onFinish: (_) => {},
    formLayout: [],
    activeStep: 0,
    submitId: '',
    setNextButtonDisabled: (_) => {},
    extraInput: {},
    setSelectedFile: (_) => {},

    getFixedHeader: () => Promise.reject('Unimplemented'),
    setHeader: (_) => {},
    setupImport: (_) => Promise.reject('Unimplemented'),

    setRowIndex: (_, __) => {},
    setEndRow: (_) => {},
    setupSuccessful: false,
    setSetupSuccessful: (_) => {},
    table: TableType.ecus
});
