import { JsonObject, JsonProperty } from 'json2typescript';
import { JsonSerializable } from '../serialization';
export interface IEcusData {
    id: string;
    code: string;
    account_number: string;
    registered_date: string;
    type_code?: string;
    hs: string;
    item_name: string;
    name?: string;
    from_country: string;
    unit_price: number;
    unit_price_taxed: number;
    total: number;
    unit: string;
    unit_2?: string;
    nt_value: number;
    total_value: number;
    tax_rate?: number;
    tax_cost?: number;
    partner: string;
    invoice_no: string;
    bill_date?: string;
    contract: string;
    contract_date?: string;
    origin_criteria?: string;
    unloading_location: string;
    shipping_date: string;
}

@JsonObject('EcusData')
export class EcusData extends JsonSerializable<EcusData> implements IEcusData {
    protected get classRef(): new () => EcusData {
        return EcusData;
    }

    static empty(): EcusData {
        return new EcusData().fromJSON({
            id: new Date().getMilliseconds(),
            code: '',
            account_number: '',
            registered_date: '',
            type_code: '',
            unit_price: 0,
            unit_price_taxed: 0,
            total: 0,
            nt_value: 0,
            total_value: 0,
            tax_rate: 0,
            tax_cost: 0,
            partner: '',
            invoice_no: '',
            name: ''
        });
    }

    @JsonProperty('id', String, false)
    id!: string;

    @JsonProperty('code', String, false)
    code!: string;

    @JsonProperty('account_number', String, false)
    account_number!: string;

    @JsonProperty('origin_criteria', String, true)
    origin_criteria: string = '';

    @JsonProperty('registered_date', String, true)
    registered_date: string = '';

    @JsonProperty('type_code', String, true)
    type_code?: string;

    @JsonProperty('hs', String, false)
    hs!: string;

    @JsonProperty('item_name', String, true)
    item_name: string = '';

    @JsonProperty('item_name', String, true)
    name: string = '';

    @JsonProperty('from_country', String, true)
    from_country: string = '';

    @JsonProperty('unit_price', Number, false)
    unit_price!: number;

    @JsonProperty('unit_price_taxed', Number, false)
    unit_price_taxed!: number;

    @JsonProperty('total', Number, false)
    total!: number;

    @JsonProperty('unit', String, true)
    unit: string = '';

    @JsonProperty('unit', String, true)
    unit_2?: string;

    @JsonProperty('nt_value', Number, false)
    nt_value!: number;

    @JsonProperty('total_value', Number, false)
    total_value!: number;

    @JsonProperty('tax_rate', Number, true)
    tax_rate: number = 0;

    @JsonProperty('tax_cost', Number, true)
    tax_cost: number = 0;

    @JsonProperty('partner', String, false)
    partner!: string;

    @JsonProperty('invoice_no', String, false)
    invoice_no!: string;

    @JsonProperty('bill_date', String, true)
    bill_date?: string;

    @JsonProperty('contract', String, true)
    contract!: string;

    @JsonProperty('contract_date', String, true)
    contract_date?: string;

    @JsonProperty('unloading_location', String, true)
    unloading_location: string = '';

    @JsonProperty('shipping_date', String, true)
    shipping_date: string = '';
}

export enum EcusBalanceType {
    IMPORT = 'IMPORT',
    AMA = 'AMA',
    CANCEL = 'CANCEL'
}
