import { JsonObject, JsonProperty } from 'json2typescript';
import { JsonSerializable } from 'models/serialization';

export interface IEndingInventorySetupACC {
    company_slug: string;
    code: string;
    quantity: string;
}

@JsonObject('EndingInventorySetupACC')
export class EndingInventorySetupACC extends JsonSerializable<EndingInventorySetupACC> implements IEndingInventorySetupACC {
    protected get classRef(): new () => EndingInventorySetupACC {
        return EndingInventorySetupACC;
    }

    @JsonProperty('company_slug', String, false)
    company_slug!: string;

    @JsonProperty('code', String, false)
    code!: string;

    @JsonProperty('quantity', String, false)
    quantity!: string;
}
