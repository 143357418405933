import useAuth from 'hooks/useAuth';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';
import { setOTP } from 'store/slices/verification';
import { verificationType } from '../CodeVerification';

// material-ui
import { Box, Button, FormHelperText, Grid, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// third-party
import OtpInput from 'react18-input-otp';
import useSnackBar, { SnackBarAlertEnum } from 'components/SnackBar/UISnackbar';

// ============================|| STATIC - CODE VERIFICATION ||============================ //

const AuthCodeVerification = ({ type }: { type: verificationType }) => {
    const theme = useTheme();
    const [otp, setOtp] = useState<string>();
    const [otpError, setOtpError] = useState<string>();
    const [disabled, setDisabled] = useState<boolean>(false);
    const { verify, resetPasswordVerify } = useAuth();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { showSnackBar } = useSnackBar();

    const onSubmit = async () => {
        switch (type) {
            case verificationType.forgot:
                await confirmResetPassword();
                break;
            case verificationType.register:
                await verifyUser();
                break;
        }
    };

    const verifyUser = async () => {
        if (!otp) {
            setOtpError('Please enter OTP');
            return;
        }
        try {
            setDisabled(true);
            await verify(otp);
        } catch (error: any) {
            console.error(error);
            setOtpError(error.type.detail);
        } finally {
            setDisabled(false);
        }
    };

    const confirmResetPassword = async () => {
        if (!otp) {
            setOtpError('Please enter OTP');
            return;
        }
        try {
            setDisabled(true);
            await resetPasswordVerify(otp);
            dispatch(
                setOTP({
                    otp
                })
            );
            showSnackBar('Successfully verified, redirecting to reset password...', SnackBarAlertEnum.success);
            navigate('/reset-password', { replace: true });
        } catch (error: any) {
            console.error(error);
            setOtpError(error.exception.type.detail);
        } finally {
            setDisabled(false);
        }
    };

    const borderColor = theme.palette.mode === 'dark' ? theme.palette.grey[200] : theme.palette.grey[300];
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <OtpInput
                    value={otp}
                    onChange={(otpNumber: string) => setOtp(otpNumber)}
                    numInputs={6}
                    containerStyle={{ justifyContent: 'space-between' }}
                    inputStyle={{
                        width: '100%',
                        margin: '8px',
                        padding: '10px',
                        border: `1px solid ${borderColor}`,
                        borderRadius: 4,
                        ':hover': {
                            borderColor: theme.palette.primary.main
                        }
                    }}
                    focusStyle={{
                        outline: 'none',
                        border: `2px solid ${theme.palette.primary.main}`
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                {otpError && (
                    <Box sx={{ mt: 3 }}>
                        <FormHelperText error>{otpError}</FormHelperText>
                    </Box>
                )}
                <Button disableElevation fullWidth size="large" type="submit" variant="contained" onClick={onSubmit} disabled={disabled}>
                    Continue
                </Button>
            </Grid>
            <Grid item xs={12}>
                <Stack direction="row" justifyContent="space-between" alignItems="baseline">
                    <Typography>Did not receive the email? Check your spam filter, or</Typography>
                    <Typography variant="body1" sx={{ minWidth: 85, ml: 2, textDecoration: 'none', cursor: 'pointer' }} color="primary">
                        Resend code
                    </Typography>
                </Stack>
            </Grid>
        </Grid>
    );
};
export default AuthCodeVerification;
