import HotTable from '@handsontable/react';
import { SheetRow } from 'components/BalanceImport';
import { SpreadSheetContext } from 'components/BalanceImport/context';
import { SnackBarAlertEnum } from 'components/SnackBar';
import useSnackBar from 'components/SnackBar/UISnackbar';
import { TableType } from 'components/Table';
import { useBalanceNewRepo, useBalanceRepo } from 'di';
import { DetailedSettings as MergeCellsSettings } from 'handsontable/plugins/mergeCells';
import _, { Dictionary } from 'lodash';
import { Exception, ResultState, resultGuard } from 'models';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setLoading } from 'store/Global';
import { P, match } from 'ts-pattern';
import * as XLSX from 'xlsx';
import { utils, Range, WorkSheet } from 'xlsx';
import { Report15Context } from '../context';

const useStep3ViewModel = ({ table }: { table?: TableType }) => {
    const { workBook, selectedSheet, selectedFile, setHeader, setRowIndex, setEndRow } = useContext(Report15Context);
    const [sheetJson, setSheetJson] = useState<SheetRow[] | undefined>();
    const [mergeCells, setMergeCells] = useState<MergeCellsSettings[]>([]);
    const hotRef = useRef<HotTable | null>(null);
    const [workSheet, setWorkSheet] = useState<WorkSheet | undefined>();
    const [startCell, setStartCell] = useState<[string, Range] | undefined>();
    const [endCell, setEndCell] = useState<[string, Range] | undefined>();
    const dispatch = useDispatch();
    const { showSnackBar } = useSnackBar();
    const getHotTableInst = () => {
        return hotRef.current?.hotInstance;
    };

    const convertSheetToJson = useCallback(() => {
        if (!selectedSheet || !workBook) return;
        const workSheet = workBook.Sheets[selectedSheet];
        const sheetJson = utils.sheet_to_json<(string | number | null)[]>(workSheet, {
            header: 1,
            skipHidden: false,
            blankrows: true,
            defval: null,
            range: `A1:${endCell![0]}`
        });
        const merged = workSheet['!merges']?.map(({ s: { r: startRow, c: startCol }, e: { r: endRow, c: endCol } }) => ({
            row: startRow,
            col: startCol,
            rowspan: endRow - startRow + 1,
            colspan: endCol - startCol + 1
        }));
        if (merged) setMergeCells(merged);

        setSheetJson(sheetJson);
    }, [selectedSheet, workBook, endCell]);

    useEffect(() => {
        if (workBook && selectedSheet) setWorkSheet(workBook.Sheets[selectedSheet]);
        // convertSheetToJson()
    }, [selectedSheet, workBook]);

    const getSelectionRange = () => {
        const hot = getHotTableInst()!;
        const selected = hot.getSelectedLast();
        const [row1, column1, row2, column2] = selected ?? [];
        if ([row1, column1, row2, column2].some((val) => _.isNil(val))) {
            throw new Exception("You haven't select the header yet");
        }
        const startRow = Math.max(Math.min(row1, row2), 0);
        const endRow = Math.max(row1, row2);
        const startCol = Math.max(Math.min(column1, column2), 0);
        const endCol = Math.max(column1, column2);
        return {
            startRow,
            endRow,
            startCol,
            endCol
        };
    };

    const availableCells: any = useMemo<Dictionary<Range>>(() => {
        return _.chain(workSheet)
            .omitBy((_, key) => key.includes('!'))
            .mapValues((value, key) => {
                return utils.decode_range(key);
            })
            .value();
    }, [workSheet]);

    let _onSelectHeader = useCallback(() => {
        const hot = getHotTableInst();
        if (!hot) return;
        hot.suspendRender();

        const { startRow, startCol, endRow, endCol } = getSelectionRange();
        for (let rowIndex = 0; rowIndex < hot.countRows(); rowIndex++) {
            for (let colIndex = 0; colIndex < hot.countCols(); colIndex++) {
                let inSelectedRange = _.inRange(rowIndex, startRow, endRow + 1) && _.inRange(colIndex, startCol, endCol + 1);

                hot.setCellMeta(rowIndex, colIndex, 'className', inSelectedRange ? 'tw-bg-blue-100' : 'tw-bg-white');
            }
        }

        hot.render();
        hot.resumeRender();

        setRowIndex(startRow + 1, endRow + 1);
    }, []);

    const onSelectHeader = async () => {
        _onSelectHeader();
        return resultGuard(upload);
    };

    const hide = useCallback(
        (params?: { shouldSuspendRender: boolean }) => {
            const hot = getHotTableInst();
            if (!hot || !startCell || !endCell) return;
            const { shouldSuspendRender = true } = params ?? {};
            if (shouldSuspendRender) hot.suspendRender();
            const hideColumnPlugin = hot.getPlugin('hiddenColumns');
            const columns = [..._.range(0, startCell[1].s.c), ..._.range(endCell[1].s.c + 1, hot.countCols())];
            hideColumnPlugin.hideColumns(columns);

            const hideRowPlugin = hot.getPlugin('hiddenRows');
            const startCellRow = startCell[1].s.r;
            const MAX_VISIBLE_ROW = 20;
            const visibleRowIndex = Math.min(startCellRow + MAX_VISIBLE_ROW, endCell[1].e.r);
            hideRowPlugin.hideRows([..._.range(0, startCellRow), ..._.range(visibleRowIndex + 1, hot.countRows())]);
            if (shouldSuspendRender) {
                hot.render();
                hot.resumeRender();
            }
        },
        [startCell, endCell]
    );

    useEffect(() => {
        if (!sheetJson) return;
        hide();
    }, [sheetJson]);

    useEffect(() => {
        setEndCell(undefined);
        setStartCell(undefined);
        setSheetJson(undefined);
    }, [selectedSheet]);

    useEffect(() => {
        if (!endCell || !startCell) setSheetJson(undefined);
        if (selectedSheet && endCell && startCell) {
            convertSheetToJson();
            setEndRow(endCell[1].s.r + 1);
        }
    }, [startCell, endCell, selectedSheet]);

    useEffect(() => {
        hide();
    }, []);

    const balanceRepo = useBalanceRepo();
    const balanceNewRepo = useBalanceNewRepo();

    const upload = useCallback(async () => {
        const { startRow, endRow } = getSelectionRange();
        dispatch(setLoading(true));
        let result;
        switch (table) {
            case TableType.statement15:
                result = await balanceNewRepo.getSheetHeader({
                    header_from: startRow + 1,
                    header_to: endRow + 1,
                    sheet_name: selectedSheet!,
                    excel_file: selectedFile!,
                    colStart: startCell![0].replaceAll(/\d/g, ''),
                    colEnd: endCell![0].replaceAll(/\d/g, '')
                });
                break;
            case TableType.statement15a:
                result = await balanceNewRepo.getSheetHeader({
                    header_from: startRow + 1,
                    header_to: endRow + 1,
                    sheet_name: selectedSheet!,
                    excel_file: selectedFile!,
                    colStart: startCell![0].replaceAll(/\d/g, ''),
                    colEnd: endCell![0].replaceAll(/\d/g, '')
                });
                break;
            case TableType.takeinAccounting:
                result = await balanceNewRepo.getSheetHeader({
                    header_from: startRow + 1,
                    header_to: endRow + 1,
                    sheet_name: selectedSheet!,
                    excel_file: selectedFile!,
                    colStart: startCell![0].replaceAll(/\d/g, ''),
                    colEnd: endCell![0].replaceAll(/\d/g, '')
                });
                break;
            case TableType.takeoutAccounting:
                result = await balanceNewRepo.getSheetHeader({
                    header_from: startRow + 1,
                    header_to: endRow + 1,
                    sheet_name: selectedSheet!,
                    excel_file: selectedFile!,
                    colStart: startCell![0].replaceAll(/\d/g, ''),
                    colEnd: endCell![0].replaceAll(/\d/g, '')
                });
                break;
            case TableType.physicalAcc:
                result = await balanceNewRepo.getSheetHeader({
                    header_from: startRow + 1,
                    header_to: endRow + 1,
                    sheet_name: selectedSheet!,
                    excel_file: selectedFile!,
                    colStart: startCell![0].replaceAll(/\d/g, ''),
                    colEnd: endCell![0].replaceAll(/\d/g, '')
                });
                break;
            case TableType.iobAccounting:
                result = await balanceNewRepo.getSheetHeader({
                    header_from: startRow + 1,
                    header_to: endRow + 1,
                    sheet_name: selectedSheet!,
                    excel_file: selectedFile!,
                    colStart: startCell![0].replaceAll(/\d/g, ''),
                    colEnd: endCell![0].replaceAll(/\d/g, '')
                });
                break;
            case TableType.iob:
                result = await balanceNewRepo.getSheetHeader({
                    header_from: startRow + 1,
                    header_to: endRow + 1,
                    sheet_name: selectedSheet!,
                    excel_file: selectedFile!,
                    colStart: startCell![0].replaceAll(/\d/g, ''),
                    colEnd: endCell![0].replaceAll(/\d/g, '')
                });
                break;
            case TableType.physicalWH:
                result = await balanceNewRepo.getSheetHeader({
                    header_from: startRow + 1,
                    header_to: endRow + 1,
                    sheet_name: selectedSheet!,
                    excel_file: selectedFile!,
                    colStart: startCell![0].replaceAll(/\d/g, ''),
                    colEnd: endCell![0].replaceAll(/\d/g, '')
                });
                break;
            case TableType.takein:
                result = await balanceNewRepo.getSheetHeader({
                    header_from: startRow + 1,
                    header_to: endRow + 1,
                    sheet_name: selectedSheet!,
                    excel_file: selectedFile!,
                    colStart: startCell![0].replaceAll(/\d/g, ''),
                    colEnd: endCell![0].replaceAll(/\d/g, '')
                });
                break;
            case TableType.takeout:
                result = await balanceNewRepo.getSheetHeader({
                    header_from: startRow + 1,
                    header_to: endRow + 1,
                    sheet_name: selectedSheet!,
                    excel_file: selectedFile!,
                    colStart: startCell![0].replaceAll(/\d/g, ''),
                    colEnd: endCell![0].replaceAll(/\d/g, '')
                });
                break;
            case TableType.mapCode:
                result = await balanceNewRepo.getSheetHeader({
                    header_from: startRow + 1,
                    header_to: endRow + 1,
                    sheet_name: selectedSheet!,
                    excel_file: selectedFile!,
                    colStart: startCell![0].replaceAll(/\d/g, ''),
                    colEnd: endCell![0].replaceAll(/\d/g, '')
                });
                break;
            case TableType.reportStatement:
                result = await balanceNewRepo.getSheetHeader({
                    header_from: startRow + 1,
                    header_to: endRow + 1,
                    sheet_name: selectedSheet!,
                    excel_file: selectedFile!,
                    colStart: startCell![0].replaceAll(/\d/g, ''),
                    colEnd: endCell![0].replaceAll(/\d/g, '')
                });
                break;
            default:
                result = await balanceRepo.getSheetHeader({
                    header_from: startRow + 1,
                    header_to: endRow + 1,
                    sheet_name: selectedSheet!,
                    excel_file: selectedFile!,
                    colStart: startCell![0].replaceAll(/\d/g, ''),
                    colEnd: endCell![0].replaceAll(/\d/g, '')
                });
                break;
        }

        match(result)
            .with({ state: ResultState.success, data: P.select() }, (data) => {
                setHeader(data);
                showSnackBar('FETCHED', SnackBarAlertEnum.success);
            })
            .with({ state: ResultState.failed, exception: P.select() }, (error) => {
                showSnackBar(error.meaning, SnackBarAlertEnum.error);
            })
            .exhaustive();
        dispatch(setLoading(false));
    }, [startCell, endCell, selectedSheet, selectedFile, table]);

    return {
        availableCells,
        hotRef,
        sheetJson,
        mergeCells,
        onSelectHeader,
        setStartCell,
        setEndCell,
        endCell,
        startCell
    };
};

export default useStep3ViewModel;
