import { JsonObject, JsonProperty } from 'json2typescript';
import { JsonSerializable } from '../serialization';
export interface IIobAccountingSetupDTO {
    company_slug: string;
    code: string;
    name: string;
    unit: string;
    begin_value: string;
    begin_value_num: string;
    take_in: string;
    take_in_num: string;
    take_out: string;
    end_value: string;
}

@JsonObject('IobAccountingSetupDTO')
export class IobAccountingSetupDTO extends JsonSerializable<IobAccountingSetupDTO> implements IIobAccountingSetupDTO {
    protected get classRef(): new () => IobAccountingSetupDTO {
        return IobAccountingSetupDTO;
    }

    @JsonProperty('company_slug', String, false)
    company_slug!: string;

    @JsonProperty('item_type', String, false)
    item_type!: string;

    @JsonProperty('code', String, false)
    code!: string;

    @JsonProperty('name', String, false)
    name!: string;

    @JsonProperty('unit', String, false)
    unit!: string;

    @JsonProperty('begin_value', String, false)
    begin_value!: string;

    @JsonProperty('begin_value_num', String, false)
    begin_value_num!: string;

    @JsonProperty('take_in', String, false)
    take_in!: string;

    @JsonProperty('take_in_num', String, false)
    take_in_num!: string;

    @JsonProperty('take_out', String, false)
    take_out!: string;

    @JsonProperty('end_value', String, false)
    end_value!: string;
}
