import {BalanceImportDataSource} from "../../BalanceImportDataSource";
import {SheetMeta} from "../../../components";
import {BalanceParams, BaseQueryParams} from "../../type";
import {Http} from "../../../api";
import {COData} from "models";

export class CODataSource extends BalanceImportDataSource<SheetMeta & BalanceParams> {
    path: string = "";
    importUrl = "api/fta/co/import_data/";
    setupUrl = "api/fta/co/setup/"
    fixedHeaderUrl = "api/fta/co/get_fixed_columns/"

    constructor(public http: Http) {
        super(http);
    }

    public async getAll(params: BaseQueryParams) {
        const response = await this.http.instance.get('/api/fta/co/', {
            params
        });
        return new COData().fromPaging<COData>(response.data);
    }

    public async delete(ids: string[]) {
        return this.http.instance.delete(`/api/fta/co/bulk-delete/`, {
            data: {id_list: ids}
        });
    }
}