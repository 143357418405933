import { Box, Button } from '@mui/material';
import { useCallback, useContext, useMemo } from 'react';
import { Report15Context } from '../context';
import styled from '../style.module.css';

const STEPS = ['Insert value', 'Import file', 'Pick header', 'Map header'];

function ReportStatementStepButton() {
    const {
        step,
        setStep,
        workBook,
        setWorkBook,
        selectedFile,
        setSelectedFile,
        setIsSelectHeader,
        isDisableSelectHeader,
        setDisableSelectHeader,
        header,
        setHeader,
        setSubmitStep4,
        isDisableSubmitBtn,
        setDisableSubmitBtn,
        year,
        setSelectedSheet,
        rawData,
        setRawData
    } = useContext(Report15Context);

    const handleNext = useCallback(() => {
        setStep((prevActiveStep: any) => prevActiveStep + 1);
    }, [step]);

    const handleBack = useCallback(() => {
        switch (step) {
            case 1:
                setWorkBook(null);
                setSelectedFile(null);
                setRawData(null);
                break;
            case 2:
                setWorkBook(null);
                setSelectedFile(null);
                setRawData(null);
                break;

            case 3:
                setHeader(undefined);
                setDisableSelectHeader(true);
                setSelectedSheet('');
                break;

            case 4:
                setDisableSubmitBtn(true);
                break;
            default:
                return;
        }
        setStep((prevActiveStep: any) => prevActiveStep - 1);
    }, [step]);

    const handleReset = () => {
        setStep(0);
    };

    const stepNextDisable = useMemo(() => {
        let v = false;
        switch (step) {
            case 0:
                if (year == '' || year.length < 4) v = true;
                break;
            case 1:
                if (!workBook || !selectedFile || !rawData) v = true;
                break;
            case 2:
                if (!header) v = true;
                break;
            case 4:
                v = true;
                break;
            default:
                return;
        }
        return v;
    }, [step, workBook, selectedFile, header, year, rawData]);

    const stepBackDisable = useMemo(() => {
        let v = false;
        if (step === 0) v = true;
        return v;
    }, [step]);

    const MiddleBtn = useCallback(() => {
        switch (step) {
            case 2:
                return (
                    <Button
                        disabled={isDisableSelectHeader}
                        onClick={() => {
                            setIsSelectHeader(true);
                        }}
                        sx={{ height: '40px', border: '1px solid' }}
                    >
                        Select header
                    </Button>
                );
            case 3:
                return (
                    <Button
                        disabled={isDisableSubmitBtn}
                        onClick={() => {
                            setSubmitStep4(true);
                        }}
                        sx={{ height: '40px', border: '1px solid' }}
                    >
                        Submit
                    </Button>
                );
            default:
                return <></>;
        }
    }, [step, isDisableSelectHeader, isDisableSubmitBtn]);

    return (
        <div className={styled['modal_step']}>
            {step === STEPS.length ? (
                <Box sx={{ width: '100%', display: 'flex' }}>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <Button onClick={handleReset} sx={{ width: '80px', height: '40px', border: '1px solid' }}>
                        Reset
                    </Button>
                </Box>
            ) : (
                <Box sx={{ width: '100%', display: 'flex' }}>
                    <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                        <Button
                            color="inherit"
                            disabled={stepBackDisable}
                            onClick={handleBack}
                            sx={{ width: '80px', height: '40px', border: '1px solid' }}
                        >
                            Back
                        </Button>
                    </Box>

                    <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                        <MiddleBtn />
                    </Box>

                    <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                        <Button onClick={handleNext} sx={{ width: '80px', height: '40px', border: '1px solid' }} disabled={stepNextDisable}>
                            {`${step === 4 ? 'Finish' : 'Next'}`}
                        </Button>
                    </Box>
                </Box>
            )}
        </div>
    );
}

export default ReportStatementStepButton;
