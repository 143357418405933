import { nanoid } from '@reduxjs/toolkit';
import { PaginationState, RowSelectionState, SortingState, Table } from '@tanstack/react-table';
import { useCompanyFolderSlug } from 'components/BalanceImport';
import useSnackBar, { SnackBarAlertEnum } from 'components/SnackBar/UISnackbar';
import { useEcusRepo } from 'di';
import { AsyncValue, ConnectionState } from 'hooks/Async';
import _ from 'lodash';
import { Exception, JSONType, PagingData, ResultState } from 'models';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { BaseQueryParams } from 'repository';
import { match } from 'ts-pattern';
import { PagingController, TableContext } from './context';
import { useSkipper } from './hooks';
import { ColumnMeta, DeleteAllTableEnum, TableState } from './type';

export const TableProvider = <T extends Object, ExtQ extends Object>(props: {
    children: JSX.Element | JSX.Element[];
    pageSize?: number;
}) => {
    const [table, setTable] = useState<Table<T>>();
    const [pagingController, setPagingController] = useState<PagingController<T>>({} as PagingController<T>);
    const [asyncData, setAsyncData] = useState<AsyncValue<PagingData<T>>>({
        state: ConnectionState.none
    });
    const [pagingState, setPagingState] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: props.pageSize ?? 25
    });
    const [sorting, setSorting] = useState<SortingState>([]);
    const [dataCount, setDataCount] = useState(0);
    const [tableState, setTableState] = useState<TableState<T>>({ state: 'read' });
    const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper();
    const [rowUpdateId, setRowUpdateId] = useState(0);
    const [refreshId, setRefreshId] = useState('');
    const [manipulateConnectionState, setManipuateConnectionState] = useState(ConnectionState.none);
    const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
    const [rowDeleteId, setRowDeleteId] = useState('');
    const [insertData, setInsertData] = useState<JSONType | undefined>();
    const [panelVisibility, setPanelVisibility] = useState(false);
    const [q, setQ] = useState<string | undefined>();
    const [extQ, setExtQ] = useState<ExtQ | undefined>();
    const [codeLabel, setCodeLabel] = useState<any>();
    const [listAutoFillName, setlistAutoFillName] = useState([]);
    const [listAutoFillUnit, setlistAutoFillUnit] = useState([]);
    const [nameInput, setNameInput] = useState('');
    const [unitInput, setUnitInput] = useState('');
    const [exportExcel, setExportExcel] = useState(false);
    const [fixBom, setFixBom] = useState(false);
    const [sortHeader, setSortHeader] = useState<string | null>(null);
    const [showDelAll, setShowDelAll] = useState(false);
    const { showSnackBar } = useSnackBar();

    const ecusRepository = useEcusRepo();
    const PARAMS: BaseQueryParams = useCompanyFolderSlug();

    const [addColumnState, setAddColumnState] = useState(false);
    const [addColumn, setAddColumn] = useState('');
    const [columnExternal, setColumnExternal] = useState<null | { [key: string]: ColumnMeta }>(null);
    const [delColumnState, setDelColumnState] = useState(false);
    const [delColumn, setDelColumn] = useState('');

    const tableStateGuard = useCallback(
        (fn: any) => {
            return match(tableState)
                .with({ state: 'read' }, (_) => fn)
                .with({ state: 'update' }, (_) => fn)
                .with({ state: 'edit' }, (_) => {
                    return undefined;
                })
                .with({ state: 'insert' }, () => {})

                .exhaustive();
        },
        [tableState]
    );

    useEffect(() => {
        match(tableState)
            .with({ state: 'update' }, () => {
                setPanelVisibility(true);
            })
            .with({ state: 'read' }, () => {
                setPanelVisibility(false);
            })
            .with({ state: 'insert' }, () => {
                setPanelVisibility(true);
            })
            .otherwise(() => {});
    }, [tableState]);

    useEffect(() => {
        if (!table) return;
        const { nextPage, setPageIndex, previousPage, getCanNextPage, getCanPreviousPage, setPageSize } = table;
        setPagingController({
            nextPage: tableStateGuard(nextPage),
            previousPage: tableStateGuard(previousPage),
            setPageIndex: tableStateGuard((index: number) => {
                skipAutoResetPageIndex();
                setPageIndex(index);
            }),
            getCanPreviousPage,
            getCanNextPage,
            setPageSize: tableStateGuard(setPageSize)
        });
    }, [table, tableState]);

    const pageCount = useMemo(() => Math.ceil(dataCount / pagingState.pageSize), [dataCount, pagingState.pageSize]);

    const [data, connectionState, error] = useMemo(() => {
        return match<AsyncValue<PagingData<T>>, [PagingData<T> | undefined, ConnectionState, Exception | undefined]>(asyncData)
            .with({ state: ConnectionState.none }, ({ state }) => [undefined, state, undefined])
            .with({ state: ConnectionState.waiting }, ({ state }) => [undefined, state, undefined])
            .with({ state: ConnectionState.hasData }, ({ state, data }) => [data, state, undefined])
            .with({ state: ConnectionState.hasError }, ({ state, error }) => [undefined, state, Exception.parse(error)])
            .exhaustive();
    }, [asyncData]);

    const combinedState = useMemo(() => {
        if ([connectionState, manipulateConnectionState].includes(ConnectionState.waiting)) return ConnectionState.waiting;
        return connectionState;
    }, [connectionState, manipulateConnectionState]);

    const refresh = (options?: { setTableStateAsRead: boolean }) => {
        const { setTableStateAsRead = true } = options ?? {};
        if (setTableStateAsRead) setTableState({ state: 'read' });
        setRefreshId(nanoid(10));
    };

    const selectedRowData = useMemo(() => {
        const rows = table?.getCoreRowModel().flatRows.map((row) => row.original) ?? [];
        return rows.filter((_, index) => rowSelection[index]);
    }, [rowSelection]);

    const togglePanel = (visibility?: boolean) => {
        if (_.isNil(visibility)) setPanelVisibility(!panelVisibility);
        else setPanelVisibility(visibility);
    };

    const insert = () => {
        match(tableState)
            .with({ state: 'insert' }, () => {
                setTableState({ state: 'read' });
            })
            .otherwise(() => {
                setTableState({ state: 'insert' });
            });
    };

    const addColumnHandler = () => {
        setAddColumnState((prev) => !prev);
    };

    const delColumnHandler = () => {
        setDelColumnState((prev) => !prev);
    };

    useEffect(() => {
        if (!delColumn || !columnExternal) return;
        else {
            let newMap: { [key: string]: ColumnMeta } = {};

            Object.entries(columnExternal).forEach(([k, v]) => {
                if (k !== delColumn) {
                    newMap[`${k}`] = v;
                }
            });
            setColumnExternal(newMap);
        }
    }, [delColumn, columnExternal]);

    async function DeleteAll(type: DeleteAllTableEnum) {
        const response = await ecusRepository.deleteAll(PARAMS, type);
        match(response)
            .with({ state: ResultState.success }, () => {
                showSnackBar('Deleted Success', SnackBarAlertEnum.success);
                refresh();
            })
            .with({ state: ResultState.failed }, () => {
                showSnackBar('Deleted failed', SnackBarAlertEnum.error);
            })
            .exhaustive();
        setShowDelAll(false);
    }

    function openDeleteAll(state: boolean | null, type?: DeleteAllTableEnum) {
        if (state !== null) {
            setShowDelAll(state);
        }
        if (type) {
            DeleteAll(type);
        }
    }

    useEffect(() => {
        setSorting([]);
    }, [refreshId]);

    return (
        <TableContext.Provider
            value={{
                pagingState,
                setPagingState,
                setTable,
                pagingController,
                sorting,
                setSorting: tableStateGuard(setSorting),
                pageCount,
                dataCount,
                setDataCount,
                tableState,
                setTableState,
                autoResetPageIndex,
                skipAutoResetPageIndex,
                data,
                connectionState: combinedState,
                error,
                setAsyncData: (data) => {
                    skipAutoResetPageIndex();
                    setAsyncData(data);
                },
                rowUpdateId,
                updateRows: () => {
                    setRowUpdateId(new Date().getMilliseconds());
                },
                manipulateConnectionState,
                setManipuateConnectionState,
                refreshId,
                refresh,
                selectedRowData,
                rowSelection,
                setRowSelection,
                rowDeleteId,
                deleteRows: () => {
                    setRowDeleteId(nanoid());
                },
                insertData,
                setInsertData,
                panelVisibility,
                togglePanel,
                q,
                setQ,

                extQ,
                setExtQ,

                insert,

                codeLabel,
                setCodeLabel,
                listAutoFillName,
                setlistAutoFillName,
                listAutoFillUnit,
                setlistAutoFillUnit,
                nameInput,
                setNameInput,
                unitInput,
                setUnitInput,

                exportExcel,
                setExportExcel,

                fixBom,
                setFixBom,

                sortHeader,
                setSortHeader,

                showDelAll,
                openDeleteAll,

                addColumnHandler,
                setAddColumnState,
                addColumnState,
                addColumn,
                setAddColumn,
                columnExternal,
                setColumnExternal,
                setDelColumnState,
                delColumnState,
                delColumnHandler,
                delColumn,
                setDelColumn
            }}
        >
            {props.children}
        </TableContext.Provider>
    );
};
