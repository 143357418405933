import { Http } from 'api';
import _ from 'lodash';
import { EcusBalanceType, EcusData, JSONType } from 'models';
import { BalanceParams, type EcusQueryParams } from '../type';
import { IEcusImportDTO } from './type';
import { AutoFillParams, DeleteAllTableEnum } from 'components';
import { AutoFillData } from 'models/AutoFillData/AutoFillData';
import { BalanceImportDataSource } from '../BalanceImportDataSource';
import { RepeatOneSharp } from '@mui/icons-material';

export class EcusDataSource extends BalanceImportDataSource<IEcusImportDTO> {
    path: string = 'ecus';
    constructor(public http: Http) {
        super(http);
    }

    public async getAll(params: EcusQueryParams) {
        const response = await this.http.instance.get('/api/balance/ecus/', {
            params
        });
        return new EcusData().fromPaging<EcusData>(response.data);
    }

    public async update(data: Partial<EcusData>, params: EcusQueryParams) {
        return this.http.instance.put(
            `/api/balance/ecus/${data.id}/`,
            _.omit(
                {
                    ...data,
                    ...params
                },
                'id'
            )
        );
    }

    public async delete(ids: string[], params: EcusQueryParams) {
        return this.http.instance.delete(`/api/balance/ecus/bulk-delete/`, {
            data: { id_list: ids }
        });
    }

    public async insert(data: JSONType) {
        return this.http.instance.post(`/api/balance/ecus/`, data);
    }

    public async importData(data: IEcusImportDTO) {
        let url = '';
        switch (data.type) {
            case EcusBalanceType.AMA:
                url = `/api/balance/ecus/ama_import_data/`;
                break;
            case EcusBalanceType.CANCEL:
                url = `/api/balance/ecus/cancel_import_data/`;
                break;
            default:
                url = `/api/balance/ecus/import_data/`;
                break;
        }
        const formData = _.omit(data, ['type']);
        var bodyFormData = new FormData();

        Object.entries(formData).map(([key, value]) => {
            if (_.isNumber(value)) {
                bodyFormData.append(key, `${value}`);
            } else bodyFormData.append(key, value);
        });
        await this.http.instance.post(url, bodyFormData, {
            headers: { 'content-type': 'multipart/form-data' }
        });
    }

    public async getAutoFill(data: AutoFillParams) {
        const respone = await this.http.instance.post('/api/balance/ecus/autofill/', data);
        return new AutoFillData().fromPaging<AutoFillData>(respone.data);
    }

    public async getSelectableValues(params: EcusQueryParams) {
        const response = await this.http.instance.get('/api/balance/ecus/get_selectable_values/', {
            params
        });
        return response.data;
    }

    async deleteAll(params: BalanceParams, type: DeleteAllTableEnum) {
        switch (type) {
            case DeleteAllTableEnum.ecus:
                const responseEcus = await this.http.instance.delete(`/api/balance/${type}/`, { data: params });
                return responseEcus;
            case DeleteAllTableEnum.begin:
                const responseBegin = await this.http.instance.delete(`/api/balance/${type}/`, { data: params });
                return responseBegin;
            case DeleteAllTableEnum.bom:
                const responseBom = await this.http.instance.delete(`/api/balance/${type}/`, { data: params });
                return responseBom;
            case DeleteAllTableEnum.purchase:
                const responsePurchase = await this.http.instance.delete(`/api/balance/${type}/`, { data: params });
                return responsePurchase;
            case DeleteAllTableEnum.bomexw:
                const responseBomEWX = await this.http.instance.delete(`/api/balance/${type}/`, { data: params });
                return responseBomEWX;
            case DeleteAllTableEnum.annex:
                const responseAnnex = await this.http.instance.delete(`/api/fta/${type}/`, { data: params });
                return responseAnnex;
            case DeleteAllTableEnum.co:
                const responseCO = await this.http.instance.delete(`/api/fta/${type}/`, { data: params });
                return responseCO;
            case DeleteAllTableEnum.statement15:
                const responseStatement15 = await this.http.instance.delete(`/api/new-balance/${type}/`, { data: params });
                return responseStatement15;
            case DeleteAllTableEnum.statement15A:
                const responseStatement15A = await this.http.instance.delete(`/api/new-balance/${type}/`, { data: params });
                return responseStatement15A;
            case DeleteAllTableEnum.takeinAcc:
                const responseTakeinAcc = await this.http.instance.delete(`/api/new-balance/${type}/`, { data: params });
                return responseTakeinAcc;
            case DeleteAllTableEnum.takeoutAcc:
                const responseTakeoutAcc = await this.http.instance.delete(`/api/new-balance/${type}/`, { data: params });
                return responseTakeoutAcc;
            case DeleteAllTableEnum.iobAcc:
                const responseIobAcc = await this.http.instance.delete(`/api/new-balance/${type}/`, { data: params });
                return responseIobAcc;
            case DeleteAllTableEnum.physicalAcc:
                const responsePhysicalAcc = await this.http.instance.delete(`/api/new-balance/${type}/`, { data: params });
                return responsePhysicalAcc;
            case DeleteAllTableEnum.iobWh:
                const responseIobWh = await this.http.instance.delete(`/api/new-balance/${type}/`, { data: params });
                return responseIobWh;
            case DeleteAllTableEnum.physicalWH:
                const responsePhysicalWH = await this.http.instance.delete(`/api/new-balance/${type}/`, { data: params });
                return responsePhysicalWH;
            case DeleteAllTableEnum.takeinWH:
                const responseTakeinlWH = await this.http.instance.delete(`/api/new-balance/${type}/`, { data: params });
                return responseTakeinlWH;
            case DeleteAllTableEnum.takeoutWH:
                const responseTakeoutlWH = await this.http.instance.delete(`/api/new-balance/${type}/`, { data: params });
                return responseTakeoutlWH;
            case DeleteAllTableEnum.mapCode:
                const responseMapCode = await this.http.instance.delete(`/api/new-balance/${type}/`, { data: params });
                return responseMapCode;
            case DeleteAllTableEnum.otherOutputAcc:
                const responseOtherOutputAcc = await this.http.instance.delete(`/api/new-balance/${type}/`, { data: params });
                return responseOtherOutputAcc;
        }
    }
}
