import { JsonSerializable, PagingData } from '../serialization';
import { JsonObject, JsonProperty } from 'json2typescript';
export interface IBomData {
    id: string;
    code: string;
    name: string;
    unit: string;
    material_code: string;
    material_name: string;
    material_unit: string;
    bom_value: number;
    contract: string;
    note: string;
    product_type: string;
    year: number;
}

@JsonObject('BomData')
export class BomData extends JsonSerializable<BomData> implements IBomData {
    protected get classRef(): new () => BomData {
        return BomData;
    }

    static empty(): BomData {
        return new BomData().fromJSON({
            id: new Date().getMilliseconds(),
            code: '',
            name: '',
            unit: '',
            material_code: '',
            material_name: '',
            material_unit: '',
            bom_value: 0,
            contract: '',
            note: '',
            product_type: '',
            year: 0
        });
    }

    @JsonProperty('id', String, false)
    id!: string;

    @JsonProperty('code', String, false)
    code!: string;

    @JsonProperty('name', String, true)
    name: string = '';

    @JsonProperty('unit', String, true)
    unit: string = '';

    @JsonProperty('material_code', String, true)
    material_code: string = '';

    @JsonProperty('material_name', String, true)
    material_name: string = '';

    @JsonProperty('material_unit', String, true)
    material_unit: string = '';

    @JsonProperty('bom_value', Number, true)
    bom_value: number = 0;

    @JsonProperty('contract', String, true)
    contract: string = '';

    @JsonProperty('note', String, true)
    note: string = '';

    @JsonProperty('product_type', String, true)
    product_type: string = '';

    @JsonProperty('year', Number, true)
    year: number = 0;
}
