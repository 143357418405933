import { JsonConvert, type JsonCustomConvert, OperationMode } from 'json2typescript';
import { Exception, ExceptionType } from './Exception';

export type JSONType = { [id: string]: any };

export type PagingData<T> = {
    next?: string | undefined;
    count: number;
    results: T[];
};

// const _converter = new JsonConvert(OperationMode.LOGGING);
const _converter = new JsonConvert();

export abstract class JsonSerializable<T extends Object> {
    fromJSON(json: JSONType): T {
        return this.guard<T>(() => _converter.deserializeObject<T>(json, this.classRef));
    }

    toJSON(): JSONType {
        return this.guard<JSONType>(() => _converter.serializeObject(this, this.classRef) as JSONType);
    }

    fromPaging<R>(json: PagingData<any>, strict?: boolean): PagingData<R> {
        const results = _converter.deserializeArray(json['results'], this.classRef);
        json.results = results;
        return json;
    }

    formData(json: { data: any[] }) {
        const data = _converter.deserializeArray(json['data'], this.classRef);
        json.data = data;
        return json;
    }

    toListJSON(list: T[]): JSONType[] {
        return _converter.serializeArray(list, this.classRef);
    }

    guard<V>(fn: () => V): V {
        try {
            return fn();
        } catch (e) {
            throw new Exception({
                type: ExceptionType.serialize,
                detail: (e as Error).message
            });
        }
    }

    protected abstract get classRef(): new () => T;
}

export class EnumConverter<T> implements JsonCustomConvert<T> {
    validValues: string[];
    isOptional = false;

    constructor(private enumType: unknown, private enumName: string, isOptional?: boolean) {
        this.isOptional = isOptional ?? false;
        // @ts-ignore
        this.validValues = Object.values(Object.getOwnPropertyDescriptors(enumType)).map(
            // @ts-ignore
            (value) => value.value
        );
    }

    deserialize(value: string): T {
        if (this.validValues.indexOf(value) < 0) {
            if (!this.isOptional) {
                throw new Error(
                    `JsonConvert error; invalid value for enum ${this.enumName}, expected one of '${this.validValues}', found '${value}'`
                );
            }
        }
        return value as unknown as T;
    }

    serialize(data: T): any {
        return data;
    }
}
