import { WorkBook } from 'xlsx';
import * as React from 'react';
import { forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import { SpreadSheetContext } from './context';
import { ImportSheet } from './ImportSheet';
import { SheetTitlePicker } from './SheetTitlePicker';
import _, { isEmpty, isNil } from 'lodash';
import { TableContext, TableType } from '../Table';
import { ColumnMapper, type ColumnMapperRef } from './ColumnMapper';
import { nanoid } from '@reduxjs/toolkit';
import { ExtraInputType, SheetMeta, SheetPreviewRef, SetupParams } from './type';
import { SheetHeaderMapping } from './SheetHeaderMapping';
import { Result } from 'models';
import { Fab, Stack } from '@mui/material';
import AnimateButton from 'ui-component/extended/AnimateButton';
import ChevronRight from '@mui/icons-material/ChevronRight';
import produce from 'immer';

type SheetPreviewProps = {
    onFinish: (params: any) => Promise<any>;
    formLayout?: string[][];
    extraInput?: ExtraInputType;
    steps?: ImportStep[];
    initSheetMeta?: SheetMeta;
    initHeader?: string[];
    table?: TableType;
};

type SheetPreview = {
    getFixedHeader: () => Promise<Result<string[]>>;
    setupImport: (data: SetupParams) => Promise<Result<void>>;
};

export enum ImportStep {
    upload = 'upload',
    pickHeader = 'pickHeader',
    mapping = 'mapping',
    extra = 'extra'
}

export const SheetPreview = forwardRef<SheetPreviewRef, SheetPreview & SheetPreviewProps<{} & SheetMeta>>((props, ref) => {
    const {
        onFinish,
        formLayout = [],
        extraInput = {},
        getFixedHeader,
        setupImport,
        steps: stepsProp = _.values(ImportStep),
        initSheetMeta,
        initHeader,
        table = TableType.ecus
    } = props;
    const [workBook, setWorkBook] = useState<WorkBook | undefined>();
    const [selectedSheet, setSelectedSheet] = useState<string | undefined>(initSheetMeta?.sheet_name);
    const [activeStep, setActiveStep] = useState(0);
    const [submitId, setSubmitId] = useState('');
    const [nextButtonDisabled, setNextButtonDisabled] = useState(true);
    const [selectedFile, setSelectedFile] = useState<File | undefined>(initSheetMeta?.excel_file);
    const [header, setHeader] = useState<string[] | undefined>(initHeader);
    const [rowIndex, _setRowIndex] = useState<[number, number | undefined] | undefined>(
        initSheetMeta ? [initSheetMeta.header_from, initSheetMeta.header_to] : undefined
    );
    const [endRow, setEndRow] = useState<number | undefined>(initSheetMeta?.end_row);
    const [setupSuccessful, setSetupSuccessful] = useState(false);

    const steps = useMemo(() => {
        const labelMap = {
            [ImportStep.upload]: 'Import Sheet',
            [ImportStep.pickHeader]: "Select Sheet's title",
            [ImportStep.mapping]: 'Mapping Sheet Column',
            [ImportStep.extra]: 'Extra fields'
        };
        return stepsProp.map((step) => labelMap[step]);
    }, [stepsProp]);

    const setRowIndex = (from: number, to: number) => {
        _setRowIndex([from, to]);
    };

    const sheetMetaImportParams = useMemo<SheetMeta | undefined>(() => {
        if (_.some([selectedFile, selectedSheet, endRow, rowIndex], isNil)) return undefined;
        const [from, to] = rowIndex!;
        const draft: SheetMeta = {
            excel_file: selectedFile!,
            sheet_name: selectedSheet!,
            header_from: from,
            end_row: endRow!
        };
        if (from !== to && !!to) draft.header_to = to;
        return draft;
    }, [selectedFile, selectedSheet, rowIndex, endRow]);

    useImperativeHandle(ref, () => ({
        getHeader: () => header
    }));

    return (
        <SpreadSheetContext.Provider
            value={{
                workBook,
                setWorkBook,
                selectedSheet,
                setSelectedSheet,
                onFinish,
                formLayout,
                activeStep,
                submitId,
                setNextButtonDisabled,
                extraInput,
                selectedFile,
                setSelectedFile,
                getFixedHeader,
                header,
                setHeader,
                setupImport,
                rowIndex,
                setRowIndex,
                endRow,
                setEndRow,
                setupSuccessful,
                setSetupSuccessful,
                sheetMetaImportParams,
                table
            }}
        >
            <div className={' tw-h-full tw-relative'}>
                <div className={'tw-h-6'} />
                <Stepper activeStep={activeStep}>
                    {steps.map((label) => {
                        return (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                <HorizontalLinearStepper steps={stepsProp} />
                <div className={'tw-absolute tw-bottom-0 tw-right-0 tw-m-8'}>
                    <Fab
                        color={'primary'}
                        disabled={nextButtonDisabled}
                        onClick={() => {
                            console.log(activeStep + 1, steps.length);
                            if (activeStep + 1 >= steps.length) {
                                setSubmitId(nanoid());
                            } else setActiveStep(activeStep + 1);
                        }}
                    >
                        <ChevronRight />
                    </Fab>
                </div>
            </div>
        </SpreadSheetContext.Provider>
    );
});

function HorizontalLinearStepper({ steps }: { steps: ImportStep[] }) {
    const {
        rowIndex,
        endRow,
        workBook,
        submitId,
        activeStep,
        setNextButtonDisabled,
        header,
        setupSuccessful,
        onFinish,
        selectedSheet,
        selectedFile,
        sheetMetaImportParams
    } = useContext(SpreadSheetContext);
    const { selectedRowData } = useContext(TableContext);

    const columnMaperRef = React.useRef<ColumnMapperRef>(null);

    const submit = useCallback(() => {
        onFinish(sheetMetaImportParams);
    }, [sheetMetaImportParams]);

    useEffect(() => {
        if (_.isEmpty(submitId)) return;
        if (_.last(steps) === ImportStep.extra) columnMaperRef?.current?.submit();
        else {
            submit();
        }
    }, [submitId]);

    const child = useMemo(() => {
        const childMap = {
            [ImportStep.upload]: <ImportSheet />,
            [ImportStep.pickHeader]: <SheetTitlePicker />,
            [ImportStep.mapping]: <SheetHeaderMapping />,
            [ImportStep.extra]: <ColumnMapper ref={columnMaperRef} />
        };
        const children = steps.map((step) => childMap[step]);
        return children[Math.min(activeStep, steps.length - 1)];
    }, [activeStep, steps]);

    useEffect(() => {
        let disabled = true;
        const validMap = {
            [ImportStep.upload]: isNil(workBook),
            [ImportStep.pickHeader]: isEmpty(rowIndex) || _.isNil(endRow) || _.isEmpty(header),
            [ImportStep.mapping]: !setupSuccessful,
            [ImportStep.extra]: false
        };
        disabled = steps.map((step) => validMap[step])[activeStep];
        setNextButtonDisabled(disabled);
    }, [steps, activeStep, workBook, selectedRowData, rowIndex, endRow, header, setupSuccessful]);

    return (
        <Box sx={{ width: '100%' }}>
            <>
                <div className="tw-h-[24px]" />
                <div className="tw-px-[16px]">
                    {child}
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            pt: 2,
                            marginTop: '12px'
                        }}
                    ></Box>
                </div>
            </>
        </Box>
    );
}
