import { JsonObject, JsonProperty } from 'json2typescript';
import { JsonSerializable } from './serialization';

export interface IDetailID {
    $oid: string;
}

@JsonObject('DetailID')
export class DetailID extends JsonSerializable<DetailID> implements IDetailID {
    protected get classRef(): new () => DetailID {
        return DetailID;
    }

    @JsonProperty('$oid', String, false)
    $oid!: string;
}

export interface IDate {
    $date: number;
}

@JsonObject('RegisteredDate')
export class RegisteredDate extends JsonSerializable<RegisteredDate> implements IDate {
    protected get classRef(): new () => RegisteredDate {
        return RegisteredDate;
    }

    @JsonProperty('$date', Number, false)
    $date!: number;
}

@JsonObject('BillDate')
export class BillDate extends JsonSerializable<BillDate> implements IDate {
    protected get classRef(): new () => BillDate {
        return BillDate;
    }

    @JsonProperty('$date', Number, false)
    $date!: number;
}

@JsonObject('CreatedAt')
export class CreatedAt extends JsonSerializable<CreatedAt> implements IDate {
    protected get classRef(): new () => CreatedAt {
        return CreatedAt;
    }

    @JsonProperty('$date', Number, false)
    $date!: number;
}

export interface IDetailRespone {
    id: string;
    ecus_id: string;
    created_at: string;
    import_record_id: number;
    account_number: number;
    row_number: number;
    unit: string;
    code: string;
    total: number;
}

@JsonObject('DetailRespone')
export class DetailRespone extends JsonSerializable<DetailRespone> implements IDetailRespone {
    protected get classRef(): new () => DetailRespone {
        return DetailRespone;
    }

    @JsonProperty('id', String, false)
    id!: string;

    @JsonProperty('ecus_id', String, false)
    ecus_id!: string;

    @JsonProperty('created_at', String, false)
    created_at!: string;

    @JsonProperty('account_number', Number, false)
    account_number!: number;

    @JsonProperty('import_record_id', Number, false)
    import_record_id!: number;

    @JsonProperty('row_number', Number, false)
    row_number!: number;

    @JsonProperty('unit', String, true)
    unit: string = '';

    @JsonProperty('code', String, true)
    code: string = '';

    @JsonProperty('total', Number, true)
    total: number = 0;
}
