export enum StatementTypeEnum {
    statement15 = '15',
    statement15A = '15A'
}

export enum Statement15TypeCodeEnum {
    e11 = 'E11 - EPE',
    e21 = 'E21 - Processing',
    e31 = 'E31 - SXXK'
}

export enum Statement15ATypeCodeEnum {
    e42 = 'E42 - EPE',
    e52 = 'E52 - Processing',
    e62 = 'E62 - SXXK'
}

export enum PhysicalCountingEnum {
    acc = 'Accounting',
    wh = 'W/H'
}

export enum TableStatementSubTab {
    bcgq = 'BCQT',
    begin = 'BEGIN',
    takein = 'TAKE IN',
    takeout = 'TAKE OUT',
    inventory = 'INVENTORY',
    missingcode = 'MISSING CODE'
}
