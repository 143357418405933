import { AutoFillParams } from 'components';
import _ from 'lodash';
import { JSONType } from 'models';
import { AutoFillData } from 'models/AutoFillData/AutoFillData';
import { EndingInventoryDataWH, EndingInventorySetupDTOWH } from 'models/EndingInventoryWH';
import { BalanceImportDataSource } from 'repository/BalanceImportDataSource';
import { EndingInventoryQueryParamsWH } from 'repository/type';
import { IEndingInventoryImportDTOWH } from './type';

export class EndingInventoryDataSourceWH extends BalanceImportDataSource<IEndingInventoryImportDTOWH> {
    path: string = 'wh-ending-inventory';

    public async getAutoFill(data: AutoFillParams) {
        const respone = await this.http.instance.post('/api/balance/wh-ending-inventory/autofill/', data);
        return new AutoFillData().fromPaging<AutoFillData>(respone.data);
    }

    public async getAll(params: EndingInventoryQueryParamsWH) {
        const response = await this.http.instance.get('/api/balance/wh-ending-inventory/', {
            params
        });
        return new EndingInventoryDataWH().fromPaging<EndingInventoryDataWH>(response.data);
    }

    public async update(data: Partial<EndingInventoryDataWH>, params: EndingInventoryQueryParamsWH) {
        return this.http.instance.put(
            `/api/balance/wh-ending-inventory/${data.id}/`,
            _.omit(
                {
                    ...data,
                    ...params
                },
                'id'
            )
        );
    }

    public async delete(ids: string[]) {
        return this.http.instance.delete(`/api/balance/wh-ending-inventory/bulk-delete/`, {
            data: { id_list: ids }
        });
    }

    public async insert(data: JSONType) {
        return this.http.instance.post(`/api/balance/wh-ending-inventory/`, data);
    }
}
