import ImportExcel from 'components/BalanceImport/importExcel';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Report15Context } from '../context';
import * as XLSX from 'xlsx';
import { Box } from '@mui/material';

function ModalStep2() {
    const { setValue } = useForm({});
    const { setWorkBook, selectedFile, setSelectedFile } = useContext(Report15Context);

    useEffect(() => {
        if (selectedFile) {
            var reader = new FileReader();
            reader.onload = function (ev) {
                var content = ev?.target?.result; // content is a string
                let sheet = XLSX.read(content, { type: 'buffer' });
                setWorkBook(sheet);
                // utils.sheet_to_json(wb.Sheets[wb.SheetNames[1]], { header: 1 })
            };
            reader.onerror = function (err) {
                console.error('Failed to read file', err);
            };
            reader.readAsArrayBuffer(selectedFile);
        }
    }, [selectedFile]);

    return (
        <Box sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <ImportExcel setValue={setValue} selectedFile={selectedFile} setSelectedFile={setSelectedFile} error={null} />
        </Box>
    );
}

export default ModalStep2;
