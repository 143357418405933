import { JsonObject, JsonProperty } from 'json2typescript';
import { JsonSerializable } from '../serialization';
import { IAnnexData } from '../AnnexTable';

export interface ICOData {
    id: string;
    reference_no: string;
    issued_date: string;
    export_declaration_number: string;
    import_record_id: number;
    part_number: string;
}

@JsonObject('COData')
export class COData extends JsonSerializable<COData> implements ICOData {
    protected get classRef(): { new (): COData } {
        return COData;
    }

    @JsonProperty('export_declaration_number', String, true)
    export_declaration_number: string = '';

    @JsonProperty('id', String, false)
    id!: string;

    @JsonProperty('import_record_id', Number, true)
    import_record_id: number = 0;

    @JsonProperty('issued_date', String, true)
    issued_date: string = '';

    @JsonProperty('part_number', String, true)
    part_number: string = '';

    @JsonProperty('reference_no', String, true)
    reference_no: string = '';
}

/**
 * "id": "64d9e22e49bd7f2f7142cd47",
 *             "created_at": "2023-08-14T15:13:34.773000",
 *             "reference_no": "K074-22-0358637",
 *             "issued_date": "2022-12-25",
 *             "export_declaration_number": "105198221110",
 *             "part_number": "A40",
 *             "import_record_id": 1,
 *             "material_type": null
 */
