import { Box } from '@mui/material';
import { FormTypeEnum } from 'components/BalanceTable/TableInvoice/InvoiceProvider';
import { useContext } from 'react';
import { InvoiceWordContext } from '..';
import InvoiceWordStep from './InvoiceWordStep/InvoiceWordStep';
import InvoiceWordStepButton from './InvoiceWordStep/InvoiceWordStepButton';
import InvoiceWordStep1 from './InvoiceWordStep1';
import InvoiceWordStep2 from './InvoiceWordStep2';
import InvoiceWordStep3 from './InvoiceWordStep3';

function InvoiceWordScreenFormDefault({ activeStep }: { activeStep: number }) {
    switch (activeStep) {
        case 0:
            return <InvoiceWordStep1 />;

        case 1:
            return <InvoiceWordStep2 />;

        default:
            return <Box sx={{ width: '100%', height: '100%' }}></Box>;
    }
}

function InvoiceWordScreenVJ({ activeStep }: { activeStep: number }) {
    switch (activeStep) {
        case 0:
            return <InvoiceWordStep1 />;

        case 1:
            return <InvoiceWordStep3 />;

        case 2:
            return <InvoiceWordStep2 />;

        default:
            return <Box></Box>;
    }
}
function InvoiceWordScreen() {
    const { wordFormType, activeStep } = useContext(InvoiceWordContext);

    switch (wordFormType) {
        case FormTypeEnum.VJ || FormTypeEnum.AJ:
            return <InvoiceWordScreenVJ activeStep={activeStep} />;
        default:
            return <InvoiceWordScreenFormDefault activeStep={activeStep} />;
    }
}
function InvoiceWordModal() {
    return (
        <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', padding: '6px 14px', gap: '12px' }}>
            <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <InvoiceWordStep />
            </Box>
            <Box sx={{ flex: 16, height: '80%' }}>
                <InvoiceWordScreen />
            </Box>
            <Box sx={{ flex: 1 }}>
                <InvoiceWordStepButton />
            </Box>
        </Box>
    );
}

export default InvoiceWordModal;
