import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

// material-ui
import { Box, Tab, Tabs } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// assets
import { TableType } from 'components';
import { TabsProps } from 'types';

// tab content
function TabPanel({ children, value, index, ...other }: TabsProps) {
    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (
                <Box sx={{ paddingY: 1, paddingX: 0 }}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

export type TabProps = {
    name: TableType;
    item: React.ReactNode;
};

// ================================|| UI TABS - SAMPLE ||================================ //

export function SimpleTabs({ data }: { data: TabProps[] }) {
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    useEffect(() => {
        setValue(0);
    }, [data]);

    return (
        <>
            <Tabs
                value={value}
                variant="scrollable"
                onChange={handleChange}
                TabIndicatorProps={{ style: { top: '28px' } }}
                sx={{
                    mb: 1,
                    '& a': {
                        px: 0.5,
                        mr: 1.5,
                        minHeight: 'auto',
                        color: theme.palette.grey[600],
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center'
                    },
                    '& a.Mui-selected': {
                        color: theme.palette.primary.main
                    },
                    '& a > svg': {
                        mb: '0px !important',
                        mr: 1.1
                    }
                }}
            >
                {data.map((item: any, index: number) => {
                    return (
                        <Tab
                            component={Link}
                            to="#"
                            label={`${item.name}`}
                            {...a11yProps(index)}
                            key={index}
                            sx={{ height: '30px', minWidth: 20 }}
                        />
                    );
                })}
            </Tabs>

            {data.map((item: any, index: number) => {
                return (
                    <div key={index}>
                        <TabPanel value={value} index={index}>
                            {item.item}
                        </TabPanel>
                    </div>
                );
            })}
        </>
    );
}
