import { useCompanyFolderSlug } from 'components/BalanceImport';
import { ConvertCurrency } from 'components/BalanceTable/BangKeSheet';
import { ConvertDate, FormTypeEnum } from 'components/BalanceTable/TableInvoice/InvoiceProvider';
import useSnackBar, { SnackBarAlertEnum } from 'components/SnackBar/UISnackbar';
import { useInvoiceRepo } from 'di';
import Docxtemplater from 'docxtemplater';
import saveAs from 'file-saver';
import _ from 'lodash';
import { IEcusData, ResultState } from 'models';
import { PSRData } from 'models/PSR';
import PizZip from 'pizzip';
import PizZipUtils from 'pizzip/utils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { BalanceParams, BaseQueryParams } from 'repository';
import { match } from 'ts-pattern';

import { BangKeHistoryDataResponse } from 'models/BangKe/BangKeHistoryData';
import {
    ConsigneeCompanyInterface,
    ConvertDateImport,
    ConvertName,
    ExporterCompanyInterface,
    FieldStateInterface,
    FromHsToOriginCriteria,
    GenerateWordTitle
} from '..';
import { CheckPropertyInterface } from '../TableInvoiceWord';
import { GenerateInvoice, TotalntValueInterface, TotalsubInterface } from '../type';
import { PsrQueryParams } from 'repository/invoice';
import { dispatch } from 'store';
import { setLoading } from 'store/Global';

export interface useGeneratePageProps {
    label: string | null;
    place: string | null;
    fieldState: FieldStateInterface | null;
    userInput: string | null;
    userInput2: string | null;
    vesselName: string | null;
    wordFormType: FormTypeEnum;
    rowPerPage: string | null;
    markPackage: string | null;
    portDischarge: string | null;
    referenceNo: string | null;
    retroactively: boolean;
    exhibition: boolean;
    movement: boolean;
    thirdParty: boolean;
    departureDate: string | null;
    companyExporter: ExporterCompanyInterface;
    companyConsignee: ConsigneeCompanyInterface;
    activeStep: number;
    linkTopFile: string;
    linkTopFileNoUserInput: string;
    linkBotFile: string;
    checkList: CheckPropertyInterface[] | null;
    gw: string;
    back2back: boolean;
    cumulation: boolean;
    thirdCountry: boolean;
}

interface GenerateDocProps {
    src: string;
    data?: any;
    type?: string;
    location?: number;
}

function useGeneratePage({
    exhibition,
    fieldState,
    label,
    markPackage,
    movement,
    place,
    portDischarge,
    referenceNo,
    retroactively,
    rowPerPage,
    thirdParty,
    userInput,
    userInput2,
    vesselName,
    wordFormType,
    companyConsignee,
    companyExporter,
    departureDate,
    activeStep,
    linkBotFile,
    linkTopFile,
    linkTopFileNoUserInput,
    checkList,
    back2back,
    cumulation,
    gw,
    thirdCountry
}: useGeneratePageProps) {
    const [uniqHSMap, setUniqHSMap] = useState<any | null>(null);
    const [psr, setPsr] = useState<null | PSRData[]>(null);
    const [wordTitle, setWordTitle] = useState<number>(1);
    const [resultList, setResultList] = useState<any[][] | null>(null);
    const [doc1, setDoc1] = useState<Blob[] | null>(null);
    const [doc2, setDoc2] = useState<Blob[] | null>(null);
    const [doc3, setDoc3] = useState<Blob[] | null>(null);
    const [blobFile1, setBlobFile1] = useState<Blob[] | null>(null);
    const [blobFile2, setBlobFile2] = useState<Blob[] | null>(null);
    const [blobFile3, setBlobFile3] = useState<Blob[] | null>(null);
    const [mergeFile1, setMergeFile1] = useState<Blob | null>(null);
    const [mergeFile2, setMergeFile2] = useState<Blob | null>(null);
    const [mergeFile3, setMergeFile3] = useState<Blob | null>(null);
    const [blobFileBottom, setBlobFileBottom] = useState<Blob | null>(null);
    const [fileName, setFileName] = useState<string | null>(null);
    const [submit, setSubmit] = useState(false);
    const [submitStep2, setSubmitStep2] = useState(false);
    const invoiceRepo = useInvoiceRepo();
    const { showSnackBar } = useSnackBar();
    const PARAMS: BaseQueryParams = useCompanyFolderSlug();
    const [revertData, setRevertData] = useState<CheckPropertyInterface[] | null>(null);
    const [bangKeHistory, setBangKeHistory] = useState<BangKeHistoryDataResponse[] | null>(null);

    // function GenerateInvoiceDateImport(props: ImportDataType): GenerateInvoice[] {
    //     const { data, form } = props;
    //     const listInvoiceDate: any[] = [];
    //     if (!data) return [];
    //     switch (form) {
    //         case FormTypeEnum.AC:
    //             data.forEach((item: InvoiceImportData) => {
    //                 const invoice_date = item.bill_date ?? '';
    //                 const invoice_no = item.invoice_no ?? '';
    //                 listInvoiceDate.push({ invoice_date, invoice_no });
    //             });
    //             return _.uniqBy(listInvoiceDate, 'invoice_date');
    //         case FormTypeEnum.VJ:
    //             data.forEach((item: InvoiceImportData) => {
    //                 const invoice_date = item.bill_date ?? '';
    //                 const invoice_no = item.invoice_no ?? '';
    //                 listInvoiceDate.push({ invoice_date, invoice_no });
    //             });
    //             return _.uniqBy(listInvoiceDate, 'invoice_date');
    //     }
    // }

    const AddExtraField = useCallback(
        (
            refactoredPage: any[][] | null,
            total_nt_value: TotalntValueInterface | null,
            total_sub: TotalsubInterface | null,
            title: string,
            unique_total?: GenerateInvoice[]
        ) => {
            if (!refactoredPage) return null;
            else {
                const { company_exporter_name, company_exporter_address } = companyExporter;
                const { company_consignee_name, company_consignee_address } = companyConsignee;
                switch (wordFormType) {
                    case FormTypeEnum.AC:
                        if (refactoredPage.length < 2) {
                            return refactoredPage.map((item) => {
                                return {
                                    item: item,
                                    user_input_1: userInput ?? '',
                                    user_input_2: userInput2 ?? '',
                                    page: '',
                                    ...total_nt_value,
                                    label: label ?? '',
                                    depature_date: departureDate ?? '',
                                    vessel_name: vesselName ?? '',
                                    port_of_discharge: portDischarge ?? '',
                                    company_exporter_name,
                                    mark_pack: markPackage,
                                    company_consignee_name,
                                    company_exporter_address,
                                    company_consignee_address,
                                    ...total_sub,
                                    item_place: place ?? '',
                                    retroact: retroactively ? '☑' : '☐',
                                    exhi: exhibition ? '☑' : '☐',
                                    movem: movement ? '☑' : '☐',
                                    third: thirdParty ? '☑' : '☐',
                                    refer: referenceNo ?? '',
                                    title: title ?? ''
                                };
                            });
                        } else {
                            return refactoredPage.map((item, index) => {
                                if (index !== refactoredPage.length - 1) {
                                    return {
                                        item: item,
                                        user_input_1: userInput ?? '',
                                        user_input_2: userInput2 ?? '',
                                        page: `Page ${index + 1} of ${refactoredPage.length}`,
                                        total_nt_value: '',
                                        label: label ?? '',
                                        depature_date: departureDate ?? '',
                                        vessel_name: vesselName ?? '',
                                        port_of_discharge: portDischarge ?? '',
                                        company_exporter_name,
                                        company_consignee_name,
                                        company_exporter_address,
                                        company_consignee_address,
                                        total_sub: '',
                                        item_place: place ?? '',
                                        retroact: retroactively ? '☑' : '☐',
                                        exhi: exhibition ? '☑' : '☐',
                                        movem: movement ? '☑' : '☐',
                                        third: thirdParty ? '☑' : '☐',
                                        refer: referenceNo ?? '',
                                        title: title ?? ''
                                    };
                                } else {
                                    return {
                                        item: item,
                                        user_input_1: userInput ?? '',
                                        user_input_2: userInput2 ?? '',
                                        page: `Page ${index + 1} of ${refactoredPage.length}`,
                                        ...total_nt_value,
                                        label: label ?? '',
                                        depature_date: departureDate ?? '',
                                        vessel_name: vesselName ?? '',
                                        port_of_discharge: portDischarge ?? '',
                                        company_exporter_name,
                                        company_consignee_name,
                                        company_exporter_address,
                                        company_consignee_address,
                                        ...total_sub,
                                        item_place: place ?? '',
                                        retroact: retroactively ? '☑' : '☐',
                                        exhi: exhibition ? '☑' : '☐',
                                        movem: movement ? '☑' : '☐',
                                        third: thirdParty ? '☑' : '☐',
                                        refer: referenceNo ?? '',
                                        title: title ?? ''
                                    };
                                }
                            });
                        }

                    case FormTypeEnum.AI:
                        if (refactoredPage.length < 2) {
                            return refactoredPage.map((item) => {
                                return {
                                    item: item,
                                    gw: gw !== '' ? 'GW ' + gw + ' KGM' : '',
                                    user_input_1: userInput ?? '',
                                    user_input_2: userInput2 ?? '',
                                    page: '',
                                    ...total_nt_value,
                                    label: label ?? '',
                                    depature_date: departureDate ?? '',
                                    vessel_name: vesselName ?? '',
                                    port_of_discharge: portDischarge ?? '',
                                    company_exporter_name,
                                    mark_pack: markPackage,
                                    company_consignee_name,
                                    company_exporter_address,
                                    company_consignee_address,
                                    ...total_sub,
                                    item_place: place ?? '',
                                    back: back2back ? '☑' : '☐',
                                    exhi: exhibition ? '☑' : '☐',
                                    cumu: cumulation ? '☑' : '☐',
                                    third: thirdCountry ? '☑' : '☐',
                                    refer: referenceNo ?? '',
                                    title: title ?? ''
                                };
                            });
                        } else {
                            return refactoredPage.map((item, index) => {
                                if (index !== refactoredPage.length - 1) {
                                    return {
                                        item: item,
                                        gw: gw !== '' ? 'GW ' + gw + ' KGM' : '',
                                        user_input_1: userInput ?? '',
                                        user_input_2: userInput2 ?? '',
                                        page: `Page ${index + 1} of ${refactoredPage.length}`,
                                        total_nt_value: '',
                                        label: label ?? '',
                                        depature_date: departureDate ?? '',
                                        vessel_name: vesselName ?? '',
                                        port_of_discharge: portDischarge ?? '',
                                        company_exporter_name,
                                        company_consignee_name,
                                        company_exporter_address,
                                        company_consignee_address,
                                        total_sub: '',
                                        item_place: place ?? '',
                                        back: back2back ? '☑' : '☐',
                                        exhi: exhibition ? '☑' : '☐',
                                        cumu: cumulation ? '☑' : '☐',
                                        third: thirdCountry ? '☑' : '☐',
                                        refer: referenceNo ?? '',
                                        title: title ?? ''
                                    };
                                } else {
                                    return {
                                        item: item,
                                        gw: gw !== '' ? 'GW ' + gw + ' KGM' : '',
                                        user_input_1: userInput ?? '',
                                        user_input_2: userInput2 ?? '',
                                        page: `Page ${index + 1} of ${refactoredPage.length}`,
                                        ...total_nt_value,
                                        label: label ?? '',
                                        depature_date: departureDate ?? '',
                                        vessel_name: vesselName ?? '',
                                        port_of_discharge: portDischarge ?? '',
                                        company_exporter_name,
                                        company_consignee_name,
                                        company_exporter_address,
                                        company_consignee_address,
                                        ...total_sub,
                                        item_place: place ?? '',
                                        back: back2back ? '☑' : '☐',
                                        exhi: exhibition ? '☑' : '☐',
                                        cumu: cumulation ? '☑' : '☐',
                                        third: thirdCountry ? '☑' : '☐',
                                        refer: referenceNo ?? '',
                                        title: title ?? ''
                                    };
                                }
                            });
                        }

                    // case FormTypeEnum.VJ:
                    //     if (refactoredPage.length < 2) {
                    //         return refactoredPage.map((item) => {
                    //             return {
                    //                 item: item,
                    //                 user_input_1: userInput ?? '',
                    //                 user_input_2: userInput2 ?? '',
                    //                 page: '',
                    //                 page2: '',
                    //                 label: label ?? '',
                    //                 depature_date: departureDate ?? '',
                    //                 vessel_name: vesselName ?? '',
                    //                 port_of_discharge: portDischarge ?? '',
                    //                 company_exporter_name,
                    //                 company_consignee_name,
                    //                 company_exporter_address,
                    //                 company_consignee_address,
                    //                 ...total_sub,
                    //                 item_place: place ?? '',
                    //                 refer: referenceNo ?? '',
                    //                 title: title ?? ''
                    //             };
                    //         });
                    //     } else {
                    //         return refactoredPage.map((item, index) => {
                    //             if (index !== refactoredPage.length - 1) {
                    //                 return {
                    //                     item: item,
                    //                     user_input_1: userInput ?? '',
                    //                     user_input_2: userInput2 ?? '',
                    //                     page: `Page ${index + 1} of ${refactoredPage.length}`,
                    //                     page2: `${index + 1} / ${refactoredPage.length}`,
                    //                     label: label ?? '',
                    //                     vessel_name: vesselName ?? '',
                    //                     port_of_discharge: portDischarge ?? '',
                    //                     depature_date: departureDate ?? '',
                    //                     company_exporter_name,
                    //                     company_consignee_name,
                    //                     company_exporter_address,
                    //                     company_consignee_address,
                    //                     total_sub: '',
                    //                     item_place: place ?? '',
                    //                     refer: referenceNo ?? '',
                    //                     title: title ?? ''
                    //                 };
                    //             } else {
                    //                 return {
                    //                     item: item,
                    //                     user_input_1: userInput ?? '',
                    //                     user_input_2: userInput2 ?? '',
                    //                     page: `Page ${index + 1} of ${refactoredPage.length}`,
                    //                     page2: `${index + 1} / ${refactoredPage.length}`,
                    //                     label: label ?? '',
                    //                     vessel_name: vesselName ?? '',
                    //                     port_of_discharge: portDischarge ?? '',
                    //                     depature_date: departureDate ?? '',
                    //                     company_exporter_name,
                    //                     company_consignee_name,
                    //                     company_exporter_address,
                    //                     company_consignee_address,
                    //                     ...total_sub,
                    //                     item_place: place,
                    //                     refer: referenceNo ?? '',
                    //                     title: title ?? ''
                    //                 };
                    //             }
                    //         });
                    //     }
                }
            }
        },
        [
            label,
            userInput,
            userInput2,
            vesselName,
            portDischarge,
            departureDate,
            companyExporter,
            companyConsignee,
            place,
            retroactively,
            exhibition,
            movement,
            thirdParty,
            referenceNo,
            wordFormType,
            gw,
            back2back,
            cumulation,
            thirdCountry
        ]
    );

    const MapWordPage = useCallback(
        (data: any[]) => {
            if (rowPerPage) {
                const num = parseInt(rowPerPage);
                const balance = data.length % num;
                const d = Math.floor(data.length / num);
                const cloneData = [...data];
                const result = [];
                for (let i = 0; i < d; i++) {
                    result.push(cloneData.splice(0, num));
                }
                if (balance) {
                    result.push(cloneData);
                }

                return result;
            } else return null;
        },
        [rowPerPage]
    );

    const MapWordData = useCallback(
        (data: CheckPropertyInterface, index: string, mark_pack: string, item_inv_no: string, item_inv_da: string) => {
            // (data: CheckPropertyInterface, index: string, mark_pack: string) => {
            if (!fieldState || !wordFormType || !data) return;
            else {
                const { item } = data;
                console.log(data);
                switch (wordFormType) {
                    case FormTypeEnum.AC:
                        return {
                            index: index,
                            item_hs: fieldState.item_hs.isHidden ? item.hs?.slice(0, 6) : '',
                            item_npl: `ART NO.` + item.code ?? '',
                            item_name: fieldState.item_name.isHidden ? ConvertName(item.name) : '',
                            item_nt_value: item.total.toString() + ' PCS',
                            item_origin_criteria: FromHsToOriginCriteria(item.hs ?? ''),
                            mark_pack,
                            item_inv_no,
                            item_inv_da
                        };

                    case FormTypeEnum.AI:
                        let item_criteria = item.origin_criteria;
                        const { id } = item;
                        if (bangKeHistory) {
                            const historyList = bangKeHistory.filter((value: BangKeHistoryDataResponse) => value.ecus_id === id);
                            if (historyList.length != 0) {
                                const history = historyList[0];
                                const { criteria = '', price_percent = 0 } = history;
                                if (criteria !== 'WO') item_criteria = 'RVC ' + ConvertCurrency(price_percent) + '% + CTSH';
                                else if (criteria === 'WO') item_criteria = 'WO';
                            }
                        }

                        return {
                            index: index,
                            item_npl: `ART NO.` + item.code ?? '',
                            item_name: fieldState.item_name.isHidden ? ConvertName(item.name) : '',
                            item_hs: fieldState.item_hs.isHidden ? item.hs?.slice(0, 6) : '',
                            item_origin_criteria: item_criteria,
                            item_nt_value: item.total.toString() + ' PCS',
                            item_sub: item.nt_value ? item.nt_value + ' USD' : '0 USD',
                            mark_pack,
                            item_inv_no,
                            item_inv_da
                        };

                    // case FormTypeEnum.VJ:
                    //     return {
                    //         index: index,
                    //         item_npl: item.code ?? '',
                    //         item_name: fieldState.item_name.isHidden ? ConvertName(item.name) : '',
                    //         item_hs: fieldState.item_hs.isHidden ? item.hs?.slice(0, 6) : '',
                    //         item_origin_criteria: item.origin_criteria,
                    //         item_nt_value: item.total.toString() + ' PCS',
                    //         item_inv_no,
                    //         item_inv_da
                    //     };

                    default:
                        return {};
                }
            }
        },
        [fieldState, wordFormType, bangKeHistory]
    );

    const GetFilterPsrList = useCallback(
        (hs: string) => {
            if (!psr || !hs) return null;
            else {
                const newHS = hs.slice(0, 6);
                return psr.filter((item: PSRData) => item.hs === newHS);
            }
        },
        [psr]
    );

    const SubmitExtraStepHandler = useCallback(() => {
        let revertData: CheckPropertyInterface[] = [];
        uniqHSMap?.forEach((value: CheckPropertyInterface[], key: string) => {
            revertData.push(...value);
        });
        setRevertData(revertData);
    }, [uniqHSMap]);

    const OriginHandler = useCallback(
        (id: string, hs: string, originate: string, isCombine: boolean) => {
            if (!uniqHSMap) return;
            else {
                let newList: CheckPropertyInterface[] = [];
                uniqHSMap.forEach((list: CheckPropertyInterface[], key: string) => {
                    if (key === hs) {
                        if (isCombine) {
                            newList = list.map((itemCheck: CheckPropertyInterface) => {
                                const { item, combine } = itemCheck;
                                let newEcusItem: IEcusData = { ...item };
                                let newItemCheck: CheckPropertyInterface = { ...itemCheck };

                                if (item.id === id) {
                                    newEcusItem = { ...item, origin_criteria: originate };
                                    newItemCheck = { ...itemCheck, combine: isCombine, item: newEcusItem };
                                } else if (combine) {
                                    newEcusItem = { ...item, origin_criteria: originate };
                                    newItemCheck = { ...itemCheck, item: newEcusItem };
                                }
                                return newItemCheck;
                            });
                            uniqHSMap.set(key, newList);
                        } else {
                            newList = list.map((itemCheck: CheckPropertyInterface) => {
                                const { item } = itemCheck;
                                let newEcusItem: IEcusData = { ...item };
                                let newItemCheck: CheckPropertyInterface = { ...itemCheck };

                                if (item.id === id) {
                                    newEcusItem = { ...item, origin_criteria: originate };
                                    newItemCheck = { ...itemCheck, combine: isCombine, item: newEcusItem };
                                }

                                return newItemCheck;
                            });
                            uniqHSMap.set(key, newList);
                        }
                    }
                });
                const newMap = new Map(uniqHSMap);
                setUniqHSMap(newMap);
            }
        },
        [uniqHSMap]
    );

    const MapNewOriginate = useCallback(
        (combineList: CheckPropertyInterface[], psrList: PSRData[] | null) => {
            switch (wordFormType) {
                case FormTypeEnum.VJ:
                    return combineList.map((value: CheckPropertyInterface) => {
                        const newItem = { ...value.item };
                        newItem.origin_criteria = psrList ? psrList[0].VJ : '';
                        return { ...value, item: newItem };
                    });
            }
        },
        [wordFormType]
    );

    function GetUniqHSMap(uniqHSList: string[], checkList: CheckPropertyInterface[] | null | undefined, psr: PSRData[] | null) {
        if (!checkList || !uniqHSList || !psr) return;
        else {
            const newMap = new Map();
            uniqHSList.forEach((item: string) => {
                const psrList = GetFilterPsrList(item);
                const newList = checkList.filter((value: CheckPropertyInterface) => value.item.hs === item);
                const combineList = MapNewOriginate(newList, psrList);
                newMap.set(item, combineList);
            });
            setUniqHSMap(newMap);
        }
    }

    function GenerateTotalNTValue(data: CheckPropertyInterface[]) {
        let total = 0;
        data.forEach((item: CheckPropertyInterface) => {
            total += item.item.nt_value;
        });

        return { total_nt_value: `USD ${ConvertCurrency(total)}` };
    }

    function GenerateInvoiceDate(data: CheckPropertyInterface[]): GenerateInvoice[] {
        const listInvoiceDate: any[] = [];
        data.forEach((item: CheckPropertyInterface) => {
            const invoice_date = item.item.bill_date ?? '';
            const invoice_no = item.item.invoice_no ?? '';
            listInvoiceDate.push({ invoice_date, invoice_no });
        });
        return _.uniqBy(listInvoiceDate, 'invoice_date');
    }

    function GenerateInvoiceNo(data: CheckPropertyInterface[]): GenerateInvoice[] {
        const listInvoiceNo: any[] = [];
        data.forEach((item: CheckPropertyInterface) => {
            const invoice_date = item.item.bill_date ?? '';
            const invoice_no = item.item.invoice_no ?? '';
            listInvoiceNo.push({ invoice_date, invoice_no });
        });

        return _.uniqBy(listInvoiceNo, 'invoice_no');
    }

    const GenerateTotalSub = useCallback<(data: CheckPropertyInterface[]) => null | TotalsubInterface>(
        (data: CheckPropertyInterface[]) => {
            if (!data) return null;
            else {
                switch (wordFormType) {
                    case FormTypeEnum.AC:
                        let total_ac = 0;
                        data.forEach((item: CheckPropertyInterface) => {
                            total_ac += item.item.total;
                        });

                        return { total_sub: `Total: ${ConvertCurrency(total_ac)}` };

                    case FormTypeEnum.VJ:
                        let total_vj = 0;
                        data.forEach((item: CheckPropertyInterface) => {
                            total_vj += item.item.total;
                        });

                        return { total_sub: `${total_vj} PIECES` };

                    case FormTypeEnum.AI:
                        let total_ai = 0;
                        data.forEach((item: CheckPropertyInterface) => {
                            total_ai += item.item.total;
                        });

                        return { total_sub: `Total: ${ConvertCurrency(total_ai)}` };
                    default:
                        return null;
                }
            }
        },
        [wordFormType]
    );

    function GeneratePageAC(data: CheckPropertyInterface[], markPackage: string, wordTitle?: number) {
        const total_sub_ac = GenerateTotalSub(data);
        const invoice_no_ac = GenerateInvoiceNo(data);
        const invoice_date_ac = GenerateInvoiceDate(data);
        const total_nt_value_ac = GenerateTotalNTValue(data);
        const wordTitleConverted_ac = wordTitle ? GenerateWordTitle(wordTitle) : GenerateWordTitle(1);
        const unique_total_ac = _.unionWith([...invoice_no_ac, ...invoice_date_ac], _.isEqual);
        const firstInvoiceNo_ac = data[0].item.invoice_no ?? '';
        const firstBillDate_ac = data[0].item.bill_date ?? ConvertDate(new Date());
        setFileName(firstBillDate_ac + '_' + firstInvoiceNo_ac);
        const listNumb = unique_total_ac.length > 6 ? unique_total_ac.length : 6;
        const refactoredPage_ac = MapWordPage(data);

        if (!refactoredPage_ac) return null;
        else {
            let count = 1;
            const newData = refactoredPage_ac.map((item: any[]) => {
                const newPage = [];
                for (let i = 0; i < listNumb; i++) {
                    if (item[i]) {
                        if (i === 0) {
                            const new_data = MapWordData(
                                item[i],
                                count.toString(),
                                markPackage,
                                unique_total_ac[i]?.invoice_no ?? '',
                                ConvertDateImport(unique_total_ac[i]?.invoice_date ?? '')
                            );

                            // const new_data = MapWordData(item[i], count.toString(), markPackage);

                            newPage.push(new_data);
                            count++;
                        } else {
                            const new_data = MapWordData(
                                item[i],
                                count.toString(),
                                '',
                                unique_total_ac[i]?.invoice_no ?? '',
                                ConvertDateImport(unique_total_ac[i]?.invoice_date ?? '')
                            );

                            // const new_data = MapWordData(item[i], count.toString(), '');

                            newPage.push(new_data);
                            count++;
                        }
                    } else {
                        newPage.push({
                            index: '',
                            item_hs: '',
                            item_npl: '',
                            item_name: '',
                            item_nt_value: '',
                            item_origin_criteria: '',
                            mark_pack: '',
                            item_inv_no: unique_total_ac[i]?.invoice_no ?? '',
                            item_inv_da: ConvertDateImport(unique_total_ac[i]?.invoice_date ?? '')
                        });
                    }
                }
                return newPage;
            });
            return AddExtraField(newData, total_nt_value_ac, total_sub_ac, wordTitleConverted_ac, unique_total_ac);
        }
    }

    // function GeneratePageVJ(data: CheckPropertyInterface[], markPackage: string, wordTitle?: number) {
    //     const total_sub_vj = GenerateTotalSub(data);
    //     const invoice_no_vj = GenerateInvoiceNo(data);
    //     const invoice_date_vj = GenerateInvoiceDate(data);
    //     const wordTitleConverted_vj = wordTitle ? GenerateWordTitle(wordTitle) : GenerateWordTitle(1);
    //     const unique_total_vj = _.unionWith([...invoice_no_vj, ...invoice_date_vj], _.isEqual);
    //     const firstInvoiceNo_vj = data[0].item.invoice_no ?? '';
    //     const firstBillDate_vj = data[0].item.bill_date ?? ConvertDate(new Date());
    //     setFileName(firstBillDate_vj + '_' + firstInvoiceNo_vj);

    //     const refactoredPage_vj = MapWordPage(data);
    //     if (!refactoredPage_vj) return null;
    //     else {
    //         let count = 1;
    //         const newData = refactoredPage_vj.map((item: any[]) => {
    //             const newPage = [];
    //             for (let i = 0; i < 7; i++) {
    //                 if (item[i]) {
    //                     if (i === 0) {
    //                         const new_data = MapWordData(
    //                             item[i],
    //                             count.toString(),
    //                             markPackage,
    //                             unique_total_vj[i]?.invoice_no ?? '',
    //                             ConvertDateImport(unique_total_vj[i]?.invoice_date ?? '')
    //                         );
    //                         newPage.push(new_data);
    //                         count++;
    //                     } else {
    //                         const new_data = MapWordData(
    //                             item[i],
    //                             count.toString(),
    //                             '',
    //                             unique_total_vj[i]?.invoice_no ?? '',
    //                             ConvertDateImport(unique_total_vj[i]?.invoice_date ?? '')
    //                         );
    //                         newPage.push(new_data);
    //                         count++;
    //                     }
    //                 } else {
    //                     newPage.push({
    //                         index: '',
    //                         item_npl: '',
    //                         item_name: '',
    //                         item_hs: '',
    //                         item_origin_criteria: '',
    //                         item_nt_value: '',
    //                         item_inv_no: '',
    //                         item_inv_da: ''
    //                     });
    //                 }
    //             }
    //             return newPage;
    //         });
    //         return AddExtraField(newData, null, total_sub_vj, wordTitleConverted_vj,unique_total_ac);
    //     }
    // }

    function GeneratePageAI(data: CheckPropertyInterface[], markPackage: string, wordTitle?: number) {
        const total_sub_ai = GenerateTotalSub(data);
        const invoice_no_ai = GenerateInvoiceNo(data);
        const invoice_date_ai = GenerateInvoiceDate(data);
        const total_nt_value_ai = GenerateTotalNTValue(data);
        const wordTitleConverted_ai = wordTitle ? GenerateWordTitle(wordTitle) : GenerateWordTitle(1);
        const unique_total_ai = _.unionWith([...invoice_no_ai, ...invoice_date_ai], _.isEqual);
        const firstInvoiceNo_ai = data[0].item.invoice_no ?? '';
        const firstBillDate_ai = data[0].item.bill_date ?? ConvertDate(new Date());
        setFileName(firstBillDate_ai + '_' + firstInvoiceNo_ai);
        const listNumb = unique_total_ai.length > 8 ? unique_total_ai.length : 8;
        const refactoredPage_ai = MapWordPage(data);

        if (!refactoredPage_ai) return null;
        else {
            let count = 1;
            const newData = refactoredPage_ai.map((item: any[]) => {
                const newPage = [];
                for (let i = 0; i < listNumb; i++) {
                    if (item[i]) {
                        if (i === 0) {
                            const new_data = MapWordData(
                                item[i],
                                count.toString(),
                                markPackage,
                                unique_total_ai[i]?.invoice_no ?? '',
                                ConvertDateImport(unique_total_ai[i]?.invoice_date ?? '')
                            );

                            // const new_data = MapWordData(item[i], count.toString(), markPackage);

                            newPage.push(new_data);
                            count++;
                        } else {
                            const new_data = MapWordData(
                                item[i],
                                count.toString(),
                                '',
                                unique_total_ai[i]?.invoice_no ?? '',
                                ConvertDateImport(unique_total_ai[i]?.invoice_date ?? '')
                            );

                            // const new_data = MapWordData(item[i], count.toString(), '');
                            newPage.push(new_data);
                            count++;
                        }
                    } else {
                        newPage.push({
                            index: '',
                            item_hs: '',
                            item_npl: '',
                            item_name: '',
                            item_nt_value: '',
                            item_sub: '',
                            item_origin_criteria: '',
                            mark_pack: '',
                            item_inv_no: unique_total_ai[i]?.invoice_no ?? '',
                            item_inv_da: ConvertDateImport(unique_total_ai[i]?.invoice_date ?? '')
                        });
                    }
                }
                return newPage;
            });
            return AddExtraField(newData, total_nt_value_ai, total_sub_ai, wordTitleConverted_ai, unique_total_ai);
        }
    }

    function GeneratePage(
        data: CheckPropertyInterface[] | null | undefined,
        wordFormType: FormTypeEnum | null,
        markPackage: string | null,
        wordTitle?: number
    ) {
        if (!data || !markPackage) return null;
        else {
            switch (wordFormType) {
                case FormTypeEnum.AC:
                    return GeneratePageAC(data, markPackage, wordTitle);
                case FormTypeEnum.AI:
                    return GeneratePageAI(data, markPackage, wordTitle);
                // case FormTypeEnum.VJ:
                //     return GeneratePageVJ(data, markPackage, wordTitle);
                default:
                    return null;
            }
        }
    }

    //working
    function GenerateDoc({ src, data, location, type }: GenerateDocProps) {
        function ConvertFile(error: any, content: any) {
            const docLocation = location ?? 0;
            const docType = type ?? 'bottom';

            if (error) {
                throw error;
            }
            const zip = new PizZip(content);
            const doc = new Docxtemplater(zip, {
                paragraphLoop: true,
                linebreaks: true
            });

            if (data) {
                doc.setData({ ...data });
            }
            try {
                doc.render();
            } catch (error) {
                throw error;
            }

            const out = doc.getZip().generate({
                type: 'blob',
                mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            });

            if (docType === 'bottom') {
                setBlobFileBottom(out);
            }
            if (docType === 'top') {
                switch (docLocation) {
                    case 1:
                        setDoc1((prev: Blob[] | null) => {
                            if (prev === null) return [out];
                            else return [...prev, out];
                        });
                        return;

                    case 2:
                        setDoc2((prev: Blob[] | null) => {
                            if (prev === null) return [out];
                            else return [...prev, out];
                        });
                        return;

                    case 3:
                        setDoc3((prev: Blob[] | null) => {
                            if (prev === null) return [out];
                            else return [...prev, out];
                        });
                        return;
                    default:
                        return;
                }
            }
        }

        function loadFile(url: string, callback: any) {
            PizZipUtils.getBinaryContent(url, callback);
        }
        loadFile(src, ConvertFile);
    }

    async function FetchPSR(data: PsrQueryParams) {
        dispatch(setLoading(true));
        const response = await invoiceRepo.getPSR(data);
        match(response)
            .with({ state: ResultState.success }, ({ data }) => {
                setPsr((prev: null | any[]) => {
                    if (prev === null) return [...data.results];
                    else return [...prev, ...data.results];
                });
            })
            .with({ state: ResultState.failed }, ({ exception }) => {
                showSnackBar(exception.message, SnackBarAlertEnum.error);
            })
            .exhaustive();
        dispatch(setLoading(false));
    }

    const psrQueryParams = useMemo(() => {
        if (checkList) {
            return {
                ...PARAMS,
                hs: checkList.map((item) => {
                    return item.item.hs?.slice(0, 6) ?? '';
                })
            };
        } else return null;
    }, [checkList]);

    async function GetWordMergeFile(data: Blob[], type: number) {
        const response = await invoiceRepo.getMergerdWord(data);
        match(response)
            .with({ state: ResultState.success }, ({ data }) => {
                switch (type) {
                    case 1:
                        setMergeFile1(data.data);
                        return;

                    case 2:
                        setMergeFile2(data.data);
                        return;

                    case 3:
                        setMergeFile3(data.data);
                        return;
                }
            })
            .with({ state: ResultState.failed }, ({ exception }) => {
                console.log(exception);
            })
            .exhaustive();
    }

    function isCheckListProvider(
        checkList: null | CheckPropertyInterface[] | undefined,
        markPackage: string | null,
        fieldState: FieldStateInterface | null,
        wordFormType: FormTypeEnum | null,
        rowPerPage: string | null,
        wordTitle: number,
        activeStep: number
    ) {
        if (checkList && markPackage && fieldState && wordFormType && rowPerPage && activeStep === 1 && wordTitle < 4) return true;
        else return false;
    }

    // useEffect(() => {
    //     if (!wordFormType || !psrQueryParams) return;
    //     else {
    //         switch (wordFormType) {
    //             case FormTypeEnum.VJ:
    //                 FetchPSR(psrQueryParams);
    //                 return;

    //             case FormTypeEnum.AJ:
    //                 FetchPSR(psrQueryParams);
    //                 return;
    //             default:
    //                 return;
    //         }
    //     }
    // }, [wordFormType, psrQueryParams]);

    async function GetBangKeHistory(params: BalanceParams) {
        const response = await invoiceRepo.getBangKeHistory(params);
        match(response)
            .with({ state: ResultState.success }, ({ data }) => {
                setBangKeHistory(data.data);
            })
            .with({ state: ResultState.failed }, ({ exception }) => {})
            .exhaustive();
    }

    useEffect(() => {
        switch (wordFormType) {
            case FormTypeEnum.AI:
                GetBangKeHistory(PARAMS);
                return;
            default:
                return;
        }
    }, [wordFormType]);

    useEffect(() => {
        if (isCheckListProvider(checkList, markPackage, fieldState, wordFormType, rowPerPage, wordTitle, activeStep)) {
            switch (wordFormType) {
                // case FormTypeEnum.VJ || FormTypeEnum.AJ:
                //     const listItem = checkList?.map(({ item }) => item);
                //     const uniqHSList = _.unionBy(listItem, 'hs').map((item: IEcusData) => item.hs);
                //     GetUniqHSMap(uniqHSList, checkList, psr);
                //     return;
                default:
                    const results = GeneratePage(checkList, wordFormType, markPackage, wordTitle);
                    if (results) {
                        setResultList((prev: null | any[][]) => {
                            if (prev === null) return [results];
                            else return [...prev, results];
                        });
                        setWordTitle(wordTitle + 1);
                    }
                    return;
            }
        }
    }, [checkList, fieldState, wordFormType, activeStep, markPackage, rowPerPage, wordTitle, activeStep]);

    // useEffect(() => {
    //     if (revertData && wordFormType && activeStep === 2 && wordTitle < 4) {
    //         const results = GeneratePage(revertData, wordFormType, markPackage, wordTitle);
    //         if (results) {
    //             setResultList((prev: null | any[][]) => {
    //                 if (prev === null) return [results];
    //                 else return [...prev, results];
    //             });
    //             setWordTitle(wordTitle + 1);
    //         }
    //     }
    // }, [revertData, wordFormType, activeStep, markPackage, wordTitle, activeStep]);

    function SeperateDoc({ resultList, wordFormType }: { resultList: null | any[][]; wordFormType: FormTypeEnum }) {
        if (resultList && wordFormType && resultList.length === 3 && wordTitle === 4) {
            resultList.forEach((list, index) => {
                if (index === 0) {
                    list.forEach((result, resultIndex) => {
                        if (userInput2 && resultIndex === list.length - 1) {
                            GenerateDoc({ src: linkTopFile, data: result, type: 'top', location: 1 });
                        } else {
                            GenerateDoc({ src: linkTopFileNoUserInput, data: result, type: 'top', location: 1 });
                        }
                    });
                } else if (index === 1) {
                    list.forEach((result, resultIndex) => {
                        if (userInput2 && resultIndex === list.length - 1) {
                            GenerateDoc({ src: linkTopFile, data: result, type: 'top', location: 2 });
                        } else {
                            GenerateDoc({ src: linkTopFileNoUserInput, data: result, type: 'top', location: 2 });
                        }
                    });
                } else {
                    list.forEach((result, resultIndex) => {
                        if (userInput2 && resultIndex === list.length - 1) {
                            GenerateDoc({ src: linkTopFile, data: result, type: 'top', location: 3 });
                        } else {
                            GenerateDoc({ src: linkTopFileNoUserInput, data: result, type: 'top', location: 3 });
                        }
                    });
                }
            });

            GenerateDoc({ src: linkBotFile });
        }
    }

    useEffect(() => {
        SeperateDoc({ resultList, wordFormType });
    }, [resultList, wordFormType]);

    const GenerateBlobFile = useCallback(
        ({ doc, location }: { doc: Blob[] | null; location: number }) => {
            if (doc && blobFileBottom && wordTitle === 4) {
                const newList: Blob[] = [];
                const combineFile = doc.map((item) => {
                    return [item, blobFileBottom];
                });
                if (combineFile) {
                    combineFile.forEach((item) => {
                        newList.push(...item);
                    });
                }
                if (newList.length !== 0) {
                    if (location === 1) setBlobFile1(newList);
                    else if (location === 2) setBlobFile2(newList);
                    else setBlobFile3(newList);
                }
            } else return;
        },
        [wordTitle, blobFileBottom]
    );

    useEffect(() => {
        const timeOut = setTimeout(() => {
            GenerateBlobFile({ doc: doc1, location: 1 });
        }, 3000);
        return () => clearTimeout(timeOut);
    }, [doc1]);

    useEffect(() => {
        const timeOut = setTimeout(() => {
            GenerateBlobFile({ doc: doc1, location: 2 });
        }, 3000);
        return () => clearTimeout(timeOut);
    }, [doc2]);

    useEffect(() => {
        const timeOut = setTimeout(() => {
            GenerateBlobFile({ doc: doc3, location: 3 });
        }, 3000);
        return () => clearTimeout(timeOut);
    }, [doc3]);

    // useEffect(() => {
    //     if (wordTitle === 4 && blobFileBottom) {
    //         if (blobFile1) {
    //             GetWordMergeFile(blobFile1, 1);
    //         }
    //         if (blobFile2) {
    //             GetWordMergeFile(blobFile2, 2);
    //         }
    //         if (blobFile3) {
    //             GetWordMergeFile(blobFile3, 3);
    //         }
    //     }
    // }, [blobFile1, blobFile2, blobFile3, wordTitle, blobFileBottom]);

    useEffect(() => {
        if (blobFile1) {
            GetWordMergeFile(blobFile1, 1);
        }
        if (blobFile2) {
            GetWordMergeFile(blobFile2, 2);
        }
        if (blobFile3) {
            GetWordMergeFile(blobFile3, 3);
        }
    }, [blobFile1, blobFile2, blobFile3]);

    useEffect(() => {
        if (mergeFile1 && mergeFile2 && mergeFile3 && !submit) {
            const fileSize1 = mergeFile1.size;
            const fileSize2 = mergeFile2.size;
            const fileSize3 = mergeFile3.size;

            if (Math.abs(fileSize1 - fileSize2) <= 150 && Math.abs(fileSize2 - fileSize3) <= 150) {
                setSubmitStep2(true);
            }
        }
        if (mergeFile1 && mergeFile2 && mergeFile3 && submit && fileName) {
            if (mergeFile1) {
                setTimeout(() => {
                    saveAs(mergeFile1, fileName + '.docx');
                }, 500);
            }
            if (mergeFile2) {
                setTimeout(() => {
                    saveAs(mergeFile2, fileName + '.docx');
                }, 500);
            }
            if (mergeFile3) {
                setTimeout(() => {
                    saveAs(mergeFile3, fileName + '.docx');
                }, 500);
            }

            setTimeout(() => {
                window.location.href = window.location.href;
            }, 3000);
        }
    }, [mergeFile1, mergeFile2, mergeFile3, submit, fileName]);

    function ResetDefault() {
        setResultList(null);
        setWordTitle(1);
        setDoc1(null);
        setDoc2(null);
        setDoc3(null);
        setBlobFile1(null);
        setBlobFile2(null);
        setBlobFile3(null);
        setBlobFileBottom(null);
        setSubmitStep2(false);
        setSubmit(false);
    }

    return { setSubmit, submitStep2, uniqHSMap, OriginHandler, SubmitExtraStepHandler, revertData, ResetDefault };
}

export default useGeneratePage;
