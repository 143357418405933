import { useCompanyFolderSlug } from 'components/BalanceImport';
import { useEcusRepo } from 'di';
import { IEcusData } from 'models';
import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BaseQueryParams, FieldsQueryParams, PagingQueryParams } from 'repository';
import { setLoading } from 'store/Global';
import { ContextProviderProps, FormTypeEnum, VoidCallBack, voidCallback } from '../TableInvoice/InvoiceProvider';
import { DataIncomeEnum } from './TableInvoiceWord';

const LINK_FORMS = {
    formAC: {
        top: 'https://dev-be.eximhow.com/media/uploads/23/11/form_e_top_12.14.48.8.docx',
        topNoUserInput: 'https://dev-be.eximhow.com/media/uploads/23/11/form_e_top_12.14.49.8.docx',
        bot: 'https://dev-be.eximhow.com/media/uploads/23/11/form_e_bottom_v4.docx'
    },
    formA: {},
    formVJ: {
        top: 'https://dev-be.eximhow.com/media/uploads/23/12/form_vj_top_1.28.docx',
        topNoUserInput: 'https://dev-be.eximhow.com/media/uploads/23/12/form_vj_top_2.28.docx',
        bot: 'https://dev-be.eximhow.com/media/uploads/23/12/form_vj_bottom_1.4.docx'
    },
    formAI: {
        top: 'https://dev-be-new.eximhow.com/media/uploads/24/01/form_ai_top_1.12.docx',
        topNoUserInput: 'https://dev-be-new.eximhow.com/media/uploads/24/01/form_ai_top_2.12.docx',
        bot: 'https://dev-be-new.eximhow.com/media/uploads/24/01/form_ai_bottom_1.6.docx'
    }
};

export interface ExporterCompanyInterface {
    company_exporter_name: string;
    company_exporter_address: string;
}

export interface ConsigneeCompanyInterface {
    company_consignee_name: string;
    company_consignee_address: string;
}

export enum FileNameEnum {
    top = '_top',
    bottom = '_bottom'
}

function ConvertTotalntValueImport(str: string) {
    let newstring = str;
    if (str.includes('$')) {
        newstring = str.replace('$', 'USD ');
    }
    return newstring;
}

export interface ExcelMapDataInterface {
    index: string;
    item_name: string;
    item_hs: string;
    item_npl: string;
    item_origin_criteria: string;
    item_nt_value: string;
    item_inv_no?: string;
    item_inv_da?: string;
    mark_pack: string;
}

export interface FieldStateInterface {
    item_hs: { isHidden: boolean };
    item_name: { isHidden: boolean };
    user_input: { isHidden: boolean };
}

export interface FormImportBottomData {
    total_sub: string;
    total_nt_value: string;
}

export const FIELD_STATE_INIT = {
    item_hs: { isHidden: false },
    item_name: { isHidden: false },
    user_input: { isHidden: false }
};

export interface InvoiceWordProviderInterface {
    ecusData: null | IEcusData[];
    checkAll: boolean;
    setCheckAll: voidCallback;
    activeStep: number;
    setActiveStep: voidCallback;
    wordFormType: FormTypeEnum;
    setWordFormType: voidCallback;
    fieldState: FieldStateInterface;
    setFieldState: voidCallback;
    setSearchInput: voidCallback;
    limit: number;
    setLimit: voidCallback;
    offset: number;
    setOffset: voidCallback;
    count: null | number;
    setCount: voidCallback;
    label: string;
    setLabel: voidCallback;
    userInput: string;
    setUserInput: voidCallback;
    place: string;
    setPlace: voidCallback;
    vesselName: string;
    setVesselName: voidCallback;
    portDischarge: string;
    setPortDischarge: voidCallback;
    departureDate: string;
    setDepartureDate: voidCallback;
    companyConsignee: ConsigneeCompanyInterface;
    setCompanyConsignee: voidCallback;
    companyExporter: ExporterCompanyInterface;
    setCompanyExporter: voidCallback;
    markPackage: string;
    setMarkPackage: voidCallback;
    sheetActiveStep: number;
    setSheetActiveStep: voidCallback;
    ordering: null | string;
    setOrdering: voidCallback;
    selectableFields: { [key: string]: any[] } | null;
    setSelectableFields: voidCallback;
    userInput2: string;
    setUserInput2: voidCallback;
    thirdParty: boolean;
    setThirdParty: voidCallback;
    exhibition: boolean;
    setExhibition: voidCallback;
    movement: boolean;
    setMovement: voidCallback;
    retroactively: boolean;
    setRetroactively: voidCallback;
    referenceNo: string;
    setReferenceNo: voidCallback;
    selectParams: { [key: string]: any } | null;
    setSelectParams: voidCallback;
    wordTitle: number;
    setWordTitle: voidCallback;
    rowPerPage: string;
    setRowPerPage: voidCallback;
    linkTopFile: string;
    linkTopFileNoUserInput: string;
    linkBotFile: string;
    ResetInvoiceModal: (type: DataIncomeEnum) => void;
    GenerateWordTitle: (num: number) => string;
    FromHsToOriginCriteria: (hs: string) => string;
    ConvertName: (name: string | undefined) => string;
    ConvertDateImport: (date: any) => any;
    FilterLoadMore: voidCallback;
    loadPrevious: boolean;
    FilterLoadPrevious: voidCallback;
    gw: string;
    setGW: voidCallback;
    back2back: boolean;
    setBack2Back: voidCallback;
    cumulation: boolean;
    setCumulation: voidCallback;
    thirdCountry: boolean;
    setThirdCountry: voidCallback;
}

export const InvoiceWordContext = createContext<InvoiceWordProviderInterface>({
    ecusData: null,
    checkAll: false,
    setCheckAll: VoidCallBack,
    activeStep: 0,
    setActiveStep: VoidCallBack,
    wordFormType: FormTypeEnum.AC,
    setWordFormType: VoidCallBack,
    fieldState: FIELD_STATE_INIT,
    setFieldState: VoidCallBack,
    setSearchInput: VoidCallBack,
    count: null,
    setCount: VoidCallBack,
    limit: 0,
    setLimit: VoidCallBack,
    offset: 0,
    setOffset: VoidCallBack,
    setUserInput: VoidCallBack,
    setLabel: VoidCallBack,
    setVesselName: VoidCallBack,
    setPortDischarge: VoidCallBack,
    departureDate: '',
    setDepartureDate: VoidCallBack,
    setCompanyConsignee: VoidCallBack,
    setCompanyExporter: VoidCallBack,
    setMarkPackage: VoidCallBack,
    setPlace: VoidCallBack,
    sheetActiveStep: 0,
    setSheetActiveStep: VoidCallBack,
    ordering: null,
    setOrdering: VoidCallBack,
    selectableFields: null,
    setSelectableFields: VoidCallBack,
    setUserInput2: VoidCallBack,
    setThirdParty: VoidCallBack,
    setExhibition: VoidCallBack,
    setMovement: VoidCallBack,
    setRetroactively: VoidCallBack,
    setReferenceNo: VoidCallBack,
    selectParams: null,
    setSelectParams: VoidCallBack,
    markPackage: '',
    wordTitle: 1,
    GenerateWordTitle: (num: number) => '',
    FromHsToOriginCriteria: (hs: string) => '',
    ConvertName: (name: string | undefined) => '',
    ConvertDateImport: (date: any) => '',
    setWordTitle: VoidCallBack,
    exhibition: false,
    label: '',
    movement: false,
    place: '',
    portDischarge: '',
    referenceNo: '',
    retroactively: false,
    thirdParty: false,
    userInput: '',
    userInput2: '',
    vesselName: '',
    companyConsignee: { company_consignee_address: '', company_consignee_name: '' },
    companyExporter: { company_exporter_address: '', company_exporter_name: '' },
    linkBotFile: '',
    linkTopFile: '',
    linkTopFileNoUserInput: '',
    ResetInvoiceModal: (type: DataIncomeEnum) => {},
    rowPerPage: '',
    setRowPerPage: VoidCallBack,
    FilterLoadMore: VoidCallBack,
    loadPrevious: false,
    FilterLoadPrevious: VoidCallBack,
    gw: '',
    setGW: VoidCallBack,
    back2back: false,
    setBack2Back: VoidCallBack,
    cumulation: false,
    setCumulation: VoidCallBack,
    thirdCountry: false,
    setThirdCountry: VoidCallBack
});

export function FromHsToOriginCriteria(hs: string) {
    const cthValues = [
        25, 26, 28, 29, 31, 42, 43, 44, 45, 46, 47, 48, 49, 57, 58, 59, 61, 62, 64, 66, 67, 68, 69, 70, 71, 73, 74, 75, 76, 77, 78, 79, 80,
        81, 82, 83, 86, 88, 91, 92, 93, 94, 95, 96, 97
    ];
    const splitHs = parseInt(hs.slice(0, 2));
    return cthValues.includes(splitHs) ? 'CTH' : 'PSR';
}

export function ConvertFormName(formType: FormTypeEnum) {
    switch (formType) {
        case FormTypeEnum.AC:
            return 'AC - FORM E';
        case FormTypeEnum.AJ:
            return 'AJCEP - FORM AJ';
        case FormTypeEnum.VJ:
            return 'VJEPA - FORM VJ';
        case FormTypeEnum.AANZ:
            return 'AANZFTA - FORM AANZ';
        case FormTypeEnum.CPTPP:
            return 'CPTPP - FORM CPTPP';
        case FormTypeEnum.RCEP:
            return 'RCEP - FORM RCEP';
        case FormTypeEnum.AI:
            return 'AIFTA - FORM AI';
        default:
            return formType;
    }
}

export function ConvertName(str: string | undefined) {
    if (str) {
        if (str.includes('mới 100%')) {
            return str.split('#&')[1].split(',')[0];
        } else if (str.includes('#&')) {
            return str.split('#&')[1].split('/')[0];
        } else {
            return str;
        }
    } else return '';
}

const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

export function ConvertDateImport(date: string | any) {
    let newDate = date;
    if (date.includes('/')) {
        const dateArr = date.split('/');
        const monthS = dateArr[0];
        const day = dateArr[1];

        if (monthS) {
            const monthN = parseInt(monthS);
            dateArr[0] = day;
            dateArr[1] = months[monthN];
            newDate = dateArr.join('-');
        }
    } else if (date.includes('-')) {
        const dateArr = date.split('-');
        const year = dateArr[0];
        const monthS = dateArr[1];
        const day = dateArr[2];

        if (year.length !== 4) {
            return date;
        }

        if (monthS) {
            const monthN = parseInt(monthS);
            dateArr[0] = day;
            dateArr[1] = months[monthN - 1];
            dateArr[2] = year;
            newDate = dateArr.join('-');
        }
    }
    if (date.includes('00:00:00') && date.includes('-')) {
        let slicedDate = date.slice(0, 10);

        const dateArr = slicedDate.split('-');
        const year = dateArr[0];
        const monthS = dateArr[1];
        const day = dateArr[2];

        if (year.length !== 4) {
            return date;
        }

        if (monthS) {
            const monthN = parseInt(monthS);
            dateArr[0] = day;
            dateArr[1] = months[monthN - 1];
            dateArr[2] = year;
            newDate = dateArr.join('-');
        }
    }

    return newDate;
}

export function GenerateWordTitle(num: number) {
    switch (num) {
        case 1:
            return 'ORIGINAL';
        case 2:
            return 'DUPLICATE';
        case 3:
            return 'TRIPLICATE';
        default:
            return '';
    }
}

function InvoiceWordProvider({ children }: ContextProviderProps) {
    const dispatch = useDispatch();
    const ecusRepo = useEcusRepo();
    const PARAMS: BaseQueryParams = useCompanyFolderSlug();
    const [checkAll, setCheckAll] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [ecusData, setEcusData] = useState<IEcusData[] | null>(null);

    const [userInput, setUserInput] = useState<string>('');
    const [userInput2, setUserInput2] = useState<string>('');
    const [label, setLabel] = useState<string>('');
    const [sheetActiveStep, setSheetActiveStep] = useState(0);
    const [vesselName, setVesselName] = useState<string>('');
    const [rowPerPage, setRowPerPage] = useState<string>('');
    const [markPackage, setMarkPackage] = useState<string>('');
    const [searchInput, setSearchInput] = useState<string>('');
    const [departureDate, setDepartureDate] = useState<string>('');
    const [portDischarge, setPortDischarge] = useState<string>('');
    const [wordFormType, setWordFormType] = useState<FormTypeEnum>(FormTypeEnum.Empty);
    const [place, setPlace] = useState<string>('');
    const [retroactively, setRetroactively] = useState(false);
    const [movement, setMovement] = useState(false);
    const [exhibition, setExhibition] = useState(false);
    const [thirdParty, setThirdParty] = useState(false);
    const [referenceNo, setReferenceNo] = useState<string>('');
    const { company_slug, folder_slug } = useCompanyFolderSlug();
    const [gw, setGW] = useState('');
    const [back2back, setBack2Back] = useState(false);
    const [cumulation, setCumulation] = useState(false);
    const [thirdCountry, setThirdCountry] = useState(false);
    const [companyExporter, setCompanyExporter] = useState<ExporterCompanyInterface>({
        company_exporter_name: '',
        company_exporter_address: ''
    });
    const [companyConsignee, setCompanyConsignee] = useState<ConsigneeCompanyInterface>({
        company_consignee_name: '',
        company_consignee_address: ''
    });
    const [fieldState, setFieldState] = useState<FieldStateInterface>({
        item_hs: { isHidden: true },
        item_name: { isHidden: true },
        user_input: { isHidden: true }
    });

    const [count, setCount] = useState<number | null>(null);
    const [offset, setOffset] = useState<number>(0);
    const [limit, setLimit] = useState<number>(50);
    const [selectableFields, setSelectableFields] = useState<{ [key: string]: any[] } | null>(null);
    const [ordering, setOrdering] = useState<string | null>(null);

    const [selectParams, setSelectParams] = useState<{ [key: string]: any } | null>(null);
    const [wordTitle, setWordTitle] = useState<number>(1);
    const [filterOffset, setFilterOffset] = useState(0);
    const [filterLimit] = useState(100);

    async function FetchFieldSelector(limit: number, offset: number) {
        dispatch(setLoading(true));
        const selectableFields = await ecusRepo.getSelectableValues({ company_slug, folder_slug, limit, offset });
        if (selectableFields) {
            setSelectableFields(selectableFields);
        }
    }

    const loadPrevious = useMemo(() => {
        if (filterOffset === 0) return true;
        return false;
    }, [filterOffset]);

    function FilterLoadMore() {
        setFilterOffset((prev: number) => prev + filterLimit);
    }

    function FilterLoadPrevious() {
        setFilterOffset((prev: number) => prev - filterLimit);
    }

    useEffect(() => {
        FetchFieldSelector(filterLimit, filterOffset);
    }, [filterLimit, filterOffset]);

    const fieldQueryParam: FieldsQueryParams = useMemo(() => {
        let params: FieldsQueryParams = {};
        if (searchInput) params['q'] = searchInput;
        if (ordering) params['ordering'] = ordering;
        if (selectParams) params = { ...params, ...selectParams };
        return params;
    }, [searchInput, ordering, selectParams]);

    const pagingQueryParam: PagingQueryParams = useMemo(() => {
        return {
            offset,
            limit
        };
    }, [limit, offset]);

    async function FetchEcusData(fieldQP: FieldsQueryParams, pagingQP: PagingQueryParams) {
        dispatch(setLoading(true));
        try {
            const response = await ecusRepo.getAll({ ...PARAMS, ...fieldQP, ...pagingQP });
            if (response) {
                setEcusData(response.results);
                setCount(response.count);
                dispatch(setLoading(false));
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        FetchEcusData(fieldQueryParam, pagingQueryParam);
    }, [fieldQueryParam, pagingQueryParam]);

    const linkTopFile = useMemo(() => {
        switch (wordFormType) {
            case FormTypeEnum.AC:
                return LINK_FORMS.formAC.top;
            case FormTypeEnum.VJ:
                return LINK_FORMS.formVJ.top;
            case FormTypeEnum.AI:
                return LINK_FORMS.formAI.top;
            default:
                return '';
        }
    }, [wordFormType]);

    const linkTopFileNoUserInput = useMemo(() => {
        switch (wordFormType) {
            case FormTypeEnum.AC:
                return LINK_FORMS.formAC.topNoUserInput;
            case FormTypeEnum.VJ:
                return LINK_FORMS.formVJ.topNoUserInput;
            case FormTypeEnum.AI:
                return LINK_FORMS.formAI.topNoUserInput;
            default:
                return '';
        }
    }, [wordFormType]);

    const linkBotFile = useMemo(() => {
        switch (wordFormType) {
            case FormTypeEnum.AC:
                return LINK_FORMS.formAC.bot;
            case FormTypeEnum.VJ:
                return LINK_FORMS.formVJ.bot;
            case FormTypeEnum.AI:
                return LINK_FORMS.formAI.bot;
            default:
                return '';
        }
    }, [wordFormType]);

    const ResetInvoiceModal = useCallback(
        (type: DataIncomeEnum) => {
            switch (type) {
                case DataIncomeEnum.checkBox:
                    setActiveStep(0);
                    setLabel('');
                    setFieldState({
                        item_hs: { isHidden: false },
                        item_name: { isHidden: false },
                        user_input: { isHidden: false }
                    });
                    setUserInput('');
                    setUserInput2('');
                    setVesselName('');
                    setWordFormType(FormTypeEnum.Empty);
                    setRowPerPage('');
                    setMarkPackage('');
                    setPortDischarge('');
                    setRetroactively(false);
                    setExhibition(false);
                    setMovement(false);
                    setThirdParty(false);
                    setReferenceNo('');
                    setCompanyConsignee({ company_consignee_address: '', company_consignee_name: '' });
                    setCompanyExporter({ company_exporter_address: '', company_exporter_name: '' });
                    setDepartureDate('');
                    setSearchInput('');
                    setOrdering('');
                    setGW('');
                    setThirdCountry(false);
                    setBack2Back(false);
                    setCumulation(false);
                    return;

                case DataIncomeEnum.import:
                    setSheetActiveStep(0);
                    setLabel('');
                    setFieldState({
                        item_hs: { isHidden: false },
                        item_name: { isHidden: false },
                        user_input: { isHidden: false }
                    });
                    setUserInput('');
                    setUserInput2('');
                    setVesselName('');
                    setWordFormType(FormTypeEnum.Empty);
                    setRowPerPage('');
                    setMarkPackage('');
                    setPortDischarge('');
                    setRetroactively(false);
                    setExhibition(false);
                    setMovement(false);
                    setThirdParty(false);
                    setReferenceNo('');
                    setCompanyConsignee({ company_consignee_address: '', company_consignee_name: '' });
                    setCompanyExporter({ company_exporter_address: '', company_exporter_name: '' });
                    setDepartureDate('');
                    setSearchInput('');
                    setOrdering('');
                    setGW('');
                    setThirdCountry(false);
                    setBack2Back(false);
                    setCumulation(false);
                    return;
            }
        },
        [activeStep, sheetActiveStep]
    );

    return (
        <InvoiceWordContext.Provider
            value={{
                ecusData,
                checkAll,
                setCheckAll,
                activeStep,
                setActiveStep,
                wordFormType,
                setWordFormType,
                fieldState,
                setFieldState,
                setSearchInput,
                limit,
                setLimit,
                offset,
                setOffset,
                count,
                setCount,
                setUserInput,
                setLabel,
                setVesselName,
                setPortDischarge,
                setDepartureDate,
                setCompanyConsignee,
                setCompanyExporter,
                setMarkPackage,
                sheetActiveStep,
                setSheetActiveStep,
                setPlace,
                selectableFields,
                setSelectableFields,
                ordering,
                setOrdering,
                setUserInput2,
                setThirdParty,
                setExhibition,
                setMovement,
                setRetroactively,
                setReferenceNo,
                selectParams,
                setSelectParams,
                markPackage,
                wordTitle,
                setWordTitle,
                GenerateWordTitle,
                FromHsToOriginCriteria,
                ConvertName,
                ConvertDateImport,
                exhibition,
                label,
                movement,
                place,
                portDischarge,
                referenceNo,
                retroactively,
                thirdParty,
                userInput,
                userInput2,
                vesselName,
                companyConsignee,
                companyExporter,
                departureDate,
                linkTopFile,
                linkTopFileNoUserInput,
                linkBotFile,
                ResetInvoiceModal,
                rowPerPage,
                setRowPerPage,
                FilterLoadMore,
                FilterLoadPrevious,
                loadPrevious,
                gw,
                setGW,
                back2back,
                cumulation,
                setBack2Back,
                setCumulation,
                thirdCountry,
                setThirdCountry
            }}
        >
            {children}
        </InvoiceWordContext.Provider>
    );
}

export default InvoiceWordProvider;
