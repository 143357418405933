import { JsonObject, JsonProperty } from 'json2typescript';
import { JsonSerializable } from 'models/serialization';

export interface UploadFileDataInterface {
    id: string;
    file: string;
    uploaded_at: string;
}

@JsonObject('UploadFileData')
export class UploadFileData extends JsonSerializable<UploadFileData> implements UploadFileDataInterface {
    protected get classRef(): new () => UploadFileData {
        return UploadFileData;
    }

    @JsonProperty('id')
    id!: string;

    @JsonProperty('file')
    file!: string;

    @JsonProperty('uploaded_at')
    uploaded_at!: string;
}
