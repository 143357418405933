import { createSlice } from '@reduxjs/toolkit';

const getYear = () => {
    try {
        if (parseInt(localStorage.getItem('hsyear') as string) >= 2017 && parseInt(localStorage.getItem('hsyear') as string) <= 2023)
            return localStorage.getItem('hsyear');
    } catch {
        return '2023';
    }
    return '2023';
};

const initialState = {
    Section: null,
    Chapter: null,
    Header: null,
    SupHeader: null,
    OpenSideBar: true,
    SelectedHs: null,
    SearchResult: null,
    isSearch: false,
    TitilePage: null,
    Year: getYear(),
    isProductSearch: false,
    currentProduct: null
};

export const HsCodeSlice = createSlice({
    name: 'HsCode',
    initialState,
    reducers: {
        setSection: (state, { payload }) => {
            state.Section = payload;
        },
        setChapter: (state, { payload }) => {
            state.Chapter = payload;
        },
        setHeader: (state, { payload }) => {
            state.Header = payload;
        },
        setSupHeader: (state, { payload }) => {
            state.SupHeader = payload;
        },
        setOpenSideBar: (state, { payload }) => {
            state.OpenSideBar = payload;
        },
        setSelectedHs: (state, { payload }) => {
            state.SelectedHs = payload;
        },
        setSearchResult: (state, { payload }) => {
            state.SearchResult = payload;
        },
        setTitilePage: (state, { payload }) => {
            state.TitilePage = payload;
        },
        setisSearch: (state, { payload }) => {
            state.isSearch = payload;
        },
        setYear: (state, { payload }) => {
            state.Year = payload;
        },
        setIsProductSearch: (state, { payload }) => {
                state.isProductSearch = payload;
            },
            setCurrentProduct: (state, { payload }) => {
                state.currentProduct = payload;
    
        }
    }
});

export const { setSection, setChapter, setHeader, setSupHeader, setOpenSideBar, setSelectedHs, setTitilePage, setisSearch, setSearchResult, setYear, setIsProductSearch } = HsCodeSlice.actions;
export default HsCodeSlice.reducer;
