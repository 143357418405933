import { JsonObject, JsonProperty } from 'json2typescript';
import { JsonSerializable } from '../serialization';

export interface IOtherOutputData {
    id: string;
    code: string;
    name: string;
    warehouse_name: string;
    bill_no: string;
    // bill_date: string;
    unit_price: number;
    other_export: number;

    unit: string;
    explain: string;
    doc_number: string;
}

@JsonObject('OtherOutputData')
export class OtherOutputData extends JsonSerializable<OtherOutputData> implements IOtherOutputData {
    protected get classRef(): new () => OtherOutputData {
        return OtherOutputData;
    }

    @JsonProperty('id', String, false)
    id!: string;

    @JsonProperty('code', String, true)
    code: string = '';

    @JsonProperty('name', String, true)
    name: string = '';

    @JsonProperty('unit', String, true)
    unit: string = '';

    @JsonProperty('warehouse_name', String, true)
    warehouse_name: string = '';

    @JsonProperty('bill_no', String, true)
    bill_no: string = '';

    // @JsonProperty('bill_date', String, true)
    // bill_date: string = '';

    @JsonProperty('explain', String, true)
    explain: string = '';

    @JsonProperty('doc_number', String, true)
    doc_number: string = '';

    @JsonProperty('unit_price', Number, true)
    unit_price: number = 0;

    @JsonProperty('other_export', Number, true)
    other_export: number = 0;
}
