import { AutoFillParams } from 'components';
import _ from 'lodash';
import { JSONType } from 'models';
import { AutoFillData } from 'models/AutoFillData/AutoFillData';
import { BalanceNewImportDataSource } from '../BalanceImportDataSource';
import { IobQueryParams } from '../type';
import { IobImportParams } from './type';
import { IobAccountingData } from 'models/IobAccountingTable';

export class IobAccountingDataSource extends BalanceNewImportDataSource<IobImportParams> {
    path: string = 'acc-iob';

    public async getAutoFill(data: AutoFillParams) {
        const respone = await this.http.instance.post('/api/new-balance/acc-iob/autofill/', data);
        return new AutoFillData().fromPaging<AutoFillData>(respone.data);
    }

    public async getAll(params: IobQueryParams) {
        const response = await this.http.instance.get('/api/new-balance/acc-iob/', {
            params
        });
        return new IobAccountingData().fromPaging<IobAccountingData>(response.data);
    }

    public async update(data: Partial<IobAccountingData>, params: IobQueryParams) {
        return this.http.instance.put(
            `/api/new-balance/acc-iob/${data.id}/`,
            _.omit(
                {
                    ...data,
                    ...params
                },
                'id'
            )
        );
    }

    public async delete(ids: string[]) {
        return this.http.instance.delete(`/api/new-balance/acc-iob/bulk-delete/`, {
            data: { id_list: ids }
        });
    }

    public async insert(data: JSONType) {
        return this.http.instance.post(`/api/new-balance/acc-iob/`, data);
    }
}
