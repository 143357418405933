import { Checkbox } from '@mui/material'
import { HTMLProps } from 'react'

export function IndeterminateCheckbox({
  checked,
  indeterminate,
  onChange,
  disabled,
}: {
  indeterminate?: boolean
  checked: boolean
  disabled?: boolean
  onChange: (event: unknown) => void
} & HTMLProps<HTMLInputElement>) {
  return (
    <Checkbox
      checked={checked}
      indeterminate={indeterminate}
      onChange={onChange}
      sx={{
        '&.Mui-checked': {
          color: '#3E66FB',
        },
      }}
    />
  )
}
