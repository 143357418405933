import { JsonObject, JsonProperty } from 'json2typescript';
import { JsonSerializable } from '../serialization';

export interface IPhysicalCountingData {
    id: string;
    code: string;
    unit: string;
    name: string;
    warehouse_name: string;
    inventory_date: string;
    inventory_quantity: number;
    import_record_id?: number;
    created_at?: string;
    company_slug_id?: number;
    folder_slug_id?: number;
    item_type?: string;
    year?: number;
    inventory_value?: number;
}

@JsonObject('PhysicalCountingData')
export class PhysicalCountingData extends JsonSerializable<PhysicalCountingData> implements IPhysicalCountingData {
    protected get classRef(): new () => PhysicalCountingData {
        return PhysicalCountingData;
    }

    @JsonProperty('id', String, false)
    id!: string;

    @JsonProperty('code', String, true)
    code: string = '';

    @JsonProperty('name', String, true)
    name: string = '';

    @JsonProperty('unit', String, true)
    unit: string = '';

    @JsonProperty('warehouse_name', String, true)
    warehouse_name: string = '';

    @JsonProperty('inventory_date', String, true)
    inventory_date: string = '';

    @JsonProperty('inventory_quantity', Number, true)
    inventory_quantity: number = 0;
}
