import { JsonObject, JsonProperty } from 'json2typescript';
import { JsonSerializable } from 'models/serialization';

export interface IEndingInventorySetupDTOProduction {
    company_slug: string;
    code: string;
    quantity: string;
}

@JsonObject('EndingInventorySetupDTOProduction')
export class EndingInventorySetupDTOProduction
    extends JsonSerializable<EndingInventorySetupDTOProduction>
    implements IEndingInventorySetupDTOProduction
{
    protected get classRef(): new () => EndingInventorySetupDTOProduction {
        return EndingInventorySetupDTOProduction;
    }

    @JsonProperty('company_slug', String, false)
    company_slug!: string;

    @JsonProperty('code', String, false)
    code!: string;

    @JsonProperty('quantity', String, false)
    quantity!: string;
}
