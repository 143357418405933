import { SetupParams } from 'components';
import { VoidCallBack, voidCallback } from 'components/BalanceTable/TableInvoice/InvoiceProvider';
import { InvoiceImportData, Result } from 'models';
import { createContext, useContext, useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import { InvoiceWordContext } from '../InvoiceWordProvider';
import useGeneratePageImport from '../hooks/useGeneratePageImport';

export interface InvoiceSheetModalContextInterface {
    selectedFile: File | null;
    setSelectedFile: voidCallback;
    workBook: XLSX.WorkBook | null;
    setWorkBook: voidCallback;
    selectedSheet: string | null;
    setSelectedSheet: voidCallback;
    header: string[] | undefined;
    setHeader: voidCallback;
    setRowIndex: (from: number, to: number) => void;
    endRow: number | null;
    setEndRow: voidCallback;
    setSetupSuccessful: (val: boolean) => void;
    coList: null | InvoiceImportData[];
    setCOList: voidCallback;
    getFixedHeader: () => Promise<Result<string[]>>;
    setupImport: (props: SetupParams) => Promise<Result<void>>;
    setOpenImport: voidCallback;
    openImport: boolean;
    setSubmit: voidCallback;
    submitStep2: boolean;
    sheetActiveStep: number;
    setMergeFile1: voidCallback;
    setMergeFile2: voidCallback;
    setMergeFile3: voidCallback;
    uniqHsMap: any;
    SubmitOriginateHandler: voidCallback;
    OriginImportHandler: (id: string, hs: number, originate: string, isCombine: boolean) => void;
    revertData: null | InvoiceImportData[];
    setRevertData: voidCallback;
    isSelectHeader: boolean;
    setSelectHeader: voidCallback;
    submitStep4: boolean;
    setSubmitStep4: voidCallback;
    disableSubmitStep4: boolean;
    setDisableSubmitStep4: voidCallback;
}

export const InvoiceSheetModalProviderContext = createContext<InvoiceSheetModalContextInterface>({
    selectedFile: null,
    setSelectedFile: VoidCallBack,
    workBook: null,
    setWorkBook: VoidCallBack,
    selectedSheet: null,
    setSelectedSheet: VoidCallBack,
    setHeader: VoidCallBack,
    setRowIndex: VoidCallBack,
    endRow: null,
    setEndRow: VoidCallBack,
    setSetupSuccessful: (_) => {},
    header: undefined,
    coList: null,
    setCOList: VoidCallBack,
    getFixedHeader: () => Promise.reject('Unimplemented'),
    setupImport: (_) => Promise.reject('Unimplemented'),
    openImport: false,
    setOpenImport: VoidCallBack,
    setSubmit: VoidCallBack,
    submitStep2: false,
    sheetActiveStep: 1,
    setMergeFile1: VoidCallBack,
    setMergeFile2: VoidCallBack,
    setMergeFile3: VoidCallBack,
    uniqHsMap: null,
    SubmitOriginateHandler: VoidCallBack,
    OriginImportHandler: (id: string, hs: number, originate: string, isCombine: boolean) => {},
    revertData: null,
    setRevertData: VoidCallBack,
    isSelectHeader: false,
    setSelectHeader: VoidCallBack,
    submitStep4: false,
    setSubmitStep4: VoidCallBack,
    disableSubmitStep4: false,
    setDisableSubmitStep4: VoidCallBack
});

function InvoiceSheetModalProvider({ children }: { children: React.ReactNode }) {
    const [openImport, setOpenImport] = useState(false);
    const [isSelectHeader, setSelectHeader] = useState(false);
    const [submitStep4, setSubmitStep4] = useState(false);
    const [disableSubmitStep4, setDisableSubmitStep4] = useState(false);

    const {
        label,
        place,
        fieldState,
        userInput,
        userInput2,
        vesselName,
        wordFormType,
        rowPerPage,
        markPackage,
        portDischarge,
        retroactively,
        exhibition,
        movement,
        thirdParty,
        referenceNo,
        companyConsignee,
        companyExporter,
        departureDate,
        sheetActiveStep,
        linkTopFile,
        linkTopFileNoUserInput,
        linkBotFile,
        back2back,
        cumulation,
        thirdCountry,
        gw
    } = useContext(InvoiceWordContext);

    useEffect(() => {
        if (!openImport) ResetDefault();
    }, [openImport]);

    const {
        coList,
        endRow,
        getFixedHeader,
        header,
        selectedFile,
        selectedSheet,
        setCOList,
        setEndRow,
        setHeader,
        setMergeFile1,
        setMergeFile2,
        setMergeFile3,
        setRowIndex,
        setSelectedFile,
        setSelectedSheet,
        setSetupSuccessful,
        setSubmit,
        setWorkBook,
        setupImport,
        submitStep2,
        uniqHsMap,
        workBook,
        SubmitOriginateHandler,
        OriginImportHandler,
        revertData,
        setRevertData,
        ResetDefault
    } = useGeneratePageImport({
        label,
        place,
        fieldState,
        exhibition,
        markPackage,
        movement,
        portDischarge,
        referenceNo,
        retroactively,
        rowPerPage,
        thirdParty,
        userInput,
        userInput2,
        vesselName,
        wordFormType,
        companyConsignee,
        companyExporter,
        departureDate,
        sheetActiveStep,
        linkBotFile,
        linkTopFile,
        linkTopFileNoUserInput,
        openImport,
        back2back,
        cumulation,
        gw,
        thirdCountry
    });

    return (
        <InvoiceSheetModalProviderContext.Provider
            value={{
                workBook,
                setWorkBook,
                setSelectedFile,
                selectedSheet,
                setSelectedSheet,
                selectedFile,
                endRow,
                setEndRow,
                setHeader,
                setRowIndex,
                coList,
                setCOList,
                getFixedHeader,
                header,
                setSetupSuccessful,
                setupImport,
                setOpenImport,
                setSubmit,
                submitStep2,
                sheetActiveStep,
                openImport,
                setMergeFile1,
                setMergeFile2,
                setMergeFile3,
                uniqHsMap,
                SubmitOriginateHandler,
                OriginImportHandler,
                revertData,
                setRevertData,
                isSelectHeader,
                setSelectHeader,
                setSubmitStep4,
                submitStep4,
                disableSubmitStep4,
                setDisableSubmitStep4
            }}
        >
            {children}
        </InvoiceSheetModalProviderContext.Provider>
    );
}

export default InvoiceSheetModalProvider;
