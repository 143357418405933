import {AutoFillParams} from 'components';
import {BomData, BomSetupDTO, JSONType, Result, resultGuard} from 'models';
import {BomQueryParams} from '../type';
import {BomDataSource, NormalBomDataSource} from './BomDataSource';
import {IBomExportExcel, IBomFix,} from './type';

export class BomRepository {
    constructor(private datasource: BomDataSource, private normalBomDataSource: NormalBomDataSource) {
    }

    public async getAll(params: BomQueryParams) {
        return this.datasource.getAll(params);
    }

    public async deleteRows(ids: string[]) {
        return this.datasource.delete(ids);
    }

    public async updateRow(row: Partial<BomData>, params: BomQueryParams) {
        return this.datasource.update(row, params);
    }

    public async insertRow(row: JSONType) {
        return this.datasource.insert(row);
    }

    public async importData(value: any): Promise<Result<void>> {
        return resultGuard(async () => {
            await this.datasource.importData(value);
        });
    }

    public async normalImportData(value: any): Promise<Result<void>> {
        return resultGuard(async () => {
            await this.normalBomDataSource.importData(value);
        });
    }

    public async getNormalFixedHeader(): Promise<Result<string[]>> {
        return resultGuard(async () => {
            return this.normalBomDataSource.getFixedHeader();
        });
    }

    public async getFixedHeader(): Promise<Result<string[]>> {
        return resultGuard(async () => {
            return this.datasource.getFixedHeader();
        });
    }

    public async getProductFixedColumn(): Promise<Result<string[]>> {
        return resultGuard(async () => {
            return ["code", "name", "unit", "quantity", "lot"];
        });
    }

    public async getMaterialFixedColumn(): Promise<Result<string[]>> {
        return resultGuard(async () => {
            return ["material_code", "material_name", "material_unit", "material_quantity", "material_lot"];
        });
    }

    public async setupImport(data: { [key: string]: any }) {
        return resultGuard(async () => {
            await this.datasource.setupImport(data);
        });
    }

    public async setupNormalImport(data: { [key: string]: any }) {
        return resultGuard(async () => {
            await this.normalBomDataSource.setupImport(data);
        });
    }


    public async getAutoFillData(data: AutoFillParams) {
        return this.datasource.getAutoFill(data);
    }

    public async exportExcel(params: IBomExportExcel) {
        return this.datasource.exportExcel(params);
    }

    public async fixBom(data: IBomFix) {
        return await this.datasource.fixBom(data);
    }
}
