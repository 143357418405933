import { ArrowDropDown, ArrowDropUp, UnfoldMore } from '@mui/icons-material';
import BuildIcon from '@mui/icons-material/Build';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import {
    Box,
    Button,
    Checkbox,
    Modal,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TextField
} from '@mui/material';
import { OutlinedButton } from 'components/Button';
import { FieldSelector, FieldSelectorMeta } from 'components/FieldSelector/FieldSelector';
import { IEcusData } from 'models';
import { useContext, useEffect, useMemo, useState } from 'react';
import { ToolTip } from 'ui-component/tooltip/tooltip';
import { voidCallback } from '../TableInvoice/InvoiceProvider';
import InvoiceSheetModal from './InvoiceSheetModal/InvoiceSheetModal';
import InvoiceSheetModalProvider, { InvoiceSheetModalProviderContext } from './InvoiceSheetModal/InvoiceSheetModalProvider';
import InvoiceModalProvider, { InvoiceModalContext } from './InvoiceWordModal/InvoiceModalProvider';
import InvoiceWordModal from './InvoiceWordModal/InvoiceWordModal';
import InvoiceWordProvider, { InvoiceWordContext } from './InvoiceWordProvider';
import styled from './style.module.css';

export enum DataIncomeEnum {
    checkBox = 'checkBox',
    import = 'import'
}

export interface TableCellHeaderInterface {
    title: string;
    field: string;
    size?: number;
}

function TableWordSearchInput() {
    const { setSearchInput } = useContext(InvoiceWordContext);
    const [input, setInput] = useState<string>('');

    function InputHandler(event: any) {
        setInput(event.target.value);
    }

    useEffect(() => {
        const timeout = setTimeout(() => {
            setSearchInput(input);
        }, 1000);

        return () => clearTimeout(timeout);
    }, [input]);

    return (
        <Box sx={{ width: '100%' }}>
            <TextField id="table_word_search_input" variant="outlined" onChange={InputHandler} value={input} />
        </Box>
    );
}

export enum SortTableEnum {
    decs = 'decs',
    acs = 'acs'
}

export interface SortTableProps {
    str: string;
    setOrdering: voidCallback;
}

export function SortTableInvoice({ str, setOrdering }: SortTableProps) {
    const [sort, setSort] = useState<SortTableEnum | null>(null);

    function ChangeOrderHandler() {
        setSort((prev) => {
            if (prev === null) {
                return SortTableEnum.acs;
            } else if (prev === SortTableEnum.acs) {
                return SortTableEnum.decs;
            } else return SortTableEnum.acs;
        });
    }

    useEffect(() => {
        if (sort && str) {
            const minus = '-';
            let newString: string = str;
            if (sort === SortTableEnum.acs) {
                newString = minus.concat('', newString);
            } else {
                newString = str;
            }
            setOrdering(newString);
        }
    }, [sort, str]);

    return (
        <Box className={styled['sort_btn']} onClick={ChangeOrderHandler}>
            {sort === null ? <UnfoldMore /> : sort === SortTableEnum.acs ? <ArrowDropUp /> : <ArrowDropDown />}
        </Box>
    );
}

function TableWordHeader() {
    const { setCheckAll, checkAll, setOrdering } = useContext(InvoiceWordContext);

    const tableCellHeader: TableCellHeaderInterface[] = [
        { title: 'Ngày hoá đơn', field: 'bill_date' },
        { title: 'Số hoá đơn', field: 'invoice_no' },
        { title: 'Số TK', field: 'account_number' },
        { title: 'Ngày ĐK', field: 'registered_date' },
        { title: 'Mã loại hình', field: 'type_code' },
        { title: 'Ngày khởi hành vận chuyển', field: 'shipping_date' },
        { title: 'Địa điểm dỡ hàng', field: 'unloading_location' },
        { title: 'Tên đối tác', field: 'partner' },
        { title: 'Tổng trị giá', field: 'total_value' },
        { title: 'Tổng số lượng', field: 'total' },
        { title: 'Mã NPL/SP', field: 'code' }
    ];

    function CheckBoxHeaderHandler() {
        setCheckAll((prev: boolean) => !prev);
    }

    return (
        <TableHead>
            <TableRow>
                <TableCell>
                    <Checkbox onClick={CheckBoxHeaderHandler} checked={checkAll} />
                </TableCell>
                {tableCellHeader &&
                    tableCellHeader.map((cell: TableCellHeaderInterface, index) => {
                        return (
                            <TableCell key={cell.title + index} className={styled['table_cell']}>
                                <Box sx={{ display: 'flex', gap: '4px' }}>
                                    <Box>{cell.title}</Box>
                                    <SortTableInvoice str={cell.field} setOrdering={setOrdering} key={cell.title + index} />
                                </Box>
                            </TableCell>
                        );
                    })}
            </TableRow>
        </TableHead>
    );
}
export interface CheckPropertyInterface {
    check: boolean;
    item: IEcusData;
    combine: boolean;
}

export function TableWordPagination() {
    const { count, limit, setLimit, setOffset } = useContext(InvoiceWordContext);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(limit);

    const handleChangePage = (event: any | null, newPage: number) => {
        if (newPage > page) {
            setOffset((prev: number) => prev + limit);
        }
        if (page > newPage && page != 0) {
            setOffset((prev: number) => prev - limit);
        }
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        setOffset(0);
    };

    useEffect(() => {
        setLimit(rowsPerPage);
    }, [rowsPerPage]);

    // TODO
    return (
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
            <TablePagination
                component="div"
                count={count ?? 0}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[50, 100, 200, 500]}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Box>
    );
}

function TableWordFull() {
    const [checkList, setCheckList] = useState<CheckPropertyInterface[] | null>(null);
    const [orderCheckList, setOrderCheckList] = useState<null | CheckPropertyInterface[]>(null);
    const { ecusData, checkAll, setCheckAll } = useContext(InvoiceWordContext);
    const { setCheckList: setCheckListProvider, checkList: checkListProvider } = useContext(InvoiceModalContext);

    const checkAllList = useMemo(() => {
        if (ecusData) {
            return ecusData.map((item: IEcusData) => {
                return { check: true, item, combine: true };
            });
        }
        return [];
    }, [ecusData]);

    const unCheckAllList = useMemo(() => {
        if (ecusData) {
            return ecusData.map((item: IEcusData) => {
                return { check: false, item, combine: true };
            });
        }
        return [];
    }, [ecusData]);

    function AddCheckProperty(listData: IEcusData[]): CheckPropertyInterface[] {
        const result = listData.map((item: IEcusData) => {
            return { check: false, item, combine: true };
        });
        return result;
    }

    function OrderCheckListFactory(item: CheckPropertyInterface) {
        if (item.check) {
            setOrderCheckList((prev) => {
                if (prev === null) {
                    return [item];
                } else return [...prev, item];
            });
        }

        if (!item.check) {
            if (!orderCheckList) return;
            else {
                const newOrderCheckList = orderCheckList.filter((i: CheckPropertyInterface) => {
                    if (item.item.id !== i.item.id) {
                        return item;
                    }
                });

                setOrderCheckList(newOrderCheckList);
            }
        }
    }

    function CheckBoxHandler(index: number) {
        if (checkList) {
            let isCheckAll = checkList.length - 1;
            const newCheckList = checkList.map((item: CheckPropertyInterface, i: number) => {
                if (item.check) isCheckAll--;
                if (i === index) {
                    const newItem = { check: !item.check, item: item.item, combine: item.combine };
                    OrderCheckListFactory(newItem);
                    return newItem;
                }
                return item;
            });

            if (isCheckAll === 0) {
                setCheckAll(true);
            }
            setCheckList(newCheckList);
        }
    }

    useEffect(() => {
        if (ecusData) {
            setCheckList(AddCheckProperty(ecusData));
            setCheckAll(false);
        }
    }, [ecusData]);

    useEffect(() => {
        if (orderCheckList) {
            setCheckListProvider(orderCheckList);
        }
    }, [orderCheckList]);

    useEffect(() => {
        if (!open) {
            setCheckAll(false);
            setCheckList(unCheckAllList);
            setOrderCheckList(null);
        }
    }, [open]);

    useEffect(() => {
        if (checkAll) {
            setCheckList(checkAllList);
            setOrderCheckList(checkAllList);
        } else {
            setCheckList(unCheckAllList);
            setOrderCheckList(null);
        }
    }, [checkAll]);

    useEffect(() => {
        if (checkListProvider === null) {
            setCheckList(unCheckAllList);
            setOrderCheckList(null);
        }
    }, [checkListProvider]);

    return (
        <>
            <Paper style={{ height: 600, width: '100%', overflowX: 'scroll', overflowY: 'scroll' }}>
                <Table>
                    <TableWordHeader />
                    <TableBody>
                        {checkList &&
                            checkList.map((ecus: CheckPropertyInterface, index) => {
                                return (
                                    <TableRow key={ecus.item.id + index}>
                                        <TableCell>
                                            <Checkbox
                                                checked={ecus.check}
                                                onClick={() => {
                                                    CheckBoxHandler(index);
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>{ecus.item.bill_date}</TableCell>
                                        <TableCell align="left">{ecus.item.invoice_no}</TableCell>
                                        <TableCell align="left">{ecus.item.account_number}</TableCell>
                                        <TableCell align="left">{ecus.item.registered_date}</TableCell>
                                        <TableCell align="left">{ecus.item.type_code}</TableCell>
                                        <TableCell align="left">{ecus.item.shipping_date}</TableCell>
                                        <TableCell align="left">{ecus.item.unloading_location}</TableCell>
                                        <TableCell align="left">{ecus.item.partner}</TableCell>
                                        <TableCell align="left">{ecus.item.total_value}</TableCell>
                                        <TableCell align="left">{ecus.item.total}</TableCell>
                                        <TableCell align="left">{ecus.item.code}</TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </Paper>
            <TableWordPagination />
        </>
    );
}

function TableInvoiceWord() {
    const fieldSelector: FieldSelectorMeta[] = [
        { field: 'code', label: 'Code' },
        { field: 'account_number', label: 'Account number' },
        { field: 'invoice_no', label: 'Invoice no' },
        { field: 'item_name', label: 'Name' }
    ];

    return (
        <InvoiceWordProvider>
            <InvoiceWordContext.Consumer>
                {({ setSelectParams, selectableFields, ResetInvoiceModal, FilterLoadMore, FilterLoadPrevious, loadPrevious }) => {
                    return (
                        <InvoiceModalProvider>
                            <InvoiceModalContext.Consumer>
                                {({ open, OpenModal }) => {
                                    return (
                                        <InvoiceSheetModalProvider>
                                            <InvoiceSheetModalProviderContext.Consumer>
                                                {({ openImport, setOpenImport }) => {
                                                    return (
                                                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                                            <Modal open={open}>
                                                                <Box
                                                                    sx={{
                                                                        position: 'relative',
                                                                        top: '50%',
                                                                        left: '50%',
                                                                        transform: 'translate(-50%, -50%)',
                                                                        width: '98%',
                                                                        height: '98%',
                                                                        bgcolor: 'whitesmoke',
                                                                        borderRadius: 2,
                                                                        display: 'flex',
                                                                        flexDirection: 'column',
                                                                        alignItems: 'center'
                                                                    }}
                                                                >
                                                                    <Box
                                                                        sx={{
                                                                            width: '100%',
                                                                            height: '100%',
                                                                            display: 'flex',
                                                                            flexDirection: 'column'
                                                                        }}
                                                                    >
                                                                        <Button
                                                                            variant="text"
                                                                            sx={{
                                                                                position: 'absolute',
                                                                                top: '0px',
                                                                                right: '0px',
                                                                                width: '40px',
                                                                                height: '40px',
                                                                                padding: '12px'
                                                                            }}
                                                                            onClick={() => {
                                                                                ResetInvoiceModal(DataIncomeEnum.checkBox);
                                                                                OpenModal(false);
                                                                            }}
                                                                        >
                                                                            Close
                                                                        </Button>
                                                                        <InvoiceWordModal />
                                                                    </Box>
                                                                </Box>
                                                            </Modal>
                                                            <Modal open={openImport}>
                                                                <Box
                                                                    sx={{
                                                                        position: 'relative',
                                                                        top: '50%',
                                                                        left: '50%',
                                                                        transform: 'translate(-50%, -50%)',
                                                                        width: '98%',
                                                                        height: '98%',
                                                                        bgcolor: 'whitesmoke',
                                                                        borderRadius: 2,
                                                                        display: 'flex',
                                                                        flexDirection: 'column',
                                                                        alignItems: 'center'
                                                                    }}
                                                                >
                                                                    <Box
                                                                        sx={{
                                                                            width: '100%',
                                                                            height: '100%',
                                                                            display: 'flex',
                                                                            flexDirection: 'column'
                                                                        }}
                                                                    >
                                                                        <Button
                                                                            variant="text"
                                                                            sx={{
                                                                                position: 'absolute',
                                                                                top: '0px',
                                                                                right: '0px',
                                                                                width: '40px',
                                                                                height: '40px',
                                                                                padding: '12px'
                                                                            }}
                                                                            onClick={() => {
                                                                                ResetInvoiceModal(DataIncomeEnum.import);
                                                                                setOpenImport(false);
                                                                            }}
                                                                        >
                                                                            Close
                                                                        </Button>
                                                                        <InvoiceSheetModal />
                                                                    </Box>
                                                                </Box>
                                                            </Modal>

                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    justifyContent: 'flex-end',
                                                                    padding: '6px',
                                                                    gap: '6px'
                                                                }}
                                                            >
                                                                <FieldSelector
                                                                    fieldSelectorMeta={fieldSelector}
                                                                    data={selectableFields}
                                                                    setSelectParams={setSelectParams}
                                                                    FilterLoadMore={FilterLoadMore}
                                                                    FilterLoadPrevious={FilterLoadPrevious}
                                                                    loadPrevious={loadPrevious}
                                                                />
                                                                <ToolTip title="Import">
                                                                    <OutlinedButton
                                                                        onClick={() => {
                                                                            setOpenImport(true);
                                                                        }}
                                                                    >
                                                                        <FileUploadIcon />
                                                                    </OutlinedButton>
                                                                </ToolTip>
                                                                <ToolTip title="Open Modal">
                                                                    <OutlinedButton
                                                                        onClick={() => {
                                                                            OpenModal(true);
                                                                        }}
                                                                    >
                                                                        <BuildIcon />
                                                                    </OutlinedButton>
                                                                </ToolTip>
                                                            </Box>

                                                            <TableWordFull />
                                                        </Box>
                                                    );
                                                }}
                                            </InvoiceSheetModalProviderContext.Consumer>
                                        </InvoiceSheetModalProvider>
                                    );
                                }}
                            </InvoiceModalContext.Consumer>
                        </InvoiceModalProvider>
                    );
                }}
            </InvoiceWordContext.Consumer>
        </InvoiceWordProvider>
    );
}

export default TableInvoiceWord;
