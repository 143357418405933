import { useCompanyFolderSlug } from 'components';
import useSnackBar, { SnackBarAlertEnum } from 'components/SnackBar/UISnackbar';
import { useEcusRepo, useInvoiceRepo } from 'di';
import { ResultState } from 'models';
import { BangKeData, BangKeDataInterface } from 'models/BangKe';
import { BangKeExcelDataInterface } from 'models/BangKe/BangKeExcelData';
import { BangKeExcelRowData, BangKeExcelRowDataInterface } from 'models/BangKe/BangKeExcelRowData';
import { BangKeHistoryDataRequest } from 'models/BangKe/BangKeHistoryData';
import { BangKePerformManualInterface } from 'models/BangKe/BangKePerformManual';
import { InvoiceProductData } from 'models/InvoiceTable/InvoiceProductData';
import { PSRDataInterface } from 'models/PSR';
import { ReactElement, createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BalanceParams, FieldsQueryParams, PagingQueryParams } from 'repository';
import { PsrQueryParams } from 'repository/invoice';
import { setLoading } from 'store/Global';
import { match } from 'ts-pattern';
import { exportXlsx } from 'utils';
import {
    CreateCCMergerCell,
    CreateEXWNonSedoFootMergerCell,
    CreateEXWWithSedoFootMergerCell,
    CreateFOBFootMergeCell,
    CreateFOBMergeCell,
    CreateFactoryPrice,
    CreateFinalResult,
    CreateFixedHeader,
    CreateProgessPriceValue,
    CreateRVC,
    CreateRVCEWX,
    CreateTotalNoFta
} from '../BangKeSheet';
import { OptionFormEnum, OptionOriginEnum, OptionPriceEnum, Step2ApplyAllValue } from './BangKeModal';
import { GetPerformManualProps } from './BangKeModal/BangKeStepFour';
import { dataStep4 } from './BangKeModal/hooks/GenerateStep4/GenerateDataStep4';
import { DropDownApplicableEnum } from './FormularComponents';

export interface NewBangKeExcelDataInterface {
    data: BangKeExcelRowDataInterface[] | Partial<BangKeExcelRowDataInterface>[];
    id: string;
}

interface CheckPropertyInterface {
    check: boolean;
    item: InvoiceProductData;
}

export interface ContextProviderProps {
    children: ReactElement;
}

export interface XlxsExportListInterface {
    rows: number | null;
    sheetData: (string | number | null | undefined)[][] | null;
    sheetMergeCell: any;
    sheetName?: string;
    sheetDataFixed?: (string | number | null | undefined)[][] | null;
    sheetDataDynamic?: (string | number | null | undefined)[][] | null;
}

export interface StockQuantityDataInterface {
    ecus_stock_id?: string;
    purchase_stock_id?: string;
    exclude_total_quantity: number;
    begin_id?: string;
    bom_value?: number;
}

export interface StockQuantityInterface {
    data: StockQuantityDataInterface[];
}

export enum FormTypeEnum {
    ATIGA = 'ATIGA',
    UKV = 'UKV',
    EV = 'EV',
    EUEA = 'EUEA',
    AC = 'AC',
    AANZ = 'AANZ',
    AHK = 'AHK',
    CPTPP = 'CPTPP',
    VC = 'VC',
    VL = 'VK',
    RCEP = 'RCEP',
    VJ = 'VJ',
    AJ = 'AJ',
    AK = 'AK',
    AI = 'AI',
    Empty = ''
}

export type voidCallback = (_?: any) => void;
export function VoidCallBack(_?: any) {}

export function ConvertDate(date: Date, type?: string) {
    const year = date.getFullYear();
    const monthString = date.toLocaleString('default', { month: 'short' });
    const month = date.getMonth() + 1;
    const dayString = date.toLocaleString('default', { day: '2-digit' });
    const day = date.getDate();

    if (!type) return [year, month, day].join('-');
    else {
        if (type === 'top') return [dayString, monthString, year].join('-');
        else return [monthString, dayString, year].join('. ');
    }
}

export function GetFirstDay(date: Date) {
    const currentYear = date.getFullYear();
    return new Date(`1 Jan ${currentYear}`);
}

export function ConvertFormType(formType: FormTypeEnum) {
    if (formType === FormTypeEnum.UKV || formType === FormTypeEnum.EV) {
        return 'EWX';
    } else {
        return 'FOB';
    }
}

export function ConvertBangKeName(str: string | undefined) {
    if (str) {
        if (str.includes('#&')) {
            return str.split('#&')[1].split('#&')[0];
        }
    } else return '';
}

export interface InvoiceContextInterface {
    searchField: string | null;
    setSearchField: voidCallback;
    checkedProducts: any[] | null;
    setCheckedProducts: voidCallback;
    open: boolean;
    activeStep: number;
    setActiveStep: voidCallback;
    formType: FormTypeEnum;
    setFormType: voidCallback;
    startDate: Date;
    setStartDate: voidCallback;
    psrData: null | PSRDataInterface[];
    setPsrData: voidCallback;
    bangKeData: null | BangKeDataInterface[];
    setBangKeData: voidCallback;
    MapBangKeData: voidCallback;
    ResetDefault: voidCallback;
    GetBangKeExcel: any;
    bangKeExcelData: null | BangKeExcelDataInterface[];
    setBangKeExcelData: voidCallback;
    isDownLoadExcel: boolean;
    setDownLoadExcel: voidCallback;
    xlxsExportList: XlxsExportListInterface[] | null;
    defaultDisplay: boolean | null;
    setDefaultDisplay: voidCallback;
    optionForm: OptionFormEnum | null;
    setOptionForm: voidCallback;
    optionPrice: OptionPriceEnum | null;
    setOptionPrice: voidCallback;
    optionOrigin: OptionOriginEnum | null;
    setOptionOrigin: voidCallback;
    setXlxsExportList: voidCallback;
    useBegin: boolean;
    setUseBegin: voidCallback;
    matchContract: boolean;
    setMatchContract: voidCallback;
    products: InvoiceProductData[] | null;
    setProducts: voidCallback;
    checkListProvider: CheckPropertyInterface[] | null;
    setCheckListProvider: voidCallback;
    checkAll: boolean;
    setCheckAll: voidCallback;
    limit: number;
    setLimit: voidCallback;
    offset: number;
    setOffset: voidCallback;
    count: number | null;
    setCount: voidCallback;
    searchInput: string;
    setSearchInput: voidCallback;
    OpenModal: voidCallback;
    isGenerateFile: boolean;
    setGenerateFile: voidCallback;
    selectableFields: { [key: string]: any[] } | null;
    setSelectableFields: voidCallback;
    setOrdering: voidCallback;
    extraFieldLength: number | null;
    selectParams: { [key: string]: any[] } | null;
    setSelectParams: voidCallback;
    FilterLoadMore: voidCallback;
    loadPrevious: boolean;
    FilterLoadPrevious: voidCallback;
    openModalApply: boolean;
    setOpenModalApply: voidCallback;
    setApplyAllValue: voidCallback;
    setSubmitStep2: voidCallback;
    GetPerformManual: voidCallback;
    performManual: BangKePerformManualInterface | null;
    listPerformManual: BangKePerformManualInterface | null;
    setListPerformManual: voidCallback;
    setDataStep4: voidCallback;
    isInitStep4: boolean;
    setInitStep4: voidCallback;
    idBangKeExcel: string | null;
    setIdBangKeExcel: voidCallback;
    dataStep4Origin: Map<string, dataStep4> | null;
}

export const InvoiceContext = createContext<InvoiceContextInterface>({
    searchField: '',
    setSearchField: VoidCallBack,
    checkedProducts: null,
    setCheckedProducts: VoidCallBack,
    open: false,
    activeStep: 0,
    setActiveStep: VoidCallBack,
    formType: FormTypeEnum.ATIGA,
    setFormType: VoidCallBack,
    startDate: new Date(),
    setStartDate: VoidCallBack,
    psrData: null,
    setPsrData: VoidCallBack,
    bangKeData: null,
    setBangKeData: VoidCallBack,
    MapBangKeData: VoidCallBack,
    ResetDefault: VoidCallBack,
    GetBangKeExcel: VoidCallBack,
    bangKeExcelData: null,
    setBangKeExcelData: VoidCallBack,
    isDownLoadExcel: false,
    setDownLoadExcel: VoidCallBack,
    xlxsExportList: null,
    defaultDisplay: true,
    setDefaultDisplay: VoidCallBack,
    optionForm: null,
    setOptionForm: VoidCallBack,
    optionPrice: null,
    setOptionPrice: VoidCallBack,
    optionOrigin: null,
    setOptionOrigin: VoidCallBack,
    setXlxsExportList: VoidCallBack,
    useBegin: true,
    setUseBegin: VoidCallBack,
    matchContract: true,
    setMatchContract: VoidCallBack,
    products: null,
    setProducts: VoidCallBack,
    checkListProvider: null,
    setCheckListProvider: VoidCallBack,
    checkAll: false,
    setCheckAll: VoidCallBack,
    limit: 0,
    setLimit: VoidCallBack,
    offset: 0,
    setOffset: VoidCallBack,
    count: null,
    setCount: VoidCallBack,
    searchInput: '',
    setSearchInput: VoidCallBack,
    OpenModal: VoidCallBack,
    isGenerateFile: false,
    setGenerateFile: VoidCallBack,
    selectableFields: null,
    setSelectableFields: VoidCallBack,
    setOrdering: VoidCallBack,
    extraFieldLength: null,
    selectParams: null,
    setSelectParams: VoidCallBack,
    FilterLoadMore: VoidCallBack,
    loadPrevious: false,
    FilterLoadPrevious: VoidCallBack,
    openModalApply: false,
    setOpenModalApply: VoidCallBack,
    setApplyAllValue: VoidCallBack,
    setSubmitStep2: VoidCallBack,
    GetPerformManual: VoidCallBack,
    performManual: null,
    listPerformManual: null,
    setListPerformManual: VoidCallBack,
    setDataStep4: VoidCallBack,
    isInitStep4: true,
    setInitStep4: VoidCallBack,
    idBangKeExcel: null,
    setIdBangKeExcel: VoidCallBack,
    dataStep4Origin: null
});

function InvoiceProvider({ children }: ContextProviderProps) {
    const dispatch = useDispatch();
    const invoiceRepo = useInvoiceRepo();
    const { showSnackBar } = useSnackBar();
    const [open, setOpen] = useState(false);
    const [useBegin, setUseBegin] = useState(true);
    const [activeStep, setActiveStep] = useState(0);
    const [matchContract, setMatchContract] = useState(true);
    const [defaultDisplay, setDefaultDisplay] = useState<boolean | null>(null);
    const [isDownLoadExcel, setDownLoadExcel] = useState(false);
    const { company_slug, folder_slug } = useCompanyFolderSlug();
    const [searchField, setSearchField] = useState<string | null>(null);
    const [psrData, setPsrData] = useState<null | PSRDataInterface[]>(null);
    const [bangKeData, setBangKeData] = useState<BangKeDataInterface[]>([]);
    const [startDate, setStartDate] = useState<Date>(GetFirstDay(new Date()));
    const [formType, setFormType] = useState<FormTypeEnum>(FormTypeEnum.ATIGA);
    const [optionForm, setOptionForm] = useState<OptionFormEnum | null>(null);
    const [optionPrice, setOptionPrice] = useState<OptionPriceEnum | null>(null);
    const [optionOrigin, setOptionOrigin] = useState<OptionOriginEnum | null>(null);
    const [xlxsExportList, setXlxsExportList] = useState<XlxsExportListInterface[] | null>(null);
    const [stockQuantity, setStockQuantity] = useState<StockQuantityDataInterface[] | null>(null);
    const [bangKeExcelData, setBangKeExcelData] = useState<null | BangKeExcelDataInterface[]>(null);
    const [checkedProducts, setCheckedProducts] = useState<null | InvoiceProductData[]>(null);

    const [searchInput, setSearchInput] = useState<string>('');
    const [count, setCount] = useState<number | null>(null);
    const [offset, setOffset] = useState<number>(0);
    const [limit, setLimit] = useState<number>(50);
    const [checkAll, setCheckAll] = useState(false);
    const [checkListProvider, setCheckListProvider] = useState<CheckPropertyInterface[] | null>(null);
    const [products, setProducts] = useState<InvoiceProductData[] | null>(null);
    const [isGenerateFile, setGenerateFile] = useState(false);
    const [selectableFields, setSelectableFields] = useState<{ [key: string]: any[] } | null>(null);

    const [ordering, setOrdering] = useState<string | null>(null);
    const [extraFieldLength, setExtraFieldLength] = useState<number | null>(null);
    const [selectParams, setSelectParams] = useState<any | null>(null);
    const [filterOffset, setFilterOffset] = useState(0);
    const [filterLimit] = useState(100);

    const ecusRepo = useEcusRepo();
    const [bangKeMap, setBangKeMap] = useState<any>(null);
    const [openModalApply, setOpenModalApply] = useState(false);
    const [applAllValue, setApplyAllValue] = useState<Step2ApplyAllValue | null>(null);
    const [submitStep2, setSubmitStep2] = useState(false);
    const [performManual, setPerformManual] = useState<BangKePerformManualInterface | null>(null);
    const [listPerformManual, setListPerformManual] = useState<BangKePerformManualInterface | null>(null);
    const [dataStep4, setDataStep4] = useState<Map<string, dataStep4> | null>(null);
    const [isInitStep4, setInitStep4] = useState(false);
    const [idBangKeExcel, setIdBangKeExcel] = useState<string | null>(null);
    const [dataStep4Origin, setDataStep4Origin] = useState<Map<string, dataStep4> | null>(null);

    const loadPrevious = useMemo(() => {
        if (filterOffset === 0) return true;
        return false;
    }, [filterOffset]);

    const fieldQueryParam: FieldsQueryParams = useMemo(() => {
        let params: FieldsQueryParams = {};
        if (searchInput) params['q'] = searchInput;
        if (ordering) params['ordering'] = ordering;
        if (selectParams) params = { ...params, ...selectParams };
        return params;
    }, [searchInput, ordering, selectParams]);

    const pagingQueryParam: PagingQueryParams = useMemo(() => {
        return {
            offset,
            limit
        };
    }, [limit, offset]);

    async function FetchProducts(fieldQueryParam: FieldsQueryParams, pagingQueryParam: PagingQueryParams) {
        dispatch(setLoading(true));
        const response = await invoiceRepo.getInvoiceProduct({ company_slug, folder_slug, ...fieldQueryParam, ...pagingQueryParam });
        match(response)
            .with({ state: ResultState.success }, ({ data }) => {
                setProducts(data.results as InvoiceProductData[]);
                setCount(data.count);
                setCheckListProvider(null);
                setCheckedProducts(null);
                dispatch(setLoading(false));
            })
            .with({ state: ResultState.failed }, ({ exception }) => {
                console.log(exception.message);
            })
            .exhaustive();
    }

    async function FetchFieldSelector(limit: number, offset: number) {
        dispatch(setLoading(true));
        const selectableFields = await ecusRepo.getSelectableValues({ company_slug, folder_slug, limit, offset });
        if (selectableFields) {
            setSelectableFields(selectableFields);
        }
    }

    useEffect(() => {
        FetchFieldSelector(filterLimit, filterOffset);
    }, [filterLimit, filterOffset]);

    function FilterLoadMore() {
        setFilterOffset((prev: number) => prev + filterLimit);
    }

    function FilterLoadPrevious() {
        setFilterOffset((prev: number) => prev - filterLimit);
    }

    useEffect(() => {
        if (checkListProvider) {
            const convertList = checkListProvider.map((item) => item.item);
            setCheckedProducts(convertList);
        }
        if (checkListProvider === null) setCheckedProducts(null);
    }, [checkListProvider]);

    const OpenModal = useCallback(
        (state: boolean) => {
            if (state) {
                if (checkedProducts) {
                    setOpen(true);
                }
            } else {
                setOpen(state);
            }
        },
        [checkedProducts]
    );

    const prsQueryParams = useMemo(() => {
        if (checkedProducts) {
            return {
                company_slug: company_slug,
                folder_slug: folder_slug,
                hs: checkedProducts.map((item) => {
                    return item.hs.slice(0, 6);
                })
            };
        } else return null;
    }, [checkedProducts]);

    async function FetchPSR(params: PsrQueryParams) {
        dispatch(setLoading(true));
        const response = await invoiceRepo.getPSR(params as PsrQueryParams);
        match(response)
            .with({ state: ResultState.success }, ({ data }) => {
                setPsrData(data.results as unknown[] as PSRDataInterface[]);
                dispatch(setLoading(false));
            })
            .with({ state: ResultState.failed }, ({ exception }) => {
                showSnackBar(exception.message, SnackBarAlertEnum.error);
                dispatch(setLoading(false));
            })
            .exhaustive();
    }

    useEffect(() => {
        if (open && prsQueryParams !== null) {
            FetchPSR(prsQueryParams as PsrQueryParams);
        }
    }, [open, prsQueryParams]);

    const AddExternalField = useCallback(
        <T,>(data: T[]) => {
            const extraField = { lookup: optionForm, origin_option: optionOrigin, price_option: optionPrice };
            return data.map((item: T) => {
                return { ...item, ...extraField };
            });
        },
        [optionForm, optionPrice, optionOrigin]
    );

    const getBangKeExcelData = useMemo(() => {
        if (startDate && bangKeData && bangKeData.length != 0 && !defaultDisplay) {
            return { start_date: ConvertDate(startDate), match_contract: matchContract, data: [...bangKeData], match_begin: useBegin };
        } else if (startDate && bangKeData && bangKeData.length != 0 && defaultDisplay) {
            return {
                start_date: ConvertDate(startDate),
                match_contract: matchContract,
                match_begin: useBegin,
                data: [...AddExternalField<BangKeDataInterface>(bangKeData)]
            };
        }
        return null;
    }, [bangKeData, startDate, defaultDisplay, matchContract, useBegin]);

    async function GetBangKeExcel(data: Object, defaultDisplay: boolean) {
        if (defaultDisplay) {
            const response = await invoiceRepo.getBangKeExcelV2({ company_slug: company_slug, folder_slug: folder_slug }, data);
            match(response)
                .with({ state: ResultState.success }, ({ data }) => {
                    setBangKeExcelData(data.data as BangKeExcelDataInterface[]);
                    setGenerateFile(false);
                })
                .with({ state: ResultState.failed }, ({ exception }) => {
                    setGenerateFile(false);
                    showSnackBar(exception.message, SnackBarAlertEnum.error);
                })
                .exhaustive();
        } else {
            const response = await invoiceRepo.getBangKeExcel({ company_slug: company_slug, folder_slug: folder_slug }, data);
            match(response)
                .with({ state: ResultState.success }, ({ data }) => {
                    setBangKeExcelData(data.data as BangKeExcelDataInterface[]);
                    setGenerateFile(false);
                })
                .with({ state: ResultState.failed }, ({ exception }) => {
                    setGenerateFile(false);
                    showSnackBar(exception.message, SnackBarAlertEnum.error);
                })
                .exhaustive();
        }
    }

    useEffect(() => {
        if (bangKeMap && applAllValue) {
            const newList: BangKeData[] = [];
            //@ts-ignore
            const { use_sedo, criteria, form_type, option } = applAllValue;
            if (use_sedo !== undefined) {
                bangKeMap.forEach((value: BangKeData) => {
                    const newValue: BangKeDataInterface = { ...value, option, form_type, criteria, use_sedo };
                    const newBangKeItem = new BangKeData().fromJSON(newValue);
                    newList.push(newBangKeItem);
                });
            } else {
                bangKeMap.forEach((value: BangKeData) => {
                    const newValue: BangKeDataInterface = { ...value, option, form_type, criteria };
                    const newBangKeItem = new BangKeData().fromJSON(newValue);
                    newList.push(newBangKeItem);
                });
            }

            setBangKeData(newList);
            setApplyAllValue(null);
            setOpenModalApply(false);
        }
    }, [applAllValue, bangKeMap]);

    useEffect(() => {
        if (bangKeMap && submitStep2) {
            const newList: BangKeData[] = [];
            bangKeMap.forEach((value: BangKeData, key: string) => {
                const bangKeItem = new BangKeData().fromJSON(value);
                newList.push(bangKeItem);
            });
            setBangKeData(newList);
            setSubmitStep2(false);
        }
    }, [bangKeMap, submitStep2]);

    function MapBangKeData(data: BangKeDataInterface) {
        const bangKeItem = new BangKeData().fromJSON(data);
        setBangKeMap((prev: any) => {
            if (prev === null) {
                const map = new Map();
                map.set(bangKeItem.id, bangKeItem);
                return map;
            } else {
                return new Map(prev.set(bangKeItem.id, bangKeItem));
            }
        });
    }

    function ResetDefault() {
        OpenModal(false);
        setOffset(0);
        setSearchInput('');
        setOrdering(null);

        setActiveStep(0);
        setStartDate(GetFirstDay(new Date()));
        setFormType(FormTypeEnum.ATIGA);
        setPsrData(null);
        setBangKeData([]);
        setCheckListProvider(null);
        setCheckedProducts(null);
        setXlxsExportList(null);
        setBangKeExcelData(null);
        setExtraFieldLength(null);
        setBangKeMap(null);
        setDataStep4(null);
        setPerformManual(null);
        setListPerformManual(null);
        setDataStep4Origin(null);
        setInitStep4(false);
        setIdBangKeExcel(null);
    }

    useEffect(() => {
        if (activeStep === 2 && getBangKeExcelData !== null && defaultDisplay !== null) {
            GetBangKeExcel(getBangKeExcelData, defaultDisplay);
            setGenerateFile(true);
        }
        if (activeStep === 3) setXlxsExportList(null);
    }, [activeStep, getBangKeExcelData, defaultDisplay]);

    const fixedNumHeader = useMemo(() => {
        const fixedNumList: string[] = [];
        for (let i = 1; i <= 13; i++) {
            fixedNumList.push(`(${i})`);
        }
        return fixedNumList;
    }, []);

    function ConvertRow(row: BangKeExcelRowDataInterface, index: number) {
        return [
            index + 1,
            row.material_name,
            row.hs,
            row.material_unit,
            row.bom_value != 0 ? row.bom_value : null,
            row.unit_price != 0 ? row.unit_price : null,
            row.has_fta_origin_cost != 0 ? row.has_fta_origin_cost : null,
            row.no_fta_origin_cost != 0 ? row.no_fta_origin_cost : null,
            row.from_country,
            row.account_number,
            row.registered_date,
            row.reference_no,
            row.issued_date
        ];
    }

    function MapStockQuantity(data: BangKeExcelDataInterface) {
        const { total, results } = data;
        const result: StockQuantityDataInterface[] = [];
        if (total && results && results.length != 0) {
            data.results.forEach((item: BangKeExcelRowData) => {
                result.push({
                    purchase_stock_id: item.purchase_stock_id,
                    ecus_stock_id: item.ecus_stock_id,
                    exclude_total_quantity: total,
                    begin_id: item.begin_id,
                    bom_value: item.bom_value
                });
            });
        }

        setStockQuantity((prev) => {
            if (prev === null) {
                return [...result];
            } else return [...prev, ...result];
        });
    }

    async function PutStockQuantity(data: StockQuantityInterface | null) {
        const response = await invoiceRepo.updateStockQuantity(data, { company_slug, folder_slug });
        match(response)
            .with({ state: ResultState.success }, ({ data }) => {})
            .with({ state: ResultState.failed }, ({ exception }) => {
                console.log(exception.message);
            })
            .exhaustive();
    }

    const stockQuantityData: StockQuantityInterface | null = useMemo(() => {
        if (stockQuantity) {
            return { data: stockQuantity };
        }
        return null;
    }, [stockQuantity]);

    const createBangKeHistoryParams = useMemo(() => {
        if (!bangKeData || !bangKeExcelData) return null;

        let data: BangKeHistoryDataRequest[] = [];
        bangKeData.forEach((item: BangKeDataInterface, index: number) => {
            const { id, option, criteria } = item;
            const { price } = bangKeExcelData[index];
            const dataRequest = new BangKeHistoryDataRequest().fromJSON({ id, option, criteria, price });
            data.push(dataRequest);
        });
        return data;
    }, [bangKeData, bangKeExcelData]);

    async function CreateBangKeHistory(params: BalanceParams, data: { data: BangKeHistoryDataRequest[] }) {
        const response = await invoiceRepo.createBangKeHistory(params, data);
        match(response)
            .with({ state: ResultState.success }, ({ data }) => {
                console.log(data);
            })
            .with({ state: ResultState.failed }, ({ exception }) => {
                console.log(exception.message);
            })
            .exhaustive();
    }

    useEffect(() => {
        const formTypeConverted = ConvertFormType(formType);
        let results: XlxsExportListInterface[] = [];
        if (!bangKeData || !bangKeExcelData) return;
        if (bangKeData.length !== bangKeExcelData.length) return;
        else {
            if (formTypeConverted === 'EWX') {
                bangKeExcelData.forEach((excelData, index) => {
                    const { use_sedo = false, form_type } = bangKeData[index];
                    const header = CreateFixedHeader(fixedNumHeader, { excelRow: excelData, bangKeItem: bangKeData[index] });
                    const rowCount = header.length;
                    let footMergeCell: any;
                    let rvc: any;
                    let progessPriceValue: any;
                    let factoryPrice: any;
                    let sheetDataDynamic: any = [];

                    if (form_type === DropDownApplicableEnum.RVC && !use_sedo) {
                        rvc = CreateRVCEWX(excelData);
                        footMergeCell = CreateEXWNonSedoFootMergerCell(rowCount + excelData.results.length);
                        setExtraFieldLength(2);
                    } else if (form_type === DropDownApplicableEnum.RVC && use_sedo) {
                        footMergeCell = CreateEXWWithSedoFootMergerCell(rowCount + excelData.results.length);
                        setExtraFieldLength(4);
                    } else if (form_type === DropDownApplicableEnum.CTC && !use_sedo) {
                        footMergeCell = CreateCCMergerCell(rowCount + excelData.results.length);
                        setExtraFieldLength(0);
                    }
                    const sheetMergeCell = CreateFOBMergeCell(footMergeCell);
                    MapStockQuantity(excelData);

                    if (excelData.results && excelData.results.length != 0) {
                        const totalNoFTA = CreateTotalNoFta(excelData);
                        const finalResult = CreateFinalResult(excelData);

                        if (use_sedo) {
                            factoryPrice = CreateFactoryPrice(excelData, bangKeData[index]);
                            progessPriceValue = CreateProgessPriceValue(excelData, bangKeData[index]);
                        }

                        const sheetRows = excelData.results.map((row, index) => ConvertRow(row, index));
                        let sheetData = [...header, ...sheetRows, totalNoFTA];
                        if (form_type === DropDownApplicableEnum.RVC && !use_sedo) {
                            sheetData = [...sheetData, ...rvc];
                            sheetDataDynamic = [...rvc];
                        } else if (form_type === DropDownApplicableEnum.RVC && use_sedo) {
                            sheetData = [...sheetData, progessPriceValue, ...factoryPrice];
                            sheetDataDynamic = [progessPriceValue, ...factoryPrice];
                        } else if (form_type === DropDownApplicableEnum.CTC) {
                        }
                        sheetDataDynamic = [...sheetDataDynamic, ...finalResult];
                        sheetData = [...sheetData, ...finalResult];
                        const name = bangKeData[index].code;
                        const billDate = bangKeData[index]?.bill_date ?? '';
                        const combineName = billDate + '-' + name;

                        const xlxsValue: XlxsExportListInterface = {
                            sheetData,
                            sheetMergeCell,
                            sheetName: combineName.slice(0, 25),
                            rows: excelData.results.length,
                            sheetDataFixed: [...header, ...sheetRows, totalNoFTA],
                            sheetDataDynamic
                        };

                        results.push(xlxsValue);
                    } else results.push({ sheetData: null, rows: null, sheetMergeCell: null });

                    setXlxsExportList(results);
                });
            } else {
                bangKeExcelData.forEach((excelData, index) => {
                    const { form_type } = bangKeData[index];
                    const header = CreateFixedHeader(fixedNumHeader, { excelRow: excelData, bangKeItem: bangKeData[index] });
                    const rowCount = header.length;
                    let footMergeCell = CreateCCMergerCell(rowCount + excelData.results.length);
                    setExtraFieldLength(0);
                    let rvc: any;
                    let sheetDataDynamic: any = [];

                    if (form_type === DropDownApplicableEnum.RVC) {
                        rvc = CreateRVC(excelData);
                        footMergeCell = CreateFOBFootMergeCell(rowCount + excelData.results.length);
                        setExtraFieldLength(2);
                    }

                    const sheetMergeCell = CreateFOBMergeCell(footMergeCell);
                    MapStockQuantity(excelData);

                    if (excelData.results && excelData.results.length != 0) {
                        const sheetRows = excelData.results.map((row, index) => ConvertRow(row, index));
                        const totalNoFTA = CreateTotalNoFta(excelData);
                        const finalResult = CreateFinalResult(excelData);
                        let sheetData = [...header, ...sheetRows, totalNoFTA];

                        if (form_type === DropDownApplicableEnum.RVC) {
                            sheetData = [...sheetData, ...rvc];
                            sheetDataDynamic = [...rvc];
                        }
                        sheetDataDynamic = [...sheetDataDynamic, ...finalResult];
                        sheetData = [...sheetData, ...finalResult];
                        const name = bangKeData[index].code;
                        const billDate = bangKeData[index]?.bill_date ?? '';
                        const combineName = billDate + '-' + name;

                        const xlxsValue: XlxsExportListInterface = {
                            sheetData,
                            sheetMergeCell,
                            sheetName: combineName.slice(0, 25),
                            rows: excelData.results.length,
                            sheetDataFixed: [...header, ...sheetRows, totalNoFTA],
                            sheetDataDynamic
                        };

                        results.push(xlxsValue);
                    } else results.push({ sheetData: null, rows: null, sheetMergeCell: null });

                    setXlxsExportList(results);
                });
            }
        }
    }, [bangKeData, bangKeExcelData, formType]);

    useEffect(() => {
        if (xlxsExportList && isDownLoadExcel && createBangKeHistoryParams) {
            exportXlsx(xlxsExportList);
            PutStockQuantity(stockQuantityData);
            CreateBangKeHistory({ company_slug, folder_slug }, { data: createBangKeHistoryParams });
            setDownLoadExcel(false);
            setTimeout(() => {
                window.location.href = window.location.href;
            }, 500);
        }
    }, [xlxsExportList, isDownLoadExcel, createBangKeHistoryParams]);

    function getReCalculateBangKeExcelParam(bangKeData: BangKeDataInterface[], bangKeExcelData: BangKeExcelDataInterface[]) {
        let value: any = [];
        bangKeExcelData.forEach((item: BangKeExcelDataInterface, index: number) => {
            const { id, form_type, criteria, input, option } = bangKeData[index];
            const { results } = item;
            value.push({ id, form_type, criteria, input, option, data: results });
        });
        return value;
    }

    async function GetReCalculateBangKe(data: any) {
        setGenerateFile(true);
        const response = await invoiceRepo.getReCalculateBangKeExcel({ company_slug, folder_slug }, data);
        match(response)
            .with({ state: ResultState.success }, ({ data }) => {
                setBangKeExcelData(data.data as BangKeExcelDataInterface[]);
                setGenerateFile(false);
            })
            .with({ state: ResultState.failed }, () => {})
            .exhaustive();
    }

    useEffect(() => {
        if (dataStep4 && activeStep === 3) {
            setDataStep4Origin((prev: Map<string, dataStep4> | null) => {
                if (prev === null) return dataStep4;
                else return prev;
            });

            const newBangKeData: BangKeDataInterface[] = [];
            const newBangKeExcel: BangKeExcelDataInterface[] = [];

            dataStep4.forEach((item: dataStep4) => {
                const { bangKeData, bangKeExcelData } = item;
                newBangKeData.push(bangKeData);
                newBangKeExcel.push(bangKeExcelData);
            });
            if (newBangKeData && newBangKeExcel) {
                const value = getReCalculateBangKeExcelParam(newBangKeData, newBangKeExcel);
                if (value) GetReCalculateBangKe({ data: value });
                setInitStep4(true);
            }
        }
    }, [dataStep4, activeStep]);

    useEffect(() => {
        FetchProducts(fieldQueryParam, pagingQueryParam);
    }, [fieldQueryParam, pagingQueryParam]);

    async function GetPerformManual({
        origin_option,
        price_option,
        data,
        total,
        all_option,
        type,
        listLimit,
        listOffset
    }: GetPerformManualProps & { total: number; type: string; all_option: boolean }) {
        dispatch(setLoading(true));
        const response = await invoiceRepo.getDropDownPerformManual(
            { company_slug, folder_slug, limit: listLimit, offset: listOffset },
            {
                start_date: ConvertDate(startDate),
                origin_option,
                price_option,
                total,
                all_option,
                data
            }
        );
        match(response)
            .with({ state: ResultState.success }, ({ data }) => {
                const { data: dataList } = data;
                const item = dataList[0];
                const { results } = item;
                if (results.length === 0) {
                    switch (type) {
                        case 'drop_down':
                            showSnackBar('Empty dropdown', SnackBarAlertEnum.warning);
                            break;
                        default:
                            showSnackBar('Empty list', SnackBarAlertEnum.warning);
                            break;
                    }
                }
                switch (type) {
                    case 'drop_down':
                        setPerformManual(item);
                        break;
                    default:
                        setListPerformManual(item);
                        break;
                }
                dispatch(setLoading(false));
            })
            .with({ state: ResultState.failed }, ({ exception }) => {
                showSnackBar(exception.meaning, SnackBarAlertEnum.error);
                dispatch(setLoading(false));
            })
            .exhaustive();
        return;
    }

    return (
        <InvoiceContext.Provider
            value={{
                searchField,
                setSearchField,
                checkedProducts,
                setCheckedProducts,
                open,
                activeStep,
                setActiveStep,
                startDate,
                setStartDate,
                formType,
                setFormType,
                psrData,
                setPsrData,
                bangKeData,
                setBangKeData,
                MapBangKeData,
                ResetDefault,
                GetBangKeExcel,
                bangKeExcelData,
                setBangKeExcelData,
                isDownLoadExcel,
                setDownLoadExcel,
                xlxsExportList,
                setDefaultDisplay,
                setOptionForm,
                setOptionOrigin,
                setOptionPrice,
                setXlxsExportList,
                setUseBegin,
                setMatchContract,
                setProducts,
                setCheckListProvider,
                setCheckAll,
                setLimit,
                setOffset,
                setCount,
                setSearchInput,
                checkAll,
                checkListProvider,
                count,
                limit,
                offset,
                products,
                searchInput,
                OpenModal,
                isGenerateFile,
                setGenerateFile,
                selectableFields,
                setSelectableFields,
                defaultDisplay,
                optionForm,
                optionOrigin,
                optionPrice,
                matchContract,
                useBegin,
                setOrdering,
                extraFieldLength,
                selectParams,
                setSelectParams,
                FilterLoadMore,
                loadPrevious,
                FilterLoadPrevious,
                openModalApply,
                setOpenModalApply,
                setApplyAllValue,
                setSubmitStep2,
                GetPerformManual,
                performManual,
                listPerformManual,
                setListPerformManual,
                setDataStep4,
                isInitStep4,
                setInitStep4,
                idBangKeExcel,
                setIdBangKeExcel,
                dataStep4Origin
            }}
        >
            {children}
        </InvoiceContext.Provider>
    );
}

export default InvoiceProvider;
