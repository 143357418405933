import { Any, JsonObject, JsonProperty } from 'json2typescript';
import { JsonSerializable } from '../serialization';

export interface IPurchaseData {
    id: string;
    created_at: string;
    updated_at?: string;
    code: string;
    name: string;
    unit: string;
    bill_date: string;
    bill_no: string;
    quantity: number;
    balance_id: number;
    import_record_id?: number;
    stt: number;
    contract: string;
    ma_ncc?: string;
    ten_ncc?: string;
    ma_nx?: number;
    ma_kho: string;
    don_gia_mua: number;
    tien_hang: number;
    tien_cp: number;
    gia_von: number;
    sub_total: number;
    vat: number;
    total: number;
    vat_num: string;
    registered_date: string;
    hs: string;
    total_quantity: number;
    unit_price_usd: number;
}

@JsonObject('PurchaseData')
export class PurchaseData extends JsonSerializable<PurchaseData> implements IPurchaseData {
    protected get classRef(): new () => PurchaseData {
        return PurchaseData;
    }

    @JsonProperty('id', String, false)
    id!: string;

    @JsonProperty('created_at', String, false)
    created_at!: string;

    @JsonProperty('updated_at', String, true)
    updated_at?: string;

    @JsonProperty('code', String, false)
    code!: string;

    @JsonProperty('name', String, false)
    name!: string;

    @JsonProperty('unit', String, false)
    unit!: string;

    @JsonProperty('bill_date', String, true)
    bill_date: string = '';

    @JsonProperty('bill_no', String, true)
    bill_no: string = '';

    @JsonProperty('quantity', Number, true)
    quantity: number = 0;

    @JsonProperty('balance_id', Number, false)
    balance_id!: number;

    @JsonProperty('import_record_id', Number, true)
    import_record_id?: number;

    @JsonProperty('stt', Any, false)
    stt!: any;

    @JsonProperty('contract', String, false)
    contract!: string;

    @JsonProperty('ma_ncc', String, true)
    ma_ncc: string = '';

    @JsonProperty('ten_ncc', String, true)
    ten_ncc: string = '';

    @JsonProperty('ma_nx', Number, true)
    ma_nx: number = 0;

    @JsonProperty('ma_kho', String, true)
    ma_kho: string = '';

    @JsonProperty('don_gia_mua', Number, true)
    don_gia_mua: number = 0;

    @JsonProperty('tien_hang', Number, true)
    tien_hang: number = 0;

    @JsonProperty('tien_cp', Number, true)
    tien_cp: number = 0;

    @JsonProperty('gia_von', Number, true)
    gia_von: number = 0;

    @JsonProperty('sub_total', Number, true)
    sub_total: number = 0;

    @JsonProperty('vat', Number, true)
    vat: number = 0;

    @JsonProperty('total', Number, true)
    total: number = 0;

    @JsonProperty('vat_num', String, true)
    vat_num: string = '';

    @JsonProperty('registered_date', String, true)
    registered_date: string = '';

    @JsonProperty('hs', String, true)
    hs: string = '';

    @JsonProperty('total_quantity', Number, true)
    total_quantity: number = 0;

    @JsonProperty('unit_price_usd', Number, true)
    unit_price_usd: number = 0;
}
