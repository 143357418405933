import { JsonObject, JsonProperty } from 'json2typescript';
import { JsonSerializable } from '../serialization';
export interface IMapCodeData {
    id: string;
    ecus_code?: string;
    acc_wh_code?: string;
    import_record_id?: number;
    created_at: string;
    company_slug_id?: number;
    folder_slug_id?: number;
}

@JsonObject('MapCodeData')
export class MapCodeData extends JsonSerializable<MapCodeData> implements IMapCodeData {
    protected get classRef(): new () => MapCodeData {
        return MapCodeData;
    }

    @JsonProperty('id', String, false)
    id!: string;

    @JsonProperty('created_at', String, true)
    created_at: string = '';
}
