import { JsonObject, JsonProperty } from 'json2typescript';
import { JsonSerializable } from 'models/serialization';

export interface InvoiceProductDataInterface {
    id: string;
    hs: string;
    code: string;
    item_name: string;
    total: number;
    unit_price: number;
    nt_value: number;
    account_number: string;
    registered_date: string;
    exchange_rate: number;
    invoice_price_condition: string;
    invoice_no: string;
    bill_date: string;
    unloading_location: string;
    shipping_date: string;
    total_value: number;
    type_code: string;
    from_country: string;
    partner: string;
}

@JsonObject('InvoiceProductData')
export class InvoiceProductData extends JsonSerializable<InvoiceProductData> implements InvoiceProductDataInterface {
    protected get classRef(): new () => InvoiceProductData {
        return InvoiceProductData;
    }

    @JsonProperty('id', String, false)
    id!: string;

    @JsonProperty('hs', String, false)
    hs!: string;

    @JsonProperty('code', String, false)
    code!: string;

    @JsonProperty('item_name', String, false)
    item_name!: string;

    @JsonProperty('total', Number, false)
    total!: number;

    @JsonProperty('unit_price', Number, false)
    unit_price!: number;

    @JsonProperty('nt_value', Number, false)
    nt_value!: number;

    @JsonProperty('account_number', String, false)
    account_number!: string;

    @JsonProperty('registered_date', String, false)
    registered_date!: string;

    @JsonProperty('invoice_price_condition', String, false)
    invoice_price_condition!: string;

    @JsonProperty('exchange_rate', Number, false)
    exchange_rate!: number;

    @JsonProperty('invoice_no', String, false)
    invoice_no!: string;

    @JsonProperty('bill_date', String, false)
    bill_date!: string;

    @JsonProperty('unloading_location', String, true)
    unloading_location: string = '';

    @JsonProperty('shipping_date', String, true)
    shipping_date: string = '';

    @JsonProperty('type_code', String, true)
    type_code: string = '';

    @JsonProperty('from_country', String, true)
    from_country: string = '';

    @JsonProperty('partner', String, true)
    partner: string = '';

    @JsonProperty('total_value', Number, true)
    total_value: number = 0;
}
