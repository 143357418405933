import { VoidCallBack, voidCallback } from 'components/BalanceTable/TableInvoice/InvoiceProvider';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { InvoiceWordContext } from '../InvoiceWordProvider';
import { CheckPropertyInterface } from '../TableInvoiceWord';
import useGeneratePage from '../hooks/useGeneratePage';

export interface InvoiceModalProviderInterface {
    open: boolean;
    revertData: CheckPropertyInterface[] | null;
    checkList: CheckPropertyInterface[] | null;
    setCheckList: voidCallback;
    OpenModal: voidCallback;
    setSubmit: voidCallback;
    submitStep2: boolean;
    uniqHSMap: any;
    OriginHandler: (id: string, hs: string, originate: string, isCombine: boolean) => void;
    SubmitExtraStepHandler: voidCallback;
    ResetDefault: voidCallback;
}

export const InvoiceModalContext = createContext<InvoiceModalProviderInterface>({
    open: false,
    revertData: null,
    checkList: null,
    setCheckList: VoidCallBack,
    OpenModal: VoidCallBack,
    setSubmit: VoidCallBack,
    submitStep2: false,
    uniqHSMap: null,
    OriginHandler: (id: string, hs: string, originate: string, isCombine: boolean) => {},
    SubmitExtraStepHandler: VoidCallBack,
    ResetDefault: VoidCallBack
});

function InvoiceModalProvider({ children }: { children: React.ReactNode }) {
    const {
        label,
        place,
        fieldState,
        userInput,
        userInput2,
        vesselName,
        wordFormType,
        rowPerPage,
        markPackage,
        portDischarge,
        retroactively,
        exhibition,
        movement,
        thirdParty,
        referenceNo,
        companyConsignee,
        companyExporter,
        departureDate,
        activeStep,
        linkTopFile,
        linkTopFileNoUserInput,
        linkBotFile,
        gw,
        back2back,
        cumulation,
        thirdCountry
    } = useContext(InvoiceWordContext);

    const [open, setOpen] = useState(false);
    const [checkList, setCheckList] = useState<CheckPropertyInterface[] | null>(null);

    const { setSubmit, submitStep2, uniqHSMap, OriginHandler, SubmitExtraStepHandler, revertData, ResetDefault } = useGeneratePage({
        label,
        place,
        fieldState,
        exhibition,
        markPackage,
        movement,
        portDischarge,
        referenceNo,
        retroactively,
        rowPerPage,
        thirdParty,
        userInput,
        userInput2,
        vesselName,
        wordFormType,
        companyConsignee,
        companyExporter,
        departureDate,
        activeStep,
        linkBotFile,
        linkTopFile,
        linkTopFileNoUserInput,
        checkList,
        gw,
        back2back,
        cumulation,
        thirdCountry
    });

    const OpenModal = useCallback(
        (state: boolean) => {
            if (state) {
                if (checkList) {
                    setOpen(true);
                }
            } else {
                setOpen(state);
            }
        },
        [checkList]
    );

    useEffect(() => {
        if (!open) {
            setCheckList(null);
            // ResetGeneratePageDefault();
        }
    }, [open]);

    return (
        <InvoiceModalContext.Provider
            value={{
                open,
                revertData,
                setCheckList,
                OpenModal,
                setSubmit,
                submitStep2,
                checkList,
                uniqHSMap,
                OriginHandler,
                SubmitExtraStepHandler,
                ResetDefault
            }}
        >
            {children}
        </InvoiceModalContext.Provider>
    );
}

export default InvoiceModalProvider;
