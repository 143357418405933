import TableInvoiceWord from 'components/BalanceTable/TableInvoiceWord/TableInvoiceWord';
import TableMapCode from 'components/BalanceTable/TableMapCode/TableMapCode';
import TableReportStatment from 'components/BalanceTable/TableReportStatement/TableReportStatement';
import useAuth from 'hooks/useAuth';
import { lazy, useEffect } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import Loadable from 'ui-component/Loadable';
import MainCard from 'ui-component/cards/MainCard';
import AuthGuard from 'utils/route-guard/AuthGuard';
const UserProfile = Loadable(lazy(() => import('components/UserProfile/index')));
const CompanySetting = Loadable(lazy(() => import('components/CompanySetting/index')));
const AnnexTable = Loadable(lazy(() => import('components/BalanceTable/TableAnnex/AnnexTable')));
const COListTable = Loadable(lazy(() => import('components/BalanceTable/TableCOList/COListTable')));
const BalanceSystem = Loadable(lazy(() => import('views/BalanceSystem/BalanceSystem')));
const InvoiceTab = Loadable(lazy(() => import('components/BalanceTable/TableInvoice/InvoiceTab')));
const TableDetailPage = Loadable(lazy(() => import('components/BalanceTable/TableDetail/TableDetail')));
const EcusStock = Loadable(lazy(() => import('components/BalanceTable/TableEcusStock/TableEcusStock')));
export interface PrivateRouteProps {
    routeType: RouteEnum;
    params?: any;
}

export enum RouteEnum {
    balanceSystem = 'balanceSystem',
    tableDetail = 'tableDetail',
    userPage = 'userPage',
    companyPage = 'companyPage',
    ftaInvoice = 'ftaInvoice',
    ftaCOList = 'ftaCOList',
    ftaANNEX = 'ftaANNEX',
    ftaEcusStock = 'ftaEcusStock',
    ftaEcusWordInvoice = 'ftaEcusWordInvoice',
    mappingCode = 'mappingCode',
    reportStatement = 'reportStatement'
}

const SwitchRoute = ({ routeType, params }: PrivateRouteProps) => {
    switch (routeType) {
        case RouteEnum.ftaANNEX:
            return (
                <AuthGuard>
                    <AnnexTable />
                </AuthGuard>
            );

        case RouteEnum.ftaCOList:
            return (
                <AuthGuard>
                    <COListTable />
                </AuthGuard>
            );

        case RouteEnum.ftaInvoice:
            return (
                <AuthGuard>
                    <InvoiceTab />
                </AuthGuard>
            );

        case RouteEnum.tableDetail:
            return <>{params.tableType && params.tableDetailId ? <TableDetailPage /> : <Navigate to={`/data/exim`} />}</>;

        case RouteEnum.userPage:
            return (
                <AuthGuard>
                    <UserProfile />
                </AuthGuard>
            );

        case RouteEnum.companyPage:
            return (
                <AuthGuard>
                    <CompanySetting />
                </AuthGuard>
            );
        case RouteEnum.ftaEcusStock:
            return (
                <AuthGuard>
                    <EcusStock />
                </AuthGuard>
            );

        case RouteEnum.ftaEcusWordInvoice:
            return (
                <AuthGuard>
                    <TableInvoiceWord />
                </AuthGuard>
            );

        case RouteEnum.mappingCode:
            return (
                <AuthGuard>
                    <MainCard>
                        <TableMapCode />
                    </MainCard>
                </AuthGuard>
            );

        case RouteEnum.reportStatement:
            return (
                <AuthGuard>
                    <MainCard>
                        <TableReportStatment />
                    </MainCard>
                </AuthGuard>
            );
        default:
            return <>{params.tableId ? <BalanceSystem /> : <Navigate to={`/`} />}</>;
    }
};

export const PrivateRoute = ({ routeType }: PrivateRouteProps) => {
    const { isLoggedIn } = useAuth();
    const params = useParams();
    const { pathname } = useLocation();

    useEffect(() => {
        if (!isLoggedIn) {
            localStorage.setItem('path', pathname);
        }
    }, []);

    return <>{isLoggedIn ? <SwitchRoute params={params} routeType={routeType} /> : <Navigate to={`/login`} />}</>;
};
