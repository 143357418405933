import { JsonProperty, JsonObject } from 'json2typescript';
import { JsonSerializable } from 'models/serialization';

export interface InvoiceTableDataInterface {
    id: number;
    invoice_no: string;
    bill_date: string;
    contract_number: string;
}

@JsonObject('InvoiceTableData')
export class InvoiceTableData extends JsonSerializable<InvoiceTableData> implements InvoiceTableDataInterface {
    protected get classRef(): new () => InvoiceTableData {
        return InvoiceTableData;
    }

    @JsonProperty('id', Number, false)
    id!: number;

    @JsonProperty('invoice_no', String, false)
    invoice_no!: string;

    @JsonProperty('date', String, false)
    bill_date!: string;

    @JsonProperty('contract_number', String, true)
    contract_number: string = '';
}
