import { CancleQueryParams } from 'repository/type';
import { CancleDataSource } from './CancleDataSource';
import { CancleData } from 'models/CancleTable';
import { JSONType, resultGuard } from 'models';

export class CancleRepository {
    constructor(private cancleDS: CancleDataSource) {}

    async getAll(params: CancleQueryParams) {
        return this.cancleDS.getAll(params);
    }

    async updateRow(row: Partial<CancleData>, params: CancleData) {
        throw new Error();
    }

    async deleteRow(ids: string[], params: CancleData) {
        throw new Error();
    }
    async insertRow(json: JSONType) {
        throw new Error();
    }

    async importData(data: any) {
        return resultGuard(async () => this.cancleDS.importData(data));
    }
}
