import { InputBase, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ProjectThemeProps } from 'theme';
export * from './DateRangePicker';

const Input = styled(TextField)(({ theme }: { theme: ProjectThemeProps }) => ({
    '& .MuiOutlinedInput-root': {
        outline: 'none',
        '& fieldset': {
            border: `1px solid ${theme.default.mainGrey}`,
            borderColor: theme.default.mainGrey
        },
        '& input': {
            paddingTop: '16px',
            paddingBottom: '16px'
        },
        '&:hover fieldset': {
            border: `1px solid ${theme.default.mainGrey}`,
            borderColor: theme.default.mainGrey
        },
        '&.Mui-focused fieldset': {
            border: `1px solid ${theme.default.mainGrey}`,
            borderColor: theme.default.mainGrey
        }
    }
}));
const StyleInput = (props: any) => {
    return <Input {...props} />;
};

export default StyleInput;
