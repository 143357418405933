import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { Allotment } from 'allotment';
import styles from 'components/BalanceTable/TableEcusNext/styles.module.css';
import { $Table, $TableForm, ColumnMeta, ColumnWidth, TableContext, TableType, useManipulateTable, useQueryTable } from 'components/Table';
import { useProductionRepo } from 'di';
import _ from 'lodash';
import { ProductionData } from 'models';
import { useContext, useMemo } from 'react';
import { BaseQueryParams } from 'repository';
import {useCompanyFolderSlug} from "../../BalanceImport";

const ProductionColumnMetaMap: { [key: string]: ColumnMeta } = {
    code: {
        inputType: 'text',
        enableSorting: true
    },
    plant: {
        inputType: 'text'
    },
    parent_item_acct: {
        inputType: 'number'
    },
    parent_item_acct_desc: {
        inputType: 'text'
    },
    tracking_no: {
        inputType: 'text'
    },
    parent_item_desc: {
        inputType: 'text'
    },
    manufacturing_order_no: {
        inputType: 'text',
        enableSorting: true
    },
    order_sequence: {
        inputType: 'number'
    },
    operation_no: {
        inputType: 'number'
    },
    product_quantity: {
        inputType: 'number',
        enableSorting: true
    },
    cost_center: {
        inputType: 'text'
    },
    cost_center_description: {
        inputType: 'text'
    },
    work_center: {
        inputType: 'text'
    },
    work_center_description: {
        inputType: 'text'
    },
    child_item_account: {
        inputType: 'number'
    },
    child_item_account_desc: {
        inputType: 'text'
    },
    child_item: {
        inputType: 'text',
        enableSorting: true
    },
    child_item_desc: {
        inputType: 'text'
    },
    movement_type: {
        inputType: 'text'
    },
    movement_type_desc: {
        inputType: 'text'
    },
    issue_quantity: {
        inputType: 'number'
    },
    issue_amount: {
        inputType: 'number'
    },
    use_quantity: {
        inputType: 'number',
        enableSorting: true
    },
    use_amount: {
        inputType: 'number'
    }
};
const TableFormType: TableType = TableType.production;

export const TableProductionNext = ( ) => {
    const params: BaseQueryParams = useCompanyFolderSlug()
    const defaultColumns = useMemo<ColumnDef<ProductionData, any>[]>(() => {
        const columnHelper = createColumnHelper<ProductionData>();
        return _.toPairs(ProductionColumnMetaMap).map(([key, value]) =>
            // @ts-ignore
            columnHelper.accessor(key, {
                id: key,
                minSize: ColumnWidth.ssm,
                size: key === 'name' || key === 'item_name' ? ColumnWidth.xxl : ColumnWidth.sm,
                enableSorting: value.enableSorting ?? false,
                cell: (info) => <div className={`tw-pr-[8px]`}>{info.getValue() as React.ReactNode}</div>,
                header: () => <div className={styles.header}>{key}</div>
            })
        );
    }, []);

    const productionRepository = useProductionRepo();

    const { panelVisibility } = useContext(TableContext);

    useQueryTable({
        params,
        fetchFn: (params) => productionRepository.getAll(params)
    });
    useManipulateTable<ProductionData, BaseQueryParams>({
        args: params,
        updateFn: (row, params) => productionRepository.updateRow(row, params),
        deleteFn: (ids) => productionRepository.deleteRows(ids),
        insertFn: (json) => productionRepository.insertRow(json),
        extractDataId: (data) => data.id
    });

    return (
        <>
            <div className="tw-relative tw-h-[886px]">
                <Allotment snap>
                    <Allotment.Pane className={styles.leftPane}>
                        <$Table<ProductionData>
                            enableRowSelection
                            columnsDef={defaultColumns}
                            columnsMeta={ProductionColumnMetaMap}
                            tableType={TableFormType}
                        />
                    </Allotment.Pane>
                    <Allotment.Pane visible={panelVisibility} className={styles.rightPane}>
                        <$TableForm columnsMeta={ProductionColumnMetaMap} TableFormType={TableFormType} />
                    </Allotment.Pane>
                </Allotment>
            </div>
        </>
    );
};
