import { Any, JsonObject, JsonProperty } from 'json2typescript';
import { JsonSerializable } from '../serialization';
export interface IProductionData {
    id: string;
    code: string;
    manufacturing_order_no: string;
    product_quantity: number;
    child_item: string;
    use_quantity: number;
    plant?: string | number;
    parent_item_acct?: string | number;
    parent_item_acct_desc?: string | number;
    tracking_no?: string | number;
    parent_item_desc?: string | number;
    order_sequence?: string | number;
    operation_no?: string | number;
    cost_center?: string | number;
    cost_center_description?: string | number;
    work_center?: string | number;
    work_center_description?: string | number;
    child_item_account?: string | number;
    child_item_account_desc?: string | number;
    child_item_desc?: string | number;
    movement_type?: string | number;
    movement_type_desc?: string | number;
    issue_quantity?: string | number;
    issue_amount?: string | number;
    use_amount?: string | number;
}

@JsonObject('ProductionData')
export class ProductionData extends JsonSerializable<ProductionData> implements IProductionData {
    protected get classRef(): new () => ProductionData {
        return ProductionData;
    }

    static empty(): ProductionData {
        return new ProductionData().fromJSON({
            id: new Date().getMilliseconds(),
            code: '',
            manufacturing_order_no: '',
            product_quantity: 0,
            child_item: '',
            use_quantity: 0,
            plant: '',
            parent_item_acct: '',
            parent_item_acct_desc: '',
            tracking_no: '',
            parent_item_desc: '',
            order_sequence: '',
            operation_no: '',
            cost_center: '',
            cost_center_description: '',
            work_center: '',
            work_center_description: '',
            child_item_account: '',
            child_item_account_desc: '',
            child_item_desc: '',
            movement_type_desc: '',
            issue_quantity: '',
            issue_amount: '',
            use_amount: ''
        });
    }

    @JsonProperty('id', String, false)
    id!: string;

    @JsonProperty('code', String, false)
    code!: string;

    @JsonProperty('manufacturing_order_no', String, false)
    manufacturing_order_no!: string;

    @JsonProperty('product_quantity', Number, false)
    product_quantity!: number;

    @JsonProperty('child_item', String, false)
    child_item!: string;

    @JsonProperty('use_quantity', Number, false)
    use_quantity!: number;

    @JsonProperty('plant', Any, true)
    plant?: string | number;

    @JsonProperty('parent_item_acct', Any, true)
    parent_item_acct?: string | number;

    @JsonProperty('parent_item_acct_desc', Any, true)
    parent_item_acct_desc?: string | number;

    @JsonProperty('tracking_no', Any, true)
    tracking_no?: string | number;

    @JsonProperty('parent_item_desc', Any, true)
    parent_item_desc?: string | number;

    @JsonProperty('order_sequence', Any, true)
    order_sequence?: string | number;

    @JsonProperty('operation_no', Any, true)
    operation_no?: string | number;

    @JsonProperty('cost_center', Any, true)
    cost_center?: string | number;

    @JsonProperty('cost_center_description', Any, true)
    cost_center_description?: string | number;

    @JsonProperty('work_center', Any, true)
    work_center?: string | number;

    @JsonProperty('work_center_description', Any, true)
    work_center_description?: string | number;

    @JsonProperty('child_item_account', Any, true)
    child_item_account?: string | number;

    @JsonProperty('child_item_account_desc', Any, true)
    child_item_account_desc?: string | number;

    @JsonProperty('child_item_desc', Any, true)
    child_item_desc?: string | number;

    @JsonProperty('movement_type', Any, true)
    movement_type?: string | number;

    @JsonProperty('movement_type_desc', Any, true)
    movement_type_desc?: string | number;

    @JsonProperty('issue_quantity', Any, true)
    issue_quantity?: string | number;

    @JsonProperty('issue_amount', Any, true)
    issue_amount?: string | number;

    @JsonProperty('use_amount', Any, true)
    use_amount?: string | number;
}
