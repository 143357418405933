import { CompanySettingData, ICompanySetting, Result } from 'models';
import { createContext, useContext } from 'react';

const voidCallback = (_?: any) => {};

export const CompanyContext = createContext<{
    company: ICompanySetting[] | null;
    companyDetail: ICompanySetting | undefined;
    updateCompany: (data: any, company_slug: string) => Promise<any>;
    isUpdate: boolean;
    setUpdate: (_?: any) => void;
}>({
    company: [],
    companyDetail: undefined,
    updateCompany: async () => {},
    isUpdate: false,
    setUpdate: voidCallback
});
export const useCompanyContext = () => useContext(CompanyContext);
