import { JsonSerializable } from '../serialization';
import { JsonObject, JsonProperty } from 'json2typescript';
import { FolderSet } from '../SideMenuData';
import _ from 'lodash';

export interface ICompanySetting {
    slug: string;
    profile_picture?: string;
    phone_number?: string;
    name: string;
    tax_code: string;
    country: string;
    address: string;
    email: string;
    contact: string;
    representative_name: string;
    representative_email: string;
    representative_contact: string;
    pic_name: string;
    pic_contact: string;
    pic_email?: string;
    folder_set?: FolderSet[];
}

@JsonObject('CompanySettingData')
export class CompanySettingData extends JsonSerializable<CompanySettingData> implements ICompanySetting {
    protected get classRef(): new () => CompanySettingData {
        return CompanySettingData;
    }

    @JsonProperty('slug', String, false)
    slug!: string;

    @JsonProperty('phone_number', String, true)
    phone_number: string = '';

    @JsonProperty('profile_picture', String, true)
    profile_picture: string = '';

    @JsonProperty('name', String, true)
    name: string = '';

    @JsonProperty('tax_code', String, true)
    tax_code: string = '';

    @JsonProperty('country', String, true)
    country: string = '';

    @JsonProperty('address', String, true)
    address: string = '';

    @JsonProperty('email', String, true)
    email: string = '';

    @JsonProperty('contact', String, true)
    contact: string = '';

    @JsonProperty('representative_name', String, true)
    representative_name: string = '';

    @JsonProperty('representative_email', String, true)
    representative_email: string = '';

    @JsonProperty('representative_contact', String, true)
    representative_contact: string = '';

    @JsonProperty('pic_name', String, true)
    pic_name: string = '';

    @JsonProperty('pic_contact', String, true)
    pic_contact: string = '';

    @JsonProperty('pic_email', String, true)
    pic_email: string = '';

    @JsonProperty('folder_set', [FolderSet], true)
    folder_set?: FolderSet[] = [];
}
