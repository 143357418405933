import {SellData, SellImportSetup, JSONType, Result, resultGuard} from 'models';
import {SellQueryParams} from '../type';
import {SellDataSource} from './SellDataSource';
import {ISellImportSetup} from './type';
import {AutoFillParams} from 'components';

export class SellRepository {
    constructor(private datasource: SellDataSource) {
    }

    public async getAll(params: SellQueryParams) {
        return this.datasource.getAll(params);
    }

    public async deleteRows(ids: string[]) {
        return this.datasource.delete(ids);
    }

    public async updateRow(row: Partial<SellData>, params: SellQueryParams) {
        return this.datasource.update(row, params);
    }

    public async insertRow(row: JSONType) {
        return this.datasource.insert(row);
    }

    public async importData(value: ISellImportSetup): Promise<Result<void>> {
        return resultGuard(async () => {
            await this.datasource.importData(value);
        });
    }

    public async setupImport(data: { [key: string]: any }) {
        return resultGuard(async () => {
            await this.datasource.setupImport(data);
        });
    }

    public async getFixedHeader(): Promise<Result<string[]>> {
        return resultGuard(() =>
            this.datasource.getFixedHeader()
        );
    }

    public async getAutoFillData(data: AutoFillParams) {
        return this.datasource.getAutoFill(data);
    }
}
