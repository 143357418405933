import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { Column, ColumnOrderState, Header, Table, flexRender } from '@tanstack/react-table';
import { useContext, useMemo, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import './$Table.scss';
import { TableContext } from './context';

const reorderColumn = (draggedColumnId: string, targetColumnId: string, columnOrder: string[]): ColumnOrderState => {
    columnOrder.splice(columnOrder.indexOf(targetColumnId), 0, columnOrder.splice(columnOrder.indexOf(draggedColumnId), 1)[0] as string);
    return [...columnOrder];
};

export const DraggableHeader = <T extends Object>({
    header,
    table,
    columnResizeMode,
    showSort,
    isFirstColumn
}: {
    header: Header<T, unknown>;
    table: Table<T>;
    columnResizeMode: string;
    showSort: boolean;
    isFirstColumn: boolean;
}) => {
    const { getState, setColumnOrder } = table;
    const { columnOrder } = getState();
    const { column } = header;
    const [hovering, setHovering] = useState(false);
    const [isClick, setIsClick] = useState(false);
    const { sortHeader, setSortHeader } = useContext(TableContext);

    const isSorted = useMemo(() => {
        return header.column.getIsSorted() as string;
    }, [header.column.getIsSorted()]);

    const [, dropRef] = useDrop({
        accept: 'column',
        drop: (draggedColumn: Column<T>) => {
            const newColumnOrder = reorderColumn(draggedColumn.id, column.id, columnOrder);
            setColumnOrder(newColumnOrder);
        }
    });

    const isResizing = useMemo(() => {
        return column.getIsResizing();
    }, [column.getIsResizing()]);

    const [{ isDragging }, dragRef, previewRef] = useDrag(
        {
            collect: (monitor) => ({
                isDragging: monitor.isDragging()
            }),
            item: () => column,
            type: 'column',
            canDrag: !isResizing
        },
        [isResizing]
    );

    return (
        <th
            onMouseEnter={(e) => {
                setHovering(true);
            }}
            onMouseLeave={(e) => {
                setHovering(false);
            }}
            key={header.id}
            className="header"
            ref={dropRef}
            colSpan={header.colSpan}
            style={{
                width: header.getSize(),
                opacity: isDragging ? 0.3 : 1,
                backgroundColor: isDragging ? 'yellow' : '#fff'
            }}
        >
            <div
                className={`tw-cursor-grab`}
                ref={previewRef}
                onClick={() => {
                    setIsClick(true);
                    setSortHeader(header.id);
                }}
            >
                <div ref={dragRef}>
                    <div>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: `${isFirstColumn === true ? `flex-start` : `flex-end`}`
                            }}
                        >
                            <span
                                className={`tw-typo-sm tw-block tw-truncate tw-text-gray1 tw-relative ${
                                    isFirstColumn ? `tw-pr-5` : `tw-pl-5 tw-pr-2`
                                }`}
                            >
                                {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                                {showSort && header.column.getCanSort() ? (
                                    <>
                                        <div
                                            className={`${
                                                hovering || (isClick && header.id === sortHeader) ? 'tw-block' : 'tw-hidden'
                                            } tw-absolute tw-top-[-7px] ${isFirstColumn ? `tw-right-[-10px]` : `tw-left-[-10px]`}`}
                                        >
                                            <IconButton
                                                size={'small'}
                                                key={header.id + '_sort_btn'}
                                                {...{
                                                    onClick: header.column.getToggleSortingHandler()
                                                }}
                                            >
                                                {{
                                                    asc: <ArrowDropDown color={'primary'} />,
                                                    desc: <ArrowDropUp color={'primary'} />
                                                }[isSorted] ?? <ArrowDropUp color={'primary'} />}
                                            </IconButton>
                                        </div>
                                    </>
                                ) : (
                                    <div />
                                )}
                            </span>
                        </div>
                    </div>
                    <div
                        onMouseDown={header.getResizeHandler()}
                        onTouchStart={header.getResizeHandler}
                        className={`resizer ${isResizing ? 'isResizing' : ''}`}
                        style={{
                            transform:
                                columnResizeMode === 'onEnd' && isResizing
                                    ? `translateX(${table.getState().columnSizingInfo.deltaOffset}px)`
                                    : ''
                        }}
                    />
                </div>
            </div>
        </th>
    );
};
