import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { Sheet } from 'components/Sheet';
import { TableType } from 'components/Table';
import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { Report15Context } from '../context';
import useStep3ViewModel from '../hooks/useStep3ViewModel';

function ModalStep3() {
    const { workBook, selectedSheet, setSelectedSheet, isSelectHeader, setIsSelectHeader, setDisableSelectHeader, isOpenModal } =
        useContext(Report15Context);
    const { onSelectHeader, availableCells, hotRef, sheetJson, mergeCells, setStartCell, setEndCell, startCell, endCell } =
        useStep3ViewModel({
            table: TableType.reportStatement
        });

    const [startCellText, setStartCellText] = useState('');
    const [endCellText, setEndCellText] = useState('');
    const [startCellTextDebounced] = useDebounce(startCellText, 500);
    const [endCellTextDebounced] = useDebounce(endCellText, 500);

    useEffect(() => {
        const range = availableCells[startCellTextDebounced];
        if (range) setStartCell([startCellTextDebounced, range]);
        else setStartCell(undefined);
    }, [startCellTextDebounced, availableCells]);

    useEffect(() => {
        const range = availableCells[endCellTextDebounced];
        if (range) setEndCell([endCellTextDebounced, range]);
        else setEndCell(undefined);
    }, [endCellTextDebounced, availableCells]);

    useEffect(() => {
        if (isSelectHeader) {
            onSelectHeader();
            setIsSelectHeader(false);
        }
    }, [isSelectHeader]);

    useEffect(() => {
        if (sheetJson) {
            setDisableSelectHeader(false);
        }
    }, [sheetJson]);

    useEffect(() => {
        if (!isOpenModal) {
            setStartCellText('');
            setEndCellText('');
            setSelectedSheet('');
        }
    }, [isOpenModal]);

    return (
        <>
            {workBook ? (
                <>
                    <div className="tw-flex tw-flex-col">
                        <FormControl sx={{ maxWidth: 320 }}>
                            <InputLabel>Sheet</InputLabel>
                            <Select<string>
                                value={selectedSheet ?? ''}
                                label="Sheet"
                                onChange={(value) => {
                                    setSelectedSheet(value.target.value);
                                }}
                            >
                                {workBook.SheetNames.map((sheetName) => (
                                    <MenuItem key={sheetName} value={sheetName}>
                                        {sheetName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <div className="tw-flex tw-flex-row tw-mt-4">
                            <TextField
                                label={'Start'}
                                value={startCellText}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setStartCellText(event.target.value.toUpperCase());
                                }}
                            ></TextField>
                            <div className="tw-w-2" />
                            <TextField
                                label={'End'}
                                value={endCellText}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setEndCellText(event.target.value.toUpperCase());
                                }}
                            ></TextField>
                        </div>
                    </div>
                    <div className="tw-h-[12px]" />
                    {sheetJson ? (
                        <div className="tw-relative tw-w-full">
                            <Sheet
                                key={_.keys(sheetJson).join('#')}
                                height={320}
                                hiddenRows
                                hiddenColumns
                                ref={hotRef}
                                outsideClickDeselects={false}
                                data={sheetJson}
                                contextMenu
                                selectionMode={'range'}
                                mergeCells={mergeCells}
                                rowHeaders={true}
                                colHeaders={true}
                                endCell={_.get(endCell, '0')}
                                startCell={_.get(startCell, '0')}
                                licenseKey="non-commercial-and-evaluation" // for non-commercial use only
                            />
                        </div>
                    ) : (
                        <></>
                    )}
                </>
            ) : (
                <></>
            )}
        </>
    );
}

export default ModalStep3;
