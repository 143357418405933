import { useCompanyFolderSlug } from 'components/BalanceImport';
import { CompanyContext } from 'contexts';
import { RowObject } from 'handsontable/common';
import { AsyncValue, ConnectionState } from 'hooks/Async';
import produce from 'immer';
import { ResultState, resultGuard } from 'models';
import { useContext, useState, useMemo, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { BaseQueryParams } from 'repository';
import { setLoading } from 'store/Global';
import { match } from 'ts-pattern';
import { removeDiacritics } from 'utils';
import { Report15Context } from '../context';
import _ from 'lodash';
import { voidCallback } from 'components/BalanceTable/TableInvoice';

const useStep4ViewModal = ({
    tableData,
    setDisableSubmitBtn,
    year
}: {
    tableData: RowObject[];
    setDisableSubmitBtn: voidCallback;
    year: string;
}) => {
    const params: BaseQueryParams = useCompanyFolderSlug();
    const { getFixedHeader, header, setupImport, setSetupSuccessful } = useContext(Report15Context);
    const [colsAsyncValue, setColsAsyncValue] = useState<
        AsyncValue<
            {
                type: string;
                source: string[];
            }[]
        >
    >({ state: ConnectionState.waiting });
    const [fixedHeader, setFixedHeader] = useState<string[]>([]);
    const dispatch = useDispatch();
    const headerTitle = useMemo(() => {
        if (!header) return [];
        return header!.map((title, index) => {
            if (index >= header.length) return '';
            return title;
        });
    }, [header]);

    const selectedHeaderMap = useMemo(() => {
        return produce<{ [key: string]: boolean }>({}, (draft) => {
            for (const value of _.flatten(tableData)) {
                if (!_.isEmpty(value)) draft[value] = true;
            }
        });
    }, [tableData]);

    const fetch = async () => {
        setColsAsyncValue({ state: ConnectionState.waiting });
        const result = await getFixedHeader();
        match(result)
            .with({ state: ResultState.success }, ({ data }) => {
                const columns = header!.map((_) => {
                    return {
                        type: 'dropdown',
                        source: data,
                        strict: false,
                        validator: false
                    };
                });
                setColsAsyncValue({ state: ConnectionState.hasData, data: columns });
                setFixedHeader(data);
            })
            .with({ state: ResultState.failed }, ({ exception }) => {
                setColsAsyncValue({ state: ConnectionState.hasError, error: exception });
            })
            .exhaustive();
    };

    useEffect(() => {
        fetch();
    }, []);

    const getPayload = useCallback(() => {
        if (!header) return {};
        //@ts-ignore
        const tuples = _.zip<string | undefined, string | undefined, string>(_.get(tableData, '0'), _.get(tableData, '1'), header);

        const result = produce<{ [key: string]: any }>({ extra_fields: {} }, (draft) => {
            for (const tuple of tuples) {
                const [key, title, mapping_col_name] = tuple;

                const entry = {
                    mapping_col_name,
                    title: title ?? mapping_col_name
                };
                if (_.isNil(key)) {
                    const key = mapping_col_name!
                        .split('|')
                        .filter((word) => !word.includes('Unnamed'))
                        .map((word: string) => removeDiacritics(word.replaceAll(' ', '_')))
                        .join('_');
                    draft.extra_fields[key] = entry;
                } else {
                    draft[key] = entry;
                }
            }
            if (_.isEmpty(draft.extra_fields)) delete draft.extra_fields;
        });
        return result;
    }, [header, tableData]);

    // const submitDisabled = useMemo(() => {
    //     return _.keys(selectedHeaderMap).length < fixedHeader?.length;
    // }, [selectedHeaderMap, fixedHeader]);

    useEffect(() => {
        const v = _.keys(selectedHeaderMap).length < fixedHeader?.length;
        if (v && year) {
            setDisableSubmitBtn(v);
        } else setDisableSubmitBtn(false);
    }, [selectedHeaderMap, fixedHeader, year]);

    const { companyDetail } = useContext(CompanyContext);

    const submit = async () => {
        const payload = getPayload();
        dispatch(setLoading(true));
        if (!companyDetail) throw Error('Company is required');
        const result = await resultGuard(() => setupImport({ ...payload, ...params }));
        match(result)
            .with(
                {
                    state: ResultState.success
                },
                () => {
                    setSetupSuccessful(true);
                }
            )
            .with(
                {
                    state: ResultState.failed
                },
                () => {
                    setSetupSuccessful(false);
                }
            )
            .exhaustive();
        dispatch(setLoading(false));
    };

    return {
        header,
        selectedHeaderMap,
        colsAsyncValue,
        headerTitle,
        fixedHeader,
        // submitDisabled,
        submit
    };
};

export default useStep4ViewModal;
