import { Http } from 'api';
import _ from 'lodash';
import { InvoiceTableData, UploadFileData } from 'models';
import { BangKeExcelData } from 'models/BangKe/BangKeExcelData';
import { InvoiceImportData } from 'models/InvoiceTable/InvoiceImportData';
import { InvoiceProductData } from 'models/InvoiceTable/InvoiceProductData';
import { PSRData } from 'models/PSR';
import { BalanceParams, BaseQueryParams, PagingQueryParams } from 'repository';
import { GetBangKeExcelParam, InvoiceProductQueryParams, InvoiceQueryParams, PsrQueryParams } from './type';
import { BangKePerformManual } from 'models/BangKe/BangKePerformManual';
import { BangKeHistoryDataRequest, BangKeHistoryDataResponse } from 'models/BangKe/BangKeHistoryData';

export class InvoiceDataSource {
    constructor(private http: Http) {}

    async getAll(params: InvoiceQueryParams) {
        const response = await this.http.instance.get('/api/fta/invoices/', { params });
        return new InvoiceTableData().fromPaging(response.data);
    }

    async getInvoiceProduct(params: InvoiceProductQueryParams) {
        const response = await this.http.instance.get('/api/fta/products/', { params });
        return new InvoiceProductData().fromPaging(response.data);
    }

    async getPSR(params: PsrQueryParams) {
        const response = await this.http.instance.get('/api/fta/psr/get_psr/', { params });
        return new PSRData().fromPaging(response.data);
    }

    async getBangKeExcel(params: GetBangKeExcelParam, data: any) {
        const response = await this.http.instance.post('/api/fta/bangke/', data, { params });
        return new BangKeExcelData().formData(response.data);
    }

    async getBangKeExcelV2(params: GetBangKeExcelParam, data: any) {
        const response = await this.http.instance.post('/api/fta/v2/bangke/', data, { params });
        return new BangKeExcelData().formData(response.data);
    }

    async getUpLoadFile() {
        const response = await this.http.instance.get('/api/fta/upload/');
        return new UploadFileData().fromPaging(response.data);
    }

    async getMergerdWord(data: any[]) {
        const form = new FormData();
        data.forEach((item) => {
            form.append('files', item);
        });

        const response = await this.http.instance.post('/api/fta/upload/merge/', form, {
            headers: { 'content-type': 'multipart/form-data' },
            responseType: 'blob'
        });

        return response;
    }

    async updateStockQuantity(data: any, params: BaseQueryParams) {
        const response = await this.http.instance.put('/api/fta/bangke/', data, { params });
        return response;
    }

    async getExcelHeader(data: Object) {
        const bodyFormData = new FormData();

        const response = await this.http.instance.post('/api/balance/get-excel-headers/', bodyFormData, {
            headers: { 'content-type': 'multipart/form-data' }
        });
        return response;
    }

    async getFixedHeader() {
        const response = await this.http.instance.get('/api/fta/co-form/get_fixed_columns/');
        if (!_.isObject(response.data)) throw Error('Response should be an object');
        return _.keys(response.data);
    }

    async setUpCOForm(data: any) {
        const response = await this.http.instance.post('/api/fta/co-form/setup/', data);
        return response;
    }

    async importCOForm(data: {}) {
        let bodyFormData = new FormData();
        Object.entries(data).map(([key, value]) => {
            if (_.isNumber(value)) {
                bodyFormData.append(key, `${value}`);
            }
            //@ts-ignore
            else bodyFormData.append(key, value);
        });

        await this.http.instance.post('/api/fta/co-form/import_data/', bodyFormData, {
            headers: { 'content-type': 'multipart/form-data' }
        });
    }

    async getCOList(params: BalanceParams) {
        const response = await this.http.instance.get('/api/fta/co-form/', { params });
        return new InvoiceImportData().fromPaging<InvoiceImportData>(response.data);
    }

    async deleteImportCOList(params: BalanceParams) {
        const response = await this.http.instance.delete('/api/fta/co-form/', { data: params });
        return response;
    }

    async getDropDownPerformManual(params: GetBangKeExcelParam & PagingQueryParams, data: any) {
        const response = await this.http.instance.post('/api/fta/bangke/perform-manual/', data, { params });
        return new BangKePerformManual().formData(response.data);
    }

    async getReCalculateBangKeExcel(params: GetBangKeExcelParam, data: any) {
        const response = await this.http.instance.post('/api/fta/bangke/re-calculate-bangke/', data, { params });
        return new BangKeExcelData().formData(response.data);
    }

    async createBangKeHistory(params: BalanceParams, data: { data: BangKeHistoryDataRequest[] }) {
        const response = await this.http.instance.post('/api/fta/bangke/create-bangke-history/', data, { params });
        return response;
    }

    async getBangKeHistory(params: BalanceParams) {
        const response = await this.http.instance.get('/api/fta/bangke/get-bangke-history/', { params });
        return new BangKeHistoryDataResponse().formData(response.data);
    }
}
