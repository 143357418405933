import { useDispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';

export enum SnackBarAlertEnum {
    error = 'error',
    warning = 'warning',
    success = 'success'
}

const useSnackBar = () => {
    const dispatch = useDispatch();

    const showSnackBar = (message: string | null, color?: SnackBarAlertEnum | null, open?: boolean) => {
        dispatch(
            openSnackbar({
                open: open ?? true,
                message: message,
                variant: 'alert',
                alert: color ? { color: color } : {},
                close: false,
                actionButton: true,
                anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                transition: 'Fade',
                autoHideDuration: 300
            })
        );
    };

    return { showSnackBar };
};

export default useSnackBar;
