import { useContext, useEffect, useMemo } from 'react';
import { Report15Context, StatementTypeEnum } from '../context';
import HotTable from '@handsontable/react';
import { registerRenderer, textRenderer } from 'handsontable/renderers';

function StatementBCQT() {
    const rowCount = 3;
    const colCount = 11;
    const { BCQT } = useContext(Report15Context);

    registerRenderer('centerTitle', (hotInstance, TD, ...rest) => {
        textRenderer(hotInstance, TD, ...rest);
        TD.style.textAlign = 'center';
    });

    const headerCellStyle = useMemo(() => {
        const headerCellStyle = [];

        for (let r = 0; r < rowCount; r++) {
            for (let c = 0; c < colCount; c++) {
                headerCellStyle.push({
                    row: r,
                    col: c,
                    renderer: 'centerTitle'
                });
            }
        }
        return headerCellStyle;
    }, []);

    return (
        <div style={{ width: '100%', height: '100%' }}>
            {BCQT && (
                <HotTable
                    data={BCQT?.data}
                    width={'auto'}
                    height={'auto'}
                    rowHeaders={true}
                    colHeaders={true}
                    contextMenu={true}
                    mergeCells={BCQT?.mergeCells}
                    autoWrapRow={true}
                    autoWrapCol={true}
                    cell={[...headerCellStyle]}
                    licenseKey="non-commercial-and-evaluation"
                />
            )}
        </div>
    );
}

export default StatementBCQT;
