import { Http } from 'api';
import { AutoFillParams } from 'components';
import _ from 'lodash';
import { JSONType } from 'models';
import { AutoFillData } from 'models/AutoFillData/AutoFillData';
import { IOtherOutputData, OtherOutputData } from 'models/OtherOutputTable';
import { BalanceNewImportDataSource } from '../BalanceImportDataSource';
import { StatementQueryParams } from '../type';
import { OtherOutputImportParams } from './type';

export class OtherOutputAccDS extends BalanceNewImportDataSource<OtherOutputImportParams> {
    path: string = 'acc-other-output';

    constructor(public http: Http) {
        super(http);
    }

    public async getAutoFill(data: AutoFillParams) {
        const respone = await this.http.instance.post('/api/new-balance/acc-other-output/autofill/', data);
        return new AutoFillData().fromPaging<AutoFillData>(respone.data);
    }

    public async getAll(params: StatementQueryParams) {
        const respone = await this.http.instance.get('/api/new-balance/acc-other-output/', {
            params
        });

        return new OtherOutputData().fromPaging<IOtherOutputData>(respone.data);
    }

    public async delete(ids: string[], params?: StatementQueryParams) {
        return this.http.instance.delete('/api/new-balance/acc-other-output/bulk-delete/', {
            data: { id_list: ids }
        });
    }

    public async update(data: Partial<IOtherOutputData>, params: StatementQueryParams) {
        return this.http.instance.put(
            `/api/new-balance/acc-other-output/${data.id}/`,
            _.omit(
                {
                    ...data,
                    ...params
                },
                'id'
            )
        );
    }

    public async insert(data: JSONType) {
        return this.http.instance.post('/api/new-balance/acc-other-output/', data);
    }
}
