import { JsonObject, JsonProperty } from 'json2typescript';
import { JsonSerializable } from '../serialization';
import { SideBarCompanyValueType, SidebarcompanyData } from './sideMenuData';

export interface ICompanyData {
    data: SideBarCompanyValueType[];
}

@JsonObject('CompanyData')
export class CompanyData extends JsonSerializable<CompanyData> {
    protected get classRef(): new () => CompanyData {
        return CompanyData;
    }

    @JsonProperty('results', [SidebarcompanyData])
    results: SidebarcompanyData[] = [];
}
