import {ColumnDef, createColumnHelper} from '@tanstack/react-table';
import {Allotment} from 'allotment';
import 'allotment/dist/style.css';
import {
    $Table,
    $TableForm,
    AutoFillParams,
    ColumnMeta,
    ColumnWidth,
    TableContext,
    TableType,
    useManipulateTable,
    useQueryTable
} from 'components/Table';
import {useEndingInvenRepoACC} from 'di';
import {ConnectionState, useAsync} from 'hooks/Async';
import _ from 'lodash';
import {EndingInventoryDataACC, PagingData} from 'models';
import {AutoFillData} from 'models/AutoFillData/AutoFillData';
import {useContext, useEffect, useMemo} from 'react';
import {EndingInventoryQueryParamsACC} from 'repository';
import styles from '../TableEcusNext/styles.module.css';
import {ItemType} from '../TableBeginNext/type';
import {useCompanyFolderSlug} from "../../BalanceImport";

const EndingInventoryMetaMap: { [key: string]: ColumnMeta } = {
    code: {
        inputType: 'text',
        enableSorting: true
    },
    name: {
        inputType: 'text'
    },
    unit: {
        inputType: 'text'
    },
    quantity: {
        inputType: 'number',
        enableSorting: true
    },
    note: {
        inputType: 'text'
    }
};
const TableFormType: TableType = TableType.endingInventoryACC;

export const TableEndingInventoryNextACC = ({material_type}: { material_type: ItemType }) => {
    // const intl = useIntl();
    const {folder_slug, company_slug} = useCompanyFolderSlug()
    const params: EndingInventoryQueryParamsACC = useMemo(() => ({
        material_type,
        folder_slug, company_slug
    }),[folder_slug,company_slug])
    const endingInventoryRepository = useEndingInvenRepoACC();
    const {
        panelVisibility,
        codeLabel,
        nameInput,
        unitInput,
        setlistAutoFillName,
        setlistAutoFillUnit
    } = useContext(TableContext);
    const {
        execute,
        tableData
    } = useAsync<PagingData<AutoFillData>, any>(() => endingInventoryRepository.getAutoFillData(autoFillParams));

    const autoFillParams: AutoFillParams = useMemo(() => {
        const result = {
            code: codeLabel ?? '',
            select: ['name', 'unit'],
            filter: {},
            company_slug: params.company_slug,
            folder_slug: params.folder_slug
        };
        if (nameInput) {
            return {
                ...result,
                filter: {name: nameInput}
            };
        } else if (unitInput) {
            return {
                ...result,
                filter: {unit: unitInput}
            };
        } else {
            return result;
        }
    }, [codeLabel, nameInput, unitInput]);

    useEffect(() => {
        if (codeLabel) {
            execute('');
        }
    }, [codeLabel, autoFillParams]);

    useEffect(() => {
        if (tableData && tableData.state === ConnectionState.hasData) {
            tableData.data.results.map((item) => {
                setlistAutoFillName(item.name);
                setlistAutoFillUnit(item.unit);
            });
        }
    }, [tableData]);

    const defaultColumns = useMemo<ColumnDef<EndingInventoryDataACC, any>[]>(() => {
        const columnHelper = createColumnHelper<EndingInventoryDataACC>();
        return _.toPairs(EndingInventoryMetaMap).map(([key, value]) =>
            // @ts-ignore
            columnHelper.accessor(key, {
                id: key,
                minSize: ColumnWidth.ssm,
                size: key === 'name' || key === 'item_name' ? ColumnWidth.xxl : ColumnWidth.sm,
                enableSorting: value.enableSorting ?? false,
                cell: (info) => <div className={`tw-pr-[8px]`}>{info.getValue() as React.ReactNode}</div>,
                header: () => <div className={styles.header}>{key}</div>
            })
        );
    }, []);

    useQueryTable({
        params,
        fetchFn: (params) => endingInventoryRepository.getAll(params)
    });
    useManipulateTable<EndingInventoryDataACC, EndingInventoryQueryParamsACC>({
        args: _.omit(params, 'material_type'),
        updateFn: (row, params) => endingInventoryRepository.updateRow(row, params),
        deleteFn: (ids) => endingInventoryRepository.deleteRows(ids),
        insertFn: (json) => endingInventoryRepository.insertRow(json),
        extractDataId: (data) => data.id
    });

    return (
        <>
            <div className="tw-relative tw-h-[886px]">
                <Allotment snap>
                    <Allotment.Pane className={styles.leftPane}>
                        <$Table<EndingInventoryDataACC>
                            enableRowSelection
                            columnsDef={defaultColumns}
                            columnsMeta={EndingInventoryMetaMap}
                            tableType={TableFormType}
                        />
                    </Allotment.Pane>
                    <Allotment.Pane visible={panelVisibility} className={styles.rightPane}>
                        <$TableForm columnsMeta={EndingInventoryMetaMap} TableFormType={TableFormType}/>
                    </Allotment.Pane>
                </Allotment>
            </div>
        </>
    );
};
