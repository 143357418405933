import TuneIcon from '@mui/icons-material/Tune';
import { Box, Button, Container, Dialog, DialogTitle, Grid, Portal } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { voidCallback } from 'components/BalanceTable';
import { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import SelectFilterAutocomplete, { API_URL, ColumnProvider } from './TestAutocomplete';
import useAuth from 'hooks/useAuth';
import { useCompanyFolderSlug } from 'components/BalanceImport/hooks';

export interface FieldSelectorMeta {
    field: string;
    label: string;
}

const REG_EXP = /^\d+$/;

export const FieldSelector = (props: {
    fieldSelectorMeta: FieldSelectorMeta[];
    data: { [key: string]: any[] } | null;
    setSelectParams: voidCallback;
    FilterLoadMore: voidCallback;
    FilterLoadPrevious: voidCallback;
    loadPrevious: boolean;
}) => {
    const { fieldSelectorMeta: fields, setSelectParams, FilterLoadMore, FilterLoadPrevious, loadPrevious } = props;
    const [open, setOpen] = useState<boolean>(false);
    const [valueMap, setValueMap] = useState<{ [key: string]: any }>(
        fields.reduce((acc, field) => {
            acc[field.field] = [];
            return acc;
        }, {} as { [key: string]: any[] })
    );

    const getQueryString = () => {
        let obj = Object.assign({}, valueMap);
        setSelectParams(obj);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={10}>
                <Box sx={{ display: 'flex', flexDirection: 'column', padding: 1 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                        <Button variant="outlined" startIcon={<TuneIcon />} onClick={() => setOpen((open) => !open)}>
                            Filter
                        </Button>
                        <Dialog open={open}>
                            <DialogTitle>Filter Options</DialogTitle>
                            <FormControl sx={{padding: 2}}>
                                <Box>
                                    {fields.map((fieldMeta) => (
                                        <SelectForm
                                            key={fieldMeta.field}
                                            field={fieldMeta.field}
                                            label={fieldMeta.label}
                                            setContext={setValueMap}
                                            FilterLoadMore={FilterLoadMore}
                                            FilterLoadPrevious={FilterLoadPrevious}
                                            loadPrevious={loadPrevious}
                                        />
                                    ))}
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: 2 }}>
                                    
                                    <Button variant="contained" onClick={() => setOpen(false)}>
                                        Close
                                    </Button>
                                    <Button variant="contained" onClick={() => getQueryString()}>
                                        Apply
                                    </Button>
                                
                                </Box>
                            </FormControl>
                        </Dialog>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={2} />
        </Grid>
    );
};

const SelectForm = (props: {
    field: string;
    label: string;
    setContext: React.Dispatch<
        React.SetStateAction<{
            [key: string]: any;
        }>
    >;
    FilterLoadMore: voidCallback;
    FilterLoadPrevious: voidCallback;
    loadPrevious: boolean;
}) => {
    const [value, setValue] = useState<string[]>([]);

    const { field, label, setContext } = props;

    const slugs = useCompanyFolderSlug();

    useEffect(() => {
        setContext((ctx) => {
            ctx[field] = value;
            return ctx;
        });
    }, [value]);

    return (
        <FormControl sx={{ m: 1, width: 300 }} size="small">
            <SelectFilterAutocomplete columnProvider={new ColumnProvider(field, API_URL, slugs, label)} value={value} setValue={setValue} />
        </FormControl>
    );
};
