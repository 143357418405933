import { AmaCancleQueryParams } from 'repository/type';
import { AmaDataSource } from './AmaDataSource';
import { AmaData, JSONType, resultGuard } from 'models';
import { IAmamImportDTO } from './type';
import { AmaSetupDTO } from 'models/AmaTable/AmaSetupDTO';

export class AmaRepository {
    constructor(private AmaDs: AmaDataSource) {}

    async getAll(params: AmaCancleQueryParams) {
        return this.AmaDs.getAll(params);
    }
    async updateRow(row: Partial<AmaData>, params: AmaData) {
        throw new Error();
    }

    async deleteRow(ids: string[], params: AmaData) {
        throw new Error();
    }
    async insertRow(json: JSONType) {
        throw new Error();
    }

    async importData(data: IAmamImportDTO) {
        return resultGuard(async () => {
            await this.AmaDs.setUp(new AmaSetupDTO().fromJSON(data));
            await this.AmaDs.importData(data);
        });
    }
}
