import { Link as RouterLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// material-ui
import { Link, Button } from '@mui/material';

// project imports
import { DASHBOARD_PATH } from 'config';
import Logo from 'ui-component/Logo';

import { activeID, activeItem, openDrawer } from 'store/slices/menu';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
    <>
        <Link
            sx={{ display: { xs: 'none', md: 'block' } }}
            component={RouterLink} to={DASHBOARD_PATH} aria-label="theme-logo">
            <Logo />
        </Link>
        <Link sx={{ display: { xs: 'block', md: 'none' } }} component={RouterLink} to={DASHBOARD_PATH} aria-label="theme-logo">
            <Logo  width={143}/>
        </Link>
    </>

);

export default LogoSection;
