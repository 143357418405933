import { Result, ResultState, resultGuard } from 'models';
import { DetailQueryParams } from 'repository/type';
import { match } from 'ts-pattern';
import { LocalAuthDataSource } from './LocalAuthDataSource';
import { RemoteAuthDataSource } from './RemoteAuthDataSource';
import { ChangePasswordProps, EmailPasswordPayload, UpdateUserProfileProps } from './type';

export class AuthRepository {
    constructor(private localAuthDS: LocalAuthDataSource, private remoteAuthDS: RemoteAuthDataSource) {}

    public isAuthenticated(): boolean {
        return this.localAuthDS.isAuthenticated;
    }

    public async login(data: EmailPasswordPayload) {
        const result = await this.remoteAuthDS.login(data);
        return new Promise((resolve, reject) => {
            match(result)
                .with({ state: ResultState.success }, ({ data: token }) => {
                    this.localAuthDS.setToken(token);
                    resolve(result);
                })
                .otherwise((_) => {
                    reject(result);
                });
        });
    }

    public async register(data: { email: string; password: string; name: string }) {
        const result = await this.remoteAuthDS.register(data);
        return result;
    }

    async verify(otp: string) {
        const result = await this.remoteAuthDS.verify(otp);
        return new Promise((resolve, reject) => {
            match(result)
                .with({ state: ResultState.success }, ({ data: token }) => {
                    this.localAuthDS.setToken(token);
                    resolve(result);
                })
                .otherwise((_) => {
                    reject(result);
                });
        });
    }

    async resendOTP(email: string) {
        const result = await this.remoteAuthDS.resendOTP(email);
        return result;
    }

    async resetPassword(email: string) {
        const result = await this.remoteAuthDS.resetPassword(email);
        return new Promise((resolve, reject) => {
            match(result)
                .with({ state: ResultState.success }, (_) => {
                    resolve(_);
                })
                .otherwise((_) => {
                    reject(_);
                });
        });
    }

    async resetPasswordVerify(otp: string) {
        const result = await this.remoteAuthDS.resetPasswordVerify(otp);
        return new Promise((resolve, reject) => {
            match(result)
                .with({ state: ResultState.success }, (_) => {
                    resolve(_);
                })
                .otherwise((_) => {
                    reject(_);
                });
        });
    }

    async resetPasswordConfirm(otp: string, password: string) {
        const result = await this.remoteAuthDS.resetPasswordConfirm(otp, password);
        return new Promise((resolve, reject) => {
            match(result)
                .with({ state: ResultState.success }, (_) => {
                    resolve(_);
                })
                .otherwise((_) => {
                    reject(_);
                });
        });
    }

    async loginWithGoogle(token: string) {
        return resultGuard(async () => {
            const response = await this.remoteAuthDS.loginWithGoogle(token);
            this.localAuthDS.setToken(response);
        });
    }

    async changePassword(data: ChangePasswordProps) {
        return resultGuard(async () => {
            const respone = await this.remoteAuthDS.changePassword(data);
            this.localAuthDS.setToken(respone);
        });
    }

    async getMe(token: string) {
        return resultGuard(async () => this.remoteAuthDS.getMe(token));
    }

    async UpdateUserProfile(userInfo: UpdateUserProfileProps) {
        return resultGuard(async () => this.remoteAuthDS.changeUserProfile(userInfo));
    }

    async getDetailPage(params: DetailQueryParams) {
        return this.remoteAuthDS.getDetailPage(params);
    }
}
