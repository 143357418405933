import { Http } from 'api';
import { ReportStatementQueryParams } from 'repository/type';
import { BalanceNewImportDataSource } from '../BalanceImportDataSource';
import { ReportStatementData } from 'models';

export class ReportStatement15ADS extends BalanceNewImportDataSource<any> {
    path: string = 'report-statement-15A';

    constructor(public http: Http) {
        super(http);
    }

    public async getAll(params: ReportStatementQueryParams) {
        const respone = await this.http.instance.get('/api/new-balance/report-statement-15A/', {
            params
        });

        // return respone.data;
        return new ReportStatementData().fromPaging<ReportStatementData>(respone.data);
    }
}
