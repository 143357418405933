import { Button } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Apiurl } from 'api';
import axios from 'axios';
import { useDebounce } from 'components/BalanceImport/hooks';
import React, { useEffect, useState } from 'react';
import { BalanceParams } from 'repository/type';

export const API_URL = `${Apiurl}api/balance/ecus/get_select_column/`;

export class ColumnProvider {
    constructor(public column: string, public apiUrl: string, public slugs: BalanceParams, public label?: string) {}

    async fetchOptions(query: string = '', offset: number = 0) {
        const response = await axios.get(this.apiUrl, {
            params: {
                company_slug: this.slugs.company_slug,
                folder_slug: this.slugs.folder_slug,
                q: query,
                offset: offset,
                limit: 20,
                column: this.column
            },
            headers: {
                Authorization: `Bearer ${window.localStorage.getItem('token')}`
            }
        });

        return response;
    }

    async fetchAll() {
        const response = await axios.get(this.apiUrl, {
            params: {
                company_slug: this.slugs.company_slug,
                folder_slug: this.slugs.folder_slug,
                column: this.column
            },
            headers: {
                Authorization: `Bearer ${window.localStorage.getItem('token')}`
            }
        });

        return response;
    }
}

const SelectFilterAutocomplete = ({
    columnProvider,
    value,
    setValue
}: {
    columnProvider: ColumnProvider;
    value: any[];
    setValue: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
    const [options, setOptions] = useState<string[]>([]);
    const [hasNext, setHasNext] = useState(false);
    const [loading, setLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const debaunchedSearchQuery = useDebounce(searchQuery, 500); // Adjust the debounce delay as needed

    const fetchOptions = async (query = '', offset = 0, isUseEffect?: boolean) => {
        setLoading(true);

        try {
            const response = await columnProvider.fetchOptions(query, offset);
            if (response.request.status === 200) {
                const data = await response.data;
                setOptions((prevOptions) => (isUseEffect ? data.results : [...prevOptions, ...data.results]));
                setHasNext(data.has_next);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (_: any, value: any, reason: string) => {
        if (reason === 'input') {
            setOptions([]);
            setSearchQuery(value);
        }
    };

    useEffect(() => {
        fetchOptions(debaunchedSearchQuery); // Use the debounced query
    }, [debaunchedSearchQuery]);

    const handleLoadMore = () => {
        fetchOptions(searchQuery, options.length);
    };

    const handleSelectAll = async () => {
        if (!hasNext && options.length === value.length) {
            setValue([]);
            return;
        }
        try {
            setLoading(true);
            const response = await columnProvider.fetchAll();
            if (response.request.status === 200) {
                const data = await response.data;
                setOptions(data.results);
                setValue(data.results);
                console.log(value);
                setHasNext(data.has_next);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchOptions(undefined, undefined, true);
    }, []); // Fetch initial options

    return (
        <Autocomplete
            multiple
            id="autocomplete"
            options={options}
            loading={loading}
            inputValue={searchQuery}
            value={value}
            onInputChange={handleInputChange}
            onChange={(event, newValue, reason) => {
                setValue(newValue);
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={columnProvider.label ? columnProvider.label : columnProvider.column}
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    {hasNext && (
                                        <Button onClick={handleLoadMore} disabled={loading}>
                                            {loading ? 'Loading...' : 'Load More'}
                                        </Button>
                                    )}
                                    <Button onClick={handleSelectAll} disabled={loading}>
                                        select all
                                    </Button>
                                </div>
                            </>
                        )
                    }}
                />
            )}
            renderOption={(props, option, { selected }) => (
                <li {...props}>
                    <span style={{ marginRight: 8 }}>{selected ? '✓' : '○'}</span>
                    {option}
                </li>
            )}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <span {...getTagProps({ index })} key={index}>
                        {option}
                    </span>
                ))
            }
        />
    );
};

export default SelectFilterAutocomplete;
