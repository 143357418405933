import { lazy } from 'react';

// project imports
import GuestGuard from 'utils/route-guard/GuestGuard';
import MinimalLayout from 'layout/MinimalLayout';
import NavMotion from 'layout/NavMotion';
import Loadable from 'ui-component/Loadable';
import { verificationType } from 'views/pages/authentication/CodeVerification';
import { GoogleOAuthProvider } from '@react-oauth/google';

// login routing
const AuthLogin = Loadable(lazy(() => import('views/pages/authentication/Login')));
const AuthRegister = Loadable(lazy(() => import('views/pages/authentication/Register')));
const AuthForgotPassword = Loadable(lazy(() => import('views/pages/authentication/ForgotPassword')));
const AuthResetPassword = Loadable(lazy(() => import('views/pages/authentication/ResetPassword')));
const AuthCodeVerification = Loadable(lazy(() => import('views/pages/authentication/CodeVerification')));
const AuthCheckMail = Loadable(lazy(() => import('views/pages/authentication/CheckMail')));

// ==============================|| AUTH ROUTING ||============================== //

const GOOGLE_CLIENT_ID = '531140989268-rc2r8d0jnrj68qu24gd9d0sugeioiqvl.apps.googleusercontent.com';

const LoginRoutes = {
    path: '/',
    element: (
        <NavMotion>
            <GuestGuard>
                <MinimalLayout />
            </GuestGuard>
        </NavMotion>
    ),
    children: [
        {
            path: '/',
            element: <AuthLogin />
        },
        {
            path: '/login',
            element: (
                <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
                    <AuthLogin />
                </GoogleOAuthProvider>
            )
        },
        {
            path: '/register',
            element: <AuthRegister />
        },
        {
            path: '/forgot',
            element: <AuthForgotPassword />
        },
        {
            path: '/reset-password',
            element: <AuthResetPassword />
        },
        {
            path: '/verify',
            element: <AuthCodeVerification type={verificationType.register} />
        },
        {
            path: '/forgot/verify',
            element: <AuthCodeVerification type={verificationType.forgot} />
        },
        {
            path: '/check-mail',
            element: <AuthCheckMail />
        }
    ]
};

export default LoginRoutes;
