import {ColumnDef, createColumnHelper} from '@tanstack/react-table';
import {Allotment} from 'allotment';
import 'allotment/dist/style.css';
import {$Table, TableContext, useCompanyFolderSlug, useManipulateTable, useQueryTable} from 'components';
import useSnackBar, {SnackBarAlertEnum} from 'components/SnackBar/UISnackbar';
import {$TableForm} from 'components/Table/TableForm/$TableForm';
import {TableType} from 'components/Table/TableForm/Type';
import {AutoFillParams, ColumnMeta, ColumnWidth} from 'components/Table/type';
import {useBomExwRepo} from 'di';
import {ConnectionState, useAsync} from 'hooks/Async';
import _ from 'lodash';
import {BomData, PagingData} from 'models';
import {AutoFillData} from 'models/AutoFillData/AutoFillData';
import {useContext, useEffect, useMemo} from 'react';
import {BomQueryParams} from 'repository/type';
import styles from '../TableEcusNext/styles.module.css';
import {BomExwItemType} from "./type";

const BomColumnMetaMap: { [key: string]: ColumnMeta } = {
    code: {
        inputType: 'text'
    },
    name: {
        inputType: 'text'
    },
    unit: {
        inputType: 'text'
    },
    material_name: {
        inputType: 'text'
    },
    material_code: {
        inputType: 'text'
    },
    material_unit: {
        inputType: 'text'
    },
    bom_value: {
        inputType: 'number'
    },
    note: {
        inputType: 'text'
    },
    contract: {
        inputType: 'text'
    },
    year: {
        inputType: 'number',
        enableSorting: true
    }
};
const TableFormType: TableType = TableType.bom;

export const TableBomExwNext = ({product_type}: { product_type: BomExwItemType }) => {
    const {showSnackBar} = useSnackBar();
    const {company_slug, folder_slug} = useCompanyFolderSlug()
    const params: BomQueryParams = useMemo(() => ({
        product_type, company_slug, folder_slug
    }),[company_slug, folder_slug])
    const bomRepository = useBomExwRepo();
    const {
        panelVisibility,
        codeLabel,
        nameInput,
        unitInput,
        exportExcel,
        fixBom,
        setlistAutoFillName,
        setlistAutoFillUnit,
        setExportExcel,
        setFixBom
    } = useContext(TableContext);
    const {
        execute,
        tableData
    } = useAsync<PagingData<AutoFillData>, any>(() => bomRepository.getAutoFillData(autoFillParams));
    const {execute: executeFixBom, tableData: fixBomData} = useAsync<any, any>(() =>
        bomRepository.fixBom({year: 2023, company_slug: params.company_slug, folder_slug: params.folder_slug})
    );

    const {execute: excelExecute, tableData: excelData} = useAsync<any, any>(() =>
        bomRepository.exportExcel({
            company_slug: params.company_slug,
            folder_slug: params.folder_slug,
            year: 2023,
            get_excel: true
        })
    );

    useEffect(() => {
        if (fixBom) {
            executeFixBom('');
            setFixBom(false);
        }
    }, [fixBom]);

    useEffect(() => {
        if (fixBomData.state === ConnectionState.hasData) {
            showSnackBar(fixBomData.data.data.Message, SnackBarAlertEnum.success);
        }
    }, [fixBomData]);

    useEffect(() => {
        if (exportExcel) {
            excelExecute('');
        }
        setExportExcel(false);
    }, [exportExcel]);

    const autoFillParams: AutoFillParams = useMemo(() => {
        const result = {
            code: codeLabel ?? '',
            select: ['name', 'unit'],
            filter: {},
            company_slug: params.company_slug,
            folder_slug: params.folder_slug
        };
        if (nameInput) {
            return {
                ...result,
                filter: {name: nameInput}
            };
        } else if (unitInput) {
            return {
                ...result,
                filter: {unit: unitInput}
            };
        } else {
            return result;
        }
    }, [codeLabel, nameInput, unitInput]);

    useEffect(() => {
        if (codeLabel) {
            execute('');
        }
    }, [codeLabel, autoFillParams]);

    useEffect(() => {
        if (tableData && tableData.state === ConnectionState.hasData) {
            tableData.data.results.map((item) => {
                setlistAutoFillName(item.name);
                setlistAutoFillUnit(item.unit);
            });
        }
    }, [tableData]);

    const defaultColumns = useMemo<ColumnDef<BomData, any>[]>(() => {
        const columnHelper = createColumnHelper<BomData>();
        return _.toPairs(BomColumnMetaMap).map(([key, value]) =>
            // @ts-ignore
            columnHelper.accessor(key, {
                id: key,
                minSize: ColumnWidth.ssm,
                size: key === 'name' || key === 'item_name' ? ColumnWidth.xxl : ColumnWidth.sm,
                enableSorting: value.enableSorting ?? false,
                cell: (info) => <div className={`tw-pr-[8px]`}>{info.getValue() as React.ReactNode}</div>,
                header: () => <div className={styles.header}>{key}</div>
            })
        );
    }, []);

    useQueryTable({
        params,
        fetchFn: (params) => bomRepository.getAll(params)
    });
    useManipulateTable<BomData, BomQueryParams>({
        args: _.omit(params, 'item_type'),
        updateFn: (row, params) => bomRepository.updateRow(row, params),
        deleteFn: (ids) => bomRepository.deleteRows(ids),
        insertFn: (json) => bomRepository.insertRow(json),
        extractDataId: (data) => data.id
    });

    return (
        <>
            <div className="tw-relative tw-h-[886px]">
                <Allotment snap>
                    <Allotment.Pane className={styles.leftPane}>
                        <$Table<BomData> enableRowSelection columnsDef={defaultColumns} columnsMeta={BomColumnMetaMap}/>
                    </Allotment.Pane>
                    <Allotment.Pane visible={panelVisibility} className={styles.rightPane}>
                        <$TableForm columnsMeta={BomColumnMetaMap} TableFormType={TableFormType}/>
                    </Allotment.Pane>
                </Allotment>
            </div>
        </>
    );
};
