import useSnackBar, {SnackBarAlertEnum} from 'components/SnackBar/UISnackbar';
import {useCompanyRepo} from 'di';
import useAuth from 'hooks/useAuth';
import {ICompanySetting, ResultState} from 'models';
import {ReactNode, useCallback, useEffect, useState} from 'react';
import {match} from 'ts-pattern';
import {CompanyContext} from './CompanyContext';
import {isEmpty} from "lodash";
import {useDispatch} from "react-redux";
import {useSelector} from "../../store/reducer";
import {setCompanyDetail} from "../../store/slices/company";

export const CompanyProvider = ({children}: { children: ReactNode }) => {
    const [company, setCompany] = useState<ICompanySetting[] | null>(null);

    const [isUpdate, setUpdate] = useState(false);
    const {user} = useAuth();
    const {showSnackBar} = useSnackBar();
    const companyRepo = useCompanyRepo();

    const dispatch = useDispatch()
    const companyDetail = useSelector(state => state.company.detail)

    const getCompany = useCallback(async () => {
        if (!isEmpty(company)) return
        const reponse = await companyRepo.getCompany();
        setCompany(reponse.results);
    }, [company])

    const getCompanyDetail = useCallback(async (company_slug: string) => {
        if (!isEmpty(companyDetail)) return
        const response = await companyRepo.getCompanyDetail(company_slug);
        match(response)
            .with({state: ResultState.success}, ({data}) => {
                dispatch(setCompanyDetail(data.toJSON()));
                if (data && data.folder_set) {
                    localStorage.setItem('company_slug', data.slug);
                    localStorage.setItem('folder_slug', data.folder_set[0].slug);
                }
            })
            .with({state: ResultState.failed}, ({exception}) => {
                showSnackBar(exception.meaning, SnackBarAlertEnum.error);
            })
            .exhaustive();
    }, [companyDetail])

    const updateCompany = async (data: any, company_slug: string) => {
        const response = await companyRepo.updateCompany(data, company_slug);

        match(response)
            .with({state: ResultState.success}, ({data}) => {
                setUpdate(true);
            })
            .otherwise(() => {
            });

        return response;
    };

    useEffect(() => {
        if (user || (user && isUpdate)) {
            getCompany();
            getCompanyDetail(user.company_slug);
            setUpdate(false);
        } else if (!user) {
            setCompany(null)
            dispatch(setCompanyDetail(null))
            localStorage.removeItem('company_slug');
            localStorage.removeItem('folder_slug');
        }
    }, [user, isUpdate]);

    return (
        <CompanyContext.Provider
            value={{
                company,
                companyDetail,
                updateCompany,
                isUpdate,
                setUpdate
            }}
        >
            {children}
        </CompanyContext.Provider>
    );
};
