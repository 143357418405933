import { JsonSerializable, PagingData } from '../serialization';
import { JsonObject, JsonProperty } from 'json2typescript';
export interface IOutputData {
    id: string;
    code: string;
    name?: string;
    unit: string;
    takeout_date?: string;
    quantity: number;
}

@JsonObject('OutputData')
export class OutputData extends JsonSerializable<OutputData> implements IOutputData {
    protected get classRef(): new () => OutputData {
        return OutputData;
    }

    static empty(): OutputData {
        return new OutputData().fromJSON({
            id: new Date().getMilliseconds(),
            item_type: '',
            code: '',
            name: '',
            unit: '',
            takeout_date: 0,
            quantity: 0
        });
    }

    @JsonProperty('id', String, false)
    id!: string;

    @JsonProperty('code', String, false)
    code!: string;

    @JsonProperty('name', String, true)
    name?: string;

    @JsonProperty('unit', String, false)
    unit!: string;

    @JsonProperty('takeout_date', String, true)
    takeout_date?: string;

    @JsonProperty('quantity', Number, false)
    quantity!: number;
}
