import { JsonObject, JsonProperty } from 'json2typescript';
import { JsonSerializable } from 'models/serialization';

export interface IAmaCancleData {
    id: number;
    keep_posting: boolean;
    date: string;
    imported_by: string;
    from_file: string;
    name: string;
    import_type: string;
    status: string;
    traceback: string;
    folder: number;
}

@JsonObject('AmaCancleData')
export class AmaCancleData extends JsonSerializable<AmaCancleData> implements IAmaCancleData {
    protected get classRef(): new () => AmaCancleData {
        return AmaCancleData;
    }

    @JsonProperty('id', Number, false)
    id!: number;

    @JsonProperty('keep_posting', Boolean, false)
    keep_posting!: boolean;

    @JsonProperty('date', String, false)
    date!: string;

    @JsonProperty('imported_by', String, false)
    imported_by!: string;

    @JsonProperty('from_file', String, true)
    from_file: string = '';

    @JsonProperty('name', String, true)
    name: string = '';

    @JsonProperty('import_type', String, false)
    import_type!: string;

    @JsonProperty('status', String, false)
    status!: string;

    @JsonProperty('traceback', String, true)
    traceback: string = '';

    @JsonProperty('folder', Number, false)
    folder!: number;
}
