import DeleteIcon from '@mui/icons-material/Delete';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import RefreshOutlined from '@mui/icons-material/RefreshOutlined';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { Box, Modal } from '@mui/material';
import { $TablePaging, DeleteAllTableEnum, TableContext, TableProvider } from 'components';
import { OutlinedButton } from 'components/Button/Button';
import { MapCodeData } from 'models';
import { ToolTip } from 'ui-component/tooltip/tooltip';
import { TableMapCodeNext, TableMapCodeNextImport } from '.';
import { ItemType } from '../TableBeginNext';

function TableMapCode() {
    return (
        <TableProvider<MapCodeData, ItemType>>
            <div className="tw-flex tw-flex-col tw-w-full">
                <TableContext.Consumer>
                    {({ refresh, deleteRows, showDelAll, openDeleteAll }) => {
                        return (
                            <div className="tw-mb-[12px] tw-flex tw-flex-col">
                                {showDelAll && (
                                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                        <Modal open={showDelAll}>
                                            <Box
                                                sx={{
                                                    position: 'absolute' as 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    transform: 'translate(-50%, -50%)',
                                                    width: '20%',
                                                    height: '20%',
                                                    bgcolor: 'whitesmoke',
                                                    borderRadius: 2,
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    paddingX: 4,
                                                    paddingY: 4
                                                }}
                                            >
                                                <h3>Do you want to delete all?</h3>
                                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                                    <OutlinedButton
                                                        onClick={() => {
                                                            openDeleteAll(false);
                                                        }}
                                                    >
                                                        Cancle
                                                    </OutlinedButton>
                                                    <OutlinedButton
                                                        onClick={() => {
                                                            openDeleteAll(true, DeleteAllTableEnum.mapCode);
                                                        }}
                                                    >
                                                        Confirm
                                                    </OutlinedButton>
                                                </Box>
                                            </Box>
                                        </Modal>
                                    </Box>
                                )}
                                <div className="tw-flex tw-justify-center tw-items-center tw-px-2 tw-gap-1">
                                    <div className="tw-flex-1 tw-flex tw-items-center tw-justify-end">
                                        <ToolTip title="Refresh">
                                            <OutlinedButton
                                                onClick={() => {
                                                    refresh();
                                                }}
                                            >
                                                <RefreshOutlined />
                                            </OutlinedButton>
                                        </ToolTip>

                                        <TableMapCodeNextImport
                                            render={(onClick) => {
                                                return (
                                                    <ToolTip title="Import">
                                                        <OutlinedButton onClick={onClick}>
                                                            <FileUploadIcon />
                                                        </OutlinedButton>
                                                    </ToolTip>
                                                );
                                            }}
                                        />

                                        <ToolTip title="Delete">
                                            <OutlinedButton
                                                onClick={() => {
                                                    deleteRows();
                                                }}
                                            >
                                                <DeleteIcon />
                                            </OutlinedButton>
                                        </ToolTip>

                                        <ToolTip title="Delete all">
                                            <OutlinedButton
                                                onClick={() => {
                                                    openDeleteAll(true);
                                                }}
                                            >
                                                <RemoveCircleIcon />
                                            </OutlinedButton>
                                        </ToolTip>
                                    </div>
                                </div>
                            </div>
                        );
                    }}
                </TableContext.Consumer>
                <Box className="">
                    <TableMapCodeNext />
                </Box>
                <$TablePaging />
            </div>
        </TableProvider>
    );
}

export default TableMapCode;
