import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { FormTypeEnum } from 'components/BalanceTable/TableInvoice/InvoiceProvider';
import useSnackBar from 'components/SnackBar/UISnackbar';
import { ReactElement, useContext, useMemo } from 'react';
import { InvoiceWordContext } from '../../InvoiceWordProvider';
import { InvoiceSheetModalProviderContext } from '../InvoiceSheetModalProvider';

export default function InvoiceSheetStep() {
    const { wordFormType } = useContext(InvoiceWordContext);

    const steps = useMemo(() => {
        switch (wordFormType) {
            case FormTypeEnum.VJ || FormTypeEnum.AJ:
                return ['Step 1', 'Step 2', 'Step 3', 'Step 4', 'Step 5', 'Step 6'];
            case FormTypeEnum.AC:
                return ['Step 1', 'Step 2', 'Step 3', 'Step 4', 'Step 5'];
            case FormTypeEnum.AI:
                return ['Step 1', 'Step 2', 'Step 3', 'Step 4', 'Step 5'];
            default:
                return ['Step 1', 'Step 2', 'Step 3', 'Step 4', 'Step 5'];
        }
    }, [wordFormType]);
    const { workBook, sheetActiveStep } = useContext(InvoiceSheetModalProviderContext);
    const { showSnackBar } = useSnackBar();
    // const {
    //     setWorkBook,
    //     selectedSheet,
    //     endRow,
    //     setEndRow,
    //     coList,
    //     setCOList,
    //     setSelectedSheet,
    //     setMergeFile1,
    //     setMergeFile2,
    //     setMergeFile3,
    //     revertData,
    //     setRevertData
    // } = useContext(InvoiceSheetModalProviderContext);

    // const handleNext = useCallback(() => {
    //     setActiveStep((prevActiveStep: any) => prevActiveStep + 1);
    // }, [sheetActiveStep]);

    // function handleBackAC(sheetActiveStep: number) {
    //     if (sheetActiveStep === 0) {
    //     } else if (sheetActiveStep === 1) {
    //         setFieldState(null);
    //         setWordFormType(null);
    //     } else if (sheetActiveStep === 2) {
    //         setWorkBook(null);
    //         setSelectedSheet(null);
    //     } else if (sheetActiveStep === 3) {
    //         setSelectedSheet(null);
    //         setEndRow(null);
    //     } else if (sheetActiveStep === 4) {
    //         setCOList(null);
    //         setMergeFile1(null);
    //         setMergeFile2(null);
    //         setMergeFile3(null);
    //         setSubmitStep2(false);
    //     }
    // }

    // function handleBackVJ(sheetActiveStep: number) {
    //     if (sheetActiveStep === 0) {
    //     } else if (sheetActiveStep === 1) {
    //         setFieldState(null);
    //         setWordFormType(null);
    //     } else if (sheetActiveStep === 2) {
    //         setWorkBook(null);
    //         setSelectedSheet(null);
    //     } else if (sheetActiveStep === 3) {
    //         setSelectedSheet(null);
    //         setEndRow(null);
    //     } else if (sheetActiveStep === 4) {
    //         setCOList(null);
    //         setMergeFile1(null);
    //         setMergeFile2(null);
    //         setMergeFile3(null);
    //         setSubmitStep2(false);
    //     } else if (sheetActiveStep === 5) {
    //         setRevertData(null);
    //     }
    // }

    // const handleBack = useCallback(() => {
    //     switch (wordFormType) {
    //         case FormTypeEnum.VJ || FormTypeEnum.AJ:
    //             handleBackVJ(sheetActiveStep);
    //             return;
    //         case FormTypeEnum.AC:
    //             handleBackAC(sheetActiveStep);
    //             return;
    //     }
    //     setActiveStep((prevActiveStep: any) => prevActiveStep - 1);
    // }, [sheetActiveStep, wordFormType]);

    // const handleReset = () => {
    //     setActiveStep(0);
    // };

    // const stepDisable = useMemo(() => {
    //     if (sheetActiveStep === 0 && fieldState && wordFormType) return false;
    //     if (sheetActiveStep === 1 && workBook) return false;
    //     if (sheetActiveStep === 2 && selectedSheet && endRow) return false;
    //     if (sheetActiveStep === 3 && coList) return false;
    //     if (sheetActiveStep === 4 && revertData) return false;
    //     return true;
    // }, [sheetActiveStep, fieldState, wordFormType, workBook, selectedSheet, endRow, coList, revertData]);

    // function stepBackDisableFormVJ(sheetActiveStep: number, submitStep2: boolean) {
    //     if (sheetActiveStep === 0) {
    //         return true;
    //     } else if (sheetActiveStep === 5 && !submitStep2) {
    //         return true;
    //     }
    //     return false;
    // }

    // function stepBackDisableFormAC(sheetActiveStep: number, submitStep2: boolean) {
    //     if (sheetActiveStep === 0) {
    //         return true;
    //     } else if (sheetActiveStep === 4 && !submitStep2) {
    //         return true;
    //     }
    //     return false;
    // }

    // const stepBackDisable = useMemo(() => {
    //     switch (wordFormType) {
    //         case FormTypeEnum.VJ || FormTypeEnum.AJ:
    //             return stepBackDisableFormVJ(sheetActiveStep, submitStep2);
    //         case FormTypeEnum.AC:
    //             return stepBackDisableFormAC(sheetActiveStep, submitStep2);
    //         default:
    //             return false;
    //     }
    // }, [sheetActiveStep, submitStep2, wordFormType]);

    // useEffect(() => {
    //     setSheetActiveStep(activeStep);
    // }, [activeStep]);

    // useEffect(() => {
    //     if (workBook) {
    //         showSnackBar('Import Success!', SnackBarAlertEnum.success);
    //     }
    // }, [workBook]);

    return (
        <Box sx={{ width: '80%' }}>
            <Stepper activeStep={sheetActiveStep} sx={{ my: '12px' }}>
                {steps.map((label, index) => {
                    const stepProps: { completed?: boolean } = {};
                    const labelProps: {
                        optional?: ReactElement;
                    } = {};

                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
        </Box>
    );
}
