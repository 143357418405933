import { resultGuard } from 'models';
import { BangKeHistoryDataRequest } from 'models/BangKe/BangKeHistoryData';
import { BalanceParams, BaseQueryParams, PagingQueryParams } from 'repository';
import { InvoiceDataSource } from './InvoiceDataSource';
import { GetBangKeExcelParam, InvoiceProductQueryParams, InvoiceQueryParams, PsrQueryParams } from './type';

export class InvoiceRepository {
    constructor(private dataSource: InvoiceDataSource) {}

    async getAll(params: InvoiceQueryParams) {
        return resultGuard(async () => await this.dataSource.getAll(params));
    }

    async getInvoiceProduct(params: InvoiceProductQueryParams) {
        return resultGuard(async () => await this.dataSource.getInvoiceProduct(params));
    }

    async getPSR(params: PsrQueryParams) {
        return resultGuard(async () => await this.dataSource.getPSR(params));
    }

    async getBangKeExcel(params: GetBangKeExcelParam, data: any) {
        return resultGuard(async () => await this.dataSource.getBangKeExcel(params, data));
    }

    async getBangKeExcelV2(params: GetBangKeExcelParam, data: any) {
        return resultGuard(async () => await this.dataSource.getBangKeExcelV2(params, data));
    }

    async getUploadFile() {
        return resultGuard(async () => await this.dataSource.getUpLoadFile());
    }

    async getMergerdWord(data: Blob[]) {
        return resultGuard(async () => await this.dataSource.getMergerdWord(data));
    }

    async updateStockQuantity(data: any, params: BaseQueryParams) {
        return resultGuard(async () => await this.dataSource.updateStockQuantity(data, params));
    }

    async getExcelHeader(data: Object) {
        return resultGuard(async () => await this.dataSource.getExcelHeader(data));
    }

    async getFixedHeader() {
        return resultGuard(async () => await this.dataSource.getFixedHeader());
    }

    public async setUpCOForm(data: { [key: string]: any }) {
        return resultGuard(async () => {
            await this.dataSource.setUpCOForm(data);
        });
    }

    async importCOForm(data: Object) {
        return resultGuard(async () => await this.dataSource.importCOForm(data));
    }

    async getCOList(params: BalanceParams) {
        return resultGuard(async () => await this.dataSource.getCOList(params));
    }

    async deleteImportCOList(params: BalanceParams) {
        return resultGuard(async () => await this.dataSource.deleteImportCOList(params));
    }

    async getDropDownPerformManual(params: GetBangKeExcelParam & PagingQueryParams, data: any) {
        return resultGuard(async () => await this.dataSource.getDropDownPerformManual(params, data));
    }

    async getReCalculateBangKeExcel(params: GetBangKeExcelParam, data: any) {
        return resultGuard(async () => await this.dataSource.getReCalculateBangKeExcel(params, data));
    }

    async createBangKeHistory(params: BalanceParams, data: { data: BangKeHistoryDataRequest[] }) {
        return resultGuard(async () => await this.dataSource.createBangKeHistory(params, data));
    }

    async getBangKeHistory(params: BalanceParams) {
        return resultGuard(async () => await this.dataSource.getBangKeHistory(params));
    }
}
