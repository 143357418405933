// project import
// import other from './other';
import homelistpages from './homelistpage';

// types
import { NavItemType } from 'types';

// ==============================|| MENU ITEMS ||============================== //

const homeMenuItems: { items: NavItemType[] } = {
    items: [homelistpages]
};

export default homeMenuItems;
