import React, { useState, useEffect  } from 'react';
import { Box, Typography } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import './SearchHS.css';
import { memo } from 'react';
import { useHsContext } from 'contexts';
import useConfig from 'hooks/useConfig';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setSelectedHs, setTitilePage } from 'store/slices/HsCode';
import { getProduct, getNestedHs } from 'api/HS/HsApi';
import { styled } from '@mui/material/styles';


function cleanString(input) {
    return input.replace(/^-+|-+$/g, '')
                .replace(/[-\s]+/g, ' ') 
                .trim(); 
  }

const formatHs = (hs, colors) => {
    // Replace "_x000D_" with a space
    const normalizedHs = hs.replace(/_x000D_/g, 'A');
    
    // Remove all characters except digits and "-"
    const cleanedHs = normalizedHs.replace(/[^0-9A]/g, '').split('A')[0];
    const icons = [];
    
    // Determine chunks
    const chunks = [];
    for (let i = 0; i < cleanedHs.length; i += 2) {
        chunks.push(cleanedHs.slice(i, i + 2));
    }

    // If length > 8 (more than 4 chunks), merge extra characters into a single chunk
    if (chunks.length > 4) {
        const remaining = chunks.slice(4).join(''); // Combine extra chunks
        chunks.splice(4, chunks.length - 4, remaining); // Replace extras with a single chunk
    }

    // Generate icons for all chunks
    chunks.forEach((chunk, i) => {
        icons.push(
            <Box
                key={chunk}
                sx={{
                    display: 'inline-block',
                    width: '24px',
                    height: '24px',
                    backgroundColor: colors[i % colors.length], // Cycle through colors
                    color: i > 1 ? 'black' : 'white',
                    textAlign: 'center',
                    lineHeight: '24px',
                    marginRight: '4px',
                }}
            >
                {chunk}
            </Box>
        );
    });

    return icons;
};

// Function to format the description with highlighting
const formatDescription = (description, highlighting) => {
    if (!highlighting) return description; // If no highlighting, return the original description

    // Replace <b> with <strong> and </b> with </strong>
    const formattedDescription = description
        .replace(/<b>/g, '<strong>')
        .replace(/<\/b>/g, '</strong>');

    const highlightTerms = formattedDescription.split(/(<strong>.*?<\/strong>)/g); // Split by highlighted parts
    
    return highlightTerms.map((part, index) => {
        // Check if the part is a highlighted term
        if (part.startsWith("<strong>") && part.endsWith("</strong>")) {
            return (
                <strong key={index} style={{ backgroundColor: '#ffff00' }}> {/* Example highlight color */}
                    {part.replace(/<\/?strong>/g, '')}
                </strong>
            );
        }
        
        return part; // Return non-highlighted part as is
    });
};

const ItemList= ({onNavigateToDetail})  => {
    const { SearchResult, isProductSearch } = useHsContext();
    const { locale: lang } = useConfig();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const itemsPerPage = 8;
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedItem, setSelectedItem] = useState(null); // State to track the selected item
    const { Year, SupHeader, isSearch } = useHsContext();
    
    let data = [];
    let description = lang !== 'vi' ? `${lang}_description` : 'description';
    let keySearch = "";


    
    // Populate data based on SearchResult
    if (SearchResult) {
        if (SearchResult?.highlighting) {
            // Lặp qua các docs để giữ nguyên thứ tự
            SearchResult.response.docs.forEach((doc) => {
                
                const key = doc.id;
                let item = {
                    Code: {
                        id: key,
                        hs: doc.hs,
                    },
                    Description: {
                        description: Object.keys(SearchResult.highlighting[key] || {}).length > 0 ?
                                    SearchResult.highlighting[key][`${lang}_description`] || SearchResult.highlighting[key].description ||  SearchResult.highlighting[key].product_name
                                    : doc[`${lang}_description`] || doc.description || doc.product_name,

                        id: key,
                        hs: doc.hs,
                        uq: doc[`${lang}_unit_of_quantity`] || doc.unit_of_quantity,
                    },
                    key: key,
                    _nest_parent_: doc._nest_parent_,
                    doc_no: doc.doc_no || '',
                    doc_date: doc.doc_date || ''
                };
                // console.log(doc.hs)
                // console.log(SearchResult.highlighting[key])
                data.push(item);
            });
        } else {
            data = SearchResult.response.docs.map((doc) => ({
                Code: {
                    id: doc.id,
                    hs: doc.hs,
                    
                    
                },
                Description: {
                    description: doc[`${lang}_description`] || doc.description || doc[`product_name`],
                    id: doc.id,
                    hs: doc.hs,
                    uq: doc[`${lang}_unit_of_quantity`] || doc.unit_of_quantity,
                },
                key: doc.id,
                _nest_parent_: doc._nest_parent_,
                doc_no: doc.doc_no || '',
                doc_date: doc.doc_date || ''
            }));
        }
    }


    useEffect(() => {
        if (SearchResult && SearchResult.response.docs.length > 0) {
            setCurrentPage(1);
            console.log(SearchResult.response.docs[0], 'SearchResult.response.docs[0]');
            setSelectedItem(SearchResult.response.docs[0].id || null);
        }
    }, [SearchResult]);
    

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const handleItemClick = (hs, index) => {
        setSelectedItem(index);
        let prefixURL = isProductSearch ? '/classification/' : '/';
        if (String(hs).length > 5) {
            const fetchProduct = async () => {
                try {
                    const productResponse = await getProduct(Year, String(hs)); 
                    const productData = productResponse.response.docs[0];
    
                    if (isProductSearch) {
                        const product = SearchResult.response.docs.find((doc) => doc.id === index);
                        dispatch(setSelectedHs(product));
                    } 
    
                    if (productData && productData.hs) {
                        if (isProductSearch) {
                            const product = SearchResult.response.docs.find((doc) => doc.id === index);
                            dispatch(setSelectedHs(product));
                        } else {
                            dispatch(setSelectedHs(productData));
                        }
                        dispatch(
                            setTitilePage(
                                `${lang === 'vi' ? 'HS code ' : 'HS code '} ${productData.hs}: ${cleanString(
                                    (productData[`${lang}_description`] || productData.description).toLowerCase()
                                )}`
                            )
                        );
    
                        if (hs.length > 5) {
                            navigate(prefixURL + hs.split('.')[0], { state: { navigateToHeadingDetail: true } });
                            onNavigateToDetail();
                        } else {
                            navigate(`${prefixURL}${hs}`, { state: { navigateToHeadingDetail: true } });
                            onNavigateToDetail();
                        }
    
                    } else {
                        console.error("Product data is missing 'hs' property or docs is empty."); 
                        onNavigateToDetail();
                    }
                } catch (error) {
                    console.error("Error fetching product:", error); 
                    onNavigateToDetail();
                }
            };
        
            fetchProduct(); 
        } else {
            if (String(hs).length > 2) {
                const filteredData = SupHeader.filter(item => item.hs === hs)[0];
                dispatch(setTitilePage(`${lang === 'vi' ? 'Nhóm ' : 'Heading '} ${filteredData.hs}: ${cleanString((filteredData[`${lang}_description`] || filteredData.description).toLowerCase())}`));
    
                if (hs.length > 5) {
                    navigate(prefixURL + hs.split('.')[0], { state: { navigateToHeadingDetail: true } });
                    onNavigateToDetail();
                } else {
                    navigate(`${prefixURL}${hs}`, { state: { navigateToHeadingDetail: true } });
                    onNavigateToDetail();
                }
            } else {
                navigate(`${prefixURL}${hs}`, { state: { navigateToHeadingDetail: true } });
                onNavigateToDetail();
            }
        } 
    };
    

    const totalPages = Math.ceil(data.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const currentItems = data.slice(startIndex, startIndex + itemsPerPage);
    // Define your custom colors for the icons
    const iconColors = ['#265e9b', '#2196F3', '#99CBFD', '#BDDEFE', '#D9EDFF'];

    

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Box sx={{ mb: 1, ml:1.5 }}>
                <h2 style={{ marginLeft: '10px', textAlign: 'left', wordWrap: 'break-word', whiteSpace: 'normal', color: '#00488c', lineHeight: '1.2' }}>
                    {lang === 'vi' || lang === 'en' ? (
                        <>
                            {SearchResult ? SearchResult.response.numFound : 0}{" "}
                            {lang === 'vi' ? "kết quả của" : "results for"} "{decodeURIComponent(isSearch)}"
                        </>
                    ) : (
                        <>
                            "{decodeURIComponent(isSearch)}" 에 대한{" "}
                            {SearchResult ? SearchResult.response.numFound : 0} 개 결과
                        </>
                    )}
                    <br />
                    <span style={{ fontWeight: '400',  fontSize: '16px'}}>
                        {lang === 'vi'
                            ? "Lưu ý: Bạn đang tìm kiếm nội dung tiếng Việt"
                            : lang === 'en'
                            ? "Note: You are searching English content"
                            : lang === 'ko'
                            ? "참고사항: 한국어 콘텐츠를 검색 중입니다."
                            : ""}
                    </span>
                </h2>
            </Box>

            <Box sx={{ flexGrow: 1, ml:{xs: 0, md: 1} }}>
                <ul style={{ listStyleType: 'none', padding: 1 }}>
                    {currentItems.map((item) => {
                        let description = item.Description.description;
                        try{
                            description = cleanString(item.Description.description);
                        }
                        catch{
                            try{
                                description =[cleanString(item.Description.description[0])];
                            }
                            catch {
                                description = "";
                            }
                            
                        }
                        const uqParam = item.Description.uq;
                        
                        
                        return(
                        <li
                            key={item.key}
                            style={{
                                marginBottom: '10px',
                                borderBottom: '1px dashed #ccc',
                                paddingBottom: '10px',
                                backgroundColor: selectedItem === item.Code.id ? '#d3e5ff' : 'transparent', // Light blue background on select
                                cursor: 'pointer', // Change cursor to pointer
                                borderRadius: '5px',
                            }}
                            
                            onClick={() => handleItemClick(item.Code.hs ? item.Code.hs : item._nest_parent_, item.Code.id)} // Handle item click
                            onMouseEnter={(e) => {
                                e.currentTarget.style.backgroundColor = '#f0f0f0'; // Change background color to gray on hover
                            }}
                            onMouseLeave={(e) => {
                                e.currentTarget.style.backgroundColor = selectedItem === item.Code.id ? '#d3e5ff' : 'transparent'; // Reset background color when mouse leaves
                            }}
                        >   

                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                <Box sx={{ display: 'flex', marginBottom: '4px', marginTop: '10px', marginLeft: '10px' }}>
                                    {formatHs(item.Code.hs, iconColors)}
                                </Box>
                                {item.doc_date !== '' && item.doc_no !== '' && (
                                    <Box 
                                        sx={{ 
                                            display: 'flex', 
                                            justifyContent: 'space-between',
                                            alignItems: 'center', 
                                            width: '100%',
                                            mt: '15px',
                                            mb: '-2px', 
                                            paddingLeft: '10px', 
                                            paddingRight: '10px' 
                                        }}
                                    >
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                color: 'black', 
                                            }}
                                        >
                                            {item.doc_no}
                                        </Typography>

                                        <Typography
                                            variant="body2"
                                            sx={{
                                                color: 'black',
                                                textAlign: 'right', 
                                            }}
                                        >
                                            {item.doc_date}
                                        </Typography>
                                    </Box>
                                )}



                                <Typography
                                    variant="body1"
                                    sx={{
                                        whiteSpace: 'pre-wrap',
                                        overflowWrap: 'break-word',
                                        marginTop: '8px', // Add marginTop to the description text
                                        marginLeft: '10px', // Optional: Add marginLeft to the description text
                                    }}
                                    
                                    dangerouslySetInnerHTML={{ __html: formatDescription(description, item.Description.highlighting) }}
                                />
                                
                                {uqParam && <StyledNote sx={{mt:2, ml: '10px'}}>UQ: {uqParam}</StyledNote>}
                            </Box>
                        </li>
                        )})}
                </ul>
            </Box>

            <Box sx={{ mt: 'auto', mb: 30, maxWidth: {xs: '95vw'} }}>
                <Stack className="custom-pagination" direction="row" justifyContent="center" alignItems="center">
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={handlePageChange}
                        shape="rounded"
                        showFirstButton
                        showLastButton
                    />
                </Stack>
            </Box>
        </Box>
    );
};

export default memo(ItemList);

const StyledNote = styled('span')(({ theme }) => ({
    fontSize: '14px', // Smaller font for the UQ note
    color: theme.palette.text.secondary, // Secondary color for the note
    
}));