import { registerRenderer, textRenderer } from 'handsontable/renderers';
import { BangKeDataInterface } from 'models/BangKe';
import { BangKeExcelDataInterface } from 'models/BangKe/BangKeExcelData';
import { useContext, useMemo } from 'react';
import { Sheet } from '../../Sheet';
import { ConvertFormType, InvoiceContext, XlxsExportListInterface } from '../TableInvoice';
import './BangeSheet.scss';
export interface CreateFixedHeaderProps {
    excelRow?: BangKeExcelDataInterface;
    bangKeItem?: BangKeDataInterface;
}

const colCount = 13;
const rowCount = 14;

export function ConvertCurrency(num: number): string {
    if (num)
        return num
            .toFixed(2)
            .toString()
            .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
    else return '0';
}

enum PriceTypeEnum {
    progess_price = 'Giá gia công: ',
    nt_price = 'Trị giá: '
}

export function ConvertPriceType(label: string) {
    switch (label) {
        case 'progressPriceInput':
            return PriceTypeEnum.progess_price;
        // case 'userInput':
        //     return '';
        default:
            return PriceTypeEnum.nt_price;
    }
}

export function CreateFixedHeader(fixedNumHeader: string[], { excelRow, bangKeItem }: CreateFixedHeaderProps) {
    return [
        ['PHỤ LỤC Ⅷ', null, null, null, null, null, null, null, null, null, null, null, null],
        [`BẢNG KÊ KHAI HÀNG HOÁ XUẤT KHẨU ĐẠT TIÊU CHÍ ${bangKeItem?.form_type}`],
        [`(ban hành kèm theo Thông tư số: 05/2018/TT-BTC ngày 03 tháng 04 năm 2018 quy định về xuất xứ hàng hóa)`],
        [
            `Tên thương nhân: "CÔNG TY TNHH SEDO VINA"`,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            `Tiêu chí áp dụng:  `,
            `${bangKeItem ? bangKeItem.criteria : ''}`,
            null,
            null
        ],
        [
            `Địa \n chỉ : `,
            `Đường Đ.20, Khu công nghiệp Châu Đức, Xã Suối Nghệ, Huyện Châu Đức, Tỉnh Bà Rịa - Vũng Tàu, Việt Nam`,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            `Tên hàng hoá: `,
            `${bangKeItem ? bangKeItem.name : ''}`,
            null,
            null
        ],
        [
            `Mã số thuế:  `,
            `${excelRow ? excelRow.tax_code : ''}`,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            `Mã HS của hàng hoá:`,
            `${bangKeItem ? bangKeItem.hs : ''}`,
            null,
            null
        ],
        [
            `Tờ khai số: `,
            `${excelRow ? excelRow.account_number : ''} ngày  ${bangKeItem ? bangKeItem.registered_date : ''}`,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            `Số lượng:`,
            `${bangKeItem ? bangKeItem.total : ''}`,
            null,
            null
        ],
        [
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            `${excelRow && ConvertPriceType(excelRow.price.type)} `,
            `${excelRow ? ConvertCurrency(excelRow.price.value) : '0'} USD`,
            null,
            null
        ],
        [
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            `${excelRow && ConvertPriceType(excelRow.price.type) === PriceTypeEnum.progess_price ? PriceTypeEnum.nt_price : ''} `,
            `${
                excelRow && ConvertPriceType(excelRow.price.type) === PriceTypeEnum.progess_price
                    ? bangKeItem && ConvertCurrency(bangKeItem.nt_value) + ' USD'
                    : ''
            }`,
            null,
            null
        ],
        [null, null, null, null, null, null, null, null, null, null, null, null, null],
        [
            'STT',
            'Nguyên phụ liệu',
            'Mã HS',
            'Đơn vị tính',
            'ĐM/1SP',
            'Nhu cầu nguyên liệu sử dụng cho lô hàng',
            null,
            null,
            'Nước xuất xứ',
            'Tờ khai hải quan \nnhập khẩu/Hóađơn \ngiá trị gia tăng',
            null,
            'C/O ưu đãi nhập khẩu/ Bản khai báo của nhà sản xuất/nhà cung cấp nguyên liệu trong nước',
            null
        ],
        [null, null, null, null, null, 'Đơn giá (CIF)', 'Trị giá USD'],
        [null, null, null, null, null, null, 'Có xuất xứ\nFTA', 'Không xuất xứ\nFTA', null, 'Số', 'Ngày', 'Số', 'Ngày'],
        fixedNumHeader
    ];
}

// export function CreateEXWFixedHeader(fixedNumHeader: string[], { excelRow, bangKeItem }: CreateFixedHeaderProps) {
//     return [
//         ['PHỤ LỤC Ⅷ', null, null, null, null, null, null, null, null, null, null, null, null],
//         [`BẢNG KÊ KHAI HÀNG HOÁ XUẤT KHẨU ĐẠT TIÊU CHÍ ${bangKeItem?.form_type}`],
//         [`(ban hành kèm theo Thông tư số: 05/2018/TT-BTC ngày 03 tháng 04 năm 2018 quy định về xuất xứ hàng hóa)`],
//         [
//             `Tên thương nhân: "CÔNG TY TNHH SEDO VINA"`,
//             null,
//             null,
//             null,
//             null,
//             null,
//             null,
//             null,
//             null,
//             `Tiêu chí áp dụng:  `,
//             `${bangKeItem ? bangKeItem.criteria : ''}`,
//             null,
//             null
//         ],
//         [
//             `Địa \n chỉ : `,
//             `Đường Đ.20, Khu công nghiệp Châu Đức, Xã Suối Nghệ, Huyện Châu Đức, Tỉnh Bà Rịa - Vũng Tàu, Việt Nam`,
//             null,
//             null,
//             null,
//             null,
//             null,
//             null,
//             null,
//             `Tên hàng hoá: `,
//             `${bangKeItem ? bangKeItem.name : ''}`,
//             null,
//             null
//         ],
//         [
//             `Mã số thuế:  `,
//             `${excelRow ? excelRow.tax_code : ''}`,
//             null,
//             null,
//             null,
//             null,
//             null,
//             null,
//             null,
//             `Mã HS của hàng hoá:`,
//             `${bangKeItem ? bangKeItem.hs : ''}`,
//             null,
//             null
//         ],
//         [
//             `Tờ khai số: `,
//             `${excelRow ? excelRow.account_number : ''} ngày  ${bangKeItem ? bangKeItem.registered_date : ''}`,
//             null,
//             null,
//             null,
//             null,
//             null,
//             null,
//             null,
//             `Số lượng:`,
//             `${bangKeItem ? bangKeItem.total : ''}`,
//             null,
//             null
//         ],
//         [
//             null,
//             null,
//             null,
//             null,
//             null,
//             null,
//             null,
//             null,
//             null,
//             `Giá ${excelRow ? ConvertPriceType(excelRow.price.type) : ''}: `,
//             `${excelRow ? ConvertCurrency(excelRow.price.value) : '000'} USD`,
//             null,
//             null
//         ],
//         [
//             null,
//             null,
//             null,
//             null,
//             null,
//             null,
//             null,
//             null,
//             null,
//             `Tỷ giá:`,
//             `${bangKeItem ? ConvertCurrency(bangKeItem.exchange_rate) : ''} USD`,
//             null,
//             null
//         ],
//         [
//             null,
//             null,
//             null,
//             null,
//             null,
//             null,
//             null,
//             null,
//             null,
//             `Giá gia công:`,
//             `${excelRow ? ConvertCurrency(excelRow.price.value) : ''} USD`,
//             null,
//             null
//         ],
//         [null, null, null, null, null, null, null, null, null, null, null, null, null],
//         [
//             'STT',
//             'Nguyên phụ liệu',
//             'Mã HS',
//             'Đơn vị tính',
//             'ĐM/1SP',
//             'Nhu cầu nguyên liệu sử dụng cho lô hàng',
//             null,
//             null,
//             'Nước xuất xứ',
//             'Tờ khai hải quan \nnhập khẩu/Hóađơn \ngiá trị gia tăng',
//             null,
//             'C/O ưu đãi nhập khẩu/ Bản khai báo của nhà sản xuất/nhà cung cấp nguyên liệu trong nước',
//             null
//         ],
//         [null, null, null, null, null, 'Đơn giá (CIF)', 'Trị giá USD'],
//         [null, null, null, null, null, null, 'Có xuất xứ\nFTA', 'Không xuất xứ\nFTA', null, 'Số', 'Ngày', 'Số', 'Ngày'],
//         fixedNumHeader
//     ];
// }

export function CreateTotalNoFta({ sum_of_no_fta_origin_cost, sum_of_has_fta_origin_cost }: BangKeExcelDataInterface) {
    return [
        'Tổng',
        null,
        null,
        null,
        null,
        null,
        ConvertCurrency(sum_of_has_fta_origin_cost),
        ConvertCurrency(sum_of_no_fta_origin_cost),
        null,
        null,
        null,
        null,
        null
    ];
}

export function CreateFinalResult({ fulfill_criteria }: BangKeExcelDataInterface) {
    return [
        [`Kết luận: ${fulfill_criteria}`, null, null, null, null, null, null, null, null, null, null, null, null],
        [`Công ty cam kết số liệu khai trên là đúng và xin chịu trách nhiệm trước pháp luật về thông tin, số liệu đã khai.`, null, null]
    ];
}

export function CreateRVCEWX({ sum_of_no_fta_origin_cost, price }: BangKeExcelDataInterface) {
    const value = (sum_of_no_fta_origin_cost / price.value) * 100 ?? 0;
    return [
        [
            `Tỷ lệ RVC = `,
            'Trị giá nguyên vật liệu đầu vào không có xuất xứ',
            null,
            '                X                 100%                =                ',
            null,
            ConvertCurrency(sum_of_no_fta_origin_cost ?? 0),
            'X                 100%                =                ',
            null,
            ConvertCurrency(value) + ' %',
            null,
            null,
            null,
            null
        ],
        [null, 'EXW', null, null, null, ConvertCurrency(price.value ?? 0), null, null, null, null, null, null, null]
    ];
}

export function CreateRVC({ price, sum_of_no_fta_origin_cost }: BangKeExcelDataInterface) {
    const value = ((price.value - sum_of_no_fta_origin_cost) / price.value) * 100;
    return [
        [
            'Tỷ lệ RVC = ',
            'Trị giá FOB                 -                Trị giá nguyên liệu đầu vào không có xuất xứ',
            null,
            '        X        100%        =        ',
            null,
            `( ${ConvertCurrency(price.value ?? 0)}      -      ${ConvertCurrency(sum_of_no_fta_origin_cost ?? 0)} )`,
            null,
            '        X        100%        =        ',
            null,
            ConvertCurrency(value) + ' %',
            null,
            null,
            null
        ],
        [null, 'Trị giá FOB', null, null, null, ConvertCurrency(price.value ?? 0), null, null, null, null, null, null, null]
    ];
}

export function CreateFactoryPrice(
    { sum_of_has_fta_origin_cost, sum_of_no_fta_origin_cost, price }: BangKeExcelDataInterface,
    { total }: BangKeDataInterface
) {
    const progessPriceValue = total * price.value;
    const factoryPrice = sum_of_has_fta_origin_cost + sum_of_no_fta_origin_cost + progessPriceValue;
    return [
        [
            `Trị giá xuất xưởng           =           Trị giá NPL có xuất xứ           +           Trị giá NPL không xuất xứ           +           Trị giá giá gia công`,
            ,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null
        ],
        [
            `Trị giá xuất xưởng           =           ${ConvertCurrency(
                sum_of_has_fta_origin_cost
            )}           +           ${ConvertCurrency(sum_of_no_fta_origin_cost)}           +           ${ConvertCurrency(
                progessPriceValue
            )}           =           `,
            null,
            null,
            null,
            null,
            null,
            ConvertCurrency(factoryPrice ?? 0),
            null,
            null,
            null,
            null,
            null,
            null
        ],
        [
            'Công thức % của giá trị nguyên liệu được sử dụng',
            null,
            `=`,
            'Trị giá nguyên liệu đầu vào không có xuất xứ VN \n           Trị giá xuất xưởng',
            null,
            null,
            null,
            'X 100%          =          ',
            price.percent.toFixed(2) + ' %',
            null,
            null,
            null,
            null
        ]
    ];
}

export function CreateProgessPriceValue({ price }: BangKeExcelDataInterface, { total }: BangKeDataInterface) {
    const progessPriceValue = total * price.value;

    return [
        `Trị giá giá gia công           =           Số lượng           x           Giá gia công           =           ${total}           x           ${ConvertCurrency(
            price.value
        )}           =`,
        null,
        null,
        null,
        null,
        null,
        ConvertCurrency(progessPriceValue),
        null,
        null,
        null,
        null,
        null,
        null
    ];
}

export function CreateCCMergerCell(rowAmount: number) {
    if (rowAmount) {
        return [
            { row: rowAmount, col: 0, rowspan: 1, colspan: 6 },
            { row: rowAmount + 1, col: 0, rowspan: 1, colspan: 6 },
            { row: rowAmount + 2, col: 0, rowspan: 1, colspan: 6 }
        ];
    } else return [];
}

export function CreateEXWNonSedoFootMergerCell(rowAmount: number) {
    if (rowAmount) {
        return [
            { row: rowAmount, col: 0, rowspan: 1, colspan: 6 },
            { row: rowAmount + 1, col: 0, rowspan: 2, colspan: 1 },
            { row: rowAmount + 1, col: 1, rowspan: 1, colspan: 2 },
            { row: rowAmount + 1, col: 3, rowspan: 2, colspan: 2 },
            { row: rowAmount + 1, col: 6, rowspan: 2, colspan: 2 },
            { row: rowAmount + 1, col: 8, rowspan: 2, colspan: 1 },
            { row: rowAmount + 2, col: 1, rowspan: 1, colspan: 2 },
            { row: rowAmount + 3, col: 0, rowspan: 1, colspan: 6 },
            { row: rowAmount + 4, col: 0, rowspan: 1, colspan: 6 }
        ];
    } else return [];
}

export function CreateEXWWithSedoFootMergerCell(rowAmount: number) {
    if (rowAmount) {
        return [
            { row: rowAmount, col: 0, rowspan: 1, colspan: 6 },
            { row: rowAmount + 1, col: 0, rowspan: 1, colspan: 6 },
            { row: rowAmount + 2, col: 0, rowspan: 1, colspan: 6 },
            { row: rowAmount + 3, col: 0, rowspan: 1, colspan: 6 },
            { row: rowAmount + 4, col: 0, rowspan: 1, colspan: 2 },
            { row: rowAmount + 4, col: 3, rowspan: 1, colspan: 4 },
            { row: rowAmount + 5, col: 0, rowspan: 1, colspan: 2 },
            { row: rowAmount + 5, col: 3, rowspan: 1, colspan: 4 },
            { row: rowAmount + 6, col: 0, rowspan: 1, colspan: 4 }
        ];
    } else return [];
}

export function CreateFOBFootMergeCell(rowAmount: number) {
    if (rowAmount) {
        return [
            { row: rowAmount, col: 0, rowspan: 1, colspan: 6 },
            { row: rowAmount + 1, col: 0, rowspan: 2, colspan: 1 },
            { row: rowAmount + 1, col: 1, rowspan: 1, colspan: 2 },
            { row: rowAmount + 1, col: 3, rowspan: 1, colspan: 2 },
            { row: rowAmount + 1, col: 5, rowspan: 1, colspan: 2 },
            { row: rowAmount + 1, col: 7, rowspan: 1, colspan: 2 },
            { row: rowAmount + 2, col: 1, rowspan: 1, colspan: 2 },
            { row: rowAmount + 2, col: 5, rowspan: 1, colspan: 2 },
            { row: rowAmount + 3, col: 0, rowspan: 1, colspan: 6 },
            { row: rowAmount + 4, col: 0, rowspan: 1, colspan: 6 }
        ];
    } else return [];
}

export function CreateFOBMergeCell(footerMergeCell: any[]) {
    return [
        { row: 0, col: 0, rowspan: 1, colspan: 13 },
        { row: 1, col: 0, rowspan: 1, colspan: 13 },
        { row: 2, col: 0, rowspan: 1, colspan: 13 },
        { row: 3, col: 0, rowspan: 1, colspan: 2 },
        { row: 3, col: 10, rowspan: 1, colspan: 3 },
        { row: 4, col: 10, rowspan: 1, colspan: 3 },
        { row: 5, col: 10, rowspan: 1, colspan: 3 },
        { row: 6, col: 10, rowspan: 1, colspan: 3 },
        { row: 7, col: 10, rowspan: 1, colspan: 3 },
        { row: 8, col: 10, rowspan: 1, colspan: 3 },
        { row: 9, col: 0, rowspan: 1, colspan: 13 },
        { row: 10, col: 0, rowspan: 3, colspan: 1 },
        { row: 10, col: 1, rowspan: 3, colspan: 1 },
        { row: 10, col: 2, rowspan: 3, colspan: 1 },
        { row: 10, col: 3, rowspan: 3, colspan: 1 },
        { row: 10, col: 4, rowspan: 3, colspan: 1 },
        { row: 10, col: 5, rowspan: 1, colspan: 3 },
        { row: 11, col: 5, rowspan: 2, colspan: 1 },
        { row: 11, col: 6, rowspan: 1, colspan: 2 },
        { row: 10, col: 8, rowspan: 3, colspan: 1 },
        { row: 10, col: 9, rowspan: 2, colspan: 2 },
        { row: 10, col: 11, rowspan: 2, colspan: 2 },
        ...footerMergeCell
    ];
}

export function CreateEXWMergeCell(footerMergeCell: any[]) {
    return [
        { row: 0, col: 0, rowspan: 1, colspan: 13 },
        { row: 1, col: 0, rowspan: 1, colspan: 13 },
        { row: 2, col: 0, rowspan: 1, colspan: 13 },
        { row: 3, col: 0, rowspan: 1, colspan: 2 },
        { row: 3, col: 10, rowspan: 1, colspan: 3 },
        { row: 4, col: 10, rowspan: 1, colspan: 3 },
        { row: 5, col: 10, rowspan: 1, colspan: 3 },
        { row: 6, col: 10, rowspan: 1, colspan: 3 },
        { row: 7, col: 10, rowspan: 1, colspan: 3 },
        { row: 8, col: 10, rowspan: 1, colspan: 3 },
        { row: 9, col: 10, rowspan: 1, colspan: 3 },
        { row: 10, col: 0, rowspan: 1, colspan: 13 },
        { row: 11, col: 0, rowspan: 3, colspan: 1 },
        { row: 11, col: 1, rowspan: 3, colspan: 1 },
        { row: 11, col: 2, rowspan: 3, colspan: 1 },
        { row: 11, col: 3, rowspan: 3, colspan: 1 },
        { row: 11, col: 4, rowspan: 3, colspan: 1 },
        { row: 11, col: 5, rowspan: 1, colspan: 3 },
        { row: 11, col: 8, rowspan: 3, colspan: 1 },
        { row: 11, col: 9, rowspan: 2, colspan: 2 },
        { row: 11, col: 11, rowspan: 2, colspan: 2 },
        { row: 12, col: 5, rowspan: 2, colspan: 1 },
        { row: 12, col: 6, rowspan: 1, colspan: 2 },
        ...footerMergeCell
    ];
}

export const BangkeSheet = ({ sheetData, sheetMergeCell, rows }: XlxsExportListInterface) => {
    const { formType, extraFieldLength } = useContext(InvoiceContext);
    registerRenderer('customStylesRenderer', (hotInstance, TD, ...rest) => {
        textRenderer(hotInstance, TD, ...rest);
        TD.style.fontWeight = 'bold';
        TD.style.fontSize = '16px';
        TD.style.textAlign = 'center';
    });
    registerRenderer('italicStyle', (hotInstance, TD, ...rest) => {
        textRenderer(hotInstance, TD, ...rest);
        TD.style.fontStyle = 'italic';
        TD.style.textAlign = 'center';
    });
    registerRenderer('centerText', (hotInstance, TD, ...rest) => {
        textRenderer(hotInstance, TD, ...rest);
        TD.style.textAlign = 'center';
        TD.style.fontWeight = 'bold';
        TD.style.margin = '0px auto';
        TD.style.border = 'none';
    });
    registerRenderer('centerTextWithBorder', (hotInstance, TD, ...rest) => {
        textRenderer(hotInstance, TD, ...rest);
        TD.style.textAlign = 'center';
    });
    registerRenderer('alignRight', (hotInstance, TD, ...rest) => {
        textRenderer(hotInstance, TD, ...rest);
        TD.style.textAlign = 'right';
        TD.style.fontWeight = 'bold';
    });
    registerRenderer('alignLeft', (hotInstance, TD, ...rest) => {
        textRenderer(hotInstance, TD, ...rest);
        TD.style.textAlign = 'left';
        TD.style.fontWeight = 'bold';
        TD.style.border = 'none';
    });

    const headerCellStyle = useMemo(() => {
        // const formTypeConverted = ConvertFormType(formType);
        const headerCellStyle = [];

        // if (formTypeConverted === 'EWX') {
        //     for (let r = 0; r < rowCount; r++) {
        //         for (let c = 0; c < colCount; c++) {
        //             if ((r === 0 && c === 0) || (r === 1 && c === 0)) {
        //                 headerCellStyle.push({
        //                     row: r,
        //                     col: c,
        //                     renderer: 'customStylesRenderer'
        //                 });
        //             }
        //             if (r === 2 && c === 0) {
        //                 headerCellStyle.push({
        //                     row: r,
        //                     col: c,
        //                     renderer: 'italicStyle'
        //                 });
        //             }
        //             if (r > 2 && r <= 9 && c === 10) {
        //                 headerCellStyle.push({
        //                     row: r,
        //                     col: c,
        //                     renderer: 'centerText'
        //                 });
        //             }
        //             if (r >= 10) {
        //                 headerCellStyle.push({
        //                     row: r,
        //                     col: c,
        //                     className: 'htMiddle htCenter'
        //                 });
        //             }
        //         }
        //     }
        // } else {
        //     for (let r = 0; r < rowCount; r++) {
        //         for (let c = 0; c < colCount; c++) {
        //             if ((r === 0 && c === 0) || (r === 1 && c === 0)) {
        //                 headerCellStyle.push({
        //                     row: r,
        //                     col: c,
        //                     renderer: 'customStylesRenderer'
        //                 });
        //             }
        //             if (r === 2 && c === 0) {
        //                 headerCellStyle.push({
        //                     row: r,
        //                     col: c,
        //                     renderer: 'italicStyle'
        //                 });
        //             }
        //             if (r > 2 && r <= 9 && c === 10) {
        //                 headerCellStyle.push({
        //                     row: r,
        //                     col: c,
        //                     renderer: 'centerText'
        //                 });
        //             }
        //             if (r >= 9) {
        //                 headerCellStyle.push({
        //                     row: r,
        //                     col: c,
        //                     className: 'htMiddle htCenter'
        //                 });
        //             }
        //         }
        //     }
        // }
        for (let r = 0; r < rowCount; r++) {
            for (let c = 0; c < colCount; c++) {
                if ((r === 0 && c === 0) || (r === 1 && c === 0)) {
                    headerCellStyle.push({
                        row: r,
                        col: c,
                        renderer: 'customStylesRenderer'
                    });
                }
                if (r === 2 && c === 0) {
                    headerCellStyle.push({
                        row: r,
                        col: c,
                        renderer: 'italicStyle'
                    });
                }
                if (r > 2 && r <= 9 && c === 10) {
                    headerCellStyle.push({
                        row: r,
                        col: c,
                        renderer: 'centerTextWithBorder'
                    });
                }
                if (r >= 10) {
                    headerCellStyle.push({
                        row: r,
                        col: c,
                        className: 'htMiddle htCenter'
                    });
                }
            }
        }
        return headerCellStyle;
    }, []);

    const bodyCellStyle = useMemo(() => {
        const formTypeConverted = ConvertFormType(formType);

        if (rows && rows != 0 && extraFieldLength !== null) {
            const bodyCellStyle = [];
            if (formTypeConverted === 'EWX') {
                for (let i = rowCount; i < rowCount + rows + extraFieldLength + 3; i++) {
                    for (let j = 0; j < colCount; j++) {
                        if (i > rows + rowCount - 1) {
                            if (i > rows + rowCount - 1 && i < rows + rowCount + 1) {
                                // if (i > rows + rowCount - 1) {
                                bodyCellStyle.push({ row: i, col: j, renderer: 'alignLeft' });
                            } else if (i > rowCount + rows && i <= rowCount + rows + extraFieldLength) {
                                bodyCellStyle.push({ row: i, col: j, renderer: 'centerText' });
                            } else {
                                bodyCellStyle.push({ row: i, col: j, renderer: 'alignLeft' });
                            }
                        } else if (j === 1) {
                            continue;
                        } else {
                            bodyCellStyle.push({ row: i, col: j, className: 'htCenter htMiddle' });
                        }
                    }
                }
            } else {
                for (let i = rowCount; i < rowCount + rows + extraFieldLength + 3; i++) {
                    for (let j = 0; j < colCount; j++) {
                        if (i > rows + rowCount - 1) {
                            if (i > rows + rowCount - 1 && i < rows + rowCount + 1) {
                                // if (i > rows + rowCount - 1) {
                                bodyCellStyle.push({ row: i, col: j, renderer: 'alignLeft' });
                            } else if (i > rowCount + rows && i <= rowCount + rows + extraFieldLength) {
                                bodyCellStyle.push({ row: i, col: j, renderer: 'centerText' });
                            } else {
                                bodyCellStyle.push({ row: i, col: j, renderer: 'alignLeft' });
                            }
                        } else if (j === 1) {
                            continue;
                        } else {
                            bodyCellStyle.push({ row: i, col: j, className: 'htCenter htMiddle' });
                        }
                    }
                }
            }
            return bodyCellStyle;
        }
        return [];
    }, [rows, formType, extraFieldLength]);

    return (
        <>
            {sheetData && sheetMergeCell && sheetData.length != 0 && sheetMergeCell.length != 0 && (
                <Sheet
                    data={sheetData}
                    width={'100%'}
                    height={'100%'}
                    colHeaders
                    rowHeaders
                    colWidths={[100, 420, 100, 100, 100, 100, 100, 160, 200, 140, 160, 120, 120]}
                    cell={[...headerCellStyle, ...bodyCellStyle]}
                    mergeCells={sheetMergeCell}
                />
            )}
        </>
    );
};
