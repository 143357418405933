import { ScrapData, ScrapSetupDTO, JSONType, Result, resultGuard } from 'models';
import { ScrapQueryParams } from '../type';
import { ScrapDataSource } from './ScrapDataSource';
import { IScrapImportDTO } from './type';
import { AutoFillParams } from 'components';

export class ScrapRepository {
    constructor(private datasource: ScrapDataSource) {}
    public async getAutoFillData(data: AutoFillParams) {
        return this.datasource.getAutoFill(data);
    }

    public async getAll(params: ScrapQueryParams) {
        return this.datasource.getAll(params);
    }

    public async deleteRows(ids: string[]) {
        return this.datasource.delete(ids);
    }

    public async updateRow(row: Partial<ScrapData>, params: ScrapQueryParams) {
        return this.datasource.update(row, params);
    }

    public async insertRow(row: JSONType) {
        return this.datasource.insert(row);
    }

    public async importData(value: any): Promise<Result<void>> {
        return resultGuard(async () => this.datasource.importData(value));
    }

    public async setupImport(data: { [key: string]: any }) {
        return resultGuard(async () => {
            await this.datasource.setupImport(data);
        });
    }

    public async getFixedHeader(): Promise<Result<string[]>> {
        return resultGuard(async () => {
            return this.datasource.getFixedHeader();
        });
    }
}
