import { JsonObject, JsonProperty } from 'json2typescript';
import { JsonSerializable } from 'models/serialization';

export interface IScrapSetupDTO {
    company_slug: string;
    code: string;
    take_in_num: string;
}

@JsonObject('ScrapSetupDTO')
export class ScrapSetupDTO extends JsonSerializable<ScrapSetupDTO> implements IScrapSetupDTO {
    protected get classRef(): new () => ScrapSetupDTO {
        return ScrapSetupDTO;
    }

    @JsonProperty('company_slug', String, false)
    company_slug!: string;

    @JsonProperty('item_type', String, false)
    item_type!: string;

    @JsonProperty('code', String, false)
    code!: string;

    @JsonProperty('take_in_num', String, false)
    take_in_num!: string;
}
