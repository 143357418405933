import { json } from 'react-router-dom'
import { JsonSerializable, PagingData } from '../serialization'
import { JsonObject, JsonProperty } from 'json2typescript'
export interface IBomSetupDTO {
  company_slug: string
  code: string
  name: string
  unit: string
  material_code: string
  material_name: string
  material_unit: string
  bom_value: string
  note: string
}

@JsonObject('BomSetupDTO')
export class BomSetupDTO
  extends JsonSerializable<BomSetupDTO>
  implements IBomSetupDTO
{
  protected get classRef(): new () => BomSetupDTO {
    return BomSetupDTO
  }

  @JsonProperty('company_slug', String, false)
  company_slug!: string

  @JsonProperty('code', String, false)
  code!: string

  @JsonProperty('name', String, false)
  name!: string

  @JsonProperty('unit', String, false)
  unit!: string

  @JsonProperty('material_code', String, false)
  material_code!: string

  @JsonProperty('material_name', String, false)
  material_name!: string

  @JsonProperty('material_unit', String, false)
  material_unit!: string

  @JsonProperty('bom_value', String, false)
  bom_value!: string

  @JsonProperty('note', String, false)
  note!: string
}
