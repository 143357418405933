import { AmaCancleQueryParams } from 'repository/type';
import { AmaCancleDataSource } from './AmaCancleDataSource';

export class AmaCancleRepository {
    constructor(public datasource: AmaCancleDataSource) {}

    public async getAll(params: AmaCancleQueryParams) {
        return this.datasource.getAll(params);
    }
}
