import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment, InputBase, TextField, styled } from '@mui/material';
import { ContainedButton } from 'components/Button/Button';
import _ from 'lodash';
import { useContext, useState } from 'react';
import { ProjectTheme } from '../../../theme/index';
import { TableContext } from '../context';

export const SearchInput = () => {
    const [userInput, setUserInput] = useState('');
    const { setQ } = useContext(TableContext);

    return (
        <>
            <TextField
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    // qRef.current = event.target.value;
                    setUserInput(event.currentTarget.value);
                }}
                placeholder="Search"
                size="small"
                value={userInput}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <div
                                className="tw-flex tw-items-center hover:tw-cursor-pointer tw-w-4"
                                onClick={() => {
                                    setUserInput('');
                                    setQ(undefined);
                                }}
                            >
                                <ClearIcon sx={{ display: `${userInput ? `block` : `none`}` }} />
                            </div>
                        </InputAdornment>
                    )
                }}
            />

            <ContainedButton
                onClick={() => {
                    if (_.isEmpty(userInput)) {
                        setQ(undefined);
                    } else setQ(userInput);
                }}
                sx={{ width: '30px', marginLeft: '4px', height: '37px' }}
            >
                <SearchIcon />
            </ContainedButton>
        </>
    );
};

export const Input = styled(InputBase)(({}) => ({
    '& .MuiInputBase-input': {
        borderRadius: 4,
        backgroundColor: ProjectTheme.colors.gray5,
        fontFamily: 'Inter',
        fontSize: 14,
        width: '100%',
        padding: '10px 16px',
        flex: 1
    }
}));

// export const SearchInput = () => {
//   const qRef = useRef<string>()
//   return (
//     <>
//       <Input
//         placeholder="Tìm kiếm"
//         onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
//           qRef.current = event.target.value
//         }}
//         size="medium"
//         className="tw-flex-1"
//       />
//       <div className="tw-w-[16px]" />
//       <TableContext.Consumer>
//         {({ setQ }) => (
//           <ContainedButton
//             onClick={() => {
//               if (_.isEmpty(qRef.current)) {
//                 setQ(undefined)
//               } else setQ(qRef.current)
//             }}
//             className="tw-w-[120px]"
//             startIcon={<Search />}
//           >
//             Tìm Kiếm
//           </ContainedButton>
//         )}
//       </TableContext.Consumer>
//     </>
//   )
// }

// const Input = styled(InputBase)(({}) => ({
//   '& .MuiInputBase-input': {
//     borderRadius: 4,
//     position: 'relative',
//     backgroundColor: theme.colors.gray5,
//     fontFamily: 'Inter',
//     fontSize: 14,
//     width: '100%',
//     padding: '10px 16px',
//   },
// }))
