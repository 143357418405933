import {JsonObject, JsonProperty} from "json2typescript";
import {JsonSerializable} from "../serialization";
import {ISellData} from "../SellTable";

export interface IAnnexData {
    id: string;
    exporter: string;
    impDeclNumb: string;
    partNumber: string;
}

@JsonObject('AnnexData')
export class AnnexData extends JsonSerializable<AnnexData> implements IAnnexData {
    protected get classRef(): { new(): AnnexData } {
        return AnnexData;
    }

    @JsonProperty('id', String, false)
    id!: string;

    @JsonProperty('exporter', String, true)
    exporter: string = "";

    @JsonProperty('import_declaration_number', String, true)
    impDeclNumb: string = "";

    @JsonProperty('part_number', String, true)
    partNumber: string = "";
}