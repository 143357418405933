import { JsonObject, JsonProperty } from 'json2typescript';
import { JsonSerializable } from '../serialization';
export interface ITakeOutSetupDTO {
    company_slug: string;
    code: string;
    name: string;
    unit: string;
    takeout_date: string;
    quantity: string;
}

@JsonObject('TakeOutSetupDTO')
export class TakeOutSetupDTO extends JsonSerializable<TakeOutSetupDTO> implements ITakeOutSetupDTO {
    protected get classRef(): new () => TakeOutSetupDTO {
        return TakeOutSetupDTO;
    }

    @JsonProperty('company_slug', String, false)
    company_slug!: string;

    @JsonProperty('item_type', String, false)
    item_type!: string;

    @JsonProperty('code', String, false)
    code!: string;

    @JsonProperty('name', String, false)
    name!: string;

    @JsonProperty('unit', String, false)
    unit!: string;

    @JsonProperty('takeout_date', String, false)
    takeout_date!: string;

    @JsonProperty('quantity', String, false)
    quantity!: string;
}
