import { JsonObject, JsonProperty } from 'json2typescript';
import { JsonSerializable } from '../serialization';

export interface IEndingInventoryDataProduction {
    id: string;
    code: string;
    material_type: string;
    name?: string;
    unit?: string;
    quantity: number;
    note?: string;
}

@JsonObject('EndingInventoryDataProduction')
export class EndingInventoryDataProduction
    extends JsonSerializable<EndingInventoryDataProduction>
    implements IEndingInventoryDataProduction
{
    protected get classRef(): new () => EndingInventoryDataProduction {
        return EndingInventoryDataProduction;
    }

    static empty(): EndingInventoryDataProduction {
        return new EndingInventoryDataProduction().fromJSON({
            id: new Date().getMilliseconds(),
            code: '',
            material_type: '',
            name: '',
            unit: '',
            quantity: 0,
            note: ''
        });
    }

    @JsonProperty('id', String, false)
    id!: string;

    @JsonProperty('code', String, false)
    code!: string;

    @JsonProperty('material_type', String, false)
    material_type!: string;

    @JsonProperty('name', String, true)
    name?: string;

    @JsonProperty('unit', String, true)
    unit: string = '';

    @JsonProperty('quantity', Number, false)
    quantity!: number;

    @JsonProperty('note', String, true)
    note?: string;
}
