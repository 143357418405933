import { AutoFillParams } from 'components';
import { JSONType, MapCodeData, Result, resultGuard } from 'models';
import { MapCodeDataSource, StatementQueryParams } from 'repository';
import { MapCodeImportParams } from './type';

export class MapCodeRepository {
    constructor(private datasource: MapCodeDataSource) {}
    public async getAutoFillData(data: AutoFillParams) {
        return this.datasource.getAutoFill(data);
    }

    public async getAll(params: StatementQueryParams) {
        return this.datasource.getAll(params);
    }

    public async importData(value: MapCodeImportParams): Promise<Result<void>> {
        return resultGuard(async () => {
            await this.datasource.importData(value);
        });
    }

    public async delete(ids: string[], params?: MapCodeImportParams) {
        return this.datasource.delete(ids);
    }

    public async update(data: Partial<MapCodeData>, params: MapCodeImportParams) {
        return this.datasource.update(data, params);
    }

    public async insert(data: JSONType) {
        return this.datasource.insert(data);
    }

    public async importFile(data?: JSONType) {
        throw new Error('Method not implemented.');
    }

    public async getFixedHeader(): Promise<Result<string[]>> {
        return resultGuard(async () => {
            return this.datasource.getFixedHeader();
        });
    }

    public async setupImport(data: { [key: string]: any }) {
        return resultGuard(async () => {
            await this.datasource.setupImport(data);
        });
    }
}
