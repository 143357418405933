import { createSlice } from '@reduxjs/toolkit';

const initialState: { otp: string | null; email: string | null } = {
    otp: null,
    email: null
};

// ==============================|| SLICE - VERIFICATION ||============================== //

const verification = createSlice({
    name: 'verification',
    initialState,
    reducers: {
        setOTP(state, action) {
            const { otp } = action.payload;
            state.otp = otp;
        },

        clearOTP(state) {
            state.otp = null;
        },

        setEmail(state, action) {
            const { email } = action.payload;
            state.email = email;
        },

        clearEmail(state) {
            state.email = null;
        }
    }
});

export default verification.reducer;

export const { setOTP, clearOTP, setEmail, clearEmail } = verification.actions;
