import { IconBug, IconKey } from '@tabler/icons';
import CompaniesIcon from 'assets/SideBarIcons/buildings.svg';
import HomeIcon from 'assets/SideBarIcons/home.svg';
import InfoIcon from 'assets/SideBarIcons/info.svg';
import PlusIcon from 'assets/SideBarIcons/plus.svg';
import MinusIcon from 'assets/SideBarIcons/minus.svg';
import NoneIcon from 'assets/SideBarIcons/none.svg';
import NoneBlueIcon from 'assets/SideBarIcons/none_blue.svg';
import QuestionIcon from 'assets/SideBarIcons/question.svg';
import SettingIcon from 'assets/SideBarIcons/setting.svg';
import { HiMiniTableCells } from 'react-icons/hi2';
import { TbBuilding } from 'react-icons/tb';
import { FormattedMessage } from 'react-intl';
import { NavItemType } from 'types';
import { useHsContext } from 'contexts';
import useConfig from 'hooks/useConfig';
import { useNavigate } from 'react-router-dom';
import { images } from 'constant/Homepage';
import IconIconHome  from './Icon/Home.Icon'
import IconInfoCircle from './Icon/HowToUse.icon'
import IconQuestionLg from './Icon/FAQ.Icon'


const icons = {
    IconKey,
    IconBug
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const createNavItemsFromSections = (sections: any[], lang: string) => {
    return sections.map((section, index) => ({
        id: section.hs,
        title: section[`${lang}_description`] || section.description,
        type: 'item',
        url: `/${section.hs}`,
        icon: PlusIcon // Replace this with appropriate icon logic if needed
    }));
};

// const GenerateHomelistpages = () => {
//     const { Section } = useHsContext();
//     const { locale: lang } = useConfig();

//     let listSection: any[] = [];
//     if (Section) {
//         listSection = Section.map((e: any, i: number) => ({
//             ...e,
//             title: e[`${lang}_description`] || e.description,
//             src: images[i]
//         }));
//     }

//     return createNavItemsFromSections(listSection, lang);
// };
// const dynamicItems = GenerateHomelistpages();

const homelistpages: NavItemType = {
    
    id: 'pages',
    title: '',
    caption: '',
    type: 'group',
    children: [
        {
            id: 'home',
            // title: <FormattedMessage id="home" />,
            title: <FormattedMessage id="Trang chủ" />,
            title_en: <FormattedMessage id="Home" />,
            type: 'item',
            url: '/',
            icon: IconIconHome
        },
        {
            id: 'how to use Eximhow',
            // title: <FormattedMessage id="How to use Eximhow" />,
            title: <FormattedMessage id="Hướng dẫn sử dụng Eximhow" />,
            title_en: <FormattedMessage id="How to use Eximhow" />,
            type: 'item',
            url: '/help',
            icon: IconInfoCircle
        },
        {
            id: 'faq',
            // title: <FormattedMessage id="FAQ" />,
            title: <FormattedMessage id="Câu hỏi thường gặp" />,
            title_en: <FormattedMessage id="FAQ" />,
            type: 'item',
            url: '/faq',
            icon: IconQuestionLg
        },
        {
            id: 'searchingCO',
            // title: <FormattedMessage id="Searching CO" />,
            title: <FormattedMessage id="Kiểm tra CO" />,
            title_en: <FormattedMessage id="Searching CO" />,
            type: 'item',
            url: '/searching-co',
            icon: IconQuestionLg
        },
        // {
        //     id: 'data',
        //     title: <FormattedMessage id="data" />,
        //     type: 'collapse',
        //     icon: PlusIcon,
        //     children: [
        //         {
        //             id: 'exim',
        //             title: <FormattedMessage id="exim" />,
        //             type: 'item',
        //             url: '/data/exim',
        //             target: false
        //         },
        //         {
        //             id: 'accounting',
        //             title: <FormattedMessage id="accounting" />,
        //             type: 'collapse',
        //             icon: MinusIcon,
        //             children: [
        //                 {
        //                     id: 'exim1',
        //                     title: <FormattedMessage id="exim1" />,
        //                     type: 'item',
        //                     url: '/data/exim',
        //                     icon: NoneBlueIcon,
        //                     target: false
        //                 },
        //                 {
        //                     id: 'accounting1',
        //                     title: <FormattedMessage id="accounting1" />,
        //                     type: 'collapse',
        //                     icon: NoneBlueIcon,
        //                     children: [
        //                         {
        //                             id: 'exim2',
        //                             title: <FormattedMessage id="exim2" />,
        //                             type: 'item',
        //                             url: '/data/exim',
        //                             icon: NoneIcon,
        //                             target: false
        //                         },
        //                         {
        //                             id: 'accounting2',
        //                             title: <FormattedMessage id="accounting2" />,
        //                             type: 'collapse',
        //                             icon: NoneIcon,
        //                             target: false
        //                         },
        //                         {
        //                             id: 'warehouse2',
        //                             title: <FormattedMessage id="warehouse2" />,
        //                             type: 'item',
        //                             url: '/data/warehouse',
        //                             icon: NoneIcon,
        //                             target: false
        //                         },
        //                         {
        //                             id: 'production2',
        //                             title: <FormattedMessage id="production2" />,
        //                             type: 'item',
        //                             url: '/data/production',
        //                             icon: NoneIcon,
        //                             target: false
        //                         },
        //                         {
        //                             id: 'bom2',
        //                             title: <FormattedMessage id="bom2" />,
        //                             type: 'item',
        //                             url: '/data/bom',
        //                             icon: NoneIcon,
        //                             target: false
        //                         }
        //                     ]
        //                 },
        //                 {
        //                     id: 'warehouse1',
        //                     title: <FormattedMessage id="warehouse1" />,
        //                     type: 'item',
        //                     url: '/data/warehouse',
        //                     icon: NoneBlueIcon,
        //                     target: false
        //                 },
        //                 {
        //                     id: 'production1',
        //                     title: <FormattedMessage id="production1" />,
        //                     type: 'item',
        //                     url: '/data/production',
        //                     icon: NoneBlueIcon,
        //                     target: false
        //                 },
        //                 {
        //                     id: 'bom1',
        //                     title: <FormattedMessage id="bom1" />,
        //                     type: 'item',
        //                     url: '/data/bom',
        //                     icon: NoneBlueIcon,
        //                     target: false
        //                 }
        //             ]
        //         },
        //         {
        //             id: 'warehouse',
        //             title: <FormattedMessage id="warehouse" />,
        //             type: 'item',
        //             url: '/data/warehouse',
        //             target: false
        //         },
        //         {
        //             id: 'production',
        //             title: <FormattedMessage id="production" />,
        //             type: 'item',
        //             url: '/data/production',
        //             target: false
        //         },
        //         {
        //             id: 'bom',
        //             title: <FormattedMessage id="bom" />,
        //             type: 'item',
        //             url: '/data/bom',
        //             target: false
        //         }
        //     ]
        // }
    ]
};

export default homelistpages;
