import { JsonObject, JsonProperty } from 'json2typescript';
import { JsonSerializable } from 'models/serialization';

export interface IAmaData {
    ecus_id: string;
    created_at: string;
    account_number: number;
    row_number: number;
    unit: string;
    code: string;
    total: number;
}

@JsonObject('AmaData')
export class AmaData extends JsonSerializable<AmaData> implements IAmaData {
    protected get classRef(): new () => AmaData {
        return AmaData;
    }

    @JsonProperty('ecus_id', String, true)
    ecus_id: string = '';

    @JsonProperty('created_at', String, false)
    created_at!: string;

    @JsonProperty('account_number', Number, false)
    account_number!: number;

    @JsonProperty('row_number', Number, false)
    row_number!: number;

    @JsonProperty('unit', String, false)
    unit!: string;

    @JsonProperty('code', String, false)
    code!: string;

    @JsonProperty('total', Number, false)
    total!: number;
}
