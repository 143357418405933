import { useContext } from 'react';
import ReportStatementStep from '../ReportStatementSteps/ReportStatementStep';
import ReportStatementStepButton from '../ReportStatementSteps/ReportStatementStepButton';
import { Report15Context } from '../context';
import styled from '../style.module.css';
import ModalStep1 from './ModalStep1';
import ModalStep2 from './ModalStep2_ImportFile';
import ModalStep3 from './ModalStep3_SheetTiltlePicker';
import ModalStep4 from './ModalStep4_MappingHeader';

function ReportStatementView() {
    const { step } = useContext(Report15Context);

    switch (step) {
        case 0:
            return (
                <div className={styled['modal_body']}>
                    <ModalStep1 />
                </div>
            );
        case 1:
            return (
                <div className={styled['modal_body']}>
                    <ModalStep2 />
                </div>
            );

        case 2:
            return (
                <div className={styled['modal_body']}>
                    <ModalStep3 />
                </div>
            );
        case 3:
            return (
                <div className={styled['modal_body']}>
                    <ModalStep4 />
                </div>
            );

        default:
            return <div></div>;
    }
}

function ReportStatementModal() {
    return (
        <div className={styled['modal']}>
            <ReportStatementStep />
            <ReportStatementView />
            <ReportStatementStepButton />
        </div>
    );
}

export default ReportStatementModal;
