import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { Allotment } from 'allotment';
import 'allotment/dist/style.css';
import { $Table, TableContext, useCompanyFolderSlug, useManipulateTable, useQueryTable } from 'components';
import { $TableForm } from 'components/Table/TableForm/$TableForm';
import { $TableFormAddColumn } from 'components/Table/TableForm/$TableFormAddColumn';
import { $TableFormDelColumn } from 'components/Table/TableForm/$TableFormDelColumn';
import { TableType } from 'components/Table/TableForm/Type';
import { AutoFillParams, ColumnMeta, ColumnWidth } from 'components/Table/type';
import { useMapCodeRepo } from 'di';
import { ConnectionState, useAsync } from 'hooks/Async';
import _ from 'lodash';
import { MapCodeData, PagingData } from 'models';
import { AutoFillData } from 'models/AutoFillData/AutoFillData';
import { Statement15Data } from 'models/Statement15Table/Statement15Data';
import { useContext, useEffect, useMemo } from 'react';
import { StatementQueryParams } from 'repository/type';
import styles from '../TableEcusNext/styles.module.css';

export const MAPCODE_META_MAP: { [key: string]: ColumnMeta } = {
    id: {
        inputType: 'text'
    },
    created_at: {
        inputType: 'text'
    }
};

export const TableMapCodeNext = () => {
    const { folder_slug, company_slug } = useCompanyFolderSlug();
    const params = useMemo<StatementQueryParams>(
        () => ({
            // item_type: ItemType.MATERIAL,
            folder_slug,
            company_slug
        }),
        [folder_slug, company_slug]
    );

    const TableFormType = TableType.mapCode;
    const mapCodeRepo = useMapCodeRepo();
    const {
        panelVisibility,
        delColumnState,
        codeLabel,
        nameInput,
        unitInput,
        setlistAutoFillName,
        setlistAutoFillUnit,
        addColumnState,
        columnExternal
    } = useContext(TableContext);
    const { execute, tableData } = useAsync<PagingData<AutoFillData>, any>(() => mapCodeRepo.getAutoFillData(autoFillParams));

    const MapCodeMetaMap: { [key: string]: ColumnMeta } = useMemo(() => {
        if (!columnExternal) return MAPCODE_META_MAP;
        else return { ...MAPCODE_META_MAP, ...columnExternal };
    }, [columnExternal]);

    const autoFillParams: AutoFillParams = useMemo(() => {
        const result = {
            code: codeLabel ?? '',
            select: ['name', 'unit'],
            filter: {},
            company_slug: params.company_slug,
            folder_slug: params.folder_slug
        };
        if (nameInput) {
            return {
                ...result,
                filter: { name: nameInput }
            };
        } else if (unitInput) {
            return {
                ...result,
                filter: { unit: unitInput }
            };
        } else {
            return result;
        }
    }, [codeLabel, nameInput, unitInput]);

    useEffect(() => {
        if (codeLabel) {
            execute('');
        }
    }, [codeLabel, autoFillParams]);

    useEffect(() => {
        if (tableData && tableData.state === ConnectionState.hasData) {
            tableData.data.results.map((item) => {
                setlistAutoFillName(item.name);
                setlistAutoFillUnit(item.unit);
            });
        }
    }, [tableData]);

    const defaultColumns = useMemo<ColumnDef<MapCodeData>[]>(() => {
        const columnHelper = createColumnHelper<MapCodeData>();

        return _.toPairs(MapCodeMetaMap).map(([key, value]) =>
            // @ts-ignore
            columnHelper.accessor(key, {
                id: key,
                minSize: ColumnWidth.ssm,
                size: key === 'name' || key === 'item_name' ? ColumnWidth.xxl : ColumnWidth.sm,
                enableSorting: value.enableSorting ?? false,
                cell: (info) => <div className={`tw-pr-[8px]`}>{info.getValue() as React.ReactNode}</div>,
                header: () => <div className={styles.header}>{key}</div>
            })
        );
    }, [MapCodeMetaMap]);

    useQueryTable({
        params,
        fetchFn: (params) => mapCodeRepo.getAll(params)
    });

    useManipulateTable<MapCodeData, StatementQueryParams>({
        args: params,
        updateFn: (row, params) => mapCodeRepo.update(row, params),
        deleteFn: (ids, params) => mapCodeRepo.delete(ids, params),
        insertFn: (json) => mapCodeRepo.insert(json),
        extractDataId: (data) => data.id
    });

    return (
        <div className="tw-relative tw-h-[886px]">
            <Allotment snap>
                <Allotment.Pane className={styles.leftPane}>
                    <$Table<MapCodeData>
                        enableRowSelection
                        columnsDef={defaultColumns}
                        columnsMeta={MapCodeMetaMap}
                        tableType={TableFormType}
                    />
                </Allotment.Pane>
                <Allotment.Pane visible={panelVisibility} className={styles.rightPane}>
                    <$TableForm columnsMeta={MapCodeMetaMap} TableFormType={TableFormType} />
                </Allotment.Pane>
            </Allotment>
        </div>
    );
};
