import { JSONType, Result, resultGuard } from 'models';
import { PurchaseData } from 'models/PurchaseTable/PuchaseTableData';
import { BaseQueryParams } from 'repository/type';
import { PurchaseDataSource, PurchaseStockDataSource } from './PurchaseDataSource';
import { PurchaseImportParams, PurchaseImportProps } from './type';
import { AutoFillParams } from 'components';

export class PurchaseRepository {
    constructor(private purchaseDS: PurchaseDataSource) {}

    public async getAutoFillData(data: AutoFillParams) {
        return this.purchaseDS.getAutoFill(data);
    }

    async getAll(params: BaseQueryParams) {
        return this.purchaseDS.getAll(params);
    }

    async delete(ids: string[], params: BaseQueryParams) {
        return this.purchaseDS.delete(ids, params);
    }

    async update(data: Partial<PurchaseData>, params: BaseQueryParams) {
        return this.purchaseDS.update(data, params);
    }

    async insertRow(json: JSONType) {
        return this.purchaseDS.insert(json);
    }

    public async importData(params: PurchaseImportParams): Promise<Result<void>> {
        return resultGuard(async () => {
            await this.purchaseDS.importData(params);
        });
    }

    public async setupImport(data: { [key: string]: any }) {
        return resultGuard(async () => {
            await this.purchaseDS.setupImport(data);
        });
    }

    public async getFixedHeader(): Promise<Result<string[]>> {
        return resultGuard(async () => {
            return this.purchaseDS.getFixedHeader();
        });
    }
}

export class PurchaseStockRepository extends PurchaseRepository {}
