import { Http } from 'api';
import _ from 'lodash';
import { PurchaseData } from 'models/PurchaseTable/PuchaseTableData';
import { BalanceParams, BaseQueryParams } from 'repository/type';
import { PurchaseImportProps } from './type';
import { AutoFillParams, SheetMeta } from 'components';
import { AutoFillData } from 'models/AutoFillData/AutoFillData';
import { BalanceImportDataSource } from '../BalanceImportDataSource';
import { JSONType } from 'models';

export class PurchaseDataSource extends BalanceImportDataSource<SheetMeta & BalanceParams> {
    path: string = 'purchase';
    app: string = 'balance';

    public async getAutoFill(data: AutoFillParams) {
        const respone = await this.http.instance.post(`/api/${this.app}/${this.path}/autofill/`, data);
        return new AutoFillData().fromPaging<AutoFillData>(respone.data);
    }

    async getAll(params: BaseQueryParams) {
        const respone = await this.http.instance.get(`/api/${this.app}/${this.path}/`, {
            params
        });

        return new PurchaseData().fromPaging<PurchaseData>(respone.data);
    }

    async delete(ids: string[], params: BaseQueryParams) {
        return this.http.instance.delete(`/api/${this.app}/${this.path}/bulk-delete/`, {
            data: { id_list: ids }
        });
    }

    async update<T>(data: Partial<PurchaseData>, params: BaseQueryParams) {
        return this.http.instance.put(
            `/api/${this.app}/${this.path}/${data.id}/`,
            _.omit(
                {
                    ...data,
                    ...params
                },
                'id'
            )
        );
    }

    async insert(data: JSONType) {
        return this.http.instance.post(`/api/${this.app}/${this.path}/`, data);
    }
}

export class PurchaseStockDataSource extends PurchaseDataSource {
    path: string = 'purchase-stock';
    app: string = 'fta';
}