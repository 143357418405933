import { Http } from 'api';
import _ from 'lodash';
import { CompanySettingData } from 'models';

export interface ICreateCompany {
    name: string;
    tax_code: string;
}

export class CompanyDataSource {
    constructor(public http: Http) {}

    public async getCompany() {
        const response = await this.http.instance.get(`/api/clients-api/companies/`);
        return new CompanySettingData().fromPaging<CompanySettingData>(response.data);
    }

    public async updateCompany(data: any, company_slug: string) {
        const bodyFormData = new FormData();

        Object.keys(data).map((e) => bodyFormData.append(e, data[e]));

        const response = await this.http.instance.patch(`/api/clients-api/companies/${company_slug}/`, bodyFormData, {
            headers: { 'content-type': 'multipart/form-data' }
        });

        return new CompanySettingData().fromJSON(response.data);
    }

    public async getCompanyDetail(company_slug: string) {
        const response = await this.http.instance.get(`/api/clients-api/companies/${company_slug}/`);
        return new CompanySettingData().fromJSON(response.data);
    }

    public async createCompany(data: ICreateCompany) {
        const response = await this.http.instance.post(`/api/clients-api/companies/`, data);
        return new CompanySettingData().fromJSON(response.data);
    }

    public async deleteCompany(company_slug: string) {
        const response = await this.http.instance.delete(`/api/clients-api/companies/${company_slug}/`);
        return response;
    }
}
