import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { Alert, Stack, styled, Typography } from '@mui/material';
import { memo, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { ImportExcelProps, UploadFileProps } from './type';

const ImportExcel = ({ setValue, error, selectedFile, setSelectedFile }: ImportExcelProps) => {
    const { getRootProps, getInputProps, acceptedFiles, isFocused, isDragAccept, isDragReject } = useDropzone({
        onDrop: (file) => {
            setValue('excel_file', file);
        }
    });
    const removeFile = () => {
        setSelectedFile(null);
        setValue('excel_file', null);
    };
    useEffect(() => {
        if (acceptedFiles[0]) setSelectedFile(acceptedFiles[0]);
    }, [acceptedFiles, setSelectedFile]);
    return (
        <div>
            <Stack alignItems="center" justifyContent="center" sx={{ pt: 2 }}>
                <UploadFile
                    {...getRootProps({
                        isfocused: isFocused ? 1 : 0,
                        isdragaccept: isDragAccept ? 1 : 0,
                        isdragreject: isDragReject ? 1 : 0
                    })}
                >
                    <input {...getInputProps()} />
                    <InsertDriveFileIcon fontSize="large" />
                    <Typography>Upload File Here</Typography>
                </UploadFile>
                {selectedFile ? (
                    <Alert severity="info" onClose={removeFile} sx={{ width: 300, mt: 1 }}>
                        <Typography noWrap sx={{ width: 220 }}>
                            {selectedFile.path}
                        </Typography>
                    </Alert>
                ) : (
                    <Typography sx={{ color: 'red', fontSize: 14 }}>{error ? error.message : null}</Typography>
                )}
            </Stack>
        </div>
    );
};
export default memo(ImportExcel);

const UploadFile = styled('div')<UploadFileProps>`
    flex: 1;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border 0.24s ease-in-out;
    box-shadow: '0px 8px 40px rgba(9, 44, 76, 0.16)';
    border-color: ${(props) => {
        if (props.isdragaccept) {
            return '#00e676';
        }
        if (props.isdragreject) {
            return '#ff1744';
        }
        if (props.isfocused) {
            return '#2196f3';
        }
        return '#eeeeee';
    }};
`;
