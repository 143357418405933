import { Cell, ColumnDef, Row, flexRender } from '@tanstack/react-table';
import { OutlinedButton } from 'components/Button';
import { SnackBarAlertEnum } from 'components/SnackBar';
import useSnackBar from 'components/SnackBar/UISnackbar';
import { ConnectionState } from 'hooks/Async';
import { useCallback, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { P, match } from 'ts-pattern';
import './$Table.scss';
import { CircularProgressBackdrop } from './CircularProgressBackdrop';
import { DraggableHeader } from './DraggableHeader';
import { TableType } from './TableForm';
import { TableContext } from './context';
import { columnResizeMode, useTableFactory } from './hooks';
import { ColumnMeta, checkbox_column_id } from './type';

type Props<T> = {
    columnsDef: ColumnDef<T, any>[];
    enableRowSelection?: boolean;
    columnsMeta: {
        [key: string]: ColumnMeta;
    };
    tableType?: TableType;
};

export const $Table = <T extends Object>(props: Props<T>) => {
    const { columnsDef, enableRowSelection, columnsMeta, tableType } = props;
    const { data, connectionState, error } = useContext(TableContext);
    const { showSnackBar } = useSnackBar();
    const navigate = useNavigate();

    const toastError = useCallback(() => {
        error && showSnackBar(error.meaning, SnackBarAlertEnum.error);
    }, [error]);

    useEffect(() => {
        if (connectionState === ConnectionState.hasError) {
            toastError();
        }
    }, [connectionState, error]);

    const { table } = useTableFactory({
        columnsDef,
        enableRowSelection,
        initData: data?.results,
        columnsMeta
    });

    return (
        <div className="tw-relative tw-h-full">
            {tableType === TableType.detail && (
                <OutlinedButton
                    className="tw-w-14 tw-align-bottom"
                    onClick={() => {
                        navigate(-1);
                    }}
                >
                    Back
                </OutlinedButton>
            )}
            {(connectionState === ConnectionState.waiting || connectionState === ConnectionState.none) && <CircularProgressBackdrop />}
            <div className="tw-h-full tw-overflow-x-scroll tw-overflow-y-scroll ">
                <table className="_table" style={{ width: table.getTotalSize() }}>
                    <thead className="tw-sticky tw-top-[-20px] tw-z-10">
                        {table.getHeaderGroups().map((headerGroup) => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => {
                                    return (
                                        <DraggableHeader
                                            key={header.id}
                                            header={header}
                                            table={table}
                                            columnResizeMode={columnResizeMode}
                                            showSort={header.id !== checkbox_column_id}
                                            isFirstColumn={header.id === 'code' ? true : false}
                                        />
                                    );
                                })}
                            </tr>
                        ))}
                    </thead>
                    <TableBody
                        isColumnResizing={!!table.getState().columnSizingInfo.isResizingColumn}
                        rows={table.getRowModel().rows}
                        tableType={tableType}
                    />
                </table>
            </div>
        </div>
    );
};

const TableBody = ({ rows, tableType }: { rows: Row<any>[]; isColumnResizing: boolean; tableType?: TableType }) => {
    const { rowSelection, tableState, setTableState, setNameInput, setUnitInput } = useContext(TableContext);
    const navigate = useNavigate();

    const getBgStyle = useCallback(
        (index: number) => {
            if (rowSelection[index]) return 'tw-bg-[#d9e1fa]';
            return match(tableState)
                .with({ state: 'update', row: { index: P.when((i) => i === index) } }, (_) => {
                    return 'tw-bg-[#d9e1fa]';
                })
                .otherwise(() => {
                    // return 'even:tw-bg-gray-200';
                });
        },
        [rowSelection, tableState]
    );

    const onSelect = useCallback(
        (row: Row<any>, cell: Cell<any, unknown>, index: number) => {
            if (cell.column.id === checkbox_column_id) return;
            match(tableState)
                .with({ state: 'update', row: { index: P.when((i) => i === index) } }, (_) => {
                    setTableState({ state: 'read' });
                })
                .otherwise(() => {
                    setTableState({
                        state: 'update',
                        row: {
                            index,
                            data: row.original
                        }
                    });
                });
        },
        [tableState]
    );
    if (tableType === TableType.ama || tableType === TableType.cancle) {
        return (
            <tbody>
                {rows.map((row, index) => {
                    return (
                        <tr
                            className={`${getBgStyle(index)} tw-cursor-pointer hover:tw-bg-[#d9e1fa]`}
                            key={row.id}
                            style={{ borderTop: '1px solid #E0E1E1' }}
                        >
                            {row.getVisibleCells().map((cell) => {
                                return (
                                    <td
                                        key={cell.id}
                                        onClick={() => {
                                            navigate(`/detail-page/${tableType}/${row.original.id}`);
                                        }}
                                        style={{
                                            width: cell.column.getSize()
                                        }}
                                        className={`tw-h-[32px] tw-truncate tw-px-3 tw-flex tw-items-center ${
                                            cell.column.id === 'code' ? `tw-justify-start` : `tw-justify-end`
                                        }`}
                                    >
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })}
            </tbody>
        );
    } else {
        return (
            <tbody>
                {rows.map((row, index) => {
                    return (
                        <tr
                            className={`${getBgStyle(index)} tw-cursor-pointer hover:tw-bg-[#d9e1fa]`}
                            key={row.id}
                            style={{ borderTop: '1px solid #E0E1E1' }}
                        >
                            {row.getVisibleCells().map((cell) => {
                                return (
                                    <td
                                        key={cell.id}
                                        onClick={() => {
                                            setNameInput(row.original.name);
                                            setUnitInput(row.original.unit);
                                            onSelect(row, cell, index);
                                        }}
                                        style={{
                                            width: cell.column.getSize()
                                        }}
                                        className={`tw-h-[32px] tw-truncate tw-px-3 tw-flex tw-items-center ${
                                            cell.column.id === 'code' ? `tw-justify-start` : `tw-justify-end`
                                        }`}
                                    >
                                        <div>{flexRender(cell.column.columnDef.cell, cell.getContext())}</div>
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })}
            </tbody>
        );
    }
};
