import { Http } from 'api';
import { AutoFillParams } from 'components';
import _ from 'lodash';
import { JSONType, MapCodeData } from 'models';
import { AutoFillData } from 'models/AutoFillData/AutoFillData';
import { BalanceNewImportDataSource } from '../BalanceImportDataSource';

import { MapCodeImportParams } from './type';
import { StatementQueryParams } from 'repository';

export class MapCodeDataSource extends BalanceNewImportDataSource<any> {
    path: string = 'map-code';

    constructor(public http: Http) {
        super(http);
    }

    public async getAutoFill(data: AutoFillParams) {
        const respone = await this.http.instance.post('/api/new-balance/map-code/autofill/', data);
        return new AutoFillData().fromPaging<AutoFillData>(respone.data);
    }

    public async getAll(params: StatementQueryParams) {
        const respone = await this.http.instance.get('/api/new-balance/map-code/', {
            params
        });

        return new MapCodeData().fromPaging<MapCodeData>(respone.data);
    }

    public async delete(ids: string[], params?: MapCodeImportParams) {
        return this.http.instance.delete('/api/new-balance/map-code/bulk-delete/', {
            data: { id_list: ids }
        });
    }

    public async update(data: Partial<MapCodeData>, params: MapCodeImportParams) {
        return;
        return this.http.instance.put(
            `/api/new-balance/map-code/${data.id}/`,
            _.omit(
                {
                    ...data,
                    ...params
                },
                'id'
            )
        );
    }

    public async insert(data: JSONType) {
        return this.http.instance.post('/api/new-balance/map-code/', data);
    }
}
