// third-party
import { combineReducers } from 'redux';

// project imports
import snackbarReducer from './slices/snackbar';
import menuReducer from './slices/menu';
import verificationReducer from './slices/verification';
import appReducer from './Global';
import { MenuProps } from '../types/menu';
import { SnackbarProps } from '@mui/material';
import hsCodeReducer from './slices/HsCode';
import Global from './Global';
import companyReducer from './slices/company';
import accountReducer from './accountReducer';
import { TypedUseSelectorHook, useSelector as useAppSelector } from 'react-redux';
// ==============================|| COMBINE REDUCER ||============================== //

export type State = { snackbar: SnackbarProps; menu: MenuProps; app: { loading: boolean; lang: string | null } };

const reducer = combineReducers({
    snackbar: snackbarReducer,
    menu: menuReducer,
    verify: verificationReducer,
    app: appReducer,
    HsCode: hsCodeReducer,
    global: Global,
    company: companyReducer,
    accountReducer
});
export const useSelector: TypedUseSelectorHook<ReturnType<typeof reducer>> = useAppSelector;
export default reducer;
