import HotTable from '@handsontable/react';
import { Chip } from '@mui/material';
import { Sheet } from 'components/Sheet';
import { RowObject } from 'handsontable/common';
import { ConnectionState } from 'hooks/Async';
import _ from 'lodash';
import { useContext, useEffect, useRef, useState } from 'react';
import { match } from 'ts-pattern';
import { Report15Context } from '../context';
import useStep4ViewModal from '../hooks/useStep4ViewModal';

const ModalStep4 = () => {
    const { setSubmitStep4, isSubmitStep4, setDisableSubmitBtn, year, setOpenModal } = useContext(Report15Context);
    const [tableData, setTableData] = useState<RowObject[]>([[], []]);

    const { submit, selectedHeaderMap, colsAsyncValue, header, fixedHeader } = useStep4ViewModal({
        tableData,
        setDisableSubmitBtn,
        year
    });
    const ref = useRef<HotTable | undefined>();

    useEffect(() => {
        if (isSubmitStep4) {
            submit();
            setSubmitStep4(false);
        }
    }, [isSubmitStep4]);

    return (
        <div className={'tw-relative tw-w-full tw-h-full tw-overflow-visible'}>
            {!_.isEmpty(fixedHeader) && (
                <div className="tw-flex-wrap">
                    {fixedHeader!.map((chip) => {
                        return (
                            <Chip
                                color={selectedHeaderMap[chip] ? 'primary' : undefined}
                                className={'tw-mr-4 tw-mb-2'}
                                key={chip}
                                label={chip}
                            />
                        );
                    })}
                </div>
            )}
            {match(colsAsyncValue)
                .with({ state: ConnectionState.hasData }, ({ data: columns }) => {
                    return (
                        <Sheet
                            height={320}
                            width={'auto'}
                            //@ts-ignore
                            ref={ref}
                            afterChange={(data) => {
                                const tableValues = ref.current?.hotInstance?.getData();
                                if (!tableValues) return;
                                setTableData(tableValues);
                            }}
                            rowHeaders={['Required header', 'Header title']}
                            rowHeaderWidth={200}
                            manualColumnResize
                            columns={columns}
                            data={tableData}
                            className={'tw-left-0 tw-right-0 tw-top-0 tw-bottom-0'}
                            colHeaders={header!.map((title) => {
                                return title
                                    .split('|')
                                    .filter((word) => !word.includes('Unnamed'))
                                    .join("<hr class='tw-border-1 tw-my-2 tw-border-gray-400'>");
                            })}
                        ></Sheet>
                    );
                })
                .otherwise(() => (
                    <></>
                ))}
        </div>
    );
};

export default ModalStep4;
