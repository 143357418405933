// third-party
import {configureStore} from '@reduxjs/toolkit';
import {useDispatch as useAppDispatch, useSelector as useAppSelector, TypedUseSelectorHook} from 'react-redux';

// project imports
import rootReducer from './reducer';
import storage from 'redux-persist/lib/storage'

import {persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER} from 'redux-persist'
// ==============================|| REDUX - MAIN STORE ||============================== //
const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['company']
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: {
            ignoredActions:[FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
        }
    })
});

const persistor = persistStore(store)

export type RootState = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof store.dispatch;

const {dispatch} = store;

const useDispatch = () => useAppDispatch<AppDispatch>();
const useSelector: TypedUseSelectorHook<RootState> = useAppSelector;

export {store, dispatch, useSelector, useDispatch, persistor};
