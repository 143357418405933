import { JsonObject, JsonProperty } from 'json2typescript';
import { JsonSerializable } from 'models';

export interface InvoiceImportDataInterface {
    id: string;
    code: string;
    name: string;
    total: number;
    hs: number;
    nt_value: number;
    origin_criteria: string;
    invoice_no: string;
    bill_date: string;
}

@JsonObject('InvoiceImportData')
export class InvoiceImportData extends JsonSerializable<InvoiceImportData> implements InvoiceImportDataInterface {
    protected get classRef(): new () => InvoiceImportData {
        return InvoiceImportData;
    }
    @JsonProperty('id', String, false)
    id!: string;

    @JsonProperty('code', String, false)
    code!: string;

    @JsonProperty('name', String, false)
    name!: string;

    @JsonProperty('total', Number, false)
    total!: number;

    @JsonProperty('hs', Number, false)
    hs!: number;

    @JsonProperty('nt_value', Number, false)
    nt_value!: number;

    @JsonProperty('origin_criteria', String, false)
    origin_criteria!: string;

    @JsonProperty('invoice_no', String, false)
    invoice_no!: string;

    @JsonProperty('bill_date', String, false)
    bill_date!: string;
}
