import { LocalAuthDataSource, remoteConfig } from 'repository';
import axios, { AxiosInstance } from 'axios';
import queryString from 'query-string';
export const HsApiUrl = 'https://dev-solr.eximhow.com/solr';
export const Apiurl = 'https://dev-be.eximhow.com/';

const buildAxiosInstance = (options?: {
    baseURL?: string;
    extractResponseData?: boolean;
    getToken: () => string | null;
    tokenType?: string;
}): AxiosInstance => {
    axios.defaults.xsrfCookieName = 'csrftoken';
    axios.defaults.xsrfHeaderName = 'X-CSRFToken';
    const { baseURL, extractResponseData = true } = options ?? {};
    const axiosClient = axios.create({
        baseURL,
        headers: {
            'content-type': 'application/json'
        },
        paramsSerializer: (params) => queryString.stringify(params)
    });
    axiosClient.interceptors.request.use(async (config) => {
        const token = options?.getToken && options.getToken();
        const tokenType = options?.tokenType ?? 'Bearer';
        if (token && config.headers) config.headers.Authorization = `${tokenType} ${token}`;
        return config;
    });
    axiosClient.interceptors.response.use(
        (response) => {
            if (extractResponseData) {
                if (response && response.data) {
                    return response.data;
                }
            }
            return response;
        },
        (error) => {
            throw error;
        }
    );
    return axiosClient;
};

export default buildAxiosInstance({
    getToken: () => localStorage.getItem('token')
});

export abstract class Http {
    constructor(public instance: AxiosInstance, public localAuthDS: LocalAuthDataSource) {}
}

export const hsHttpInstance = buildAxiosInstance({
    baseURL: HsApiUrl,
    extractResponseData: true,
    getToken: () => 'c29scnVzZXI6RVhJTXNvbHJwQHNzdzByZA==',
    tokenType: 'Basic'
});

export class EximhowHttp implements Http {
    instance: AxiosInstance;

    constructor(public localAuthDS: LocalAuthDataSource) {
        this.instance = buildAxiosInstance({
            baseURL: Apiurl,
            extractResponseData: false,
            getToken: () => localAuthDS.token
        });
    }
}

// export class NgRokHttp implements Http {
//     instance: AxiosInstance;
//
//     constructor(public localAuthDS: LocalAuthDataSource) {
//         this.instance = buildAxiosInstance({
//             baseURL: remoteConfig.getDevEndpoint(),
//             extractResponseData: false,
//             getToken: () => localAuthDS.token
//         });
//     }
//
//     updateBaseUrl(baseURL: string) {
//         this.instance.defaults.baseURL = baseURL;
//     }
// }
