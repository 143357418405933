import { ICompanySetting, resultGuard } from 'models';
import { CompanyDataSource, ICreateCompany } from './CompanyDataSource';

export class CompanyRepository {
    constructor(private datasource: CompanyDataSource) {}

    public async getCompany() {
        return this.datasource.getCompany();
    }

    public async updateCompany(data: Partial<ICompanySetting>, company_slug: string) {
        return resultGuard(async () => await this.datasource.updateCompany(data, company_slug));
    }

    public async getCompanyDetail(company_slug: string) {
        return resultGuard(async () => await this.datasource.getCompanyDetail(company_slug));
    }

    public async createCompany(data: ICreateCompany) {
        return this.datasource.createCompany(data);
    }

    public async deleteCompany(company_slug: string) {
        return await this.datasource.deleteCompany(company_slug);
    }
}
