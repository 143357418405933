import { JsonObject, JsonProperty } from 'json2typescript';
import { JsonSerializable } from '../serialization';

export interface ISellData {
    id: string;
    created_at: string;
    code: string;
    bill_no: string;
    name: string;
    quantity: number;
    balance_id: number;
}

@JsonObject('SellData')
export class SellData extends JsonSerializable<SellData> implements ISellData {
    protected get classRef(): new () => SellData {
        return SellData;
    }

    @JsonProperty('id', String, false)
    id!: string;

    @JsonProperty('created_at', String, false)
    created_at: string = '';

    @JsonProperty('code', String, false)
    code!: string;

    @JsonProperty('bill_no', String, false)
    bill_no!: string;

    @JsonProperty('name', String, false)
    name!: string;

    @JsonProperty('quantity', Number, false)
    quantity!: number;

    @JsonProperty('balance_id', Number, false)
    balance_id: number = 0;
}
