import {
    ColumnDef,
    ColumnOrderState,
    OnChangeFn,
    PaginationState,
    RowSelectionState,
    getCoreRowModel,
    useReactTable
} from '@tanstack/react-table';
import produce from 'immer';
import _ from 'lodash';
import { useContext, useEffect, useMemo, useState } from 'react';
import { IndeterminateCheckbox } from '../IndeterminateCheckbox';
import { TableContext } from '../context';
import { ColumnMeta, checkbox_column_id } from '../type';
export const columnResizeMode = 'onChange';

export const useTableFactory = <T extends Object>({
    enableRowSelection,
    columnsDef,
    initData,
    columnsMeta
}: {
    columnsDef: ColumnDef<T, any>[];
    enableRowSelection?: boolean;
    initData?: T[];
    columnsMeta: {
        [key: string]: ColumnMeta;
    };
}) => {
    const {
        setTable,
        sorting,
        setSorting,
        setPagingState,
        autoResetPageIndex,
        rowSelection,
        setRowSelection,
        pagingState,
        addColumn,
        setColumnExternal,
        delColumn
    } = useContext(TableContext);

    const [data, setData] = useState<T[]>([]);
    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: pagingState.pageSize
    });
    const pagination = useMemo(
        () => ({
            pageIndex,
            pageSize
        }),
        [pageIndex, pageSize]
    );
    function AddColumnRefactor(column: string) {
        const initMeta: ColumnMeta = { inputType: 'text' };
        const value: any = new Object();
        value[`${column}`] = initMeta;
        return value;
    }

    useEffect(() => {
        if (addColumn) {
            setColumnExternal((prev: null | { [key: string]: ColumnMeta }) => {
                if (prev === null) return AddColumnRefactor(addColumn);
                else {
                    return { ...prev, ...AddColumnRefactor(addColumn) };
                }
            });
        }
    }, [addColumn]);

    useEffect(() => {
        if (initData) setData(_.cloneDeep(initData));
    }, [initData]);

    const columns = useMemo<ColumnDef<T, any>[]>(() => {
        if (!enableRowSelection) return columnsDef;
        return produce(columnsDef, (draft) => {
            draft.unshift({
                minSize: 68,
                maxSize: 68,
                id: checkbox_column_id,
                header: ({ table }) => (
                    <div className="tw-flex tw-items-center tw-justify-center tw-h-full">
                        <IndeterminateCheckbox
                            indeterminate={table.getIsSomeRowsSelected()}
                            onChange={table.getToggleAllRowsSelectedHandler()}
                            checked={table.getIsAllRowsSelected()}
                        />
                    </div>
                ),
                cell: ({ row }) => (
                    <div className="tw-flex tw-items-center tw-justify-center tw-h-full">
                        <IndeterminateCheckbox
                            checked={row.getIsSelected()}
                            disabled={!row.getCanSelect()}
                            indeterminate={row.getIsSomeSelected()}
                            onChange={row.getToggleSelectedHandler()}
                        />
                    </div>
                )
            });
        });
    }, [columnsDef, enableRowSelection]);

    const [columnOrder, setColumnOrder] = useState<ColumnOrderState>(columns.map((column) => column.id as string));

    useEffect(() => {
        setPagingState(pagination);
    }, [pagination]);

    // const updateData = useCallback(
    //   (rowIndex: number, columnId: number, value: T) => {
    //     let nextTableState: TableState<T> = tableState
    //     const nextData = produce(data, (oldTableDraft) => {
    //       //@ts-ignore
    //       oldTableDraft[rowIndex][columnId] = value
    //       //@ts-ignore
    //       nextTableState = match(nextTableState)
    //         .with({ state: 'read' }, (_) => {
    //           return {
    //             state: 'edit',
    //             draft: { [rowIndex]: oldTableDraft[rowIndex] },
    //           } as TableState<T>
    //         })
    //         .with({ state: 'edit' }, ({ draft }) => {
    //           return {
    //             state: 'edit',
    //             draft: produce(draft, (draft2) => {
    //               draft2[rowIndex] = oldTableDraft[rowIndex]
    //             }),
    //           } as TableState<T>
    //         })
    //         .exhaustive()
    //     })
    //     setData(nextData)
    //     nextTableState = match(nextTableState)
    //       .with({ state: 'edit' }, (nextTableState) => {
    //         // filter out the updating value have the same object with initial data
    //         // prevent send redundant update
    //         const next = produce(nextTableState, ({ state, draft }) => {
    //           if (!initData) return
    //           for (const [index, value] of Object.entries(draft)) {
    //             if (_.isEqual(value, initData[Number.parseInt(index, 10)])) {
    //               delete draft[Number.parseInt(index, 10)]
    //             }
    //           }
    //         })
    //         if (_.isEmpty(next.draft)) return { state: 'read' } as TableState<T>
    //         return next
    //       })
    //       .otherwise(() => nextTableState)
    //     setTableState(nextTableState)
    //   },
    //   [data, tableState, initData]
    // )

    const table = useReactTable({
        data: data ?? [],
        columns,
        onRowSelectionChange: setRowSelection as OnChangeFn<RowSelectionState>,
        state: {
            columnOrder,
            rowSelection,
            pagination,
            sorting
        },
        defaultColumn,
        onSortingChange: setSorting,
        columnResizeMode,
        manualPagination: true,
        onPaginationChange: setPagination,
        getCoreRowModel: getCoreRowModel(),
        onColumnOrderChange: setColumnOrder,
        manualSorting: true,
        autoResetPageIndex,
        meta: {
            columnsMeta
            // updateData: (rowIndex: number, columnId: number, value: T) => {
            //   // Skip page index reset until after next rerender
            //   skipAutoResetPageIndex()
            //   updateData(rowIndex, columnId, value)
            // },
        }
    });

    useEffect(() => {
        setTable(table);
    }, [table]);

    return {
        table,
        pagination
    };
};

const defaultColumn: Partial<ColumnDef<any>> = {
    minSize: 0

    // cell: ({ getValue, row: { index }, column: { id }, table }) => {
    //     const initialValue = getValue();
    //     const [value, setValue] = useState<string | unknown>(initialValue);

    //     const columnMeta = useMemo<ColumnMeta | null>(() => {
    //         const metaMap = _.get(table, 'options.meta.columnsMeta');
    //         if (_.isNil(metaMap)) return null;
    //         return _.get(metaMap, id);
    //     }, [table]);

    //     const debounced = useDebouncedCallback((value) => {
    //         let nextValue = value;
    //         if (columnMeta?.inputType === 'number') {
    //             nextValue = _.toNumber(nextValue);
    //         }

    //         const updateData = _.get(table, 'options.meta.updateData');
    //         if (updateData) updateData(index, id, nextValue);
    //     }, 300);
    //     const captured = useRef(value);

    //     useEffect(() => {
    //         captured.current = value;
    //     }, [value]);

    //     // If the initialValue is changed external, sync it up with our state
    //     useEffect(() => {
    //         setValue(`${initialValue}`);
    //     }, [initialValue]);

    //     return <span className="tw-px-[16px] tw-py-[4px] tw-break-all tw-line-clamp-2 tw-text-clip">{value as string}</span>;

    // return (
    //   <input
    //     type={columnMeta?.inputType}
    //     value={value as string}
    //     onChange={(e) => {
    //       setValue(e.target.value)
    //       debounced(e.target.value)
    //     }}
    //   />
    // )
};
