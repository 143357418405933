import { Http } from 'api';
import { CompanySettingData } from 'models';
export class SideBarComapnyDS {
    constructor(public http: Http) {}

    public async getAll() {
        const response = await this.http.instance.get('/clients-api/companies/');
        return new CompanySettingData().fromJSON(response.data);
    }
}
