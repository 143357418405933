import { JsonObject, JsonProperty } from 'json2typescript';
import { JsonSerializable } from 'models';
import type { BangKeExcelDataPriceInterface } from './BangKeExcelData';

export interface BangKeHistoryDataRequestInterface {
    id: string;
    option: string;
    criteria: string;
    price: BangKeExcelDataPriceInterface;
}

@JsonObject('BangKeHistoryDataRequest')
export class BangKeHistoryDataRequest extends JsonSerializable<BangKeHistoryDataRequest> implements BangKeHistoryDataRequestInterface {
    protected get classRef(): new () => BangKeHistoryDataRequest {
        return BangKeHistoryDataRequest;
    }

    @JsonProperty('id', String, false)
    id!: string;

    @JsonProperty('option', String, false)
    option!: string;

    @JsonProperty('criteria', String, false)
    criteria!: string;

    @JsonProperty('price', Object, true)
    price: BangKeExcelDataPriceInterface = {
        type: '',
        value: 0,
        percent: 0
    };
}

export interface BangKeHistoryDataResponseInterface {
    ecus_id: string;
    criteria: string;
    option: string;
    price_type: string;
    price_value: number;
    price_percent: number;
}

@JsonObject('BangKeHistoryDataResponse')
export class BangKeHistoryDataResponse extends JsonSerializable<BangKeHistoryDataResponse> implements BangKeHistoryDataResponseInterface {
    protected get classRef(): new () => BangKeHistoryDataResponse {
        return BangKeHistoryDataResponse;
    }

    @JsonProperty('ecus_id', String, false)
    ecus_id!: string;

    @JsonProperty('option', String, false)
    option!: string;

    @JsonProperty('criteria', String, false)
    criteria!: string;

    @JsonProperty('price_type', String, true)
    price_type: string = '';

    @JsonProperty('price_value', Number, true)
    price_value: number = 0;

    @JsonProperty('price_percent', Number, true)
    price_percent: number = 0;
}
