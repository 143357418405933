import { JsonObject, JsonProperty } from 'json2typescript';
import { JsonSerializable } from '../serialization';
export interface IScrapData {
    id: string;
    code: string;
    name?: string;
    unit?: string;
    begin_value?: number;
    begin_value_num?: number;
    take_in?: number;
    take_in_num: number;
    take_out?: number;
    take_out_num?: number;
    end_value?: number;
    end_value_num?: number;
}

@JsonObject('ScrapData')
export class ScrapData extends JsonSerializable<ScrapData> implements IScrapData {
    protected get classRef(): new () => ScrapData {
        return ScrapData;
    }

    static empty(): ScrapData {
        return new ScrapData().fromJSON({
            id: new Date().getMilliseconds(),
            code: '',
            name: '',
            unit: '',
            begin_value: 0,
            begin_value_num: 0,
            take_in: 0,
            take_in_num: 0,
            take_out: 0,
            take_out_num: 0,
            end_value: 0,
            end_value_num: 0
        });
    }

    @JsonProperty('id', String, false)
    id!: string;

    @JsonProperty('code', String, false)
    code!: string;

    @JsonProperty('name', String, true)
    name?: string;

    @JsonProperty('unit', String, true)
    unit?: string;

    @JsonProperty('begin_value', Number, true)
    begin_value?: number;

    @JsonProperty('begin_value_num', Number, true)
    begin_value_num?: number;

    @JsonProperty('take_in', Number, true)
    take_in?: number;

    @JsonProperty('take_in_num', Number, false)
    take_in_num!: number;

    @JsonProperty('take_out', Number, true)
    take_out?: number;

    @JsonProperty('take_out_num', Number, true)
    take_out_num?: number;

    @JsonProperty('end_value', Number, true)
    end_value?: number;

    @JsonProperty('end_value_num', Number, false)
    end_value_num?: number;
}
