import { Http } from 'api';
import { AutoFillParams, SheetMeta } from 'components';
import _ from 'lodash';
import { JSONType } from 'models';
import { SellData, SellImportSetup } from 'models';
import { AutoFillData } from 'models/AutoFillData/AutoFillData';
import { BalanceParams, SellQueryParams } from 'repository/type';
import { BalanceImportDataSource } from '../BalanceImportDataSource';

export class SellDataSource extends BalanceImportDataSource<SheetMeta & BalanceParams> {
    path: string = 'sell';

    public async getAll(params: SellQueryParams) {
        const response = await this.http.instance.get('/api/balance/sell/', {
            params
        });
        return new SellData().fromPaging<SellData>(response.data);
    }

    public async update(data: Partial<SellData>, params: SellQueryParams) {
        return this.http.instance.put(
            `/api/balance/sell/${data.id}/`,
            _.omit(
                {
                    ...data,
                    ...params
                },
                'id'
            )
        );
    }

    public async delete(ids: string[]) {
        return this.http.instance.delete(`/api/balance/sell/bulk-delete/`, {
            data: { id_list: ids }
        });
    }

    public async insert(data: JSONType) {
        return this.http.instance.post(`/api/balance/sell/`, data);
    }

    public async getAutoFill(data: AutoFillParams) {
        const respone = await this.http.instance.post('/api/balance/sell/autofill/', data);
        return new AutoFillData().fromPaging<AutoFillData>(respone.data);
    }
}
