import {createRoot} from 'react-dom/client';

// third party
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';

// project imports
import App from 'App';
import './App.scss';
import {BASE_PATH} from 'config';
import {store, persistor} from 'store';
import * as serviceWorker from 'serviceWorker';
import reportWebVitals from 'reportWebVitals';
import {ConfigProvider} from 'contexts/ConfigContext';

// style + assets
import {ContainerProvider} from 'brandi-react';
import {container as containerDI} from 'di/di';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {PersistGate} from 'redux-persist/integration/react'
// ==============================|| REACT DOM RENDER  ||============================== //

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <ConfigProvider>
                <ContainerProvider container={containerDI}>
                    <BrowserRouter basename={BASE_PATH}>
                        <DndProvider backend={HTML5Backend}>
                            <App/>
                        </DndProvider>
                    </BrowserRouter>
                </ContainerProvider>
            </ConfigProvider>
        </PersistGate>
    </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
