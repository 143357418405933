import { Exception } from 'models';
import { useCallback, useState } from 'react';

export enum ConnectionState {
    none = 'none',
    waiting = 'waiting',
    hasData = 'hasData',
    hasError = 'hasError'
}

interface IUseAsyncReturned<T, Args = undefined> {
    tableData: AsyncValue<T>;
    execute: (args: Args) => Promise<void>;
    refresh: (args: Args) => Promise<void>;
    reset: () => void;
}

export type AsyncValue<T> =
    | { state: ConnectionState.none }
    | { state: ConnectionState.waiting }
    | { state: ConnectionState.hasData; data: T }
    | { state: ConnectionState.hasError; error: Exception };

export function useAsync<T, Args = undefined>(
    asyncFunction: (args: Args) => Promise<T>,
    option?: { initialValue?: AsyncValue<T> }
): IUseAsyncReturned<T, Args> {
    const { initialValue } = option ?? {};
    const [tableData, setData] = useState<AsyncValue<T>>(
        initialValue ?? {
            state: ConnectionState.none
        }
    );

    const reset = () => {
        setData({ state: ConnectionState.none });
    };

    const fetch = (promiseFunction: (args: Args) => Promise<T>, args: Args) => {
        setData({ state: ConnectionState.waiting });
        return promiseFunction(args)
            .then((data: T) => {
                setData({ state: ConnectionState.hasData, data: data });
            })
            .catch((error) => {
                setData({
                    state: ConnectionState.hasError,
                    error: Exception.parse(error)
                });
            });
    };

    const exec = useCallback(
        (args: Args) => {
            return fetch(asyncFunction, args);
        },
        [asyncFunction]
    );

    let refresh = (args: Args) => fetch(asyncFunction, args);

    return {
        tableData,
        execute: exec,
        refresh,
        reset
    };
}
