import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import produce from 'immer';
import _ from 'lodash';
import moment from 'moment';
import { forwardRef, useContext, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ContainedButton, OutlinedButton } from '../Button/Button';
import { TableContext } from '../Table';
import './style.css';
import { ToolTip } from 'ui-component/tooltip/tooltip';

export const DateRangePicker = () => {
    const { setExtQ } = useContext(TableContext);
    const [startDate, setStartDate] = useState<Date | undefined>();
    const [endDate, setEndDate] = useState<Date | undefined>();
    const onChange = (dates: [Date, Date]) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    useEffect(() => {
        if (startDate && !endDate) return;
        const extQ = produce<{
            registered_date_from?: string;
            registered_date_to?: string;
        }>({}, (draft) => {
            if (!!startDate) {
                draft.registered_date_from = moment(startDate).format('YYYY-MM-DD');
            }
            if (!!endDate) {
                draft.registered_date_to = moment(endDate).format('YYYY-MM-DD');
            }
        });
        setExtQ(extQ);
    }, [startDate, endDate]);

    return (
        <DatePicker
            isClearable={true}
            popperPlacement="bottom-start"
            selected={startDate}
            onChange={onChange}
            startDate={startDate}
            endDate={endDate}
            customInput={<CustomInput />}
            selectsRange
        />
    );
};

const CustomInput = forwardRef(({ value, onClick }: any, ref: any) => {
    if (_.isEmpty(value)) {
        return (
            <ToolTip title="Date">
                <OutlinedButton onClick={onClick} ref={ref}>
                    <CalendarMonthIcon />
                </OutlinedButton>
            </ToolTip>
        );
    }
    return (
        <ContainedButton className="tw-w-full" onClick={onClick} ref={ref}>
            <span className="tw-truncate tw-line-clamp-1">{value}</span>
        </ContainedButton>
    );
});
