import { Http } from 'api';
import { EcusStockData } from 'models/EcusStock/EcusStockData';
import { BaseQueryParams } from 'repository/type';

export class EcusStockDataSource {
    constructor(public http: Http) {}

    async getAll(params: BaseQueryParams) {
        const response = await this.http.instance.get('/api/fta/ecus-stock/', { params });
        return new EcusStockData().fromPaging(response.data);
    }
}
