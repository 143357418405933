import { EndingInventoryDataProduction, EndingInventorySetupDTOProduction, JSONType, Result, resultGuard } from 'models';
import { EndingInventoryQueryParamsProduction } from '../type';
import { EndingInventoryDataSourceProduction } from './EndingInventoryDataSourceProduction';
import { IEndingInventoryImportDTOProduction } from './type';
import { AutoFillParams } from 'components';

export class EndingInventoryRepositoryProduction {
    constructor(private datasource: EndingInventoryDataSourceProduction) {}

    public async getAll(params: EndingInventoryQueryParamsProduction) {
        return this.datasource.getAll(params);
    }

    public async deleteRows(ids: string[]) {
        return this.datasource.delete(ids);
    }

    public async updateRow(row: Partial<EndingInventoryDataProduction>, params: EndingInventoryQueryParamsProduction) {
        return this.datasource.update(row, params);
    }

    public async insertRow(row: JSONType) {
        return this.datasource.insert(row);
    }

    public async importData(value: IEndingInventoryImportDTOProduction): Promise<Result<void>> {
        return resultGuard(async () => {
            await this.datasource.importData(value);
        });
    }

    public async setupImport(data: { [key: string]: any }) {
        return resultGuard(async () => {
            await this.datasource.setupImport(data);
        });
    }

    public async getFixedHeader(): Promise<Result<string[]>> {
        return resultGuard(async () => {
            return this.datasource.getFixedHeader();
        });
    }
    public async getAutoFillData(data: AutoFillParams) {
        return this.datasource.getAutoFill(data);
    }
}
