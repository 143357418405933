import { TablePagination as MuiPagination } from '@mui/material';
import { useContext, useEffect, useMemo } from 'react';
import { TableContext } from '../context';
import { ConnectionState } from 'hooks/Async';

export const $TablePaging = () => {
    const { pagingController, pagingState, tableState, dataCount, connectionState } = useContext(TableContext);

    const isDisabled = useMemo(
        () => tableState.state === 'edit' || connectionState === ConnectionState.waiting || connectionState === ConnectionState.none,
        [tableState.state, connectionState]
    );

    return (
        <MuiPagination
            SelectProps={{
                disabled: isDisabled
            }}
            backIconButtonProps={
                isDisabled
                    ? {
                          disabled: isDisabled
                      }
                    : undefined
            }
            nextIconButtonProps={
                isDisabled
                    ? {
                          disabled: isDisabled
                      }
                    : undefined
            }
            component="div"
            count={dataCount}
            page={pagingState.pageIndex}
            onPageChange={(_, page) => {
                pagingController.setPageIndex(page);
            }}
            rowsPerPage={pagingState.pageSize}
            onRowsPerPageChange={(event) => {
                pagingController.setPageSize(parseInt(event.target.value, 10));
            }}
        />
    );
};
