import { JsonObject, JsonProperty } from 'json2typescript';
import { JsonSerializable } from 'models/serialization';

export interface IEndingInventorySetupDTOWH {
    company_slug: string;
    code: string;
    quantity: string;
}

@JsonObject('EndingInventorySetupDTOWH')
export class EndingInventorySetupDTOWH extends JsonSerializable<EndingInventorySetupDTOWH> implements IEndingInventorySetupDTOWH {
    protected get classRef(): new () => EndingInventorySetupDTOWH {
        return EndingInventorySetupDTOWH;
    }

    @JsonProperty('company_slug', String, false)
    company_slug!: string;

    @JsonProperty('code', String, true)
    code: string = '';

    @JsonProperty('quantity', String, false)
    quantity!: string;
}
