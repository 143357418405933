import { Button } from '@mui/material';
import { Box } from '@mui/system';
import { FormTypeEnum } from 'components/BalanceTable';
import { OutlinedButton } from 'components/Button';
import { SnackBarAlertEnum } from 'components/SnackBar';
import useSnackBar from 'components/SnackBar/UISnackbar';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { InvoiceWordContext } from '../../InvoiceWordProvider';
import { InvoiceModalContext } from '../InvoiceModalProvider';

function InvoiceWordStepButton() {
    const { showSnackBar } = useSnackBar();
    const [submit, setSubmit] = useState(false);
    const { activeStep, fieldState, wordFormType, setActiveStep, isMissing, setMarkPackage, setRowPerPage } =
        useContext(InvoiceWordContext);
    const { revertData, setSubmit: setWordProviderSubmit, submitStep2, ResetDefault } = useContext(InvoiceModalContext);

    const steps = useMemo(() => {
        if (wordFormType === FormTypeEnum.VJ || wordFormType === FormTypeEnum.AJ) return ['Step 1', 'Step 2', 'Step 3'];
        else return ['Step 1', 'Step 2'];
    }, [wordFormType]);

    const handleNext = () => {
        setActiveStep((prevActiveStep: any) => prevActiveStep + 1);
    };

    const handleBack = useCallback(() => {
        if (activeStep === 1) {
            setMarkPackage('');
            setRowPerPage('');
            ResetDefault();
        }
        setActiveStep((prevActiveStep: any) => prevActiveStep - 1);
    }, [activeStep]);

    const handleReset = () => {
        setActiveStep(0);
    };

    const stepDisable = useMemo(() => {
        let result = true;
        switch (wordFormType) {
            default:
                if (activeStep === 0) {
                    if (!isMissing) result = false;
                }
                break;
        }

        return result;
    }, [activeStep, fieldState, wordFormType, revertData, isMissing]);

    useEffect(() => {
        if (submit) {
            setWordProviderSubmit(true);
            showSnackBar('Submit success', SnackBarAlertEnum.success);
        }
    }, [submit]);

    useEffect(() => {
        if (submitStep2) {
            showSnackBar('Generate Completed!', SnackBarAlertEnum.success);
        }
    }, [submitStep2]);
    return (
        <>
            {activeStep === steps.length ? (
                <>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: '6px' }}>
                        <Button onClick={handleReset}>Reset</Button>
                    </Box>
                </>
            ) : (
                <>
                    <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                        <Button color="inherit" disabled={activeStep === 0 || !submitStep2} onClick={handleBack}>
                            Back
                        </Button>
                        <Box>
                            {wordFormType !== FormTypeEnum.VJ && wordFormType !== FormTypeEnum.AJ && activeStep === 1 && (
                                <OutlinedButton
                                    disabled={!submitStep2}
                                    onClick={() => {
                                        setSubmit((prev) => !prev);
                                    }}
                                >
                                    Download
                                </OutlinedButton>
                            )}
                        </Box>
                        <Button onClick={handleNext} disabled={stepDisable}>
                            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                        </Button>
                    </Box>
                </>
            )}
        </>
    );
}

export default InvoiceWordStepButton;
