import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { Allotment } from 'allotment';
import 'allotment/dist/style.css';
import _ from 'lodash';
import { useContext, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import styles from '../TableEcusNext/styles.module.css';
import {
    $Table,
    $TableForm,
    AutoFillParams,
    ColumnMeta,
    ColumnWidth,
    TableContext,
    TableType,
    useManipulateTable,
    useQueryTable
} from 'components/Table';
import { useScrapRepo } from 'di';
import { PagingData, ScrapData } from 'models';
import {BaseQueryParams} from 'repository';
import { useAsync, ConnectionState } from 'hooks/Async';
import { AutoFillData } from 'models/AutoFillData/AutoFillData';
import {useCompanyFolderSlug} from "../../BalanceImport";

const ScrapColumnMetaMap: { [key: string]: ColumnMeta } = {
    code: {
        inputType: 'text',
        enableSorting: true
    },
    name: {
        inputType: 'text'
    },
    unit: {
        inputType: 'text'
    },
    begin_value: {
        inputType: 'number'
    },
    begin_value_num: {
        inputType: 'number',
        enableSorting: true
    },
    take_in: {
        inputType: 'number'
    },
    take_in_num: {
        inputType: 'number'
    },
    take_out: {
        inputType: 'number'
    },
    take_out_num: {
        inputType: 'number'
    },
    end_value: {
        inputType: 'number'
    },
    end_value_num: {
        inputType: 'number'
    }
};
const TableFormType: TableType = TableType.scrapNg;

export const TableScrapNext = () => {
    //const intl = useIntl();
    const params : BaseQueryParams = useCompanyFolderSlug()
    const scrapRepository = useScrapRepo();
    const { panelVisibility, codeLabel, nameInput, unitInput, setlistAutoFillName, setlistAutoFillUnit } = useContext(TableContext);
    const { execute, tableData } = useAsync<PagingData<AutoFillData>, any>(() => scrapRepository.getAutoFillData(autoFillParams));

    const autoFillParams: AutoFillParams = useMemo(() => {
        const result = {
            code: codeLabel ?? '',
            select: ['name', 'unit'],
            filter: {},
            company_slug: params.company_slug,
            folder_slug: params.folder_slug
        };
        if (nameInput) {
            return {
                ...result,
                filter: { name: nameInput }
            };
        } else if (unitInput) {
            return {
                ...result,
                filter: { unit: unitInput }
            };
        } else {
            return result;
        }
    }, [codeLabel, nameInput, unitInput]);

    useEffect(() => {
        if (codeLabel) {
            execute('');
        }
    }, [codeLabel, autoFillParams]);

    useEffect(() => {
        if (tableData && tableData.state === ConnectionState.hasData) {
            tableData.data.results.map((item) => {
                setlistAutoFillName(item.name);
                setlistAutoFillUnit(item.unit);
            });
        }
    }, [tableData]);
    const defaultColumns = useMemo<ColumnDef<ScrapData, any>[]>(() => {
        const columnHelper = createColumnHelper<ScrapData>();
        return _.toPairs(ScrapColumnMetaMap).map(([key, value]) =>
            // @ts-ignore
            columnHelper.accessor(key, {
                id: key,
                minSize: ColumnWidth.ssm,
                size: key === 'name' || key === 'item_name' ? ColumnWidth.xxl : ColumnWidth.sm,
                enableSorting: value.enableSorting ?? false,
                cell: (info) => <div className={`tw-pr-[8px]`}>{info.getValue() as React.ReactNode}</div>,
                header: () => <div className={styles.header}>{key}</div>
            })
        );
    }, []);

    useQueryTable({
        params,
        fetchFn: (params) => scrapRepository.getAll(params)
    });
    useManipulateTable<ScrapData, BaseQueryParams>({
        args: params,
        updateFn: (row, params) => scrapRepository.updateRow(row, params),
        deleteFn: (ids) => scrapRepository.deleteRows(ids),
        insertFn: (json) => scrapRepository.insertRow(json),
        extractDataId: (data) => data.id
    });

    return (
        <>
            <div className="tw-relative tw-h-[886px]">
                <Allotment snap>
                    <Allotment.Pane className={styles.leftPane}>
                        <$Table<ScrapData>
                            enableRowSelection
                            columnsDef={defaultColumns}
                            columnsMeta={ScrapColumnMetaMap}
                            tableType={TableFormType}
                        />
                    </Allotment.Pane>
                    <Allotment.Pane visible={panelVisibility} className={styles.rightPane}>
                        <$TableForm columnsMeta={ScrapColumnMetaMap} TableFormType={TableFormType} />
                    </Allotment.Pane>
                </Allotment>
            </div>
        </>
    );
};
