import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'store';

// material-ui
import { Box, Button, Divider, FormHelperText, Grid, Stack, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// project imports
import Logo from 'ui-component/Logo';
import BackgroundPattern2 from 'ui-component/cards/BackgroundPattern2';
import AnimateButton from 'ui-component/extended/AnimateButton';
import AuthCardWrapper from '../AuthCardWrapper';
import AuthWrapper from '../AuthWrapper';
import AuthCodeVerification from './auth-forms/AuthCodeVerification';

// assets
import imgMain from 'assets/images/auth/img-login.jpg';

//repository
import useAuth from 'hooks/useAuth';

// ===========================|| AUTH2 - CODE VERIFICATION ||=========================== //

export enum verificationType {
    register = 'register',
    forgot = 'forgot'
}

const CodeVerification = ({ type }: { type: verificationType }) => {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));

    const { resendOTP, resetPassword } = useAuth();
    const { email } = useSelector((state) => state.verify);
    const censoredEmail = email?.replace(/(?:.{3}).(?=[^@]*?.@)/g, '*');
    const [error, setError] = React.useState<string | null>(null);
    const navigate = useNavigate();

    React.useEffect(() => {
        if (email === null) {
            navigate('/login', { replace: true });
        }
    }, []);

    const onSubmit = async () => {
        try {
            startTimer(10);
            if (email === null) return;
            switch (type) {
                case verificationType.forgot:
                    await resetPassword(email);
                    break;
                case verificationType.register:
                    await resendOTP(email);
                    break;
            }
        } catch (error: any) {
            console.error(error);
            setError(error.response.data.status);
        }
    };

    // create a timer for the resend button, when the timer is done, the button will be enabled
    const [timer, setTimer] = React.useState(0);
    const [disabled, setDisabled] = React.useState(false);

    const startTimer = (seconds: number) => {
        setDisabled(true);
        setTimer(seconds);

        const intervalId = setInterval(() => {
            setTimer((prev) => prev - 1);
        }, 1000);

        setTimeout(() => {
            setTimer(0);
            clearInterval(intervalId);
            setDisabled(false);
        }, seconds * 1000);
    };

    return (
        <AuthWrapper>
            <Grid container justifyContent={matchDownSM ? 'center' : 'space-between'} alignItems="center">
                <Grid item md={6} lg={7} xs={12} sx={{ minHeight: '100vh' }}>
                    <Grid
                        sx={{ minHeight: '100vh' }}
                        container
                        alignItems={matchDownSM ? 'center' : 'flex-start'}
                        justifyContent={matchDownSM ? 'center' : 'space-between'}
                    >
                        <Grid item sx={{ display: { xs: 'none', md: 'block' }, m: 3 }}>
                            <Link to="#" aria-label="theme logo">
                                <Logo />
                            </Link>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            container
                            justifyContent="center"
                            alignItems="center"
                            sx={{ minHeight: { xs: 'calc(100vh - 68px)', md: 'calc(100vh - 152px)' } }}
                        >
                            <Stack justifyContent="center" alignItems="center" spacing={5} m={2}>
                                <Box component={Link} to="#" sx={{ display: { xs: 'block', md: 'none' } }}>
                                    <Logo />
                                </Box>
                                <AuthCardWrapper border={matchDownMD}>
                                    <Grid container spacing={2} justifyContent="center">
                                        <Grid item xs={12}>
                                            <Stack alignItems="center" justifyContent="center" spacing={1}>
                                                <Typography
                                                    color={theme.palette.secondary.main}
                                                    gutterBottom
                                                    variant={matchDownSM ? 'h3' : 'h2'}
                                                >
                                                    Enter Verification Code
                                                </Typography>
                                                <Typography variant="subtitle1" fontSize="1rem">
                                                    We send you on mail.
                                                </Typography>
                                                <Typography variant="caption" fontSize="0.875rem" textAlign="center">
                                                    We’ve send you code on {censoredEmail}
                                                </Typography>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <AuthCodeVerification type={type} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid item container direction="column" alignItems="center" xs={12}>
                                                <Typography
                                                    component={Link}
                                                    to="#"
                                                    variant="subtitle1"
                                                    sx={{ textAlign: 'center', textDecoration: 'none' }}
                                                >
                                                    Did not receive the email? Check your spam filter, or
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12}>
                                            {error && (
                                                <Box sx={{ mt: 3 }}>
                                                    <FormHelperText error>{error}</FormHelperText>
                                                </Box>
                                            )}
                                            <AnimateButton>
                                                <Button
                                                    disabled={disabled}
                                                    disableElevation
                                                    fullWidth
                                                    size="large"
                                                    type="submit"
                                                    variant="outlined"
                                                    color="secondary"
                                                    onClick={onSubmit}
                                                >
                                                    Resend Code {timer > 0 ? `(${timer})` : ''}
                                                </Button>
                                            </AnimateButton>
                                        </Grid>
                                    </Grid>
                                </AuthCardWrapper>
                            </Stack>
                        </Grid>
                        {/* <Grid item xs={12} sx={{ m: 3 }}>
                            <AuthFooter />
                        </Grid> */}
                    </Grid>
                </Grid>
                <Grid item md={6} lg={5} sx={{ position: 'relative', alignSelf: 'stretch', display: { xs: 'none', md: 'block' } }}>
                    <BackgroundPattern2
                        //@ts-ignore
                        sx={{
                            backgroundImage: `url(${imgMain})`,
                            backgroundPosition: 'center',
                            '&:after': {
                                content: '""',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                zIndex: 1,
                                bottom: 0
                            }
                        }}
                    >
                        {}
                    </BackgroundPattern2>
                </Grid>
            </Grid>
        </AuthWrapper>
    );
};

export default CodeVerification;
