import { OnChangeFn, PaginationInstance, PaginationState, RowSelectionState, SortingState, Table } from '@tanstack/react-table';
import { AsyncValue, ConnectionState } from 'hooks/Async';
import { Exception, JSONType, PagingData } from 'models';
import { createContext } from 'react';
import { ColumnMeta, DeleteAllTableEnum, TableState } from './type';

export type PagingController<T> = Pick<
    PaginationInstance<T>,
    'nextPage' | 'setPageIndex' | 'previousPage' | 'getCanNextPage' | 'getCanPreviousPage' | 'setPageSize'
>;

const voidCallback = (_?: any) => {};

export const TableContext = createContext<{
    pagingController: PagingController<any>;
    setTable: (value: Table<any>) => void;
    pagingState: PaginationState;
    setPagingState: (value: PaginationState) => void;
    sorting: SortingState;
    setSorting: OnChangeFn<SortingState>;
    pageCount: number;
    dataCount: number;
    setDataCount: (value: number) => void;
    tableState: TableState<any>;
    setTableState: (value: TableState<any>) => void;
    autoResetPageIndex: boolean;
    skipAutoResetPageIndex: () => void;
    setAsyncData: (data: AsyncValue<PagingData<any>>) => void;

    data?: PagingData<any>;
    connectionState: ConnectionState;
    error?: Exception;

    rowUpdateId: number;
    updateRows: () => void;

    manipulateConnectionState: ConnectionState;
    setManipuateConnectionState: (state: ConnectionState) => void;

    refreshId: string;
    refresh: (options?: { setTableStateAsRead: boolean }) => void;

    selectedRowData: any[];
    rowSelection: RowSelectionState;
    setRowSelection: (value: RowSelectionState) => void;

    rowDeleteId: string;
    deleteRows: () => void;
    insertData?: JSONType;
    setInsertData: (value?: JSONType) => void;
    panelVisibility: boolean;
    togglePanel: (visibility?: boolean) => void;
    q?: string;
    setQ: (value?: string) => void;

    extQ: any;
    setExtQ: (value: any) => void;

    insert: (_?: any) => void;

    codeLabel: string;
    setCodeLabel: (code: string) => void;
    listAutoFillName: any;
    setlistAutoFillName: (_: any) => void;
    listAutoFillUnit: any;
    setlistAutoFillUnit: (_: any) => void;
    nameInput: string;
    setNameInput: (_?: any) => void;
    unitInput: string;
    setUnitInput: (_?: any) => void;

    exportExcel: boolean;
    setExportExcel: (_?: any) => void;

    fixBom: boolean;
    setFixBom: (_?: any) => void;

    sortHeader: null | string;
    setSortHeader: (_?: any) => void;
    openDeleteAll: (state: boolean | null, type?: DeleteAllTableEnum) => void;
    showDelAll?: boolean;

    addColumnHandler: (_?: any) => void;
    setAddColumnState: (_?: any) => void;
    addColumnState: boolean;
    addColumn: string;
    setAddColumn: (_?: any) => void;
    columnExternal: null | { [key: string]: ColumnMeta };
    setColumnExternal: (_?: any) => void;
    delColumnState: boolean;
    setDelColumnState: (_?: any) => void;
    delColumnHandler: (_?: any) => void;
    delColumn: string;
    setDelColumn: (_?: any) => void;
}>({
    pagingController: {} as PagingController<any>,
    setTable: voidCallback,
    pagingState: {
        pageIndex: 0,
        pageSize: 0
    },
    setPagingState: voidCallback,
    sorting: [],
    setSorting: voidCallback,
    pageCount: 0,
    dataCount: 0,
    setDataCount: voidCallback,
    tableState: { state: 'read' },
    setTableState: voidCallback,
    autoResetPageIndex: false,
    skipAutoResetPageIndex: () => {},
    setAsyncData: voidCallback,
    connectionState: ConnectionState.none,
    rowUpdateId: 0,
    updateRows: voidCallback,
    manipulateConnectionState: ConnectionState.none,
    setManipuateConnectionState: voidCallback,
    refresh: voidCallback,
    refreshId: '',
    rowSelection: {},
    setRowSelection: voidCallback,
    selectedRowData: [],

    rowDeleteId: '',
    deleteRows: voidCallback,
    setInsertData: voidCallback,
    panelVisibility: false,
    togglePanel: voidCallback,
    setQ: voidCallback,

    extQ: undefined,
    setExtQ: voidCallback,

    insert: voidCallback,
    codeLabel: '',
    setCodeLabel: voidCallback,
    listAutoFillName: [],
    setlistAutoFillName: voidCallback,
    listAutoFillUnit: [],
    setlistAutoFillUnit: voidCallback,
    nameInput: '',
    setNameInput: voidCallback,
    unitInput: '',
    setUnitInput: voidCallback,

    exportExcel: false,
    setExportExcel: voidCallback,

    fixBom: false,
    setFixBom: voidCallback,

    sortHeader: null,
    setSortHeader: voidCallback,
    openDeleteAll: voidCallback,

    addColumnHandler: voidCallback,
    setAddColumnState: voidCallback,
    addColumnState: false,
    addColumn: '',
    setAddColumn: voidCallback,
    columnExternal: null,
    setColumnExternal: voidCallback,
    setDelColumnState: voidCallback,
    delColumnState: false,
    delColumnHandler: voidCallback,
    delColumn: '',
    setDelColumn: (_?: any) => voidCallback
});
