import { SetupParams, SheetMeta, useCompanyFolderSlug } from 'components/BalanceImport';
import { ConvertCurrency } from 'components/BalanceTable/BangKeSheet';
import { ConvertDate, FormTypeEnum } from 'components/BalanceTable/TableInvoice/InvoiceProvider';
import useSnackBar, { SnackBarAlertEnum } from 'components/SnackBar/UISnackbar';
import { useInvoiceRepo } from 'di';
import Docxtemplater from 'docxtemplater';
import saveAs from 'file-saver';
import _, { isNil } from 'lodash';
import { InvoiceImportData, InvoiceImportDataInterface, ResultState } from 'models';
import PizZip from 'pizzip';
import PizZipUtils from 'pizzip/utils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { BaseQueryParams } from 'repository';
import { match } from 'ts-pattern';
import * as XLSX from 'xlsx';
import {
    ConsigneeCompanyInterface,
    ConvertDateImport,
    ConvertName,
    ExporterCompanyInterface,
    FieldStateInterface,
    FromHsToOriginCriteria,
    GenerateWordTitle
} from '..';
import { ImportDataType, TotalntValueInterface, TotalsubInterface } from '../type';

export interface InvoiceImportCombine {
    data: InvoiceImportData;
    combine: boolean;
}
export interface useGeneratePageImportProps {
    label: string | null;
    place: string | null;
    fieldState: FieldStateInterface | null;
    userInput: string | null;
    userInput2: string | null;
    vesselName: string | null;
    wordFormType: FormTypeEnum;
    rowPerPage: string | null;
    markPackage: string | null;
    portDischarge: string | null;
    referenceNo: string | null;
    retroactively: boolean;
    exhibition: boolean;
    movement: boolean;
    thirdParty: boolean;
    departureDate: string | null;
    companyExporter: ExporterCompanyInterface;
    companyConsignee: ConsigneeCompanyInterface;
    sheetActiveStep: number;
    linkTopFile: string;
    linkTopFileNoUserInput: string;
    linkBotFile: string;
    openImport: boolean;
    gw: string;
    back2back: boolean;
    cumulation: boolean;
    thirdCountry: boolean;
}

function useGeneratePageImport({
    exhibition,
    fieldState,
    label,
    markPackage,
    movement,
    place,
    portDischarge,
    referenceNo,
    retroactively,
    rowPerPage,
    thirdParty,
    userInput,
    userInput2,
    vesselName,
    wordFormType,
    companyConsignee,
    companyExporter,
    departureDate,
    sheetActiveStep,
    linkBotFile,
    linkTopFile,
    linkTopFileNoUserInput,
    openImport,
    back2back,
    cumulation,
    gw,
    thirdCountry
}: useGeneratePageImportProps) {
    const [wordTitle, setWordTitle] = useState<number>(1);
    const [resultList, setResultList] = useState<any[][] | null>(null);
    const [doc1, setDoc1] = useState<Blob[] | null>(null);
    const [doc2, setDoc2] = useState<Blob[] | null>(null);
    const [doc3, setDoc3] = useState<Blob[] | null>(null);
    const [blobFile1, setBlobFile1] = useState<Blob[] | null>(null);
    const [blobFile2, setBlobFile2] = useState<Blob[] | null>(null);
    const [blobFile3, setBlobFile3] = useState<Blob[] | null>(null);
    const [mergeFile1, setMergeFile1] = useState<Blob | null>(null);
    const [mergeFile2, setMergeFile2] = useState<Blob | null>(null);
    const [mergeFile3, setMergeFile3] = useState<Blob | null>(null);
    const [blobFileBottom, setBlobFileBottom] = useState<Blob | null>(null);
    const [fileName, setFileName] = useState<string | null>(null);
    const [submit, setSubmit] = useState(false);
    const [submitStep2, setSubmitStep2] = useState(false);
    const [selectedSheet, setSelectedSheet] = useState<string | null>(null);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [header, setHeader] = useState<string[] | undefined>([]);
    const [rowIndex, _setRowIndex] = useState<[number, number | undefined] | undefined>(undefined);
    const [endRow, setEndRow] = useState<number | null>(null);
    const [setupSuccessful, setSetupSuccessful] = useState(false);
    const [fetchCO, setFetchCO] = useState(false);
    const PARAMS: BaseQueryParams = useCompanyFolderSlug();
    const { showSnackBar } = useSnackBar();
    const [coList, setCOList] = useState<InvoiceImportData[] | null>(null);
    const [revertData, setRevertData] = useState<InvoiceImportData[] | null>(null);
    const [workBook, setWorkBook] = useState<XLSX.WorkBook | null>(null);
    const [uniqHsMap, setUniqHsMap] = useState<any | null>(null);

    const invoiceRepo = useInvoiceRepo();

    const setupImport = (params: SetupParams) => invoiceRepo.setUpCOForm(params);

    const setRowIndex = (from: number, to: number) => {
        _setRowIndex([from, to]);
    };

    const getFixedHeader = () => {
        return invoiceRepo.getFixedHeader();
    };

    const sheetMetaImportParams = useMemo<SheetMeta | undefined>(() => {
        if (_.some([selectedFile, selectedSheet, endRow, rowIndex], isNil)) return undefined;
        const [from, to] = rowIndex!;
        const draft: SheetMeta = {
            excel_file: selectedFile!,
            sheet_name: selectedSheet!,
            header_from: from,
            end_row: endRow!
        };
        if (from !== to && !!to) draft.header_to = to;
        return draft;
    }, [selectedFile, selectedSheet, rowIndex, endRow]);

    async function ImportCOForm(params: SheetMeta) {
        const { company_slug, folder_slug } = PARAMS;
        const response = await invoiceRepo.importCOForm({ ...params, company_slug, folder_slug });
        match(response)
            .with({ state: ResultState.success }, () => {
                showSnackBar('Import Success', SnackBarAlertEnum.success);
                setFetchCO(true);
            })
            .with({ state: ResultState.failed }, ({ exception }) => {
                showSnackBar(exception.message, SnackBarAlertEnum.error);
            })
            .exhaustive();
    }

    async function FetchCOList() {
        const response = await invoiceRepo.getCOList(PARAMS);
        match(response)
            .with({ state: ResultState.success }, ({ data }) => {
                setCOList((prev: null | InvoiceImportData[]) => {
                    if (prev === null) return [...data.results];
                    else return [...prev, ...data.results];
                });
            })
            .with({ state: ResultState.failed }, ({ exception }) => {
                showSnackBar(exception.message, SnackBarAlertEnum.error);
            })
            .exhaustive();
    }

    async function DeleteImportCOList() {
        const response = await invoiceRepo.deleteImportCOList(PARAMS);
        match(response)
            .with({ state: ResultState.success }, ({}) => {})
            .with({ state: ResultState.failed }, ({ exception }) => {
                console.log(exception.message);
            })
            .exhaustive();
    }

    useEffect(() => {
        if (!openImport) {
            DeleteImportCOList();
            setResultList(null);
            setDoc1(null);
            setDoc2(null);
            setDoc3(null);
            setBlobFile1(null);
            setBlobFile2(null);
            setBlobFile3(null);
            setMergeFile1(null);
            setMergeFile2(null);
            setMergeFile3(null);
            setBlobFileBottom(null);
            setFileName(null);
            setSubmit(false);
            setSubmitStep2(false);
            setSelectedFile(null);
            setHeader(undefined);
            _setRowIndex(undefined);
            setEndRow(null);
            setSetupSuccessful(false);
            setFetchCO(false);
            setCOList(null);
            setWorkBook(null);
            setSelectedSheet(null);
        }
    }, [openImport]);

    function GenerateDoc(src: string, data?: any, type?: string, location?: number) {
        function ConvertFile(error: any, content: any) {
            const docLocation = location ?? 0;
            const docType = type ?? 'bottom';

            if (error) {
                throw error;
            }
            const zip = new PizZip(content);
            const doc = new Docxtemplater(zip, {
                paragraphLoop: true,
                linebreaks: true
            });

            if (data) {
                doc.setData({ ...data });
            }
            try {
                doc.render();
            } catch (error) {
                throw error;
            }

            const out = doc.getZip().generate({
                type: 'blob',
                mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            });

            if (docType === 'bottom') {
                setBlobFileBottom(out);
            }
            if (docType === 'top') {
                switch (docLocation) {
                    case 1:
                        setDoc1((prev: Blob[] | null) => {
                            if (prev === null) return [out];
                            else return [...prev, out];
                        });
                        return;

                    case 2:
                        setDoc2((prev: Blob[] | null) => {
                            if (prev === null) return [out];
                            else return [...prev, out];
                        });
                        return;

                    case 3:
                        setDoc3((prev: Blob[] | null) => {
                            if (prev === null) return [out];
                            else return [...prev, out];
                        });
                        return;
                    default:
                        return;
                }
            }
        }

        function loadFile(url: string, callback: any) {
            PizZipUtils.getBinaryContent(url, callback);
        }
        loadFile(src, ConvertFile);
    }

    async function GetWordMergeFile(data: Blob[], type: number) {
        const response = await invoiceRepo.getMergerdWord(data);
        match(response)
            .with({ state: ResultState.success }, ({ data }) => {
                switch (type) {
                    case 1:
                        setMergeFile1(data.data);
                        return;

                    case 2:
                        setMergeFile2(data.data);
                        return;

                    case 3:
                        setMergeFile3(data.data);
                        return;
                }
            })
            .with({ state: ResultState.failed }, ({ exception }) => {
                console.log(exception);
            })
            .exhaustive();
    }

    function GenerateInvoiceDateImport(props: ImportDataType) {
        const { data, form } = props;
        const listInvoiceDate: any[] = [];
        if (!data) return listInvoiceDate;
        switch (form) {
            case FormTypeEnum.AC:
                data.forEach((item: InvoiceImportData) => {
                    const invoice_date = item.bill_date ?? '';
                    const invoice_no = item.invoice_no ?? '';
                    listInvoiceDate.push({ invoice_date, invoice_no });
                });
                return _.uniqBy(listInvoiceDate, 'invoice_date');
            case FormTypeEnum.AI:
                data.forEach((item: InvoiceImportData) => {
                    const invoice_date = item.bill_date ?? '';
                    const invoice_no = item.invoice_no ?? '';
                    listInvoiceDate.push({ invoice_date, invoice_no });
                });
                return _.uniqBy(listInvoiceDate, 'invoice_date');
            case FormTypeEnum.VJ:
                data.forEach((item: InvoiceImportData) => {
                    const invoice_date = item.bill_date ?? '';
                    const invoice_no = item.invoice_no ?? '';
                    listInvoiceDate.push({ invoice_date, invoice_no });
                });
                return _.uniqBy(listInvoiceDate, 'invoice_date');
            default:
                return listInvoiceDate;
        }
    }

    const MapWordDataImport = useCallback(
        (data: InvoiceImportData, index: string, mark_pack: string, item_inv_no: string, item_inv_da: string) => {
            if (!fieldState || !wordFormType) return;
            else {
                switch (wordFormType) {
                    case FormTypeEnum.AC:
                        return {
                            index: index,
                            item_hs: fieldState.item_hs.isHidden ? data.hs?.toString().slice(0, 6) : '',
                            item_npl: `ART NO.` + data.code ?? ' PSR',
                            item_name: fieldState.item_name.isHidden ? ConvertName(data.name) : '',
                            item_nt_value: data.total.toString() + ' PCS',
                            item_origin_criteria: FromHsToOriginCriteria(data.hs.toString() ?? ''),
                            mark_pack,
                            item_inv_no,
                            item_inv_da
                        };

                    case FormTypeEnum.AI:
                        return {
                            index: index,
                            item_hs: fieldState.item_hs.isHidden ? data.hs?.toString().slice(0, 6) : '',
                            item_npl: `ART NO.` + data.code ?? ' PSR',
                            item_name: fieldState.item_name.isHidden ? ConvertName(data.name) : '',
                            item_nt_value: data.total.toString() + ' PCS',
                            item_sub: data.nt_value + ' USD' ?? '0 USD',
                            item_origin_criteria: FromHsToOriginCriteria(data.hs.toString() ?? ''),
                            mark_pack,
                            item_inv_no,
                            item_inv_da
                        };
                    case FormTypeEnum.VJ:
                        return {
                            index: index,
                            item_npl: data.code ?? '',
                            item_name: fieldState.item_name.isHidden ? ConvertName(data.name) : '',
                            item_hs: fieldState.item_hs.isHidden ? data.hs?.toString().slice(0, 6) : '',
                            item_origin_criteria: FromHsToOriginCriteria(data.hs.toString() ?? ''),
                            item_nt_value: data.total.toString() + ' PCS',
                            item_inv_no,
                            item_inv_da
                        };
                }
            }
        },
        [fieldState, wordFormType]
    );

    const MapWordPage = useCallback(
        (data: any[]) => {
            if (rowPerPage) {
                const num = parseInt(rowPerPage);
                const balance = data.length % num;
                const d = Math.floor(data.length / num);
                const cloneData = [...data];
                const result = [];
                for (let i = 0; i < d; i++) {
                    result.push(cloneData.splice(0, num));
                }
                if (balance) {
                    result.push(cloneData);
                }

                return result;
            } else return null;
        },
        [rowPerPage]
    );

    function GenerateInvoiceNoImport(props: ImportDataType) {
        const { data, form } = props;
        const listInvoiceNo: any[] = [];
        if (!data) return listInvoiceNo;
        switch (form) {
            case FormTypeEnum.AC:
                data.forEach((item: InvoiceImportData) => {
                    const invoice_date = item.bill_date ?? '';
                    const invoice_no = item.invoice_no ?? '';
                    listInvoiceNo.push({ invoice_date, invoice_no });
                });
                return _.uniqBy(listInvoiceNo, 'invoice_no');

            case FormTypeEnum.AI:
                data.forEach((item: InvoiceImportData) => {
                    const invoice_date = item.bill_date ?? '';
                    const invoice_no = item.invoice_no ?? '';
                    listInvoiceNo.push({ invoice_date, invoice_no });
                });
                return _.uniqBy(listInvoiceNo, 'invoice_no');

            case FormTypeEnum.VJ:
                data.forEach((item: InvoiceImportData) => {
                    const invoice_date = item.bill_date ?? '';
                    const invoice_no = item.invoice_no ?? '';
                    listInvoiceNo.push({ invoice_date, invoice_no });
                });
                return _.uniqBy(listInvoiceNo, 'invoice_no');
            default:
                return listInvoiceNo;
        }
    }

    function GenerateTotalNTValueImport(props: ImportDataType) {
        const { data, form } = props;
        let total = 0;
        if (!data) return null;
        switch (form) {
            case FormTypeEnum.AC:
                data.forEach((item: InvoiceImportData) => {
                    total += item.nt_value;
                });

                return { total_nt_value: `USD ${ConvertCurrency(total)}` };

            case FormTypeEnum.AI:
                data.forEach((item: InvoiceImportData) => {
                    total += item.nt_value;
                });

                return { total_nt_value: `USD ${ConvertCurrency(total)}` };
            case FormTypeEnum.VJ:
                return null;
            default:
                return null;
        }
    }

    function GenerateTotalSubImport(props: ImportDataType) {
        let total = 0;
        const { data, form } = props;
        if (!data) return null;
        switch (form) {
            case FormTypeEnum.AC:
                data.forEach((item: InvoiceImportData) => {
                    total += item.total;
                });

                return { total_sub: `Total: ${total}` };

            case FormTypeEnum.AI:
                data.forEach((item: InvoiceImportData) => {
                    total += item.total;
                });

                return { total_sub: `Total: ${total}` };

            case FormTypeEnum.VJ:
                data.forEach((item: InvoiceImportData) => {
                    total += item.total;
                });

                return { total_sub: `${total} PIECES` };
            default:
                return null;
        }
    }

    const AddExtraField = useCallback(
        (
            refactoredPage: any[][] | null,
            total_nt_value: TotalntValueInterface | null,
            total_sub: TotalsubInterface | null,
            title: string
        ) => {
            if (!refactoredPage) return null;
            else {
                const { company_exporter_name, company_exporter_address } = companyExporter;
                const { company_consignee_name, company_consignee_address } = companyConsignee;

                switch (wordFormType) {
                    case FormTypeEnum.AC:
                        if (refactoredPage.length < 2) {
                            return refactoredPage.map((item) => {
                                return {
                                    item: item,
                                    user_input_1: userInput ?? '',
                                    user_input_2: userInput2 ?? '',
                                    page: '',
                                    ...total_nt_value,
                                    label: label ?? '',
                                    depature_date: departureDate ?? '',
                                    vessel_name: vesselName ?? '',
                                    port_of_discharge: portDischarge ?? '',
                                    company_exporter_name,
                                    mark_pack: markPackage,
                                    company_consignee_name,
                                    company_exporter_address,
                                    company_consignee_address,
                                    ...total_sub,
                                    item_place: place ?? '',
                                    retroact: retroactively ? '☑' : '☐',
                                    exhi: exhibition ? '☑' : '☐',
                                    movem: movement ? '☑' : '☐',
                                    third: thirdParty ? '☑' : '☐',
                                    refer: referenceNo ?? '',
                                    title: title ?? ''
                                };
                            });
                        } else {
                            return refactoredPage.map((item, index) => {
                                if (index !== refactoredPage.length - 1) {
                                    return {
                                        item: item,
                                        user_input_1: userInput ?? '',
                                        user_input_2: userInput2 ?? '',
                                        page: `Page ${index + 1} of ${refactoredPage.length}`,
                                        total_nt_value: '',
                                        label: label ?? '',
                                        depature_date: departureDate ?? '',
                                        vessel_name: vesselName ?? '',
                                        port_of_discharge: portDischarge ?? '',
                                        company_exporter_name,
                                        company_consignee_name,
                                        company_exporter_address,
                                        company_consignee_address,
                                        total_sub: '',
                                        item_place: place ?? '',
                                        retroact: retroactively ? '☑' : '☐',
                                        exhi: exhibition ? '☑' : '☐',
                                        movem: movement ? '☑' : '☐',
                                        third: thirdParty ? '☑' : '☐',
                                        refer: referenceNo ?? '',
                                        title: title ?? ''
                                    };
                                } else {
                                    return {
                                        item: item,
                                        user_input_1: userInput ?? '',
                                        user_input_2: userInput2 ?? '',
                                        page: `Page ${index + 1} of ${refactoredPage.length}`,
                                        ...total_nt_value,
                                        label: label ?? '',
                                        depature_date: departureDate ?? '',
                                        vessel_name: vesselName ?? '',
                                        port_of_discharge: portDischarge ?? '',
                                        company_exporter_name,
                                        company_consignee_name,
                                        company_exporter_address,
                                        company_consignee_address,
                                        ...total_sub,
                                        item_place: place ?? '',
                                        retroact: retroactively ? '☑' : '☐',
                                        exhi: exhibition ? '☑' : '☐',
                                        movem: movement ? '☑' : '☐',
                                        third: thirdParty ? '☑' : '☐',
                                        refer: referenceNo ?? '',
                                        title: title ?? ''
                                    };
                                }
                            });
                        }

                    case FormTypeEnum.AI:
                        if (refactoredPage.length < 2) {
                            return refactoredPage.map((item) => {
                                return {
                                    item: item,
                                    gw: gw !== '' ? 'GW ' + gw + ' KGM' : '',
                                    user_input_1: userInput ?? '',
                                    user_input_2: userInput2 ?? '',
                                    page: '',
                                    ...total_nt_value,
                                    label: label ?? '',
                                    depature_date: departureDate ?? '',
                                    vessel_name: vesselName ?? '',
                                    port_of_discharge: portDischarge ?? '',
                                    company_exporter_name,
                                    mark_pack: markPackage,
                                    company_consignee_name,
                                    company_exporter_address,
                                    company_consignee_address,
                                    ...total_sub,
                                    item_place: place ?? '',
                                    back: back2back ? '☑' : '☐',
                                    exhi: exhibition ? '☑' : '☐',
                                    cumu: cumulation ? '☑' : '☐',
                                    third: thirdCountry ? '☑' : '☐',
                                    refer: referenceNo ?? '',
                                    title: title ?? ''
                                };
                            });
                        } else {
                            return refactoredPage.map((item, index) => {
                                if (index !== refactoredPage.length - 1) {
                                    return {
                                        item: item,
                                        gw: gw !== '' ? 'GW ' + gw + ' KGM' : '',
                                        user_input_1: userInput ?? '',
                                        user_input_2: userInput2 ?? '',
                                        page: `Page ${index + 1} of ${refactoredPage.length}`,
                                        total_nt_value: '',
                                        label: label ?? '',
                                        depature_date: departureDate ?? '',
                                        vessel_name: vesselName ?? '',
                                        port_of_discharge: portDischarge ?? '',
                                        company_exporter_name,
                                        company_consignee_name,
                                        company_exporter_address,
                                        company_consignee_address,
                                        total_sub: '',
                                        item_place: place ?? '',
                                        back: back2back ? '☑' : '☐',
                                        exhi: exhibition ? '☑' : '☐',
                                        cumu: cumulation ? '☑' : '☐',
                                        third: thirdCountry ? '☑' : '☐',
                                        refer: referenceNo ?? '',
                                        title: title ?? ''
                                    };
                                } else {
                                    return {
                                        item: item,
                                        gw: gw !== '' ? 'GW ' + gw + ' KGM' : '',
                                        user_input_1: userInput ?? '',
                                        user_input_2: userInput2 ?? '',
                                        page: `Page ${index + 1} of ${refactoredPage.length}`,
                                        ...total_nt_value,
                                        label: label ?? '',
                                        depature_date: departureDate ?? '',
                                        vessel_name: vesselName ?? '',
                                        port_of_discharge: portDischarge ?? '',
                                        company_exporter_name,
                                        company_consignee_name,
                                        company_exporter_address,
                                        company_consignee_address,
                                        ...total_sub,
                                        item_place: place ?? '',
                                        back: back2back ? '☑' : '☐',
                                        exhi: exhibition ? '☑' : '☐',
                                        cumu: cumulation ? '☑' : '☐',
                                        third: thirdCountry ? '☑' : '☐',
                                        refer: referenceNo ?? '',
                                        title: title ?? ''
                                    };
                                }
                            });
                        }
                    // case FormTypeEnum.VJ:
                    //     if (refactoredPage.length < 2) {
                    //         return refactoredPage.map((item) => {
                    //             return {
                    //                 item: item,
                    //                 user_input_1: userInput ?? '',
                    //                 user_input_2: userInput2 ?? '',
                    //                 page: '',
                    //                 page2: '',
                    //                 label: label ?? '',
                    //                 depature_date: departureDate ?? '',
                    //                 vessel_name: vesselName ?? '',
                    //                 port_of_discharge: portDischarge ?? '',
                    //                 company_exporter_name,
                    //                 company_consignee_name,
                    //                 company_exporter_address,
                    //                 company_consignee_address,
                    //                 total_sub: total_sub?.total_sub ?? '',
                    //                 item_place: place ?? '',
                    //                 refer: referenceNo ?? '',
                    //                 title: title ?? ''
                    //             };
                    //         });
                    //     } else {
                    //         return refactoredPage.map((item, index) => {
                    //             if (index !== refactoredPage.length - 1) {
                    //                 return {
                    //                     item: item,
                    //                     user_input_1: userInput ?? '',
                    //                     user_input_2: userInput2 ?? '',
                    //                     page: `Page ${index + 1} of ${refactoredPage.length}`,
                    //                     page2: `${index + 1} / ${refactoredPage.length}`,
                    //                     label: label ?? '',
                    //                     vessel_name: vesselName ?? '',
                    //                     port_of_discharge: portDischarge ?? '',
                    //                     depature_date: departureDate ?? '',
                    //                     company_exporter_name,
                    //                     company_consignee_name,
                    //                     company_exporter_address,
                    //                     company_consignee_address,
                    //                     total_sub: '',
                    //                     item_place: place ?? '',
                    //                     refer: referenceNo ?? '',
                    //                     title: title ?? ''
                    //                 };
                    //             } else {
                    //                 return {
                    //                     item: item,
                    //                     user_input_1: userInput ?? '',
                    //                     user_input_2: userInput2 ?? '',
                    //                     page: `Page ${index + 1} of ${refactoredPage.length}`,
                    //                     page2: `${index + 1} / ${refactoredPage.length}`,
                    //                     label: label ?? '',
                    //                     vessel_name: vesselName ?? '',
                    //                     port_of_discharge: portDischarge ?? '',
                    //                     depature_date: departureDate ?? '',
                    //                     company_exporter_name,
                    //                     company_consignee_name,
                    //                     company_exporter_address,
                    //                     company_consignee_address,
                    //                     total_sub: total_sub?.total_sub,
                    //                     item_place: place,
                    //                     refer: referenceNo ?? '',
                    //                     title: title ?? ''
                    //                 };
                    //             }
                    //         });
                    //     }
                }
            }
        },
        [
            label,
            userInput,
            userInput2,
            vesselName,
            portDischarge,
            departureDate,
            companyExporter,
            companyConsignee,
            place,
            retroactively,
            exhibition,
            movement,
            thirdParty,
            referenceNo,
            wordFormType,
            gw,
            back2back,
            cumulation,
            thirdCountry
        ]
    );

    function GeneratePageImportAC(props: ImportDataType, markPackage: string | null, wordTitle?: number) {
        const { data } = props;
        if (!data || !markPackage) return null;
        const total_sub_ac = GenerateTotalSubImport(props);
        const invoice_no_ac = GenerateInvoiceNoImport(props);
        const invoice_date_ac = GenerateInvoiceDateImport(props);
        const total_nt_value_ac = GenerateTotalNTValueImport(props);
        const wordTitleConverted_ac = wordTitle ? GenerateWordTitle(wordTitle) : GenerateWordTitle(1);
        const unique_total_ac = _.unionWith([...(invoice_no_ac ?? []), ...(invoice_date_ac ?? [])], _.isEqual);
        const firstInvoiceNo_ac = data[0].invoice_no ?? 'sample_invoice';
        const firstBillDate_ac = data[0].bill_date ?? ConvertDate(new Date());
        setFileName(firstBillDate_ac + '_' + firstInvoiceNo_ac);

        const refactoredPage_ac = MapWordPage(data);
        if (!refactoredPage_ac) return null;
        else {
            let count = 1;
            const newData = refactoredPage_ac.map((item: any[]) => {
                const newPage = [];
                //*
                for (let i = 0; i < 6; i++) {
                    if (item[i]) {
                        if (i === 0) {
                            const new_data = MapWordDataImport(
                                item[i],
                                count.toString(),
                                markPackage,
                                unique_total_ac[i]?.invoice_no ?? '',
                                ConvertDateImport(unique_total_ac[i]?.invoice_date ?? '')
                            );
                            newPage.push(new_data);
                            count++;
                        } else {
                            const new_data = MapWordDataImport(
                                item[i],
                                count.toString(),
                                '',
                                unique_total_ac[i]?.invoice_no ?? '',
                                ConvertDateImport(unique_total_ac[i]?.invoice_date ?? '')
                            );
                            newPage.push(new_data);
                            count++;
                        }
                    } else {
                        newPage.push({
                            index: '',
                            item_hs: '',
                            item_npl: '',
                            item_name: '',
                            item_nt_value: '',
                            item_origin_criteria: '',
                            mark_pack: '',
                            item_inv_no: '',
                            item_inv_da: ''
                        });
                    }
                }
                return newPage;
            });
            return AddExtraField(newData, total_nt_value_ac, total_sub_ac, wordTitleConverted_ac);
        }
    }

    function GeneratePageImportAI(props: ImportDataType, markPackage: string | null, wordTitle?: number) {
        const { data } = props;
        if (!data || !markPackage) return null;
        const total_sub_ai = GenerateTotalSubImport(props);
        const invoice_no_ai = GenerateInvoiceNoImport(props);
        const invoice_date_ai = GenerateInvoiceDateImport(props);
        const total_nt_value_ai = GenerateTotalNTValueImport(props);
        const wordTitleConverted_ai = wordTitle ? GenerateWordTitle(wordTitle) : GenerateWordTitle(1);
        const unique_total_ai = _.unionWith([...(invoice_no_ai ?? []), ...(invoice_date_ai ?? [])], _.isEqual);
        const firstInvoiceNo_ai = data[0].invoice_no ?? 'sample_invoice';
        const firstBillDate_ai = data[0].bill_date ?? ConvertDate(new Date());
        setFileName(firstBillDate_ai + '_' + firstInvoiceNo_ai);

        const refactoredPage_ai = MapWordPage(data);
        if (!refactoredPage_ai) return null;
        else {
            let count = 1;
            const newData = refactoredPage_ai.map((item: any[]) => {
                const newPage = [];
                //*
                for (let i = 0; i < 8; i++) {
                    if (item[i]) {
                        if (i === 0) {
                            const new_data = MapWordDataImport(
                                item[i],
                                count.toString(),
                                markPackage,
                                unique_total_ai[i]?.invoice_no ?? '',
                                ConvertDateImport(unique_total_ai[i]?.invoice_date ?? '')
                            );
                            newPage.push(new_data);
                            count++;
                        } else {
                            const new_data = MapWordDataImport(
                                item[i],
                                count.toString(),
                                '',
                                unique_total_ai[i]?.invoice_no ?? '',
                                ConvertDateImport(unique_total_ai[i]?.invoice_date ?? '')
                            );
                            newPage.push(new_data);
                            count++;
                        }
                    } else {
                        newPage.push({
                            index: '',
                            item_hs: '',
                            item_npl: '',
                            item_name: '',
                            item_nt_value: '',
                            item_sub: '',
                            item_origin_criteria: '',
                            mark_pack: '',
                            item_inv_no: unique_total_ai[i]?.invoice_no ?? '',
                            item_inv_da: ConvertDateImport(unique_total_ai[i]?.invoice_date ?? '')
                        });
                    }
                }
                return newPage;
            });
            return AddExtraField(newData, total_nt_value_ai, total_sub_ai, wordTitleConverted_ai);
        }
    }

    // function GeneratePageImportVJ(props: ImportDataType, markPackage: string | null, wordTitle?: number) {
    //     const { data } = props;
    //     if (!data || !markPackage) return null;

    //     const total_sub_vj = GenerateTotalSubImport(props);
    //     const invoice_no_vj = GenerateInvoiceNoImport(props);
    //     const invoice_date_vj = GenerateInvoiceDateImport(props);
    //     const total_nt_value_vj = GenerateTotalNTValueImport(props);
    //     const wordTitleConverted_vj = wordTitle ? GenerateWordTitle(wordTitle) : GenerateWordTitle(1);
    //     const unique_total_vj = _.unionWith([...invoice_no_vj, ...invoice_date_vj], _.isEqual);
    //     const firstInvoiceNo_vj = data[0].invoice_no ?? 'sample_invoice';
    //     const firstBillDate_vj = data[0].bill_date ?? ConvertDate(new Date());
    //     setFileName(firstBillDate_vj + '_' + firstInvoiceNo_vj);

    //     const refactoredPage_vj = MapWordPage(data);
    //     if (!refactoredPage_vj) return null;
    //     else {
    //         let count = 1;
    //         const newData = refactoredPage_vj.map((item: any[]) => {
    //             const newPage = [];
    //             //*
    //             for (let i = 0; i < 7; i++) {
    //                 if (item[i]) {
    //                     if (i === 0) {
    //                         const new_data = MapWordDataImport(
    //                             item[i],
    //                             count.toString(),
    //                             markPackage,
    //                             unique_total_vj[i].invoice_no ?? '',
    //                             ConvertDateImport(unique_total_vj[i].invoice_date ?? '')
    //                         );
    //                         newPage.push(new_data);
    //                         count++;
    //                     } else {
    //                         const new_data = MapWordDataImport(
    //                             item[i],
    //                             count.toString(),
    //                             '',
    //                             unique_total_vj[i]?.invoice_no ?? '',
    //                             ConvertDateImport(unique_total_vj[i]?.invoice_date ?? '')
    //                         );
    //                         newPage.push(new_data);
    //                         count++;
    //                     }
    //                 } else {
    //                     newPage.push({
    //                         index: '',
    //                         item_hs: '',
    //                         item_npl: '',
    //                         item_name: '',
    //                         item_nt_value: '',
    //                         item_origin_criteria: '',
    //                         mark_pack: '',
    //                         item_inv_no: '',
    //                         item_inv_da: ''
    //                     });
    //                 }
    //             }
    //             return newPage;
    //         });
    //         return AddExtraField(newData, total_nt_value_vj, total_sub_vj, wordTitleConverted_vj);
    //     }
    // }

    function GeneratePageImport(props: ImportDataType, markPackage: string | null, wordTitle?: number) {
        const { form, data } = props;
        if (!data) return null;
        switch (form) {
            case FormTypeEnum.AC:
                return GeneratePageImportAC(props, markPackage, wordTitle);

            case FormTypeEnum.AI:
                return GeneratePageImportAI(props, markPackage, wordTitle);
            default:
                return null;
            // case FormTypeEnum.VJ:
            //     return GeneratePageImportVJ(props, markPackage, wordTitle);
        }
    }

    useEffect(() => {
        if (fetchCO) {
            FetchCOList();
            setFetchCO(false);
        }
    }, [fetchCO]);

    useEffect(() => {
        if (setupSuccessful && sheetMetaImportParams) {
            ImportCOForm(sheetMetaImportParams);
            setSetupSuccessful(false);
        }
        return;
    }, [setupSuccessful, sheetMetaImportParams]);

    function IntegrateCombine(coList: InvoiceImportData[] | null): InvoiceImportCombine[] | undefined {
        return coList?.map((item: InvoiceImportData) => {
            return { data: item, combine: true };
        });
    }

    const OriginImportHandler = useCallback(
        (id: string, hs: number, originate: string, isCombine: boolean) => {
            if (!uniqHsMap) return;
            else {
                uniqHsMap.forEach((value: InvoiceImportCombine[], key: number) => {
                    if (key === hs) {
                        if (isCombine) {
                            const newList = value.map((e: InvoiceImportCombine) => {
                                if (e.combine) {
                                    const newData: InvoiceImportDataInterface = { ...e.data, origin_criteria: originate };
                                    return { ...e, data: newData };
                                } else return e;
                            });
                            uniqHsMap.set(key, newList);
                        } else {
                            const newList = value.map((e: InvoiceImportCombine) => {
                                const newData: InvoiceImportDataInterface = { ...e.data, origin_criteria: originate };
                                const newItem: InvoiceImportCombine = { combine: isCombine, data: newData as InvoiceImportData };
                                return newItem;
                            });
                            uniqHsMap.set(key, newList);
                        }
                    }
                });
                const newUniqHsMap = new Map(uniqHsMap);
                setUniqHsMap(newUniqHsMap);
            }
        },
        [uniqHsMap]
    );

    const SubmitOriginateHandler = useCallback(() => {
        if (!uniqHsMap) return;
        else {
            let revertData: InvoiceImportData[] = [];
            uniqHsMap.forEach((value: InvoiceImportCombine[], key: number) => {
                const newList = value.map((item: InvoiceImportCombine) => item.data);
                revertData.push(...newList);
            });
            setRevertData(revertData);
        }
    }, [uniqHsMap]);

    // function isCheckRevertData(
    //     revertData: InvoiceImportData[] | null,
    //     sheetActiveStep: number,
    //     markPackage: string | null,
    //     wordTitle: number,
    //     fieldState: FieldStateInterface | null,
    //     rowPerPage: string | null
    // ) {
    //     return revertData && markPackage && fieldState && rowPerPage && sheetActiveStep === 5 && wordTitle < 4 ? true : false;
    // }

    // useEffect(() => {
    //     if (isCheckRevertData(revertData, sheetActiveStep, markPackage, wordTitle, fieldState, rowPerPage)) {
    //         switch (wordFormType) {
    //             case FormTypeEnum.VJ:
    //                 const resultVJ = GeneratePageImport({ data: revertData, form: wordFormType }, markPackage, wordTitle);
    //                 if (resultVJ) {
    //                     setResultList((prev: null | any[][]) => {
    //                         if (prev === null) return [resultVJ];
    //                         else return [...prev, resultVJ];
    //                     });
    //                     setWordTitle(wordTitle + 1);
    //                 }
    //                 return;
    //             case FormTypeEnum.AJ:
    //                 const resultsAJ = GeneratePageImport({ data: revertData, form: wordFormType }, markPackage, wordTitle);
    //                 if (resultsAJ) {
    //                     setResultList((prev: null | any[][]) => {
    //                         if (prev === null) return [resultsAJ];
    //                         else return [...prev, resultsAJ];
    //                     });
    //                     setWordTitle(wordTitle + 1);
    //                 }
    //                 return;
    //         }
    //     } else return;
    // }, [revertData, sheetActiveStep, markPackage, wordTitle, fieldState, rowPerPage, wordFormType]);

    // function GetInvoiceImportHSMap(coList: InvoiceImportData[] | null) {
    //     const integrateCombine = IntegrateCombine(coList);

    //     if (!integrateCombine) return null;
    //     else {
    //         const invoiceImportList = integrateCombine.map((item: InvoiceImportCombine) => item.data);
    //         const uniqHsList = _.uniqBy(invoiceImportList, 'hs');
    //         const uniqHsMap = new Map();
    //         uniqHsList.forEach((item: InvoiceImportData) => {
    //             const hsList = integrateCombine.filter((value: InvoiceImportCombine) => item.hs === value.data.hs);
    //             uniqHsMap.set(item.hs, hsList);
    //         });
    //         setUniqHsMap(uniqHsMap);
    //     }
    // }

    function isCheckImport(
        coList: null | InvoiceImportData[],
        markPackage: null | string,
        wordFormType: null | FormTypeEnum,
        fieldState: null | FieldStateInterface,
        rowPerPage: null | string,
        sheetActiveStep: number,
        wordTitle: number
    ) {
        return coList && markPackage && fieldState && wordFormType && rowPerPage && sheetActiveStep === 4 && wordTitle < 4 ? true : false;
    }

    useEffect(() => {
        if (isCheckImport(coList, markPackage, wordFormType, fieldState, rowPerPage, sheetActiveStep, wordTitle)) {
            switch (wordFormType) {
                // case FormTypeEnum.VJ || FormTypeEnum.AJ:
                //     GetInvoiceImportHSMap(coList);
                //     return;
                case FormTypeEnum.AC:
                    const resultsAC = GeneratePageImport({ data: coList, form: wordFormType }, markPackage, wordTitle);
                    if (resultsAC) {
                        setResultList((prev: null | any[][]) => {
                            if (prev === null) return [resultsAC];
                            else return [...prev, resultsAC];
                        });
                        setWordTitle(wordTitle + 1);
                    }
                    return;
                case FormTypeEnum.AI:
                    const resultsAI = GeneratePageImport({ data: coList, form: wordFormType }, markPackage, wordTitle);
                    if (resultsAI) {
                        setResultList((prev: null | any[][]) => {
                            if (prev === null) return [resultsAI];
                            else return [...prev, resultsAI];
                        });
                        setWordTitle(wordTitle + 1);
                    }
                    return;
                default:
                    return;
            }
        }
    }, [coList, fieldState, wordFormType, sheetActiveStep, markPackage, rowPerPage, wordTitle]);

    useEffect(() => {
        if (resultList && wordFormType && resultList.length === 3 && wordTitle === 4) {
            resultList.forEach((list, index) => {
                if (index === 0) {
                    list.forEach((result, resultIndex) => {
                        if (userInput2 && resultIndex === list.length - 1) {
                            GenerateDoc(linkTopFile, result, 'top', 1);
                        } else {
                            GenerateDoc(linkTopFileNoUserInput, result, 'top', 1);
                        }
                    });
                } else if (index === 1) {
                    list.forEach((result, resultIndex) => {
                        if (userInput2 && resultIndex === list.length - 1) {
                            GenerateDoc(linkTopFile, result, 'top', 2);
                        } else {
                            GenerateDoc(linkTopFileNoUserInput, result, 'top', 2);
                        }
                    });
                } else {
                    list.forEach((result, resultIndex) => {
                        if (userInput2 && resultIndex === list.length - 1) {
                            GenerateDoc(linkTopFile, result, 'top', 3);
                        } else {
                            GenerateDoc(linkTopFileNoUserInput, result, 'top', 3);
                        }
                    });
                }
            });

            GenerateDoc(linkBotFile);
        }
    }, [wordTitle, resultList, userInput2, wordFormType, linkTopFile, linkTopFileNoUserInput, linkBotFile]);

    useEffect(() => {
        if (doc1 && wordTitle === 4 && blobFileBottom) {
            const timeOut = setTimeout(() => {
                const newList: Blob[] = [];
                const combineFile = doc1.map((item) => {
                    return [item, blobFileBottom];
                });
                if (combineFile) {
                    combineFile.forEach((item) => {
                        newList.push(...item);
                    });
                }
                if (newList.length !== 0) {
                    setBlobFile1(newList);
                }
            }, 3000);
            return () => clearTimeout(timeOut);
        } else return;
    }, [doc1, wordTitle, blobFileBottom]);

    useEffect(() => {
        if (doc2 && wordTitle === 4 && blobFileBottom) {
            const timeOut = setTimeout(() => {
                const newList: Blob[] = [];
                const combineFile = doc2.map((item) => {
                    return [item, blobFileBottom];
                });
                if (combineFile) {
                    combineFile.forEach((item) => {
                        newList.push(...item);
                    });
                }
                if (newList.length !== 0) {
                    setBlobFile2(newList);
                }
            }, 3000);
            return () => clearTimeout(timeOut);
        } else return;
    }, [doc2, wordTitle, blobFileBottom]);

    useEffect(() => {
        if (doc3 && wordTitle === 4 && blobFileBottom) {
            const timeOut = setTimeout(() => {
                const newList: Blob[] = [];
                const combineFile = doc3.map((item) => {
                    return [item, blobFileBottom];
                });
                if (combineFile) {
                    combineFile.forEach((item) => {
                        newList.push(...item);
                    });
                }
                if (newList.length !== 0) {
                    setBlobFile3(newList);
                }
            }, 3000);
            return () => clearTimeout(timeOut);
        } else return;
    }, [doc3, wordTitle, blobFileBottom]);

    useEffect(() => {
        if (wordTitle === 4 && blobFileBottom) {
            if (blobFile1) {
                GetWordMergeFile(blobFile1, 1);
            }
            if (blobFile2) {
                GetWordMergeFile(blobFile2, 2);
            }
            if (blobFile3) {
                GetWordMergeFile(blobFile3, 3);
            }
        }
    }, [blobFile1, blobFile2, blobFile3, wordTitle, blobFileBottom]);

    useEffect(() => {
        if (mergeFile1 && mergeFile2 && mergeFile3 && !submit) {
            const fileSize1 = mergeFile1.size;
            const fileSize2 = mergeFile2.size;
            const fileSize3 = mergeFile3.size;

            if (Math.abs(fileSize1 - fileSize2) <= 150 && Math.abs(fileSize2 - fileSize3) <= 150) {
                setSubmitStep2(true);
            }
        }
        if (mergeFile1 && mergeFile2 && mergeFile3 && submit && fileName) {
            if (mergeFile1) {
                setTimeout(() => {
                    saveAs(mergeFile1, fileName + '.docx');
                }, 500);
            }
            if (mergeFile2) {
                setTimeout(() => {
                    saveAs(mergeFile2, fileName + '.docx');
                }, 500);
            }
            if (mergeFile3) {
                setTimeout(() => {
                    saveAs(mergeFile3, fileName + '.docx');
                }, 500);
            }

            setTimeout(() => {
                window.location.href = window.location.href;
            }, 3000);
        }
    }, [mergeFile1, mergeFile2, mergeFile3, submit, fileName]);

    function ResetDefault() {
        setResultList(null);
        setWordTitle(1);
        setDoc1(null);
        setDoc2(null);
        setDoc3(null);
        setBlobFile1(null);
        setBlobFile2(null);
        setBlobFile3(null);
        setBlobFileBottom(null);
        setSubmitStep2(false);
        setSubmit(false);
    }

    return {
        setSubmit,
        submitStep2,
        selectedSheet,
        setSelectedSheet,
        setRowIndex,
        setEndRow,
        endRow,
        rowIndex,
        selectedFile,
        setSelectedFile,
        workBook,
        setWorkBook,
        setHeader,
        header,
        getFixedHeader,
        setSetupSuccessful,
        coList,
        fetchCO,
        setFetchCO,
        setupImport,
        setCOList,
        setMergeFile1,
        setMergeFile2,
        setMergeFile3,
        uniqHsMap,
        SubmitOriginateHandler,
        OriginImportHandler,
        revertData,
        setRevertData,
        ResetDefault
    };
}

export default useGeneratePageImport;
