import {forwardRef, useContext, useImperativeHandle} from 'react'
import {SpreadSheetContext} from './context'
import {Controller, useForm} from 'react-hook-form'
import {Autocomplete, TextField} from '@mui/material'
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers'
import _ from 'lodash'
import * as yup from 'yup'
import {yupResolver} from '@hookform/resolvers/yup'
import {Primitive, space_key} from './type'
import {match, Pattern, P} from "ts-pattern";
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {Moment} from "moment";

export type ColumnMapperRef = {
    submit: () => object
}

export const ColumnMapper = forwardRef<ColumnMapperRef, any>((__, ref) => {
    const {
        onFinish,
        formLayout: layout,
        extraInput,
        rowIndex,
        sheetMetaImportParams
    } = useContext(SpreadSheetContext)


    const formSchema = yup
        .object(
            _.chain(layout)
                .flatten()
                .filter((value) => value !== space_key)
                .reduce<{ [key: string]: any }>((acc, current) => {
                    acc[current] = match(extraInput[current])
                        .with(Primitive.date, (_) => yup.date().required())
                        .otherwise(() => yup.string().required())
                    return acc
                }, {})
                .value()
        )
        .required()

    const {handleSubmit, control, reset, getValues, formState} = useForm({
        mode: 'onSubmit',
        resolver: yupResolver(formSchema),
    })

    const onSubmit = () => {
        const val = getValues()
        if (_.isEmpty(rowIndex)) {
            throw new Error('No title selected')
        }
        const [from, to] = rowIndex!

        onFinish({
            ...val,
            ...sheetMetaImportParams,
        })
    }

    useImperativeHandle(ref, () => ({
        submit: handleSubmit(onSubmit),
    }))

    return (
        <form>
            {layout.map((row, index) => {
                return (
                    <div key={index} className="tw-flex tw-px-4 tw-flex-row tw-mb-[16px]">
                        {row.map((key) => {
                            if (key === space_key)
                                return <div key={key + index} className="tw-w-[32px]"/>
                            return (
                                <div key={key + index} className="tw-flex-1">
                                    <Controller
                                        name={key}
                                        control={control}
                                        render={({
                                                     field: {onChange, value},
                                                     fieldState: {error},
                                                 }) => (
                                            <>
                                                {
                                                    match(extraInput[key])
                                                        .with(P.array(Pattern.string), (input) => {
                                                            return <Autocomplete
                                                                freeSolo={
                                                                    _.isEmpty(input)
                                                                }
                                                                disablePortal
                                                                options={input}
                                                                onChange={(e, data) => onChange(data)}
                                                                renderInput={
                                                                    (params) => (
                                                                        <TextField
                                                                            {...params}
                                                                            label={key}
                                                                            error={!_.isNil(error)}
                                                                            helperText={error?.message}
                                                                            onChange={(e) => onChange(e.target.value)}
                                                                        />
                                                                    )
                                                                }
                                                            />
                                                        })
                                                        .with(Primitive.date, (type) => {
                                                            return <LocalizationProvider dateAdapter={AdapterMoment}>
                                                                <DatePicker onChange={(e, data) => {
                                                                    const dateStr = (e as Moment).format("YYYY-MM-DD");
                                                                    onChange(dateStr)
                                                                }}
                                                                            renderInput={(params) => (
                                                                                <TextField
                                                                                    {...params}
                                                                                    label={key}
                                                                                    error={!_.isNil(error)}
                                                                                    helperText={error?.message}

                                                                                />
                                                                            )} value={value}/>
                                                            </LocalizationProvider>
                                                        })
                                                        .exhaustive()

                                                }

                                            </>
                                        )}
                                    />
                                </div>
                            )
                        })}
                    </div>
                )
            })}
        </form>
    )
})
