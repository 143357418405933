import {createSlice} from '@reduxjs/toolkit';
import {CompanySettingData, ICompanySetting} from "../../models";

const initialState: { detail?: ICompanySetting } = {
    detail: undefined,

};

const company = createSlice({
    name: 'company',
    initialState,
    reducers: {
        setCompanyDetail(state,{ payload }) {
             state.detail = payload
        }
    }
})

export default company.reducer;

export const { setCompanyDetail } =  company.actions;
