import {JsonObject, JsonProperty} from 'json2typescript';
import {JsonSerializable} from '../serialization';
import {FolderSet} from './folderSetData';
import _ from 'lodash'

export interface SideBarCompanyValueType {
    slug: string;
    name: string;
    description: string;
    created_by: string;
    tax_code: string;
    last_updated: string;
    balance_count: number;
    statement_count: number;
    staff_count: number;
    profile_picture: string;
    company_type: string;
    folder_set: FolderSet[];
}

@JsonObject('SidebarcompanyData')
export class SidebarcompanyData extends JsonSerializable<SidebarcompanyData> implements SideBarCompanyValueType {
    protected get classRef(): new () => SidebarcompanyData {
        return SidebarcompanyData;
    }

    @JsonProperty('slug', String, true)
    slug: string = '';

    @JsonProperty('name', String, true)
    name: string = '';

    @JsonProperty('description', String, true)
    description: string = '';

    @JsonProperty('created_by', String, true)
    created_by: string = '';

    @JsonProperty('tax_code', String, true)
    tax_code: string = '';

    @JsonProperty('last_updated', String, true)
    last_updated: string = '';

    @JsonProperty('balance_count', Number, true)
    balance_count: number = 0;

    @JsonProperty('statement_count', Number, true)
    statement_count: number = 0;

    @JsonProperty('staff_count', Number, true)
    staff_count: number = 0;

    @JsonProperty('profile_picture', String, true)
    profile_picture: string = '';

    @JsonProperty('company_type', String, true)
    company_type: string = '';

    @JsonProperty('folder_set', [FolderSet], true)
    folder_set: FolderSet[] = [];

    get folderSlug()  {
        if(_.isEmpty(this.folder_set))
            throw Error("Folder set should not be empty")
        return this.folder_set[0].slug
    }
}
