import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import useSnackBar, { SnackBarAlertEnum } from 'components/SnackBar/UISnackbar';
import { useContext, useEffect, useState } from 'react';
import { InvoiceSheetModalProviderContext } from './InvoiceSheetModalProvider';

function InvoiceSheetStep2() {
    const { showSnackBar } = useSnackBar();
    const [submit, setSubmit] = useState(false);
    const { setSubmit: setWordProviderSubmit, submitStep2, setOpenImport } = useContext(InvoiceSheetModalProviderContext);

    useEffect(() => {
        if (submit) {
            setWordProviderSubmit(true);
            setOpenImport(false);
            showSnackBar('Submit success', SnackBarAlertEnum.success);
        }
    }, [submit]);

    useEffect(() => {
        if (submitStep2) {
            showSnackBar('Generate Completed!', SnackBarAlertEnum.success);
        }
    }, [submitStep2]);

    return (
        <>
            {!submitStep2 && (
                <Box
                    sx={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '8px'
                    }}
                >
                    <h2>Generate Word file...</h2>
                    <CircularProgress thickness={4.2} size={64} />
                </Box>
            )}
            {submitStep2 && (
                <Box
                    sx={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '8px'
                    }}
                >
                    <h2>Generated Success!</h2>
                </Box>
            )}
        </>
    );
}

export default InvoiceSheetStep2;
