import { DetailedSettings } from 'handsontable/plugins/mergeCells/mergeCells';
import { XlxsExportListInterface } from 'components/BalanceTable';
import * as XLSX from 'xlsx-js-style';

const mergedCellsFromHandsonToSheetJS = (mergedCells: DetailedSettings[]): XLSX.Range[] => {
    return mergedCells.map(({ row, col, rowspan, colspan }) => ({
        s: { r: row, c: col },
        e: { r: rowspan + row - 1, c: colspan + col - 1 }
    }));
};

// export const exportXlsx = (data: any[][], mergedCells: DetailedSettings[]) => {
//     const workbook = utils.book_new();
//     const ws = utils.aoa_to_sheet(data);
//     const ws1 = utils.aoa_to_sheet(data);
//     ws['!merges'] = [...mergedCellsFromHandsonToSheetJS(mergedCells)];
//     utils.book_append_sheet(workbook, ws, 'sheet');
//     utils.book_append_sheet(workbook, ws1, 'sheet1');
//     writeFileXLSX(workbook, 'export.xlsx');
// };

function applyStyleDataFixed(data: any[][]) {
    return data.map((item: any[], index: number) => {
        if (index === 0 || index === 1) {
            return item.map((jtem: any) => {
                if (typeof jtem === 'string') {
                    return {
                        v: jtem,
                        s: {
                            font: { sz: 16, bold: true },
                            alignment: { vertical: 'center', horizontal: 'center' }
                        }
                    };
                } else return jtem;
            });
        } else if (index === 2) {
            return item.map((jtem: any) => {
                if (typeof jtem === 'string') {
                    return {
                        v: jtem,
                        s: {
                            font: { italic: true },
                            alignment: { vertical: 'center', horizontal: 'center' }
                        }
                    };
                } else return jtem;
            });
        } else if (index >= 3 && index <= 9) {
            return item.map((jtem: any, jndex: number) => {
                if (typeof jtem === 'string' && jndex === 10) {
                    return {
                        v: jtem,
                        s: {
                            alignment: { vertical: 'center', horizontal: 'center' }
                        }
                    };
                } else return jtem;
            });
        } else if (index === data.length - 1) {
            return item.map((jtem: any) => {
                if (typeof jtem === 'string' || typeof jtem === 'number') {
                    return {
                        v: jtem,
                        s: {
                            font: { bold: true }
                        }
                    };
                } else return jtem;
            });
        } else {
            return item.map((jtem: any) => {
                if (typeof jtem === 'string' || typeof jtem === 'number') {
                    return {
                        v: jtem,
                        s: {
                            alignment: { vertical: 'center', horizontal: 'center' }
                        }
                    };
                } else return jtem;
            });
        }
    });
}

function applyStyleDataDynamic(data: any[][]) {
    return data.map((item: any[], index: number) => {
        if (index === data.length - 1 || index === data.length - 2) {
            return item.map((jtem: any) => {
                if (typeof jtem === 'string' || typeof jtem === 'number') {
                    return {
                        v: jtem,
                        s: {
                            font: { bold: true }
                        }
                    };
                } else return jtem;
            });
        } else {
            return item.map((jtem: any) => {
                if (typeof jtem === 'string' || typeof jtem === 'number') {
                    return {
                        v: jtem,
                        s: {
                            font: { bold: true },
                            alignment: { vertical: 'center', horizontal: 'center' }
                        }
                    };
                } else return jtem;
            });
        }
    });
}

export const exportXlsx = (data: XlxsExportListInterface[]) => {
    const firstName = data[0].sheetName + '.xlsx' ?? 'export.xlsx';
    const wb = XLSX.utils.book_new();

    if (data && data.length != 0) {
        data.forEach((item, index) => {
            if (item.sheetData && item.sheetDataFixed && item.sheetDataDynamic) {
                const newDataFixed = applyStyleDataFixed(item.sheetDataFixed);
                const newDataDynamic = applyStyleDataDynamic(item.sheetDataDynamic);
                const ws = XLSX.utils.aoa_to_sheet([...newDataFixed, ...newDataDynamic]);
                ws['!merges'] = [...mergedCellsFromHandsonToSheetJS(item.sheetMergeCell)];
                ws['!cols'] = [
                    { width: 15 },
                    { width: 50 },
                    { width: 10 },
                    { width: 10 },
                    { width: 10 },
                    { width: 10 },
                    { width: 25 },
                    { width: 25 },
                    { width: 20 },
                    { width: 30 },
                    { width: 30 },
                    { width: 30 },
                    { width: 30 }
                ];
                XLSX.utils.book_append_sheet(wb, ws, `${item.sheetName} (${index})`);
            }
        });
    } else {
        throw new Error(`Can't export file with empty data`);
    }

    XLSX.writeFile(wb, firstName);
};
