import { useLocation } from 'react-router-dom';
import {
    AppBar,
    Box,
    Container,
    CssBaseline,
    Toolbar,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import LAYOUT_CONST from 'constant';
import useAuth from 'hooks/useAuth';
import useConfig from 'hooks/useConfig';
import { useEffect, useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'store';
import { drawerWidth } from 'store/constant';
import { openDrawer } from 'store/slices/menu';
import Header from './Header';
import HorizontalBar from './HorizontalBar';
import Sidebar from './Sidebar';
import HomeSidebar from './Sidebar/HomeSidebar';
import FloatingContactWidget from './Sidebar/ContactWidget';
import searchCover from 'assets/Search-Cover.png';
import Search from './Header/SearchSection/Search';

const Main = styled('main')(({ theme, open }: any) => ({
    ...theme.typography.mainContent,
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.shorter + 200,
    }),
    marginLeft: open ? drawerWidth - 30 : 20,
    marginRight: 0,
    marginTop: 0,
    maxWidth: `calc(100vw - ${open ? drawerWidth - 30 : 0}px)`,
    [theme.breakpoints.down('md')]: {
        marginLeft: 0,
        padding: 0,
        maxWidth: '100vw',
    },
    overflowX: 'hidden', // Prevent horizontal scrolling
}));

const MainLayout = () => {
    const theme = useTheme();
    const matchDownXs = useMediaQuery(theme.breakpoints.down('xs'));
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
   
    const { isLoggedIn } = useAuth();
    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);
    const { drawerType, container, layout } = useConfig();
    const location = useLocation();

    // useEffect(() => {
    //     {!matchDownMd &&
    //         dispatch(openDrawer(drawerType === LAYOUT_CONST.DEFAULT_DRAWER));
    //     }
    // }, [drawerType]);

    const header = useMemo(
        () => (
            <Toolbar sx={{ p: { xs: 0, sm: '10px' }, borderBottom: '1px solid #ccc', width: '100%' }}>
                <Header />
            </Toolbar>
        ),
        [layout]
    );
    const excludedRoutes = ['/searching-co',];

    // Check if the current route is in the excluded list
    const isExcluded = excludedRoutes?.includes(location.pathname);
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', overflowX: 'hidden', gap: '10px' }}>
            <CssBaseline />
            {/* Main AppBar */}
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{ bgcolor: theme.palette.background.default }}
            >
                {header}
                {matchDownMd && !isExcluded &&  (
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            height: '150px',
                            alignItems: 'center',
                            background: `url(${searchCover}) no-repeat center center`,
                            backgroundSize: 'cover',
                        }}
                    >
                        <Search />
                    </Box>
                )}


            </AppBar>

            {/* Second AppBar only for XS screens */}

            {layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownMd && isLoggedIn && <HorizontalBar />}
            {layout !== LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownXs && isLoggedIn && <Sidebar />}
            {layout !== LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownXs && !isLoggedIn && <HomeSidebar />}
            {layout !== LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownXs && !isLoggedIn && <FloatingContactWidget />}

            <Main theme={theme} open={drawerOpen}>
                <Container
                    maxWidth={container ? 'lg' : false}
                    {...(!container && { sx: { px: { xs: 0 }, mx: { xs: 0 }, mt: {xs: !isExcluded ? 27 : 0, sm: !isExcluded ? 32: 0, md: !isExcluded ? 10: 0 } } })}
                >
                    <Outlet />
                </Container>
            </Main>
        </Box>
    );
};

export default MainLayout;
