import { Box, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import background01 from '../../../../assets/images/pages/bangke_bg_01.avif';
import { PhysicalCountingEnum, Report15Context, Statement15ATypeCodeEnum, Statement15TypeCodeEnum, StatementTypeEnum } from '../context';
import useSnackBar, { SnackBarAlertEnum } from 'components/SnackBar/UISnackbar';

const STATEMENT_LIST = ['15', '15A'];
const STATEMENT15_TYPECODE_LIST = ['E11 - EPE', 'E21 - Processing', 'E31 - SXXK'];
const STATEMENT15A_TYPECODE_LIST = ['E42 - EPE', 'E52 - Processing', 'E62 - SXXK'];
const PHYSICALCOUNTING_LIST = ['Accounting', 'W/H'];

function ModalStep1() {
    const { showSnackBar } = useSnackBar();
    const {
        statementType,
        setStatementType,
        statement15TypeCode,
        setStatement15TypeCode,
        statement15ATypeCode,
        setStatement15ATypeCode,
        physicalCounting,
        setPhysicalCounting,
        year,
        setYear
    } = useContext(Report15Context);

    const [statementTypeModal, setStatementTypeModal] = useState<string>(statementType);
    const [statement15TypeCodeModal, setStatement15TypeCodeModal] = useState<string>(statement15TypeCode);
    const [statement15ATypeCodeModal, setStatement15ATypeCodeModal] = useState<string>(statement15ATypeCode);
    const [physicalCountingModal, setPhysicalCountingModal] = useState<string>(physicalCounting);
    const [yearModal, setYearModal] = useState('');

    function StatementTypeHandler(v: SelectChangeEvent) {
        setStatementTypeModal(v.target.value as StatementTypeEnum);
    }

    function Statement15TypeCodeHandler(v: SelectChangeEvent) {
        setStatement15TypeCodeModal(v.target.value as Statement15TypeCodeEnum);
    }

    function Statement15ATypeCodeHandler(v: SelectChangeEvent) {
        setStatement15ATypeCodeModal(v.target.value as Statement15ATypeCodeEnum);
    }

    function PhysicalCountingHandler(v: SelectChangeEvent) {
        setPhysicalCountingModal(v.target.value as PhysicalCountingEnum);
    }

    function YearHandler(v: any) {
        setYearModal(v.target.value);
    }

    useEffect(() => {
        setStatementType(statementTypeModal);
        setStatement15TypeCode(statement15TypeCodeModal);
        setStatement15ATypeCode(statement15ATypeCodeModal);
        setPhysicalCounting(physicalCountingModal);
        setYear(yearModal);
        showSnackBar('Update Success', SnackBarAlertEnum.success);
    }, [statementTypeModal, statement15TypeCodeModal, statement15ATypeCodeModal, physicalCountingModal, yearModal]);

    return (
        <Box sx={{ display: 'flex' }}>
            <Box sx={{ flex: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img style={{ width: '85%', borderRadius: '4px' }} src={background01} alt="bangke_bg_01" />
            </Box>
            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '16px' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <h3>Please choose type of statement:</h3>
                    <Select
                        labelId="drop-down-statement-select"
                        id="drop-down-statement-select"
                        value={statementTypeModal}
                        onChange={StatementTypeHandler}
                        sx={{ width: '200px', height: '48px', borderRadius: '5px' }}
                    >
                        {STATEMENT_LIST.map((item) => (
                            <MenuItem key={item} value={item}>
                                {item}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <h3>Choose type code:</h3>
                    {statementType === StatementTypeEnum.statement15 ? (
                        <Select
                            labelId="drop-down-statement15-select-typecode"
                            id="drop-down-statement15-select-typecode"
                            value={statement15TypeCodeModal}
                            onChange={Statement15TypeCodeHandler}
                            sx={{ width: '200px', height: '48px', borderRadius: '5px' }}
                        >
                            {STATEMENT15_TYPECODE_LIST.map((item) => (
                                <MenuItem key={item} value={item}>
                                    {item}
                                </MenuItem>
                            ))}
                        </Select>
                    ) : (
                        <Select
                            labelId="drop-down-statement15a-select-typecode"
                            id="drop-down-statement15a-select-typecode"
                            value={statement15ATypeCodeModal}
                            onChange={Statement15ATypeCodeHandler}
                            sx={{ width: '200px', height: '48px', borderRadius: '5px' }}
                        >
                            {STATEMENT15A_TYPECODE_LIST.map((item) => (
                                <MenuItem key={item} value={item}>
                                    {item}
                                </MenuItem>
                            ))}
                        </Select>
                    )}
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <h3>Choose Physical counting:</h3>
                    <Select
                        labelId="drop-down-statement-select"
                        id="drop-down-statement-select"
                        value={physicalCountingModal}
                        onChange={PhysicalCountingHandler}
                        sx={{ width: '200px', height: '48px', borderRadius: '5px' }}
                    >
                        {PHYSICALCOUNTING_LIST.map((item) => (
                            <MenuItem key={item} value={item}>
                                {item}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <h3>Please insert year:</h3>
                    <TextField
                        value={yearModal}
                        onChange={YearHandler}
                        sx={{ width: '200px', height: '48px', borderRadius: '5px' }}
                        type="number"
                    />
                </Box>
            </Box>
        </Box>
    );
}

export default ModalStep1;
