import { Http } from 'api';
import { BeginData, JSONType, BeginSetupDTO } from 'models';
import _ from 'lodash';
import { BeginQueryParams } from '../type';
import { AutoFillData, IAutoFillData } from 'models/AutoFillData/AutoFillData';
import { AutoFillParams } from 'components';
import { BalanceImportDataSource } from '../BalanceImportDataSource';
import { BeginImportParams } from './type';

export class BeginDataSource extends BalanceImportDataSource<BeginImportParams> {
    path: string = 'begin';

    public async getAll(params: BeginQueryParams) {
        const response = await this.http.instance.get('/api/balance/begin/', {
            params
        });
        return new BeginData().fromPaging<BeginData>(response.data);
    }

    public async update(data: Partial<BeginData>, params: BeginQueryParams) {
        return this.http.instance.put(
            `/api/balance/begin/${data.id}/`,
            _.omit(
                {
                    ...data,
                    ...params
                },
                'id'
            )
        );
    }

    public async delete(ids: string[]) {
        return this.http.instance.delete(`/api/balance/begin/bulk-delete/`, {
            data: { id_list: ids }
        });
    }

    public async insert(data: JSONType) {
        return this.http.instance.post(`/api/balance/begin/`, data);
    }

    public async getAutoFill(data: AutoFillParams) {
        const respone = await this.http.instance.post('/api/balance/begin/autofill/', data);
        return new AutoFillData().fromPaging<AutoFillData>(respone.data);
    }
}
