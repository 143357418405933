import { JsonObject, JsonProperty } from 'json2typescript';
import { JsonSerializable } from 'models/serialization';

export interface IAutoFillData {
    code: string;
    item_name?: string[];
    name?: string[];
    unit: string[];
}

@JsonObject('AutoFillData')
export class AutoFillData extends JsonSerializable<AutoFillData> implements IAutoFillData {
    protected get classRef(): new () => AutoFillData {
        return AutoFillData;
    }

    @JsonProperty('code', String, false)
    code!: string;

    @JsonProperty('item_name', [String], true)
    item_name: string[] = [''];

    @JsonProperty('name', [String], true)
    name: string[] = [''];

    @JsonProperty('unit', [String], true)
    unit: string[] = [''];
}
