import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import TreeItem from '@mui/lab/TreeItem';
import Collapse from '@mui/material/Collapse';
import { useNavigate } from 'react-router-dom';
import { useSpring, animated } from 'react-spring'; // web.cjs is required for IE 11 support
import { Stack } from '@mui/material';
import Divider from '@mui/material/Divider';
import { useDispatch, useSelector } from 'store';
import { setSelectedHs, setTitilePage } from 'store/slices/HsCode';
import useConfig from 'hooks/useConfig';
import React, { useState } from 'react';

function cleanString(input) {
  return input.replace(/^-+|-+$/g, '') // Xóa dấu '-' ở đầu và cuối chuỗi
              .replace(/[-\s]+/g, ' ') // Thay thế các dấu '-' và khoảng trắng liên tiếp bằng một khoảng trắng
              .trim(); // Xóa khoảng trắng ở đầu và cuối chuỗi
}
// const {TitilePage} = useHsContext();
function MinusSquare(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#0277bd" class="bi bi-dash-circle" viewBox="0 0 16 16">
      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
      <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8"/>
    </svg>

  );
  }

function PlusSquare(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#0277bd" class="bi bi-plus-circle" viewBox="0 0 16 16">
      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
      <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/>
  </svg>
  );
}

function CloseSquare(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" className="bi bi-circle" viewBox="0 0 16 16">
      <circle cx="8" cy="8" r="7" stroke="#0277bd" fill="#8adaff" /> 
    </svg>
  );
}

function TransitionComponent(props) {
    const style = useSpring({
        from: { opacity: 0, transform: 'translate3d(20px,0,0)' },
        to: { opacity: props.in ? 1 : 0, transform: `translate3d(${props.in ? 0 : 20}px,0,0)` },
    });

    return (
        <animated.div style={style}>
            <Collapse {...props} />
        </animated.div>
    );
}

TransitionComponent.propTypes = {
/**
 * Show the component; triggers the enter or exit states
 */
in: PropTypes.bool,
};

// const { Year, SelectedHs } = useHsContext();
const VerticalLine = styled('div')(({ theme }) => ({
  width: '2px', // Width of the vertical line
  backgroundColor: '#0277bd', // Color of the vertical line
  position: 'absolute', // Position it absolutely
  left: '-9px', // Adjust this value to position the line correctly
  bottom: '-14px', // Position the line 14px below the block
  height: 'calc(50% + 14px)', // Height is half of the block's height plus 14px
  minHeight: '100%'
}));

const capitalizeWords = (str) => {
  return str
    .toLowerCase() // Chuyển toàn bộ chuỗi về chữ thường
    .replace(/(?:^|\s)\S/g, (match) => match.toUpperCase()); // In hoa chữ cái đầu tiên sau khoảng trắng hoặc đầu chuỗi
};


const CustomTreeItem = (props) => {
  const navigate = useNavigate();
  const { hs, iconText, uqParam, end, node, section, expanded, id, ...otherProps } = props; // Added section
  const dispatch = useDispatch();
  const { locale: lang } = useConfig();
  // const [isExpanded, setIsExpanded] = useState(false);
  let isExpanded = false
  try{
    isExpanded = expanded.includes(id)
  }catch{
    isExpanded = false
  }
  
  const handleItemClick = () => {

      if (end) {
          
          dispatch(setSelectedHs(end));
          dispatch(setTitilePage(capitalizeWords(`${lang === 'vi' ? 'HS code ': 'HS code'} ${end.hs}: ${cleanString((end[`${lang}_description`] || end.description))}`)));
      } else {
          if (node) {
              dispatch(setSelectedHs(node));
              dispatch(setTitilePage(capitalizeWords(`${lang === 'vi' ? 'Nhóm ' : 'Heading '}  ${node.hs}: ${cleanString((node[`${lang}_description`] || node.description))}`)));
          }
      }
      // navigate(`/regulation/${hs}`);
      navigate(`/${hs}`);
      
  };

  const processIconText = (text) => {
      let processedText = text.replace(/\./g, '').replace(/\D/g, '');
      if (processedText.length === 1) {
          processedText = '0' + processedText;
      }
      return processedText;
  };

  const getIconTextChunks = (text) => {
      const chunks = [];
      for (let i = 0; i < text.length; i += 2) {
          chunks.push(text.substring(i, i + 2));
      }
      return chunks;
  };

  const colorShades = ['#265e9b', '#2196F3', '#99CBFD', '#BDDEFE'];
  const processedIconText = processIconText(iconText);
  const iconTextChunks = getIconTextChunks(processedIconText);

  return (
      <TreeItem
          {...otherProps}
          TransitionComponent={TransitionComponent}
          onClick={handleItemClick}
          label={
              <Stack direction="column" spacing={1}>
                 {isExpanded && <VerticalLine />}
                  <Stack direction="row" alignItems="center" spacing={1} style={{  marginTop: section? isExpanded ? '0.65em':'0' : isExpanded? '0':'0'}}>
                      {iconTextChunks.map((chunk, index) => (
                          <StyledIcon key={index} shade={colorShades[index % colorShades.length]} >
                             {chunk}
                          </StyledIcon>
                      ))}
                  </Stack>
                  <Stack direction="column" spacing={0.5} alignItems="flex-start">
                      {/* Conditionally change label color if section is provided */}
                    
                      <StyledLabel style={{ color: section ? '#0277bd' : '#000000' , marginTop: section? isExpanded ? '-1em':'-0.5em' : isExpanded? '0':'0'
                        , marginLeft: section ? '0.5rem':'0',  marginBottom: section ? '0.5rem':'0'}}>
                        {props.label}
                      </StyledLabel>
               
                      {/* Conditionally render section content or UQ note */}
                      {section ? (
                          <StyledNote style={{  marginLeft: '0.5rem'}}>
                            {section}
                          </StyledNote>
                      ) : (
                          uqParam && <StyledNote>UQ: {uqParam}</StyledNote>
                      )}
                  </Stack>
                  {/* Conditionally remove Divider if section is provided */}
                  {!section && <Divider sx={{ borderBottom: '1px dashed #CDCDCD', marginTop: '5px' }} />}
              </Stack>
          }
      />
  );
};



const StyledTreeItem = styled(CustomTreeItem)(({ theme }) => ({

    
    '&': {  
      position: 'relative',
      paddingLeft: '1rem',
     
     
    },
   '&:after': {
      position: 'absolute',
      left: '-18px',
      top: '0rem',
      borderLeft: '2px solid #0277bd',
      borderBottom: '2px solid #0277bd',
      content: '""',
      width: '1.9rem',
      height: '1.6rem',
    },
    '&:last-child::before': {
      display: 'none',
    },
    '&:before': {
      position: 'absolute',
      left: '-18px',
      top: '0px',
      borderLeft: '2px solid #0277bd',
      content: '""',
      width: '2.4rem',
      height: '100%',
    },
    '& .MuiTreeItem-iconContainer': {
      position: 'absolute',
      left: '.5rem', 
      top: '1rem', 
    },
    '& .MuiTreeItem-content': {
    backgroundColor: '#fff',
    fontWeight: 'normal',
    '&:hover': {
      backgroundColor: 'rgba(33, 150, 243, 0.1)', // Màu nền khi hover
    },
    '&.Mui-selected': {
      backgroundColor: 'rgba(33, 150, 243, 0.2)', // Màu nền khi được chọn
      '&:hover': {
        backgroundColor: 'rgba(33, 150, 243, 0.3)', // Màu nền khi hover trong trạng thái chọn
      },
    },
  
    
  },
  }));

  

const StyledTreeItem_noline = styled(CustomTreeItem)(({ theme }) => ({
  '&': {
    position: 'relative',
    paddingLeft: '1rem',
  },
//  '&:after': {
//     position: 'absolute',
//     left: '-10px',
//     top: '0rem',
//     borderLeft: '2px solid #0277bd',
//     borderBottom: '2px solid #0277bd',
//     content: '""',
//     width: '1.3rem',
//     height: '1.6rem',
//   },
//   '&:last-child::before': {
//     display: 'none',
//   },
//   '&:before': {
//     position: 'absolute',
//     left: '-10px',
//     top: '0px',
//     borderLeft: '2px solid #0277bd',
//     content: '""',
//     width: '2.4rem',
//     height: '100%',
//   },
  '& .MuiTreeItem-iconContainer': {
    position: 'absolute',
    left: '.5rem', 
    top: '1rem', 
    
  },
  '& .MuiTreeItem-content': {
    backgroundColor: '#fff',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: 'rgba(33, 150, 243, 0.1)', // Màu nền khi hover
    },
    '&.Mui-selected': {
      backgroundColor: 'rgba(33, 150, 243, 0.2)', // Màu nền khi được chọn
      '&:hover': {
        backgroundColor: 'rgba(33, 150, 243, 0.3)', // Màu nền khi hover trong trạng thái chọn
      },
    },
  
    
  },

  }));

const StyledLabel = styled('span')(({ theme }) => ({
    color: '#000000',
    fontWeight: 'normal',// Set the font weight to bold
    display: 'block', // Allow the label to act as a block
    whiteSpace: 'normal', // Allow the text to wrap
    wordWrap: 'break-word', // Break long words if necessary
    fontSize: '1.1em',
    lineHeight: 1.3

}));

const StyledNote = styled('span')(({ theme }) => ({
    fontSize: '14px', // Smaller font for the UQ note
    color: theme.palette.text.secondary, // Secondary color for the note
    
}));

const StyledIcon = styled('div')(({ theme, shade }) => ({
    width: '24px',
    height: '24px',
    backgroundColor: shade || theme.palette.primary.main, // Color of the circle, progressively lighter shades
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff', // Color of the text inside the icon
    fontSize: '12px', // Font size for the icon text

}));

export {StyledTreeItem, StyledTreeItem_noline, StyledLabel, MinusSquare, PlusSquare, CloseSquare};



